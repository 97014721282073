import Connect from 'components/svg/connect';
import Disconnect from 'components/svg/disconnect';
import Information from 'components/svg/information';
import { LoadingCircle } from 'constants/index';
import React from 'react';
import styles from './screenshot.module.scss';

interface iProps {
  className?: string;
  screenshot: string;
  isConnectedScreen: boolean;
  isOnline: boolean;
  displayNewItemFlag?: boolean;
}

function Screenshot(props: iProps): JSX.Element {
  const {
    className,
    isConnectedScreen,
    isOnline,
    screenshot,
    displayNewItemFlag,
  } = props;

  const StatusIcon =
    (isConnectedScreen && (isOnline ? Connect : Disconnect)) || Information;

  const statusMessage =
    (isConnectedScreen && (isOnline ? 'Online' : 'Offline')) ||
    "You haven't connected this to a live screen yet.";

  return (
    <div
      className={`${className} ${styles.screen} ${
        !screenshot && styles.noScreenShot
      }`}
    >
      {screenshot === 'BeingAdded' && (
        <div className={styles.screenBeingAddLoder}>
          <img className={styles.loadingCircle} src={LoadingCircle} alt="" />
          <p>Your screen is being added. This will take a minute or two</p>
        </div>
      )}
      {screenshot !== 'BeingAdded' &&
        (screenshot ? (
          <>
            <img className={styles.screenshot} src={screenshot} alt="" />
            {displayNewItemFlag && (
              <p className={styles.sharedStatus}>Newly shared with you</p>
            )}
          </>
        ) : (
          <>
            {displayNewItemFlag && (
              <p className={styles.sharedStatus}>Newly shared with you</p>
            )}
            <div className={styles.connectionStatus}>
              <StatusIcon className={styles.svg} />
              {statusMessage}
            </div>
          </>
        ))}
    </div>
  );
}

Screenshot.defaultProps = {
  className: '',
  displayNewItemFlag: false,
};

export default Screenshot;

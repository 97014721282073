import { Mutation, Query } from 'config';
import { captureException } from 'helpers/sentry';
import {
  iAddOrUpdateMSAccountArgs,
  iAddOrUpdateMSAccountData,
  iAddOrUpdateMSAccountResponse,
  iDeleteMSAccountArgs,
  iDeleteMSAccountData,
  iDeleteRoomsResponse,
  iGetMsAccountsArgs,
  iGetMsAccountsData,
  iGetMsAccountsResponse,
  iGraphqlResponse,
} from 'types';
import {
  addOrUpdateMSAccountMutation,
  deleteRoomsMutation,
  getMsAccountsQuery,
} from '../graphql';

export const addOrUpdateMSAccount = async (
  args: iAddOrUpdateMSAccountArgs,
  isLoading
): Promise<iAddOrUpdateMSAccountData | null> => {
  try {
    isLoading(true);
    const response = await (<iGraphqlResponse<iAddOrUpdateMSAccountResponse>>(
      await Mutation(addOrUpdateMSAccountMutation, args)
    ));
    isLoading(false);
    return response.data.addOrUpdateMSAccount.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const getMSAccount = async (
  args: iGetMsAccountsArgs,
  isLoading
): Promise<Array<iGetMsAccountsData> | null> => {
  try {
    isLoading(true);
    const response = await (<iGraphqlResponse<iGetMsAccountsResponse>>(
      await Query(getMsAccountsQuery, args)
    ));
    isLoading(false);
    return response.data.getMsAccounts.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const deleteMSAccount = async (
  args: iDeleteMSAccountArgs,
  isLoading
): Promise<iDeleteMSAccountData | null> => {
  try {
    isLoading(true);
    const response = await (<iGraphqlResponse<iDeleteRoomsResponse>>(
      await Mutation(deleteRoomsMutation, args)
    ));
    isLoading(false);
    return response.data.deleteMsAccount.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

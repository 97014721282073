import React, { useCallback, useEffect, useState } from 'react';
import Checkbox from 'components/checkbox/checkbox';
import Input from 'components/input/input';
import { meetingOverviewFullActions } from 'store/actions';
import { iState } from 'types';
import { useStore } from 'store';
import styles from './overlay.module.scss';

interface iProps {
  className?: string;
}

function Overlay(props: iProps): JSX.Element {
  const { className } = props;
  const [checked, setChecked] = useState<boolean>(false);
  const [state, dispatch]: [iState, any] = useStore();
  const { eventBgImg, transparency } = state.meetingOverviewFull || {};
  const [progressValue, setProgressValue] = useState<string>('9');
  const handleCheckbox = useCallback(() => {
    setChecked(!checked);
  }, [checked]);

  useEffect(() => {
    if (transparency !== '' || null) {
      setProgressValue((Number(transparency) * 10).toString());
      setChecked(!checked);
    }
    if (transparency === '0') {
      setProgressValue('9');
    }
  }, []);

  const handleProgressBar = useCallback(
    (event) => {
      if (!eventBgImg) {
        const { value } = event.target;
        setProgressValue(Math.max(5, value).toString());
      } else {
        setProgressValue(event.target.value || '');
      }
    },
    [eventBgImg]
  );

  useEffect(() => {
    dispatch(meetingOverviewFullActions.setTransparency(progressValue));
    return () => {
      dispatch(meetingOverviewFullActions.setTransparency(''));
    };
  }, [dispatch, progressValue]);

  return (
    <div className={`${styles.overLay} ${className}`}>
      <section className={styles.checkBoxContainer}>
        <Checkbox
          checkmarkStyle
          isChecked={checked}
          className={styles.checkbox}
          onClick={handleCheckbox}
        />
        <p className={styles.checkBoxTitle} onClick={handleCheckbox}>
          Dark Overlay
        </p>
      </section>
      {checked && (
        <div className={styles.sliderContainer}>
          <input
            type="range"
            min="0"
            max="10"
            value={progressValue}
            onChange={(event) => handleProgressBar(event)}
            className={styles.slider}
          />
          <Input
            className={styles.progressInputField}
            disabled
            type="text"
            value={(Number(progressValue) * 10).toString()}
            placeholder="70"
          />
        </div>
      )}
    </div>
  );
}
Overlay.defaultProps = {
  className: '',
};
export default Overlay;

import { Query, Mutation } from 'config';
import {
  iGraphqlResponse,
  iGetPermissionsResponse,
  iGetPermissionsArgs,
  iAddOrUpdatePermissionsArgs,
  iPermissionsData,
  iAddOrUpdatePermissionResponse,
  AddOrUpdatePermissionData,
  iAddRoleResponse,
  iAddRoleData,
} from 'types';

import { captureException } from 'helpers/sentry';
import {
  getPermissionsQuery,
  addOrUpdatePermissionMutation,
  addRoleMutation,
} from '../graphql';

// const preparePermissions = (data: iPermissionsRawData): iPermissionsData => {
//   return {
//     rellerPermissions: {
//       manageReseller: data['manageReseller'],
//     },
//     clientsPermissions: {
//       clients: data['clients'],
//       addClients: data['addClients'],
//       deleteClients: data['deleteClients'],
//     },
//     teammatesPermissions: {
//       addTeammates: data['addTeammates'],
//       removeTeammates: data['removeTeammates'],
//       editCompanyDetails: data['editCompanyDetails'],
//     },
//     screensPermissions: {
//       screenAccess: data['screenAccess'],
//       deleteScreenAndFolder: data['deleteScreenAndFolder'],
//     },
//     contentsPermissions: {
//       contentAcces: data['contentAcces'],
//       addContentAndFolders: data['addContentAndFolders'],
//       deleteContentAndFolder: data['deleteContentAndFolder'],
//     },
//     playlistsPermissions: {
//       playListAccess: data['playListAccess'],
//       addPlayListAndFolders: data['addPlayListAndFolders'],
//       deletePlayListAndFolder: data['deletePlayListAndFolder'],
//     },
//   };
// };

export const getPermissions = async (
  variables: iGetPermissionsArgs,
  isLoading?: (type: boolean) => void
): Promise<iPermissionsData | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = <iGraphqlResponse<iGetPermissionsResponse>>(
      await Query(getPermissionsQuery, variables)
    );
    if (isLoading) isLoading(false);
    const { data } = response.data.getPermissions || {};
    // return preparePermissions(data);
    return data;
  } catch (error: any) {
    if (isLoading) isLoading(false);
    captureException(error);
    return null;
  }
};

export const addOrUpdatePermissions = async (
  variables: iAddOrUpdatePermissionsArgs,
  isLoading?: (type: boolean) => void
): Promise<AddOrUpdatePermissionData | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = <iGraphqlResponse<iAddOrUpdatePermissionResponse>>(
      await Mutation(addOrUpdatePermissionMutation, variables)
    );
    if (isLoading) isLoading(false);
    const { data } = response.data.addOrUpdatePermission || {};
    return data;
  } catch (error: any) {
    if (isLoading) isLoading(false);
    captureException(error);
    return null;
  }
};

export const addRole = async (
  roleName,
  isLoading?: (type: boolean) => void
): Promise<iAddRoleData | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = <iGraphqlResponse<iAddRoleResponse>>(
      await Mutation(addRoleMutation, { role_name: roleName })
    );
    if (isLoading) isLoading(false);
    return response.data.adduserrole.data;
  } catch (error: any) {
    if (isLoading) isLoading(false);
    captureException(error);
    return null;
  }
};

import React, { useCallback, useEffect, useState } from 'react';
import { CARD_NAME, LanguageType } from 'enums';
import { caretLeft } from 'constants/index';
import { iState } from 'types';
import { useStore } from 'store';
import { s3Operations } from 'helpers';
import { meetingOverviewFullActions } from 'store/actions';
import Button, { ButtonVariants } from 'components/button/button';
import englishData from '../../../../english.json';
import norwayData from '../../../../norwegian.json';
import Overlay from '../../../overlay/overlay';
import styles from './assetManager.module.scss';

interface iProps {
  className?: string;
  heading: string;
  assetType: 'bgImg' | 'logo';
  cardNumber: CARD_NAME;
}

function AssetManager(props: iProps): JSX.Element {
  const { className, heading, assetType, cardNumber } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { eventBgImg, cardDetails } = state.meetingOverviewFull || {};
  const { loggedInData } = state.user || {};
  const [isShowing, setIsShowing] = useState<boolean>(false);
  const { overviewEventLogo } = cardDetails[cardNumber] || {};

  const [templateTexts, setTemplateTexts] = useState<any>(englishData);
  const { selectedLanguage } = state.office365 || {};

  useEffect(() => {
    if (selectedLanguage.value === LanguageType.English) {
      setTemplateTexts(englishData);
    }
    if (selectedLanguage.value === LanguageType.Norwegian) {
      setTemplateTexts(norwayData);
    }
  }, [selectedLanguage]);

  const { id: userId } = loggedInData || {};
  const fileUpload = useCallback(
    async (file: File): Promise<string> => {
      const response: any = await s3Operations.upload({
        params: {
          Key: file.name,
          Body: file,
        },
        uploadType: 'calenderEventAssets',
        userId,
        progress: (progress) => {
          console.log('progress', progress);
        },
      });
      if (response) {
        //  console.log('UPLOAD_RESPONSE', response);
        return response.Location;
      }
      return '';
    },
    [userId]
  );

  const onChange = useCallback(
    async (event) => {
      const { files } = event.target || {};
      const file = files[0];
      if (assetType === 'bgImg') {
        const url = await fileUpload(file);
        dispatch(meetingOverviewFullActions.setEventBgImg(url));
      }
      if (assetType === 'logo') {
        const url = await fileUpload(file);
        dispatch(
          meetingOverviewFullActions.setOverviewEventLogo({
            url,
            CARD_NAME: cardNumber,
          })
        );
      }
    },
    [assetType, dispatch, fileUpload, cardNumber]
  );

  // useEffect(() => {
  //   if (transparency !== '') {
  //     setIsShowing(!isShowing);
  //   }
  // }, [isShowing, transparency]);

  return (
    <div className={`${styles.assetManager} ${className}`}>
      <div
        onClick={() => setIsShowing(!isShowing)}
        className={styles.headingContainer}
      >
        <span className={styles.heading}>{heading}</span>
        <img className={styles.arrorIcon} src={caretLeft} alt="" />
      </div>

      {isShowing && (
        <>
          <hr className={styles.hr} />
          <div className={styles.previewAndChangeBtn}>
            {eventBgImg && assetType === 'bgImg' && (
              <div
                className={`${styles.previewContainer} ${
                  assetType === 'bgImg' && styles.bgPreview
                } `}
              >
                <img className={styles.preview} src={eventBgImg || ''} alt="" />
              </div>
            )}
            {overviewEventLogo && assetType === 'logo' && overviewEventLogo && (
              <div
                className={`${styles.previewContainer} ${
                  assetType === 'logo' && styles.logo
                }`}
              >
                <img
                  className={`${styles.preview} `}
                  src={overviewEventLogo || ''}
                  alt=""
                />
              </div>
            )}

            <div className={styles.fileUploadBtnContainer}>
              {assetType === 'bgImg' && (
                <>
                  <Button
                    className={styles.changeImgBtn}
                    variant={ButtonVariants.SmallStd}
                    btnName={
                      eventBgImg
                        ? templateTexts.changeImage
                        : templateTexts.addImage
                    }
                  />
                  <input
                    className={styles.fileInput}
                    type="file"
                    onChange={onChange}
                  />
                </>
              )}
              {assetType === 'logo' && (
                <>
                  <Button
                    className={styles.changeImgBtn}
                    variant={ButtonVariants.SmallStd}
                    btnName={
                      overviewEventLogo
                        ? templateTexts.changeImage
                        : templateTexts.addImage
                    }
                  />
                  <input
                    className={styles.fileInput}
                    type="file"
                    onChange={onChange}
                  />
                </>
              )}
            </div>
          </div>
          <hr className={styles.hr} />
          <div>
            <Overlay />
          </div>
        </>
      )}
    </div>
  );
}

AssetManager.defaultProps = {
  className: '',
};

export default AssetManager;

import React, { useEffect } from 'react';
// import Radio from 'components/radiobutton/radioButton';
import FolderLevelOne from 'modules/user/components/team-members-permissions/components/folder-hierarachy-one/folderlevelOne';
// import Accordion from './accordion/accordion';
import { iPermissionsData } from 'types';
import { SpecificPermissionsVariants } from './variants';

import styles from './specificPermission.module.scss';

interface iProps {
  className?: string;
  variant: SpecificPermissionsVariants;
  selectedFolder: any[];
  setSelectedFolder?: (data: any) => void;
  folderData: any;
  permisssions: iPermissionsData;
  isNotDisabled?: boolean;
}

function SpecificPermissions(props: iProps): JSX.Element {
  const {
    className,
    variant,
    folderData = [],
    setSelectedFolder,
    selectedFolder,
    permisssions,
    isNotDisabled,
  } = props;

  useEffect(() => {
    // console.log(selectedFolder, 'selectedFolders');
  }, [setSelectedFolder, selectedFolder]);

  const description = `Currently assigned to the following 
  ${
    (variant === SpecificPermissionsVariants.ScreensAccess && 'screens') ||
    (variant === SpecificPermissionsVariants.ContentAccess && 'folders') ||
    (variant === SpecificPermissionsVariants.PlaylistAccess && 'playlists')
  }.`;

  return (
    <div
      className={`${styles.permissions} ${className} ${
        !isNotDisabled && styles.disabled
      }`}
    >
      <div className={styles.sharablesList}>
        <div className={styles.listHeading}>
          <p>{description}</p>
          <p>Access Status</p>
        </div>
        <div className={styles.container}>
          {folderData.length === 0 && (
            <div
              className={styles.feature}
              onClick={() => {
                // setContentType(subPerm.key);
              }}
            >
              No Folders
            </div>
          )}
          {Array.isArray(folderData) &&
            folderData &&
            folderData.map((data) => (
              <FolderLevelOne
                isNotDisabled={isNotDisabled}
                selectedFolder={selectedFolder}
                setSelectedFolder={setSelectedFolder}
                folderData={data}
                permisssions={permisssions}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

SpecificPermissions.defaultProps = {
  className: '',
  isNotDisabled: false,
  setSelectedFolder: () => {},
};

export { SpecificPermissionsVariants };
export default SpecificPermissions;

export const teamMembersTableColumns = [
  {
    Header: 'Name',
    accessor: 'user',
  },
  {
    Header: 'Permission',
    accessor: 'permission',
  },
  {
    Header: 'Last Active',
    accessor: 'lastActiveTime',
  },
  {
    Header: '2fa',
    accessor: 'fA',
  },
  {
    Header: '',
    accessor: 'more',
  },
];

export const teamMembersTempTableData = [
  {
    id: '1',
    name: { name: 'Rune Solberg', email: 'rune@screenz.no' },
    permission: 'Full Access',
    lastActive: '01.01.2017 12:34',
    fA: '',
  },
  {
    id: '2',
    name: { name: 'Frode Ingebretsen', email: 'frode@screenz.no' },
    permission: 'Full Access',
    lastActive: '01.01.2017 12:34',
    fA: '',
  },
  {
    id: '3',
    name: { name: 'Leif Pedersen', email: 'otra@screenz.no' },
    permission: 'Full Access',
    lastActive: '01.01.2017 12:34',
    fA: '',
  },
];

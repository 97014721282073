import React, { useState, useEffect } from 'react';
import styles from './switch.module.scss';

interface iProps {
  className?: string;
  value: boolean;
  onChange: (a: boolean) => void;
}

function Switch(props: iProps): JSX.Element {
  const { className, value, onChange } = props;
  const [isOn, setOn] = useState<boolean>(false);

  useEffect(() => {
    setOn(value);
  }, [value]);

  return (
    <div
      className={`${styles.switch} ${isOn && styles.on} ${className}`}
      onClick={() => {
        onChange(!isOn);
        setOn(!isOn);
      }}
    >
      <div className={styles.button} />
    </div>
  );
}

Switch.defaultProps = {
  className: '',
};

export default Switch;

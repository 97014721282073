import React, { useEffect, useState, useCallback } from 'react';
import Logo from 'components/logo/logo';
import { iCompanyData, iState } from 'types';
import { chooseCompanies, getPermissions } from 'apis';
import { useStore } from 'store';
import { useNavigate, useLocation } from 'react-router-dom';
import { homePageActions, layoutActions, userActions } from 'store/actions';
import { LayoutViews, PermissionFor } from 'enums';
import { arrowRightIcon, ROUTES, ROLES } from 'constants/index';
import { uuid } from 'helpers';
import Pattern from '../components/pattern/pattern';
import styles from './chooseClient.module.scss';

interface iProps {
  className?: string;
}

function ChooseClient(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const [companies, setCompanies] = useState<Array<iCompanyData>>([]);
  const { isLoggedIn } = state.user || {};
  const { roleId, id = '' } = state.user.loggedInData || {};
  const navigate = useNavigate();
  const { state: navigateState }: any = useLocation();

  useEffect(() => {
    if (!isLoggedIn) navigate(ROUTES.login);
  }, [isLoggedIn, navigate]);

  const { userId } = navigateState || {};
  useEffect(() => {
    dispatch(userActions.setClientChoosed(false));
  }, []);

  // const fetchPermissions = useCallback(async (response: iCompanyData) => {
  //   const permissionsResponse = await getPermissions(
  //     {
  //       permissionFor: PermissionFor.User,
  //       rollOrCompanyUserId: response.companyUserId || '',
  //     },
  //     () => {}
  //   );
  //   if (permissionsResponse) {
  //     navigate(ROUTES.screenz, { replace: true });
  //     const { id: role } = response?.role || {};
  //     localStorage.setItem('isLoggedIn', 'true');
  //     if (role) dispatch(userActions.changeUserRole(role));
  //     dispatch(userActions.setUserPermissions(permissionsResponse));
  //     console.log(permissionsResponse, 'responsechooseCompanies');
  //   }
  // }, []);

  const fetchPermissions = useCallback(
    async (companyData) => {
      const permissionsResponse = await getPermissions(
        {
          permissionFor: PermissionFor.User,
          rollOrCompanyUserId: companyData.companyUserId,
        },
        () => {}
      );
      if (permissionsResponse) {
        dispatch(userActions.setUserPermissions(permissionsResponse));
        console.log(permissionsResponse, 'permissionsResponse');
      }
    },
    [dispatch]
  );

  useEffect(() => {
    (async () => {
      //  console.log(id, 'id');
      const response = await chooseCompanies(id || userId);

      if (response) {
        if (Number(roleId) === ROLES.super_admin && response.length === 0) {
          dispatch(userActions.setClientChoosed(true));
          navigate(ROUTES.home, { replace: true });
          return;
        }
        if (response.length === 0 && Number(roleId) !== ROLES.super_admin) {
          // dispatch(homePageActions.setCompanyData(response[0]));
          // dispatch(userActions.setClientChoosed(true));
          // const { id: role } = response[0]?.role || {};
          // if (role) dispatch(userActions.changeUserRole(role));
          // navigate(ROUTES.home, { replace: true });
          return;
          // fetchPermissions(response[0]);
          // navigate(ROUTES.home, { replace: true });
        }
        setCompanies(response);
      }
    })();
  }, [id, roleId, userId, navigate, dispatch]);

  const chooseClientHandler = useCallback(
    (companyData: iCompanyData) => {
      console.log(companyData, 'test');
      dispatch(homePageActions.setCompanyData(companyData));
      dispatch(layoutActions.setHomePageView(LayoutViews.Screens));
      const { role } = companyData || {};
      const { id: roleIds } = role || {};
      if (roleIds) dispatch(userActions.changeUserRole(roleIds));
      navigate(ROUTES.screenz, { replace: true });
      dispatch(userActions.setClientChoosed(true));
      localStorage.setItem('isLoggedIn', 'true');
      // fetchPermissions(companyData);
      // dispatch(userActions.setClientChoosed(true));

      // dispatch(userActions.setClientChoosed(true));
      fetchPermissions(companyData);
    },
    [dispatch, navigate]
  );

  const continueAsSuperAdmin = useCallback(() => {
    dispatch(layoutActions.setHomePageView(LayoutViews.Screens));
    navigate(ROUTES.home, { replace: true });
    dispatch(userActions.setClientChoosed(true));
    localStorage.setItem('isLoggedIn', 'true');
  }, [dispatch, navigate]);

  return Array.isArray(companies) && companies.length > 0 ? (
    <div className={`${styles.chooseClient} ${className}`}>
      <Pattern position="top" />
      <Pattern position="bottom" />
      <section className={`${styles.section}`}>
        <div className={styles.chooseClientContainer}>
          <Logo className={styles.logo} variant="onboarding" />
          <p className={styles.chooseClientHeading}>Log into existing client</p>
          <p className={styles.chooseClientDescription}>
            You’re already a member of the following clients.
          </p>

          <div className={styles.clientsList}>
            {companies &&
              companies.map((client) => {
                const date =
                  (client.lastActiveTime && new Date(client.lastActiveTime)) ||
                  '';
                return (
                  <div
                    key={uuid()}
                    className={styles.client}
                    onClick={() => chooseClientHandler(client)}
                  >
                    <Logo className={styles.logo} variant="onboarding" />
                    <div className={styles.details}>
                      <p className={styles.name}>{client.name}</p>
                      <p className={styles.lastLogin}>
                        Last login
                        {date &&
                          ` ${date.getDate()}.${
                            date.getMonth() + 1
                          }.${date.getFullYear()} ${date.getHours()}.${date.getMinutes()} `}
                      </p>
                    </div>
                    <img className={styles.arrow} src={arrowRightIcon} alt="" />
                  </div>
                );
              })}
          </div>
          {Number(roleId) === ROLES.super_admin && (
            <p
              className={styles.continueAsSuperUserAdmin}
              onClick={continueAsSuperAdmin}
            >
              Conitnue as Superuser admin
            </p>
          )}
        </div>
      </section>
    </div>
  ) : (
    <div />
  );
}

ChooseClient.defaultProps = {
  className: '',
};

export default ChooseClient;

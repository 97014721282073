import React, { useCallback, useState, useEffect } from 'react';
import {
  calendarIcon,
  closeIcon,
  // englishIcon,
  informationIcon,
} from 'constants/index';
import TemplateIcon from 'components/svg/template';
import { toaster, uuid } from 'helpers';
import { useStore } from 'store';
import { getContent, getRooms, MsGraphApis } from 'apis';
import { CalenderSettingsView, EventContentType, LanguageType } from 'enums';
import { iState, iOffice365DropdownOption, iCalenderEvent } from 'types';
import { meetingOverviewFullActions, office365Actions } from 'store/actions';
import AssetManager from './asset-manager/assetManager';
import NameEditor from './name-editor/nameEditor';
import {
  CONTEN_AREA_OPTIONS,
  DEFAULT,
  CONTEN_TYPE_OPTIONS,
  LANGUAGE_OPTIONS,
} from './constants';
import { TIME_ZONES } from './timeZones';
import Toggler from './toggler/toggler';
import SettingsDropdown from './settings-dropdown/settingsDropdown';
import styles from './templateSettingsBar.module.scss';
import englishData from '../../../english.json';
import norwayData from '../../../norwegian.json';

interface iProps {
  className?: string;
}

function TemplateSettingsBar(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const [timezones, setTimeZones] = useState<Array<iOffice365DropdownOption>>(
    []
  );
  const { companyData } = state.homePage || {};
  const [rooms, setRooms] = useState<any>([]);
  const [templateTexts, setTemplateTexts] = useState<any>(englishData);

  const {
    currentView,
    contentAreaContentOption,
    contentSettingsContentOption,
    templateIdToEdit,
    selectedLanguage,
  } = state.office365 || {};

  const {
    isShowSubject,
    // isShowActiveBooking,
    // isShowHostName,
    isShowNextMeeting,
    timeZone,
    roomChoosed,
    eventBgImg = '',
    eventLogo = '',
    customName,
    templateName = '',
  } = state.office365.eventInfo || {};
  const { ContentSetting, TemplateSetting, ContentArea } = CalenderSettingsView;

  useEffect(() => {
    if (selectedLanguage.value === LanguageType.English) {
      setTemplateTexts(englishData);
    }
    if (selectedLanguage.value === LanguageType.Norwegian) {
      setTemplateTexts(norwayData);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    const zones = TIME_ZONES.map((i) => ({
      id: uuid(),
      option: i.text,
      icon: '',
      value: i.utc[0],
    }));
    zones.unshift({
      id: uuid(),
      option: 'Empty',
      icon: informationIcon,
      value: EventContentType.Empty,
    });
    setTimeZones(zones);
  }, []);

  const {
    setIsShowSubject,
    // setIsShowHostName,
    setIsShowNextMeeting,
    // setIsShowactivateBooking,
  } = office365Actions;

  const locationBasedEvents = useCallback(
    (allEvents: iCalenderEvent[]) => {
      const { value } = timeZone || {};
      return allEvents.filter((event) => event.originalStartTimeZone === value);
    },
    [timeZone]
  );

  const callEvents = useCallback(
    async (room) => {
      try {
        const apiResponse = await MsGraphApis.callEventsOnRoom([room.id]);

        const events = await apiResponse[0].events;
        dispatch(office365Actions.setAllCalenderEvents([]));
        dispatch(office365Actions.setCurrentCalenderEvents(null));
        dispatch(office365Actions.setNextCurrentCalenderEvents(null));
        if (events.length === 0) {
          dispatch(office365Actions.setAllCalenderEvents([]));
          dispatch(office365Actions.setCurrentCalenderEvents(null));
          dispatch(office365Actions.setNextCurrentCalenderEvents(null));
        }
        if (timeZone && timeZone.value === EventContentType.Empty) {
          dispatch(office365Actions.setCurrentView(ContentArea));
          return;
        }

        if (events) {
          let filteredEvents: iCalenderEvent[] = events;

          filteredEvents = filteredEvents.filter((event) => {
            const startDate = new Date(`${event.start.dateTime}Z`);
            const endDate = new Date(`${event.end.dateTime}Z`);
            const currentdate = new Date();
            return (
              startDate.getDate() === currentdate.getDate() &&
              endDate >= currentdate
            );
          });

          //  console.log(`sachin testing `, filteredEvents[0]);

          dispatch(office365Actions.setAllCalenderEvents(filteredEvents || []));
          if (filteredEvents.length > 0) {
            const onGoingEvent = filteredEvents.filter((event) => {
              const startDate = new Date(`${event.start.dateTime}Z`);
              const endDate = new Date(`${event.end.dateTime}Z`);
              const currentdate = new Date();
              return (
                startDate.getTime() <= currentdate.getTime() &&
                endDate.getTime() >= currentdate.getTime()
              );
            });
            if (onGoingEvent.length > 0) {
              dispatch(
                office365Actions.setCurrentCalenderEvents(onGoingEvent[0])
              );
              if (filteredEvents.length >= 2) {
                dispatch(
                  office365Actions.setNextCurrentCalenderEvents(
                    filteredEvents[1]
                  )
                );
              }
              return;
            }
            dispatch(office365Actions.setCurrentCalenderEvents(null));

            dispatch(
              office365Actions.setNextCurrentCalenderEvents(filteredEvents[0])
            );
          }
          if (filteredEvents.length === 0) {
            dispatch(office365Actions.setAllCalenderEvents([]));
            dispatch(office365Actions.setCurrentCalenderEvents(null));
            dispatch(office365Actions.setNextCurrentCalenderEvents(null));
          }
        }
      } catch (error) {
        //  console.log(`Error`, error);

        dispatch(office365Actions.setAllCalenderEvents([]));
        dispatch(office365Actions.setCurrentCalenderEvents(null));
        dispatch(office365Actions.setNextCurrentCalenderEvents(null));
      }
    },
    [dispatch, locationBasedEvents, ContentArea, timeZone]
  );
  const isContentSettings = currentView === ContentSetting;
  const isContentArea = currentView === ContentArea;
  const isTemplatesSettings = currentView === TemplateSetting;

  useEffect(() => {
    (async () => {
      if (!companyData) return;
      // if (isContentSettings) {
      const roomsResponse = await getRooms({
        company: companyData.id || '',
      });
      if (roomsResponse) {
        // //  console.log('roomsAtBeginning', roomsResponse);
        setRooms(roomsResponse);
        // }
      }
    })();
  }, [templateIdToEdit, rooms]);

  const getTemplate = useCallback(async () => {
    if (!templateIdToEdit) {
      return;
    }
    const response = await getContent(templateIdToEdit);
    if (response?.templateData) {
      // const { details, room, roomName, templateType } =
      //   response.templateData || {};

      // const {
      //   backgroundImageUrl,
      //   logoUrl,
      //   showHostName,
      //   ActivateBooking,
      //   showNextMeeting,
      //   showSubject,
      //   timezone,
      // } = details || {};
      const { details, contents, templateType } = response.templateData || {};

      const { backgroundImageUrl, logoUrl, ActivateBooking } = details || {};
      const { room, details: contentsDetails } = contents[0] || {};
      const { showHostName, showNextMeeting, showSubject, timezone } =
        contentsDetails || {};
      dispatch(office365Actions.setTempalteDataToUpdate(response));
      if (response.name) {
        dispatch(office365Actions.setTemplateName(response.name || ''));
      }
      if (templateType) {
        dispatch(office365Actions.setChosenTemplateType(templateType || ' '));
      }

      if (!eventBgImg)
        dispatch(office365Actions.setEventBgImg(backgroundImageUrl || ''));
      if (!eventLogo) dispatch(office365Actions.setEventLogo(logoUrl || ''));
      const { custom_name } = room || {};
      dispatch(office365Actions.setIsShowSubject(showSubject));
      dispatch(office365Actions.setIsShowHostName(showHostName));
      dispatch(office365Actions.setIsShowNextMeeting(showNextMeeting));
      dispatch(office365Actions.setIsShowactivateBooking(ActivateBooking));
      dispatch(office365Actions.updateCustomName(custom_name || ''));

      // dispatch(office365Actions.updateCustomName(''));

      const choosedRoom = rooms.find((i) => i.id === room?.id);

      if (choosedRoom) {
        dispatch(office365Actions.setChoosedRoom(choosedRoom));
        callEvents(choosedRoom);
      }

      const contentTypeOption = CONTEN_TYPE_OPTIONS.find(
        (i) => i.value === EventContentType.Calender
      );
      if (contentTypeOption)
        dispatch(
          office365Actions.setContentSettingsContentOption(contentTypeOption)
        );
      const contentAreaContent = CONTEN_AREA_OPTIONS.find(
        (i) => i.value === EventContentType.Clock
      );
      if (contentAreaContent)
        dispatch(
          office365Actions.setContentAreaContentOption(contentAreaContent)
        );

      const timeZoneOption = timezones.find((i) => i.value === timezone);
      if (timeZoneOption) {
        dispatch(
          office365Actions.setTimeZoneOption({
            id: uuid(),
            option: timeZoneOption.option,
            value: timeZoneOption.value,
            icon: '',
          })
        );
      }
      if (response.templateData.opacity) {
        dispatch(
          meetingOverviewFullActions.setTransparency(
            response.templateData.opacity.toString()
          )
        );
      }
    }
  }, [templateIdToEdit, dispatch, rooms, callEvents]);

  useEffect(() => {
    if (templateIdToEdit) {
      getTemplate();
    }
  }, [templateIdToEdit, rooms]);

  const onChangeView = useCallback(() => {
    if (isTemplatesSettings)
      dispatch(office365Actions.setCurrentView(ContentSetting));
    if (isContentArea && timeZone?.value === EventContentType.Empty) {
      toaster.error('Please choose time zone');
      return;
    }
    if (isContentArea && timeZone) {
      dispatch(office365Actions.setCurrentView(TemplateSetting));
    }
    if (isContentSettings) {
      dispatch(office365Actions.setCurrentView(TemplateSetting));
      // dispatch(office365Actions.setChoosedRoom(null));
      // setRooms([]);
    }
  }, [
    dispatch,
    timeZone,
    ContentSetting,
    ContentArea,
    isContentArea,
    isTemplatesSettings,
    isContentSettings,
  ]);

  const onChangeDropdown = useCallback(
    (option: iOffice365DropdownOption) => {
      if (isContentArea) {
        if (option.value === EventContentType.Empty)
          dispatch(office365Actions.setContentAreaContentOption(option));
        if (option.value === EventContentType.Clock)
          dispatch(office365Actions.setContentAreaContentOption(option));
      }

      if (isContentSettings) {
        if (option.value === EventContentType.Empty)
          dispatch(office365Actions.setContentSettingsContentOption(option));
        if (option.value === EventContentType.Calender)
          dispatch(office365Actions.setContentSettingsContentOption(option));
      }
    },
    [dispatch, isContentSettings, isContentArea]
  );

  const onChangeTimeZone = useCallback(
    (option: iOffice365DropdownOption) => {
      dispatch(office365Actions.setTimeZoneOption(option));
    },
    [dispatch, ContentSetting, isContentArea]
  );

  const onClickRoom = useCallback(
    (option: iOffice365DropdownOption) => {
      const roomData = rooms.find((i) => i.id === option.id);
      if (roomData) {
        dispatch(office365Actions.updateCustomName(roomData.custom_name));
        dispatch(office365Actions.setChoosedRoom(roomData));
        callEvents(roomData);
      }
    },
    [dispatch, rooms, callEvents]
  );

  const onClickLanguage = useCallback(
    (option: iOffice365DropdownOption) => {
      dispatch(office365Actions.setSelectedLanguage(option));
    },
    [dispatch, state]
  );

  const isShowChooseTimeZone =
    contentAreaContentOption.option === EventContentType.Clock;

  useEffect(() => {
    console.log(selectedLanguage, 'selected Language');
  }, [selectedLanguage]);

  return (
    <div className={`${styles.templateSettingsBar} ${className}`}>
      <div className={styles.headingContainer}>
        {(isTemplatesSettings || isContentArea) && (
          <TemplateIcon className={styles.templatesIcon} />
        )}
        {isContentSettings && (
          <img className={styles.icon} src={calendarIcon} alt="" />
        )}

        <p className={styles.heading}>
          {(isContentArea && templateTexts.contentArea) ||
            (isContentSettings && templateTexts.contentSettings) ||
            (isTemplatesSettings && templateTexts.templateSettings)}{' '}
        </p>
        <img
          className={styles.closeIcon}
          src={closeIcon}
          alt=""
          onClick={onChangeView}
        />
      </div>
      <hr className={styles.hr} />
      {isTemplatesSettings && (
        <>
          <NameEditor
            label={templateTexts.templateName}
            value={templateName}
            onChange={(event) => {
              dispatch(
                office365Actions.setTemplateName(event.target.value || '')
              );
            }}
          />
          <hr className={styles.hr} />
          <AssetManager heading={templateTexts.background} assetType="bgImg" />
          <hr className={styles.hr} />
          <AssetManager heading={templateTexts.logo} assetType="logo" />
          <hr className={styles.hr} />
          <SettingsDropdown
            heading={templateTexts.language}
            options={LANGUAGE_OPTIONS}
            choosedOption={
              selectedLanguage
              // || {
              //   id: uuid(),
              //   option: 'English',
              //   optionNorway: 'English',
              //   icon: englishIcon,
              //   value: LanguageType.English,
              // }
            }
            onChange={onClickLanguage}
          />
        </>
      )}

      {isContentArea && (
        <>
          <SettingsDropdown
            heading={templateTexts.contentType}
            options={CONTEN_AREA_OPTIONS}
            choosedOption={contentAreaContentOption}
            onChange={onChangeDropdown}
          />
          <hr className={styles.hr} />
          {isShowChooseTimeZone && (
            <SettingsDropdown
              heading={templateTexts.setTimezoneType}
              options={timezones}
              choosedOption={
                (timeZone && {
                  id: timeZone?.id,
                  value: timeZone?.value,
                  option: timeZone?.option,
                  icon: '',
                }) ||
                DEFAULT
              }
              onChange={onChangeTimeZone}
              timeZoneDropdown
            />
          )}
        </>
      )}

      {/* Content Settings */}
      {isContentSettings && (
        <>
          <SettingsDropdown
            heading={templateTexts.contentType}
            options={CONTEN_TYPE_OPTIONS.filter(
              (item) =>
                item.value === EventContentType.Calender ||
                item.value === EventContentType.Empty
            )}
            choosedOption={contentSettingsContentOption}
            onChange={onChangeDropdown}
          />
          <hr className={styles.hr} />
          <SettingsDropdown
            heading={templateTexts.setCalendar}
            isShowMSIcon
            options={
              (rooms &&
                rooms.length > 0 &&
                rooms.map((room) => ({
                  id: room.id,
                  option: room.name,
                  optionNorway: room.name,
                  value: room.name,
                  icon: '',
                }))) || [DEFAULT]
            }
            choosedOption={
              (roomChoosed && {
                id: roomChoosed?.id,
                value: roomChoosed?.name,
                option: roomChoosed?.name,
                optionNorway: roomChoosed?.name,
                icon: '',
              }) ||
              DEFAULT
            }
            onChange={onClickRoom}
          />
          <hr className={styles.hrTwo} />
          <NameEditor
            label={templateTexts.setCustomName}
            value={customName || ''}
            onChange={(event) => {
              dispatch(office365Actions.updateCustomName(event.target.value));
              dispatch(office365Actions.setupdateRoomCustomName(true));
            }}
          />
          <hr className={styles.hrTwo} />
          <div className={styles.toggleSettingsContainer}>
            <p className={styles.heading}>
              {templateTexts.settingsForResource}
            </p>
            <Toggler
              label={templateTexts.showSubject}
              value={isShowSubject || false}
              onChange={(isOn) => dispatch(setIsShowSubject(isOn))}
            />
            {/* <Toggler
              label={templateTexts.showHostname}
              value={isShowHostName || false}
              onChange={(isOn) => dispatch(setIsShowHostName(isOn))}
            /> */}
            <Toggler
              label={templateTexts.showNextMeeting}
              value={isShowNextMeeting || false}
              onChange={(isOn) => dispatch(setIsShowNextMeeting(isOn))}
            />
            {/* <Toggler
              label={templateTexts.activateBooking}
              value={isShowActiveBooking || false}
              onChange={(isOn) => dispatch(setIsShowactivateBooking(isOn))}
            /> */}
          </div>
        </>
      )}
      <hr className={styles.hr} />
    </div>
  );
}

TemplateSettingsBar.defaultProps = {
  className: '',
};

export default TemplateSettingsBar;

import Input from 'components/input/input';
import { cancelIcon, searchIcon } from 'constants/index';
import { UserRoles } from 'enums';
import { currentPage } from 'helpers';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useStore } from 'store';
import {
  contentPageActions,
  homePageActions,
  playlistPageActions,
} from 'store/actions';
import { iState } from 'types';
import styles from './search.module.scss';

interface iProps {
  className?: string;
  isOpenState?: boolean;
  isShowClear?: boolean;
  placeholder?: string;
  onChange?: (type: string) => void;
  setKeyword?: (type: string) => void;
  searchKeyword?: string;
  rolebasedHeader?: boolean;
}

let timer: any; // Timer identifier
function Search(props: iProps): JSX.Element {
  const {
    className,
    placeholder,
    isOpenState,
    isShowClear,
    onChange,
    setKeyword,
    searchKeyword,
    rolebasedHeader,
  } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const [searchKey, setSearchKey] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(isOpenState || false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const containerRef: any = useRef(null);
  const { role } = state.user || {};
  const { isShowingClientInstance } = state.layout || {};
  const waitTime = 500;

  const { isScreensPage, isContentsPage, isPlaylistPage, isHomePage } =
    currentPage();
  const { SuperAdmin, Client, Admin, Owner } = UserRoles;

  const onKeyUp = (e: any): void => {
    const text = e.currentTarget.value;
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (isContentsPage && !rolebasedHeader)
        dispatch(contentPageActions.setSearchContentKey(text));

      if (isPlaylistPage && !rolebasedHeader)
        dispatch(playlistPageActions.setSearchPlaylistKey(text));

      if (isScreensPage) {
        if (
          role === Client ||
          role === Admin ||
          role === Owner ||
          (role === SuperAdmin && isShowingClientInstance && !rolebasedHeader)
        )
          dispatch(homePageActions.setSearchScreenKey(text));
      }
      if (isHomePage) {
        if (role === SuperAdmin && !isShowingClientInstance && !rolebasedHeader)
          dispatch(homePageActions.setSearchCompanyKey(text));
      }
      if (onChange) onChange(text);
    }, waitTime);
  };

  useEffect(() => {
    if (!searchKeyword) setSearchKey('');
  }, [searchKeyword]);

  const onSearch = useCallback((event): void => {
    setSearchKey(event.target.value);
    if (setKeyword) setKeyword(event.target.value);
  }, []);

  const onClear = useCallback((): void => {
    setSearchKey('');
    // setKeyword('');
    dispatch(contentPageActions.setSearchContentKey(''));
    dispatch(homePageActions.setSearchCompanyKey(''));
    dispatch(homePageActions.setSearchScreenKey(''));
    dispatch(playlistPageActions.setSearchPlaylistKey(''));
  }, [dispatch]);

  const clickOutSide = useCallback(
    (event: MouseEvent) => {
      if (isOpenState) {
        if (isFocused) setIsFocused(false);
      } else if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        if (isOpen && !searchKey) setIsOpen(false);
        if (isFocused && !searchKey) setIsFocused(false);
        if (!searchKey) {
          onClear();
        }
      }
    },
    [isOpenState, isOpen, isFocused, onClear, searchKey]
  );

  const { windowWidth } = state.layout || {};

  useEffect(() => {
    if (windowWidth > 800 && !isOpenState) {
      setIsOpen(false);
      setIsFocused(false);
    }
  }, [state.layout]);

  useEffect(() => {
    onClear();
  }, [isShowingClientInstance]);

  useEffect(() => {
    document.addEventListener('click', clickOutSide);
    return () => document.removeEventListener('click', clickOutSide);
  }, [clickOutSide, searchKey]);

  const onClick = useCallback(() => {
    const inputElement = document.getElementById('searchInput');
    if (inputElement) inputElement.focus();
    setIsOpen(true);
  }, []);

  useEffect(() => {
    if (rolebasedHeader) {
      onClick();
    }
  }, [rolebasedHeader]);

  return (
    <div
      className={`${className} ${styles.search} ${isOpen && styles.open} ${
        isFocused && styles.focused
      }`}
      ref={containerRef}
    >
      <img
        className={`${styles.searchIcon} ${isOpen && styles.open}`}
        src={searchIcon}
        alt=""
        onClick={onClick}
      />
      <Input
        id="searchInput"
        className={`${styles.input} ${isOpen && styles.open}`}
        value={searchKey}
        placeholder={placeholder || 'Search'}
        onChange={onSearch}
        onFocus={() => setIsFocused(true)}
        onKeyUp={onKeyUp}
      />
      {isShowClear && (
        <img
          className={styles.cancelIcon}
          src={cancelIcon}
          alt=""
          onClick={onClear}
        />
      )}
    </div>
  );
}

Search.defaultProps = {
  className: '',
  isOpenState: false,
  isShowClear: false,
  placeholder: '',
  onChange: () => {},
  setKeyword: () => {},
  searchKeyword: '',
  rolebasedHeader: false,
};

export default Search;

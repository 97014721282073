import React from 'react';
import Input from 'components/input/input';
import styles from './search.module.scss';

function Search({ icon, ...props }): JSX.Element {
  return (
    <div className={`${props.className} ${styles.titleContainer}`}>
      <p className={styles.title}>Office 365</p>
      <div className={styles.search}>
        <img src={icon} alt="" className={styles.searchIcon} />
        <Input value={props.value} {...props} className={styles.inputField} />
      </div>
    </div>
  );
}

export default Search;

export const validators = {
  isValidPhoneNumber: (number) => {
    return number.toString()?.length === 10;
  },
  isEmail: (email: string) => {
    const VALID_EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return VALID_EMAIL_REGEX.test(email);
  },
  isValidCountryCode: (countryCod: string) => {
    return countryCod === '+91' || countryCod === '+1';
  },
  isValidEnum: (Enum, value) => {
    return value.toUpperCase() in Enum;
  },
  validatePassword: (password: string): boolean => {
    // eslint-disable-next-line
    const regex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[- ~`!@#$%^&*+=:;,.?])(?=.{6,30}$)'
    );
    // const regex = new RegExp(
    //   '^(?=.[a-z])(?=.[A-Z])(?=.[0-9])(?=.*[~`!@#$%^&*+=:;,.?-])(?=.{6,30}$)'
    // );
    return regex.test(password);
    // let isContainerUpper
    // if (!password) return false;
    // if (password.length < 8) return false;
    // if()
    // return false;
  },
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import Layout, { LayoutVariants } from 'components/Layouts/layout';
import Search from 'components/searchInput/searchInput';
import { getCompaniesWithFeatures } from 'apis';
// import Button, { ButtonVariants } from 'components/button/button';
import CheckboxContainer from './components/checkbox-container/checkboxContainer';

import styles from './features.module.scss';

interface iProps {
  className?: string;
}

function Features(props: iProps): JSX.Element {
  const { className } = props;
  const [searchKey, setsearchKey] = useState('');
  // return <div className={`${styles.roleWrapper} ${className}`}>Roles</div>;
  const containerRef: any = useRef(null);
  // const [checked, setChecked] = useState(false);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    document.title = 'Features';
  }, []);

  const getCompaniesFeatures = useCallback(async () => {
    const response = await getCompaniesWithFeatures(
      {
        limit: 0,
        skip: 0,
        searchQuery: searchKey,
      },
      () => {}
    );
    if (response) {
      const responseData = response.map((item) => {
        return {
          id: item.id,
          name: item.name,
          type: 'Direct Client',
          hasAccessManagementPermission:
            item.features?.accessManagment || false,
        };
      });
      setData(responseData);
    }
  }, [searchKey]);

  useEffect(() => {
    getCompaniesFeatures();
  }, [searchKey]);

  return (
    <Layout variant={LayoutVariants.layoutFour}>
      <div className={`${styles.componentContainer} ${className}`}>
        <div className={styles.searchContainer}>
          <Search
            setSearchKey={setsearchKey}
            searchKey={searchKey}
            className={styles.searchField}
          />
          {/* <Button
            className={styles.saveChanges}
            btnName="Save changes"
            variant={ButtonVariants.SmallStd}
            onClick={() => {}}
          /> */}
        </div>
        <div className={styles.listContainer} ref={containerRef}>
          <div className={`${styles.listHeader} ${styles.firstHeader}`}>
            <p>Client</p>
            <div className={styles.horizontalLine} />
            <p>Access Management</p>
            <p> Future Features</p>
            <p>Future Features</p>
            <p>Future Features</p>
            <p>Future Features</p>
            <p>Future Features</p>
            <p>Future Features</p>
            <p>Future Features</p>
            <p>Future Features</p>
          </div>
          <div className={styles.clientListContainer}>
            {data.map((item) => {
              return (
                <CheckboxContainer data={data} item={item} setData={setData} />
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
}

Features.defaultProps = {
  className: '',
};

export default Features;

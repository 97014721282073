import { deleteMSAccount } from 'apis';
import o365badge from 'assets/images/o365badge.png';
import RunningStatus from 'components/assign-content-modal/running-state/runningState';
import DeleteModal, {
  DeleteModalVariants,
} from 'components/delete-modal/deleteModal';
import Popover from 'components/popover/popover';
import More from 'components/svg/more';
import { dustbinIcon, editIcon } from 'constants/index';
import { ModalViews } from 'enums';
// import { ModalViews } from 'enums';
import { toaster } from 'helpers';
import React, { useState } from 'react';
import { useStore } from 'store';
import { layoutActions } from 'store/actions';
import { iState } from 'types';
// import { useStore } from 'store';
// import { layoutActions } from 'store/actions';
// import { iState } from 'types';
import styles from './o365AccountsTable.module.scss';

interface iProps {
  msAccounts: any;
  callAccessTkn: any;
  setStoredCompanyId: any;
}

function O365AccountsTable(props: iProps): JSX.Element {
  const { msAccounts, callAccessTkn, setStoredCompanyId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch]: [iState, any] = useStore();
  const { isShowDeleteModal } = state.layout || {};
  const [deleteAccount, setDeleteAccount] = useState({
    name: '',
    id: '',
  });

  // useEffect(() => {
  //   console.log(msAccounts, 'msaccounts');
  // }, [msAccounts]);

  const getDateFormatedDate = (dateTime: string): string => {
    if (!dateTime) return '';
    const date = new Date(dateTime);
    return (
      (date &&
        `${date.getDate()}.${
          date.getMonth() + 1
        }.${date.getFullYear()} ${date.getHours()}.${date.getMinutes()}`) ||
      ''
    );
  };

  const deleteMsAccountHandler = async (delteId): Promise<void> => {
    // if (delteId) return;
    const response = await deleteMSAccount(
      {
        id: delteId,
      },
      setIsLoading
    );
    if (response) {
      toaster.success('Account is Successfully Deleted');
      dispatch(layoutActions.invokeFetchMSAccounts(true));
      dispatch(layoutActions.toggleModal());
    }
  };

  return (
    <div>
      <p className={styles.heading}>Your integrations</p>
      <div className={styles.listContainer}>
        <div className={styles.listHeader}>
          <p>Provider</p>
          <p>Account</p>
          <p>Status</p>
          <p>Added</p>
        </div>
        <div className={styles.bodyList} id="contentListingContainer">
          {!isLoading &&
            msAccounts?.map((account) => {
              return (
                <div
                  className={styles.content}
                  onClick={() => {
                    setStoredCompanyId(account.id);
                    callAccessTkn(account.id);
                  }}
                  key={account.id}
                >
                  <div className={styles.badgeContainer}>
                    <img src={o365badge} height="24px" width="24px" alt="" />
                    <span className={styles.badgeText}>Office 365</span>
                  </div>
                  <div className={styles.previewAndMeta}>
                    <div className={styles.contentNameAndLength}>
                      <p className={styles.contentName}>{account?.name}</p>
                    </div>
                  </div>
                  <p className={styles.ownerName}>
                    {' '}
                    <div>
                      <RunningStatus status={account.inUse ? 'In Use' : ''} />
                    </div>
                  </p>
                  <p className={styles.modifiedOn}>
                    {getDateFormatedDate(account?.created_at)}
                  </p>

                  <Popover
                    className={styles.morePopupContainer}
                    popoverClassName={`${styles.popover} ${
                      false && styles.last
                    }`}
                    popoverComponent={
                      <div
                        className={`${styles.morePopup} ${styles.webContentMutation}`}
                      >
                        <div
                          className={styles.option}
                          onClick={() => {
                            setStoredCompanyId(account.id);
                            callAccessTkn(account.accountDetails, account.id);
                          }}
                        >
                          <img
                            src={editIcon}
                            alt=""
                            onClick={() => {
                              setStoredCompanyId(account.id);
                              callAccessTkn(account.id);
                            }}
                            key={account.id}
                          />
                          <div
                            // className={styles.option}
                            onClick={() => {
                              setStoredCompanyId(account.id);
                              callAccessTkn(account.id);
                            }}
                            key={account.id}
                          >
                            <p>Edit</p>
                          </div>
                        </div>
                        <div
                          className={`${styles.option} ${
                            account.inUse && styles.disable
                          }`}
                          onClick={() => {
                            if (!account.inUse) {
                              setDeleteAccount({
                                id: account.id,
                                name: account.name,
                              });
                              dispatch(
                                layoutActions.toggleModal(
                                  ModalViews.isShowDeleteModal
                                )
                              );
                            }
                          }}
                        >
                          <img src={dustbinIcon} alt="" />
                          <p>Delete</p>
                        </div>
                      </div>
                    }
                  >
                    <div className={styles.moreBtn}>
                      <More className={styles.icon} />
                    </div>
                  </Popover>
                </div>
              );
            })}
          <DeleteModal
            isOpen={isShowDeleteModal}
            variant={DeleteModalVariants.MSAccount}
            name={deleteAccount.name || ''}
            deleteHandler={() => {
              deleteMsAccountHandler(deleteAccount.id);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default O365AccountsTable;

import { UserRoles } from 'enums';
import { getNewlySharedStatus } from 'helpers';
import React, { useCallback } from 'react';
import { useStore } from 'store';
// import { homePageActions } from 'store/actions';
import { iFolderData, iState } from 'types';
import Folder from './folder/folder';
import styles from './folders.module.scss';

interface iProps {
  className?: string;
  data?: iFolderData[] | null;
  variant?: 'small' | 'default';
  openFolder?: (data: iFolderData) => void;
  setChecked?: () => void;
  isHavingAllAccess?: boolean;
}

function Folders(props: iProps): JSX.Element {
  const {
    className,
    data,
    variant,
    openFolder,
    setChecked,
    isHavingAllAccess,
  } = props;
  const [state]: [iState, any] = useStore();
  const { openedFolders = [] } = state.homePage || {};
  const { loggedInData, role } = state.user || {};

  const openFolderHandler = useCallback(
    (folder) => {
      if (openFolder) openFolder(folder);
      if (setChecked) setChecked();
      // dispatch(homePageActions.setActiveFolder(folder));
    },
    [openFolder, setChecked]
  );

  const isSuperUserAdmin = role === UserRoles.SuperAdmin;
  // console.log(isHavingAllAccess, 'isHavingAllAccess');

  return (
    <div
      className={`${styles.foldersWrapper} ${
        openedFolders?.length > 0 && styles.withList
      } ${className}`}
    >
      {data &&
        data.map((i) => (
          <>
            {(isSuperUserAdmin || isHavingAllAccess) && (
              <Folder
                variant={variant}
                isActive={false}
                folderName={i.name}
                onClick={() => openFolderHandler(i)}
              />
            )}
            {!isSuperUserAdmin && !isHavingAllAccess && i.owner?.id && (
              <Folder
                className={`${
                  getNewlySharedStatus(i.sharedAt) &&
                  i.owner?.id !== loggedInData?.id &&
                  styles.recentlyShared
                }`}
                variant={variant}
                isActive={i.owner?.id !== loggedInData?.id}
                folderName={i.name}
                onClick={() => openFolderHandler(i)}
              />
            )}
            {!isSuperUserAdmin && !isHavingAllAccess && !i.owner?.id && (
              <Folder
                // className={`${
                //   // getNewlySharedStatus(i.sharedAt) &&
                //   // i.owner?.id !== loggedInData?.id &&
                //   // styles.recentlyShared
                // }`}
                variant={variant}
                isActive={false}
                folderName={i.name}
                onClick={() => openFolderHandler(i)}
              />
            )}
          </>
        ))}
    </div>
  );
}

Folders.defaultProps = {
  className: '',
  data: [],
  variant: 'default',
  openFolder: () => {},
  isHavingAllAccess: true,
  setChecked: () => {},
};

export default Folders;

import Content from 'modules/content/content';
import EmptyStates from 'modules/empty-states/emptyStates';
import ScreenDetails from 'modules/home/components/client/screen-details-view/screenDetailsView';
import Home from 'modules/home/home';
// import FileStack from 'modules/file-stack/filestack';
import JoinYourTeam from 'modules/onboarding/join-your-team/joinYourTeam';
import Login from 'modules/onboarding/login/login';
import Verify2FA from 'modules/onboarding/verify-2fa/verify2fa';
import PasswordResetInstructions from 'modules/onboarding/password-reset-instructions/passwordResetInstructions';
import ResetPassword from 'modules/onboarding/reset-password/resetPassword';
import SetNewPassword from 'modules/onboarding/set-new-password/setNewPassword';
import VerifyEmail from 'modules/onboarding/verify-email/verifyEmail';
import Roles from 'modules/roles-and-permissions/roles/roles';
import RolesDetailsView from 'modules/roles-and-permissions/role-details-view/roleDetailsView';
import PageNotFound from 'modules/page-not-found/pageNotFound';
import AddWebContent from 'modules/content/components/add-web-content/addWebContent';
import PlaylistView from 'modules/playlists/playlist-view/playlistView';
import Playlists from 'modules/playlists/playlists';
import UserSettings from 'modules/user/settings';
import ChooseTeam from 'modules/onboarding/choose-client/chooseClient';
import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Office365Auth from 'modules/office-365/office365-auth/office365Auth';
import CalenderEvents from 'modules/office-365/calender-events/calenderEvents';
import Features from 'modules/features/features';
import { ROUTES } from '../constants/index';
import ProtectedRoute from './protected-route';

class AppRoutes extends Component {
  render(): JSX.Element {
    return (
      <BrowserRouter>
        {/* <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div> */}
        <Routes>
          <Route path={ROUTES.login} element={<Login />} />
          <Route path={ROUTES.joinYourTeam} element={<JoinYourTeam />} />
          <Route path={ROUTES.forgotPassword} element={<ResetPassword />} />
          <Route path={ROUTES.verifyEmail} element={<VerifyEmail />} />
          <Route path={ROUTES.chooseTeam} element={<ChooseTeam />} />
          <Route path={ROUTES.verify2fa} element={<Verify2FA />} />

          <Route
            path={ROUTES.passwordResetInstructions}
            element={<PasswordResetInstructions />}
          />
          <Route path={ROUTES.setNewPassword} element={<SetNewPassword />} />
          <Route path={ROUTES.setNewPassword} element={<SetNewPassword />} />
          <Route
            path={ROUTES.emptyStates}
            element={<ProtectedRoute component={<EmptyStates />} />}
          />
          <Route
            path={ROUTES.home}
            element={<ProtectedRoute component={<Home />} />}
          />
          <Route
            path={ROUTES.screenz}
            element={<ProtectedRoute component={<Home />} />}
          />
          <Route
            path={ROUTES.screenDetails}
            element={<ProtectedRoute component={<ScreenDetails />} />}
          />
          <Route
            path={`${ROUTES.userSettings}`}
            element={<ProtectedRoute component={<UserSettings />} />}
          />
          <Route
            path={`${ROUTES.userSettingsTeamMembers.toLocaleLowerCase()}`}
            element={<ProtectedRoute component={<UserSettings />} />}
          />
          <Route
            path={`${ROUTES.userSettingsPermissions.toLocaleLowerCase()}`}
            element={<ProtectedRoute component={<UserSettings />} />}
          />
          <Route
            path={`${ROUTES.userSettingsIntegrations}`}
            element={<ProtectedRoute component={<UserSettings />} />}
          />
          <Route
            path={`${ROUTES.userSettingsProfile}`}
            element={<ProtectedRoute component={<UserSettings />} />}
          />
          <Route
            path={`${ROUTES.addTeamMates}`}
            element={<ProtectedRoute component={<UserSettings />} />}
          />
          <Route
            path={`${ROUTES.userSettingsPassword}`}
            element={<ProtectedRoute component={<UserSettings />} />}
          />
          <Route
            path={ROUTES.content}
            element={<ProtectedRoute component={<Content />} />}
          />
          <Route
            path={ROUTES.addWebpage}
            element={<ProtectedRoute component={<AddWebContent />} />}
          />
          <Route
            path={ROUTES.roles}
            element={<ProtectedRoute component={<Roles />} />}
          />
          <Route
            path={ROUTES.office365Auth}
            element={<ProtectedRoute component={<Office365Auth />} />}
          />
          <Route
            path={ROUTES.calenderEvents}
            element={<ProtectedRoute component={<CalenderEvents />} />}
          />

          <Route
            path={ROUTES.rolesDetails}
            element={<ProtectedRoute component={<RolesDetailsView />} />}
          />

          <Route
            path={ROUTES.playlists}
            element={<ProtectedRoute component={<Playlists />} />}
          />
          <Route
            path={ROUTES.playlist}
            element={<ProtectedRoute component={<PlaylistView />} />}
          />
          <Route
            path={ROUTES.features}
            element={<ProtectedRoute component={<Features />} />}
          />
          {/* <Route
            path="/filestack"
            element={<ProtectedRoute component={<FileStack />} />}
          /> */}
          <Route path="*" element={<PageNotFound />} />
          {/* <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="teams" element={<Teams />}>
            <Route path=":teamId" element={<Team />} />
            <Route path="new" element={<NewTeamForm />} />
            <Route index element={<LeagueStandings />} />
          </Route>
        </Route> */}
        </Routes>
      </BrowserRouter>
    );
  }
}

export default AppRoutes;

import React, { useCallback, useEffect, useState } from 'react';
import { caretLeft } from 'constants/index';
import { iState } from 'types';
import { useStore } from 'store';
import { s3Operations } from 'helpers';
import { office365Actions } from 'store/actions';
import Button, { ButtonVariants } from 'components/button/button';
import { LanguageType } from 'enums';
import englishData from '../../../../english.json';
import norwayData from '../../../../norwegian.json';

import styles from './assetManager.module.scss';

interface iProps {
  className?: string;
  heading: string;
  assetType: 'bgImg' | 'logo';
}

function AssetManager(props: iProps): JSX.Element {
  const { className, heading, assetType } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { eventBgImg, eventLogo } = state.office365.eventInfo || {};
  const { loggedInData } = state.user || {};
  const [isShowing, setIsShowing] = useState<boolean>(false);
  const [templateTexts, setTemplateTexts] = useState<any>(englishData);

  const { selectedLanguage } = state.office365 || {};
  const { id: userId } = loggedInData || {};

  useEffect(() => {
    if (selectedLanguage.value === LanguageType.English) {
      setTemplateTexts(englishData);
    }
    if (selectedLanguage.value === LanguageType.Norwegian) {
      setTemplateTexts(norwayData);
    }
  }, [selectedLanguage]);

  const fileUpload = useCallback(
    async (file: File): Promise<string> => {
      const { name } = file || {};
      const response: any = await s3Operations.upload({
        params: {
          Key: name,
          Body: file,
        },
        uploadType: 'calenderEventAssets',
        userId,
        progress: (progress) => {
          console.log('progress', progress);
        },
      });
      if (response) {
        return response.Location;
      }
      return '';
    },
    [userId]
  );

  const onChange = useCallback(
    async (event) => {
      const { files } = event.target || {};
      const file = files[0];
      if (assetType === 'bgImg') {
        const url = await fileUpload(file);
        dispatch(office365Actions.setEventBgImg(url));
      }
      if (assetType === 'logo') {
        const url = await fileUpload(file);
        dispatch(office365Actions.setEventLogo(url));
      }
    },
    [assetType, dispatch, fileUpload]
  );

  return (
    <div className={`${styles.assetManager} ${className}`}>
      <div
        onClick={() => setIsShowing(!isShowing)}
        className={styles.headingContainer}
      >
        <span className={styles.heading}>{heading}</span>
        <img className={styles.arrorIcon} src={caretLeft} alt="" />
      </div>

      {isShowing && (
        <>
          <hr className={styles.hr} />
          <div className={styles.previewAndChangeBtn}>
            <div
              className={`${styles.previewContainer} ${
                assetType === 'bgImg' && styles.bgPreview
              } ${assetType === 'logo' && styles.logo}`}
            >
              {eventBgImg && assetType === 'bgImg' && (
                <img className={styles.preview} src={eventBgImg || ''} alt="" />
              )}
              {eventLogo && assetType === 'logo' && (
                <img
                  className={`${styles.preview} `}
                  src={eventLogo || ''}
                  alt=""
                />
              )}
            </div>
            <div className={styles.fileUploadBtnContainer}>
              {assetType === 'bgImg' && (
                <>
                  <Button
                    className={styles.changeImgBtn}
                    variant={ButtonVariants.SmallStd}
                    btnName={
                      eventBgImg
                        ? templateTexts.changeImage
                        : templateTexts.addImage
                    }
                  />
                  <input
                    className={styles.fileInput}
                    type="file"
                    onChange={onChange}
                  />
                </>
              )}
              {assetType === 'logo' && (
                <>
                  <Button
                    className={styles.changeImgBtn}
                    variant={ButtonVariants.SmallStd}
                    btnName={
                      eventLogo
                        ? templateTexts.changeImage
                        : templateTexts.addImage
                    }
                  />
                  <input
                    className={styles.fileInput}
                    type="file"
                    onChange={onChange}
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

AssetManager.defaultProps = {
  className: '',
};

export default AssetManager;

// import { SCREENS } from 'constants/index';
import { FolderType } from 'enums';
import { uuid } from 'helpers';
import { COMMON, HOME_PAGE } from 'store/constants';
import { iCompanyData, iHomePageState } from 'types';
import { initialState } from '../initialState/initialState';

export const homePageReducer = (
  state: iHomePageState,
  action
): iHomePageState => {
  switch (action.type) {
    case HOME_PAGE.addSuperUserClient: {
      const clients = [...state.superUserClients];
      clients.push({
        id: uuid(),
        name: `Client ${Math.floor(Math.random() * 100)}`,
        totalOnline: Math.floor(Math.random() * 1000),
        totalOffline: Math.floor(Math.random() * 1000),
      });
      return { ...state, superUserClients: clients };
    }
    case HOME_PAGE.setCompanies: {
      return { ...state, companies: action.payload };
    }
    case HOME_PAGE.setIsFetchingFolders: {
      return { ...state, isFetchingFolders: action.payload };
    }
    case HOME_PAGE.setActiveCompanyData: {
      return { ...state, activeCompanyData: action.payload };
    }
    case HOME_PAGE.setTotalOnlineCount: {
      return { ...state, totalOnlineCount: action.payload };
    }
    case HOME_PAGE.setTotalOfflineCount: {
      return { ...state, totalOfflineCount: action.payload };
    }
    case HOME_PAGE.setCompanyData: {
      return { ...state, companyData: action.payload };
    }
    case HOME_PAGE.setScreenId: {
      return { ...state, screenId: action.payload };
    }
    case HOME_PAGE.setIsScreenBeingAdded: {
      return { ...state, isScreenBeingAdded: action.payload };
    }
    case HOME_PAGE.updateScreenData: {
      return { ...state, screenData: action.payload };
    }
    case HOME_PAGE.setIsSaveAndCloseDisabled: {
      return { ...state, isSaveAndCloseDisabled: action.payload };
    }
    case HOME_PAGE.setLastFetchedTime: {
      return { ...state, lastFetchedTime: action.payload };
    }
    case HOME_PAGE.setScreensOnlineStatus: {
      return { ...state, screensOnlineStatus: action.payload };
    }
    case HOME_PAGE.addSuperUserResellerClient: {
      const resellers: iCompanyData[] = [...state.superUserResellers];
      resellers.push({
        id: uuid(),
        name: `Client ${Math.floor(Math.random() * 100)}`,
        online: Math.floor(Math.random() * 1000),
        offline: Math.floor(Math.random() * 1000),
        totalClients: Math.floor(Math.random() * 1000),
        email: 'email',
        address: 'address',
        city: 'city',
        postal_code: 'postal_code',
        org_no: 'org_no',
        phone_number: 'phone_number',
        country_code: 'country_code',
        lastActiveTime: '',
      });
      return { ...state, superUserResellers: resellers };
    }
    case HOME_PAGE.addResellerClient: {
      const resellers: iCompanyData[] = [...state.resellerClients];
      resellers.push({
        id: uuid(),
        name: `Client ${Math.floor(Math.random() * 100)}`,
        online: Math.floor(Math.random() * 1000),
        offline: Math.floor(Math.random() * 1000),
        totalClients: Math.floor(Math.random() * 1000),
        email: 'email',
        address: 'address',
        city: 'city',
        postal_code: 'postal_code',
        org_no: 'org_no',
        phone_number: 'phone_number',
        country_code: 'country_code',
        lastActiveTime: '',
      });
      return { ...state, resellerClients: resellers };
    }
    case HOME_PAGE.addScreen: {
      const screens = [...state.screens];
      // screens.push({
      //   id: uuid(),
      //   ...SCREENS[Math.floor(Math.random() * 7)],
      // });
      return { ...state, screens };
    }
    case HOME_PAGE.screenOrderChange: {
      return { ...state, screens: action.payload };
    }
    case HOME_PAGE.setScreenData: {
      return { ...state, screenData: action.payload };
    }
    case HOME_PAGE.addFolder: {
      const folders = [...state.foldersAndSubFolders];
      const { activeFolder } = state;
      folders.push({
        id: uuid(),
        name: action.payload.name,
        parentFolders: [],
        url: '',
        company: '',
        folder_path: '',
        folderType: FolderType.CONTENT,
      });

      return {
        ...state,
        foldersAndSubFolders: folders,
        openedFolders:
          (activeFolder === null &&
            folders.filter((i) => !i.parentFolders[0].id)) ||
          folders.filter((i) => activeFolder?.id === i.parentFolders[0].id) ||
          [],
      };
    }
    case HOME_PAGE.setActiveFolder: {
      let folderHierarchy = [...state.folderHierarchy];
      const folders = [...state.foldersAndSubFolders];
      if (action?.payload === null) {
        return {
          ...state,
          activeFolder: null,
          folderHierarchy: [],
          openedFolders: folders.filter((i) => !i.parentFolders),
        };
      }
      const isExist = folderHierarchy.find((i) => i.id === action?.payload?.id);
      if (!isExist) {
        folderHierarchy.push(action.payload);
      } else {
        const index = folderHierarchy.findIndex(
          (i) => i.id === action.payload.id
        );
        folderHierarchy = folderHierarchy.slice(0, index + 1);
      }
      const openedFolders = folders.filter(
        (i) => action?.payload?.id === i.parentFolders
      );

      return {
        ...state,
        activeFolder: action.payload,
        folderHierarchy,
        openedFolders,
      };
    }
    case HOME_PAGE.setActiveClientInstanceData: {
      return { ...state, activeClientInstanceData: action.payload };
    }
    case HOME_PAGE.fetchScreensData: {
      return { ...state, canFetchScreensData: action.payload };
    }
    case HOME_PAGE.searchCompanyKey: {
      return { ...state, searchCompanyKey: action.payload };
    }
    case HOME_PAGE.searchScreenKey: {
      return { ...state, searchScreenKey: action.payload };
    }
    case HOME_PAGE.invokeGetCompanies: {
      return { ...state, invokeGetCompanies: action.payload };
    }
    case HOME_PAGE.screenzId: {
      return { ...state, screenzId: action.payload };
    }
    case HOME_PAGE.setScreensFolders: {
      return {
        ...state,
        screensFolders: action.payload,
      };
    }
    case HOME_PAGE.setScreensActiveFolder: {
      return {
        ...state,
        screensActiveFolder: action.payload,
      };
    }
    case HOME_PAGE.setContentToAssignToScreen: {
      return {
        ...state,
        contentToAssignScreen: action.payload,
      };
    }
    case HOME_PAGE.invokeUpdateScreen: {
      return {
        ...state,
        invokeUpdateScreen: action.payload,
      };
    }
    case HOME_PAGE.setPlaylistToAssignToScreen: {
      return {
        ...state,
        playlistToAssignScreen: action.payload,
      };
    }
    case HOME_PAGE.invokeGetScreenById: {
      return {
        ...state,
        invokeGetScreenById: action.payload,
      };
    }
    case HOME_PAGE.setScreensFolderHierarchy: {
      return {
        ...state,
        screensFolderHierarchy: action.payload,
      };
    }
    case COMMON.reset: {
      return initialState.homePage;
    }
    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';
import { iState } from 'types';
import { useStore } from 'store';
import { CalenderSettingsView, EventContentType, LanguageType } from 'enums';
import Button, { ButtonVariants } from 'components/button/button';
import { office365Actions } from 'store/actions';
import {
  getCurrentTime,
  getCurrentDate,
  getHours,
  getMinutes,
  getMinutesDifference,
} from './constants';
import englishData from '../../../english.json';
import norwayData from '../../../norwegian.json';
import styles from './eventView.module.scss';

interface iProps {
  className?: string;
}

function EventView(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const {
    contentAreaContentOption,
    contentSettingsContentOption,
    currentEvents,
    nextEvents,
    currentView,
  } = state.office365;

  const {
    eventBgImg,
    eventLogo,
    isShowSubject,
    isShowActiveBooking,
    // isShowHostName,
    isShowNextMeeting,
    customName = '',
    roomChoosed,
    timeZone,
  } = state.office365.eventInfo || {};

  const {
    start,
    end,
    subject,
    // organizer
  } = currentEvents || {};
  const { start: currentStart, end: currentEnd } = nextEvents || {};

  const { ContentSetting, TemplateSetting, ContentArea } = CalenderSettingsView;
  const [templateTexts, setTemplateTexts] = useState<any>(englishData);
  const { selectedLanguage } = state.office365 || {};

  useEffect(() => {
    if (selectedLanguage.value === LanguageType.English) {
      setTemplateTexts(englishData);
    }
    if (selectedLanguage.value === LanguageType.Norwegian) {
      setTemplateTexts(norwayData);
    }
  }, [selectedLanguage]);

  const startTime =
    start && `${getHours(start.dateTime)}:${getMinutes(start.dateTime)}`;

  const endTime =
    end && `${getHours(end.dateTime)}:${getMinutes(end.dateTime)}`;

  const nextStartTime =
    currentStart &&
    `${getHours(currentStart.dateTime)}:${getMinutes(currentStart.dateTime)}`;

  const nextEndTime =
    (currentEnd &&
      `${getHours(currentEnd.dateTime)}:${getMinutes(currentEnd.dateTime)}`) ||
    '';

  const isShowClock =
    contentAreaContentOption.option === EventContentType.Clock || false;

  const isShowEventDetails =
    contentSettingsContentOption.value === EventContentType.Calender || false;

  //  console.log(currentEvents?.start);
  // console.log(isShowEventDetails, 'eventsfilter');

  const selectedLanguageType =
    (selectedLanguage.value === LanguageType.English && LanguageType.English) ||
    (selectedLanguage.value === LanguageType.Norwegian &&
      LanguageType.Norwegian) ||
    LanguageType.English;

  const formatNumber = (number): string => {
    return `0${number}`.slice(-2);
  };

  const getTimeFromData = (date): string => {
    //  console.log(`date`);
    //  console.log(date);

    const dateFormate = new Date(`${date}Z`);
    const hours = dateFormate.getHours().toString();
    const minutes = dateFormate.getMinutes().toString();

    return `${formatNumber(hours)}:${formatNumber(minutes)}`;
  };

  const isContentSettings = currentView === ContentSetting;
  const isContentArea = currentView === ContentArea;
  const isTemplatesSettings = currentView === TemplateSetting;

  const availableTime =
    currentStart && getMinutesDifference(currentStart?.dateTime);

  return (
    <div className={`${styles.eventContainer}  ${className}`}>
      <div className={styles.main}>
        {eventBgImg && (
          <img className={styles.eventBgImg} src={eventBgImg} alt="" />
        )}
        <div
          className={`${styles.eventDetails} ${
            currentEvents?.start.dateTime && styles.ongoing
          }`}
        >
          <div className={styles.sectionOne}>
            <div
              className={`${styles.currentTime} ${
                isContentArea && !isTemplatesSettings && styles.showActive
              }`}
              onClick={() =>
                dispatch(office365Actions.setCurrentView(ContentArea))
              }
            >
              {isShowClock && (
                <>
                  <p className={styles.time}>
                    {timeZone &&
                      (timeZone.value || '').includes('/') &&
                      getCurrentTime(timeZone?.value)}
                  </p>
                  <p className={styles.date}>
                    {timeZone &&
                      (timeZone.value || '').includes('/') &&
                      getCurrentDate(timeZone?.value, selectedLanguageType)}
                  </p>
                </>
              )}
            </div>
          </div>

          <div
            className={`${styles.sectionTwo} ${
              isContentSettings && !isTemplatesSettings && styles.showActive
            }`}
            onClick={() =>
              dispatch(office365Actions.setCurrentView(ContentSetting))
            }
          >
            {isShowEventDetails && (
              <>
                <p className={styles.subject}>
                  {customName.length < 21
                    ? customName
                    : `${customName.slice(0, 20)}...`}
                </p>
                {!currentEvents && nextStartTime && (
                  <p className={styles.available}>
                    {templateTexts.availableUntil}{' '}
                    {getTimeFromData(nextEvents?.start.dateTime)}{' '}
                    {templateTexts.today}
                  </p>
                )}
                {!currentEvents && !nextStartTime && roomChoosed && (
                  <p className={styles.available}>{templateTexts.available}</p>
                )}
                <p className={styles.meetingTime}>
                  {(startTime || endTime) &&
                    `${getTimeFromData(
                      currentEvents?.start.dateTime
                    )}-> ${getTimeFromData(currentEvents?.end.dateTime)}`}
                </p>
                {isShowSubject && (
                  <p className={styles.description}>
                    {subject && subject.length < 25 && subject}
                    {subject && subject.length >= 25 && subject.slice(0, 25)}
                  </p>
                )}
                {/* {isShowHostName && (
                  <p className={styles.organizer}>
                    {organizer &&
                      organizer.emailAddress.name.length < 20 &&
                      organizer.emailAddress.name}
                    {organizer &&
                      organizer.emailAddress.name.length > 20 &&
                      `${organizer.emailAddress.name.slice(0, 20)}...`}
                  </p>
                )} */}
                {isShowActiveBooking && currentEvents && (
                  <Button
                    className={styles.activateBookingBtn}
                    variant={ButtonVariants.MediumStd}
                    btnName={templateTexts.endMeeting}
                  />
                )}
                {isShowActiveBooking &&
                  roomChoosed &&
                  !currentEvents &&
                  roomChoosed &&
                  (availableTime > 15 || !availableTime) && (
                    <div className={styles.bookingDetails}>
                      <p className={styles.bookNow}>{templateTexts.bookNow}</p>
                      {(availableTime > 15 || !availableTime) && (
                        <Button
                          className={styles.activateBookingBtn}
                          variant={ButtonVariants.MediumTransp}
                          btnName={templateTexts.fifteenMin}
                        />
                      )}
                      {(availableTime > 30 || !availableTime) && (
                        <Button
                          className={styles.activateBookingBtn}
                          variant={ButtonVariants.MediumTransp}
                          btnName={templateTexts.thirtyMin}
                        />
                      )}
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.logoHolder}>
          {eventLogo && <img src={eventLogo} alt="" />}
        </div>

        <div className={styles.nextMeeting}>
          {isShowNextMeeting && isShowEventDetails && nextEvents && (
            <>
              <div className={styles.subject}>
                <p className={styles.heading}>{templateTexts.nextMeeting}</p>
                <div className={styles.schedule}>
                  <p>
                    {(nextStartTime || nextEndTime) &&
                      `${getTimeFromData(
                        nextEvents?.start.dateTime
                      )}-> ${getTimeFromData(nextEvents?.end.dateTime)}`}
                  </p>
                  {/* <p>.</p> */}
                </div>
                <div className={styles.organizer}>
                  <p>{nextEvents.organizer.emailAddress.name}</p>
                  <p>.</p>
                </div>
              </div>
              <p className={styles.value}>
                {nextEvents.subject.length < 60 && nextEvents.subject}
                {nextEvents.subject.length > 60 &&
                  `${nextEvents.subject.slice(0, 60)}...`}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

EventView.defaultProps = {
  className: '',
};

export default EventView;

import o365badge from 'assets/images/o365badge.png';
import Button, { ButtonVariants } from 'components/button/button';
import Modal from 'components/modal/modal';
import React, { useCallback } from 'react';
import { useStore } from 'store';
import { layoutActions } from 'store/actions';
import { iState } from 'types';
import styles from './o365Modal.module.scss';

type iProps = {
  handleLogin: any;
};

function O365Modal(props: iProps): JSX.Element {
  const { handleLogin } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { isShowO365Modal = false } = state.layout || {};

  const closeModalHandler = useCallback(() => {
    dispatch(layoutActions.toggleModal());
  }, [dispatch]);

  return (
    <Modal
      isOpen={isShowO365Modal}
      headingIcon={o365badge}
      heading="Office 365"
      onClose={closeModalHandler}
      submit={() => {}}
      isShowFooter={false}
    >
      <div className={styles.o365ModalContainer}>
        <div className={styles.O365Modal}>
          <Button
            className={styles.o365Btn}
            variant={ButtonVariants.LargeTransp}
            btnName="Sign in to Microsoft Office 365"
            icon={o365badge}
            onClick={handleLogin}
          />
        </div>
        <span className={styles.notes}>Notes</span>
        <p className={styles.notesContent}>
          Å få tilgang til, og vise Office365-ressurser (kalendere og dets
          hendelser), vil være basert på delegerte tillatelser og administreres
          av Office365 Administratoren din. Ta kontakt med oss på chat her for å
          få hjelp til å sette opp Office 365 til dette formålet. For å vise
          innhold fra dine ressurser i Office 365 på selve spilleren trenger
          spilleren også tillatelser som kan settes opp av Global Admin på
          ressursnivå. Ta her også kontakt med oss i chatten nede for hjelp til
          å sette i gang.
        </p>
      </div>
    </Modal>
  );
}

O365Modal.defaultProps = {};

export default O365Modal;

import Logo from 'components/logo/logo';
import More from 'components/svg/more';
import { ROUTES } from 'constants/index';
import { LayoutViews, UserRoles } from 'enums';
import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import {
  contentPageActions,
  homePageActions,
  layoutActions,
  playlistPageActions,
} from 'store/actions';
import { iState } from 'types';
import ClientDropdown from './clients-list-dropdown/clientsListDropdown';
import Profile from './profile/profile';
import styles from './roleBasedHeader.module.scss';

interface iProps {
  className?: string;
}

// headerxfasdf
function RoleBasedHeader(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { role } = state.user || {};
  const { layout } = state || {};
  const { companyData } = state.homePage || {};
  // const [containerActive, setContainerActive] = useState(false);
  // const { companies = [], companyData } = state.homePage;

  const navigate = useNavigate();
  const { pathname } = useLocation();
  // useEffect(() => {
  //   dispatch(layoutActions.initialSidebarState(false));
  // }, []);

  const isSuperAdmin = role === UserRoles.SuperAdmin;
  const isOwner = role === UserRoles.Owner;
  const isAdmin = role === UserRoles.Admin;
  const isReseller = role === UserRoles.Reseller;

  const userRole =
    (isSuperAdmin && 'Superuser Admin') ||
    (isOwner && 'Owner') ||
    (isAdmin && 'Admin') ||
    (isReseller && 'Reseller');

  const { isShowingClientInstance } = layout || {};

  const activeBtn = !isShowingClientInstance && pathname !== ROUTES.emptyStates;
  // const activeClient =
  //   companyData || (companies?.length > 0 && companies[0]) || null;

  const onClickSuperAdmin = useCallback(() => {
    if (!isSuperAdmin) return;
    dispatch(contentPageActions.setContents([]));
    dispatch(homePageActions.setCompanyData(null));
    dispatch(layoutActions.setShowClientsInstance(false));
    dispatch(layoutActions.initialSidebarState(false));
    dispatch(layoutActions.setHomePageView(LayoutViews.SuperAdminClients));

    dispatch(homePageActions.setScreensActiveFolder(null));
    dispatch(homePageActions.setScreensFolders([]));
    dispatch(homePageActions.setScreensFolderHierarchy([]));

    dispatch(contentPageActions.setContentActiveFolder(null));
    dispatch(contentPageActions.setContentFolders([]));
    dispatch(contentPageActions.setContentFolderHierarchy([]));

    dispatch(playlistPageActions.setPlaylistsActiveFolder(null));
    dispatch(playlistPageActions.setPlaylistsFolders([]));
    dispatch(playlistPageActions.setPlaylistsFolderHierarchy([]));

    dispatch(layoutActions.setAssignModalContentActiveFolders(null));
    dispatch(layoutActions.setAssignModalContentFolders([]));
    dispatch(layoutActions.setAssignModalContentsFolderHierarchy([]));

    navigate('/');
  }, [isSuperAdmin, navigate, dispatch]);

  useEffect(() => {
    if (
      pathname === ROUTES.emptyStates ||
      pathname === ROUTES.roles ||
      pathname === ROUTES.userSettings ||
      pathname === ROUTES.rolesDetails ||
      pathname === ROUTES.userSettingsIntegrations ||
      pathname === ROUTES.userSettingsTeamMembers ||
      pathname === ROUTES.userSettingsProfile ||
      pathname === ROUTES.userSettingsPassword ||
      pathname === ROUTES.features
    )
      return;
    if (!companyData) onClickSuperAdmin();
  }, [companyData, onClickSuperAdmin, pathname]);

  return (
    <div className={`${className} ${styles.roleBasedHeader}`}>
      <section className={styles.left}>
        <Logo className={styles.logo} variant={`${`sidebar`}`} />
        <span
          className={`${styles.userType} ${activeBtn && styles.active}`}
          onClick={onClickSuperAdmin}
        >
          {userRole}
        </span>
        {isSuperAdmin && (
          <div
            // onClick={() => setContainerActive(true)}
            // onClick={() => {
            // }}
            className={`${styles.clientsContainer} ${
              isShowingClientInstance &&
              pathname !== ROUTES.emptyStates &&
              styles.active
            }`}
          >
            <ClientDropdown />
          </div>
        )}

        {isSuperAdmin && (
          <span
            className={`${styles.emptyState} ${
              pathname === ROUTES.emptyStates && styles.active
            }`}
            onClick={() => navigate(ROUTES.emptyStates)}
          >
            Empty States
          </span>
        )}

        {isSuperAdmin && (
          <span
            className={`${styles.emptyState} ${
              (pathname === ROUTES.roles && styles.active) ||
              (pathname === ROUTES.rolesDetails && styles.active)
            }`}
            onClick={() => navigate(ROUTES.roles)}
          >
            Roles
          </span>
        )}
        {isSuperAdmin && (
          <span
            className={`${styles.emptyState} ${
              pathname === ROUTES.features && styles.active
            }`}
            onClick={() => navigate(ROUTES.features)}
          >
            Features
          </span>
        )}
      </section>
      <section className={styles.right}>
        <Profile />
        <More className={styles.moreIcon} />
      </section>
    </div>
  );
}

RoleBasedHeader.defaultProps = {
  className: '',
};

export default RoleBasedHeader;

export enum SubFolderEmptyStatesVariants {
  Screens = 'screens',
  Contents = 'contents',
  Playlists = 'playlists',
}

export const Variants = {
  [SubFolderEmptyStatesVariants.Screens]: {
    heading: 'screens',
    description: 'Add a screen or folder to start',
  },
  [SubFolderEmptyStatesVariants.Contents]: {
    heading: 'contents',
    description: 'Add a content or folder to start',
  },
  [SubFolderEmptyStatesVariants.Playlists]: {
    heading: 'playlists',
    description: 'Add a playlist or folder to start',
  },
};

import { gql } from 'apollo-boost';

export enum Companies {
  AddCompany = 'addCompany',
  UpdateCompany = 'updateCompany',
  GetCompanies = 'getCompanies',
  GetCompanyById = 'getCompanybyid',
  SearchCompany = 'searchCompanybyname',
  DeleteCompany = 'deleteCompany',
  GetCompaniesWithFeatures = 'getCompaniesWithFeatures',
  UpdateFeatureOfCompany = 'updateFeatureOfCompany',
}

export const addCompanyMutation = gql`
  mutation ${Companies.AddCompany} (
    $email: String!
    $name: String!
    $address: String
    $city: String
    $postal_code: String
    $org_no: String
    $sendEmail: Boolean
  ){
    ${Companies.AddCompany} (
      email: $email
      name: $name
      address: $address
      city: $city
      postalCode: $postal_code
      orgNr: $org_no
      sendEmail: $sendEmail
    ){
      message
      statusCode
      data {
        email
        address
        city
        name
        postal_code
        org_no
        phone_number
        country_code
        id
        created_at
      }
    }
  }
`;

export const updateCompanyMutation = gql`
  mutation ${Companies.UpdateCompany} (
    $id: String!
    $email: String!
    $name: String!
    $address: String
    $city: String
    $postal_code: String
    $org_no: String
  ){
    ${Companies.UpdateCompany} (
      id: $id
      email: $email
      name: $name
      address: $address
      city: $city
      postalCode: $postal_code
      orgNr: $org_no
    ){
      statusCode
      message
      data {
        id
        name
        email
        org_no
        address
        postal_code
        city
        email
      }
    }
  }
`;

export const getCompaniesWithFeaturesQuery = gql`
  query ${Companies.GetCompaniesWithFeatures}(
    $skip: Int!
    $limit: Int!
    $searchQuery: String
  ){
    ${Companies.GetCompaniesWithFeatures}(
      skip: $skip
      limit: $limit
      searchQuery: $searchQuery
    ){
      statusCode
      message
      data {
        id
        name
        features{
          accessManagment
        }
        
      }
    }
  }
`;

export const updateCompanyFeatureMutation = gql`
  mutation ${Companies.UpdateFeatureOfCompany}(
    $companyId: String!
    $accessManagement: Boolean! 
  ){
    ${Companies.UpdateFeatureOfCompany}(
      companyId: $companyId
      accessManagement: $accessManagement
    ){
      message
      statusCode
      data {
        updated
      }
    }
  }
`;

export const getCompaniesQuery = gql`
  query ${Companies.GetCompanies}(
    $skip: Int!
    $limit: Int!
  ){
    ${Companies.GetCompanies} (
      skip: $skip
      limit: $limit
    ){
      statusCode
      message
      data {
        id
        email
        onlinecount
        offlinecount
        address
        city
        name
        postal_code
        org_no
        phone_number
        country_code
        companyUserId
      }
    }
  } 
`;

export const getCompaniesByIdQuery = gql`
      query ${Companies.GetCompanyById}($id: String!){
        ${Companies.GetCompanyById}(id: $id){
          statusCode
          message
          data{
            id
            name
            org_no
            address
            postal_code
            city
            email
            phone_number
            features{
              accessManagment
            }
          }
        }
      }`;

export const searchCompanyMutation = gql`
  mutation ${Companies.SearchCompany} (
    $name: String!
  ){
    ${Companies.SearchCompany} (
      name: $name
    ){
      message
      statusCode
      data {
        email
        address
        onlinecount
        offlinecount
        city
        name
        postal_code
        org_no
        phone_number
        country_code
        id
        created_at
      }
    }
  }
`;

export const deleteCompanyByIdMutation = gql`
mutation ${Companies.DeleteCompany}(
  $id: String!
){
  ${Companies.DeleteCompany}(
    id: $id
  ){
    statusCode
    message
    data {
        email
        address
        city
        name
        postal_code
        org_no
        phone_number
        country_code
        id
        created_at
    }
  }
}
`;

import React from 'react';
import Switch from 'components/switch/switch';
import styles from './toggler.module.scss';

interface iProps {
  className?: string;
  label: string;
  value: boolean;
  onChange: (a: boolean) => void;
}

function Toggler(props: iProps): JSX.Element {
  const { className, label, value, onChange } = props;

  return (
    <div className={`${styles.toggler} ${className}`}>
      <p className={styles.label}>{label}</p>
      <Switch onChange={onChange} value={value} />
    </div>
  );
}

Toggler.defaultProps = {
  className: '',
};

export default Toggler;

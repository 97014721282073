import { getAllPlaylists, searchPlaylist } from 'apis';
import AddFolderModal from 'components/add-folder-modal/addFolderModal';
import AddModal, { AddModalVariants } from 'components/add-modal/addModal';
import Layout, { LayoutVariants } from 'components/Layouts/layout';
import PageWrapper from 'components/page-wrapper/pageWrapper';
import { ROUTES } from 'constants/index';
import { LayoutViews } from 'enums';
import { toaster } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { layoutActions, playlistPageActions } from 'store/actions';
import { iPlaylistData, iState } from 'types';
import PlaylistsList from './components/playlists-list/playlistsList';
import styles from './playlists.module.scss';

function Playlists(): JSX.Element {
  const [state, dispatch]: [iState, any] = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [foundPlaylist, setFoundPlaylist] =
    useState<Array<iPlaylistData> | null>(null);
  const { windowWidth } = state.layout || {};
  const { companyData } = state.homePage || {};
  const { canFetchPlaylistData, searchPlaylistKey, playlistActiveFolder } =
    state.playlistsPage || {};
  const { id: companyId, companyUserId = '' } = companyData || {};
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!companyData) {
      navigate(ROUTES.chooseTeam);
    }
  }, [pathname]);

  useEffect(() => {
    if (windowWidth > 768)
      dispatch(layoutActions.setHomePageView(LayoutViews.Playlists));
  }, [dispatch, windowWidth]);

  useEffect(() => {
    document.title = 'Playlists';
  }, []);

  useEffect(() => {
    dispatch(layoutActions.setHomePageView(LayoutViews.Playlists));
  }, [dispatch]);

  const getPlaylists = useCallback(async () => {
    if (!companyId) {
      toaster.error('Company data not found');
      return;
    }
    const response = await getAllPlaylists(
      {
        companyId,
        folderId: playlistActiveFolder?.id || null,
        companyUserId: companyUserId || '',
      },
      setIsLoading
    );
    if (response) {
      console.log(response);
      dispatch(playlistPageActions.setPlaylist(response));
      dispatch(playlistPageActions.fetchPlaylistData(false));
    }
  }, [companyId, dispatch, playlistActiveFolder?.id]);

  useEffect(() => {
    getPlaylists();
  }, [getPlaylists]);

  useEffect(() => {
    if (canFetchPlaylistData) getPlaylists();
  }, [canFetchPlaylistData, getPlaylists]);

  const searchPlaylistHandler = useCallback(async () => {
    if (!companyId) {
      toaster.error('Company data not found');
      return;
    }
    const response = await searchPlaylist(
      {
        name: searchPlaylistKey,
        company: companyId,
        folderId: playlistActiveFolder?.id || null,
      },
      setIsLoading
    );
    if (response) {
      setFoundPlaylist(response);
    } else setFoundPlaylist(null);
  }, [searchPlaylistKey, companyId, playlistActiveFolder?.id]);

  useEffect(() => {
    if (searchPlaylistKey !== '') searchPlaylistHandler();
    else setFoundPlaylist(null);
  }, [searchPlaylistKey, searchPlaylistHandler]);

  useEffect(() => {
    return () => {
      dispatch(layoutActions.toggleSideDrawer());
    };
  }, [dispatch]);

  return (
    <Layout variant={LayoutVariants.layoutTwo}>
      <PageWrapper className={styles.playlistWrapper}>
        <>
          {(!isLoading &&
            searchPlaylistKey !== '' &&
            !Array.isArray(foundPlaylist) && (
              <p className={styles.notFound}>Result not found</p>
            )) || (
            <PlaylistsList searchedData={foundPlaylist} isLoading={isLoading} />
          )}
          <AddModal variant={AddModalVariants.AddPlaylist} />
          <AddFolderModal />
        </>
      </PageWrapper>
    </Layout>
  );
}

export default Playlists;

import React, { useCallback, useEffect } from 'react';
import { caretLeft, ROUTES } from 'constants/index';
// import Dustbin from 'components/svg/dustbin';
import { iState } from 'types';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import Button, { ButtonVariants } from 'components/button/button';
import styles from './subheader.module.scss';

interface iProps {
  className?: string;
  roleName?: string;
  isDisabled: boolean;
  saveChanges: () => void;
  removeRole: () => void;
}

function SubHeader(props: iProps): JSX.Element {
  const { className, roleName, isDisabled, saveChanges, removeRole } = props;
  const [,]: [iState, any] = useStore();
  const navigate = useNavigate();

  const redirectToRole = useCallback(() => {
    navigate(ROUTES.roles);
  }, [navigate]);

  useEffect(() => {}, [removeRole]);

  return (
    <div className={`${styles.subheader} ${className}`}>
      <div className={styles.left}>
        <div className={styles.heading} onClick={redirectToRole}>
          <img className={styles.arrowIcon} src={caretLeft} alt="" />
          <p>{roleName?.split('_').join(' ')}</p>
        </div>
      </div>
      <div className={styles.right}>
        <Button
          className={styles.btnStyle}
          btnName="Cancel"
          variant={ButtonVariants.SmallTransp}
          onClick={redirectToRole}
        />

        {/* <button
          className={`${styles.btnStyle} ${styles.removeButton}`}
          type="button"
          onClick={removeRole}
        >
          <Dustbin className={styles.distbinIcon} />
          Remove role
        </button> */}
        <Button
          className={styles.btnStyle}
          btnName="Save changes"
          variant={ButtonVariants.SmallStd}
          isDisabled={!isDisabled}
          onClick={saveChanges}
        />
      </div>
    </div>
  );
}

SubHeader.defaultProps = {
  className: '',
  roleName: '',
};

export default SubHeader;

import Button, { ButtonVariants } from 'components/button/button';
import Popover from 'components/popover/popover';
import {
  companyIcon,
  contentIcon,
  folder2Icon,
  playlistsIcon,
  ROUTES,
  screenGreyIcon,
  WebIcon,
  whiteAddIcon,
} from 'constants/index';
import { ModalViews, UserRoles } from 'enums';
import { uuid } from 'helpers';
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'store';
// import { layoutActions } from 'store/actions';
import { layoutActions } from 'store/actions';
import { iState } from 'types';
import styles from './header.module.scss';

interface iProps {
  className?: string;
}

const PLAYLIST_ADD_OPTIONS = [
  {
    id: uuid(),
    icon: playlistsIcon,
    option: 'New Playlist',
    action: 'mainOperation',
  },
  { id: uuid(), icon: folder2Icon, option: 'New folder', action: 'addFolder' },
];
const SCREENS_ADD_OPTIONS = [
  {
    id: uuid(),
    icon: screenGreyIcon,
    option: 'New Screen',
    action: 'mainOperation',
  },
  { id: uuid(), icon: folder2Icon, option: 'New folder', action: 'addFolder' },
];
const CONTENT_ADD_OPTIONS = [
  {
    id: uuid(),
    icon: contentIcon,
    option: 'Add Content',
    action: 'mainOperation',
  },
  { id: uuid(), icon: folder2Icon, option: 'New folder', action: 'addFolder' },
  {
    id: uuid(),
    icon: WebIcon,
    option: 'Add webpage',
    action: 'addWebPage',
  },
];

function Header(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { user } = state || {};
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isShowingClientInstance } = state.layout || {};

  // const activeView = user?.userSettings?.activeView !== 'General';
  // const role = user.role !== UserRoles.Client;
  // useEffect(() => {
  //   //  console.log(state.user);
  // }, [state, dispatch]);

  const addContent = useCallback(() => {
    // if (user.role === UserRoles.SuperAdmin || !isShowingClientInstance)
    //   dispatch(layoutActions.toggleModal(ModalViews.isShowAddClient));
    if (isShowingClientInstance && pathname === ROUTES.screenz)
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddScreen));
    if (user.role === UserRoles.Reseller)
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddClient));
    if (user.role === UserRoles.Client)
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddScreen));
    // if (pathname === ROUTES.home)
    //   dispatch(layoutActions.toggleModal(ModalViews.isShowAddScreen));
    if (pathname === ROUTES.content)
      // dispatch(layoutActions.openAddContentModal());
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddContent));
    if (pathname === ROUTES.playlists)
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddPlaylist));
  }, [user, dispatch, pathname, isShowingClientInstance]);

  const addFolder = useCallback(() => {
    // if (user.role === UserRoles.SuperAdmin)
    //   dispatch(layoutActions.toggleModal(ModalViews.isShowAddFolder));
    // if (user.role === UserRoles.Reseller)
    //   dispatch(layoutActions.toggleModal(ModalViews.isShowAddFolder));
    // if (user.role === UserRoles.Client)
    //   dispatch(layoutActions.toggleModal(ModalViews.isShowAddFolder));
    // if (pathname === ROUTES.playlists)
    //   dispatch(
    //     layoutActions.toggleModal(ModalViews.isShowAddFolderForPlaylists)
    //   );
    dispatch(layoutActions.toggleModal(ModalViews.isShowAddFolder));
  }, [dispatch]);

  const addWebPage = useCallback(() => {
    // if (pathname === ROUTES.content)
    //   dispatch(layoutActions.toggleModal(ModalViews.isShowAddWebContent));
    navigate(ROUTES.addWebpage);
  }, [navigate]);

  const isSuperUserAdmin = user.role === UserRoles.SuperAdmin;
  const isOwner = user.role === UserRoles.Owner;
  const isAdmin = user.role === UserRoles.Admin;
  const isClient = user.role === UserRoles.Client;
  const isResellerAdmin = user.role === UserRoles.Reseller;

  const heading =
    (isShowingClientInstance &&
      isSuperUserAdmin &&
      pathname === ROUTES.screenz &&
      'Your Screens') ||
    (pathname === ROUTES.playlists && 'Your Playlists') ||
    (pathname === ROUTES.playlist && 'Your Playlists') ||
    (pathname === ROUTES.roles && 'Roles settings') ||
    (pathname === ROUTES.rolesDetails && 'Roles settings') ||
    (pathname === ROUTES.content && 'Your Contents') ||
    (pathname === ROUTES.userSettings && 'Your Settings') ||
    (pathname === ROUTES.features && 'Features') ||
    (pathname === ROUTES.screenDetails && 'Your Screens') ||
    ((isClient || isOwner || isAdmin) && 'Your Screens') ||
    (isSuperUserAdmin && 'Your Clients') ||
    (isResellerAdmin && 'Your Clients');

  const onTeammateClick = useCallback(() => {
    dispatch(layoutActions.toggleModal(ModalViews.isShowAddTeammate));
  }, [dispatch]);
  const addBtnOptions =
    (pathname === ROUTES.screenz && SCREENS_ADD_OPTIONS) ||
    (pathname === ROUTES.playlists && PLAYLIST_ADD_OPTIONS) ||
    (pathname === ROUTES.content && CONTENT_ADD_OPTIONS) ||
    [];

  const displayMobileAddIcon =
    pathname === ROUTES.roles ||
    pathname === ROUTES.features ||
    pathname === ROUTES.playlist;

  return (
    <div className={`${className} ${styles.headerWrapper}`}>
      <span className={styles.title}>{heading}</span>
      {pathname === ROUTES.userSettingsTeamMembers ? (
        <Popover
          className={styles.popoverWrapper}
          popoverClassName={styles.mobileDropdownBtn}
          popoverComponent={<div />}
        >
          {pathname === ROUTES.userSettingsTeamMembers ? (
            <Button
              className={`${styles.mobileDropdownBtn}`}
              variant={ButtonVariants.SmallStd}
              btnName="Screen"
              icon={whiteAddIcon}
              onClick={onTeammateClick}
              isSupportResponsive
            />
          ) : (
            <div />
          )}
        </Popover>
      ) : (
        <Popover
          className={styles.popoverWrapper}
          popoverClassName={styles.mobileDropdownBtn}
          popoverComponent={
            <div className={`${styles.playlistDropdown} ${className}`}>
              {isShowingClientInstance ||
              isClient ||
              isAdmin ||
              pathname === ROUTES.content ||
              pathname === ROUTES.playlists ? (
                addBtnOptions.map((i) => (
                  <div
                    key={uuid()}
                    className={styles.option}
                    onClick={() => {
                      if (i.action === 'mainOperation') addContent();
                      if (i.action === 'addWebPage') addWebPage();
                      if (i.action === 'addFolder') addFolder();
                    }}
                  >
                    <img src={i.icon} alt="" />
                    <span>{i.option}</span>
                  </div>
                ))
              ) : (
                <>
                  {pathname === ROUTES.home ? (
                    <div
                      className={styles.option}
                      onClick={() => {
                        dispatch(
                          layoutActions.toggleModal(ModalViews.isShowAddClient)
                        );
                      }}
                    >
                      <img src={companyIcon} alt="" />
                      <span>New Client</span>
                    </div>
                  ) : (
                    <div />
                  )}
                  <div />
                </>
              )}
            </div>
          }
        >
          <Button
            className={`${styles.mobileDropdownBtn} ${
              displayMobileAddIcon && styles.hideAddBtn
            }`}
            variant={ButtonVariants.SmallStd}
            btnName="Screen"
            icon={whiteAddIcon}
            isSupportResponsive
          />
        </Popover>
      )}

      {/* <Button
        className={`${styles.addScreensBtn}`}
        variant={ButtonVariants.SmallStd}
        btnName="Screen"
        icon={whiteAddIcon}
        isSupportResponsive
        onClick={onClickAdd}
      /> */}
    </div>
  );
}

Header.defaultProps = {
  className: '',
};

export default Header;

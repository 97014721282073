import { getScreensByCompany, searchScreens } from 'apis';
import { toaster } from 'helpers';
import React, { useEffect, useState, useCallback } from 'react';
import { useStore } from 'store';
import { homePageActions } from 'store/actions';
import { iCompanyData, iScreenData, iState } from 'types';
import { UserRoles } from 'enums';
import ScreenList from './screens-list/screensList';
import styles from './client.module.scss';

const tempScreenData = (company: iCompanyData, name: string): iScreenData => {
  return {
    hashCode: 'BeingAdded',
    name,
    id: 'BeingAdded',
    created_at: 'BeingAdded',
    isOnline: false,
    lastPing: null,
    brightness: 0,
    volume: 0,
    sortOrder: 100,
    company,
    screenShotUrl: 'BeingAdded',
    currentPlayList: null,
    currentContent: null,
  };
};

interface iProps {
  className?: string;
}

// const timeDiffInMinutes = (past: Date): number => {
//   const current = new Date();
//   let diff = (current.getTime() - new Date(past).getTime()) / 1000;
//   diff /= 60;
//   return Math.abs(Math.round(diff));
// };

function Client(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { homePage } = state || {};
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [screens, setScreens] = useState<Array<iScreenData>>([]);
  const [searchedScreens, setSearchedScreens] =
    useState<Array<iScreenData> | null>(null);
  const { role } = state.user || {};
  const { companyUserId } = homePage.companyData || {};
  const isSuperUserAdmin = role === UserRoles.SuperAdmin;
  const {
    companyData,
    canFetchScreensData,
    searchScreenKey,
    screensActiveFolder,
    isScreenBeingAdded,
    // lastFetchedTime,
  } = homePage || {};

  const getScreens = useCallback(async () => {
    if (!companyData) {
      toaster.error('Company details not found');
      return;
    }
    const response = await getScreensByCompany(
      {
        companyId: companyData?.id,
        folderId: screensActiveFolder?.id || null,
        // companyUserId: 'd1ba521d-6756-4c5b-beed-9b3363423a46',
        companyUserId: companyUserId || null,
      },
      setIsLoading
    );
    if (response) {
      setScreens(response.screenz);
      dispatch(homePageActions.fetchScreensData(false));
      dispatch(homePageActions.setIsScreenBeingAdded(''));
      // if (
      //   (screensOnlineStatus && screensOnlineStatus.length === 0) ||
      //   timeDiffInMinutes(lastFetchedTime) > 5
      // )
      dispatch(homePageActions.setTotalOnlineCount(response.onlineCount));
      dispatch(homePageActions.setTotalOfflineCount(response.offlineCount));
      setSearchedScreens(response.screenz);
      if (!searchScreenKey) {
        setScreens(response.screenz);
      }
      // getScreensStatusHandler(response);
    }
  }, [companyData, screensActiveFolder?.id, dispatch]);

  // useEffect(() => {
  //   const ONE_MINUTE = 60000;
  //   // const ONE_MINUTE = 1000;
  //   const interval = setInterval(() => {
  //     const diff = timeDiffInMinutes(lastFetchedTime);
  //     if (diff > 5) {
  //       getScreens();
  //     }
  //   }, ONE_MINUTE);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [lastFetchedTime, getScreensStatusHandler, screens]);

  useEffect(() => {
    getScreens();
  }, [screensActiveFolder]);

  useEffect(() => {
    if (isSuperUserAdmin) getScreens();
  }, [companyData]);

  useEffect(() => {
    if (!companyData) return;
    if (isScreenBeingAdded)
      setScreens([...screens, tempScreenData(companyData, isScreenBeingAdded)]);
  }, [isScreenBeingAdded]);

  useEffect(() => {
    if (canFetchScreensData) getScreens();
  }, [canFetchScreensData, getScreens]);

  const searchScreenHandler = useCallback(async () => {
    const { id: companyId = '' } = companyData || {};
    if (!companyId) {
      toaster.error('company data not found');
      return;
    }
    const response = await searchScreens(
      {
        name: searchScreenKey,
        company: companyId,
        folder: screensActiveFolder?.id || null,
      },
      setIsLoading
    );
    if (response) {
      setSearchedScreens(response);
    } else {
      setSearchedScreens(null);
    }
  }, [companyData, searchScreenKey, screensActiveFolder?.id]);

  useEffect(() => {
    if (searchScreenKey !== '') searchScreenHandler();
    else setSearchedScreens(null);
  }, [searchScreenKey, searchScreenHandler]);

  const data =
    Array.isArray(searchedScreens) &&
    searchedScreens.length > 0 &&
    searchScreenKey !== ''
      ? searchedScreens
      : screens;

  return (
    <div className={`${className} ${styles.clientWrapper}`}>
      {searchScreenKey !== '' && !Array.isArray(searchedScreens) ? (
        <p className={styles.notFound}>Result not found</p>
      ) : (
        <ScreenList isLoading={isLoading} data={data} onReorder={setScreens} />
      )}
    </div>
  );
}

Client.defaultProps = {
  className: '',
};

export default Client;

import React, { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES, CONTENT_TYPE } from 'constants/index';
import { UserRoles } from 'enums';
import { useStore } from 'store';
import { contentPageActions, layoutActions } from 'store/actions';
import { iState } from 'types';
import ContentPreview from './content-preview/contentPreview';
import WebContent from './web-content/webContent';
import PlaylistPreview from './playlist-preview/playlistPreview';
import TemplatePreview from './template-preview/templatePreview';
import styles from './sideDrawer.module.scss';

interface iProps {
  className?: string;
}

function SideDrawer(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { pathname } = useLocation();
  const {
    isShowContentPreview = false,
    isShowPlaylistPreview,
    isShowTemplatePreview,
  } = state.layout || {};
  const { contentView, templateView } = state.contentPage || {};
  const { playlistPreview } = state.playlistsPage || {};
  const { role } = state.user || {};
  const containerRef: any = useRef(null);

  //  console.log('isShowTemplatePreview____', isShowTemplatePreview);

  const isWebContent =
    contentView?.contentType === CONTENT_TYPE.Website || false;

  const closeDrawer = useCallback(() => {
    dispatch(layoutActions.toggleSideDrawer());
  }, [dispatch]);

  const isChild = useCallback((parent, child) => {
    let node = child.parentNode;
    while (node) {
      if (node.id === parent.id) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }, []);

  const clickOutSide = useCallback(
    (event: MouseEvent) => {
      const contentListItem = document.getElementById(
        'contentListingContainer'
      );
      const playlistContaierItem = document.getElementById('playlistContaier');
      const previewSidebarItem = document.getElementById('previewSidebar');

      let isContentItem = false;
      if (contentListItem) {
        isContentItem = isChild(contentListItem, event.target);
      }
      let isPlaylistItem = false;
      if (playlistContaierItem) {
        isPlaylistItem = isChild(playlistContaierItem, event.target);
      }
      let isPreviewSidebarItem = false;
      if (previewSidebarItem) {
        isPreviewSidebarItem = isChild(previewSidebarItem, event.target);
      }

      if (
        !isContentItem &&
        !isPlaylistItem &&
        !isPreviewSidebarItem &&
        !containerRef.current.contains(event.target)
      ) {
        dispatch(layoutActions.toggleSideDrawer());
      }
    },
    [dispatch, isChild]
  );

  useEffect(() => {
    dispatch(contentPageActions.setIsEditWebContent(true));
  }, []);

  useEffect(() => {
    if (
      isShowContentPreview ||
      isShowPlaylistPreview ||
      isShowTemplatePreview
    ) {
      document.addEventListener('click', clickOutSide);
    }
    return () => document.removeEventListener('click', clickOutSide);
  }, [
    clickOutSide,
    isShowContentPreview,
    isShowPlaylistPreview,
    isShowTemplatePreview,
  ]);

  return (
    <div
      id="previewSidebar"
      className={`${styles.sideDrawer} ${
        (isShowContentPreview ||
          isShowPlaylistPreview ||
          isShowTemplatePreview) &&
        styles.open
      } ${role === UserRoles.SuperAdmin && styles.superAdmin} ${
        pathname === ROUTES.playlist && styles.playlistDetails
      } ${className}`}
      ref={containerRef}
    >
      {/* {isWebContent ? <WebContent close={closeDrawer} />} */}

      {isShowContentPreview &&
        contentView &&
        (isWebContent ? (
          <WebContent data={contentView} close={closeDrawer} />
        ) : (
          <ContentPreview data={contentView} close={closeDrawer} />
        ))}
      {isShowPlaylistPreview && playlistPreview && (
        <PlaylistPreview data={playlistPreview} close={closeDrawer} />
      )}
      {isShowTemplatePreview && templateView && (
        <TemplatePreview data={templateView} close={closeDrawer} />
      )}
    </div>
  );
}

SideDrawer.defaultProps = {
  className: '',
};

export default SideDrawer;

import {
  companyIcon,
  ScreenTabIcon,
  playlistsIcon,
  dustbinIcon,
  userIcon,
} from 'constants/index';

export enum DeleteModalVariants {
  Screen = 'screen',
  Client = 'client',
  Content = 'content',
  MSAccount = 'msaccount',
  Playlist = 'playlist',
  TeamMember = 'teamMember',
  PlaylistContent = 'playlistContent',
  Folder = 'folder',
  Teammate = 'teamMate',
}

export enum DeletingTo {
  Screen = 'screen',
  Client = 'client',
  MSAccount = 'MS Account',
  Content = 'content',
  Playlist = 'playlist',
  TeamMember = 'team member',
  Folder = 'folder',
  TeamMate = 'Teammate Name',
}

export const Variants = {
  [DeleteModalVariants.Screen]: {
    deletingTo: DeletingTo.Screen,
    heading: 'Delete ',
    description:
      'Are you sure you want to delete this screen? This is Permanent',
    icon: ScreenTabIcon,
  },
  [DeleteModalVariants.Client]: {
    deletingTo: DeletingTo.Client,
    heading: 'Delete ',
    description:
      'Are you sure you want to delete this client? This is Permanent',
    icon: companyIcon,
  },
  [DeleteModalVariants.MSAccount]: {
    deletingTo: DeletingTo.MSAccount,
    heading: 'Delete ',
    description:
      'Are you sure you want to delete this MS account? This is Permanent',
    icon: companyIcon,
  },
  [DeleteModalVariants.Content]: {
    deletingTo: DeletingTo.Content,
    heading: 'Delete ',
    description:
      'Are you sure you want to delete this content? This is Permanent',
    icon: companyIcon,
  },
  [DeleteModalVariants.Playlist]: {
    deletingTo: DeletingTo.Playlist,
    heading: 'Delete ',
    description:
      'Are you sure you want to delete this content? This is Permanent',
    icon: playlistsIcon,
  },
  [DeleteModalVariants.TeamMember]: {
    deletingTo: DeletingTo.TeamMember,
    heading: 'Remove ',
    description:
      'Are you sure you want to remove from the team? This is Permanent',
    icon: playlistsIcon,
  },
  [DeleteModalVariants.Folder]: {
    deletingTo: DeletingTo.Folder,
    heading: 'Delete ',
    description:
      'Are you sure you want to delete folder? All folders and contents under this folder will be deleted',
    icon: dustbinIcon,
  },
  [DeleteModalVariants.Teammate]: {
    deletingTo: DeletingTo.TeamMate,
    heading: 'Remove ',
    description: 'Are you sure you want to remove from the team?',
    icon: userIcon,
  },
};

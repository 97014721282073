import React, { useCallback, useState } from 'react';
import { ROUTES, caretLeft } from 'constants/index';
import Dustbin from 'components/svg/dustbin';
import { iState } from 'types';
import { userActions, layoutActions, commonActions } from 'store/actions';
import { useStore } from 'store';
import { ModalViews, UserSettingsView } from 'enums';
import Button, { ButtonVariants } from 'components/button/button';
import { deleteUser } from 'apis';
import { toaster } from 'helpers';
import DeleteModal, {
  DeleteModalVariants,
} from 'components/delete-modal/deleteModal';
import { useNavigate } from 'react-router-dom';
import styles from './subheader.module.scss';

interface iProps {
  className?: string;
  roleName?: string;
  isDisabled: boolean;
  saveChanges: () => void;
}

function Subheader(props: iProps): JSX.Element {
  const { className, roleName, isDisabled, saveChanges } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { removeTeammateData, loggedInData } = state?.user || {};
  const { layout } = state || {};
  const { isShowDeleteContent } = layout || {};
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isContentUsedSomeWhere, setIsContentUsedSomeWhere] =
    useState<boolean>(false);
  const { companyData } = state.homePage || {};
  console.log(setIsDeleting, isContentUsedSomeWhere);
  const navigate = useNavigate();
  const { removeTeammates } = state.user.userPermissions || {};

  const changeView = useCallback(() => {
    navigate(ROUTES.userSettingsTeamMembers);
    dispatch(userActions.changeView(UserSettingsView.TeamMembers));
  }, [dispatch]);

  const removeTeammateHandler = useCallback(async () => {
    const { companyUserId } = removeTeammateData || {};
    if (!companyUserId) return;

    const response = await deleteUser(companyUserId, () => {});
    if (response) {
      if (
        removeTeammateData?.id === loggedInData?.id &&
        removeTeammateData?.email === loggedInData?.email
      ) {
        dispatch(commonActions.reset());
      }
      toaster.success('The teammate removed successfully ');
      dispatch(layoutActions.toggleModal());
      // dispatch(userActions.changeView(UserSettingsView.TeamMembers));
      navigate(ROUTES.userSettingsTeamMembers, { replace: true });
    }
  }, [removeTeammateData, dispatch]);

  const openDeleteTeammateModal = useCallback(() => {
    dispatch(layoutActions.toggleModal(ModalViews.isShowDeleteContent));
  }, [dispatch]);

  return (
    <>
      <div className={`${styles.subheader} ${className}`}>
        <div className={styles.left}>
          <div className={styles.heading} onClick={changeView}>
            <img className={styles.arrowIcon} src={caretLeft} alt="" />
            <p>{roleName?.split('_').join(' ')}</p>
          </div>
        </div>
        <div className={styles.right}>
          <Button
            className={styles.btnStyle}
            btnName="Cancel"
            variant={ButtonVariants.SmallTransp}
            onClick={changeView}
          />
          <button
            className={`${styles.btnStyle} ${styles.removeButton} ${
              !removeTeammates && styles.disabled
            }`}
            type="button"
            onClick={(event) => {
              const teamMemberCanDeleteOwner =
                companyData?.role?.id === '3' &&
                removeTeammateData?.role?.id === '2';
              if (!teamMemberCanDeleteOwner) {
                if (!removeTeammates)
                  toaster.error('You do not have access to remove');
                if (removeTeammates) {
                  event.stopPropagation();
                  openDeleteTeammateModal();
                }
              } else {
                toaster.error(
                  'Team member do not have the access to delete the owner'
                );
              }
            }}
            //   () => {
            //   if (removeTeammates) openDeleteTeammateModal();
            // }}
          >
            <Dustbin className={styles.distbinIcon} />
            Remove team member
          </button>

          <Button
            className={styles.btnStyle}
            btnName="Save changes"
            variant={ButtonVariants.SmallStd}
            isDisabled={!isDisabled}
            onClick={saveChanges}
          />
        </div>
      </div>
      <DeleteModal
        isOpen={isShowDeleteContent}
        variant={DeleteModalVariants.Teammate}
        name={state.user.userId || ''}
        isLoading={isDeleting}
        onClose={() => setIsContentUsedSomeWhere(false)}
        confirmationMsg="Are you sure you want to remove this user?"
        usageNotification="The user will no longer be able to access Screenz."
        deleteHandler={removeTeammateHandler}
        displayInput={false}
      />
    </>
  );
}

Subheader.defaultProps = {
  className: '',
  roleName: '',
};

export default Subheader;

import { getContentsByCompany, searchContent } from 'apis';
import AddFolderModal from 'components/add-folder-modal/addFolderModal';
import AddTemplateModal from 'components/add-template/addTemplate';
import Layout, { LayoutVariants } from 'components/Layouts/layout';
import UploadContentModal from 'components/upload-content-modal/uploadContentModal';
import UploadContentWebpageModal from 'components/upload-content-webpage-modal/uploadContentWebpageModal';
import { toaster } from 'helpers';
// import Loader from 'components/loader/loader';
import SideDrawer from 'components/side-drawer/sideDrawer';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'store';
import { contentPageActions, layoutActions } from 'store/actions';
import { iContentData, iState } from 'types';
import { useLocation, useNavigate } from 'react-router-dom';
// import FileStack from 'modules/file-stack/filestack';
import { ROUTES } from 'constants/index';
import ContentList from './components/content-list/contentList';

import styles from './content.module.scss';

// const DUMMY_TEMPLATE_CONTENT = {
//   id: '24558b422-ab01-48e1-9dca-2f5ac4a3845f',
//   name: 'Meeting Room',
//   dimension: '',
//   url: '',
//   orientation: '',
//   contentType: 'template',
//   size: '',
//   thumbnailPath: '',
//   updated_at: '2022-08-23T08:30:38.927Z',
//   user: {
//     id: '233e2db3-d16c-46c1-9c81-529958a13dca',
//     first_name: 'Ram',
//     last_name: '',
//   },
//   duration: '',
//   minutes: '',
//   seconds: '',
//   folder: '',
//   company: '',
// };

function Content(): JSX.Element {
  const [state, dispatch]: [iState, any] = useStore();
  const { homePage, contentPage } = state || {};
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(contentPageActions.setContents([]));
  }, []);

  useEffect(() => {
    document.title = 'Content';
  }, []);

  const [searchContentData, setSearchContentData] =
    useState<Array<iContentData> | null>(null);
  const {
    contents,
    canFetchContentsData,
    searchContentKey,
    contentActiveFolder,
  } = contentPage || {};
  const { companyData } = homePage || {};
  const { id: companyId, companyUserId } = companyData || {};
  const { id: folderId } = contentActiveFolder || {};
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!companyData) {
      navigate(ROUTES.chooseTeam);
    }
  }, [pathname]);

  const getContents = useCallback(async () => {
    if (!companyId) return;
    const response = await getContentsByCompany(
      {
        company: companyId,
        folder: folderId || '',
        companyUserId: companyUserId || null,
      },
      setIsLoading
    );
    if (response) {
      dispatch(contentPageActions.setContents(response));
      dispatch(contentPageActions.fetchContentsData(false));
    }
  }, [companyId, folderId, companyUserId, dispatch]);

  useEffect(() => {
    getContents();
  }, [getContents]);

  useEffect(() => {
    if (canFetchContentsData) getContents();
  }, [canFetchContentsData, getContents]);

  const searchContentHandler = useCallback(async () => {
    if (!companyId) {
      toaster.error('Company data not found');
      return;
    }
    const response = await searchContent(
      {
        name: searchContentKey,
        company: companyId,
        folder: folderId || null,
      },
      setIsLoading
    );
    if (response) {
      setSearchContentData(response);
    } else {
      setSearchContentData(null);
    }
  }, [companyId, searchContentKey, folderId]);

  useEffect(() => {
    if (searchContentKey !== '') searchContentHandler();
    else setSearchContentData(null);
  }, [searchContentKey, searchContentHandler, dispatch, contents]);

  useEffect(() => {
    return () => {
      dispatch(layoutActions.toggleSideDrawer());
    };
  }, [dispatch]);

  // const data = searchContentData?.length > 0 ? searchContentData : contents;

  const data =
    (Array.isArray(searchContentData) && searchContentData.length > 0) ||
    searchContentData?.length === 0
      ? searchContentData
      : contents;
  console.log(state);

  return (
    <Layout variant={LayoutVariants.layoutTwo}>
      <>
        <section className={styles.contentWrapper}>
          {/* {isLoading && <Loader />} */}
          {searchContentKey !== '' && searchContentData?.length === 0 && (
            <p className={styles.notFound}>Result not found</p>
          )}
          {searchContentData?.length !== 0 && (
            <ContentList data={data} isLoading={isLoading} />
          )}
          <AddFolderModal />
          <AddTemplateModal />
          <UploadContentModal />
          <UploadContentWebpageModal />
        </section>
        <SideDrawer />
      </>
    </Layout>
  );
}

export default Content;

import LabelAndInput from 'components/label-and-input/labelAndInput';
import Modal from 'components/modal/modal';
import { toaster } from 'helpers';
import { addRole } from 'apis';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'store';
import { layoutActions, userActions } from 'store/actions';
import { iState } from 'types';
import styles from './addRoleModal.module.scss';

function AddRoleModal(): JSX.Element {
  const [state, dispatch]: [iState, any] = useStore();
  const [roleName, setRoleName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { isShowAddRole = false } = state.layout || {};

  const onChange = useCallback((event): void => {
    setRoleName(event.target.value);
    setErrorMessage('');
  }, []);

  const addRoleHandler = useCallback(async () => {
    if (!roleName) {
      setErrorMessage('Please enter a role name');
      return;
    }

    const response = await addRole(roleName, setIsLoading);
    if (response) {
      toaster.success('Role added succesfully');
      dispatch(layoutActions.toggleModal());
      dispatch(userActions.setCanFetchRoles(true));
    }
  }, [dispatch, roleName]);

  const closeModalHandler = useCallback(() => {
    dispatch(layoutActions.toggleModal());
  }, [dispatch]);

  useEffect(() => {
    const inputElement = document.getElementById('roleName');
    if (inputElement && isShowAddRole) {
      inputElement.focus();
    }
  }, [isShowAddRole]);

  return (
    <Modal
      isOpen={isShowAddRole}
      heading="Create Role"
      btnName="Add Role"
      onClose={closeModalHandler}
      submit={addRoleHandler}
      isLoading={isLoading}
    >
      <div className={styles.addRoleModal}>
        <div className={styles.labelAndInput}>
          <LabelAndInput
            className={styles.input}
            name="name"
            id="roleName"
            label={errorMessage && `Role Name`}
            placeholder="Give a role name"
            value={roleName}
            onChange={onChange}
            errorMessage={errorMessage}
          />
        </div>
      </div>
    </Modal>
  );
}

export default AddRoleModal;

import {
  folderIcon,
  connectionIcon,
  disconnectionIcon,
  rootFolderId,
} from 'constants/index';
import React, { useEffect, useState } from 'react';
import ShareAccess from 'components/share-access/shareAccess';
// import { getOnlineStatus } from 'helpers';
import PrimaryDetailsSkeleton from 'components/skeletons/primary-details/primaryDetails';
import CurrentFolder, {
  CurrentFolderVariants,
} from 'components/current-folder/currentFolder';
import { useStore } from 'store';
import { iScreenData, iState } from 'types';
// import { getScreensStatusByCompany } from 'apis';
import { FolderType, itemType } from 'enums';
import styles from './screenPrimaryDetails.module.scss';

interface iProps {
  className?: string;
  data: iScreenData[];
  isLoading: boolean;
}

interface iDeviceStatusCounts {
  offlineCount: number;
  onlineCount: number;
}

function ScreenPrimaryDetails(props: iProps): JSX.Element {
  const { className, isLoading, data } = props;
  const [state]: [iState] = useStore();
  const { screensActiveFolder, totalOfflineCount, totalOnlineCount } =
    state.homePage || {};
  const [deviceStatusCounts, setDeviceStatusCounts] =
    useState<iDeviceStatusCounts>({
      offlineCount: 0,
      onlineCount: 0,
    });

  // const [rootFolderDeviceStatusCounts, setRootFolderDeviceStatusCounts] =
  //   useState<iDeviceStatusCounts>({
  //     offlineCount: 0,
  //     onlineCount: 0,
  //   });
  // const getOnlineStatus = useCallback((lastPingTime) => {
  //   const ONE_MINUT = 60000;
  //   const difference = new Date().getTime() - new Date(lastPingTime).getTime();
  //   if (difference < ONE_MINUT) return true;
  //   return false;
  // }, []);

  // const getScreensStatusHandler = useCallback(async (company) => {
  //   if (!company) return;

  //   const response = await getScreensStatusByCompany({
  //     companyId: company.id,
  //   });
  //   if (response) {
  //     const onlineStatusMapped = response.map((i) => ({
  //       ...i,
  //       lastPing: response.find((j) => j.id === i.id)?.lastPing || '',
  //     }));

  //     let offlineCount = 0;
  //     let onlineCount = 0;
  //     if (onlineStatusMapped) {
  //       onlineStatusMapped.forEach((element) => {
  //         if (getOnlineStatus(element.lastPing)) {
  //           onlineCount += 1;
  //         } else {
  //           offlineCount += 1;
  //         }
  //       });
  //       setRootFolderDeviceStatusCounts({ offlineCount, onlineCount });
  //     } else {
  //       setRootFolderDeviceStatusCounts({ offlineCount: 0, onlineCount: 0 });
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   console.log(totalOfflineCount, totalOnlineCount, 'totalOnlineCount');
  // }, [totalOfflineCount, totalOnlineCount]);

  useEffect(() => {
    let offlineCount = 0;
    let onlineCount = 0;
    if (data) {
      data.forEach((element) => {
        // if (getOnlineStatus(element.lastPing)) {
        if (element.isOnline) {
          onlineCount += 1;
        } else {
          offlineCount += 1;
        }
      });
      setDeviceStatusCounts({ offlineCount, onlineCount });
    } else {
      setDeviceStatusCounts({ offlineCount: 0, onlineCount: 0 });
    }
  }, [data]);

  return (
    <div className={`${className} ${styles.screenzDetails}`}>
      {isLoading ? (
        <PrimaryDetailsSkeleton />
      ) : (
        <>
          {screensActiveFolder && (
            <CurrentFolder variant={CurrentFolderVariants.Screens} />
          )}

          <div className={styles.primaryDetails}>
            {!screensActiveFolder && (
              <>
                {' '}
                <img className={styles.folderIcon} src={folderIcon} alt="" />
                <p className={styles.paragraphOne}>Your Screens</p>
                <p className={styles.paragraphTwo}>Status</p>
              </>
            )}

            <div className={styles.container}>
              <div className={styles.connectionStatus}>
                <div className={`${styles.statusIcon} ${styles.connected}`}>
                  <img src={connectionIcon} alt="" />
                </div>
                <span className={styles.count}>
                  {screensActiveFolder
                    ? deviceStatusCounts.onlineCount
                    : totalOnlineCount || 0}
                </span>
                <span className={styles.message}>Active Screens</span>
              </div>
              <div className={styles.connectionStatus}>
                <div className={`${styles.statusIcon} ${styles.disconnected}`}>
                  <img src={disconnectionIcon} alt="" />
                </div>
                <span className={styles.count}>
                  {screensActiveFolder
                    ? deviceStatusCounts.offlineCount
                    : totalOfflineCount || 0}
                </span>
                <span className={styles.message}>Players are offline</span>
              </div>
            </div>
            <ShareAccess
              className={styles.screenFolderShare}
              shareType={itemType.FOLDER}
              folderType={FolderType.SCREENZ}
              heading={screensActiveFolder?.name || 'Screen'}
              shareItemId={screensActiveFolder?.id || rootFolderId}
            />
          </div>
        </>
      )}
    </div>
  );
}

ScreenPrimaryDetails.defaultProps = {
  className: '',
};

export default ScreenPrimaryDetails;

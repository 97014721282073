export const getOnlineStatus = (lastPingTime: string | null): boolean => {
  if (!lastPingTime) return false;

  const ONE_MINUT = 60000 * 1;
  const difference = new Date().getTime() - new Date(lastPingTime).getTime();
  if (difference < ONE_MINUT) return true;
  return false;
};

export const getNewlySharedStatus = (
  lastPingTime: string | null | undefined
): boolean => {
  if (!lastPingTime) return false;
  const TWENTY_FOUR_HOURS = 60000 * 60 * 24;
  const difference = new Date().getTime() - new Date(lastPingTime).getTime();
  if (difference < TWENTY_FOUR_HOURS) return true;
  return false;
};

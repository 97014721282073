import { Mutation, Query } from 'config';
import { captureException } from 'helpers/sentry';
import { iGraphqlResponse } from 'types';
import {
  iGetCompaniesFeaturesArgs,
  iGetCompaniesWithFeaturesData,
  iGetCompaniesWithFeaturesResponse,
  iUpdateCompanyFeatureArgs,
  iUpdateCompanyFeatureData,
  iUpdateCompanyFeatureResponse,
} from 'types/accessManagement';
import {
  getCompaniesWithFeaturesQuery,
  updateCompanyFeatureMutation,
} from '../graphql/index';

export const getCompaniesWithFeatures = async (
  args: iGetCompaniesFeaturesArgs,
  isLoading
): Promise<Array<iGetCompaniesWithFeaturesData> | null> => {
  try {
    isLoading(true);
    const response = await (<
      iGraphqlResponse<iGetCompaniesWithFeaturesResponse>
    >await Query(getCompaniesWithFeaturesQuery, args));
    isLoading(false);
    return response.data.getCompaniesWithFeatures.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const updateCompanyFeature = async (
  args: iUpdateCompanyFeatureArgs,
  isLoading
): Promise<iUpdateCompanyFeatureData | null> => {
  try {
    isLoading(true);
    const response = await (<iGraphqlResponse<iUpdateCompanyFeatureResponse>>(
      await Mutation(updateCompanyFeatureMutation, args)
    ));
    isLoading(false);
    return response.data.updateFeatureOfCompany.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

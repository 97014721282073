import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'store';
import { CalenderSettingsView, EventTemplateType, LanguageType } from 'enums';
import TemplateIcon from 'components/svg/template';
import { meetingOverviewFullActions, office365Actions } from 'store/actions';
import { iState } from 'types';
import englishData from '../../english.json';
import norwayData from '../../norwegian.json';
import styles from './rightSidebar.module.scss';

interface iProps {
  className?: string;
}

function RightSidebar(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { currentView } = state.office365 || {};
  const { TemplateSetting } = CalenderSettingsView;
  const { templateToView } = state.layout || {};
  const [templateTexts, setTemplateTexts] = useState<any>(englishData);

  const { selectedLanguage } = state.office365 || {};

  useEffect(() => {
    if (selectedLanguage.value === LanguageType.English) {
      setTemplateTexts(englishData);
    }
    if (selectedLanguage.value === LanguageType.Norwegian) {
      setTemplateTexts(norwayData);
    }
  }, [selectedLanguage]);

  const onChangeView = useCallback(() => {
    if (templateToView === EventTemplateType.meetingRoom) {
      dispatch(office365Actions.setCurrentView(TemplateSetting));
    }
    if (templateToView !== EventTemplateType.meetingRoom) {
      dispatch(meetingOverviewFullActions.setCureentView(TemplateSetting));
    }
  }, [dispatch, TemplateSetting]);

  const isMeetingRoomTemplateSettings = currentView === TemplateSetting;
  const { currentView: meetingOverviewFullCurrentView } =
    state.meetingOverviewFull || {};
  const isMeetingOverviewFullTemplateSettings =
    meetingOverviewFullCurrentView === TemplateSetting;
  const hideSideBorder =
    templateToView === EventTemplateType.meetingOverviewMessage ||
    templateToView === EventTemplateType.meetingOverviewPotrait;
  return (
    <div
      className={`${styles.rightSidebar} ${className}${
        hideSideBorder && styles.hideSideBarBorder
      }`}
    >
      <div className={styles.templateIconContainer} onClick={onChangeView}>
        <div
          className={`${styles.menuContainer} ${
            (isMeetingOverviewFullTemplateSettings ||
              isMeetingRoomTemplateSettings) &&
            styles.active
          }`}
        >
          <TemplateIcon className={styles.templatesIcon} />
          {/* <img className={styles.templatesIcon} src={templatesIcon} alt="" /> */}
        </div>
        <span
          className={`${styles.menu} ${
            (isMeetingOverviewFullTemplateSettings ||
              isMeetingRoomTemplateSettings) &&
            styles.active
          }`}
        >
          {templateTexts.template}
        </span>
      </div>
    </div>
  );
}

RightSidebar.defaultProps = {
  className: '',
};

export default RightSidebar;

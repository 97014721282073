import React, { useState } from 'react';
import styles from './featureWithSwitch.module.scss';

interface iProps {
  className?: string;
  feature: string;
  isAccessible: boolean;
  isNotDisabled?: boolean;
  onChange: (a: boolean) => void;
}

function FeatureWithSwitch(props: iProps): JSX.Element {
  const { className, feature, isAccessible, onChange, isNotDisabled } = props;
  const [isActive, setIsActive] = useState<boolean>(isAccessible);
  const [notDisabled] = useState(isNotDisabled);
  //  console.log({ isAccessible });

  return (
    <div className={`${styles.featureWithSwitch} ${className}`}>
      <p className={styles.feature}>{feature}</p>
      <div
        className={`${styles.switchBtn} ${isActive && styles.active}`}
        onClick={() => {
          if (notDisabled === true) {
            setIsActive(!isActive);
            onChange(!isActive);
          }
        }}
        style={{ opacity: notDisabled ? 1 : 0.5 }}
      >
        <span className={`${styles.nob} ${isActive && styles.active}`} />
      </div>
    </div>
  );
}

FeatureWithSwitch.defaultProps = {
  className: '',
  isNotDisabled: false,
};

export default FeatureWithSwitch;

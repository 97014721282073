import React from 'react';

interface iProps {
  className?: string;
}

function Template(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h9zm0 2H3v6h9V3zm9-2a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h3zm0 2h-3v6h3V3zm2 12a2 2 0 0 0-2-2h-6a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-6zm-2 0v6h-6v-6h6zM9 13a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2h6zm0 2H3v6h6v-6z"
        fill="#615AE2"
      />
    </svg>
  );
}

Template.defaultProps = {
  className: '',
};

export default Template;

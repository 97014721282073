import { uuid } from 'helpers';
import { COMMON, PLAYLIST_PAGE } from 'store/constants';
import { iPlaylistData, iPlaylistsPageState } from 'types';
import { initialState } from '../initialState/initialState';

export const playlistsPageReducer = (
  state: iPlaylistsPageState,
  action
): iPlaylistsPageState => {
  switch (action.type) {
    case PLAYLIST_PAGE.setPlaylists: {
      return {
        ...state,
        playlists: action.payload,
      };
    }
    case PLAYLIST_PAGE.setPlaylistData: {
      //  console.log('PLAYLIST_PAGE.setPlaylistData', action.payload);

      return {
        ...state,
        playlistData: action.payload,
      };
    }
    case PLAYLIST_PAGE.invokeDeletePlaylist: {
      return {
        ...state,
        canDeletePlaylist: action.payload,
      };
    }
    case PLAYLIST_PAGE.addPlaylist: {
      const playlists: iPlaylistData[] = [...state.playlists];

      playlists.push({
        id: uuid(),
        name: action.payload,
        screenType: 'Landscape',
        duration: '00:00:00',
        status: 'RUNNING',
        dimention: '',
        companyId: '',
      });
      return {
        ...state,
        playlists,
      };
    }
    case PLAYLIST_PAGE.addContentToPlaylist: {
      let contentToAddToPlaylist = [...state.contentToAddToPlaylist];
      if (contentToAddToPlaylist.map((i) => i.id).includes(action.payload.id)) {
        contentToAddToPlaylist = contentToAddToPlaylist.filter(
          (i) => i.id !== action.payload.id
        );
        return {
          ...state,
          contentToAddToPlaylist,
        };
      }
      // //  console.log(state.contentToAddToPlaylist, 'contentToAddToPlaylsit');
      contentToAddToPlaylist = [...contentToAddToPlaylist, action.payload];
      return {
        ...state,
        contentToAddToPlaylist,
      };
    }
    case PLAYLIST_PAGE.fetchPlaylistsData: {
      return {
        ...state,
        canFetchPlaylistData: action.payload,
      };
    }
    case PLAYLIST_PAGE.clearAddContentToPlaylist: {
      return {
        ...state,
        contentToAddToPlaylist: [],
      };
    }
    case PLAYLIST_PAGE.setContentView: {
      return {
        ...state,
        contentView: action.payload,
      };
    }
    case PLAYLIST_PAGE.toggleLlaylistSaveAndCloseBtn: {
      return {
        ...state,
        isPlaylistSaveAndCloseBtnDisabled: action.payload,
      };
    }
    case PLAYLIST_PAGE.setPlaylistPreview: {
      return {
        ...state,
        playlistPreview: action.payload,
      };
    }
    case PLAYLIST_PAGE.invokeUpdatePlaylist: {
      return {
        ...state,
        canUpdatePlaylist: action.payload,
      };
    }
    case PLAYLIST_PAGE.updatePlaylist: {
      return {
        ...state,
        playlistData: action.payload,
      };
    }
    case PLAYLIST_PAGE.setSearchPlaylistKey: {
      return {
        ...state,
        searchPlaylistKey: action.payload,
      };
    }
    case PLAYLIST_PAGE.setPlaylistsFolders: {
      return {
        ...state,
        playlistFolders: action.payload,
      };
    }
    case PLAYLIST_PAGE.setPlaylistsActiveFolder: {
      return {
        ...state,
        playlistActiveFolder: action.payload,
      };
    }
    case PLAYLIST_PAGE.showNewlyAssignedContentsInList: {
      return {
        ...state,
        isShowNewlyAssignedContentsInList: action.payload,
      };
    }
    case PLAYLIST_PAGE.setPlaylistsFolderHierarchy: {
      return {
        ...state,
        playlistsFolderHierarchy: action.payload,
      };
    }
    case COMMON.reset: {
      return initialState.playlistsPage;
    }
    default:
      return state;
  }
};

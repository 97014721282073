import { Mutation, Query } from 'config';
import { captureException } from 'helpers/sentry';
import { DeleteStatus } from 'enums';

import {
  iAddContentArgs,
  iAddContentResponse,
  iContentData,
  iGetcontentbyidResponse,
  iGetContentByCompanyIdArgs,
  iGetContentByCompanyIdResponse,
  iGraphqlResponse,
  iSearchContentArgs,
  iSearchContentResponse,
  iDeleteContentArgs,
  iDeleteContentResponse,
  iUpdateContentResponse,
  iEditWebContentArgs,
  iValidateURLResponse,
  iValidateURLData,
  // iDeleteContentData,
} from 'types';
import {
  addContentMutation,
  getContentByCompanyQuery,
  searchContentMutation,
  deleteContentMutation,
  editWebContentMutation,
  validateUrlQuery,
  getContentByIdQuery,
} from '../graphql';

export const getContentsByCompany = async (
  args: iGetContentByCompanyIdArgs,
  isLoading?: (type: boolean) => void
): Promise<Array<iContentData> | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = <iGraphqlResponse<iGetContentByCompanyIdResponse>>(
      await Query(getContentByCompanyQuery, { ...args })
    );

    if (isLoading) isLoading(false);
    return response.data.getcontentbyCompanyid.data;
  } catch (error: any) {
    if (isLoading) isLoading(false);
    captureException(error);
    return [];
  }
};

export const addContent = async (
  args: iAddContentArgs,
  isLoading?: (type: boolean) => void
): Promise<iContentData | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = <iGraphqlResponse<iAddContentResponse>>(
      await Mutation(addContentMutation, args)
    );
    if (isLoading) isLoading(false);
    // console.log(response.data.addContent);
    return response.data.addContent.data;
  } catch (error: any) {
    if (isLoading) isLoading(false);
    // console.log(error);
    captureException(error);
    return null;
  }
};

export const searchContent = async (
  args: iSearchContentArgs,
  isLoading?: (type: boolean) => void
): Promise<Array<iContentData> | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = <iGraphqlResponse<iSearchContentResponse>>(
      await Mutation(searchContentMutation, args)
    );
    if (isLoading) isLoading(false);
    return response.data.searchContentbyname.data;
  } catch (error: any) {
    captureException(error);
    if (isLoading) isLoading(false);
    return null;
  }
};

export const editWebContent = async (
  args: iEditWebContentArgs,
  isLoading?: (type: boolean) => void
): Promise<iContentData | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = <iGraphqlResponse<iUpdateContentResponse>>(
      await Mutation(editWebContentMutation, args)
    );
    if (isLoading) isLoading(false);
    return response.data.updateContent.data;
  } catch (error: any) {
    captureException(error);
    if (isLoading) isLoading(false);
    return null;
  }
};

export const validateUrl = async (
  url: string,
  isLoading?: (type: boolean) => void
): Promise<iValidateURLData | null> => {
  try {
    if ([].length === 0) return { valid: true };
    if (isLoading) isLoading(true);
    const response = <iGraphqlResponse<iValidateURLResponse>>(
      await Query(validateUrlQuery, { url })
    );
    if (isLoading) isLoading(false);
    return response.data.validateUrl.data;
  } catch (error: any) {
    captureException(error);
    if (isLoading) isLoading(false);
    return null;
  }
};

export const getContent = async (
  id: string,
  isLoading?: (type: boolean) => void
): Promise<iContentData | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = <iGraphqlResponse<iGetcontentbyidResponse>>(
      await Query(getContentByIdQuery, { id })
    );
    if (isLoading) isLoading(false);
    return response.data.getcontentbyid.data;
  } catch (error: any) {
    captureException(error);
    if (isLoading) isLoading(false);
    return null;
  }
};

export const deleteContent = async (
  args: iDeleteContentArgs,
  isLoading?: (type: boolean) => void
): Promise<string | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = <iGraphqlResponse<iDeleteContentResponse>>(
      await Mutation(deleteContentMutation, args)
    );
    if (isLoading) isLoading(false);
    const {
      isDeleted,
      playLists = [],
      screenz = [],
    } = response.data.deleteContent.data;
    if (!isDeleted && playLists.length === 0 && screenz.length === 0)
      return DeleteStatus.CanDelete;
    if (isDeleted) {
      return DeleteStatus.Deleted;
    }
    if (playLists.length > 0 || screenz.length > 0)
      return DeleteStatus.UsedSomewhere;
    return '';
  } catch (error: any) {
    captureException(error);
    if (isLoading) isLoading(false);
    return null;
  }
};

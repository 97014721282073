import Button, { ButtonVariants } from 'components/button/button';
import React from 'react';
import { iState } from 'types';
import OnlineStatusPill from 'components/online-status-pill/onlineStatusPill';
import PingTime from 'components/ping-time/pingTime';
import { useStore } from 'store';
import styles from './subheader.module.scss';

interface iProps {
  className?: string;
  onClick?: (btnName: 'Screen' | 'Remote') => void;
  view?: 'Screen' | 'Remote';
  isLoading: boolean;
}

function Subheader(props: iProps): JSX.Element {
  const { className, onClick, view, isLoading } = props;
  const [state]: [iState] = useStore();
  const { screenData } = state.homePage || {};
  const { lastPing } = screenData || {};

  const {
    // lastPing = '',
    isOnline,
  } = screenData || {};
  return (
    <div className={`${className} ${styles.screenDetailsSubHeader}`}>
      <div className={styles.colOne}>
        <Button
          btnName="Screen"
          className={styles.changeViewBtn}
          isActive={view === 'Screen'}
          variant={ButtonVariants.ChangeView}
          onClick={() => onClick && onClick('Screen')}
        />
        <Button
          btnName="Remote"
          isActive={view === 'Remote'}
          className={styles.changeViewBtn}
          variant={ButtonVariants.ChangeView}
          onClick={() => onClick && onClick('Remote')}
        />
      </div>
      <div className={styles.colTwo}>
        <OnlineStatusPill
          className={styles.onlineStatusPill}
          isOnline={isOnline || false}
        />
        <PingTime
          className={styles.pingTime}
          isLoading={isLoading}
          lastPing={lastPing || ''}
        />
      </div>
    </div>
  );
}

Subheader.defaultProps = {
  className: '',
  view: 'Screen',
  onClick: () => {
    //
  },
};

export default Subheader;

import { folderIcon, rootFolderId } from 'constants/index';
import React from 'react';
import { useStore } from 'store';
import CurrentFolder, {
  CurrentFolderVariants,
} from 'components/current-folder/currentFolder';
import PrimaryDetailsSkeleton from 'components/skeletons/primary-details/primaryDetails';
import ShareAccess from 'components/share-access/shareAccess';
import { iState } from 'types';
import { FolderType, itemType } from 'enums';
import styles from './contentDetails.module.scss';

interface iProps {
  className?: string;
  isLoading: boolean;
}

function ContentDetails(props: iProps): JSX.Element {
  const { className, isLoading } = props;
  const [state]: [iState] = useStore();
  const { homePage } = state || {};
  const { contentActiveFolder } = state.contentPage || {};
  const { activeFolder } = homePage || {};

  return (
    <div className={`${className} ${styles.contentDetails}`}>
      {isLoading ? (
        <PrimaryDetailsSkeleton />
      ) : (
        <>
          {contentActiveFolder && (
            <CurrentFolder variant={CurrentFolderVariants.Contents} />
          )}

          {!contentActiveFolder && (
            <>
              <img className={styles.folderIcon} src={folderIcon} alt="" />
              <p className={styles.paragraphOne}>
                {activeFolder?.name || 'Your Contents'}
              </p>
            </>
          )}

          <ShareAccess
            className={styles.shareAccess}
            shareType={itemType.FOLDER}
            folderType={FolderType.CONTENT}
            heading={contentActiveFolder?.name || 'Content'}
            shareItemId={contentActiveFolder?.id || rootFolderId}
          />
        </>
      )}
    </div>
  );
}

ContentDetails.defaultProps = {
  className: '',
};

export default ContentDetails;

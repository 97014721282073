import { logoutIcon, ROUTES } from 'constants/index';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { commonActions, userActions } from 'store/actions';
import { iState } from 'types';
import styles from './logout.module.scss';

interface iProps {
  className?: string;
}

function Logout(props: iProps): JSX.Element {
  const { className } = props;

  const [, dispatch]: [iState, any] = useStore();
  const navigate = useNavigate();

  const logout = useCallback(() => {
    window.Intercom('shutdown');
    localStorage.removeItem('isLoggedIn');
    window.Intercom('boot', {
      app_id: 'd7jw43br',
    });
    dispatch(userActions.setClientChoosed(false));
    dispatch(commonActions.reset());
    navigate(ROUTES.login);
  }, [dispatch, navigate]);

  return (
    <div className={`${className} ${styles.menu}`} onClick={logout}>
      <img src={logoutIcon} alt="" />
      <p>Logout</p>
      {/* <img src={caretLeft} alt="" /> */}
    </div>
  );
}

Logout.defaultProps = {
  className: '',
};

export default Logout;

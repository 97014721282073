import React, { useCallback, useEffect, useState } from 'react';
import { iAddOrUpdatePermissionsArgs, iPermissionsData, iState } from 'types';
import FeatureWithSwitch from 'modules/roles-and-permissions/role-details-view/feature-with-switch/featureWithSwitch';
import FeatureWithRadio from 'modules/roles-and-permissions/role-details-view/features-with-radio-btns/featuresWithRadioBtns';
import {
  ClientPermissions,
  ContentPermissions,
  FolderType,
  PermissionFor,
  PermissionMutationType,
  PermissionType,
  PlayListPermissions,
  Roles,
  ScreenPermissions,
  accessType,
  UserRoles,
} from 'enums';
import SpecificPermissions, {
  SpecificPermissionsVariants,
} from 'modules/roles-and-permissions/specific-permission/specificPermission';
import { getPermissions, addOrUpdatePermissions, getAllFolders } from 'apis';
import { toaster } from 'helpers';
import { useStore } from 'store';
import Subheader from '../subheader/subheader';
// import Permissions, { PermissionsVariants } from './permissions/permissions';
import { ALL_PERMISSIONS, PermissionKeys } from './constants';
import styles from './teamMembersPermissions.module.scss';

interface iProps {
  className?: string;
}

const initialValues = {
  id: '',
  addClients: true,
  addContentAndFolders: true,
  addPlayListAndFolders: true,
  addScreensAndFolders: true,
  addTeammates: true,
  clients: 'allClients',
  contentAcces: 'allContents',
  deleteClients: true,
  deleteContentAndFolder: true,
  deletePlayListAndFolder: true,
  deleteScreenAndFolder: true,
  editCompanyDetails: true,
  manageReseller: true,
  mutationType: 'CREATE',
  permissionFor: 'User',
  permissionType: 'fullAccess',
  playListAccess: 'allPlayList',
  removeTeammates: true,
  rollOrCompanyUserId: '',
  screenAccess: 'allScreens',
};

function TeamMembersPermissions(props: iProps): JSX.Element {
  const { className } = props;
  const [state]: [iState, any] = useStore();
  const [permissions, setPermissions] = useState<iPermissionsData>();
  const [isPermissionsModified, setIsPermissionsModified] =
    useState<boolean>(false);
  const { companyData } = state.homePage || {};
  const { id: companyId } = companyData || {};
  const [screenFolderInfo, setScreenFolderInfo] = useState<any>([]);
  const [permissionData, setPermissionsData] = useState<any>([]);
  const [contentType, setContentType] = useState<any>([]);
  const [selectedFolder, setSelectedFolder] = useState<any>([]);
  const [contentFolderInfo, setContentFolderInfo] = useState<any>();
  const [playlistFolderInfo, setPlaylistFolderInfo] = useState<any>();
  const [enable, setEnable] = useState(false);
  const { loggedInData, removeTeammateData } = state.user || {};
  const { id: loginUserId } = loggedInData || {};
  const { id: selectedUserId } = removeTeammateData || {};
  // console.log(
  //   ClientPermissions,
  //   contentFolderInfo,
  //   playlistFolderInfo,
  //   'permissions'
  // );

  const filterSelectedContent = useCallback((array) => {
    const uniqueIds = new Set();
    return array
      .filter((item) => {
        if (item.folderType === 'CONTENT' && !uniqueIds.has(item.id)) {
          uniqueIds.add(item.id);
          return true;
        }
        return false;
      })
      .map((item) => ({ folderId: item.id, accessType: accessType.EDIT }));
  }, []);

  const filteredContent = filterSelectedContent(selectedFolder);
  const filterSelectedPlayList = useCallback((array) => {
    const uniqueIds = new Set();
    return array
      .filter((item) => {
        if (item.folderType === 'PLAYLIST' && !uniqueIds.has(item.id)) {
          uniqueIds.add(item.id);
          return true;
        }
        return false;
      })
      .map((item) => ({ folderId: item.id, accessType: accessType.EDIT }));
  }, []);
  const filteredPlayList = filterSelectedPlayList(selectedFolder);

  const filterSelectedSceenz = useCallback((array) => {
    // console.log(array, 'array');
    const uniqueIds = new Set();
    return array
      .filter((item) => {
        if (item.folderType === 'SCREENZ' && !uniqueIds.has(item.id)) {
          uniqueIds.add(item.id);
          return true;
        }
        return false;
      })
      .map((item) => ({ folderId: item.id, accessType: accessType.EDIT }));
  }, []);
  const filteredScreenz = filterSelectedSceenz(selectedFolder);

  const getAllRootFolders = useCallback(
    async (folderType, responsePermissions) => {
      if (!companyId) return;
      const response = await getAllFolders({ companyId, folderType }, () => {});
      if (response) {
        response.forEach((fold) => {
          if (
            responsePermissions?.screenFolders
              ?.map((item) => item.folderId)
              .includes(fold.id) ||
            responsePermissions?.contentFolders
              ?.map((item) => item.folderId)
              .includes(fold.id) ||
            responsePermissions?.playListFolders
              ?.map((item) => item.folderId)
              .includes(fold.id)
          ) {
            setSelectedFolder((prevData) => [...prevData, fold]);
          }
        });
        if (folderType === FolderType.SCREENZ) setScreenFolderInfo(response);
        if (folderType === FolderType.CONTENT) setContentFolderInfo(response);
        if (folderType === FolderType.PLAYLIST) setPlaylistFolderInfo(response);
      }
    },
    [permissions]
  );

  const getPermissionsData = useCallback(async () => {
    const response = await getPermissions({
      rollOrCompanyUserId: state?.user?.userCompanyId,
      permissionFor: PermissionFor.User,
    });
    if (response) {
      setPermissions(response);
      getAllRootFolders(FolderType.SCREENZ, response);
      getAllRootFolders(FolderType.CONTENT, response);
      getAllRootFolders(FolderType.PLAYLIST, response);
      setPermissionsData(response.permissionFor);
    }
    if (!response) {
      setPermissions(initialValues);
    }
  }, [state?.user?.userCompanyId]);

  const getAllFoldersData = useCallback(async () => {
    let folderType;
    switch (contentType) {
      case 'screenAccess':
        folderType = FolderType.SCREENZ;
        break;
      case 'contentAcces':
        folderType = FolderType.CONTENT;
        break;
      case 'playListAccess':
        folderType = FolderType.PLAYLIST;
        break;
      default:
        return;
    }
    if (!companyId) return;
    const response = await getAllFolders({ companyId, folderType }, () => {});
    if (response) {
      response.forEach((fold) => {
        if (
          permissions?.screenFolders
            ?.map((item) => item.folderId)
            .includes(fold.id) ||
          permissions?.contentFolders
            ?.map((item) => item.folderId)
            .includes(fold.id) ||
          permissions?.playListFolders
            ?.map((item) => item.folderId)
            .includes(fold.id)
        ) {
          setSelectedFolder((prevData) => [...prevData, fold]);
        }
      });
      if (contentType === 'screenAccess') setScreenFolderInfo(response);
      if (contentType === 'contentAcces') setContentFolderInfo(response);
      if (contentType === 'playListAccess') setPlaylistFolderInfo(response);
    }
  }, [companyId, contentType]);

  // useEffect(() => {
  // getAllRootFolders(FolderType.SCREENZ);
  // getAllRootFolders(FolderType.CONTENT);
  // getAllRootFolders(FolderType.PLAYLIST);
  // }, [permissions]);

  // const updateSelectedFolder = useCallback(
  //   (data) => {
  //     console.log(selectedFolder.includes(data), data, selectedFolder);
  //     if (!selectedFolder.includes(data)) {
  //       setSelectedFolder((prevData) => [...prevData, data]);
  //     }
  //   },
  //   [selectedFolder]
  // );

  // const getFoldersByIdHandler = useCallback(
  //   async (data) => {
  //     updateSelectedFolder(data);
  //     setIsPermissionsModified(true);
  //     const response = await getFolderById({ id: data.id }, () => {});
  //     if (response && response.subFolders) {
  //       setFolderInfo((prevFolders) => {
  //         const folderIndex = prevFolders.findIndex(
  //           (folder) => folder.id === data.id
  //         );
  //         if (folderIndex === -1) return prevFolders;
  //         const newFolders = [...prevFolders];
  //         newFolders[folderIndex].subFolders = response.subFolders;
  //         return newFolders;
  //       });
  //     }
  //   },
  //   [selectedFolder]
  // );

  useEffect(() => {
    getPermissionsData();
  }, [getPermissionsData]);

  const updatePermissions = useCallback(async () => {
    if (!permissions) {
      return;
    }
    const { screenAccess, contentAcces, playListAccess } = permissions || {};

    let updatedScreenAccess;
    let updatedContentAccess;
    let updatedPlaylistAccess;
    if (screenAccess) {
      updatedScreenAccess =
        ScreenPermissions[
          `${permissions.screenAccess[0]?.toUpperCase()}${permissions.screenAccess.slice(
            1
          )}`
        ];
      if (!updatedScreenAccess) {
        toaster.error(
          'Please select a screen permission whether all or specific'
        );
        return;
      }
    } else {
      updatedScreenAccess = ScreenPermissions.Disabled;
    }
    if (contentAcces) {
      updatedContentAccess =
        ContentPermissions[
          `${permissions.contentAcces[0]?.toUpperCase()}${permissions.contentAcces.slice(
            1
          )}`
        ];
      if (!updatedContentAccess) {
        toaster.error(
          'Please select a content permission whether all or specific'
        );
        return;
      }
    } else {
      updatedContentAccess = ContentPermissions.Disabled;
    }
    if (playListAccess) {
      updatedPlaylistAccess =
        PlayListPermissions[
          `${permissions.playListAccess[0]?.toUpperCase()}${permissions.playListAccess.slice(
            1
          )}`
        ];
      if (!updatedPlaylistAccess) {
        toaster.error(
          'Please select a playlist permission whether all or specific'
        );
        return;
      }
    } else {
      updatedPlaylistAccess = PlayListPermissions.Disabled;
    }

    // if (typeof updatedScreenAccess === 'boolean') {
    //   toaster.error(
    //     'Please select a screen permission whether all or specific'
    //   );
    //   return;
    // }
    // if (typeof updatedContentAccess === 'boolean') {
    //   toaster.error(
    //     'Please select a content permission whether all or specific'
    //   );
    //   return;
    // }
    // if (typeof updatedPlaylistAccess === 'boolean') {
    //   toaster.error(
    //     'Please select a playlist permission whether all or specific'
    //   );
    //   return;
    // }
    if (permissionData === PermissionFor.Role) {
      const updateArgs: iAddOrUpdatePermissionsArgs = {
        mutationType: PermissionMutationType.CREATE,
        addClients: permissions.addClients,
        addContentAndFolders: permissions.addContentAndFolders,
        addPlayListAndFolders: permissions.addPlayListAndFolders,
        addScreensAndFolders: permissions.addScreensAndFolders,
        addTeammates: permissions.addTeammates,
        deleteClients: permissions.deleteClients,
        deleteContentAndFolder: permissions.deleteContentAndFolder,
        deletePlayListAndFolder: permissions.deletePlayListAndFolder,
        deleteScreenAndFolder: permissions.deleteScreenAndFolder,
        editCompanyDetails: permissions.editCompanyDetails,
        manageReseller: permissions.manageReseller,
        removeTeammates: permissions.removeTeammates,
        permissionFor: PermissionFor.User,
        permissionType: PermissionType.fullAccess,
        rollOrCompanyUserId: state?.user?.userCompanyId,
        clients:
          ClientPermissions[
            `${permissions.clients[0]?.toUpperCase()}${permissions.clients.slice(
              1
            )}`
          ],
        screenAccess: updatedScreenAccess,
        contentAcces: updatedContentAccess,
        playListAccess: updatedPlaylistAccess,
        screenFolders: filteredScreenz,
        contentFolders: filteredContent,
        playListFolders: filteredPlayList,
      };
      const response = await addOrUpdatePermissions(updateArgs);
      if (response) {
        setIsPermissionsModified(false);
        toaster.success('Permissions updated successfully');
        return;
      }
      if (!response) {
        toaster.error('Failed to update the permissions');
        return;
      }
      return;
    }
    if (permissionData === PermissionFor.User) {
      const updateArgs: iAddOrUpdatePermissionsArgs = {
        mutationType: PermissionMutationType.UPDATE,
        ...permissions,
        id: permissions.id || '',
        permissionFor: PermissionFor.User,
        permissionType: PermissionType.fullAccess,
        rollOrCompanyUserId: state?.user?.userCompanyId,
        clients:
          ClientPermissions[
            `${permissions.clients[0]?.toUpperCase()}${permissions.clients.slice(
              1
            )}`
          ],
        screenAccess: updatedScreenAccess,
        contentAcces: updatedContentAccess,
        playListAccess: updatedPlaylistAccess,
        screenFolders: filteredScreenz,
        contentFolders: filteredContent,
        playListFolders: filteredPlayList,
      };
      const response = await addOrUpdatePermissions(updateArgs);
      if (response) {
        setIsPermissionsModified(false);
        toaster.success('Permissions updated successfully');
        return;
      }
      if (!response) {
        toaster.error('failed to update the permissions');
      }
    }
  }, [
    filteredContent,
    filteredPlayList,
    filteredScreenz,
    permissionData,
    permissions,
    state?.user?.userCompanyId,
  ]);

  const isSuperAdmin = state.user.role === UserRoles.SuperAdmin;
  const updateDisable = useCallback(() => {
    if (companyData?.features?.accessManagment === true || isSuperAdmin) {
      if (companyData?.role?.id !== '3' || isSuperAdmin) {
        if (
          loginUserId === selectedUserId &&
          companyData?.role?.id === '2' &&
          !isSuperAdmin
        ) {
          setEnable(false);
          return;
        }
        setEnable(true);
      } else {
        setEnable(false);
      }
    } else if (companyData?.features?.accessManagment === false) {
      setEnable(false);
    } else if (companyData?.features === null) {
      setEnable(false);
    }
  }, [companyData?.features]);

  useEffect(() => {
    getAllFoldersData();
    updateDisable();
  }, [getAllFoldersData, updateDisable]);

  useEffect(() => {
    console.log(permissions, 'permissions');
  }, [permissions]);

  return (
    <div className={`${styles.teamMembersPermissions} ${className}`}>
      <Subheader
        roleName="Team member"
        isDisabled={isPermissionsModified}
        saveChanges={updatePermissions}
      />
      <div className={styles.headingAndSectionContainer}>
        <p className={styles.sectionHeading}>
          Change {state.user.userId} permissions
        </p>
        <div className={styles.section}>
          <div className={styles.sectionContainer}>
            {permissions &&
              ALL_PERMISSIONS.map((i) => {
                return (
                  <>
                    <p className={`${styles.mainHeading} ${styles.noBorder}`}>
                      {i.heading}
                    </p>
                    <>
                      {i.permissions.map((permission) => {
                        const mainPermissions =
                          (typeof permissions[permission.key] === 'boolean' &&
                            permissions[permission.key]) ||
                          (permissions[permission.key] === 'disabled' &&
                            false) ||
                          (permissions[permission.key] === 'onlyTheyAreAdded' &&
                            true) ||
                          (permissions[permission.key] === 'allPlayList' &&
                            true) ||
                          (permissions[permission.key] === 'allContents' &&
                            true) ||
                          (permissions[permission.key] === 'allScreens' &&
                            true) ||
                          false;
                        return (
                          <>
                            {permission.option && (
                              <FeatureWithSwitch
                                feature={permission.option}
                                isAccessible={mainPermissions}
                                isNotDisabled={enable}
                                onChange={(status) => {
                                  if (
                                    permission.key ===
                                      PermissionKeys.screenAccess ||
                                    permission.key ===
                                      PermissionKeys.contentAcces ||
                                    permission.key ===
                                      PermissionKeys.playListAccess
                                  ) {
                                    const permissionsStatus =
                                      (permission.key ===
                                        PermissionKeys.screenAccess &&
                                        ScreenPermissions.AllScreens) ||
                                      (permission.key ===
                                        PermissionKeys.contentAcces &&
                                        ContentPermissions.AllContents) ||
                                      (permission.key ===
                                        PermissionKeys.playListAccess &&
                                        PlayListPermissions.AllPlayList);
                                    // console.log(status, 'status');
                                    setPermissions({
                                      ...permissions,
                                      [permission.key]: status
                                        ? permissionsStatus
                                        : 'disabled',
                                      // setPermissions({
                                      //   ...permissions,
                                      //   [permission.key]: status || 'disabled',
                                      // ? status
                                      // : 'disabled',
                                    });
                                  } else
                                    setPermissions({
                                      ...permissions,
                                      [permission.key]: status,
                                    });
                                  setIsPermissionsModified(true);
                                }}
                              />
                            )}
                            {permission.subPermissions.map((subPerm) => {
                              return (
                                <>
                                  <FeatureWithRadio
                                    feature={subPerm.option}
                                    value={subPerm.value}
                                    isNotDisabled={enable}
                                    isChecked={
                                      subPerm.value === permissions[subPerm.key]
                                    }
                                    onChange={(choosed) => {
                                      if (mainPermissions) {
                                        if (
                                          companyData?.features
                                            ?.accessManagment === false ||
                                          companyData?.features === null ||
                                          !Roles.Super_admin
                                        )
                                          return;
                                        setPermissions({
                                          ...permissions,
                                          [subPerm.key]: choosed,
                                        });
                                        setIsPermissionsModified(true);
                                        setContentType(subPerm.key);
                                      }
                                    }}
                                  />
                                  {subPerm.value ===
                                    (ScreenPermissions.OnlyTheyAreAdded ||
                                      ContentPermissions.OnlyTheyAreAdded ||
                                      PlayListPermissions.OnlyTheyAreAdded) &&
                                    subPerm.value ===
                                      permissions[subPerm.accessTo] &&
                                    subPerm.accessTo === 'screenAccess' && (
                                      <SpecificPermissions
                                        variant={
                                          SpecificPermissionsVariants.ScreensAccess
                                        }
                                        isNotDisabled={enable}
                                        permisssions={permissions}
                                        selectedFolder={selectedFolder}
                                        folderData={screenFolderInfo}
                                        setSelectedFolder={(data) => {
                                          setIsPermissionsModified(true);
                                          setSelectedFolder(data);
                                        }}
                                      />
                                    )}
                                  {subPerm.value ===
                                    (ScreenPermissions.OnlyTheyAreAdded ||
                                      ContentPermissions.OnlyTheyAreAdded ||
                                      PlayListPermissions.OnlyTheyAreAdded) &&
                                    subPerm.value ===
                                      permissions[subPerm.accessTo] &&
                                    subPerm.accessTo === 'contentAcces' && (
                                      <SpecificPermissions
                                        variant={
                                          SpecificPermissionsVariants.ContentAccess
                                        }
                                        isNotDisabled={enable}
                                        permisssions={permissions}
                                        selectedFolder={selectedFolder}
                                        folderData={contentFolderInfo}
                                        setSelectedFolder={(data) => {
                                          setIsPermissionsModified(true);
                                          setSelectedFolder(data);
                                        }}
                                      />
                                    )}
                                  {subPerm.value ===
                                    (ScreenPermissions.OnlyTheyAreAdded ||
                                      ContentPermissions.OnlyTheyAreAdded ||
                                      PlayListPermissions.OnlyTheyAreAdded) &&
                                    subPerm.value ===
                                      permissions[subPerm.accessTo] &&
                                    subPerm.accessTo === 'playListAccess' && (
                                      <SpecificPermissions
                                        variant={
                                          SpecificPermissionsVariants.PlaylistAccess
                                        }
                                        selectedFolder={selectedFolder}
                                        isNotDisabled={enable}
                                        folderData={playlistFolderInfo}
                                        setSelectedFolder={(data) => {
                                          setIsPermissionsModified(true);
                                          setSelectedFolder(data);
                                        }}
                                        permisssions={permissions}
                                      />
                                    )}
                                </>
                              );
                            })}
                          </>
                        );
                      })}
                    </>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

TeamMembersPermissions.defaultProps = {
  className: '',
};

export default TeamMembersPermissions;

import React, { useCallback, useEffect, useState } from 'react';
// import Radio from 'components/radiobutton/radioButton';
import Accordion from 'modules/roles-and-permissions/specific-permission/accordion/accordion';
import { getFolderById } from 'apis';
import { iPermissionsData } from 'types';
import styles from './folderlevelOne.module.scss';

interface iProps {
  //   className?: string;
  folderData: any;
  selectedFolder: any[];
  setSelectedFolder?: (data: any) => void;
  permisssions: iPermissionsData;
  isNotDisabled?: boolean;
}

function FolderLevelOne(props: iProps): JSX.Element {
  const {
    folderData,
    setSelectedFolder,
    selectedFolder,
    permisssions,
    isNotDisabled,
  } = props;
  const [subFolders, setSubFolders] = useState<any>([]);

  const getFoldersByIdHandler = useCallback(async (data) => {
    const response = await getFolderById({ id: data.id }, () => {});
    if (response && response.subFolders) {
      response.subFolders.forEach((fold) => {
        if (
          permisssions?.screenFolders
            ?.map((item) => item.folderId)
            .includes(fold.id) ||
          permisssions?.contentFolders
            ?.map((item) => item.folderId)
            .includes(fold.id) ||
          permisssions?.playListFolders
            ?.map((item) => item.folderId)
            .includes(fold.id)
          //   ||
          // selectedFolder.map((fld) => fld.id).includes(data.id)
        ) {
          if (setSelectedFolder)
            setSelectedFolder((prevData) => [...prevData, fold]);
        }
      });
      setSubFolders(response.subFolders);
    }
  }, []);

  const addFolder = useCallback(
    (data) => {
      if (!selectedFolder.includes(data) && setSelectedFolder) {
        setSelectedFolder((prevData) => [...prevData, data]);
      }
      if (selectedFolder.includes(data) && setSelectedFolder) {
        setSelectedFolder((prevData) =>
          prevData.filter((item) => item.id !== data.id)
        );
      }
    },
    [selectedFolder]
  );

  useEffect(() => {
    console.log(selectedFolder, 'selectedFolder');
  }, [selectedFolder]);

  return (
    <div>
      <Accordion
        heading={folderData.name}
        isNotDisabled={isNotDisabled}
        onClick={() => {
          if (isNotDisabled) {
            getFoldersByIdHandler(folderData);
          }
        }}
        key={folderData.id}
        onSelect={() => {
          if (isNotDisabled) {
            addFolder(folderData);
          }
        }}
        isChecked={selectedFolder
          .map((item) => item.id)
          .includes(folderData.id)}
      >
        <div className={styles.accordion}>
          {subFolders &&
            subFolders.length > 0 &&
            subFolders.map((i) => (
              <FolderLevelOne
                selectedFolder={selectedFolder}
                setSelectedFolder={setSelectedFolder}
                folderData={i}
                permisssions={permisssions}
              />
            ))}
        </div>
      </Accordion>
    </div>
  );
}

FolderLevelOne.defaultProps = {
  //   className: '',
  setSelectedFolder: () => {},
  isNotDisabled: false,
};

export default FolderLevelOne;

import AddClientModal from 'components/add-client-modal/addClientModal';
import AddFolderModal from 'components/add-folder-modal/addFolderModal';
import AddScreenModal from 'components/add-screen-modal/addScreenModal';
import Layout, { LayoutVariants } from 'components/Layouts/layout';
import { ROUTES } from 'constants/index';
import { LayoutViews, UserRoles } from 'enums';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import {
  contentPageActions,
  homePageActions,
  layoutActions,
  playlistPageActions,
} from 'store/actions';
import { iState } from 'types';
import Client from './components/client/client';
import ResellerAdmin from './components/reseller-admin/resellerAdmin';
import SuperUserAdmin from './components/super-user-admin/superUserAdmin';
import styles from './home.module.scss';

function Home(): JSX.Element {
  const [state, dispatch]: [iState, any] = useStore();
  const { layout, user } = state || {};
  const { role } = user || {};
  const { isShowingClientInstance } = layout || {};
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { companyData } = state.homePage || {};
  const { windowWidth } = layout || {};

  useEffect(() => {
    if (windowWidth > 800 && role === UserRoles.Client)
      dispatch(layoutActions.setHomePageView(LayoutViews.Screens));

    if (role === UserRoles.SuperAdmin && isShowingClientInstance)
      dispatch(layoutActions.setHomePageView(LayoutViews.Screens));

    if (!isShowingClientInstance && role === UserRoles.SuperAdmin)
      dispatch(layoutActions.setHomePageView(LayoutViews.SuperAdminClients));
  }, [dispatch, windowWidth, role, isShowingClientInstance]);

  // eslint-disable-next-line
  const isClient = role === UserRoles.Client;
  const isOwner = role === UserRoles.Owner;
  const isAdmin = user.role === UserRoles.Admin;
  const isSuperUserAdmin = role === UserRoles.SuperAdmin;
  const isResellerAdmin = role === UserRoles.Reseller;

  useEffect(() => {
    if (pathname === ROUTES.screenz) {
      document.title = 'Screenz';
      return;
    }
    document.title = 'Clients';
  }, [pathname, state]);

  useEffect(() => {
    if (
      (isClient || isOwner || isAdmin || isShowingClientInstance) &&
      pathname === ROUTES.screenz
    ) {
      if (companyData) {
        navigate(ROUTES.screenz);
        return;
      }
      if (!companyData) {
        navigate(ROUTES.chooseTeam);
      }
    }
    if (pathname === ROUTES.home && companyData) {
      dispatch(contentPageActions.setContents([]));
      dispatch(homePageActions.setCompanyData(null));
      dispatch(layoutActions.setShowClientsInstance(false));
      dispatch(layoutActions.initialSidebarState(false));
      dispatch(layoutActions.setHomePageView(LayoutViews.SuperAdminClients));

      dispatch(homePageActions.setScreensActiveFolder(null));
      dispatch(homePageActions.setScreensFolders([]));
      dispatch(homePageActions.setScreensFolderHierarchy([]));

      dispatch(contentPageActions.setContentActiveFolder(null));
      dispatch(contentPageActions.setContentFolders([]));
      dispatch(contentPageActions.setContentFolderHierarchy([]));

      dispatch(playlistPageActions.setPlaylistsActiveFolder(null));
      dispatch(playlistPageActions.setPlaylistsFolders([]));
      dispatch(playlistPageActions.setPlaylistsFolderHierarchy([]));

      dispatch(layoutActions.setAssignModalContentActiveFolders(null));
      dispatch(layoutActions.setAssignModalContentFolders([]));
      dispatch(layoutActions.setAssignModalContentsFolderHierarchy([]));
    } else if (!isSuperUserAdmin) {
      navigate(ROUTES.chooseTeam);
    }
  }, [pathname]);
  return (
    <Layout variant={LayoutVariants.layoutTwo}>
      <section className={styles.homeWrapper}>
        {(isClient || isOwner || isAdmin) && companyData && <Client />}
        {isShowingClientInstance && isSuperUserAdmin && <Client />}
        {!isShowingClientInstance && isSuperUserAdmin && <SuperUserAdmin />}
        {!isShowingClientInstance && isResellerAdmin && <ResellerAdmin />}
        <AddClientModal />
        <AddScreenModal />
        <AddFolderModal />
      </section>
    </Layout>
  );
}

export default Home;

import { COMMON, OFFICE_365 } from 'store/constants';
import { englishIcon, informationIcon, norwayData } from 'constants/index';
import { iOffice365State, iEventInfo } from 'types';
import { uuid } from 'helpers';
import { CalenderSettingsView, EventContentType, LanguageType } from 'enums';
import { initialState } from '../initialState/initialState';

const eventInfoDestructured = (eventInfo: iEventInfo | null): iEventInfo => {
  const {
    eventBgImg = '',
    eventLogo = '',
    isShowActiveBooking = true,
    isShowHostName = true,
    isShowNextMeeting = true,
    isShowSubject = true,
    customName = '',
    timeZone = null,
    roomChoosed = null,
    templateName = '',
    chosenTemplateType = '',
  } = eventInfo || {};
  return {
    chosenTemplateType,
    templateName,
    eventBgImg,
    eventLogo,
    isShowActiveBooking,
    isShowHostName,
    isShowNextMeeting,
    isShowSubject,
    customName,
    timeZone,
    roomChoosed,
  };
};

export const office365Reducer = (
  state: iOffice365State,
  action
): iOffice365State => {
  switch (action.type) {
    case OFFICE_365.setMsAccountAccessToken: {
      const { account = null } = state.activeMsAccount || {};
      const activeMsAccount = {
        account,
        accessToken: action.payload,
      };
      return {
        ...state,
        activeMsAccount,
      };
    }
    case OFFICE_365.setActiveMsAccount: {
      const { accessToken = '' } = state.activeMsAccount || {};
      const activeMsAccount = {
        accessToken,
        account: action.payload,
      };
      return {
        ...state,
        activeMsAccount,
      };
    }
    case OFFICE_365.setCurrentView: {
      return { ...state, currentView: action.payload || '' };
    }
    case OFFICE_365.setContentSettingsContentOption: {
      return { ...state, contentSettingsContentOption: action.payload };
    }
    case OFFICE_365.setContentAreaContentOption: {
      return { ...state, contentAreaContentOption: action.payload };
    }
    case OFFICE_365.setSelectedLanguage: {
      return { ...state, selectedLanguage: action.payload };
    }
    case OFFICE_365.setAllCalenderEvents: {
      return { ...state, calanderEvents: action.payload };
    }
    case OFFICE_365.setCurrentEvents: {
      return { ...state, currentEvents: action.payload };
    }
    case OFFICE_365.setNextEvents: {
      return { ...state, nextEvents: action.payload };
    }
    case OFFICE_365.setTemplateIdToEdit: {
      return { ...state, templateIdToEdit: action.payload };
    }
    case OFFICE_365.setTempalteDataToUpdate: {
      return { ...state, tempalteDataToUpdate: action.payload };
    }
    case OFFICE_365.setChosenTemplateType: {
      const event = eventInfoDestructured(state.eventInfo);
      return {
        ...state,
        eventInfo: {
          ...event,
          chosenTemplateType: action.payload,
        },
      };
    }
    case OFFICE_365.updateCustomName: {
      const event = eventInfoDestructured(state.eventInfo);
      return {
        ...state,
        eventInfo: {
          ...event,
          customName: action.payload,
        },
      };
    }

    case OFFICE_365.resetTemplatData: {
      const DEFAULT = {
        id: uuid(),
        option: 'Empty',
        optionNorway: norwayData.empty,
        icon: informationIcon,
        value: '',
      };
      return {
        ...state,
        templateIdToEdit: null,
        currentView: CalenderSettingsView.TemplateSetting,
        eventCntentType: EventContentType.Empty,
        isShowClock: false,
        calanderEvents: [],
        currentEvents: null,
        nextEvents: null,
        contentAreaContentOption: DEFAULT,
        contentSettingsContentOption: DEFAULT,
        selectedLanguage: {
          id: uuid(),
          option: 'English',
          optionNorway: 'English',
          icon: englishIcon,
          value: LanguageType.English,
        },
        eventInfo: {
          eventBgImg: '',
          eventLogo: '',
          isShowActiveBooking: true,
          isShowNextMeeting: true,
          isShowHostName: true,
          isShowSubject: true,
          customName: '',
          timeZone: null,
          roomChoosed: null,
          templateName: '',
          chosenTemplateType: '',
        },
      };
    }

    case OFFICE_365.setChoosedRoom: {
      return {
        ...state,
        eventInfo: {
          ...eventInfoDestructured(state.eventInfo),
          roomChoosed: action.payload || '',
        },
      };
    }
    case OFFICE_365.setTemplateName: {
      return {
        ...state,
        eventInfo: {
          ...eventInfoDestructured(state.eventInfo),
          templateName: action.payload,
        },
      };
    }
    case OFFICE_365.setTimeZoneOption: {
      return {
        ...state,
        eventInfo: {
          ...eventInfoDestructured(state.eventInfo),
          timeZone: action.payload || '',
        },
      };
    }

    case OFFICE_365.setEventBgImg: {
      return {
        ...state,
        eventInfo: {
          ...eventInfoDestructured(state.eventInfo),
          eventBgImg: action.payload || '',
        },
      };
    }
    case OFFICE_365.setEventLogo: {
      return {
        ...state,
        eventInfo: {
          ...eventInfoDestructured(state.eventInfo),
          eventLogo: action.payload || '',
        },
      };
    }
    case OFFICE_365.setIsShowSubject: {
      return {
        ...state,
        eventInfo: {
          ...eventInfoDestructured(state.eventInfo),
          isShowSubject: action.payload || false,
        },
      };
    }
    case OFFICE_365.setIsShowHostName: {
      return {
        ...state,
        eventInfo: {
          ...eventInfoDestructured(state.eventInfo),
          isShowHostName: action.payload || false,
        },
      };
    }
    case OFFICE_365.setIsShowactivateBooking: {
      return {
        ...state,
        eventInfo: {
          ...eventInfoDestructured(state.eventInfo),
          isShowActiveBooking: action.payload || false,
        },
      };
    }
    case OFFICE_365.setIsShowNextMeeting: {
      return {
        ...state,
        eventInfo: {
          ...eventInfoDestructured(state.eventInfo),
          isShowNextMeeting: action.payload || false,
        },
      };
    }
    case OFFICE_365.setIsShowClock: {
      return {
        ...state,
        isShowClock: action.payload,
      };
    }

    case OFFICE_365.setEventContentType: {
      return {
        ...state,
        eventCntentType: action.payload,
      };
    }
    case OFFICE_365.setStoredAccountRooms: {
      return {
        ...state,
        storedAccountRooms: action.payload,
      };
    }
    case OFFICE_365.setO365UserRooms: {
      return { ...state, o365UserRooms: action.payload };
    }
    case OFFICE_365.addOrRemoveRoom: {
      const updatedRooms = state.o365UserRooms?.map((room) => {
        if (
          room.emailAddress?.toLocaleLowerCase() ===
          action.payload.toLocaleLowerCase()
        ) {
          return { ...room, status: !room.status };
        }
        return room;
      });
      return {
        ...state,
        o365UserRooms: updatedRooms || [],
      };
    }
    case OFFICE_365.setCustomName: {
      const updatedRooms = state.o365UserRooms?.map((room) => {
        if (room.id === action.payload.id) {
          return {
            ...room,
            custom_name: action.payload.name,
            customNameError: action.payload.customNameError,
          };
        }
        return room;
      });
      return {
        ...state,
        o365UserRooms: updatedRooms || [],
      };
    }
    case OFFICE_365.setupdateRoomCustomName: {
      return {
        ...state,
        updateRoomCustomName: action.payload,
      };
    }
    case OFFICE_365.setResponseAfterLogin: {
      return {
        ...state,
        responseAfterLogin: action.payload,
      };
    }
    case OFFICE_365.setMsAccountAccessResponse: {
      return {
        ...state,
        msAccountAccessResponse: action.payload,
      };
    }
    case OFFICE_365.setIsMSaccountsFetched: {
      return {
        ...state,
        isMSAccountsFetched: action.payload,
      };
    }
    case OFFICE_365.setIsMStokensFetched: {
      return {
        ...state,
        isMStokensFetched: action.payload,
      };
    }
    case OFFICE_365.setFetchedMSAccounts: {
      return {
        ...state,
        fetchedMSAccounts: action.payload,
      };
    }
    case COMMON.reset: {
      return initialState.office365;
    }
    default:
      return state;
  }
};

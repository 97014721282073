import { getUserByCompanyId } from 'apis';
import { TwoFAType } from 'enums';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'store';
import { iState, iUserData } from 'types';
import NotSetup from '../../../team-member-list/components/not-setup/notSetup';
import Resend from '../resend/resend';
import styles from './invitedMobile.module.scss';

interface iProps {
  className: string;
  isResending: boolean;
  isRevoking: boolean;
  resendingInviteTo: string;
  revokingInviteTo: string;
  resend: (data: any) => void;
  revoke: (data: any) => void;
  canRefetchInvited: boolean;
  setCanRefetchInvited: (a: boolean) => void;
}

function InvitedMobileView(props: iProps): JSX.Element {
  const {
    className,
    resendingInviteTo,
    revokingInviteTo,
    isResending = false,
    isRevoking = false,
    resend,
    revoke,
    canRefetchInvited,
    setCanRefetchInvited,
  } = props;
  const [, setIsLoading] = useState<boolean>(false);
  const [state]: [iState, any] = useStore();
  const { homePage } = state || {};
  const [invitedUser, setInvitedUser] = useState<Array<iUserData>>([]);
  const { companyData } = homePage || {};
  const { id: companyId } = companyData || {};
  //  console.log({ isLoading });

  // useEffect(() => {
  //   (async () => {
  //     if (!companyId) return;
  //     const response = await getUserByCompanyId(
  //       { company: companyId },
  //       setIsLoading
  //     );
  //     if (response) {
  //       //  console.log({ response });
  //       setInvitedUser(response);
  //     }
  //   })();
  // }, [companyId]);

  const getUsers = useCallback(async () => {
    if (!companyId) return;
    const response = await getUserByCompanyId(
      { company: companyId },
      setIsLoading
    );
    if (response) {
      //  console.log({ response });
      const parsed = response
        .filter(
          (i) => i.invitedStatus === 'INVITED' || i.invitedStatus === 'REVOKED'
        )
        .map((i) => ({
          ...i,
          user: {
            name: i.first_name,
            lastName: i.last_name,
            email: i.email,
            profilePic: i.profile_pic,
            invitedStatus: i.invitedStatus,
          },
        }));
      setInvitedUser(parsed);
      setCanRefetchInvited(false);
    }
  }, [companyId, setCanRefetchInvited]);

  const getDateFormatedDate = (dateTime: string): string => {
    if (!dateTime) return '';
    const date = new Date(dateTime);
    return (
      (date &&
        `${date.getDate()}.${
          date.getMonth() + 1
        }.${date.getFullYear()} ${date.getHours()}.${date.getMinutes()}`) ||
      ''
    );
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (canRefetchInvited) getUsers();
  }, [canRefetchInvited, getUsers]);

  return (
    <div className={`${className} ${styles.playlists}`}>
      {invitedUser
        .filter((i) => i.invitedStatus === 'INVITED')
        .map((item) => (
          <section key={item.id}>
            <div className={styles.avatarContainer}>
              <div className={styles.avatarCircle}>
                <div className={styles.avatarText}>
                  {item.profile_pic ? (
                    <img src={item.profile_pic} alt="" />
                  ) : (
                    <span>
                      {(item.first_name && item.first_name[0]) || ''}
                      {(item.last_name && item.last_name[0]) || ''}
                    </span>
                  )}
                </div>
              </div>
              <section>
                <span className={styles.nameCotainer}>
                  <p className={styles.nameDetails}>{item.first_name}</p>
                  <p className={styles.nameDetails}>{item.last_name}</p>
                </span>
                <p className={styles.emailDetails}>{item.email}</p>
              </section>
            </div>
            <div className={styles.dividerRow} />
            <div className={styles.permissionContainer}>
              <div className={styles.fullAccess}>
                <div className={styles.permissionDetails}>
                  <p className={styles.permissionTitle}>Permission</p>
                  <p className={styles.permissionAccess}>
                    {(item?.role?.id === ('1' || '2') && 'Full Permission') ||
                      'Limited Permission'}
                  </p>
                </div>
                <div className={styles.permissionDetails}>
                  <p className={styles.permissionTitle}>Sent</p>
                  <p className={styles.permissionAccess}>
                    {item.emailSentTime &&
                      getDateFormatedDate(item.emailSentTime)}
                  </p>
                </div>
              </div>
              <div className={styles.notSetupContainer}>
                <p className={styles.fA}>2fa</p>
                <span>
                  <NotSetup
                    className={styles.notSetupBtn}
                    isEnabled={
                      item?.settings
                        ? item?.settings?.twoFA !== TwoFAType.DISABLED
                        : false
                    }
                  />
                </span>
              </div>
            </div>
            <div className={styles.dividerRow} />
            <div className={styles.actionContainer}>
              <div className={styles.actionButtonsContainer}>
                <p className={styles.actionTitle}>Actions</p>
                <Resend
                  // resendingInviteTo
                  // revokingInviteTo
                  className={styles.actionButtons}
                  isRevoked={item.invitedStatus === 'REVOKED' || false}
                  isResending={
                    (resendingInviteTo === item.id && isResending) || false
                  }
                  isRevoking={
                    (revokingInviteTo === item.id && isRevoking) || false
                  }
                  resend={() => resend(item)}
                  revoke={() => revoke(item)}
                />
              </div>
            </div>
          </section>
        ))}
    </div>
  );
}

export default InvitedMobileView;

import { Mutation, Query } from 'config';
import { toaster } from 'helpers';
// import { toaster } from 'helpers';
import { captureException } from 'helpers/sentry';
import {
  iAddTeamMember,
  iAddTeamMemberArgs,
  iAddTeamMemberResponse,
  iDeleteUserResponse,
  iDeleteUserrole,
  iDeleteUserRoleResponse,
  iGetItemUsersArgs,
  iGetItemUsersResponse,
  iGetUserByCompanyIdArgs,
  iGetUserByCompanyIdResponse,
  iGetUserByIdArgs,
  iGetUserByIdResponse,
  iGetUserByPassTokenArgs,
  iGetUserByPassTokenResponse,
  iGetUserRolesResponse,
  iGraphqlResponse,
  // iUpdateUserData,
  iResendInviteArgs,
  iResendInviteResponse,
  iRevokeInviteArgs,
  iRevokeInviteResponse,
  iRoleData,
  iShareAccessArgs,
  iShareAccessData,
  iShareAccessResponse,
  iUpdateUserArgs,
  iUpdateUserPasswordArgs,
  iUpdateUserPasswordResponse,
  // iUpdateUserResponse,
  iUserData,
} from 'types';
import {
  addTeamMemberMutation,
  deleteuserMutation,
  deleteuserRoleMutation,
  getItemUsersQuery,
  getRolesQuery,
  getUserByCompanyIdQuery,
  getUserByIdQuery,
  getUserByPassTokenQuery,
  resendInviteMutation,
  revokeInviteMutation,
  shareAccessQuery,
  updateUserMutation,
  updateUserPasswordMutation,
} from '../graphql';

export const updateUser = async (
  args: iUpdateUserArgs,
  isLoading?: (a: boolean) => void
): Promise<boolean | null> => {
  try {
    if (isLoading) isLoading(true);

    await Mutation(updateUserMutation, args);
    if (isLoading) isLoading(false);

    return true;
  } catch (error: any) {
    if (isLoading) isLoading(false);

    captureException(error);
    return null;
  }
};

export const getUserRoles = async (
  userId: string
): Promise<Array<iRoleData> | null> => {
  try {
    const response = <iGraphqlResponse<iGetUserRolesResponse>>(
      await Query(getRolesQuery, { userId })
    );
    return response.data.getUserroles.data;
  } catch (error: any) {
    captureException(error);
    return null;
  }
};

export const updateUserPassword = async (
  args: iUpdateUserPasswordArgs,
  isLoading
): Promise<{ id: null } | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iUpdateUserPasswordResponse>>(
      await Mutation(updateUserPasswordMutation, args)
    );
    isLoading(false);
    return response.data.updateUserpassword.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    captureException(error);
    return null;
  }
};

export const getUserByCompanyId = async (
  args: iGetUserByCompanyIdArgs,
  isLoading
): Promise<Array<iUserData> | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iGetUserByCompanyIdResponse>>(
      await Query(getUserByCompanyIdQuery, args)
    );
    isLoading(false);
    return response.data.getuserbyCompanyid.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const addTeamMember = async (
  args: iAddTeamMemberArgs,
  isLoading
): Promise<iAddTeamMember | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iAddTeamMemberResponse>>(
      await Mutation(addTeamMemberMutation, {
        ...args,
        // invitedStatus: 'INVITED',
      })
    );
    isLoading(false);
    return response.data.addUser;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    toaster.error("One or more entered email id's already exists");
    captureException(error);
    return null;
  }
};

export const getUser = async (
  args: iGetUserByIdArgs
): Promise<iUserData | null> => {
  try {
    const response = <iGraphqlResponse<iGetUserByIdResponse>>(
      await Query(getUserByIdQuery, args)
    );
    const { data } = response.data.getuserbyid;

    return data;
  } catch (error: any) {
    console.log(error.message);
    captureException(error);
    return null;
  }
};

export const getUserByPassToken = async (
  args: iGetUserByPassTokenArgs,
  isLoading
): Promise<iUserData | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iGetUserByPassTokenResponse>>(
      await Query(getUserByPassTokenQuery, args)
    );
    isLoading(false);
    return response.data.getuserbypasstoken.data;
  } catch (error: any) {
    // toaster.error('You may already verified, Please try to login');
    captureException(error);
    isLoading(false);
    return null;
  }
};

export const resendInvitation = async (
  args: iResendInviteArgs,
  isLoading
): Promise<iUserData | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iResendInviteResponse>>(
      await Mutation(resendInviteMutation, args)
    );
    isLoading(false);
    return response.data.resendinvite.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    captureException(error);
    return null;
  }
};

export const revokeInvitation = async (
  args: iRevokeInviteArgs,
  isLoading
): Promise<iUserData | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iRevokeInviteResponse>>(
      await Mutation(revokeInviteMutation, args)
    );
    isLoading(false);
    return response.data.revokeinvite.data;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    captureException(error);
    return null;
  }
};

export const deleteUser = async (
  id: string,
  isLoading
): Promise<boolean | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iDeleteUserResponse>>(
      await Mutation(deleteuserMutation, { id })
    );
    isLoading(false);
    if (response.data.deleteuser.data) {
      return true;
    }
    return false;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    captureException(error);
    return null;
  }
};

export const deleteUserRole = async (
  id: string,
  isLoading
): Promise<iDeleteUserrole | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iDeleteUserRoleResponse>>(
      await Mutation(deleteuserRoleMutation, { id })
    );
    isLoading(false);
    return response.data.deleteUserrole;
  } catch (error: any) {
    isLoading(false);
    console.log(error.message);
    captureException(error);
    return null;
  }
};

export const shareAccess = async (
  args: iShareAccessArgs,
  isLoading
): Promise<iShareAccessData | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iShareAccessResponse>>(
      await Mutation(shareAccessQuery, args)
    );
    isLoading(false);
    return response.data.shareAccess.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const getItemUsers = async (
  args: iGetItemUsersArgs,
  isLoading
): Promise<Array<iUserData> | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iGetItemUsersResponse>>(
      await Query(getItemUsersQuery, args)
    );
    isLoading(false);
    return response.data.getItemUsers.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

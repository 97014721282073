import { CalenderSettingsView, CARD_NAME } from 'enums';
import { MEETINGOVERVIEWFULL } from 'store/constants';
import { iOffice365DropdownOption } from 'types';

interface iActions {
  type: string;
  payload?: any;
}
export const meetingOverviewFullActions = {
  setCureentView: (data: CalenderSettingsView): iActions => ({
    type: MEETINGOVERVIEWFULL.setCurrentView,
    payload: data,
  }),
  setMeetingTemplateName: (data: string): iActions => ({
    type: MEETINGOVERVIEWFULL.setMeetingTemplateName,
    payload: data,
  }),
  setEventBgImg: (img: string): iActions => ({
    type: MEETINGOVERVIEWFULL.setOverviewEventBgImg,
    payload: img,
  }),
  setCurrentCard: (cardName: CARD_NAME | null): iActions => ({
    type: MEETINGOVERVIEWFULL.setCurrentCard,
    payload: cardName,
  }),
  setOverviewSettingsContentOption: (cardContent: any): iActions => ({
    type: MEETINGOVERVIEWFULL.setOverviewSettingsContentOption,
    payload: cardContent,
  }),
  setOverviewClockAllignement: (clockContent: any): iActions => ({
    type: MEETINGOVERVIEWFULL.setOverviewClockAllignement,
    payload: clockContent,
  }),
  setOverviewTimeZoneOption: (timeZOneContent: any): iActions => ({
    type: MEETINGOVERVIEWFULL.setOverviewTimeZoneOption,
    payload: timeZOneContent,
  }),
  setOverviewEventLogo: (eventLogo: any): iActions => ({
    type: MEETINGOVERVIEWFULL.setOverviewEventLogo,
    payload: eventLogo,
  }),
  updateOverviewCustomName: (name: any): iActions => ({
    type: MEETINGOVERVIEWFULL.updateOverviewCustomName,
    payload: name,
  }),
  updateRoomDetails: (details: any): iActions => ({
    type: MEETINGOVERVIEWFULL.updateRoomDetails,
    payload: details,
  }),
  setOverviewChoosedRoom: (roomData: any): iActions => ({
    type: MEETINGOVERVIEWFULL.setOverviewChoosedRoom,
    payload: roomData,
  }),
  setOverviewAllCalenderEvents: (calendarEvents: any): iActions => ({
    type: MEETINGOVERVIEWFULL.setOverviewAllCalenderEvents,
    payload: calendarEvents,
  }),
  setOverviewCurrentCalenderEvents: (calendarEvents: any): iActions => ({
    type: MEETINGOVERVIEWFULL.setOverviewCurrentCalenderEvents,
    payload: calendarEvents,
  }),
  setOverviewNextCurrentCalenderEvents: (calendarEvents: any): iActions => ({
    type: MEETINGOVERVIEWFULL.setOverviewNextCurrentCalenderEvents,
    payload: calendarEvents,
  }),
  setIsShowOverviewSubject: (isOn: any): iActions => ({
    type: MEETINGOVERVIEWFULL.setIsShowOverviewSubject,
    payload: isOn,
  }),
  setIsShowOverviewHostname: (isOn: any): iActions => ({
    type: MEETINGOVERVIEWFULL.setIsShowOverviewHostname,
    payload: isOn,
  }),
  setIsShowOverviewNextMeeting: (isOn: any): iActions => ({
    type: MEETINGOVERVIEWFULL.setIsShowOverviewNextMeeting,
    payload: isOn,
  }),
  setOverviewContentsId: (option: any): iActions => ({
    type: MEETINGOVERVIEWFULL.setOverviewContentsId,
    payload: option,
  }),
  setLargeAreaContent: (option: iOffice365DropdownOption) => ({
    type: MEETINGOVERVIEWFULL.setLargeAreaContent,
    payload: option,
  }),
  updateOverviewPretitle: (preTitle: string) => ({
    type: MEETINGOVERVIEWFULL.updateOverviewPretitle,
    payload: preTitle,
  }),
  updateOverviewTitle: (title: string) => ({
    type: MEETINGOVERVIEWFULL.updateOverviewTitle,
    payload: title,
  }),
  updateOverviewBody: (title: string) => ({
    type: MEETINGOVERVIEWFULL.updateOverviewBody,
    payload: title,
  }),
  updateOverviewSubtext: (subText: string) => ({
    type: MEETINGOVERVIEWFULL.updateOverviewSubtext,
    payload: subText,
  }),
  setOverviewLargeImage: (url: string) => ({
    type: MEETINGOVERVIEWFULL.setOverviewLargeImage,
    payload: url,
  }),
  resetOverviewTemplateData: (): iActions => ({
    type: MEETINGOVERVIEWFULL.resetOverviewTemplateData,
  }),
  setTransparency: (transparency: string) => ({
    type: MEETINGOVERVIEWFULL.setTransparency,
    payload: transparency,
  }),
};

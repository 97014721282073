import englishData from '../modules/office-365/calender-events/english.json';
import norwayData from '../modules/office-365/calender-events/norwegian.json';

export * from './environment';
export * from './client';
export * from './common';
export * from './icons';
export * from './paths';
export { englishData, norwayData };
export * from './playlists';
export * from './teammemberlist';
export * from './teammemberpendinglist';

export const SENTRY_ID =
  'https://64e7df2f5e1843d5a89b11159fbc3656@o355766.ingest.sentry.io/6491726';

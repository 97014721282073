import { deleteScreenById, getScreenById } from 'apis';
import AssignContentModal from 'components/assign-content-modal/assignContentModal';
// import Button, { ButtonVariants } from 'components/button/button';
import DeleteModal, {
  DeleteModalVariants,
} from 'components/delete-modal/deleteModal';
import Layout, { LayoutVariants } from 'components/Layouts/layout';
import Remote from 'components/remote/remote';
import UploadContentModal from 'components/upload-content-modal/uploadContentModal';
import { ROUTES } from 'constants/index';
import { toaster } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { homePageActions, layoutActions } from 'store/actions';
import PingTime from 'components/ping-time/pingTime';
import { iScreenData, iState } from 'types';
import RoleBasedHeader from 'components/Layouts/components/role-based-header/roleBasedHeader';
import { UserRoles } from 'enums';
import MainHeader from 'components/Layouts/components/header/header';
import Details from './components/details/details';
import Header from './components/header/header';
import Screenshot from './components/screenshot/screenshot';
import Subheader from './components/subheader/subheader';
import styles from './screenDetailsView.module.scss';

function ScreenDetails(): JSX.Element {
  const [state, dispatch]: [iState, any] = useStore();
  const { homePage, layout } = state || {};
  const { role } = state.user || {};
  const { screenzId, screenData, invokeGetScreenById } = homePage || {};
  const { isShowDeleteScreen } = layout || {};

  const [view, setView] = useState<'Screen' | 'Remote'>('Screen');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteDelayMessage, setDeleteDelayMessage] = useState<string>('');
  const [screenDetails, setScreenDetails] = useState<iScreenData>();
  const { screenShotUrl, lastPing } = screenDetails || {};

  //  console.log('screenData____', screenData);

  const navigate = useNavigate();
  useEffect(() => {
    if (!screenData?.id) navigate(ROUTES.screenz);
  }, [screenData?.id, navigate]);

  const getScreen = useCallback(async () => {
    const response = await getScreenById({ id: screenzId }, setIsLoading);
    if (!response) return;
    dispatch(
      homePageActions.setScreenData({
        ...response,
        screenShotUrl: response.screenShotUrl,
      })
    );
    console.log(response, 'screen');
    dispatch(homePageActions.invokeGetScreenById(false));
    dispatch(homePageActions.setScreenId(response.hashCode));
    setScreenDetails(response);
  }, [screenzId, dispatch, invokeGetScreenById]);

  useEffect(() => {
    getScreen();
  }, [getScreen]);

  const deleteItem = async (): Promise<void> => {
    if (screenDetails) {
      // setIsDeleting(true);
      const response = await deleteScreenById({ id: screenDetails?.id });
      if (response?.statusCode === 200) {
        setDeleteDelayMessage(
          'Your screen is being deleted. This will take a minute or two. Please wait!'
        );
        /**
         * Dont change this delay code,  Mohan asked to implement the delay, for some scenario
         * */
        setIsDeleting(false);
        dispatch(layoutActions.toggleModal());
        toaster.success(
          'Your screen is being deleted. This will take a minute or two. Please wait!',
          40000
        );
        setTimeout(() => {
          setDeleteDelayMessage('');
          navigate(ROUTES.screenz);
          toaster.success('Screen deleted successfully');
        }, 40000);
      } else {
        setIsDeleting(false);
      }
    }
  };

  const screenId = screenData?.id;

  return (
    <Layout variant={LayoutVariants.layoutOne}>
      <div className={styles.screenDetails}>
        {role === UserRoles.SuperAdmin && <RoleBasedHeader />}
        <AssignContentModal />
        <UploadContentModal />
        <MainHeader />
        <Header />
        <div className={styles.mainContent}>
          <div className={styles.colOne}>
            <Subheader isLoading={isLoading} view={view} />
            <div className={styles.main}>
              <Screenshot
                src={screenShotUrl}
                screenShotTime={screenData?.screenShotTiming}
              />
              {screenId && (
                <Remote className={styles.remote} screenId={screenId} />
              )}
            </div>
          </div>
          <Details className={styles.details} />
        </div>
        <div className={styles.responsiveView}>
          <Subheader view={view} onClick={setView} isLoading={isLoading} />
          {view === 'Screen' && (
            <>
              <Screenshot
                className={styles.screenshot}
                src={screenShotUrl}
                screenShotTime={screenData?.screenShotTiming}
              />
              <Details variant="mobile" />
              <PingTime
                isLoading={isLoading}
                className={styles.pingTime}
                lastPing={lastPing || ''}
              />
              {/* <Header /> */}
            </>
          )}
          {view === 'Remote' && screenId && (
            <Remote className={styles.remote} screenId={screenId} />
          )}
        </div>
        <DeleteModal
          isOpen={isShowDeleteScreen}
          variant={DeleteModalVariants.Screen}
          isLoading={isDeleting}
          name={screenDetails?.name || ''}
          deleteHandler={deleteItem}
          message={deleteDelayMessage}
        />
      </div>
    </Layout>
  );
}

ScreenDetails.defaultProps = {};
export default ScreenDetails;

import React, { useEffect } from 'react';
import AddRoleModal from 'components/add-role-modal/addRoleModal';
// import { iState } from 'types';
// import { ModalViews } from 'enums';
// import { layoutActions } from 'store/actions';
// import { useStore } from 'store';
// import Button, { ButtonVariants } from 'components/button/button';
// import { whiteAddIcon } from 'constants/index';

import Layout, { LayoutVariants } from 'components/Layouts/layout';
import RolesTable from './roles-table/rolesTable';
import styles from './roles.module.scss';

interface iProps {
  className?: string;
}

function Roles(props: iProps): JSX.Element {
  const { className } = props;
  // const [, dispatch]: [iState, any] = useStore();
  // return <div className={`${styles.roleWrapper} ${className}`}>Roles</div>;

  useEffect(() => {
    document.title = 'Roles';
  }, []);

  return (
    <Layout variant={LayoutVariants.layoutFour}>
      <section className={`${styles.roleWrapper} ${className}`}>
        <div className={styles.componentHeading}>
          <p className={styles.heading}>Roles</p>
          {/* <Button
            className={styles.addRoleBtn}
            btnName="Role"
            isDisabled
            icon={whiteAddIcon}
            iconPosition="left"
            variant={ButtonVariants.SmallStd}
            onClick={() => {
              dispatch(layoutActions.toggleModal(ModalViews.isShowAddRole));
            }}
          /> */}
        </div>
        <div className={styles.rolesList}>
          <RolesTable />
        </div>
        <AddRoleModal />
      </section>
    </Layout>
  );
}

Roles.defaultProps = {
  className: '',
};

export default Roles;

import Popover from 'components/popover/popover';
import Dustbin from 'components/svg/dustbin';
import More from 'components/svg/more';
import { teamMembersTableColumns, ROUTES } from 'constants/index';
import { ModalViews, TwoFAType, UserSettingsView } from 'enums';
import React, { useCallback, useMemo, useState } from 'react';
import { useTable } from 'react-table';
import { useStore } from 'store';
import { commonActions, layoutActions, userActions } from 'store/actions';
import { iState, iUserData } from 'types';
import { useNavigate } from 'react-router-dom';
import DeleteModal, {
  DeleteModalVariants,
} from 'components/delete-modal/deleteModal';
import { toaster } from 'helpers';
import { deleteUser } from 'apis';
import Avatar from './components/avatar/avatar';
import NotSetup from './components/not-setup/notSetup';
import styles from './teamMember.module.scss';

interface iProps {
  teamMembers: iUserData[];
  setFetchAgainTeammates: (a: boolean) => void;
}

function TeamMemberList(props: iProps): JSX.Element {
  const { teamMembers, setFetchAgainTeammates } = props;
  // const [isLoading] = useState<boolean>(false);
  const [state, dispatch]: [iState, any] = useStore();
  const { companyData } = state.homePage || {};
  const { removeTeammates } = state.user.userPermissions || {};
  // const { removeTeammateData, loggedInData } = state?.user || {};
  //  console.log({ isLoading });
  const columns = useMemo(() => teamMembersTableColumns, []);
  const data = useMemo(() => teamMembers, [teamMembers]);
  // const { loggedInData } = state.user || {};
  const { layout } = state || {};
  // const { firstName, lastName } = loggedInData || {};
  const { isShowDeleteContent } = layout || {};
  const { removeTeammateData, loggedInData } = state?.user || {};
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isContentUsedSomeWhere, setIsContentUsedSomeWhere] =
    useState<boolean>(false);
  console.log(isContentUsedSomeWhere, setIsDeleting);

  const tableInstance = useTable({
    columns,
    data,
  });
  const changeView = useCallback((cell) => {
    // if (
    //   companyData?.role?.id === '3' ||
    //   (`${firstName}${lastName}` ===
    //     `${cell.row.original.first_name}${cell.row.original.last_name}` &&
    //     companyData?.role?.id === '2')
    // ) {
    //   return;
    // }
    dispatch(userActions.changeView(UserSettingsView.TeamMemberPermissions));
    dispatch(userActions.setUserCompanyId(cell.row.original.companyUserId));
    dispatch(
      userActions.setUserId(
        `${cell.row.original.first_name} ${cell.row.original.last_name}`
      )
    );
    dispatch(userActions.setRemoveTeammate(cell.row.original));
    navigate(ROUTES.userSettingsPermissions);
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const openDeleteTeammateModal = useCallback(
    (user: iUserData) => {
      dispatch(userActions.setRemoveTeammate(user));
      dispatch(layoutActions.toggleModal(ModalViews.isShowDeleteContent));
    },
    [dispatch]
  );

  const getDateFormatedDate = (dateTime: string): string => {
    if (!dateTime) return '';
    const date = new Date(dateTime);
    return (
      (date &&
        `${date.getDate()}.${
          date.getMonth() + 1
        }.${date.getFullYear()} ${date.getHours()}.${date.getMinutes()}`) ||
      ''
    );
  };

  const removeTeammateHandler = useCallback(async () => {
    const { companyUserId } = removeTeammateData || {};
    if (!companyUserId) return;
    const response = await deleteUser(companyUserId, () => {});
    if (response) {
      if (
        removeTeammateData?.id === loggedInData?.id &&
        removeTeammateData?.email === loggedInData?.email
      ) {
        dispatch(commonActions.reset());
      }
      toaster.success('The teammate removed successfully ');
      setFetchAgainTeammates(true);
      dispatch(layoutActions.toggleModal());
      navigate(ROUTES.userSettingsTeamMembers);
    }
  }, [dispatch, navigate, removeTeammateData]);

  return (
    <>
      <table {...getTableProps()} className={styles.tableHead}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const { id } = cell.column || {};
                  // const { role } = cell.row.original || {};
                  const userAvatar = id === 'user';
                  const notSetup = id === 'fA';
                  const lastActive = id === 'lastActiveTime';
                  const isShowPermission = id === 'permission';
                  // const permissions =
                  //   (role.id === ('1' || '2') && 'Full Permission') ||
                  //   'Limited Permission';
                  const isMore = id === 'more';
                  // console.log(
                  //   cell.row.original.permissionType,
                  //   'cell.row.original'
                  // );
                  const settings = cell?.row?.original?.settings;
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={`${
                        id === 'lastActiveTime' && styles.lastActive
                      }`}
                      onClick={() => {
                        changeView(cell);
                      }}
                    >
                      {notSetup && (
                        <NotSetup
                          isEnabled={
                            settings
                              ? settings?.twoFA !== TwoFAType.DISABLED
                              : false
                          }
                        />
                      )}
                      {isShowPermission &&
                        (cell.row.original.permissionType === 'limitedAccess'
                          ? 'Limited Access'
                          : 'Full Access')}
                      {userAvatar && <Avatar data={cell.value} />}
                      {lastActive && getDateFormatedDate(cell.value)}
                      {isMore && (
                        <Popover
                          className={styles.morePopupContainer}
                          popoverClassName={styles.popover}
                          popoverComponent={
                            <div
                              className={styles.morePopup}
                              onClick={(event) => {
                                const teamMemberCanDeleteOwner =
                                  companyData?.role?.id === '3' &&
                                  cell?.row?.original?.role?.id === '2';
                                if (!teamMemberCanDeleteOwner) {
                                  if (!removeTeammates)
                                    toaster.error(
                                      'You do not have access to remove'
                                    );
                                  if (removeTeammates) {
                                    event.stopPropagation();
                                    openDeleteTeammateModal(cell.row.original);
                                  }
                                } else {
                                  toaster.error(
                                    'Team member do not have the access to delete the owner'
                                  );
                                }
                              }}
                            >
                              <Dustbin className={styles.moreBtn} />
                              <p>Remove Team Member</p>
                            </div>
                          }
                        >
                          <div className={styles.moreBtn}>
                            <More className={styles.icon} />
                          </div>
                        </Popover>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <DeleteModal
        isOpen={isShowDeleteContent}
        variant={DeleteModalVariants.Teammate}
        name={
          `${removeTeammateData?.first_name} ${removeTeammateData?.last_name}` ||
          ''
        }
        isLoading={isDeleting}
        onClose={() => setIsContentUsedSomeWhere(false)}
        confirmationMsg="Are you sure you want to remove this user?"
        usageNotification="The user will no longer be able to access Screenz."
        deleteHandler={removeTeammateHandler}
        displayInput={false}
      />
    </>
  );
}

export default TeamMemberList;

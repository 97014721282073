import Screen from 'components/svg/screenz';
import Content from 'components/svg/content';
import Playlist from 'components/svg/playlist';

export enum SpecificPermissionsVariants {
  ScreensAccess = 'screensAccess',
  ContentAccess = 'contentAccess',
  PlaylistAccess = 'PlaylistAccess',
}

export const Variants = {
  [SpecificPermissionsVariants.ScreensAccess]: {
    icon: Screen,
    accessType: 'Screens Access',
    all: 'All screens',
    specific: 'Screens assigned to them only',
  },
  [SpecificPermissionsVariants.ContentAccess]: {
    icon: Content,
    accessType: 'Content Access',
    all: 'All content',
    specific: 'Content assigned to them only',
  },
  [SpecificPermissionsVariants.PlaylistAccess]: {
    icon: Playlist,
    accessType: 'Playlist Access',
    all: 'All playlists',
    specific: 'Playlists assigned to them only',
  },
};

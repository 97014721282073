export const getCurrentTime = (timeZone = ''): string => {
  const date = new Date(new Date().toLocaleString('en-US', { timeZone }));
  let hours = `${date.getHours()}`;
  hours = (hours.length === 1 && `0${hours}`) || hours;
  let minutes = `${date.getMinutes()}`;
  minutes = (minutes.length === 1 && `0${minutes}`) || minutes;
  return `${hours}:${minutes}`;
};

export const getCurrentDate = (timeZone = ''): string => {
  const DAYS = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const date = new Date(new Date().toLocaleString('en-US', { timeZone }));
  return `${DAYS[date.getDay()]} ${date.getDate()}, ${date.getFullYear()}`;
};

export const getHours = (date: string): string => {
  let hours = `${new Date(date).getHours()}`;
  hours = (hours.length === 1 && `0${hours}`) || hours;
  return hours;
};

export const getMinutes = (date: string): string => {
  let minutes = `${new Date(date).getMinutes()}`;
  minutes = (minutes.length === 1 && `0${minutes}`) || minutes;
  return minutes;
};

import React, { useCallback, useState } from 'react';
import Modal from 'components/modal/modal';
import { useStore } from 'store';
import { toaster } from 'helpers';
import { securityIcon } from 'constants/index';
import { TwoFAType } from 'enums';
import { setUp2FA } from 'apis';
import { layoutActions, userActions } from 'store/actions';
import { iState } from 'types';
import styles from './disableTwoFaModal.module.scss';

function DisableTwoFa(): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [state, dispatch]: [iState, any] = useStore();
  const { isShowDisable2FA } = state.layout || {};
  const { id } = state.user.loggedInData || {};

  const disable2FAHandler = useCallback(async () => {
    if (!id) return;
    const response = await setUp2FA(
      {
        userId: id,
        twoFAType: TwoFAType.DISABLED,
      },
      setIsLoading
    );
    if (response) {
      toaster.success('Successfully disabled 2FA');
      dispatch(layoutActions.toggleModal());
      dispatch(
        userActions.set2FAData({
          id: '',
          twoFA: TwoFAType.DISABLED,
          isDarkMode: false,
          authQR: '',
        })
      );
    }
  }, [id, dispatch]);

  return (
    <Modal
      isOpen={isShowDisable2FA}
      heading="Disable 2FA"
      btnName="Disable"
      isLoading={isLoading}
      headingIcon={securityIcon}
      submit={disable2FAHandler}
      onClose={() => {
        dispatch(layoutActions.toggleModal());
      }}
    >
      <div className={styles.disable2FA}>
        <p>
          Two factor authentication is a second level authentication for
          enchanced security. Do you really want to disable it?
        </p>
      </div>
    </Modal>
  );
}

DisableTwoFa.defaultProps = {
  className: '',
};

export default DisableTwoFa;

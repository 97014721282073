import { getUser, updateUser } from 'apis';
import Button, { ButtonVariants } from 'components/button/button';
import LabelWithInput from 'components/label-and-input/labelAndInput';
import Logo from 'components/logo/logo';
import { ROUTES } from 'constants/index';
import { toaster, validators } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useStore } from 'store';
import { commonActions } from 'store/actions';
import { iState, iUserData } from 'types';
// import { useStore } from 'store';
// import { iState } from 'types';
import Pattern from '../components/pattern/pattern';
import styles from './setNewPassword.module.scss';

interface iNewPassword {
  password: string;
  confirmPassword: string;
}

function Login(): JSX.Element {
  // const [state]: [iState] = useStore();
  const [userData, setUserData] = useState<iUserData>();

  const [newPassword, setPassword] = useState<iNewPassword>({
    password: '',
    confirmPassword: '',
  });
  const navigate = useNavigate();
  const [, dispatch]: [iState, any] = useStore();

  // const { userId = '' } = state.user.resetPasswordData || {};
  const [passwordErrorMassage, setPasswordErrorMassage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmPasswordErrorMassage, setConfirmPasswordErrorMassage] =
    useState<string>('');
  const { password, confirmPassword } = newPassword;

  const queryParams = new URLSearchParams(window.location.search);
  const userId = queryParams.get('token') || '';

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const temp = { ...newPassword };
      temp[event.target.name] = event.target.value;
      setPassword(temp);
      setPasswordErrorMassage('');
      setConfirmPasswordErrorMassage('');
    },
    [newPassword]
  );

  const getUserHandler = useCallback(async () => {
    if (!userId) {
      return;
    }
    const response = await getUser({
      // userId: '07b71bf3-7e5c-4196-acc2-694a6605faf7',
      userId,
    });

    if (response) {
      setUserData(response);
    }
  }, [userId]);

  useEffect(() => {
    getUserHandler();
  }, [getUserHandler]);

  const setNewPasswordHandler = useCallback(async (): Promise<void> => {
    if (!password && !confirmPassword) {
      setPasswordErrorMassage('Please enter the Password');
      setConfirmPasswordErrorMassage('Please enter the Confirm Password');
      return;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordErrorMassage(
        'Password and Confirm password should be same'
      );
      return;
    }
    if (!validators.validatePassword(password)) {
      setPasswordErrorMassage(
        'Password must contain minimum of 8 characters including of atleast, 1 upper letter, 1 small letter, 1 digit, 1 special character'
      );
      setConfirmPasswordErrorMassage(
        'Password must contain minimum of 8 characters including of atleast, 1 upper letter, 1 small letter, 1 digit, 1 special character'
      );
      return;
    }
    const { first_name, last_name, email, profile_pic } = userData || {};

    const response = await updateUser(
      {
        id: userId,
        password,
        firstName: first_name,
        lastName: last_name,
        email,
        profile_pic,
        // companyId: userData?.company?.id,
        // roleId: `${userData?.role.id}`,
        invitedStatus: 'ACCEPTED',
      },
      setIsLoading
    );

    if (response) {
      toaster.success('Password reset successfully, Please login');
      window.Intercom('shutdown');
      localStorage.removeItem('isLoggedIn');
      window.Intercom('boot', {
        app_id: 'd7jw43br',
      });
      dispatch(commonActions.reset());
      navigate(ROUTES.home);
    }
  }, [password, confirmPassword, navigate, userId, userData]);

  return (
    <div className={`flex flex-col p3 ${styles.setNewPasswordWrapper}`}>
      <Pattern position="top" />
      <Pattern position="bottom" />
      <section className={`flex-center flex-grow ${styles.section}`}>
        <div className={`${styles.container}`}>
          <Logo className={styles.logo} variant="onboarding" />
          <p className={styles.headingOne}>Create new password</p>
          <p className={styles.headingTwo}>
            Your new password must be different from previous used passwords.
          </p>

          <LabelWithInput
            className={styles.inputLabel}
            label="Password"
            type="password"
            name="password"
            value={newPassword.password}
            onChange={onChange}
            errorMessage={passwordErrorMassage}
          />
          <p className={`mb5  ${styles.passwordCriteria}`}>
            Must be at least 8 characters.
          </p>

          <LabelWithInput
            className={styles.inputLabel}
            type="password"
            label="Confirm Password"
            name="confirmPassword"
            value={newPassword.confirmPassword}
            onChange={onChange}
            errorMessage={confirmPasswordErrorMassage}
          />
          <p className={`mb6 ${styles.passwordCriteria}`}>
            Both passwords must match.
          </p>

          <Button
            className={styles.resetPwdBtn}
            btnName="Reset Password"
            variant={ButtonVariants.MediumStd}
            onClick={setNewPasswordHandler}
            isLoading={isLoading}
          />
        </div>
      </section>
      {/* <Chat /> */}
    </div>
  );
}

export default Login;

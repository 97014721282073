export const ON_BOARDING = {
  loginData: 'loginData',
  set2FAData: 'set2FAData',
};

export const LAYOUT = {
  setWindowWidth: 'setWindowWidth',
  invokeGetAllFolders: 'invokeGetAllFolders',
  invokeGetFolderById: 'invokeGetFolderById',
  setHomePageView: 'setHomePageView',
  openAddContentModal: 'openAddContentModal',
  openFilterContentModal: 'openFilterContentModal',
  openAddWebContentModal: 'openAddWebContentModal',
  toggleSideDrawer: 'toggleSideDrawer',
  initialSidebarState: 'initialSidebarState',
  setShowClientsInstance: 'setShowClientsInstance',
  toggleModal: 'toggleModal',
  setAssignModalContentFolders: 'setAssignModalContentFolders',
  setAssignModalContentActiveFolders: 'setAssignModalContentActiveFolders',
  setAssignModalPlaylistActiveFolder: 'setAssignModalPlaylistActiveFolder',
  setAssignModalContentsFolderHierarchy:
    'setAssignModalContentsFolderHierarchy',
  setEmptyStates: 'setEmptyStates',
  invokeFetchMSAccounts: 'invokeFetchMSAccounts',
  setTemplateToView: 'setTemplateToView',
};

export const HOME_PAGE = {
  setScreenData: 'setScreenData',
  setScreenId: 'setScreenId',
  setActiveCompanyData: 'setActiveCompanyData',
  updateScreenData: 'updateScreenData',
  addScreen: 'addScreen',
  fetchScreensData: 'fetchScreensData',
  setScreensFolderHierarchy: 'setScreensFolderHierarchy',
  setIsScreenBeingAdded: 'setIsScreenBeingAdded',
  addFolder: 'addFolder',
  setIsFetchingFolders: 'setIsFetchingFolders',
  invokeGetCompanies: 'invokeGetCompanies',
  screenzId: 'screenzId',
  setCompanies: 'setCompanies',
  searchCompanyKey: 'searchCompanyKey',
  searchScreenKey: 'searchScreenKey',
  setTotalOnlineCount: 'setTotalOnlineCount',
  setTotalOfflineCount: 'setTotalOfflineCount',
  setCompanyData: 'setCompanyData',
  screenOrderChange: 'screenOrderChange',
  addSuperUserClient: 'addSuperUserClient',
  addSuperUserResellerClient: 'addSuperUserResellerClient',
  addResellerClient: 'addResellerClient',
  setActiveFolder: 'setActiveFolder',
  setActiveClientInstanceData: 'setActiveClientInstanceData',
  setScreensFolders: 'setScreensFolders',
  setScreensActiveFolder: 'setScreensActiveFolder',
  setContentToAssignToScreen: 'setContentToAssignToScreen',
  setPlaylistToAssignToScreen: 'setPlaylistToAssignToScreen',
  invokeUpdateScreen: 'invokeUpdateScreen',
  invokeGetScreenById: 'invokeGetScreenById',
  setIsSaveAndCloseDisabled: 'setIsSaveAndCloseDisabled',
  setLastFetchedTime: 'setLastFetchedTime',
  setScreensOnlineStatus: 'setScreensOnlineStatus',
};

export const CONTENT_PAGE = {
  setUploadingFiles: 'setUploadingFiles',
  setContentView: 'setContentView',
  setContents: 'setContents',
  updateWebPageOrientation: 'updateWebPageOrientation',
  fetchContentsData: 'fetchContentsData',
  updateUploadingStatus: 'updateUploadingStatus',
  removeFile: 'removeFile',
  setAddWebContentData: 'setAddWebContentData',
  clearUploadingFiles: 'clearUploadingFiles',
  emptyState: 'emptyState',
  setisTriedToLoadWebsiteTwo: 'setisTriedToLoadWebsiteTwo',
  setContentFolderHierarchy: 'setContentFolderHierarchy',
  showCancelAndSaveBtns: 'showCancelAndSaveBtns',
  resetEmptyAndBtns: 'resetEmptyAndBtns',
  setSearchContentKey: 'setSearchContentKey',
  setContentFolders: 'setContentFolders',
  setContentActiveFolder: 'setContentActiveFolder',
  isEditWebContent: 'isEditWebContent',
  setTemplatePreview: 'setTemplatePreview',
};
export const PLAYLIST_PAGE = {
  addPlaylist: 'addPlaylist',
  setPlaylists: 'setPlaylists',
  setPlaylistData: 'setPlaylistData',
  addContentToPlaylist: 'addContentToPlaylist',
  clearAddContentToPlaylist: 'clearAddContentToPlaylist',
  fetchPlaylistsData: 'fetchPlaylistsData',
  invokeUpdatePlaylist: 'invokeUpdatePlaylist',
  updatePlaylist: 'updatePlaylist',
  setSearchPlaylistKey: 'setSearchPlaylistKey',
  setPlaylistsFolders: 'setPlaylistsFolders',
  setPlaylistsActiveFolder: 'setPlaylistsActiveFolder',
  setPlaylistsFolderHierarchy: 'setPlaylistsFolderHierarchy',
  setContentView: 'setContentView',
  setPlaylistPreview: 'setPlaylistPreview',
  toggleLlaylistSaveAndCloseBtn: 'toggleLlaylistSaveAndCloseBtn',
  showNewlyAssignedContentsInList: 'showNewlyAssignedContentsInList',
  invokeDeletePlaylist: 'invokeDeletePlaylist',
};

export const COMMON = {
  reset: 'reset',
};

export const USER = {
  setLoginData: 'setLoginData',
  changeView: 'changeView',
  setAllRoles: 'setAllRoles',
  setResetPasswordData: 'setResetPasswordData',
  updateLoggedInUserData: 'updateLoggedInUserData',
  setRemoveTeammate: 'setRemoveTeammate',
  set2FAData: 'set2FAData',
  changeUserRole: 'changeUserRole',
  setCanFetchRoles: 'setCanFetchRoles',
  setClientChoosed: 'setClientChoosed',
  setUserCompanyId: 'setUserCompanyId',
  setUserId: 'setUserId',
  setUserPermissions: 'setUserPermissions',
};

export const OFFICE_365 = {
  setCurrentView: 'setCurrentView',
  setEventBgImg: 'setEventBgImg',
  setEventLogo: 'setEventLogo',
  setIsShowSubject: 'setIsShowSubject',
  setFetchedMSAccounts: 'setFetchedMSAccounts',
  setIsMSaccountsFetched: 'setIsMSaccountsFetched',
  setIsShowClock: 'setIsShowClock',
  setMsAccountAccessResponse: 'setMsAccountAccessResponse',
  setEventContentType: 'setEventContentType',
  setIsShowHostName: 'setIsShowHostName',
  setResponseAfterLogin: 'setResponseAfterLogin',
  setIsShowNextMeeting: 'setIsShowNextMeeting',
  setIsShowactivateBooking: 'setIsShowactivateBooking',
  setContentAreaContentOption: 'setContentAreaContentOption',
  setContentSettingsContentOption: 'setContentSettingsContentOption',
  setTimeZoneOption: 'setTimeZoneOption',
  setIsMStokensFetched: 'setIsMStokensFetched',
  setupdateRoomCustomName: 'setupdateRoomCustomName',
  setChoosedRoom: 'setChoosedRoom',
  updateCustomName: 'updateCustomName',
  setTemplateName: 'setTemplateName',
  setAllCalenderEvents: 'setAllCalenderEvents',
  setCurrentEvents: 'setCurrentEvents',
  setNextEvents: 'setNextEvents',
  setActiveMsAccount: 'setActiveMsAccount',
  setMsAccountAccessToken: 'setMsAccountAccessToken',
  setCustomName: 'setCustomName',
  setStoredAccountRooms: 'setStoredAccountRooms',
  addOrRemoveRoom: 'addOrRemoveRoom',
  setO365UserRooms: 'setO365UserRooms',
  resetTemplatData: 'resetTemplatData',
  setTemplateIdToEdit: 'setTemplateIdToEdit',
  setTempalteDataToUpdate: 'setTempalteDataToUpdate',
  setChosenTemplateType: 'setChosenTemplateType',
  setSelectedLanguage: 'setSelectedLanguage',
};

export const MEETINGOVERVIEWFULL = {
  setCurrentView: 'setCurrentView',
  setMeetingTemplateName: 'setMeetingTemplateName',
  setOverviewEventBgImg: 'setOverviewEventBgImg',
  setCurrentCard: 'setCurrentCard',
  setOverviewSettingsContentOption: 'setOverviewSettingsContentOption',
  setOverviewClockAllignement: 'setOverviewClockAllignement',
  setOverviewTimeZoneOption: 'setOverviewTimeZoneOption',
  setOverviewEventLogo: 'setOverviewEventLogo',
  updateOverviewCustomName: 'updateOverviewCustomName',
  updateRoomDetails: 'updateRoomDetails',
  setOverviewChoosedRoom: 'setOverviewChoosedRoom',
  setOverviewAllCalenderEvents: 'setOverviewAllCalenderEvents',
  setOverviewCurrentCalenderEvents: 'setOverviewCurrentCalenderEvents',
  setOverviewNextCurrentCalenderEvents: 'setOverviewNextCurrentCalenderEvents',
  setIsShowOverviewSubject: 'setIsShowOverviewSubject',
  setIsShowOverviewHostname: 'setIsShowOverviewHostname',
  setIsShowOverviewNextMeeting: 'setIsShowOverviewNextMeeting',
  resetOverviewTemplateData: 'resetOverviewTemplateData',
  setOverviewContentsId: 'setOverviewContentsId',
  setLargeAreaContent: 'setLargeAreaContent',
  updateOverviewPretitle: 'updateOverviewPretitle',
  updateOverviewTitle: 'updateOverviewTitle',
  updateOverviewBody: 'updateOverviewBody',
  updateOverviewSubtext: 'updateOverviewSubtext',
  setOverviewLargeImage: 'setOverviewLargeImage',
  setTransparency: 'setTransparency',
};

import Layout, { LayoutVariants } from 'components/Layouts/layout';
import React from 'react';
import LocalLayout from './components/layout/layout';

function UserSettings(): JSX.Element {
  return (
    <Layout variant={LayoutVariants.layoutFour}>
      <LocalLayout />
    </Layout>
  );
}

export default UserSettings;

import React, { useState, useCallback, useEffect } from 'react';
import Button, { ButtonVariants } from 'components/button/button';
import Screen from 'components/svg/screenz';
import { iState } from 'types';
import { validateUrl } from 'apis';
import OrientationAndSize from 'components/orientation-and-size/orientationAndSize';
import { toaster } from 'helpers';
import { useStore } from 'store';
import { ScreenType } from 'enums';
import { contentPageActions } from 'store/actions';
import Popover from 'components/popover/popover';
import PortraitScren from 'components/svg/portraitScren';
import { caretFilledSmallDown, screenRotation } from 'constants/index';
import styles from './sidebar.module.scss';

const ENTER_KEY = 'Enter';
interface iProps {
  className?: string;
  openWebSite: (a: boolean) => void;
  refresher: () => void;
  setOreintation: (a: ScreenType) => void;
}

const SCREEN_SIZES = [
  { id: '1', option: 'Landscape (16:9)', value: 'Landscape (16:9)' },
  { id: '2', option: 'Landscape HD (16:9)', value: 'Landscape HD (16:9)' },
  { id: '3', option: 'Landscape UHD (16:9)', value: 'Landscape UHD (16:9)' },
  { id: '4', option: 'Portarit (9:16)', value: 'Portarit (9:16)' },
  { id: '5', option: 'Portrait HD (9:16)', value: 'Portrait HD (9:16)' },
  { id: '6', option: 'Portrait UHD (9:16)', value: 'Portrait UHD (9:16)' },
];

const refreshIntervals = [
  { id: '1', option: 'Do Not Reload', time: 0 },
  { id: '2', option: 'Every 5 minutes', time: 5 },
  { id: '3', option: 'Every 30 minutes', time: 30 },
  { id: '4', option: 'Every hour', time: 60 },
  { id: '3', option: 'Every 6 hours', time: 6 * 60 },
  { id: '3', option: 'Every 12 hours', time: 12 * 60 },
];

function Sidebar(props: iProps): JSX.Element {
  const { className, openWebSite, refresher, setOreintation } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const [refreshTime, setRefreshTime] = useState<{
    id: string;
    option: string;
    time: number;
  }>();
  const [screenSize, setScreenSize] = useState<string>(SCREEN_SIZES[0].value);
  const [selectedOrientation, setSelectedOrientation] = useState<ScreenType>(
    ScreenType.HORIZONTAL
  );

  const {
    addWebContentData,
    isEditWebContent,
    contentView,
    // isTriedToLoadWebsiteTwo,
  } = state.contentPage;
  const { url, webSiteName } = addWebContentData || {};
  const isLandscape = selectedOrientation === ScreenType.HORIZONTAL;
  const isPortrait = selectedOrientation === ScreenType.VERTICAL;

  //  console.log('contentView_____', { contentView });

  // const clearData = useCallback(() => {
  // dispatch(contentPageActions.setIsEditWebContent(false));
  // dispatch(
  //   contentPageActions.setAddWebContentData({
  //     url: '',
  //     webSiteName: '',
  //     isLoadableInIFrame: false,
  //     isTriedToLoadWebsite: false,
  //     orintation: ScreenType.VERTICAL,
  //   })
  // );
  // }, [dispatch]);

  // useEffect(() => {
  //   return () => {
  //     if (isEditWebContent) clearData();
  //   };
  // }, [isEditWebContent, clearData]);

  useEffect(() => {
    if (!refreshTime) return;
    if (refreshTime.time === 0) return;
    const interval = setInterval(() => {
      //  console.log('Refreshing');
      refresher();
    }, refreshTime.time * 1000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(interval);
    };
  }, [refreshTime, refresher]);

  const switchOrientation = useCallback(() => {
    if (selectedOrientation === ScreenType.HORIZONTAL) {
      setOreintation(ScreenType.VERTICAL);
      setSelectedOrientation(ScreenType.VERTICAL);

      dispatch(contentPageActions.setisTriedToLoadWebsiteTwo(false));
    }
    if (selectedOrientation === ScreenType.VERTICAL) {
      setOreintation(ScreenType.HORIZONTAL);
      setSelectedOrientation(ScreenType.HORIZONTAL);
      dispatch(contentPageActions.setisTriedToLoadWebsiteTwo(false));
    }
  }, [selectedOrientation, setOreintation]);

  useEffect(() => {
    //  console.log('urladdWebContentData', addWebContentData?.url);
  }, [addWebContentData]);

  const isValidUrl = useCallback(
    async (urll) => {
      if (!urll || urll === '') {
        toaster.error('Please enter URL');
        return false;
      }
      try {
        // const isValid = new URL(urll);
        //  console.log(isValid);
      } catch (error) {
        toaster.error('Please enter a valid URL');
        return false;
      }
      const response = await validateUrl(urll);
      if (response) {
        let isLoadableInIFrame = false;
        let data;
        if (isEditWebContent) {
          data = { url: contentView?.url, webSiteName: contentView?.name };
        } else {
          data = addWebContentData || {
            url: '',
            webSiteName: '',
          };
        }
        if (response.valid) {
          isLoadableInIFrame = true;
        } else {
          isLoadableInIFrame = false;
        }
        dispatch(contentPageActions.setisTriedToLoadWebsiteTwo(true));
        dispatch(
          contentPageActions.setAddWebContentData({
            ...data,
            isLoadableInIFrame,
            isTriedToLoadWebsite: true,
            orintation: selectedOrientation,
          })
        );
      }
      return true;
    },
    [addWebContentData, dispatch, selectedOrientation]
  );

  useEffect(() => {
    if (!isEditWebContent) return;
    if (!contentView) return;
    if (!isValidUrl(contentView.url)) return;
    //  console.log('contentViewpage', contentView);
    const contentOrientation =
      contentView.orientation === 'VERTICAL'
        ? ScreenType.VERTICAL
        : ScreenType.HORIZONTAL;
    setSelectedOrientation(contentOrientation);
    setOreintation(contentOrientation);

    dispatch(
      contentPageActions.setAddWebContentData({
        url: contentView.url,
        webSiteName: contentView.name,
        isLoadableInIFrame: false,
        isTriedToLoadWebsite: false,
        orintation: contentOrientation,
      })
    );
  }, [isEditWebContent, contentView, dispatch]);

  const onChange = useCallback(
    (event) => {
      const data = addWebContentData || {
        url: '',
        webSiteName: '',
        isLoadableInIFrame: false,
        isTriedToLoadWebsite: false,
      };

      const isModifedUrl = event.target.name === 'url';
      if (data) {
        data[event.target.name] = event.target.value;
        dispatch(
          contentPageActions.setAddWebContentData({
            ...data,
            isLoadableInIFrame: isModifedUrl ? false : data.isLoadableInIFrame,
            isTriedToLoadWebsite: isModifedUrl
              ? false
              : data.isTriedToLoadWebsite,
            orintation: selectedOrientation,
          })
        );
      }
      if (isModifedUrl) openWebSite(false);
    },
    [addWebContentData, dispatch, openWebSite, selectedOrientation]
  );

  const onClickOpenWebite = useCallback(async () => {
    const isValid = await isValidUrl(url);
    if (!isValid) return;
    openWebSite(true);
  }, [url, openWebSite, isValidUrl]);

  useEffect(() => {
    const input = document.getElementById('addWebUrlInput');
    if (input) {
      input.focus();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      const input: any = document.getElementById('addWebUrlInput');
      if (input) {
        if (input !== document.activeElement) return;
        if (event.key === ENTER_KEY) {
          if (!isValidUrl(input.value)) return;
          openWebSite(true);
        }
      }
    });
  }, [url, openWebSite, isValidUrl]);

  return (
    <div className={`${styles.addWebContentSidebar} ${className}`}>
      <div className={styles.sidebarHeader}>Settings</div>
      <div className={styles.sidebarMainSection}>
        <div className={styles.primaryDetailsContainer}>
          <p className={styles.urlLabel}>URL</p>
          <input
            className={styles.urlInput}
            id="addWebUrlInput"
            name="url"
            placeholder="Enter website URL"
            type="text"
            value={url}
            onChange={onChange}
          />
          <Button
            className={styles.openWebsiteBtn}
            btnName="Load website"
            variant={ButtonVariants.SmallStd}
            onClick={onClickOpenWebite}
          />

          <p className={styles.urlLabel}>Give the website a name</p>
          <input
            className={styles.urlInput}
            name="webSiteName"
            placeholder="Name"
            type="text"
            value={webSiteName}
            onChange={onChange}
          />
        </div>
        <hr />
        <div className={styles.orientationHeading}>
          <img className={styles.orintationIcon} src={screenRotation} alt="" />
          <p className={styles.orintation}>Screen orientation</p>
        </div>
        <OrientationAndSize
          activeScreenTpe={selectedOrientation}
          onClickOrientation={switchOrientation}
        />
        <div className={styles.sizeAndScreenType}>
          <p className={styles.sectionHeading}>Size</p>

          <Popover
            popoverClassName={styles.refreshPopover}
            popoverComponent={
              <div className={styles.options}>
                {SCREEN_SIZES.map((i) => (
                  <p
                    className={styles.option}
                    onClick={() => setScreenSize(i.value)}
                  >
                    {i.option}
                  </p>
                ))}
              </div>
            }
          >
            <div className={styles.dropdownContainer}>
              <p className={styles.choosedOption}>{screenSize}</p>
              <img
                className={styles.dropdownIcon}
                src={caretFilledSmallDown}
                alt=""
              />
            </div>
          </Popover>

          <div className={styles.resolutionContainer}>
            <div className={styles.widthContainer}>
              <p>W</p>
              <input
                className={styles.resolutionInput}
                placeholder=""
                type="text"
                value="1920"
              />
            </div>
            <div className={styles.heightContainer}>
              <p>H</p>
              <input
                className={styles.resolutionInput}
                placeholder=""
                type="text"
                value="1080"
              />
            </div>
            <div className={styles.deviceTypes} onClick={switchOrientation}>
              <span
                className={`${styles.activeIndicator} ${
                  isLandscape && styles.landscape
                } ${isPortrait && styles.portrait}`}
              />
              <Screen
                className={`${styles.screen} ${isLandscape && styles.active}`}
              />
              <PortraitScren
                className={`${styles.portrait} ${isPortrait && styles.active}`}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className={styles.refreshContainer}>
          <p className={styles.refreshHeading}>Automatic Refresh</p>
          <Popover
            popoverClassName={styles.refreshPopover}
            popoverComponent={
              <div className={styles.options}>
                {refreshIntervals.map((i) => (
                  <p
                    className={styles.option}
                    onClick={() => setRefreshTime(i)}
                  >
                    {i.option}
                  </p>
                ))}
              </div>
            }
          >
            <div className={styles.dropdownContainer}>
              <p className={styles.choosedOption}>
                {(refreshTime && refreshTime.option) || 'Do not refresh'}
              </p>
              <img
                className={styles.dropdownIcon}
                src={caretFilledSmallDown}
                alt=""
              />
            </div>
          </Popover>
        </div>
        <hr />
      </div>
    </div>
  );
}

Sidebar.defaultProps = {
  className: '',
};

export default Sidebar;

import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { iRoleData, iState } from 'types';
import { ROLES, ROUTES } from 'constants/index';
import { useStore } from 'store';
import { userActions } from 'store/actions';
import { getUserRoles } from 'apis';
import styles from './rolesTable.module.scss';

interface iProps {
  className?: string;
}

function RolesTable(props: iProps): JSX.Element {
  const { className } = props;
  const [roles, setRoles] = useState<Array<iRoleData>>();
  const [state, dispatch]: [iState, any] = useStore();
  const { canFetchRoles } = state.user || {};
  const { id: userId } = state.user.loggedInData || {};
  const navigate = useNavigate();

  const createRole = useCallback(async () => {
    if (!userId) return;
    const response = await getUserRoles(userId);
    if (response) {
      setRoles(response);
      dispatch(userActions.setCanFetchRoles(false));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    createRole();
  }, [createRole]);

  useEffect(() => {
    if (canFetchRoles) createRole();
  }, [canFetchRoles, createRole]);

  return (
    <div className={`${styles.rolesTable} ${className}`}>
      <div className={styles.tableHead}>
        <p className={styles.roleName}>Name</p>
        <p className={styles.lastUpdated}>Last Updated</p>
      </div>
      <div className={styles.tableBody}>
        {roles &&
          roles.map((role) => {
            const lastUpdatedAt =
              (role.updated_at && new Date(role.updated_at)) || '';
            return (
              <div
                className={styles.row}
                onClick={() => {
                  if (Number(role.id) !== ROLES.super_admin) {
                    navigate(ROUTES.rolesDetails, {
                      state: { id: role.id, roleName: role.role_name },
                    });
                  }
                }}
              >
                <p className={styles.nameValue}>
                  {role?.role_name?.split('_').join(' ')}
                </p>
                <p className={styles.lastUpdatedValue}>
                  {lastUpdatedAt &&
                    `${lastUpdatedAt.getDate()}.${
                      lastUpdatedAt.getMonth() + 1
                    }.${lastUpdatedAt.getFullYear()} ${lastUpdatedAt.getHours()}.${lastUpdatedAt.getMinutes()} `}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
}

RolesTable.defaultProps = {
  className: '',
};

export default RolesTable;

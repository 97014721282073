import LabelAndInput from 'components/label-and-input/labelAndInput';
import Modal from 'components/modal/modal';
import {
  contentIcon2,
  templatesGreyIcon,
  videoIcon,
  warningIcon,
  WebIcon,
} from 'constants/index';
import React, { useCallback, useState } from 'react';
import { useStore } from 'store';
import { layoutActions } from 'store/actions';
import { iState } from 'types';
import styles from './deleteModal.module.scss';
import { DeleteModalVariants, Variants } from './variants';

type iProps = {
  isOpen: boolean;
  isUsedSomewhere?: boolean;
  confirmationMsg?: string;
  usageNotification?: string;
  name: string;
  message?: string;
  isLoading?: boolean;
  contentType?: string;
  variant: DeleteModalVariants;
  deleteHandler: () => void;
  onClose?: () => void;
  displayInput?: boolean;
};

const CONTENT_ICONS = {
  image: contentIcon2,
  video: videoIcon,
  website: WebIcon,
  template: templatesGreyIcon,
};

function DeleteModal(props: iProps): JSX.Element {
  const {
    isOpen,
    name,
    message,
    isLoading,
    variant,
    contentType,
    isUsedSomewhere,
    confirmationMsg,
    usageNotification,
    deleteHandler,
    onClose,
    displayInput = true,
  } = props;

  const [, dispatch]: [iState, any] = useStore();
  const [deletingItemName, setDeletingItemName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  // const { isShowDeleteModal = false } = state.layout || {};

  //  console.log('__contentToDelete', contentType);

  const { heading, description, icon, deletingTo } = Variants[variant] || {};

  const onChange = useCallback((event): void => {
    event.stopPropagation();
    setDeletingItemName(event.target.value);
  }, []);

  const verifyAndDeleteHandler = useCallback((): void => {
    if (!deletingItemName) {
      setErrorMessage(`Please enter ${deletingTo} name`);
      return;
    }

    if (
      deletingItemName.replace(/\s+/g, ' ') !== name.replace(/\s+/g, ' ')
      // displayInput
    ) {
      setErrorMessage(`Please enter a valid ${deletingTo} name`);
      return;
    }
    setErrorMessage('');
    deleteHandler();
    setDeletingItemName('');
  }, [deleteHandler, deletingItemName, deletingTo, name]);

  const deleteWithoutVerify = useCallback((): void => {
    deleteHandler();
  }, [deleteHandler, deletingItemName, deletingTo, name]);

  const closeModalHandler = useCallback(() => {
    dispatch(layoutActions.toggleModal());
    setErrorMessage('');
    setDeletingItemName('');
    if (onClose) onClose();
  }, [dispatch, onClose]);

  //  console.log('usageNotification_____', {
  //   confirmationMsg,
  //   usageNotification,
  //   isUsedSomewhere,
  //   isShowDeleteModal,
  // });

  // const decideDeleteInputBtn = (): any => {
  //   if(displayInput){
  //     if(deletingItemName === ""){

  //     }
  //   }else{

  //   }
  // };

  return (
    <Modal
      isOpen={isOpen}
      headingIcon={contentType ? CONTENT_ICONS[contentType] : icon}
      heading={`${heading}  "${name || deletingTo}"`}
      btnName="Delete"
      isLoading={isLoading}
      onClose={closeModalHandler}
      submit={
        !isUsedSomewhere && displayInput
          ? verifyAndDeleteHandler
          : deleteWithoutVerify
      }
      isBtnDisabled={
        !isUsedSomewhere && displayInput ? deletingItemName === '' : false
      }
      deleteModal
    >
      <div className={styles.deleteModal}>
        {message !== '' && <p className={styles.delayMessage}>{message}</p>}
        {message === '' && (
          <>
            {confirmationMsg && (
              <p className={styles.confirmationMsg}>{confirmationMsg}</p>
            )}

            {usageNotification && (
              <div className={styles.deleteWarn}>
                <img className={styles.warnIcon} src={warningIcon} alt="" />
                <p className={styles.message}>{usageNotification}</p>
              </div>
            )}

            {(!usageNotification || !confirmationMsg) && (
              <>
                <p className={styles.labelParagraph}>
                  {contentType
                    ? description.replace('content?', `${contentType}?`)
                    : description}
                </p>
                {displayInput && (
                  <div className={styles.deleteModal}>
                    <div className={styles.labelAndInput}>
                      <LabelAndInput
                        className={styles.input}
                        name="name"
                        label={`${deletingTo} Name*`}
                        placeholder="Enter Name"
                        value={deletingItemName}
                        onChange={onChange}
                        errorMessage={errorMessage}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Modal>
  );
}

DeleteModal.defaultProps = {
  message: '',
  isLoading: false,
  contentType: '',
  isUsedSomewhere: false,
  usageNotification: '',
  confirmationMsg: '',
  onClose: () => {},
  displayInput: true,
};

export { DeleteModalVariants };
export default DeleteModal;

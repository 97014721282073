import React, { useCallback, useState } from 'react';
import { setUp2FA } from 'apis';
import { iState } from 'types';
import { toaster } from 'helpers';
import { TwoFAType } from 'enums';
import { useStore } from 'store';
import styles from './authenticateVerifyCode.module.scss';

interface iProps {
  className?: string;
  setCodeToVerify: (code: string) => void;
}

function AuthenticateVerifyCode(props: iProps): JSX.Element {
  const { className, setCodeToVerify } = props;
  const [state]: [iState] = useStore();
  const [isReSending, setIsReSending] = useState<boolean>(false);
  const { id = '' } = state.user.loggedInData || {};

  const resendCode = useCallback(async () => {
    const response = await setUp2FA(
      {
        userId: id,
        twoFAType: TwoFAType.EMAIL,
      },
      setIsReSending
    );
    if (response) {
      toaster.success('Send code to mail again');
    }
  }, [id]);

  return (
    <div className={`${styles.authenticateVerifyCode} ${className}`}>
      <p className={styles.verifyCodeHeading}>We have sent you an email</p>
      <p className={styles.verifyCodeDescription}>
        Check your email for the code, make sure to check your spam folder if
        you cant find it.
      </p>

      <p className={styles.verificationCodeToEmail}>Verification Code</p>
      <input
        className={styles.verifyCodeInput}
        type="text"
        placeholder=""
        onChange={(event) => setCodeToVerify(event.target.value)}
      />

      <p className={styles.sendNewCode} onClick={resendCode}>
        {isReSending ? 'Re-Sending...' : 'Send new code?'}
      </p>
    </div>
  );
}

AuthenticateVerifyCode.defaultProps = {
  className: '',
};

export default AuthenticateVerifyCode;

import React from 'react';
import { useStore } from 'store';
import { iState } from 'types';
import { EventTemplateType } from 'enums';
import Header from './components/header/header';
// import LeftSideBar from './components/left-sidebar/leftSidebar';
import RightSideBar from './components/right-sidebar/rightSidebar';
import MeetingRoomSection from './components/is-meetingRoom-section/meetingRoomSection';
import MeetingOverViewSection from './components/is-meetingOverview-section/meetingOverviewSection';
import MeetingPortraitSection from './components/is_meeting_Portrait/meetingPortraitSection';
import MeetingMessageSection from './components/is_meetingMessageOverview-section/meetingMessageOverviewSection';
import styles from './calenderEvents.module.scss';

function CalenderEvents(): JSX.Element {
  const [state]: [iState] = useStore();
  const { templateToView = null } = state.layout || {};

  const isMeetingRoom = templateToView === EventTemplateType.meetingRoom;
  const isMeetingOverviewFull =
    templateToView === EventTemplateType.meetingOverviewFull;
  const isMeetingOverviewWithMessage =
    templateToView === EventTemplateType.meetingOverviewMessage;
  const isMeetingOverviewPortrait =
    templateToView === EventTemplateType.meetingOverviewPotrait;

  return (
    <div className={styles.calenderEvents}>
      <Header />
      <div
        className={`${styles.mainSection} ${
          isMeetingRoom && styles.hideScroll
        }`}
      >
        {/* <LeftSideBar /> */}
        {isMeetingRoom && <MeetingRoomSection />}
        {isMeetingOverviewFull && <MeetingOverViewSection />}
        {isMeetingOverviewWithMessage && <MeetingMessageSection />}
        {isMeetingOverviewPortrait && <MeetingPortraitSection />}
        <RightSideBar />
      </div>
    </div>
  );
}

CalenderEvents.defaultProps = {
  className: '',
};

export default CalenderEvents;

import Popover from 'components/popover/popover';
import { caretLeft } from 'constants/index';
import { UserSettingsView } from 'enums';
import React from 'react';
import { useStore } from 'store';
import { iState } from 'types';
import { navigatorVariant } from 'modules/user/constants';
import Navigators from '../navigators/navigators';
import styles from './viewSwitchDropdown.module.scss';

interface iProps {
  className?: string;
}

function ViewSwitchDropdown(props: iProps): JSX.Element {
  const { className } = props;
  const [state]: [iState] = useStore();
  const { userSettings } = state.user || {};
  const { activeView } = userSettings || {};

  const selectedOption =
    (activeView === UserSettingsView.General && 'General') ||
    (activeView === UserSettingsView.TeamMembers && 'Team Members') ||
    (activeView === UserSettingsView.Integrations && 'Integrations') ||
    (activeView === UserSettingsView.Profile && 'Profile') ||
    (activeView === UserSettingsView.AddTeamMembers && 'Add Team Members') ||
    (UserSettingsView.PasswordAndSecurity && 'Password & Security');

  return (
    <Popover
      className={styles.popoverWrapper}
      popoverClassName={styles.popover}
      popoverComponent={
        <Navigators variant={navigatorVariant.mobileDropDown} />
      }
    >
      <div className={`${styles.selectedOption} ${className}`}>
        <p>{selectedOption}</p>
        <img className={styles.caretLeft} src={caretLeft} alt="" />
      </div>
    </Popover>
  );
}

ViewSwitchDropdown.defaultProps = {
  className: '',
};

export default ViewSwitchDropdown;

import React, { useState } from 'react';
import Card from '../../card/card';
// import Language from '../../language/language';
// import Language from '../../language/language';
import PersonalInformation from '../../personal-information/personalInformation';
import ProfilePicture from '../../profile-picture/profilePicture';
import SectionHeading from '../../section-heading/sectionHeading';
// import Themes from '../../themes/themes';
import styles from './profile.module.scss';

interface iProps {
  className?: string;
}

function Profile(props: iProps): JSX.Element {
  const { className } = props;
  const [enableBtn, setEnableBtn] = useState(true);
  return (
    <div className={`${styles.mainContent}  ${className}`}>
      <Card className="mb4">
        <div className={styles.container}>
          <SectionHeading heading="Personal information" />
          <div>
            <PersonalInformation setEnableBtn={setEnableBtn} />
            <ProfilePicture enableBtn={enableBtn} setEnableBtn={setEnableBtn} />
          </div>
        </div>
      </Card>
      {/* <Card className="mb4">
        <div className={styles.container}> */}
      {/*  As per the instruction commenting out themse and langauge components for time being  */}
      {/* <SectionHeading heading="Theme" />
          <div>
            <Themes />
          </div> */}
      {/* <SectionHeading heading="Language" /> */}
      {/* <div> */}
      {/* <Language /> */}
      {/* </div>
        </div>
      </Card> */}
    </div>
  );
}

Profile.defaultProps = {
  className: '',
};

export default Profile;

import Button, { ButtonVariants } from 'components/button/button';
import Filter from 'components/filter-content-popover/filter';
import Search from 'components/search/search';
import { closeIcon, cloudUpload2Icon, greyAddIcon } from 'constants/index';
import { ModalViews } from 'enums';
import React, { useCallback, useEffect } from 'react';
import { useStore } from 'store';
import { layoutActions } from 'store/actions';
import styles from './modal.module.scss';
import { ModalVariants, Variants } from './variants';

interface Props {
  className?: string;
  isOpen: boolean;
  heading: string | JSX.Element;
  headingIcon?: string;
  btnName?: string;
  btnIcon?: string;
  iconPosition?: 'left' | 'right';
  isBtnDisabled?: boolean;
  isLoading?: boolean;
  isShowFooter?: boolean;
  children: JSX.Element;
  submitBtn?: JSX.Element;
  variant?: ModalVariants;
  deleteModal?: boolean;
  checked?: any;
  setChecked?: any;
  clientModal?: any;
  onClose: (type: boolean) => void;
  submit: () => void;
  onSearch?: (key: string) => void;
  searchKeyword?: string;
}

function Modal(props: Props): JSX.Element {
  const {
    className,
    heading,
    headingIcon,
    btnName,
    btnIcon,
    iconPosition,
    isOpen = false,
    isShowFooter = true,
    onClose,
    submit,
    onSearch,
    children,
    isBtnDisabled,
    submitBtn,
    isLoading,
    deleteModal,
    variant = ModalVariants.Standard,
    checked,
    setChecked,
    clientModal,
    searchKeyword,
  } = props;

  const { showSearchBar, showFilter, showUploadBtn, showAddBtn } =
    Variants[variant];

  const [, dispatch] = useStore();

  const closeModal = useCallback(() => {
    onClose(false);
  }, [onClose]);

  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden';
    if (!isOpen) document.body.style.overflow = 'unset';
  }, [isOpen]);

  if (!isOpen) return <div />;
  return (
    <div
      className={`
        ${styles.modal}
        ${isOpen ? styles.show : styles.hide} 
        
      `}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          submit();
        }
      }}
    >
      <div className={styles.backdrop} onClick={closeModal} />
      <div
        className={`${styles.body}         
        ${clientModal && styles.clientModal} ${className} ${styles[variant]}`}
      >
        <header className={styles.header}>
          <div className={styles.container}>
            {headingIcon && (
              <img
                className={`${styles.icon} ${
                  heading === 'Office 365' && styles.o365Icon
                }`}
                src={headingIcon}
                alt=""
              />
            )}
            <p className={styles.heading}>{heading}</p>
          </div>
          {showSearchBar && (
            <Search
              className={`${styles.searchBar} ${styles[variant]}`}
              isOpenState
              placeholder="Search your content"
              onChange={onSearch}
              searchKeyword={searchKeyword}
            />
          )}
          {showFilter && (
            <Filter checked={checked} setChecked={setChecked} />
            // <Button
            //   className={`${styles.filter} ${styles[variant]}`}
            //   variant={ButtonVariants.MediumTransp}
            //   icon={filterIcon}
            //   btnName="Filter"
            //   onClick={<() => {}>}
            // />
          )}
          {showUploadBtn && (
            <Button
              className={`${styles.uploadBtn} ${styles[variant]}`}
              variant={ButtonVariants.MediumTransp}
              icon={cloudUpload2Icon}
              onClick={() => {
                dispatch(
                  layoutActions.toggleModal(ModalViews.isShowAddContent)
                );
              }}
            />
          )}
          {showAddBtn && (
            <img
              className={styles.responsiveAddBtn}
              src={greyAddIcon}
              alt=""
              onClick={() => {
                dispatch(
                  layoutActions.toggleModal(ModalViews.isShowAddContent)
                );
              }}
            />
          )}

          <img
            className={styles.closeIcon}
            src={closeIcon}
            alt=""
            onClick={closeModal}
          />
        </header>
        <section
          className={`${styles.contentArea} ${styles[variant]} ${
            !isShowFooter && styles.withoutFooter
          }`}
        >
          {children}
        </section>
        {isShowFooter && (
          <footer className={styles.footer}>
            <Button
              className={styles.btns}
              variant={ButtonVariants.SmallTransp}
              btnName="Cancel"
              onClick={closeModal}
              deleteModal={deleteModal}
            />

            {submitBtn || (
              <Button
                className={`${styles.btns}`}
                variant={ButtonVariants.SmallStd}
                btnName={btnName}
                icon={btnIcon}
                isDisabled={isBtnDisabled}
                iconPosition={iconPosition}
                onClick={submit}
                isLoading={isLoading}
                deleteModal={deleteModal}
              />
            )}
          </footer>
        )}
      </div>
    </div>
  );
}

Modal.defaultProps = {
  className: '',
  headingIcon: '',
  btnIcon: '',
  iconPosition: 'left',
  isBtnDisabled: false,
  isShowFooter: true,
  submitBtn: '',
  isLoading: false,
  variant: ModalVariants.Standard,
  deleteModal: false,
  onSearch: () => {},
  checked: {
    showAll: false,
    image: false,
    videos: false,
    webPages: false,
  },
  setChecked: () => {},
  clientModal: false,
  searchKeyword: '',
  btnName: '',
};
export { ModalVariants };
export default Modal;

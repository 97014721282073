import { ROUTES } from 'constants/index';
import { UserRoles, UserSettingsView } from 'enums';
import { toaster } from 'helpers';
import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { userActions } from 'store/actions';
import { iState } from 'types';
import {
  SETTING_DESKTOP_NAV,
  SETTING_MOBILE_NAV,
  navigatorVariant,
} from '../../constants';
import styles from './navigators.module.scss';

interface iProps {
  className?: string;
  variant: navigatorVariant;
}

function Navigators(props: iProps): JSX.Element {
  const { className, variant } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { userSettings } = state.user || {};
  const { activeView } = userSettings || {};
  const { role } = state.user || {};
  const { companyData } = state.homePage || {};
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (activeView === UserSettingsView.TeamMembers) {
      document.title = 'Team members';
      return;
    }
    if (activeView === UserSettingsView.General) {
      document.title = 'User Settings';
      return;
    }
    if (activeView === UserSettingsView.Integrations) {
      document.title = 'Integrations';
      return;
    }
    if (activeView === UserSettingsView.Profile) {
      document.title = 'Profile';
      return;
    }
    if (activeView === UserSettingsView.PasswordAndSecurity) {
      document.title = 'Password and security';
      return;
    }
    if (activeView === UserSettingsView.AddTeamMembers) {
      document.title = 'Add Team Members';
      return;
    }
    document.title = 'Screenz';
  }, [activeView]);

  useEffect(() => {
    if (pathname === ROUTES.userSettingsTeamMembers) {
      dispatch(userActions.changeView(UserSettingsView.TeamMembers));
    }
    if (pathname === ROUTES.userSettings) {
      dispatch(userActions.changeView(UserSettingsView.General));
    }
    if (pathname === ROUTES.userSettingsIntegrations) {
      dispatch(userActions.changeView(UserSettingsView.Integrations));
    }
    if (pathname === ROUTES.userSettingsProfile) {
      dispatch(userActions.changeView(UserSettingsView.Profile));
    }
    if (pathname === ROUTES.userSettingsPassword) {
      dispatch(userActions.changeView(UserSettingsView.PasswordAndSecurity));
    }
    // if (pathname === ROUTES.addTeamMates) {
    //   dispatch(userActions.changeView(UserSettingsView.AddTeamMembers));
    // }
  }, [pathname]);

  const changeView = useCallback(
    (view) => {
      //  console.log('changeView', view);
      if (
        (view === UserSettingsView.General && !companyData) ||
        (view === UserSettingsView.TeamMembers && !companyData) ||
        (view === UserSettingsView.AddTeamMembers && !companyData) ||
        (view === UserSettingsView.Integrations && !companyData)
      ) {
        toaster.error('Please choose a client to proceed');
        return;
      }
      // dispatch(userActions.changeView(view));
      if (view === UserSettingsView.General) {
        navigate(ROUTES.userSettings);
        return;
      }
      if (view === UserSettingsView.TeamMembers) {
        navigate(ROUTES.userSettingsTeamMembers);
        return;
      }
      if (view === UserSettingsView.Integrations) {
        navigate(ROUTES.userSettingsIntegrations);
        return;
      }
      if (view === UserSettingsView.Profile) {
        navigate(ROUTES.userSettingsProfile);
        return;
      }
      if (view === UserSettingsView.PasswordAndSecurity) {
        navigate(ROUTES.userSettingsPassword);
        return;
      }
      if (view === UserSettingsView.AddTeamMembers) {
        navigate(ROUTES.addTeamMates);
        return;
      }
      navigate(ROUTES.userSettings);
    },
    [dispatch, companyData]
  );

  const settingsDropdown =
    variant === navigatorVariant.desktopDropdown
      ? SETTING_DESKTOP_NAV
      : SETTING_MOBILE_NAV;

  return (
    <>
      {settingsDropdown
        .filter((item) => {
          if (role === UserRoles.Client) {
            return item.heading !== 'Your Company';
          }
          return true;
        })
        .map((item) => (
          <div className={`${styles.container} ${className}`} key={item.id}>
            <div className={styles.heading}>
              <img src={item.icon} alt="" />
              <span>{item.heading}</span>
            </div>
            {item.options.map((c) => (
              <ul className={styles.optionsList} key={c.id}>
                <li
                  className={`${styles.list} ${
                    activeView === c.view && styles.active
                  }`}
                  onClick={() => changeView(c.view)}
                >
                  {c.option}
                </li>
              </ul>
            ))}
          </div>
        ))}
    </>
  );
}

Navigators.defaultProps = {
  className: '',
};

export default Navigators;

import {
  getUserByCompanyId,
  resendInvitation,
  revokeInvitation,
  deleteUser,
} from 'apis';
import { commonActions, layoutActions } from 'store/actions';
import Button, { ButtonVariants } from 'components/button/button';
import DeleteModal, {
  DeleteModalVariants,
} from 'components/delete-modal/deleteModal';
import { toaster } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'store';
import { iState, iUserData } from 'types';
import InvitedMobileView from './components/all-team-members/invited-member-list/components/invited-mobile-view/invitedMobileView';
import Invited from './components/all-team-members/invited-member-list/Invited';
import TeamMembersMobileView from './components/all-team-members/team-member-list/components/team-members-mobile-view/teamMembersMobileView';
import TeamMemberList from './components/all-team-members/team-member-list/TeamMemberList';
import styles from './teamMembers.module.scss';

function TeamMember(): JSX.Element {
  const [state, dispatch]: [iState, any] = useStore();
  const [toggleState, setToggleState] = useState(1);
  const [toggleMobState, setToggleMobState] = useState(3);
  const [isReSendingInvite, setIsReSendingInvite] = useState<boolean>(false);
  const [isRevokingInvite, setIsRevokingInvite] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>();
  const [canRefetchInvited, setCanRefetchInvited] = useState<boolean>(false);
  const [resendingInviteTo, setResendingInviteTo] = useState<string>('');
  const [revokingInviteTo, setRevokingInviteTo] = useState<string>('');
  const [teamMembers, setTeamMembers] = useState<Array<iUserData>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fetchAgainTeammates, setFetchAgainTeammates] =
    useState<boolean>(false);
  const { isShowDeleteTeammate } = state.layout || {};

  const { removeTeammateData, loggedInData } = state?.user || {};

  //  console.log('fetchAgainTeammates____', fetchAgainTeammates);

  const { companyData } = state.homePage || {};
  const { windowWidth } = state.layout || {};
  const { id: companyId } = companyData || {};

  const toggleTab = useCallback((index) => {
    setToggleState(index);
  }, []);

  const toggleMobTab = useCallback((index) => {
    setToggleMobState(index);
  }, []);

  const resendInvitationHandler = useCallback(async (data) => {
    setResendingInviteTo(data.id);
    const response = await resendInvitation(
      { id: data.companyUserId },
      setIsReSendingInvite
    );
    if (response) {
      toaster.success('Successfully re-sent invite');
      setResendingInviteTo('');
    }
  }, []);

  const revokeInvitationHandler = useCallback(async (data) => {
    setRevokingInviteTo(data.id);
    const response = await revokeInvitation(
      { id: data.companyUserId },
      setIsRevokingInvite
    );
    if (response) {
      toaster.success('Successfully revoked invite');
      setRevokingInviteTo('');
      setCanRefetchInvited(true);
    }
  }, []);

  const getTeammemebers = useCallback(async () => {
    if (!companyId) return;
    const response = await getUserByCompanyId(
      { company: companyId },
      setIsLoading
    );
    if (response) {
      //  console.log({ response });
      const parsed = response
        .filter((i) => i.invitedStatus === 'ACCEPTED')
        .map((i) => ({
          ...i,
          user: {
            name: i.first_name,
            lastName: i.last_name,
            email: i.email,
            profilePic: i.profile_pic,
          },
        }));
      setTeamMembers(parsed);
      setFetchAgainTeammates(false);
    }
  }, [companyId]);

  const removeTeammateHandler = useCallback(async () => {
    const { companyUserId } = removeTeammateData || {};
    if (!companyUserId) return;

    const response = await deleteUser(companyUserId, setIsDeleting);
    if (response) {
      if (
        removeTeammateData?.id === loggedInData?.id &&
        removeTeammateData?.email === loggedInData?.email
      ) {
        dispatch(commonActions.reset());
      }
      dispatch(layoutActions.toggleModal());
      getTeammemebers();
    }
  }, [removeTeammateData, dispatch, loggedInData, getTeammemebers]);

  useEffect(() => {
    if (fetchAgainTeammates) getTeammemebers();
  }, [fetchAgainTeammates, getTeammemebers]);

  useEffect(() => {
    getTeammemebers();
  }, [getTeammemebers]);

  useEffect(() => {
    if (windowWidth <= 800 && !isLoading) getTeammemebers();
  }, [windowWidth]);

  //  console.log(resendInvitationHandler, revokeInvitationHandler);

  return (
    <>
      <div className={styles.teamMembersContainer}>
        <div className={styles.tabList}>
          <Button
            className={
              toggleState === 1 ? styles.activeTabs : styles.notActiveTabs
            }
            onClick={() => toggleTab(1)}
            btnName="Team Members"
            variant={ButtonVariants.SmallStd}
          />
          <Button
            className={
              toggleState === 2 ? styles.activeTabs : styles.notActiveTabs
            }
            onClick={() => toggleTab(2)}
            btnName="Invited"
            variant={ButtonVariants.SmallStd}
          />
        </div>
        <section className={styles.tabDetails}>
          {toggleState === 1 ? (
            <TeamMemberList
              teamMembers={teamMembers}
              setFetchAgainTeammates={setFetchAgainTeammates}
            />
          ) : (
            <Invited
              resendingInviteTo={resendingInviteTo}
              revokingInviteTo={revokingInviteTo}
              isResending={isReSendingInvite}
              isRevoking={isRevokingInvite}
              resend={resendInvitationHandler}
              revoke={revokeInvitationHandler}
              canRefetchInvited={canRefetchInvited}
              setCanRefetchInvited={setCanRefetchInvited}
            />
          )}
        </section>
      </div>
      <div className={styles.teamMembersMobContainer}>
        <div className={styles.mobTabList}>
          <Button
            className={
              toggleMobState === 3
                ? styles.activeMobTabs
                : styles.notActiveMobTabs
            }
            onClick={() => toggleMobTab(3)}
            btnName="Team Members"
            variant={ButtonVariants.SmallStd}
          />
          <Button
            className={
              toggleMobState === 4
                ? styles.activeMobTabs
                : styles.notActiveMobTabs
            }
            onClick={() => toggleMobTab(4)}
            btnName="Invited"
            variant={ButtonVariants.SmallStd}
          />
        </div>
        <section className={styles.tabMobDetails}>
          {toggleMobState === 3 ? (
            <TeamMembersMobileView
              className=""
              teamMembers={teamMembers}
              setFetchAgainTeammates={setFetchAgainTeammates}
            />
          ) : (
            <InvitedMobileView
              className=""
              resendingInviteTo={resendingInviteTo}
              revokingInviteTo={revokingInviteTo}
              isResending={isReSendingInvite}
              isRevoking={isRevokingInvite}
              resend={resendInvitationHandler}
              revoke={revokeInvitationHandler}
              canRefetchInvited={canRefetchInvited}
              setCanRefetchInvited={setCanRefetchInvited}
            />
          )}
        </section>
      </div>
      <DeleteModal
        isOpen={isShowDeleteTeammate}
        variant={DeleteModalVariants.TeamMember}
        isLoading={isDeleting}
        name={`${removeTeammateData?.first_name} ${
          removeTeammateData?.last_name ? removeTeammateData?.last_name : ''
        }`}
        deleteHandler={removeTeammateHandler}
      />
    </>
  );
}

export default TeamMember;

export enum PermissionFor {
  User = 'User',
  Role = 'Role',
}

export enum PermissionMutationType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export enum PermissionType {
  fullAccess = 'fullAccess',
  limitedAccess = 'limitedAccess',
}

export enum ClientPermissions {
  AllClients = 'allClients',
  SubsetOFClients = 'subsetOFClients',
  OnlyTheyAreAdded = 'onlyTheyAreAdded',
}

export enum ScreenPermissions {
  Disabled = 'disabled',
  AllScreens = 'allScreens',
  OnlyTheyAreAdded = 'onlyTheyAreAdded',
}

export enum ContentPermissions {
  Disabled = 'disabled',
  AllContents = 'allContents',
  OnlyTheyAreAdded = 'onlyTheyAreAdded',
}
export enum PlayListPermissions {
  Disabled = 'disabled',
  AllPlayList = 'allPlayList',
  OnlyTheyAreAdded = 'onlyTheyAreAdded',
}
export enum AccessType {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  READ = 'READ',
}

export enum TempEnum {
  Value = 'value',
}

export enum UserRoles {
  Client = 'Client',
  ClientInstance = 'ClientInstance',
  SuperAdmin = 'SuperAdmin',
  Owner = 'owner',
  Admin = 'admin',
  Reseller = 'Reseller',
}

export enum LayoutViews {
  Screens = 'Screens',
  Screen = 'Screen',
  Client = 'Client',
  ScreensDetails = 'ScreensDetails',
  SuperAdminClients = 'SuperAdminClients',
  SuperAdminResellers = 'SuperAdminResellers',
  ResellerClients = 'ResellerClients',
  Content = 'Content',
  ContentDetails = 'ContentDetails',
  Playlists = 'Playlists',
  PlaylistsDetails = 'PlaylistsDetails',
  Playlist = 'Playlist',
  PlaylistDetails = 'PlaylistDetails',
}

export enum ModalViews {
  isShowAddContent = 'isShowAddContent',
  isShowAddTemplate = 'isShowAddTemplate',
  isShowAddWebContent = 'isShowAddWebContent',
  isShowContentPreview = 'isShowContentPreview',
  isShowAddPlaylist = 'isShowAddPlaylist',
  isShowAddFolderForPlaylists = 'isShowAddFolderForPlaylists',
  isShowAddContentToPlaylist = 'isShowAddContentToPlaylist',
  isShowAddTeammate = 'isShowAddTeammate',
  isShowingClientInstance = 'isShowingClientInstance',
  isShowAddClient = 'isShowAddClient',
  isShowAddScreen = 'isShowAddScreen',
  isShowAddFolder = 'isShowAddFolder',
  isShowDeleteModal = 'isShowDeleteModal',
  isShowAssignContentOrPlaylist = 'isShowAssignContentOrPlaylist',
  isShowShareAccess = 'isShowShareAccess',
  isShowSetUp2FA = 'isShowSetUp2FA',
  isShowDisable2FA = 'isShowDisable2FA',
  isShowAddRole = 'isShowAddRole',
  isShowEditPlaylistResolution = 'isShowEditPlaylistResolution',
  isShowDeleteFolder = 'isShowDeleteFolder',
  isShowDeleteContent = 'isShowDeleteContent',
  isShowDeletePlaylist = 'isShowDeletePlaylist',
  isShowDeleteTeammate = 'isShowDeleteTeammate',
  isShowDeleteClient = 'isShowDeleteClient',
  isShowDeleteScreen = 'isShowDeleteScreen',
  isShowO365Modal = 'isShowO365Modal',
  isShowO365RoomsModal = 'isShowO365RoomsModal',
}

export enum SideDrawers {
  isShowContentPreview = 'isShowContentPreview',
  isShowPlaylistPreview = 'isShowPlaylistPreview',
  isShowTemplatePreview = 'isShowTemplatePreview',
}

export enum EmptyScreens {
  Screens = 'screens_empty_state',
  Content = 'content_empty_state',
  Playlists = 'playlists_empty_state',
  Clients = 'clients_empty_state',
}

export enum ScreenType {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum FolderType {
  SCREENZ = 'SCREENZ',
  CONTENT = 'CONTENT',
  PLAYLIST = 'PLAYLIST',
}

export enum RemoteActions {
  Reboot = 'reboot',
  Brightness = 'brightness',
  Volume = 'volume',
  RestartApp = 'restartApp',
  DisplayOn = 'displayOn',
  DisplayOff = 'displayOff',
}

export enum DevicePowerAction {
  APP_RESTART = 'APP_RESTART',
  SYSTEM_REBOOT = 'SYSTEM_REBOOT',
  DISPLAY_POWER_ON = 'DISPLAY_POWER_ON',
  DISPLAY_POWER_OFF = 'DISPLAY_POWER_OFF',
}

export enum TwoFAType {
  DISABLED = 'DISABLED',
  EMAIL = 'EMAIL',
  AUTHAPP = 'AUTHAPP',
}

export enum Orientations {
  Landscap = 'Landscap',
  Portrait = 'Portrait',
  Custom = 'Custom',
}

export enum Roles {
  Super_admin = 'super_admin',
  Owner = 'owner',
  Admin = 'Team member',
  User = 'user',
  Reseller = 'reseller',
}

export enum DeleteStatus {
  UsedSomewhere = 'UsedSomewhere',
  CanDelete = 'CanDelete',
  Deleted = 'Deleted',
}

export enum CalenderSettingsView {
  ContentSetting = 'ContentSetting',
  ContentArea = 'ContentArea',
  TemplateSetting = 'TemplateSetting',
  LargeArea = 'LargeArea',
}

export enum CARD_NAME {
  One = 'One',
  Two = 'Two',
  Three = 'Three',
  Four = 'Four',
  Five = 'Five',
  Six = 'Six',
  Seven = 'Seven',
  Eight = 'Eight',
  Nine = 'Nine',
  Ten = 'Ten',
  Eleven = 'Eleven',
  Twelve = 'Twelve',
}

export enum EventContentType {
  Empty = 'Empty',
  Calender = 'Calender',
  Clock = 'Clock',
  Image = 'Image',
  Text = 'Text',
}

export enum LanguageType {
  English = 'English',
  Norwegian = 'Norwegian',
}

export enum ClockContentType {
  RightAligned = 'Right',
  LeftAligned = 'Left',
  Empty = 'Empty',
}

export enum LeftAreaContentType {
  Playlist = 'Playlist',
  Image = 'Image',
  Text = 'Text',
}

export enum AddOrUpdateTemplateMutationType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export enum EventTemplateType {
  meetingRoom = 'meetingRoom',
  meetingOverviewFull = 'meetingOverviewlandscapeFull',
  meetingOverviewMessage = 'meetingOverviewlandscape',
  meetingOverviewPotrait = 'meetingOverviewPotrait',
}

export enum EventTemplateContentType {
  Calender = 'Calender',
  Clock = 'Clock',
  Image = 'Image',
}

export enum itemType {
  FOLDER = 'FOLDER',
  SCREEN = 'SCREEN',
  PLAYLIST = 'PLAYLIST',
}
export enum accessType {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  READ = 'READ',
}

import Button, { ButtonVariants } from 'components/button/button';
import LabelAndInput from 'components/label-and-input/labelAndInput';
// import Screen from 'components/svg/screenz';
import {
  delete2Icon,
  duplicateIcon,
  playlistsIcon,
  editIcon,
  caretLeft,
} from 'constants/index';
import Screen from 'components/svg/screenz';
import PortraitScren from 'components/svg/portraitScren';
import React, { useCallback, useState } from 'react';
import { useStore } from 'store';
import { itemType, ModalViews, ScreenType } from 'enums';
import ShareAccess from 'components/share-access/shareAccess';
import { playlistPageActions, layoutActions } from 'store/actions';
import { iState } from 'types';
import { calculateContentRuntime, toaster } from 'helpers';
import { addPlaylist, getAllPlaylists } from 'apis';
import EditResolutionModal from './edit-resolution-modal/editResolutionModal';
import ColorPicker from '../color-picker/colorPicker';
import styles from './details.module.scss';

interface iProps {
  className?: string;
  variant?: 'desktop' | 'mobile';
  setLatePlaylistName?: any;
}

function Index(props: iProps): JSX.Element {
  const { className, variant = 'desktop', setLatePlaylistName } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const [isUpdatedName, setIsUpdatedName] = useState<boolean>(false);
  const { playlistData, playlistActiveFolder } = state.playlistsPage || {};
  // const { screensActiveFolder } = state.homePage || {};
  const { name: playlistDataName } = playlistData || {};
  const { companyData } = state.homePage || {};
  const { id: companyId, companyUserId = '' } = companyData || {};

  const onChange = useCallback(
    (event) => {
      dispatch(
        playlistPageActions.updatePlaylist({
          ...playlistData,
          [event.target.name]: event.target.value,
        })
      );
      dispatch(playlistPageActions.disablePlaylistSaveAndCloseBtn(false));
      setIsUpdatedName(true);
      setLatePlaylistName(true);
    },
    [dispatch, playlistData, setLatePlaylistName]
  );

  const updatePlaylistHandler = useCallback(() => {
    dispatch(playlistPageActions.disablePlaylistSaveAndCloseBtn(true));
    dispatch(playlistPageActions.invokeUpdatePlaylist(true));
    setIsUpdatedName(false);
  }, [dispatch]);

  const playlistDuration = calculateContentRuntime(
    playlistData?.playListContents
  );

  const duplicatePlaylistHandler = useCallback(async () => {
    if (!companyId) {
      toaster.error('Company data not found');
      return;
    }
    if (!playlistData) {
      toaster.error('Playlist data not found');
      return;
    }

    const { screenType } = playlistData || {};

    const response = await addPlaylist(
      {
        // ...playlistData,
        dimention: playlistData.dimention,
        duration: playlistDuration,
        // id: playlistData.id,
        // playListContents: playlistData.playListContents,

        contents:
          (playlistData.playListContents &&
            playlistData.playListContents.map((i) => ({
              id: i.id,
              duration: i.duration,
              sortOrder: i.sortOrder,
            }))) ||
          [],
        companyId,
        name: `${playlistData.name} copy`,
        folderId: playlistActiveFolder?.id || null,
        screenType:
          screenType === 'HORIZONTAL'
            ? ScreenType.HORIZONTAL
            : ScreenType.VERTICAL,
        userId: state.user.loggedInData?.id || '',
      },
      () => {}
    );
    if (response) {
      const responsePlaylist = await getAllPlaylists(
        {
          companyId,
          folderId: playlistActiveFolder?.id || null,
          companyUserId: companyUserId || '',
        },
        () => {}
      );
      if (responsePlaylist) {
        toaster.success('Duplicate Playlist created Successfully');
        const duplicatePlaylist = responsePlaylist.find(
          (plylist) => plylist.id === response.id
        );
        if (duplicatePlaylist)
          dispatch(playlistPageActions.setPlaylistData(duplicatePlaylist));
        if (duplicatePlaylist?.name)
          dispatch(playlistPageActions.addPlaylist(duplicatePlaylist?.name));
      }
    }
  }, [
    companyId,
    dispatch,
    playlistData,
    calculateContentRuntime,
    playlistActiveFolder?.id,
    playlistDuration,
  ]);

  return (
    <div className={`${className} ${styles.details}`}>
      <EditResolutionModal />
      {variant === 'desktop' && (
        <div className={styles.header}>
          <img className={styles.playlistsIcon} src={playlistsIcon} alt="" />
          <span className={styles.heading}>Playlist</span>
        </div>
      )}
      <div className={styles.screenNameAndHashCode}>
        <LabelAndInput
          className={styles.playlistName}
          value={playlistDataName || ''}
          name="name"
          placeholder="Playlist name"
          label="Playlist name"
          onChange={onChange}
        />
        <Button
          className={styles.saveBtn}
          variant={ButtonVariants.ChangeView}
          btnName="Save"
          isActive={isUpdatedName}
          onClick={updatePlaylistHandler}
        />
      </div>
      <div className={styles.orientationTypeAndSize}>
        <div className={styles.sizeContainer}>
          <p className={styles.size}>Size</p>
          <div className={styles.btnContainer}>
            <Button
              className={styles.changeResolutionBtn}
              btnName="Change"
              icon={editIcon}
              iconPosition="left"
              variant={ButtonVariants.SmallTransp}
              onClick={() =>
                dispatch(
                  layoutActions.toggleModal(
                    ModalViews.isShowEditPlaylistResolution
                  )
                )
              }
            />
            <img className={styles.arrowIcon} src={caretLeft} alt="" />
          </div>
        </div>
        <hr />
        <div className={styles.activeOrientationContainer}>
          {playlistData?.screenType === ScreenType.HORIZONTAL && (
            <Screen className={styles.screenIcon} />
          )}
          {playlistData?.screenType === ScreenType.VERTICAL && (
            <PortraitScren className={styles.screenIcon} />
          )}
          <p className={styles.screenOrientation}>
            {playlistData?.screenType === ScreenType.HORIZONTAL
              ? 'Landscape'
              : 'Portrait'}
          </p>
        </div>
      </div>
      <hr />

      <ShareAccess
        className={styles.shareAccess}
        shareType={itemType.PLAYLIST}
        heading={playlistDataName || 'PlayList'}
        shareItemId={playlistData?.id}
      />
      <ColorPicker className={styles.colorPicker} />
      <div
        className={styles.mutateOption}
        onClick={() => duplicatePlaylistHandler()}
      >
        <img src={duplicateIcon} alt="" />
        <p>Duplicate playlist</p>
      </div>
      <div
        className={styles.mutateOption}
        onClick={() => {
          dispatch(layoutActions.toggleModal(ModalViews.isShowDeletePlaylist));
        }}
      >
        <img src={delete2Icon} alt="" />
        <p>Delete playlist</p>
      </div>
    </div>
  );
}

Index.defaultProps = {
  className: '',
  variant: 'desktop',
  setLatePlaylistName: () => {},
};

export default Index;

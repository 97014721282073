import { ROUTES } from 'constants/index';
import { UserRoles } from 'enums';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useStore } from 'store';
import { iState } from 'types';

function ProtectedRoute({
  component: Component,
}: {
  component: any;
}): JSX.Element {
  const [state]: [iState] = useStore();
  const { companyData } = state.homePage || {};

  const { user } = state || {};
  const { isLoggedIn = false } = user || {};
  // console.log('Company data', Component);

  if (isLoggedIn) {
    if (user.role === UserRoles.SuperAdmin) {
      return Component;
    }
    if (companyData) return Component;
    return <Navigate to={ROUTES.chooseTeam} />;
  }
  return <Navigate to={ROUTES.login} />;
}

export default ProtectedRoute;

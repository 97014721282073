import React from 'react';
import EventView from './event-view/eventView';
import TemplateSettings from './template-settings-bar/templateSettingsBar';
import styles from './meetingRoomSection.module.scss';

interface iProps {
  className?: string;
}

function MainSection(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <div className={`${styles.calenderEventsMainSection} ${className}`}>
      <EventView />
      <TemplateSettings />
    </div>
  );
}

MainSection.defaultProps = {
  className: '',
};

export default MainSection;

import { addTeamMember } from 'apis';
// import Button, { ButtonVariants } from 'components/button/button';
// import Input from 'components/input/input';
import LabelAndInput from 'components/label-and-input/labelAndInput';
import Modal from 'components/modal/modal';
import { /* securityIcon, */ team } from 'constants/index';
import { ModalViews, Roles } from 'enums';
import { toaster } from 'helpers';
import React, { useCallback, useState } from 'react';
import { useStore } from 'store';
import { layoutActions } from 'store/actions';
import { iState } from 'types';
import styles from './addTeammateModal.module.scss';

function AddTeammateModal(): JSX.Element {
  // const Navigate = useNavigate();
  const [teammate, setTeammate] = useState({
    email: '',
    role: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const [state, dispatch]: [iState, any] = useStore();
  const { isShowAddTeammate = false } = state.layout || {};
  const [errorMessage, setErrorMessage] = useState('');
  const { allRoles = [] } = state.user || {};

  const onChange = useCallback(
    (event): void => {
      const temp = { ...teammate };

      temp[event.target.name] = event.target.value;
      setTeammate(temp);
    },
    [teammate]
  );

  const addTeammate = useCallback(() => {
    (async () => {
      if (!teammate.email) {
        setErrorMessage('Please enter valid mail Id');
        return;
      }
      // if (teammate.role === '') {
      //   setErrorMessage('Please select a role from the dropdown');
      //   return;
      // }
      // console.log(teammate.email.split(','), 'all Rles');
      const adminRole = Array.isArray(allRoles)
        ? allRoles.find((i) => i.role_name === Roles.Admin)
        : null;

      if (!adminRole) {
        toaster.error('Roles not found');
        return;
      }

      const response = await addTeamMember(
        {
          email: teammate.email.split(','),
          companyId: state.homePage.companyData?.id || '',
          roleId: Number(adminRole?.id),
        },
        setIsLoading
      );
      if (response?.statusCode === 200) {
        dispatch(layoutActions.toggleModal(ModalViews.isShowAddTeammate));
        toaster.success('Team member invited successfully');
        setTeammate({ email: '', role: '' });
      }
    })();

    // Navigate(ROUTES.joinYourTeam);
  }, [dispatch, state, teammate, allRoles]);

  return (
    <Modal
      isOpen={isShowAddTeammate}
      heading="Add Team Members"
      btnName="Send Invites"
      headingIcon={team}
      // onClose={() => dispatch(layoutActions.toggleAddTeammate())}
      onClose={() => dispatch(layoutActions.toggleModal())}
      submit={addTeammate}
      isLoading={isLoading}
    >
      <div className={styles.addTeammate}>
        <div className={styles.labelAndInput}>
          <LabelAndInput
            className={styles.emailInput}
            label="Email"
            name="email"
            placeholder="user@domain.com"
            value={teammate.email}
            onChange={onChange}
            errorMessage={errorMessage}
          />
        </div>
        {/* <div className={styles.divider} />
        <div className={styles.labelAndInput}>
          <Button
            className={styles.role}
            variant={ButtonVariants.LargeTransp}
            btnName="Role"
            icon={securityIcon}
            // onClick={onClick}
          />
        </div>
        <div className={styles.flex}>
          <div className={styles.desc}>
            <p className={styles.label}>
              Users can only work with content and playlists.
            </p>
            <p className={styles.label}>Admins have all rights</p>
          </div>
          {allRoles && allRoles.length > 0 && (
            <div className={`${styles.labelAndInput} ${'flex-grow'}`}>
              <select
                className={styles.inputSelect}
                name="role"
                onChange={onChange}
              >
                <option value="">Select role</option>
                {allRoles.map((role) => (
                  <option value={role.id}>{role.role_name}</option>
                ))}
              </select>
            </div>
          )}
        </div> */}
      </div>
    </Modal>
  );
}

export default AddTeammateModal;

import { chooseCompanies, getPermissions } from 'apis';
import Logo from 'components/logo/logo';
import More from 'components/svg/more';
import { ROUTES } from 'constants/index';
import { LayoutViews, PermissionFor, UserRoles } from 'enums';
import { toaster } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import {
  layoutActions,
  contentPageActions,
  homePageActions,
  playlistPageActions,
  userActions,
} from 'store/actions';
import { iState } from 'types';
import { SIDEBAR_ICONS } from './constants';
import MoreDrawer from './more-drawer/moreDrawer';
import Profile from './profile-popover/profilePopover';
import styles from './sidebar.module.scss';

interface iProps {
  className?: string;
}

function Sidebar(props: iProps): JSX.Element {
  const { className } = props;
  const [isMoreOpen, setIsMoreOpen] = useState<boolean>(false);
  const [state, dispatch]: [iState, any] = useStore();
  const {
    chosenView,
    isShowingClientInstance,
    initialSidebarState,
    isShowAddContent,
    isShowAddWebContent,
    isShowContentPreview,
    isShowPlaylistPreview,
    isShowAddPlaylist,
    isShowAddFolderForPlaylists,
    isShowAddContentToPlaylist,
    isShowAddTeammate,
    isShowAddClient,
    isShowAddScreen,
    isShowAddFolder,
    isShowShareAccess,
    isShowDeleteModal,
    invokeGetAllFolders,
    invokeGetFolderById,
    isShowAssignContentOrPlaylist,
  } = state.layout || {};

  const isModalOpen =
    isShowAddContent ||
    isShowAddWebContent ||
    isShowContentPreview ||
    isShowPlaylistPreview ||
    isShowAddPlaylist ||
    isShowAddFolderForPlaylists ||
    isShowAddContentToPlaylist ||
    isShowAddTeammate ||
    isShowAddClient ||
    isShowAddScreen ||
    isShowAddFolder ||
    isShowShareAccess ||
    isShowDeleteModal ||
    invokeGetAllFolders ||
    invokeGetFolderById ||
    isShowAssignContentOrPlaylist;

  const { companyData } = state.homePage || {};
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const selectedSettingsBtn = pathname === ROUTES.userSettings || isMoreOpen;
  const { role, loggedInData } = state.user || {};
  const { id: userId } = loggedInData || {};
  const isSuperUserAdmin = role === UserRoles.SuperAdmin;
  const isOwner = role === UserRoles.Owner;
  const isAdmin = role === UserRoles.Admin;
  const isClient = role === UserRoles.Client;
  // const isResellerAdmin = role === UserRoles.Reseller;

  useEffect(() => {
    if (role === UserRoles.Client)
      dispatch(layoutActions.initialSidebarState(true));
  }, [role, dispatch]);

  const clear = useCallback(() => {
    dispatch(homePageActions.setScreensActiveFolder(null));
    dispatch(homePageActions.setScreensFolderHierarchy([]));
    dispatch(contentPageActions.setContentActiveFolder(null));
    dispatch(contentPageActions.setContentFolderHierarchy([]));
    dispatch(playlistPageActions.setPlaylistsActiveFolder(null));
    dispatch(playlistPageActions.setPlaylistsFolderHierarchy([]));
    dispatch(layoutActions.setAssignModalContentActiveFolders(null));
    dispatch(layoutActions.setAssignModalContentsFolderHierarchy([]));
    dispatch(layoutActions.setAssignModalContentActiveFolders(null));
    dispatch(layoutActions.setAssignModalContentFolders([]));
    dispatch(layoutActions.setAssignModalContentsFolderHierarchy([]));
  }, [dispatch, navigate, chosenView]);

  const onClick = useCallback(
    (icon: any): void => {
      clear();
      navigate(icon.path);
      if (icon.path === ROUTES.screenz)
        if (chosenView === LayoutViews.SuperAdminClients) {
          dispatch(
            layoutActions.setHomePageView(LayoutViews.SuperAdminClients)
          );
        }
      // dispatch(layoutActions.initialSidebarState());
      dispatch(layoutActions.setHomePageView(LayoutViews.Screens));
      dispatch(layoutActions.initialSidebarState(true));
      if (icon.path === ROUTES.content)
        dispatch(layoutActions.setHomePageView(LayoutViews.Content));
      if (icon.path === ROUTES.playlists)
        dispatch(layoutActions.setHomePageView(LayoutViews.Playlists));
    },
    [navigate, dispatch, chosenView]
  );

  const isActivePath = useCallback(
    (icon) => {
      const { path } = icon || {};

      return (
        pathname === path ||
        (path === ROUTES.screenz && pathname === ROUTES.screenDetails) ||
        (path === ROUTES.playlists && pathname === ROUTES.playlist)
      );
    },
    [pathname]
  );

  const clearActiveFolders = useCallback(() => {
    dispatch(homePageActions.setScreensActiveFolder(null));
    dispatch(contentPageActions.setContentActiveFolder(null));
    dispatch(playlistPageActions.setPlaylistsActiveFolder(null));
  }, [dispatch]);

  const onClickSideBarHandler = (icon: any): void => {
    if (!companyData) {
      toaster.error('Please choose a client to proceed');
      if (!isSuperUserAdmin) {
        navigate(ROUTES.chooseTeam);
        return;
      }
      return;
    }
    clearActiveFolders();
    if (isShowingClientInstance && pathname !== ROUTES.emptyStates)
      onClick(icon);
    if (isClient || isOwner || isAdmin) onClick(icon);
    if (pathname === ROUTES.userSettings) {
      navigate('/');
    }
  };

  useEffect(() => {
    if (isOwner || isAdmin || isClient)
      dispatch(layoutActions.initialSidebarState(true));
  }, [dispatch, isOwner, isAdmin, isClient]);

  const fetchPermissions = useCallback(async () => {
    const { companyUserId = '' } = companyData || {};
    if (!companyUserId) {
      return;
    }
    const permissionsResponse = await getPermissions(
      {
        permissionFor: PermissionFor.User,
        rollOrCompanyUserId: companyUserId || '',
      },
      () => {}
    );
    if (permissionsResponse) {
      dispatch(userActions.setUserPermissions(permissionsResponse));
      // console.log(permissionsResponse, 'permissionsResponse');
    }
  }, [dispatch, state.layout, pathname, companyData]);

  const fetchUserCompanies = useCallback(async () => {
    if (!userId || isSuperUserAdmin) return;
    const response = await chooseCompanies(userId);
    if (response && companyData) {
      const selectedCompany = response.find(
        (company) => company.id === companyData.id
      );
      dispatch(homePageActions.setCompanies(response));
      if (selectedCompany)
        dispatch(homePageActions.setCompanyData(selectedCompany));
    }
  }, [dispatch, state.layout]);

  useEffect(() => {
    fetchPermissions();
  }, [state.layout, pathname, companyData]);

  useEffect(() => {
    fetchUserCompanies();
  }, [pathname]);

  const hideSidebar = pathname === ROUTES.playlist && true;

  return (
    <div
      className={`${styles.wrapper} ${
        (hideSidebar || isModalOpen) && styles.hide
      }`}
    >
      <MoreDrawer isOpen={isMoreOpen} />
      <div className={`${className} ${styles.sidebar}`}>
        <Logo className={styles.logo} variant="sidebar" />
        {SIDEBAR_ICONS.map((icon) => {
          const isActive = initialSidebarState ? isActivePath(icon) : false;

          return (
            <div
              key={icon.id}
              className={`${styles.icon} ${
                isShowingClientInstance &&
                pathname !== ROUTES.emptyStates &&
                styles.active
              }
                ${
                  state.user.role === UserRoles.Client &&
                  pathname !== ROUTES.emptyStates &&
                  styles.active
                }`}
              onClick={() => onClickSideBarHandler(icon)}
            >
              <div
                className={`${styles.iconHolder} ${isActive && styles.active}`}
              >
                <icon.icon
                  className={`${styles.svgStyle} ${isActive && styles.active}`}
                />
              </div>
              <span className={`${styles.name} ${isActive && styles.active}`}>
                {icon.iconName}
              </span>
            </div>
          );
        })}

        <div
          className={`${styles.icon} ${styles.moreIcon}`}
          onClick={() => setIsMoreOpen(!isMoreOpen)}
        >
          <div
            className={`${styles.iconHolder} ${
              selectedSettingsBtn && styles.active
            } `}
          >
            <More className={`${styles.svgStyle}`} />
          </div>
          <span
            className={`${styles.name} ${selectedSettingsBtn && styles.active}`}
          >
            More
          </span>
        </div>
        <Profile />
      </div>
    </div>
  );
}

Sidebar.defaultProps = {
  className: '',
};

export default Sidebar;

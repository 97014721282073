export enum UserSettingsView {
  Profile = 'Profile',
  PasswordAndSecurity = 'PasswordAndSecurity',
  General = 'General',
  TeamMembers = 'TeamMembers',
  CompanyInformation = 'CompanyInformation',
  TeamMemberPermissions = 'TeamMemberPermissions',
  Integrations = 'Integrations',
  AddTeamMembers = 'AddTeamMembers',
}

export enum SharedAccess {
  itemType = 'itemType',
  folderType = 'folderType',
  accessType = 'accessType',
}

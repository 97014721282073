import React from 'react';
import Input from 'components/input/input';
import styles from './nameEditor.module.scss';

interface iProps {
  className?: string;
  label: string;
  value: string;
  isDisabled?: boolean;
  onChange: (a: any) => void;
}

function Index(props: iProps): JSX.Element {
  const { className, label, value, isDisabled = false, onChange } = props;

  return (
    <div className={`${styles.nameContainer} ${className}`}>
      <p className={styles.label}>{label}</p>
      <Input
        className={styles.customNameInput}
        value={value}
        disabled={isDisabled}
        onChange={onChange}
      />
    </div>
  );
}

Index.defaultProps = {
  className: '',
  isDisabled: false,
};

export default Index;

import React, { useState, useEffect } from 'react';
import Button, { ButtonVariants } from 'components/button/button';
import styles from './offline.module.scss';

function OfflineDetector({ children }): JSX.Element {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    window.addEventListener('online', () => setIsOnline(true));
    window.addEventListener('offline', () => setIsOnline(false));
    return () => {
      window.removeEventListener('online', () => setIsOnline(true));
      window.removeEventListener('offline', () => setIsOnline(false));
    };
  }, []);

  return isOnline ? (
    children
  ) : (
    <div className={styles.mainContainer}>
      <div className={styles.errorContainer}>
        <span className={styles.errorMessage}>
          We could not connect to the internet. Try checking your connection.
        </span>
        <Button
          className={styles.gotoScreenBtn}
          variant={ButtonVariants.MediumStd}
          btnName="Refresh"
          onClick={() => window.location.reload()}
        />
      </div>
    </div>
  );
}

export default OfflineDetector;

import LabelAndInput from 'components/label-and-input/labelAndInput';
import React, { useCallback } from 'react';
import { useStore } from 'store';
import { userActions } from 'store/actions';
import { iState, iUpdateLoggedInUserData } from 'types';
import SectionHeading from '../section-heading/sectionHeading';
import styles from './personalInformation.module.scss';

interface iProps {
  className?: string;
  // enableBtn?: boolean;
  setEnableBtn?: any;
}
function PersonalInformation(props: iProps): JSX.Element {
  const { className, setEnableBtn } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { loggedInData } = state.user || {};
  const { firstName, lastName, email } = loggedInData || {};

  const handleChange = useCallback(
    (event) => {
      if (loggedInData) {
        const temp: iUpdateLoggedInUserData = { ...loggedInData };
        temp[event.target.name] = event.target.value;
        //  console.log('USER_DATA', { temp, loggedInData });
        if (temp[event.target.name] === loggedInData[event.target.name])
          setEnableBtn(true);
        dispatch(userActions.updateLoggedInUserData(temp));
      }
    },
    [loggedInData, dispatch, setEnableBtn]
  );

  return (
    <div className={`${styles.personalInformation} ${className}`}>
      <SectionHeading
        className={styles.sectionHeading}
        heading="Personal information"
      />
      <div className={styles.firstAndLastName}>
        <LabelAndInput
          className={styles.labelAndInput}
          label="Firstname"
          name="firstName"
          value={firstName || ''}
          onChange={handleChange}
        />
        <LabelAndInput
          className={styles.labelAndInput}
          label="Lastname"
          name="lastName"
          value={lastName || ''}
          onChange={handleChange}
        />
      </div>
      <LabelAndInput
        className={`${styles.inputEmail} ${styles.email}`}
        label="Email"
        value={`${email}`}
        disabled
        onChange={handleChange}
      />
    </div>
  );
}

PersonalInformation.defaultProps = {
  className: '',
  // enableBtn: false,
  setEnableBtn: () => {},
};

export default PersonalInformation;

import React from 'react';
import Avatar, { AvatarVariants } from 'components/avatar/avatar';
import { useStore } from 'store';
import styles from './avatarGroup.module.scss';

interface iProps {
  className?: string;
  data: any;
  onClick?: any;
}

function AvatarGroup(props: iProps): JSX.Element {
  const { className, data = [], onClick } = props;
  const [state] = useStore();
  const { id: userId } = state.user || {};
  const { id: dataID } = data[0] || {};

  const displayAccountOwnerAccess = dataID === userId;
  return (
    <div className={`${styles.avatarGroup} ${className}`}>
      {data?.slice(0, 4).map((user, index) => (
        <Avatar
          className={styles.avatar}
          variant={AvatarVariants.Medium}
          style={{ left: `${index * 30}px` }}
          firstName={user.first_name}
          lastName={user.last_name}
          onClick={onClick}
          profilePic={user.profile_pic}
          avatarCount={index > 2 ? data.length - 3 : 0}
        />
      ))}
      {data?.length === 1 && (
        <>
          {displayAccountOwnerAccess && (
            <p className={styles.accessToOnlyOne}>Only you have access</p>
          )}
          {!displayAccountOwnerAccess && (
            <p
              className={styles.accessToOnlyOne}
            >{`Only ${data[0].first_name} ${data[0].last_name} has access`}</p>
          )}
        </>
      )}
    </div>
  );
}

AvatarGroup.defaultProps = {
  className: '',
  onClick: () => {},
};

export default AvatarGroup;

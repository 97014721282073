import React, { useCallback, useState, useEffect } from 'react';
import Button, { ButtonVariants } from 'components/button/button';
import { iAddOrUpdateTempleteArgs, iState } from 'types';
import {
  AddOrUpdateTemplateMutationType,
  EventTemplateType,
  EventTemplateContentType,
  ClockContentType,
  LeftAreaContentType,
  LanguageType,
} from 'enums';
import { meetingOverviewFullActions, office365Actions } from 'store/actions';
import { useNavigate } from 'react-router-dom';
import { toaster, uuid } from 'helpers';
import { addTemplate } from 'apis';
import { useStore } from 'store';
import { caretLeftDark, ROUTES } from 'constants/index';
import englishData from '../../english.json';
import norwayData from '../../norwegian.json';
import styles from './header.module.scss';

interface iProps {
  className?: string;
}

function Header(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { companyData } = state.homePage;
  const { contentActiveFolder } = state.contentPage;
  const { templateToView } = state.layout || {};
  const {
    eventInfo,
    templateIdToEdit,
    tempalteDataToUpdate,
    updateRoomCustomName = false,
  } = state.office365 || {};
  const { user } = state || {};
  const templateId = tempalteDataToUpdate?.templateData?.id;
  const [templateTexts, setTemplateTexts] = useState<any>(englishData);
  const { transparency } = state.meetingOverviewFull || {};

  const { selectedLanguage } = state.office365 || {};

  useEffect(() => {
    if (selectedLanguage.value === LanguageType.English) {
      setTemplateTexts(englishData);
    }
    if (selectedLanguage.value === LanguageType.Norwegian) {
      setTemplateTexts(norwayData);
    }
  }, [selectedLanguage]);

  const templateCalendarType =
    (templateToView === EventTemplateType.meetingOverviewFull &&
      EventTemplateType.meetingOverviewFull) ||
    (templateToView === EventTemplateType.meetingOverviewMessage &&
      EventTemplateType.meetingOverviewMessage) ||
    (templateToView === EventTemplateType.meetingOverviewPotrait &&
      EventTemplateType.meetingOverviewPotrait) ||
    EventTemplateType.meetingOverviewPotrait;

  const selectedLanguageType =
    (selectedLanguage.value === LanguageType.English && LanguageType.English) ||
    (selectedLanguage.value === LanguageType.Norwegian &&
      LanguageType.Norwegian) ||
    LanguageType.English;

  const saveMeetingRoomTemplateHandler = useCallback(async () => {
    const { id: folderId = '' } = contentActiveFolder || {};
    const { id: companyId = '' } = companyData || {};
    const {
      isShowActiveBooking = true,
      isShowHostName = true,
      isShowNextMeeting = true,
      isShowSubject = true,
      roomChoosed,
      eventBgImg = '',
      eventLogo = '',
      timeZone,
      customName = '',
      templateName = '',
    } = eventInfo || {};

    if (!templateName) {
      toaster.error('Please enter Template Name');
      return;
    }
    const { id: roomId = '' } = roomChoosed || {};

    if (!roomChoosed) {
      toaster.error('Room data not found');
      return;
    }

    const args: iAddOrUpdateTempleteArgs = {
      userId: user.loggedInData ? user.loggedInData?.id : '',
      name: templateName,
      mutationType: templateIdToEdit
        ? AddOrUpdateTemplateMutationType.UPDATE
        : AddOrUpdateTemplateMutationType.CREATE,
      templateType: EventTemplateType.meetingRoom,
      folderId,
      language: selectedLanguageType,
      companyId,
      opacity: Number(transparency) / 10,
      updateRoomName: updateRoomCustomName,
      details: {
        leftAreaContetType: null,
        backgroundImageUrl: eventBgImg,
        logoUrl: eventLogo,
        ActivateBooking: isShowActiveBooking,
        leftAreaText: {
          body: null,
          preTitle: null,
          subText: null,
          title: null,
        },
        leftAreaImageUrl: '',
      },
      contents: [
        {
          // id: '',
          contentType: EventTemplateContentType.Calender,
          customName,
          position: 1,
          room: roomId,
          details: {
            contentAlignment: ClockContentType.LeftAligned,
            imageUrl: null,
            roomDetails: null,
            showHostName: isShowHostName,
            showNextMeeting: isShowNextMeeting,
            showSubject: isShowSubject,
            timezone: (timeZone && timeZone.value) || '',
          },
        },
      ],
    };
    if (templateIdToEdit) args['id'] = templateId;
    const response = await addTemplate(args, setIsLoading);
    if (response) {
      if (templateIdToEdit) toaster.success('Template updated successfully');
      if (!templateIdToEdit) toaster.success('Template added successfully');
      navigate(ROUTES.content);
      //  console.log('addTemplate_response', response);
      dispatch(office365Actions.setupdateRoomCustomName(false));
    }
  }, [
    eventInfo,
    companyData,
    contentActiveFolder,
    navigate,
    templateIdToEdit,
    templateId,
    templateToView,
    state,
  ]);

  useEffect(() => {
    return () => {
      dispatch(office365Actions.resetTemplatData());
      dispatch(meetingOverviewFullActions.resetOverviewTemplateData());
    };
  }, [dispatch]);

  const saveMeetingOverviewTemplateHandler = useCallback(async () => {
    const { id: folderId = '' } = contentActiveFolder || {};
    const { id: companyId = '' } = companyData || {};
    const {
      eventBgImg,
      cardDetails,
      templateOverviewName = '',
      title,
      body,
      largeAreaContent,
      largeContentUrl,
      preTitle,
      subText,
    } = state.meetingOverviewFull || {};
    if (!templateOverviewName) {
      toaster.error('Please enter Template Name');
      return;
    }

    const contentArray: any = [];

    Object.values(cardDetails).forEach((card) => {
      if (card.contentOption.value === 'Empty') return;
      const contentType =
        (card.contentOption.value === EventTemplateContentType.Calender &&
          EventTemplateContentType.Calender) ||
        (card.contentOption.value === EventTemplateContentType.Clock &&
          EventTemplateContentType.Clock) ||
        EventTemplateContentType.Image;
      const contentAlignment =
        (card.clockAlignment.value === ClockContentType.LeftAligned &&
          ClockContentType.LeftAligned) ||
        ClockContentType.RightAligned;
      if (card.id) {
        contentArray.push({
          id: card.id,
          position: card.position,
          contentType,
          customName: card.overviewCustom_name || '',
          room:
            (card.overviewChoosedRoom && card.overviewChoosedRoom.id) || null,
          details: {
            contentAlignment,
            imageUrl: card.overviewEventLogo,
            roomDetails: card.roomDetails,
            showHostName: card.isShowOverviewHostName,
            showNextMeeting: card.isShowOverviewNextMeeting,
            showSubject: card.isShowOverviewSubject,
            timezone:
              (card.overviewTimezone.value !== 'Empty' &&
                card.overviewTimezone.value) ||
              '',
          },
        });
        return;
      }
      contentArray.push({
        position: card.position,
        contentType,
        customName: card.overviewCustom_name || '',
        room: (card.overviewChoosedRoom && card.overviewChoosedRoom.id) || null,
        details: {
          contentAlignment,
          imageUrl: card.overviewEventLogo,
          roomDetails: card.roomDetails,
          showHostName: card.isShowOverviewHostName,
          showNextMeeting: card.isShowOverviewNextMeeting,
          showSubject: card.isShowOverviewSubject,
          timezone:
            (card.overviewTimezone.value !== 'Empty' &&
              card.overviewTimezone.value) ||
            '',
        },
      });
    });

    const leftAreaContetType =
      (largeAreaContent.value === LeftAreaContentType.Text &&
        LeftAreaContentType.Text) ||
      (largeAreaContent.value === LeftAreaContentType.Image &&
        LeftAreaContentType.Image) ||
      null;

    const args: iAddOrUpdateTempleteArgs = {
      userId: user.loggedInData ? user.loggedInData?.id : '',
      name: templateOverviewName,
      mutationType: templateIdToEdit
        ? AddOrUpdateTemplateMutationType.UPDATE
        : AddOrUpdateTemplateMutationType.CREATE,
      templateType: templateCalendarType,
      folderId,
      language: selectedLanguageType,
      companyId,
      opacity: Number(transparency) / 10,
      updateRoomName: updateRoomCustomName,
      details: {
        leftAreaContetType,
        backgroundImageUrl: eventBgImg,
        logoUrl: null,
        ActivateBooking: false,
        leftAreaText: {
          body,
          preTitle,
          subText,
          title,
        },
        leftAreaImageUrl: largeContentUrl,
      },
      contents: contentArray,
    };

    if (templateIdToEdit) args['id'] = templateId;
    const response = await addTemplate(args, setIsLoading);
    if (response) {
      if (templateIdToEdit) toaster.success('Template updated successfully');
      if (!templateIdToEdit) toaster.success('Template added successfully');
      navigate(ROUTES.content);
      //  console.log('addTemplate_response', response);
      dispatch(office365Actions.setupdateRoomCustomName(false));
    }
  }, [state, templateToView]);

  const isMeetingRoom =
    eventInfo?.chosenTemplateType === EventTemplateType.meetingRoom;
  const isMeetingOverviewFull =
    eventInfo?.chosenTemplateType === EventTemplateType.meetingOverviewFull;
  const isMeetingOverviewWithMessage =
    eventInfo?.chosenTemplateType === EventTemplateType.meetingOverviewMessage;
  const isMeetingOverviewPortrait =
    eventInfo?.chosenTemplateType === EventTemplateType.meetingOverviewPotrait;
  const saveTemplateHandler = useCallback(() => {
    if (templateToView === EventTemplateType.meetingRoom) {
      saveMeetingRoomTemplateHandler();
    }
    if (templateToView !== EventTemplateType.meetingRoom) {
      saveMeetingOverviewTemplateHandler();
    }
  }, [state, templateToView]);

  return (
    <div className={`${styles.calenderEventsHeader} ${className}`}>
      <div className={styles.leftOne}>
        <img
          className={styles.backIcon}
          src={caretLeftDark}
          alt=""
          onClick={() => {
            navigate(ROUTES.content);
            dispatch(meetingOverviewFullActions.resetOverviewTemplateData());
            dispatch(
              office365Actions.setSelectedLanguage({
                id: uuid(),
                option: 'English',
                icon: '',
                value: LanguageType.English,
              })
            );
          }}
        />
        <p className={styles.dropdownHeading}>
          {isMeetingRoom && 'Meeting Room'}
          {isMeetingOverviewFull && 'Meeting Overview Full'}
          {isMeetingOverviewWithMessage && 'Meeting Overview'}
          {isMeetingOverviewPortrait && 'Meeting Overview Portrait'}
        </p>
        <img className={styles.dropdownIcon} src={caretLeftDark} alt="" />
      </div>
      <div className={styles.rightOne}>
        <Button
          className={styles.saveTemplateBtn}
          variant={ButtonVariants.SmallStd}
          btnName={templateTexts.saveTemplate}
          isLoading={isLoading}
          onClick={saveTemplateHandler}
        />
      </div>
    </div>
  );
}

Header.defaultProps = {
  className: '',
};

export default Header;

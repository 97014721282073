import Popover from 'components/popover/popover';
import User from 'components/svg/user';
import {
  caretLeft,
  helpCircleOutline,
  ROUTES,
  settingsIcon,
} from 'constants/index';
import { LayoutViews, UserRoles, UserSettingsView } from 'enums';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import {
  homePageActions,
  layoutActions,
  userActions,
  contentPageActions,
  playlistPageActions,
} from 'store/actions';
import { iCompanyData, iState } from 'types';
import Avatar from '../avatar/avatar';
import Company from '../company-menu-item/company';
import { COMPANIES } from '../constants';
import Logout from '../logout/logout';
import styles from './profilePopover.module.scss';

interface iProps {
  className?: string;
}

function ProfilePopover(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { companies, companyData } = state.homePage || {};
  const { role } = state.user || {};
  const [activeCompany, setActiveCompany] = useState<iCompanyData | null>();
  const navigate = useNavigate();
  const [popOverEffect, setPopoverEffect] = useState(false);
  const pathName = useLocation();

  useEffect(() => {
    setActiveCompany(companyData || null);
  }, [companyData, companies]);

  const selectSettingBtn =
    pathName.pathname === ROUTES.userSettings || popOverEffect;
  const onClickCompanyHandler = useCallback(
    (company: iCompanyData) => {
      // dispatch(homePageActions.setCompanyData(company));
      // dispatch(layoutActions.initialSidebarState(true));

      dispatch(layoutActions.setShowClientsInstance(true));
      dispatch(homePageActions.setCompanyData(company));
      dispatch(layoutActions.initialSidebarState(true));
      dispatch(layoutActions.setHomePageView(LayoutViews.Screens));

      dispatch(homePageActions.setScreensActiveFolder(null));
      dispatch(homePageActions.setScreensFolders([]));
      dispatch(homePageActions.setScreensFolderHierarchy([]));

      dispatch(contentPageActions.setContentActiveFolder(null));
      dispatch(contentPageActions.setContentFolders([]));
      dispatch(contentPageActions.setContentFolderHierarchy([]));

      dispatch(playlistPageActions.setPlaylistsActiveFolder(null));
      dispatch(playlistPageActions.setPlaylistsFolders([]));
      dispatch(playlistPageActions.setPlaylistsFolderHierarchy([]));

      dispatch(layoutActions.setAssignModalContentActiveFolders(null));
      dispatch(layoutActions.setAssignModalContentFolders([]));
      dispatch(layoutActions.setAssignModalContentsFolderHierarchy([]));

      navigate(ROUTES.screenz);
    },
    [dispatch, navigate]
  );

  const chooseClientHandler = useCallback(
    (selectedCompanyCompanyData: iCompanyData) => {
      dispatch(homePageActions.setCompanyData(selectedCompanyCompanyData));
      dispatch(layoutActions.setHomePageView(LayoutViews.Screens));
      const { role: roleOfSelectedCompany } = selectedCompanyCompanyData || {};
      const { id: roleIds } = roleOfSelectedCompany || {};
      if (roleIds) dispatch(userActions.changeUserRole(roleIds));
      navigate(ROUTES.screenz, { replace: true });
      // fetchPermissions(companyData);
      // dispatch(userActions.setClientChoosed(true));

      // dispatch(userActions.setClientChoosed(true));
    },
    [dispatch, navigate]
  );

  const handleHelpCenter = useCallback(() => {
    window.open('http://support.screenz.no/nb/');
  }, []);

  return (
    <div className={`${className} ${styles.wrapper} ${styles.profileIcon}`}>
      <Popover
        canCloseOnClick={false}
        popoverClassName={styles.morePopover}
        popoverComponent={
          <>
            <Avatar className={styles.avatar} />

            {companies?.length > 0 && (
              <Popover
                popoverClassName={styles.companiesPopover}
                popoverComponent={
                  <>
                    {companies.map((company: iCompanyData, i: number) => (
                      <Company
                        key={company.id}
                        className={styles.company}
                        data={company}
                        isActive={company.id === activeCompany?.id}
                        isLastOne={COMPANIES.length - 1 === i}
                        onClick={() => {
                          if (role === UserRoles.SuperAdmin) {
                            onClickCompanyHandler(company);
                            return;
                          }
                          chooseClientHandler(company);
                        }}
                      />
                    ))}
                  </>
                }
              >
                <div className={styles.companyWrapper}>
                  <div className={styles.companyAndOwner}>
                    <p className={styles.company}>
                      {activeCompany?.name || 'All Clients'}
                    </p>
                    {/* {activeCompany && (
                      <p className={styles.owner}>Bytt selskap her</p>
                    )} */}
                  </div>
                  <img src={caretLeft} alt="" />
                </div>
              </Popover>
            )}

            <div
              onClick={() => {
                // navigate(ROUTES.userSettings)
                dispatch(userActions.changeView(UserSettingsView.Profile));
                navigate(ROUTES.userSettingsProfile, {
                  state: { view: 'Profile' },
                });
              }}
              className={styles.menu}
            >
              <img src={settingsIcon} alt="" />
              <p>Settings</p>
            </div>

            <div className={`${styles.menu}`} onClick={handleHelpCenter}>
              <img src={helpCircleOutline} alt="" />
              <p>Screenz Help Centre</p>
              {/* <img src={caretLeft} alt="" /> */}
            </div>
            <Logout className={styles.logout} />
          </>
        }
        popOverEffect={popOverEffect}
        setPopoverEffect={setPopoverEffect}
      >
        <div className={`${styles.icon}`}>
          <div
            className={`${styles.iconHolder} ${
              selectSettingBtn && styles.active
            }`}
          >
            <User
              className={`${styles.svgStyle} ${
                selectSettingBtn && styles.active
              } `}
            />
          </div>
        </div>
      </Popover>
    </div>
  );
}

ProfilePopover.defaultProps = {
  className: '',
};

export default ProfilePopover;

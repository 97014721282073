import { gql } from 'apollo-boost';

export enum Playlist {
  AddPlaylist = 'addPlaylist',
  GetPlayListByCompany = 'getPlayListByCompany',
  GetPlaylistById = 'getplaylistbyid',
  UpdatePlaylist = 'updatePlaylist',
  SearchPlaylist = 'searchPlaylistbyname',
  DeletePlaylist = 'deletePlaylist',
}

export const addPlaylistMutation = gql`
  mutation ${Playlist.AddPlaylist} (
    $name: String!
    $companyId: String!
    $folderId: String
    $contents: [playlistContents]
    $duration: String
    $screenType: ScreenType
    $dimention: String
    $status: String
    $companyUserId: String
    $userId: String!

  ){
    ${Playlist.AddPlaylist} (
      name: $name
      companyId: $companyId
      folderId: $folderId
      contents: $contents
      duration: $duration
      screenType: $screenType
      dimention: $dimention
      status: $status
      companyUserId: $companyUserId
      userId: $userId
    ){
      statusCode
      message
      data {
        id
        companyId
        name
        playListContents {
          id
          name
          url
          contentType
          size
          dimension
          folder{
            id
          }
          thumbnailPath
        }
      }
    }
  }
`;

export const getPlayListByCompanyQuery = gql`
  query ${Playlist.GetPlayListByCompany}(
    $companyId: String!
    $folderId: String
    $companyUserId: String
  ){
    ${Playlist.GetPlayListByCompany}
    (
      companyId: $companyId
      folderId: $folderId
      companyUserId: $companyUserId
    ){
      statusCode
      message
    data {
      id
      companyId
      name
      duration
      dimention
      screenType
      status
      playListContents {
        id
        name
        url
        contentType
        size
        sortOrder
        dimension
        orientation
        duration
        thumbnailPath
      }
      owner{
        id
      }
      sharedAt
    }
    }
  }
`;

export const getPlaylistByIdQuery = gql`
  query ${Playlist.GetPlaylistById}($id: String){
    ${Playlist.GetPlaylistById}(id: $id){
      statusCode
      message
      data {
        id
        companyId
        name
        duration
        dimention
        screenType
        status
        playListContents {
          id
          name
          url
          contentType
          size
          dimension
          orientation
          duration
          thumbnailPath
          sortOrder
          playlistContentId
          templateData{
            templateType
          }
        }
      }
    }
  }
`;

export const updatePlaylistMutation = gql`
  mutation ${Playlist.UpdatePlaylist} (
    $id: String!
    $name: String!
    $companyId: String!
    $folderId: String
    $contents: [playlistContents]
    $duration: String
    $dimention: String
    $screenType: ScreenType
  ){
    ${Playlist.UpdatePlaylist} (
      id: $id
      name: $name
      companyId: $companyId
      folderId: $folderId
      contents: $contents
      duration: $duration
      dimention: $dimention
      screenType: $screenType
    ){
      statusCode
      message
      data {
        id
      }
    }
  }
`;

export const searchPlaylistMutation = gql`
  mutation ${Playlist.SearchPlaylist} (
    $name: String
    $company: String!
    $folderId: String
  ){
    ${Playlist.SearchPlaylist} (
      name: $name
      company: $company
      folderId: $folderId
    ){
      message
      statusCode
      data {
        id
        companyId
        name
        duration
        dimention
        screenType
        status
        sharedAt
        owner{
          id
        }
      }
    }
  }
`;

export const deletePlaylistMutation = gql`
  mutation ${Playlist.DeletePlaylist} (
    $id: String!
    $is_data: Boolean!
  ){
    ${Playlist.DeletePlaylist} (
      id: $id
      is_data: $is_data
    ){
      message
      statusCode
      data {
        id
        name
        screenz {
          id
          name
        }
      }
    }
  }
`;

import React, { useState, useCallback, useEffect } from 'react';
import Modal from 'components/modal/modal';
import { imageIcon } from 'constants/index';
import { getCroppedImg } from 'helpers/getCroppedImg';
import Cropper from 'react-easy-crop';
import RangeSlider from 'components/range-slider/slider';
import styles from './cropProfilePic.module.scss';

interface iProps {
  className?: string;
  isOpen: boolean;
  file: File;
  isLoading: boolean;
  onCropImageHandler: (file: File) => void;
  onClose: () => void;
}

// const IMAGE =
//   'https://cdn.pixabay.com/photo/2015/06/19/21/24/avenue-815297__340.jpg';

function CropProfilePic(props: iProps): JSX.Element {
  const { className, isOpen, file, isLoading, onClose, onCropImageHandler } =
    props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(0.8);
  const [fileDataUrl, setFileDataUrl] = useState<any>();
  const [croppedFile, setCroppedFile] = useState<File>(file);

  const onCropComplete = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      const croppedImage = await getCroppedImg(fileDataUrl, croppedAreaPixels);
      setCroppedFile(new File([croppedImage], file.name, { type: file.type }));
    },
    [fileDataUrl, file.name, file.type]
  );

  useEffect(() => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFileDataUrl(reader.result);
    };
    reader.readAsDataURL(file);
  }, [file]);

  return (
    <Modal
      isOpen={isOpen}
      isLoading={isLoading}
      heading="Crop your profile image"
      btnName="Use image"
      submit={() => {
        onCropImageHandler(croppedFile);
      }}
      onClose={onClose}
    >
      <div className={`${styles.cropProfilePic} ${className}`}>
        <div className={styles.sizeDragger}>
          <img className={styles.smallIcon} src={imageIcon} alt="" />
          <RangeSlider
            className={styles.slider}
            min={8}
            value={1}
            onChange={() => {}}
            onRealTimeChange={(range) => {
              if (range >= 8 && range <= 10) {
                setZoom(range / 10);
              } else if (range > 10) {
                setZoom(range / 10);
              }
              // if (range === 0 || range <= 10) setZoom(1);
            }}
          />
          <img className={styles.largeIcon} src={imageIcon} alt="" />
        </div>
        <Cropper
          classes={styles.cropper}
          image={fileDataUrl}
          crop={crop}
          zoom={zoom}
          showGrid={false}
          cropSize={{ width: 350, height: 350 }}
          cropShape="round"
          restrictPosition
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
    </Modal>
  );
}

CropProfilePic.defaultProps = {
  className: '',
};

export default CropProfilePic;

/* eslint-disable */
import AddTeammateModal from 'components/add-teammate-modal/addTeammateModal';
import Button, { ButtonVariants } from 'components/button/button';
import { ROUTES, whiteAddIcon } from 'constants/index';
import { ModalViews, Roles, UserRoles, UserSettingsView } from 'enums';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import {
  commonActions,
  homePageActions,
  layoutActions,
  userActions,
} from 'store/actions';
import { iState } from 'types';
import TeamMembersPermissions from '../../components/team-members-permissions/teamMembersPermissions';
import CompanyInformation from '../company-information/companyInformation';
import ContactInformation from '../contact-information/contactInformation';
import General from '../general/general';
import HeaderTitle from '../general/header-title/headerTitle';
import Integrations from '../Integrations/integrations';
// import Language from '../language/language';
// import Language from '../language/language';
import Navigators from '../navigators/navigators';
import Password from '../password/password';
import PersonalInformation from '../personal-information/personalInformation';
import ProfilePicture from '../profile-picture/profilePicture';
import TeamMember from '../team-members/teamMembers';
import TwoFactorAuthentication from '../two-factor-authentication/twoFactorAuthentication';
import ViewSwitchDropdown from '../view-switch-dropdown/viewSwitchDropdown';
import styles from './layout.module.scss';
import PasswordAndSecurity from './password-and-security/passwordAndSecurity';
import Profile from './profile/profile';
import AddTeamMembers from '../layout/add-team-members/addTeamMembers';
import { toaster } from 'helpers';
import { addTeamMember } from 'apis';
import { navigatorVariant } from 'modules/user/constants';

interface iProps {
  className?: string;
}

function Layout(props: iProps): JSX.Element {
  const { className } = props;

  const [state, dispatch]: [iState, any] = useStore();
  const { userSettings } = state.user || {};
  const { activeView } = userSettings || {};
  const { homePage } = state || {};
  const { companyData } = homePage || {};
  const [enableBtn, setEnableBtn] = useState(false);
  const isSuperAdmin = state.user.role === UserRoles.SuperAdmin;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { features } = companyData || {};
  const { addTeammates } = state.user.userPermissions || {};
  const [teammate, setTeammate] = useState({
    email: '',
    role: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const { allRoles = [] } = state.user || {};

  const isSubscribed = features?.accessManagment || false;

  useEffect(() => {
    // console.log(state.user.userSettings);
  }, [state]);
  useEffect(() => {
    if (!state.user.clientChoosed && isSuperAdmin) {
      // const foundSuperAdmin = allRoles?.find((role) => role.role_name === Roles.Super_admin)
      // if(foundSuperAdmin)
      dispatch(homePageActions.setCompanyData(null));
      navigate(ROUTES.chooseTeam);
    }
    if (!isSuperAdmin && !state.user.clientChoosed) {
      dispatch(userActions.setClientChoosed(false));
      navigate(ROUTES.chooseTeam);
    }
  }, [state.user.clientChoosed, pathname]);

  const selectedOption =
    (activeView === UserSettingsView.General && 'General') ||
    (activeView === UserSettingsView.TeamMembers && (
      <p>Team members for {companyData?.name}</p>
    )) ||
    (activeView === UserSettingsView.Integrations && 'Integrations') ||
    (activeView === UserSettingsView.Profile && 'Profile') ||
    (activeView === UserSettingsView.TeamMemberPermissions && 'Permissions') ||
    (UserSettingsView.PasswordAndSecurity && 'Password & Security');

  const displayAddTeammateBtn =
    // activeView === UserSettingsView.General ||
    activeView === UserSettingsView.TeamMembers;
  const onTeammateClick = useCallback(() => {
    if (isSuperAdmin || !isSubscribed || (isSubscribed && addTeammates)) {
      // dispatch(layoutActions.toggleModal(ModalViews.isShowAddTeammate));
      navigate(ROUTES.addTeamMates);
      return;
    }
    toaster.error('You do not have access for adding Team members');
  }, [dispatch]);

  const displayCancelAndSaveBtn = activeView === UserSettingsView.General;

  const displayCancelAndSetPermissionBtn = pathname === ROUTES.addTeamMates;

  const addTeammate = useCallback(() => {
    (async () => {
      if (!teammate.email) {
        toaster.error('Please enter a email address before proceeding.');
        return;
      }
      // if (teammate.role === '') {
      //   setErrorMessage('Please select a role from the dropdown');
      //   return;
      // }
      // console.log(teammate.email.split(','), 'all Rles');
      // const adminRole = Array.isArray(allRoles)
      //   ? allRoles.find((i) => Number(i.id) === 3)
      //   : null;

      // if (!adminRole) {
      //   toaster.error('Roles not found');
      //   return;
      // }
      const emailList = teammate.email.split(',');
      const uniqueEmails = [...new Set(emailList.map((email) => email.trim()))];
      const duplicateEmails = emailList.filter((email, index) => {
        return emailList.indexOf(email) !== index;
      });
      console.log(duplicateEmails, 'duplicateEmails');
      const invalidEmails = uniqueEmails.filter((email) => {
        return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
      });

      if (invalidEmails.length) {
        toaster.error(
          'Invalid email address. Please enter the email ids separated by comma.'
        );
        return;
      } else if (duplicateEmails.length) {
        toaster.error(
          'Duplicate email address detected! Please enter a unique email address'
        );
        return;
      } else if (emailList.some((email) => email.trim() === '')) {
        toaster.error('Email addresses cannot contain empty spaces');
        return;
      }
      const response = await addTeamMember(
        {
          email: teammate.email.split(','),
          companyId: state.homePage.companyData?.id || '',
          roleId: 3,
        },
        setIsLoading
      );
      if (response?.statusCode === 200) {
        // dispatch(layoutActions.toggleModal(ModalViews.isShowAddTeammate));
        navigate(ROUTES.userSettingsTeamMembers);
        toaster.success('Team member invited successfully');
        setTeammate({ email: '', role: '' });
      }
    })();

    // Navigate(ROUTES.joinYourTeam);
  }, [dispatch, state, teammate, allRoles]);

  return (
    <>
      <div className={`${styles.localLayout} ${className}`}>
        <div className={styles.sidebar}>
          <Navigators variant={navigatorVariant.desktopDropdown} />
        </div>
        <div className={styles.right}>
          {selectedOption === 'Permissions' ? null : (
            <div className={styles.subHeader}>
              <div>{selectedOption}</div>
              {displayAddTeammateBtn && !displayCancelAndSetPermissionBtn && (
                <div>
                  <Button
                    className={styles.screenzBtn}
                    variant={ButtonVariants.SmallStd}
                    btnName="Add Team Members"
                    icon={whiteAddIcon}
                    onClick={onTeammateClick}
                    isDisabled={!addTeammates}
                  />
                </div>
              )}
              {displayCancelAndSaveBtn || (false && <HeaderTitle isDisabled />)}
              {displayCancelAndSetPermissionBtn && (
                <div className={styles.btnContainer}>
                  <Button
                    className={styles.cancelBtn}
                    variant={ButtonVariants.ExSmallTransp}
                    btnName="Cancel"
                    onClick={() => {
                      navigate(ROUTES.userSettingsTeamMembers);
                      setTeammate({ email: '', role: '' });
                    }}
                  />
                  <Button
                    className={styles.screenzBtn}
                    variant={ButtonVariants.SmallStd}
                    btnName="Add Team Members"
                    onClick={addTeammate}
                    isLoading={isLoading}
                  />
                </div>
              )}
            </div>
          )}

          {activeView === UserSettingsView.TeamMemberPermissions && (
            <TeamMembersPermissions />
          )}
          {activeView === UserSettingsView.Integrations &&
            state.layout.windowWidth > 800 && <Integrations />}
          {activeView === UserSettingsView.General && <General />}
          {activeView === UserSettingsView.TeamMembers &&
            pathname !== ROUTES.addTeamMates && <TeamMember />}
          {activeView === UserSettingsView.Profile && <Profile />}
          {pathname === ROUTES.addTeamMates && (
            <AddTeamMembers teammate={teammate} setTeammate={setTeammate} />
          )}
          {activeView === UserSettingsView.PasswordAndSecurity && (
            <PasswordAndSecurity />
          )}
          <div></div>
          <AddTeammateModal />
        </div>
      </div>
      <div className={styles.responsiveContainer}>
        <ViewSwitchDropdown />
        {activeView === UserSettingsView.General && (
          <>
            <CompanyInformation />
            <ContactInformation />
          </>
        )}
        {activeView === UserSettingsView.Integrations &&
          state.layout.windowWidth < 800 && (
            <>
              <Integrations />
            </>
          )}
        {activeView === UserSettingsView.TeamMembers &&
          pathname !== ROUTES.addTeamMates && <TeamMember />}
        {activeView === UserSettingsView.Profile && (
          <>
            <PersonalInformation setEnableBtn={setEnableBtn} />
            <ProfilePicture setEnableBtn={setEnableBtn} enableBtn={enableBtn} />
            {/* <Language /> */}
          </>
        )}
        {activeView === UserSettingsView.PasswordAndSecurity && (
          <>
            <Password />
            <TwoFactorAuthentication />
          </>
        )}
        {pathname === ROUTES.addTeamMates && (
          <AddTeamMembers teammate={teammate} setTeammate={setTeammate} />
        )}
        <AddTeammateModal />
      </div>
    </>
  );
}

Layout.defaultProps = {
  className: '',
};

export default Layout;

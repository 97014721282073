import { addFolder } from 'apis';
import LabelAndInput from 'components/label-and-input/labelAndInput';
import Modal from 'components/modal/modal';
import { folder2Icon, ROUTES } from 'constants/index';
import { FolderType } from 'enums';
import { toaster } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from 'store';
import { layoutActions } from 'store/actions';
import { iState } from 'types';
import styles from './addFolderModal.module.scss';

function AddFolderModal(): JSX.Element {
  const [state, dispatch]: [iState, any] = useStore();
  const { companyData, screensActiveFolder } = state.homePage || {};
  const { playlistActiveFolder } = state.playlistsPage || {};
  const { contentActiveFolder } = state.contentPage || {};
  const [folderName, setFolderName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { pathname } = useLocation();
  const { isShowAddFolder = false } = state.layout || {};
  const { id: companyId } = companyData || {};

  const onChange = useCallback((event): void => {
    setFolderName(event.target.value);
  }, []);

  const isScreensPage = pathname === ROUTES.screenz || false;
  const isContentsPage = pathname === ROUTES.content || false;
  const isPlaylistPage = pathname === ROUTES.playlists || false;

  const invokeFolders = useCallback(() => {
    if (
      (isScreensPage && !screensActiveFolder) ||
      (isContentsPage && !contentActiveFolder) ||
      (isPlaylistPage && !playlistActiveFolder)
    )
      dispatch(layoutActions.invokeGetAllFolders(true));
    else dispatch(layoutActions.invokeGetFolderById(true));
  }, [
    dispatch,
    screensActiveFolder,
    contentActiveFolder,
    isContentsPage,
    isPlaylistPage,
    isScreensPage,
    playlistActiveFolder,
  ]);

  const addFolderHandler = useCallback(() => {
    if (!companyId) {
      toaster.error('Something went wrong');
      return;
    }
    if (!folderName) {
      setErrorMessage('Please enter folder name');
      return;
    }
    const folderType =
      (isContentsPage && FolderType.CONTENT) ||
      (isPlaylistPage && FolderType.PLAYLIST) ||
      (isScreensPage && FolderType.SCREENZ);

    const parentId =
      (isScreensPage && screensActiveFolder?.id) ||
      (isPlaylistPage && playlistActiveFolder?.id) ||
      (isContentsPage && contentActiveFolder?.id) ||
      null;

    if (folderType) {
      (async () => {
        const { companyUserId } = companyData || {};
        const response = await addFolder(
          {
            companyId,
            name: folderName,
            folderType,
            parentId,
            companyUserId: companyUserId || '',
            userId: state.user.loggedInData?.id || '',
          },
          setIsLoading
        );
        if (response) {
          dispatch(layoutActions.toggleModal());
          // dispatch(layoutActions.invokeGetAllFolders(true));
          invokeFolders();
          setFolderName('');
          toaster.success('Folder added successfully');
          // dispatch(homePageActions.addFolder(folderName, foldersAndSubFolders));
        }
      })();
    }
  }, [
    dispatch,
    folderName,
    companyId,
    contentActiveFolder?.id,
    isContentsPage,
    isPlaylistPage,
    isScreensPage,
    playlistActiveFolder?.id,
    screensActiveFolder?.id,
    invokeFolders,
  ]);

  const closeModalHandler = useCallback(() => {
    dispatch(layoutActions.toggleModal());
    setErrorMessage('');
    setFolderName('');
  }, [dispatch]);

  useEffect(() => {
    const inputElement = document.getElementById('addFolderName');
    if (inputElement && isShowAddFolder) {
      inputElement.focus();
    }
  }, [isShowAddFolder]);

  return (
    <Modal
      isOpen={isShowAddFolder}
      heading="Create Folder"
      btnName="Add Folder"
      headingIcon={folder2Icon}
      onClose={closeModalHandler}
      submit={addFolderHandler}
      isLoading={isLoading}
    >
      <div className={styles.addFolderModal}>
        <div className={styles.labelAndInput}>
          <LabelAndInput
            className={styles.input}
            name="name"
            id="addFolderName"
            label={errorMessage && `Folder Name`}
            placeholder="Give a folder name"
            value={folderName}
            onChange={onChange}
            errorMessage={errorMessage}
          />
          {/* <Input
            id="addFolderName"
            className={styles.input}
            name="name"
            placeholder={errorMessage || 'Give a folder name'}
            value={folderName}
            onChange={onChange}
            isError={errorMessage !== ''}
          /> */}
        </div>
      </div>
    </Modal>
  );
}

export default AddFolderModal;

import Popover from 'components/popover/popover';
import { caretFilledSmallDown, checkCircleIcon } from 'constants/index';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './timePicker.module.scss';

interface iProps {
  className?: string;
  value: number;
  onClick: (event, _: number) => void;
  isDisabled?: boolean;
}

function TimePicker(props: iProps): JSX.Element {
  const { className, value, isDisabled = false, onClick } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [canClose, setCanClose] = useState<boolean>(false);
  const id = Date.now() * Math.random();

  useEffect(() => {
    const element = document.getElementById(`${id}`);
    if (element) {
      if (isOpen) {
        element.focus();
      }
    }
  }, [isOpen, id]);

  const onKeyUp = useCallback(
    (event: any): void | null => {
      //  console.log('event.key', event.key);
      event.stopPropagation();
      if (Number(event.target.value) > 60 && event.key !== 'Backspace') {
        event.preventDefault();
        event.target.value = '';
      }
      if (event.key === 'Enter') {
        onClick(event, Number(event.target.value));
        setCanClose(true);
      }
    },
    [onClick]
  );

  return (
    <Popover
      className={className}
      isDisabled={isDisabled}
      setPopoverEffect={(status) => {
        setIsOpen(status);
        setCanClose(false);
      }}
      canCloseOnClick
      closePopover={canClose}
      popoverClassName={styles.timePickerPopup}
      popoverComponent={
        <div className={styles.secondsAndMinutes}>
          <input
            id={`${id}`}
            className={styles.timeInput}
            placeholder="Set"
            type="number"
            min="0"
            maxLength={2}
            onKeyUp={onKeyUp}
            // onBlur={(event) => {
            //   event.stopPropagation();
            //   //  console.log('event.target.value_____', event.target.value);
            //   onClick(event, Number(event.target.value));
            // }}
          />
          <hr className={styles.devider} />
          <ul>
            {[...Array(61)].map((i, index) => (
              <li
                className={value > 0 && index === value && styles.active}
                onClick={(event) => onClick(event, index)}
              >
                {index < 10 ? `0${index}` : index}{' '}
                {value > 0 && index === value && (
                  <img src={checkCircleIcon} alt="" />
                )}
              </li>
            ))}
          </ul>
        </div>
      }
    >
      <div
        className={`${styles.timePicker} ${
          isOpen && styles.opened
        } ${className}`}
      >
        <span>{value < 10 ? `0${value}` : value}</span>
        {!isDisabled && (
          <img
            className={styles.dropdownIcon}
            src={caretFilledSmallDown}
            alt=""
          />
        )}
      </div>
    </Popover>
  );
}

TimePicker.defaultProps = {
  className: '',
  isDisabled: false,
};

export default TimePicker;

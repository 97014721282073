import Modal from 'components/modal/modal';
import { templatesGreyIcon, ROUTES } from 'constants/index';
import React, { useCallback } from 'react';
import { useStore } from 'store';
import { useNavigate } from 'react-router-dom';
import { layoutActions, office365Actions } from 'store/actions';
import { iState } from 'types';
import { EventTemplateType } from 'enums';
import styles from './addTemplate.module.scss';
import { meetingRooms } from './constants';

function AddTemplateModal(): JSX.Element {
  const [state, dispatch]: [iState, any] = useStore();
  const { isShowAddTemplate = false } = state.layout || {};
  const navigate = useNavigate();

  const addTemplateHandler = useCallback(
    (templateType: EventTemplateType) => {
      navigate(ROUTES.calenderEvents);
      dispatch(layoutActions.setTemplateToView(templateType));
      dispatch(office365Actions.setChosenTemplateType(templateType));
      dispatch(layoutActions.toggleModal());
    },
    [navigate, dispatch]
  );

  const closeModalHandler = useCallback(() => {
    dispatch(layoutActions.toggleModal());
  }, [dispatch]);

  return (
    <Modal
      className={styles.modalStyles}
      isOpen={isShowAddTemplate}
      heading="Add Template"
      btnName=""
      isShowFooter={false}
      headingIcon={templatesGreyIcon}
      onClose={closeModalHandler}
      submit={() => {}}
    >
      <div className={styles.addTemplateModal}>
        {meetingRooms &&
          meetingRooms.map((item) => (
            <div
              className={styles.template}
              key={item.id}
              onClick={() => addTemplateHandler(item.eventTemplateType)}
            >
              <div className={styles.templatePreview}>
                <img
                  className={`${styles.standardMeetingRoom} ${
                    (item.eventTemplateType === EventTemplateType.meetingRoom ||
                      item.eventTemplateType ===
                        EventTemplateType.meetingOverviewPotrait) &&
                    styles.extraWidth
                  }`}
                  src={item.icon}
                  alt=""
                />
              </div>
              <p className={styles.name}>{item.name}</p>
              <p className={styles.description}>{item.message}</p>
            </div>
          ))}
      </div>
    </Modal>
  );
}

export default AddTemplateModal;

import Input from 'components/input/input';
import { cancelIcon, searchIcon } from 'constants/index';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './searchInput.module.scss';

interface iProps {
  className?: string;
  searchKey: string;
  setSearchKey: (type: string) => void;
  isShowClear?: boolean;
}

function Search(props: iProps): JSX.Element {
  const { className, isShowClear, searchKey, setSearchKey } = props;
  const [isFocused, setIsFocused] = useState(false);
  //   const [state, dispatch]: [iState, any] = useStore();
  const containerRef: any = useRef(null);

  const onSearch = useCallback((event) => {
    if (setSearchKey) setSearchKey(event.target.value);
  }, []);

  const onClear = useCallback(() => {}, []);

  const clickOutSide = useCallback(
    (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        if (isFocused && !searchKey) setIsFocused(false);
      }
    },
    [isFocused, onClear, searchKey]
  );

  useEffect(() => {
    document.addEventListener('click', clickOutSide);
    return () => document.removeEventListener('click', clickOutSide);
  }, [clickOutSide, searchKey]);

  return (
    <div
      className={`${className} ${styles.search} ${isFocused && styles.open} ${
        isFocused && styles.focused
      }
      `}
      ref={containerRef}
    >
      <img
        className={`${styles.searchIcon} ${isFocused && styles.open}`}
        src={searchIcon}
        alt=""
        onClick={() => setIsFocused(true)}
      />
      <Input
        id="searchInput"
        className={`${styles.input} ${isFocused && styles.open}`}
        value={searchKey}
        placeholder="Search"
        onChange={onSearch}
        onFocus={() => setIsFocused(true)}
        // onKeyUp={onKeyUp}
      />
      {isShowClear && (
        <img
          className={styles.cancelIcon}
          src={cancelIcon}
          alt=""
          onClick={onClear}
        />
      )}
    </div>
  );
}

Search.defaultProps = {
  className: '',
  isShowClear: false,
};

export default Search;

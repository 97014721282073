import { folderIcon, rootFolderId } from 'constants/index';
import ShareAccess from 'components/share-access/shareAccess';
import React from 'react';
import CurrentFolder, {
  CurrentFolderVariants,
} from 'components/current-folder/currentFolder';
import PrimaryDetailsSkeleton from 'components/skeletons/primary-details/primaryDetails';
import { iState } from 'types';
import { useStore } from 'store';
import { FolderType, itemType } from 'enums';
import styles from './playlistDetails.module.scss';

interface iProps {
  className?: string;
  isLoading: boolean;
}

function PlaylistDetails(props: iProps): JSX.Element {
  const { className, isLoading } = props;

  const [state]: [iState] = useStore();
  const { playlistActiveFolder } = state.playlistsPage || {};

  return (
    <div className={`${className} ${styles.playlistDetails}`}>
      {isLoading ? (
        <PrimaryDetailsSkeleton />
      ) : (
        <>
          {playlistActiveFolder && (
            <CurrentFolder variant={CurrentFolderVariants.Playlists} />
          )}
          {!playlistActiveFolder && (
            <>
              <img className={styles.folderIcon} src={folderIcon} alt="" />
              <p className={styles.paragraphOne}>Your Playlists</p>
            </>
          )}
          <ShareAccess
            className={styles.sharePlaylist}
            shareType={itemType.FOLDER}
            folderType={FolderType.PLAYLIST}
            heading={playlistActiveFolder?.name || 'Playlist'}
            shareItemId={playlistActiveFolder?.id || rootFolderId}
          />
        </>
      )}
    </div>
  );
}

PlaylistDetails.defaultProps = {
  className: '',
};

export default PlaylistDetails;

import React from 'react';
import Modal from 'components/modal/modal';
import { iState } from 'types';
import { useStore } from 'store';
import { layoutActions, playlistPageActions } from 'store/actions';
import { screenRotation } from 'constants/index';
import { ScreenType } from 'enums';
import OrientationAndSize from 'components/orientation-and-size/orientationAndSize';
import styles from './editResolutionModal.module.scss';

interface iProps {
  className?: string;
}

function EditResolutionModal(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { isShowEditPlaylistResolution } = state.layout;
  const { playlistData } = state.playlistsPage || {};

  const screenType =
    (playlistData?.screenType && ScreenType[playlistData?.screenType]) ||
    ScreenType.HORIZONTAL;

  return (
    <Modal
      isOpen={isShowEditPlaylistResolution}
      btnName="Save changes"
      heading="Edit Playlist Size"
      headingIcon={screenRotation}
      onClose={() => {
        dispatch(layoutActions.toggleModal());
      }}
      submit={() => {
        dispatch(playlistPageActions.invokeUpdatePlaylist(true));
      }}
    >
      <div className={`${styles.editResolutionModal} ${className}`}>
        <OrientationAndSize
          activeScreenTpe={screenType}
          onClickOrientation={(choosedScreenType) => {
            if (playlistData)
              dispatch(
                playlistPageActions.setPlaylistData({
                  ...playlistData,
                  screenType: choosedScreenType,
                })
              );
          }}
        />
      </div>
    </Modal>
  );
}

EditResolutionModal.defaultProps = {
  className: '',
};

export default EditResolutionModal;

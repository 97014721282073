import { Mutation, Query } from 'config';
import { toaster } from 'helpers';
import { captureException } from 'helpers/sentry';
import {
  iAddFolderArgs,
  iAddFolderResponse,
  iFolderData,
  iGraphqlResponse,
  iGetAllFoldersResponse,
  iGetAllFolderArgs,
  iGetFolderByIdArgs,
  iGetFolderByIdResponse,
  iDeleteFolderResponse,
  iDeleteFolderData,
  iUpdateFolderResponse,
  iUpdateFolderData,
  iUpdateFolderArgs,
} from 'types';
import {
  addFolderMutation,
  getAllFoldersQuery,
  getFolderByIdQuery,
  deleteFolderMutation,
  updateFolderMutation,
} from '../graphql';

export const addFolder = async (
  args: iAddFolderArgs,
  isLoading
): Promise<iFolderData | null> => {
  try {
    isLoading(true);
    const response = await (<iGraphqlResponse<iAddFolderResponse>>(
      await Mutation(addFolderMutation, args)
    ));
    isLoading(false);
    return response.data.addFolder.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    console.log(error.message);
    return null;
  }
};

export const getAllFolders = async (
  args: iGetAllFolderArgs,
  isLoading
): Promise<Array<iFolderData> | null> => {
  try {
    isLoading(true);
    const response = await (<iGraphqlResponse<iGetAllFoldersResponse>>(
      await Query(getAllFoldersQuery, args)
    ));
    isLoading(false);
    return response.data.getFoldersByCompany.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    toaster.error(error.message);
    return null;
  }
};

export const getFolderById = async (
  args: iGetFolderByIdArgs,
  isLoading
): Promise<iFolderData | null> => {
  try {
    isLoading(true);
    const response = await (<iGraphqlResponse<iGetFolderByIdResponse>>(
      await Query(getFolderByIdQuery, args)
    ));
    isLoading(false);
    return response.data.getFolderbyid.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    toaster.error(error.message);
    return null;
  }
};

export const deleteFolder = async (
  id: string,
  isLoading?: (a: boolean) => void
): Promise<iDeleteFolderData | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = await (<iGraphqlResponse<iDeleteFolderResponse>>(
      await Mutation(deleteFolderMutation, { id })
    ));
    if (isLoading) isLoading(false);
    return response.data.deleteFolder.data;
  } catch (error: any) {
    if (isLoading) isLoading(false);
    captureException(error);
    toaster.error(error.message);
    return null;
  }
};

export const updateFolder = async (
  args: iUpdateFolderArgs,
  isLoading?: (a: boolean) => void
): Promise<iUpdateFolderData | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = await (<iGraphqlResponse<iUpdateFolderResponse>>(
      await Mutation(updateFolderMutation, args)
    ));
    if (isLoading) isLoading(false);
    return response.data.updateFolder.data;
  } catch (error: any) {
    if (isLoading) isLoading(false);
    captureException(error);
    toaster.error(error.message);
    return null;
  }
};

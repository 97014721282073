import { gql } from 'apollo-boost';

export enum Content {
  AddContent = 'addContent',
  UpdateContent = 'updateContent',
  GetContentByCompanyId = 'getcontentbyCompanyid',
  SearchContentByName = 'searchContentbyname',
  DeleteContent = 'deleteContent',
  ValidateUrl = 'validateUrl',
  Getcontentbyid = 'getcontentbyid',
}

export const getContentByCompanyQuery = gql`
  query ${Content.GetContentByCompanyId}($company: String!, $folder:String $companyUserId: String){
    ${Content.GetContentByCompanyId}(company: $company, folder: $folder companyUserId: $companyUserId){
      statusCode
      message
      data {
        id
        name
        dimension
        url
        orientation
        contentType
        size
        thumbnailPath
        updated_at
        templateData{
          id
          name
          templateType
        }
        user {
          id
          first_name
          last_name
          email
        }
        company {
          email
          address
          city
          postal_code
          org_no
          phone_number
          country_code
          id
        }
      folder {
        id
        url
        parentFolders {
          id
          name
        }
        folder_path
        name
        folderType
        subFolders {
          id 
          url
          parentFolders {
            id
            name
					}
          company
          folder_path
          name
          folderType
        }
      }
      }
    }
  }
`;

export const addContentMutation = gql`
    mutation ${Content.AddContent}(
      $name: String!
      $url: String!
      $contentType: String
      $size: String
      $dimension: String
      $orientation: ScreenType
      $folder: String
      $thumbnailPath: String
      $company: String!
      $user: String!
      ){
        ${Content.AddContent}(
          name: $name
          url: $url
          contentType: $contentType
          size: $size
          dimension: $dimension
          orientation: $orientation
          folder: $folder
          thumbnailPath: $thumbnailPath
          company: $company
          user: $user
          ){
            message
            statusCode
            data {
              id
              name
              url
              contentType
              size
              dimension
              folder{
                id
              }
              thumbnailPath
            }
        }
    }
`;

export const editWebContentMutation = gql`
  mutation ${Content.UpdateContent}(
    $id: String!
    $name: String
    $size: String
    $orientation: ScreenType
    $dimension: String
    $url: String!
    ) {
    ${Content.UpdateContent}(
      id: $id
      name: $name
      size: $size
      orientation: $orientation
      dimension: $dimension
      url: $url
    ) {
      statusCode
      message
      data {
        id
        name
      }
    }
  }
`;

export const searchContentMutation = gql`
    mutation ${Content.SearchContentByName}(
      $name: String
      $company: String!
      $folder: String
      ){
        ${Content.SearchContentByName}(
          name: $name
          company: $company
          folder: $folder
          ){
            message
            statusCode
            data {
              id
              name
              url
              contentType
              size
              dimension
              orientation
              thumbnailPath
              user {
                id
                first_name
                last_name
              }
              updated_at
              templateData {
                id
                name
                templateType
                contents{
                  id
                  room{
                    id
                    roomid
                    name
                    custom_name
                    address
                    geoCoordinates
                    phone
                    nickname
                    emailAddress
                    building
                    floorNumber
                    floorLabel
                    label
                    capacity
                    bookingType
                    audioDeviceName
                    videoDeviceName
                    displayDeviceName
                    tags
                    status
                  }
                  contentType
                  position
                  details {
                    roomDetails
                    showSubject
                    showHostName
                    showNextMeeting
                    contentAlignment
                    timezone
                    imageUrl
                  }
                }
                details{
                  backgroundImageUrl
                  logoUrl
                  ActivateBooking
                  leftAreaImageUrl
                  leftAreaContetType
                  leftAreaText {
                    preTitle
                    title
                    body
                    subText
                  }
                }
              }
            }
        }
    }
`;

export const deleteContentMutation = gql`
  mutation ${Content.DeleteContent}($id: String!, $deleteContent: Boolean!){
    ${Content.DeleteContent}(id: $id, deleteContent: $deleteContent){
      statusCode
      message
      data {
        isDeleted
        screenz {
          name
        }
        playLists {
          name
        }
      }
    }
  }
`;

export const validateUrlQuery = gql`
  query ${Content.ValidateUrl}($url: String!) {
    ${Content.ValidateUrl}(url: $url) {
      statusCode
      message
      data {
        valid
      }
    }
  }
`;

export const getContentByIdQuery = gql`
  query ${Content.Getcontentbyid}($id: String!) {
    ${Content.Getcontentbyid}(id: $id) {
      statusCode
      message
      data {
        id
        name
        url
        contentType
        orientation
        size
        dimension
        folder{
          id
        }
        updated_at
        company {
          email
          address
          city
          name
          postal_code
          org_no
          phone_number
          country_code
          id
          created_at
          updated_at
        }
        user {
          id
          first_name
          last_name
          email
        }
        duration
        sortOrder
        playlistContentId
        thumbnailPath
        templateData {
          id
          name
          templateType
          language
          opacity
          contents{
            id
            room{
              id
              roomid
              name
              custom_name
              address
              geoCoordinates
              phone
              nickname
              emailAddress
              building
              floorNumber
              floorLabel
              label
              capacity
              bookingType
              audioDeviceName
              videoDeviceName
              displayDeviceName
              tags
              status
            }
            contentType
            position
            details {
              roomDetails
              showSubject
              showHostName
              showNextMeeting
              contentAlignment
              timezone
              imageUrl
            }
          }
          details{
            backgroundImageUrl
            logoUrl
            ActivateBooking
            leftAreaImageUrl
            leftAreaContetType
            leftAreaText {
              preTitle
              title
              body
              subText
            }
          }
        }
      }
    }
  }
`;

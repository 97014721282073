import React from 'react';
import Radio from 'components/radiobutton/radioButton';
import { ScreenType } from 'enums';
import Screen from 'components/svg/screenz';
import PortraitScreen from 'components/svg/portraitScren';
// import Settings from 'components/svg/settings';
import styles from './orientationAndSize.module.scss';

interface iProps {
  className?: string;
  activeScreenTpe?: ScreenType;
  onClickOrientation?: (a: ScreenType) => void;
}

const ORIENTATIONS = [
  { type: 'Landscape', value: ScreenType.HORIZONTAL, icon: Screen },
  { type: 'Portrait', value: ScreenType.VERTICAL, icon: PortraitScreen },
];

function OrientationAndSize(props: iProps): JSX.Element {
  const {
    className,
    onClickOrientation,
    activeScreenTpe = ScreenType.HORIZONTAL,
  } = props;
  //  console.log('activeScreenTpe_____', activeScreenTpe);

  // const [orientation, setOrientation] = useState<ScreenType>(activeScreenTpe);
  // const [customResolution, setCustomResolution] = useState<{
  //   height: null | number;
  //   width: null | number;
  // }>({
  //   height: null,
  //   width: null,
  // });

  // const isCustom = orientation === Orientations.Custom;

  return (
    <div className={`${styles.sizeAndOrientation} ${className}`}>
      <div className={styles.btnContainer}>
        {ORIENTATIONS.map((i) => {
          const Icon = i.icon;

          return (
            <div
              className={styles.orientationBtn}
              onClick={() => {
                // setCustomResolution({ height: null, width: null });
                if (onClickOrientation) onClickOrientation(i.value);
              }}
            >
              <div
                className={`${styles.radioBtn} ${
                  activeScreenTpe === i.value && styles.active
                }`}
              >
                <Radio
                  value=""
                  isChecked={activeScreenTpe === i.value}
                  onChange={() => {}}
                />
              </div>
              <div
                className={`${styles.screenType} ${
                  activeScreenTpe === i.value && styles.active
                }`}
              >
                <Icon className={styles.screenIcon} />
                <span className={styles.orientationType}>{i.type}</span>
              </div>
            </div>
          );
        })}
      </div>

      {/* <div
        className={`${styles.orientationBtn} ${isCustom && styles.active}`}
        onClick={() => {
          // setCustomResolution({ height: 1080, width: 1920 });
          setOrientation(Orientations.Custom);
        }}
      >
        <div className={`${styles.radioBtn} ${isCustom && styles.active}`}>
          <Radio value="" isChecked={isCustom} onChange={() => {}} />
        </div>

        <div
          className={`${styles.screenType} ${isCustom && styles.active} ${
            styles.customResolution
          }`}
        >
          <div
            className={`${styles.customHeading} ${isCustom && styles.active}`}
          >
            <Settings className={`${styles.screenIcon} `} />
            <span className={styles.orientationType}>Custom</span>
          </div>

          <div className={styles.resolutionContainer}>
            <div className={styles.widthContainer}>
              <p
                className={`${styles.heighWithLabel} ${
                  isCustom && styles.active
                }`}
              >
                Width
              </p>
              <input
                className={`${styles.resolutionInput} ${
                  isCustom && styles.active
                }`}
                disabled={!isCustom}
                placeholder=""
                type="text"
                value={customResolution.width || ''}
              />
            </div>
            <div className={styles.heightContainer}>
              <p
                className={`${styles.heighWithLabel} ${
                  isCustom && styles.active
                }`}
              >
                Height
              </p>
              <input
                className={`${styles.resolutionInput} ${
                  isCustom && styles.active
                }`}
                disabled={!isCustom}
                placeholder=""
                type="text"
                value={customResolution.height || ''}
              />
            </div>
          </div>
        </div> 
      </div> */}
    </div>
  );
}

OrientationAndSize.defaultProps = {
  className: '',
  activeScreenTpe: ScreenType.HORIZONTAL,
  onClickOrientation: () => {},
};

export default OrientationAndSize;

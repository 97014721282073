import Button, { ButtonVariants } from 'components/button/button';
import Popover from 'components/popover/popover';
import {
  caretFilledSmallDown,
  delete2Icon,
  duplicateIcon,
  eyeCrossed,
  eyeIcon,
  ROUTES,
} from 'constants/index';
import { ScreenType, SideDrawers } from 'enums';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { layoutActions, playlistPageActions } from 'store/actions';
import { iState } from 'types';
import { addPlaylist, getPlaylistsById } from 'apis';
import { calculateContentRuntime, toaster } from 'helpers';
import styles from './header.module.scss';

interface iProps {
  className?: string;
  latePlaylistName?: boolean;
}

function Header(props: iProps): JSX.Element {
  const { className, latePlaylistName } = props;
  const navigate = useNavigate();
  const [popOverEffect, setPopoverEffect] = useState(false);
  const [state, dispatch]: [iState, any] = useStore();
  const {
    playlistData,
    isPlaylistSaveAndCloseBtnDisabled,
    canUpdatePlaylist,
    playlistActiveFolder,
  } = state.playlistsPage || {};
  const { isShowPlaylistPreview } = state.layout || {};
  const [playlistName, setPlaylistName] = useState<string>(
    playlistData?.name || ''
  );
  const { companyData } = state.homePage;
  const { id: companyId } = companyData || {};
  const { deletePlayListAndFolder } = state.user.userPermissions || {};

  useEffect(() => {
    setPlaylistName(playlistData?.name || '');
  }, [canUpdatePlaylist, playlistData]);

  const updatePlaylistHandler = useCallback(() => {
    dispatch(playlistPageActions.invokeUpdatePlaylist(true));
  }, [dispatch]);

  const cancel = useCallback(() => {
    dispatch(playlistPageActions.setPlaylistsActiveFolder(null));
    navigate(ROUTES.playlists);
  }, [navigate]);

  const togglePlaylist = useCallback(() => {
    if (!playlistData) return;
    const { playListContents } = playlistData || {};

    if (isShowPlaylistPreview) {
      dispatch(layoutActions.toggleSideDrawer());
      dispatch(playlistPageActions.setPlaylistPreview(null));
    } else {
      dispatch(
        layoutActions.toggleSideDrawer(SideDrawers.isShowPlaylistPreview)
      );
      if (Array.isArray(playListContents))
        dispatch(playlistPageActions.setPlaylistPreview(playListContents));
    }
  }, [dispatch, playlistData, isShowPlaylistPreview]);

  const onClickDelete = useCallback(() => {
    dispatch(playlistPageActions.invokeDeletePlaylist(true));
  }, [dispatch]);

  const playlistDuration = calculateContentRuntime(
    playlistData?.playListContents
  );

  const getPlaylist = useCallback(
    async (responseID) => {
      const response = await getPlaylistsById({ id: responseID }, () => {});
      if (response) {
        toaster.success('Duplicate Playlist created Successfully');
        dispatch(playlistPageActions.setPlaylistData(response));
        dispatch(playlistPageActions.addPlaylist(response?.name));
      }
    },
    [
      companyId,
      dispatch,
      playlistData,
      navigate,
      calculateContentRuntime,
      playlistActiveFolder?.id,
      playlistDuration,
    ]
  );

  const duplicatePlaylistHandler = useCallback(async () => {
    if (!companyId) {
      toaster.error('Company data not found');
      return;
    }
    if (!playlistData) {
      toaster.error('Playlist data not found');
      return;
    }

    const { screenType } = playlistData || {};

    const response = await addPlaylist(
      {
        // ...playlistData,
        dimention: playlistData.dimention,
        duration: playlistDuration,
        // id: playlistData.id,
        // playListContents: playlistData.playListContents,

        contents:
          (playlistData.playListContents &&
            playlistData.playListContents.map((i) => ({
              id: i.id,
              duration: i.duration,
              sortOrder: i.sortOrder,
            }))) ||
          [],
        companyId,
        name: `${playlistData.name} copy`,
        folderId: playlistActiveFolder?.id || null,
        screenType:
          screenType === 'HORIZONTAL'
            ? ScreenType.HORIZONTAL
            : ScreenType.VERTICAL,
        userId: state.user.loggedInData?.id || '',
      },
      () => {}
    );
    if (response) {
      getPlaylist(response.id);
      // const responsePlaylist = await getAllPlaylists(
      //   {
      //     companyId,
      //     folderId: playlistActiveFolder?.id || null,
      //     companyUserId: companyUserId || '',
      //   },
      //   () => {}
      // );
      // if (responsePlaylist) {
      //   toaster.success('Duplicate Playlist created Successfully');
      //   const duplicatePlaylist = responsePlaylist.find(
      //     (plylist) => plylist.id === response.id
      //   );
      //   if (duplicatePlaylist)
      //     dispatch(playlistPageActions.setPlaylistData(duplicatePlaylist));
      //   if (duplicatePlaylist?.name)
      //     dispatch(playlistPageActions.addPlaylist(duplicatePlaylist?.name));
      // }
    }
  }, [
    companyId,
    dispatch,
    playlistData,
    navigate,
    calculateContentRuntime,
    playlistActiveFolder?.id,
    playlistDuration,
  ]);

  return (
    <div className={`${styles.playlistViewHeader} ${className}`}>
      <div className={styles.colOne}>
        <p className={styles.playlistName}>
          {playlistName.length > 65
            ? `${playlistName.slice(0, 65)}...`
            : playlistName}
        </p>
      </div>
      <Button
        className={styles.saveBtn}
        btnName="Save"
        // isDisabled
        isActive={latePlaylistName}
        variant={ButtonVariants.ChangeView}
        onClick={updatePlaylistHandler}
      />
      {/* <Button
        className={styles.saveBtn}
        variant={ButtonVariants.ChangeView}
        btnName="Save"
        // isActive
        onClick={updatePlaylistHandler}
      /> */}
      <div className={styles.colTwo}>
        {/* <ColorPicker /> */}
        <Button
          className={`${styles.eyeBtn} ${styles.headerBtns}`}
          variant={ButtonVariants.SmallTransp}
          icon={isShowPlaylistPreview ? eyeCrossed : eyeIcon}
          onClick={togglePlaylist}
        />
        <Popover
          popoverClassName={styles.morePopover}
          popoverComponent={
            <div className={styles.moreWrapper}>
              <div className={styles.mutateOption}>
                <img src={duplicateIcon} alt="" />
                <p
                  onClick={() =>
                    // dispatch(
                    //   layoutActions.toggleModal(ModalViews.isShowAddPlaylist)
                    // )
                    duplicatePlaylistHandler()
                  }
                >
                  Duplicate playlist
                </p>
              </div>
              {deletePlayListAndFolder === true ? (
                <div className={styles.mutateOption} onClick={onClickDelete}>
                  <img src={delete2Icon} alt="" />
                  <p>Delete playlist</p>
                </div>
              ) : null}
            </div>
          }
          setPopoverEffect={setPopoverEffect}
          popOverEffect={popOverEffect}
        >
          <Button
            className={`${styles.saveAndCloseBtn} ${styles.moreBtn} ${
              styles.headerBtns
            } ${popOverEffect && styles.activeMore}`}
            variant={ButtonVariants.SmallTransp}
            btnName="More"
            icon={caretFilledSmallDown}
            iconPosition="right"
          />
        </Popover>
        <Button
          className={`${styles.saveAndCloseBtn} ${styles.headerBtns}`}
          variant={ButtonVariants.SmallTransp}
          btnName="Cancel"
          onClick={cancel}
        />
        <Button
          className={`${styles.saveAndCloseBtn}`}
          variant={ButtonVariants.SmallStd}
          isActive={false}
          btnName="Save and close"
          onClick={updatePlaylistHandler}
          isDisabled={isPlaylistSaveAndCloseBtnDisabled}
        />
      </div>
    </div>
  );
}

Header.defaultProps = {
  className: '',
  latePlaylistName: false,
};

export default Header;

import React from 'react';
import styles from './runningState.module.scss';

interface iProps {
  className?: string;
  status: string;
}

function RunningStatus(props: iProps): JSX.Element {
  const { className, status } = props;

  return (
    <div
      className={`${className} ${styles.runningStatus} ${
        status && styles.inUse
      }`}
    >
      {status ? 'IN USE' : 'NOT IN USE'}
    </div>
  );
}

RunningStatus.defaultProps = {
  className: '',
};

export default RunningStatus;

import { companyIcon, userIcon } from 'constants/index';
import { UserSettingsView } from 'enums';

export const SETTING_DESKTOP_NAV = [
  {
    id: 1,
    heading: 'Your Company',
    icon: companyIcon,
    options: [
      {
        id: '1.1',
        option: 'General',
        view: UserSettingsView.General,
      },
      {
        id: '1.2',
        option: 'Team Members',
        view: UserSettingsView.TeamMembers,
        relatedView: UserSettingsView.TeamMemberPermissions,
      },
      {
        id: '1.3',
        option: 'Integrations',
        view: UserSettingsView.Integrations,
      },
      // {
      //   id: '1.4',
      //   option: 'Add Team Members',
      //   view: UserSettingsView.AddTeamMembers,
      // },
    ],
  },
  {
    id: 2,
    heading: 'Your Info',
    icon: userIcon,
    options: [
      {
        id: '2.1',
        option: 'Profile',
        view: UserSettingsView.Profile,
      },
      {
        id: '2.2',
        option: 'Password And Security',
        view: UserSettingsView.PasswordAndSecurity,
      },
    ],
  },
];

export const SETTING_MOBILE_NAV = [
  {
    id: 1,
    heading: 'Your Company',
    icon: companyIcon,
    options: [
      {
        id: '1.1',
        option: 'General',
        view: UserSettingsView.General,
      },
      {
        id: '1.2',
        option: 'Team Members',
        view: UserSettingsView.TeamMembers,
        relatedView: UserSettingsView.TeamMemberPermissions,
      },
    ],
  },
  {
    id: 2,
    heading: 'Your Info',
    icon: userIcon,
    options: [
      {
        id: '2.1',
        option: 'Profile',
        view: UserSettingsView.Profile,
      },
      {
        id: '2.2',
        option: 'Password And Security',
        view: UserSettingsView.PasswordAndSecurity,
      },
    ],
  },
];

export enum navigatorVariant {
  mobileDropDown = 'mobileDropDown',
  desktopDropdown = 'desktopDropdown',
}

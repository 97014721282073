import { getAllFolders, getFolderById } from 'apis';
import ClientNameAndFolderPath from 'components/client-name-and-folder-path/clientNameAndFolderPath';
import SideDrawer from 'components/side-drawer/sideDrawer';
import { ROUTES } from 'constants/index';
import { FolderType, UserRoles, UserSettingsView } from 'enums';
import ClientsDropdown from 'modules/home/components/super-user-admin/clientsdropdownList/clientsListDropdown';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from 'store';
import {
  contentPageActions,
  homePageActions,
  layoutActions,
  playlistPageActions,
} from 'store/actions';
import { iState } from 'types';
import Header from './components/header/header';
import RoleBasedHeader from './components/role-based-header/roleBasedHeader';
import Sidebar from './components/sidebar/sidebar';
import SubHeader from './components/sub-header/subHeader';
import SubheaderTwo from './components/subheader-two/subheaderTwo';
import styles from './layout.module.scss';
import { LayoutVariants, variants } from './variants';

interface iProps {
  className?: string;
  children: JSX.Element;
  variant: LayoutVariants;
}

function Layout(props: iProps): JSX.Element {
  const { className, children, variant } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { user } = state || {};
  const { companyData, screensActiveFolder } = state.homePage || {};
  const { contentActiveFolder } = state.contentPage || {};
  const { playlistActiveFolder } = state.playlistsPage || {};
  const { invokeGetAllFolders, invokeGetFolderById } = state.layout || {};

  const { id: activeScreenFolderId } = screensActiveFolder || {};
  const { id: activeContentFolderId } = contentActiveFolder || {};
  const { id: activePlaylistFolderId } = playlistActiveFolder || {};
  const { userSettings, loggedInData } = state.user || {};
  const { activeView } = userSettings || {};
  const { firstName, lastName, email, id: loggedInDataId } = loggedInData || {};
  const { pathname } = useLocation();
  const { sidebar, roleBasedHeader, header, subHeader, subHeaderTwo } =
    variants[variant];
  const { id: companyId, name: companyName } = companyData || {};

  const isScreensPage = pathname === ROUTES.screenz || false;
  const isContentsPage = pathname === ROUTES.content || false;
  const isPlaylistPage = pathname === ROUTES.playlists || false;

  useEffect(() => {
    dispatch(layoutActions.setWindowWidth(window.innerWidth));
    if (state.user.loggedInData) {
      window.Intercom('boot', {
        app_id: 'd7jw43br',
        name: `${firstName} ${lastName}`,
        email,
        user_id: loggedInDataId,
        client: companyName,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(layoutActions.setWindowWidth(window.innerWidth));
    });
  }, [dispatch]);

  const getAllFoldersHandler = useCallback(async () => {
    if (!companyId) {
      // toaster.error('Company data not found');
      return;
    }
    const folderType =
      (isContentsPage && FolderType.CONTENT) ||
      (isScreensPage && FolderType.SCREENZ) ||
      (isPlaylistPage && FolderType.PLAYLIST);

    if (!folderType) {
      return;
    }
    const { companyUserId } = companyData || {};
    const response = await getAllFolders(
      {
        companyId,
        folderType,
        companyUserId: companyUserId || '',
      },
      () => {}
    );
    if (response) {
      if (isContentsPage) {
        dispatch(contentPageActions.setContentFolders(response));
      }
      if (isScreensPage) {
        dispatch(homePageActions.setScreensFolders(response));
      }
      if (isPlaylistPage) {
        dispatch(playlistPageActions.setPlaylistsFolders(response));
      }

      dispatch(layoutActions.invokeGetAllFolders(false));
    }
  }, [companyId, dispatch, isContentsPage, isScreensPage, isPlaylistPage]);

  const clearRootFolders = useCallback(() => {
    dispatch(contentPageActions.setContentFolders([]));
    dispatch(homePageActions.setScreensFolders([]));
    dispatch(playlistPageActions.setPlaylistsFolders([]));
  }, [dispatch]);

  const getFoldersByIdHandler = useCallback(async () => {
    const id =
      (isContentsPage && activeContentFolderId) ||
      (isScreensPage && activeScreenFolderId) ||
      (isPlaylistPage && activePlaylistFolderId);

    if (!id) {
      return;
    }
    clearRootFolders();
    const response = await getFolderById({ id }, () => {});
    if (response && response.subFolders) {
      dispatch(homePageActions.setIsFetchingFolders(false));
      const { subFolders, parentFolders } = response;

      if (isContentsPage) {
        dispatch(contentPageActions.setContentFolders(subFolders || []));
        dispatch(
          contentPageActions.setContentFolderHierarchy(
            [...parentFolders].reverse()
          )
        );
      }
      if (isScreensPage) {
        dispatch(homePageActions.setScreensFolders(subFolders || []));
        dispatch(
          homePageActions.setScreensFolderHierarchy(
            [...parentFolders].reverse()
          )
        );
      }
      if (isPlaylistPage) {
        dispatch(playlistPageActions.setPlaylistsFolders(subFolders || []));
        dispatch(
          playlistPageActions.setPlaylistsFolderHierarchy(
            [...parentFolders].reverse()
          )
        );
      }
      dispatch(layoutActions.invokeGetFolderById(false));
    }
  }, [
    dispatch,
    isContentsPage,
    isPlaylistPage,
    isScreensPage,
    activeContentFolderId,
    activePlaylistFolderId,
    activeScreenFolderId,
    clearRootFolders,
  ]);

  useEffect(() => {
    getFoldersByIdHandler();
    if (invokeGetFolderById) getFoldersByIdHandler();
  }, [getFoldersByIdHandler, invokeGetFolderById]);

  // useEffect(() => {
  //   getAllFoldersHandler();
  // }, []);

  useEffect(() => {
    if (
      !activeScreenFolderId &&
      !activeContentFolderId &&
      !activePlaylistFolderId
    ) {
      getAllFoldersHandler();
    }
  }, [
    activeScreenFolderId,
    activeContentFolderId,
    activePlaylistFolderId,
    getAllFoldersHandler,
  ]);

  // useEffect(() => {
  //   getAllFoldersHandler();
  // }, [companyId, getAllFoldersHandler]);

  useEffect(() => {
    if (invokeGetAllFolders) getAllFoldersHandler();
  }, [invokeGetAllFolders, getAllFoldersHandler, getFoldersByIdHandler]);

  const isSuperAdmin = user.role === UserRoles.SuperAdmin;
  // const isOwner = user.role === UserRoles.Owner;
  // const isAdmin = user.role === UserRoles.Admin;
  // const isReseller = user.role === UserRoles.Reseller;

  return (
    <div className={`${className} ${styles.layoutWrapper}`}>
      {sidebar && <Sidebar />}
      <section className={styles.bodyWrapper}>
        {roleBasedHeader && isSuperAdmin && <RoleBasedHeader />}
        {header && <Header />}
        {subHeader && <SubHeader />}
        <SideDrawer />
        {isSuperAdmin &&
          // isShowingClientInstance &&
          (isContentsPage || isScreensPage || isPlaylistPage) && (
            <ClientsDropdown className={styles.clientsListInLayout} />
          )}

        <ClientNameAndFolderPath className={styles.folderPath} username="" />
        {pathname === ROUTES.userSettings &&
          activeView === UserSettingsView.TeamMembers && (
            <div className={styles.folderName}>
              <p> for {companyData?.name}</p>
            </div>
          )}
        {subHeaderTwo && <SubheaderTwo />}
        <div className={styles.child}>{children}</div>
      </section>
    </div>
  );
}

Layout.defaultProps = {
  className: '',
};

export { LayoutVariants };

export default Layout;

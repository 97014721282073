import React, { useCallback, useEffect, useState } from 'react';
import { iContentData, iState } from 'types';
import { editWebContent, getContentsByCompany } from 'apis';
import { useStore } from 'store';
import { useNavigate } from 'react-router-dom';
import { contentPageActions } from 'store/actions';
import Button, { ButtonVariants } from 'components/button/button';
import { ScreenType } from 'enums';
import { toaster } from 'helpers';
import {
  closeIcon,
  WebIcon,
  refreshIcon,
  editIcon,
  ROUTES,
} from 'constants/index';
import styles from './webContent.module.scss';

interface iProps {
  className?: string;
  data: iContentData;
  close: () => void;
}

function WebContent(props: iProps): JSX.Element {
  const [state, dispatch]: [iState, any] = useStore();
  const { className, data, close } = props;
  const { homePage, contentPage } = state || {};
  const [name, setName] = useState<string>(data.name);
  const [, setIsMutated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { contentActiveFolder } = contentPage || {};
  const { companyData } = homePage || {};
  const { id: companyId } = companyData || {};
  const { id: folderId } = contentActiveFolder || {};
  const { companyUserId } = companyData || {};
  const navigate = useNavigate();

  useEffect(() => {
    setName(data.name);
  }, [data]);

  const onChange = useCallback((event) => {
    setIsMutated(true);
    setName(event.target.value);
  }, []);

  const getContents = useCallback(async () => {
    if (!companyId) return;

    const response = await getContentsByCompany(
      {
        company: companyId,
        folder: folderId || '',
        companyUserId: companyUserId || null,
      },
      setIsLoading
    );
    if (response) {
      dispatch(contentPageActions.setContents(response));
      dispatch(contentPageActions.fetchContentsData(false));
    }
  }, [companyId, folderId, companyUserId, dispatch]);

  const updateWebContent = useCallback(async () => {
    const response = await editWebContent(
      {
        id: data.id,
        name,
        url: data.url,
        orientation:
          (data.orientation && ScreenType[data.orientation]) ||
          ScreenType.HORIZONTAL,
        dimension: data.dimension || '',
      },
      setIsLoading
    );
    if (response) {
      setIsMutated(false);
      dispatch(
        contentPageActions.setContentView({ ...data, name: response.name })
      );
      getContents();
      toaster.success('Web content updated successfully');
    } else setIsMutated(false);
  }, [data, name, dispatch, getContents]);

  const onClickEdit = useCallback(() => {
    dispatch(contentPageActions.setIsEditWebContent(true));
    navigate(ROUTES.addWebpage);
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(contentPageActions.setIsEditWebContent(true));
  }, []);

  return (
    <div className={`${styles.webContentPreview} ${className}`}>
      <div className={styles.drawerHeader}>
        <div className={styles.colOne}>
          <span className={styles.previewHeading}>Details</span>
        </div>
        <div className={styles.colTwo}>
          <img
            className={styles.closeIcon}
            src={closeIcon}
            alt=""
            onClick={close}
          />
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.webSiteHeading}>
          <img src={WebIcon} alt="" />
          <p>Webpage name</p>
        </div>
        <div className={styles.inputAndSaveBtn}>
          <input type="text" value={name} onChange={onChange} />
          <Button
            className={styles.saveBtn}
            btnName="Save"
            variant={ButtonVariants.SmallTransp}
            onClick={updateWebContent}
            // isDisabled={!isMutated}
            isLoading={isLoading}
          />
        </div>
        <div className={styles.webIcon}>
          {/* <img src={WebIcon} alt="" /> */}
          <iframe
            className={styles.previewIFrame}
            id="addWebContentIframe"
            title="Web page preview"
            src={data.url}
            frameBorder="0"
          />
        </div>
        <div>
          <p className={styles.url}>{data.url}</p>
        </div>

        <div className={styles.refresh}>
          <img src={refreshIcon} alt="" />
          <p>Does not refresh</p>
        </div>
        <Button
          className={styles.editWebPageBtn}
          btnName="Edit Webpage"
          variant={ButtonVariants.SmallTransp}
          icon={editIcon}
          iconPosition="left"
          onClick={onClickEdit}
        />
      </div>
    </div>
  );
}

WebContent.defaultProps = {
  className: '',
};

export default WebContent;

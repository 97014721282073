import { ROUTES } from 'constants/index';

export const currentPage = (): {
  isScreensPage: boolean;
  isContentsPage: boolean;
  isPlaylistPage: boolean;
  isPlaylistDetails: boolean;
  isHomePage: boolean;
} => {
  const { pathname } = window.location;

  const isScreensPage = pathname === ROUTES.screenz || false;
  const isHomePage = pathname === ROUTES.home || false;
  const isContentsPage = pathname === ROUTES.content || false;
  const isPlaylistPage = pathname === ROUTES.playlists || false;
  const isPlaylistDetails = pathname === ROUTES.playlist;
  return {
    isScreensPage,
    isContentsPage,
    isPlaylistPage,
    isPlaylistDetails,
    isHomePage,
  };
};

import React, { useCallback, useState, useEffect } from 'react';
import {
  iState,
  iContentData,
  iCalenderEvent,
  iAddOrUpdateTempleteArgs,
  iTemplateContent,
  iOffice365DropdownOption,
} from 'types';
import { useStore } from 'store';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonVariants } from 'components/button/button';
import {
  contentPageActions,
  meetingOverviewFullActions,
  office365Actions,
} from 'store/actions';
import {
  closeIcon,
  standardMeetingRoom,
  editIcon,
  ROUTES,
  templatesGreyIcon,
  meetingOverviewFull,
  informationIcon,
  meetingOverviewMessage,
  meetingOverviewportrait,
} from 'constants/index';

import { toaster, uuid } from 'helpers';
import { TIME_ZONES } from 'modules/office-365/calender-events/components/is-meetingRoom-section/template-settings-bar/timeZones';
import {
  AddOrUpdateTemplateMutationType,
  CalenderSettingsView,
  CARD_NAME,
  ClockContentType,
  EventContentType,
  EventTemplateContentType,
  EventTemplateType,
  LanguageType,
  LeftAreaContentType,
} from 'enums';
import {
  CONTEN_AREA_OPTIONS,
  LANGUAGE_OPTIONS,
} from 'modules/office-365/calender-events/components/is-meetingRoom-section/template-settings-bar/constants';
import { addTemplate, getContent, getRooms, MsGraphApis } from 'apis';
import {
  CLOCK_ALIGNMENT_OPTIONS,
  CONTEN_TYPE_OPTIONS,
} from 'modules/office-365/calender-events/components/is-meetingOverview-section/template-settings-bar/constants';
import { LARGE_AREATYPE_OPTIONS } from 'modules/office-365/calender-events/components/is_meetingMessageOverview-section/template-settings-bar/constants';
import styles from './templatePreview.module.scss';

interface iProps {
  className?: string;
  data: iContentData;
  close: () => void;
}

function TemplatePreview(props: iProps): JSX.Element {
  const { className, data, close } = props;
  const [tempName, setTempName] = useState<string>('');
  const [isLoading] = useState<boolean>(false);
  const [state, dispatch]: [iState, any] = useStore();
  const { contentActiveFolder } = state.contentPage;
  const [rooms, setRooms] = useState<any>([]);
  const { eventInfo, tempalteDataToUpdate } = state.office365 || {};
  const { templateData } = tempalteDataToUpdate || {};
  const { id: templateDataContentID, contents: templateContents } =
    templateData || {};
  const templateId = templateDataContentID && templateDataContentID;
  const { companyData } = state.homePage || {};
  const { templateToView } = state.layout || {};
  const { transparency } = state.meetingOverviewFull || {};
  const { user } = state || {};
  const [timezones, setTimeZones] = useState<Array<iOffice365DropdownOption>>(
    []
  );
  const {
    // currentView,
    // contentAreaContentOption,
    // contentSettingsContentOption,
    activeMsAccount,
    templateIdToEdit,
    selectedLanguage,
  } = state.office365 || {};
  const {
    setIsShowOverviewSubject,
    setIsShowOverviewHostname,
    setIsShowOverviewNextMeeting,
  } = meetingOverviewFullActions;

  const {
    timeZone,
    eventBgImg = '',
    eventLogo = '',
    // customName,
    // templateName = '',
  } = state.office365.eventInfo || {};

  const navigate = useNavigate();

  useEffect(() => {
    setTempName(data?.name || '');
  }, [data]);

  const onChange = useCallback((event) => {
    setTempName(event.target.value);
  }, []);

  const locationBasedEvents = useCallback(
    (allEvents: iCalenderEvent[]) => {
      const { value } = timeZone || {};
      return allEvents.filter((event) => event.originalStartTimeZone === value);
    },
    [timeZone]
  );

  const { ContentArea } = CalenderSettingsView;
  const callEvents = useCallback(
    async (room) => {
      try {
        const events = await MsGraphApis.callEventsOnRoom(room.emailAddress);
        if (events.length === 0) {
          dispatch(office365Actions.setAllCalenderEvents([]));
          dispatch(office365Actions.setCurrentCalenderEvents(null));
          dispatch(office365Actions.setNextCurrentCalenderEvents(null));
        }
        if (timeZone && timeZone.value === EventContentType.Empty) {
          dispatch(office365Actions.setCurrentView(ContentArea));
          return;
        }

        if (events) {
          let filteredEvents: iCalenderEvent[] = events;

          filteredEvents = filteredEvents.filter((event) => {
            const startDate = new Date(`${event.start.dateTime}Z`);
            const endDate = new Date(`${event.end.dateTime}Z`);
            const currentdate = new Date();
            return (
              startDate.getDate() === currentdate.getDate() &&
              endDate >= currentdate
            );
          });

          //  console.log(`sachin testing `, filteredEvents[0]);

          dispatch(office365Actions.setAllCalenderEvents(filteredEvents || []));
          if (filteredEvents.length > 0) {
            const onGoingEvent = filteredEvents.filter((event) => {
              const startDate = new Date(`${event.start.dateTime}Z`);
              const endDate = new Date(`${event.end.dateTime}Z`);
              const currentdate = new Date();
              return (
                startDate.getTime() <= currentdate.getTime() &&
                endDate.getTime() >= currentdate.getTime()
              );
            });
            if (onGoingEvent.length > 0) {
              dispatch(
                office365Actions.setCurrentCalenderEvents(onGoingEvent[0])
              );
              if (filteredEvents.length >= 2) {
                dispatch(
                  office365Actions.setNextCurrentCalenderEvents(
                    filteredEvents[1]
                  )
                );
              }
              return;
            }
            dispatch(office365Actions.setCurrentCalenderEvents(null));
            dispatch(
              office365Actions.setNextCurrentCalenderEvents(filteredEvents[0])
            );
          }

          if (filteredEvents.length === 0) {
            dispatch(office365Actions.setAllCalenderEvents([]));
            dispatch(office365Actions.setCurrentCalenderEvents(null));
            dispatch(office365Actions.setNextCurrentCalenderEvents(null));
          }
        }
      } catch (error) {
        dispatch(office365Actions.setAllCalenderEvents([]));
        dispatch(office365Actions.setCurrentCalenderEvents(null));
        dispatch(office365Actions.setNextCurrentCalenderEvents(null));
      }
    },
    [dispatch, locationBasedEvents, ContentArea, timeZone]
  );

  useEffect(() => {
    (async () => {
      if (!companyData) return;
      const roomsResponse = await getRooms({
        company: companyData.id || '',
      });
      if (roomsResponse) {
        setRooms(roomsResponse);
        // }
      }
    })();
  }, [dispatch, activeMsAccount, templateIdToEdit]);

  useEffect(() => {
    const zones = TIME_ZONES.map((i) => ({
      id: uuid(),
      option: i.text,
      icon: '',
      value: i.utc[0],
    }));
    //  console.log(zones, 'zones');
    zones.unshift({
      id: uuid(),
      option: 'Empty',
      icon: informationIcon,
      value: EventContentType.Empty,
    });
    setTimeZones(zones);
  }, []);

  const mapDataToContentBox = useCallback(
    (content: iTemplateContent, cardPosition: CARD_NAME) => {
      const {
        contentType,
        details: cardContentDetails,
        // id,
        room,
      } = content || {};
      const {
        contentAlignment,
        imageUrl,
        roomDetails: contentRoomDetails,
        showHostName,
        showNextMeeting,
        showSubject,
        timezone,
      } = cardContentDetails || {};
      if (cardPosition === CARD_NAME.One)
        console.log('statemeetingOVerview', content, state.meetingOverviewFull);

      const contentTypeOption = CONTEN_TYPE_OPTIONS.find(
        (i) => i.value === contentType
      );

      if (contentTypeOption) {
        dispatch(
          meetingOverviewFullActions.setOverviewSettingsContentOption({
            option: contentTypeOption,
            CARD_NAME: cardPosition,
          })
        );
      }
      const contentClockAlignment = CLOCK_ALIGNMENT_OPTIONS.find(
        (i) => i.value === contentAlignment
      );
      if (contentClockAlignment) {
        dispatch(
          meetingOverviewFullActions.setOverviewClockAllignement({
            option: contentClockAlignment,
            CARD_NAME: cardPosition,
          })
        );
      }
      const timeZoneOption = timezones.find((i) => i.value === timezone);
      if (timeZoneOption) {
        dispatch(
          meetingOverviewFullActions.setOverviewTimeZoneOption({
            option: {
              id: uuid(),
              option: timeZoneOption.option,
              value: timeZoneOption.value,
              icon: '',
            },
            CARD_NAME: cardPosition,
          })
        );
      }
      if (imageUrl) {
        dispatch(
          meetingOverviewFullActions.setOverviewEventLogo({
            url: imageUrl,
            CARD_NAME: cardPosition,
          })
        );
      }
      const choosedRoom = rooms.find((i) => i.id === room?.id);

      if (choosedRoom) {
        dispatch(
          meetingOverviewFullActions.updateOverviewCustomName({
            custom_Name: choosedRoom.custom_name,
            CARD_NAME: cardPosition,
          })
        );
        dispatch(
          meetingOverviewFullActions.setOverviewChoosedRoom({
            roomData: choosedRoom,
            CARD_NAME: cardPosition,
          })
        );
        // callEvents(choosedRoom, cardPosition);
      }
      if (contentRoomDetails) {
        dispatch(
          meetingOverviewFullActions.updateRoomDetails({
            room_details: contentRoomDetails,
            CARD_NAME: cardPosition,
          })
        );
      }
      dispatch(
        setIsShowOverviewSubject({
          isOn: showSubject,
          CARD_NAME: cardPosition,
        })
      );
      dispatch(
        setIsShowOverviewHostname({
          isOn: showHostName,
          CARD_NAME: cardPosition,
        })
      );
      dispatch(
        setIsShowOverviewNextMeeting({
          isOn: showNextMeeting,
          CARD_NAME: cardPosition,
        })
      );
    },
    [templateIdToEdit, dispatch, rooms, callEvents, state]
  );

  useEffect(() => {
    console.log('statemeetingOVerview', state.layout);
  }, [state]);

  const getTemplate = useCallback(async () => {
    if (!templateIdToEdit) {
      return;
    }
    const response = await getContent(templateIdToEdit);
    if (response?.templateData) {
      const { details, contents, templateType, language } =
        response.templateData || {};

      const {
        backgroundImageUrl,
        logoUrl,
        ActivateBooking,
        leftAreaContetType,
        leftAreaImageUrl,
        leftAreaText,
      } = details || {};

      if (language) {
        const templateLanguage = LANGUAGE_OPTIONS.find(
          (lang) => lang.value === language
        );
        if (templateLanguage)
          dispatch(office365Actions.setSelectedLanguage(templateLanguage));
      }

      dispatch(office365Actions.setTempalteDataToUpdate(response));
      if (templateType) {
        dispatch(office365Actions.setChosenTemplateType(templateType || ' '));
      }
      if (
        templateToView === EventTemplateType.meetingRoom &&
        contents.length > 0
      ) {
        const { room } = contents[0] || {};
        const { showHostName, showNextMeeting, showSubject, timezone } =
          contents[0].details || {};
        if (response.name) {
          dispatch(office365Actions.setTemplateName(response.name || ''));
        }

        if (!eventBgImg)
          dispatch(office365Actions.setEventBgImg(backgroundImageUrl || ''));
        if (!eventLogo) dispatch(office365Actions.setEventLogo(logoUrl || ''));
        if (showSubject)
          dispatch(office365Actions.setIsShowSubject(showSubject));
        if (showHostName)
          dispatch(office365Actions.setIsShowHostName(showHostName));
        if (showNextMeeting)
          dispatch(office365Actions.setIsShowNextMeeting(showNextMeeting));
        if (ActivateBooking)
          dispatch(office365Actions.setIsShowactivateBooking(ActivateBooking));
        dispatch(office365Actions.updateCustomName(room.custom_name || ''));

        const choosedRoom = rooms.find((i) => i.id === room?.id);

        if (choosedRoom) {
          dispatch(office365Actions.setChoosedRoom(choosedRoom));
          // callEvents(choosedRoom);
        }

        const contentTypeOption = CONTEN_TYPE_OPTIONS.find(
          (i) => i.value === EventContentType.Calender
        );
        if (contentTypeOption)
          dispatch(
            office365Actions.setContentSettingsContentOption(contentTypeOption)
          );
        const contentAreaContent = CONTEN_AREA_OPTIONS.find(
          (i) => i.value === EventContentType.Clock
        );
        if (contentAreaContent)
          dispatch(
            office365Actions.setContentAreaContentOption(contentAreaContent)
          );

        const timeZoneOption = TIME_ZONES.find((i) => i.utc[0] === timezone);
        if (timeZoneOption) {
          dispatch(
            office365Actions.setTimeZoneOption({
              id: uuid(),
              option: timeZoneOption.value,
              value: timeZoneOption.utc[0],
              icon: '',
            })
          );
        }
      }
      if (templateToView !== EventTemplateType.meetingRoom) {
        if (response.name) {
          dispatch(
            meetingOverviewFullActions.setMeetingTemplateName(
              response.name || ''
            )
          );
        }
        const largeAreaTypeOption = LARGE_AREATYPE_OPTIONS.find(
          (i) => i.value === leftAreaContetType
        );

        if (largeAreaTypeOption) {
          dispatch(
            meetingOverviewFullActions.setLargeAreaContent(largeAreaTypeOption)
          );
        }

        dispatch(
          meetingOverviewFullActions.setEventBgImg(backgroundImageUrl || '')
        );

        if (leftAreaImageUrl) {
          // leftAreaImage
          dispatch(
            meetingOverviewFullActions.setOverviewLargeImage(leftAreaImageUrl)
          );
        }

        if (leftAreaText) {
          // EmptyText
          dispatch(
            meetingOverviewFullActions.updateOverviewPretitle(
              leftAreaText.preTitle || ''
            )
          );
          dispatch(
            meetingOverviewFullActions.updateOverviewTitle(
              leftAreaText.title || ''
            )
          );
          dispatch(
            meetingOverviewFullActions.updateOverviewBody(
              leftAreaText.body || ''
            )
          );
          dispatch(
            meetingOverviewFullActions.updateOverviewSubtext(
              leftAreaText.subText || ''
            )
          );
        }
        if (response.templateData.opacity) {
          dispatch(
            meetingOverviewFullActions.setTransparency(
              response.templateData.opacity.toString()
            )
          );
        }

        if (Array.isArray(contents)) {
          contents.forEach((content) => {
            if (content.position === 7) {
              const cardPosition = CARD_NAME.One;
              mapDataToContentBox(content, cardPosition);
            }
            if (content.position === 1) {
              const cardPosition = CARD_NAME.Two;
              mapDataToContentBox(content, cardPosition);
            }
            if (content.position === 8) {
              const cardPosition = CARD_NAME.Three;
              mapDataToContentBox(content, cardPosition);
            }
            if (content.position === 2) {
              const cardPosition = CARD_NAME.Four;
              mapDataToContentBox(content, cardPosition);
            }
            if (content.position === 9) {
              const cardPosition = CARD_NAME.Five;
              mapDataToContentBox(content, cardPosition);
            }
            if (content.position === 3) {
              const cardPosition = CARD_NAME.Six;
              mapDataToContentBox(content, cardPosition);
            }
            if (content.position === 10) {
              const cardPosition = CARD_NAME.Seven;
              mapDataToContentBox(content, cardPosition);
            }
            if (content.position === 4) {
              const cardPosition = CARD_NAME.Eight;
              mapDataToContentBox(content, cardPosition);
            }
            if (content.position === 11) {
              const cardPosition = CARD_NAME.Nine;
              mapDataToContentBox(content, cardPosition);
            }
            if (content.position === 5) {
              const cardPosition = CARD_NAME.Ten;
              mapDataToContentBox(content, cardPosition);
            }
            if (content.position === 12) {
              const cardPosition = CARD_NAME.Eleven;
              mapDataToContentBox(content, cardPosition);
            }
            if (content.position === 6) {
              const cardPosition = CARD_NAME.Twelve;
              mapDataToContentBox(content, cardPosition);
            }
          });
        }
      }
    }
  }, [templateIdToEdit, dispatch, rooms]);

  const { value: selectedLanguageValue } = selectedLanguage || {};

  const selectedLanguageType =
    (selectedLanguageValue === LanguageType.English && LanguageType.English) ||
    (selectedLanguageValue === LanguageType.Norwegian &&
      LanguageType.Norwegian) ||
    LanguageType.English;

  const saveMeetingRoomTemplateHandler = useCallback(async () => {
    const { id: folderId = null } = contentActiveFolder || {};
    const { id: companyId = '' } = companyData || {};
    const {
      isShowActiveBooking = true,
      isShowHostName = true,
      isShowNextMeeting = true,
      isShowSubject = true,
      roomChoosed,
      // eventBgImg = '',
      // eventLogo = '',
      // timeZone,
      customName = '',
      // templateName = '',
    } = eventInfo || {};

    if (!roomChoosed) {
      toaster.error('Please choose room');
      return;
    }
    const { id: roomId = '' } = roomChoosed || {};

    if (!roomChoosed) {
      toaster.error('Room data not found');
      return;
    }
    const isContentsArray = Array.isArray(templateContents);
    if (!isContentsArray) return;
    if (isContentsArray && templateContents.length === 0) return;
    const meetingRoomContentId = templateContents[0].id;

    const args: iAddOrUpdateTempleteArgs = {
      userId: user.loggedInData ? user.loggedInData?.id : '',
      name: tempName,
      mutationType: AddOrUpdateTemplateMutationType.UPDATE,
      templateType: EventTemplateType.meetingRoom,
      folderId,
      companyId,
      opacity: Number(transparency) / 10,
      language: selectedLanguageType,
      updateRoomName: false,
      details: {
        leftAreaContetType: null,
        backgroundImageUrl: eventBgImg,
        logoUrl: eventLogo,
        ActivateBooking: isShowActiveBooking,
        leftAreaText: {
          body: null,
          preTitle: null,
          subText: null,
          title: null,
        },
        leftAreaImageUrl: '',
      },
      contents: [
        {
          id: meetingRoomContentId,
          contentType: EventTemplateContentType.Calender,
          customName,
          position: 1,
          room: roomId,
          details: {
            contentAlignment: ClockContentType.LeftAligned,
            imageUrl: null,
            roomDetails: null,
            showHostName: isShowHostName,
            showNextMeeting: isShowNextMeeting,
            showSubject: isShowSubject,
            timezone: (timeZone && timeZone.value) || '',
          },
        },
      ],
    };
    if (templateIdToEdit) args['id'] = templateId;
    const response = await addTemplate(args, () => {});
    if (response) {
      if (templateIdToEdit) toaster.success('Template updated successfully');
      navigate(ROUTES.content);
      dispatch(contentPageActions.fetchContentsData(true));
    }
  }, [
    eventInfo,
    companyData,
    contentActiveFolder,
    tempName,
    navigate,
    templateIdToEdit,
    templateId,
  ]);

  useEffect(() => {
    if (templateIdToEdit) {
      getTemplate();
    }
  }, [templateIdToEdit, rooms]);

  const onClickEdit = useCallback(() => {
    dispatch(office365Actions.setTemplateIdToEdit(data.id));
    dispatch(
      office365Actions.setChosenTemplateType(EventTemplateType.meetingRoom)
    );
    navigate(ROUTES.calenderEvents);
  }, [dispatch, navigate, data.id]);

  const TemplateImage =
    (data.templateData?.templateType === EventTemplateType.meetingRoom &&
      standardMeetingRoom) ||
    (data.templateData?.templateType ===
      EventTemplateType.meetingOverviewFull &&
      meetingOverviewFull) ||
    (data.templateData?.templateType ===
      EventTemplateType.meetingOverviewMessage &&
      meetingOverviewMessage) ||
    (data.templateData?.templateType ===
      EventTemplateType.meetingOverviewPotrait &&
      meetingOverviewportrait);

  const templateCalendarType =
    (templateToView === EventTemplateType.meetingOverviewFull &&
      EventTemplateType.meetingOverviewFull) ||
    (templateToView === EventTemplateType.meetingOverviewMessage &&
      EventTemplateType.meetingOverviewMessage) ||
    (templateToView === EventTemplateType.meetingOverviewPotrait &&
      EventTemplateType.meetingOverviewPotrait) ||
    EventTemplateType.meetingOverviewMessage;

  const saveMeetingOverviewTemplateHandler = useCallback(async () => {
    const { id: folderId = '' } = contentActiveFolder || {};
    const { id: companyId = '' } = companyData || {};
    const {
      eventBgImg: meetingOverviewFullEventBgImg,
      cardDetails,
      templateOverviewName = '',
      title,
      body,
      largeAreaContent,
      largeContentUrl,
      preTitle,
      subText,
    } = state.meetingOverviewFull || {};
    if (!templateOverviewName) {
      toaster.error('Please enter Template Name');
      return;
    }

    const leftAreaContetType =
      (largeAreaContent.value === LeftAreaContentType.Text &&
        LeftAreaContentType.Text) ||
      (largeAreaContent.value === LeftAreaContentType.Image &&
        LeftAreaContentType.Image) ||
      LeftAreaContentType.Playlist;

    const contentArray: any = [];

    Object.values(cardDetails).forEach((card) => {
      if (card.contentOption.value === 'Empty') return;
      const contentType =
        (card.contentOption.value === EventTemplateContentType.Calender &&
          EventTemplateContentType.Calender) ||
        (card.contentOption.value === EventTemplateContentType.Clock &&
          EventTemplateContentType.Clock) ||
        EventTemplateContentType.Image;
      const contentAlignment =
        (card.clockAlignment.value === ClockContentType.LeftAligned &&
          ClockContentType.LeftAligned) ||
        ClockContentType.RightAligned;
      contentArray.push({
        position: card.position,
        contentType,
        customName: card.overviewCustom_name || '',
        room: (card.overviewChoosedRoom && card.overviewChoosedRoom.id) || null,
        details: {
          contentAlignment,
          imageUrl: card.overviewEventLogo,
          roomDetails: card.roomDetails,
          showHostName: card.isShowOverviewHostName,
          showNextMeeting: card.isShowOverviewNextMeeting,
          showSubject: card.isShowOverviewSubject,
          timezone:
            (card.overviewTimezone.value !== 'Empty' &&
              card.overviewTimezone.value) ||
            '',
        },
      });
    });

    const args: iAddOrUpdateTempleteArgs = {
      userId: user.loggedInData ? user.loggedInData?.id : '',
      name: tempName,
      mutationType: templateIdToEdit
        ? AddOrUpdateTemplateMutationType.UPDATE
        : AddOrUpdateTemplateMutationType.CREATE,
      templateType: templateCalendarType,
      folderId,
      companyId,
      opacity: Number(transparency) / 10,
      language: selectedLanguageType,
      updateRoomName: false,
      details: {
        leftAreaContetType,
        backgroundImageUrl: meetingOverviewFullEventBgImg,
        logoUrl: null,
        ActivateBooking: false,
        leftAreaText: {
          body,
          preTitle,
          subText,
          title,
        },
        leftAreaImageUrl: largeContentUrl,
      },
      contents: contentArray,
    };

    if (templateIdToEdit) args['id'] = templateId;
    const response = await addTemplate(args, () => {});
    if (response) {
      if (templateIdToEdit) toaster.success('Template updated successfully');
      navigate(ROUTES.content);
      dispatch(contentPageActions.fetchContentsData(true));
    }
  }, [
    state,
    eventInfo,
    companyData,
    contentActiveFolder,
    tempName,
    navigate,
    templateIdToEdit,
    templateId,
  ]);

  const saveTemplateHandler = useCallback(() => {
    if (templateToView === EventTemplateType.meetingRoom) {
      saveMeetingRoomTemplateHandler();
    }
    if (templateToView !== EventTemplateType.meetingRoom) {
      saveMeetingOverviewTemplateHandler();
    }
  }, [
    state,
    eventInfo,
    companyData,
    contentActiveFolder,
    tempName,
    navigate,
    templateIdToEdit,
    templateId,
  ]);

  return (
    <div className={`${styles.templatePreview} ${className}`}>
      <div className={styles.drawerHeader}>
        <div className={styles.colOne}>
          <img className={styles.templateIcon} src={templatesGreyIcon} alt="" />
          <span className={styles.previewHeading}>Details</span>
        </div>
        <div className={styles.colTwo}>
          <img
            className={styles.closeIcon}
            src={closeIcon}
            alt=""
            onClick={close}
          />
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.templateHeading}>
          <p>Template name</p>
        </div>
        <div className={styles.inputAndSaveBtn}>
          <input type="text" value={tempName} onChange={onChange} />
          <Button
            className={styles.saveBtn}
            btnName="Save"
            variant={ButtonVariants.SmallTransp}
            onClick={saveTemplateHandler}
            // isDisabled
            isLoading={isLoading}
          />
        </div>
        <hr className={styles.hr} />
        <img
          className={styles.standardMeetingRoom}
          src={TemplateImage || ''}
          alt=""
        />
        <Button
          className={styles.editWebPageBtn}
          btnName="Edit Template"
          variant={ButtonVariants.SmallTransp}
          icon={editIcon}
          iconPosition="left"
          onClick={onClickEdit}
        />
      </div>
    </div>
  );
}

TemplatePreview.defaultProps = {
  className: '',
};

export default TemplatePreview;

import React from 'react';
import Button, { ButtonVariants } from 'components/button/button';
import { iContentData } from 'types';
import { EventTemplateType } from 'enums';
import {
  closeIcon,
  CONTENT_TYPE,
  meetingOverviewFull,
  meetingOverviewMessage,
  meetingOverviewportrait,
  standardMeetingRoom,
} from 'constants/index';
import { useLocation } from 'react-router-dom';
import styles from './contentPreview.module.scss';

interface iProps {
  className?: string;
  data: iContentData;
  close: () => void;
}

function ContentPreview(props: iProps): JSX.Element {
  const { className, data, close } = props;
  const { pathname } = useLocation();

  const content = data;
  const contentView = data;
  const date =
    (contentView?.updated_at && new Date(contentView?.updated_at)) || '';

  const TemplateImage =
    (data.templateData?.templateType === EventTemplateType.meetingRoom &&
      standardMeetingRoom) ||
    (data.templateData?.templateType ===
      EventTemplateType.meetingOverviewFull &&
      meetingOverviewFull) ||
    (data.templateData?.templateType ===
      EventTemplateType.meetingOverviewMessage &&
      meetingOverviewMessage) ||
    (data.templateData?.templateType ===
      EventTemplateType.meetingOverviewPotrait &&
      meetingOverviewportrait);

  return (
    <div className={`${styles.contentPreview} ${className}`}>
      <div
        className={`${
          pathname === '/content'
            ? styles.contentDrawerHeader
            : styles.playListDrawerHeader
        }`}
      >
        <div className={styles.colOne}>
          <Button variant={ButtonVariants.ChangeView} btnName="Info" isActive />
        </div>
        <div className={styles.colTwo}>
          <img
            className={styles.closeIcon}
            src={closeIcon}
            alt=""
            onClick={close}
          />
        </div>
      </div>

      <div className={`${styles.section}`}>
        <div className={styles.imageContentPreview}>
          {content?.contentType === CONTENT_TYPE.Image && (
            <img
              className={styles.imageContentPreview}
              src={content?.url}
              alt=""
            />
          )}
          {content?.contentType === CONTENT_TYPE.Video && (
            <video preload="none" src={content?.url} controls>
              <source src={content?.url} type="video/mp4" />
              <track src="captions_en.vtt" kind="captions" />
            </video>
          )}
          {content.contentType === CONTENT_TYPE.Template && (
            <img
              className={styles.standardMeetingRoom}
              src={TemplateImage || ''}
              alt=""
            />
          )}
        </div>

        <p className={styles.contentName}>{contentView?.name}</p>
        {content.contentType !== CONTENT_TYPE.Template && (
          <table className={styles.details}>
            <tr>
              <td className={styles.heading}>Dimension</td>
              <td className={styles.value}>{contentView?.dimension}</td>
            </tr>
            <tr>
              <td className={styles.heading}>Size</td>
              <td className={styles.value}>{contentView?.size} KB</td>
            </tr>
            <tr>
              <td className={styles.heading}>Type</td>
              <td className={styles.value}>{contentView?.contentType}</td>
            </tr>
            <tr>
              <td className={styles.heading}>Modified</td>
              {/* <td className={styles.value}>14.03.2020 13:47</td> */}
              <td className={styles.value}>
                {' '}
                {date &&
                  `${date.getDate()}.${date.getMonth()}.${date.getFullYear()} ${date.getHours()}.${date.getMinutes()} `}
              </td>
            </tr>
          </table>
        )}
      </div>
    </div>
  );
}

ContentPreview.defaultProps = {
  className: '',
};

export default ContentPreview;

import React, { useCallback } from 'react';
import styles from './checkbox.module.scss';

interface iProps {
  className?: string;
  isChecked?: boolean;
  checkmarkStyle?: boolean;
  onClick?: () => void;
}

function Checkbox(props: iProps): JSX.Element {
  const { className, isChecked, checkmarkStyle, onClick } = props;

  const onClickHandler = useCallback(() => {
    // event.stopPropagation();
    if (onClick) onClick();
  }, [onClick]);

  return checkmarkStyle ? (
    <div
      className={`${styles.main} ${className}`}
      onClick={() => onClickHandler()}
    >
      <input type="checkbox" checked={isChecked} />
      <span className={styles.checkmarkstyle} />
    </div>
  ) : (
    <div
      className={`${className} ${styles.container}`}
      onClick={() => onClickHandler()}
    >
      <input type="checkbox" checked={isChecked} />
      <span className={styles.checkmark} />
    </div>
  );
}

Checkbox.defaultProps = {
  className: '',
  isChecked: false,
  checkmarkStyle: false,
  onClick: () => {},
};

export default Checkbox;

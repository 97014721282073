import Button, { ButtonVariants } from 'components/button/button';
import React, { useCallback } from 'react';
import { useStore } from 'store';
import { playlistPageActions } from 'store/actions';
import { iState } from 'types';
import styles from './footer.module.scss';

interface iProps {
  className?: string;
  onClickCancel: () => void;
}

function Index(props: iProps): JSX.Element {
  const { className, onClickCancel } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { isPlaylistSaveAndCloseBtnDisabled } = state.playlistsPage;
  const updatePlaylistHandler = useCallback(() => {
    dispatch(playlistPageActions.invokeUpdatePlaylist(true));
  }, [dispatch]);
  return (
    <footer className={`${styles.footer} ${className}`}>
      <Button
        className={styles.btns}
        variant={ButtonVariants.MediumTransp}
        btnName="Cancel"
        onClick={onClickCancel}
      />

      <Button
        className={styles.btns}
        variant={ButtonVariants.MediumStd}
        btnName="Save and close"
        isDisabled={isPlaylistSaveAndCloseBtnDisabled}
        onClick={updatePlaylistHandler}
      />
    </footer>
  );
}

Index.defaultProps = {
  className: '',
};

export default Index;

import { resetPassword } from 'apis';
import Button, { ButtonVariants } from 'components/button/button';
import LabelAndInput from 'components/label-and-input/labelAndInput';
import Logo from 'components/logo/logo';
import { ROUTES } from 'constants/index';
import { parseJwt, validators } from 'helpers';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { userActions } from 'store/actions';
import { iState } from 'types';
import Pattern from '../components/pattern/pattern';
import styles from './resetPassword.module.scss';

function ResetPassword(): JSX.Element {
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [, dispatch]: [iState, any] = useStore();

  const navigate = useNavigate();

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setEmail(event.target.value);
      setErrorMessage('');
    },
    []
  );

  const sendResetInstruction = useCallback(async () => {
    //  console.log(email);
    if (!email) {
      setErrorMessage('Please enter an email id');
      return;
    }
    if (!validators.isEmail(email)) {
      setErrorMessage('Please enter a valid email id');
      return;
    }
    const response = await resetPassword(
      { email: email.toLocaleLowerCase().trim() },
      setIsLoading
    );
    if (!response) {
      setErrorMessage(`Email id doesn't exist to reset password`);
      return;
    }
    if (response) {
      dispatch(
        userActions.setResetPasswordData({
          email,
          userId: parseJwt(response.jwtToken).id,
        })
      );
      navigate(ROUTES.verifyEmail);
    }
  }, [email, navigate, dispatch]);

  return (
    <div
      className={`flex flex-col p3 ${styles.resetPasswordWrapper}`}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          sendResetInstruction();
        }
      }}
    >
      <Pattern position="top" />
      <Pattern position="bottom" />
      <section className={`flex-center flex-grow ${styles.section}`}>
        <div className={`${styles.resetPasswordContainer}`}>
          <Logo className={styles.logo} variant="onboarding" />
          <p className={styles.resetPassword}>Reset password</p>
          <p className={styles.enterDetails}>
            If you have misplaced your password, you can change it here.
          </p>

          <LabelAndInput
            className={styles.enterEmail}
            label="Email address"
            placeholder="name@domain.com"
            value={email}
            onChange={onChange}
            errorMessage={errorMessage}
          />

          <Button
            className={styles.sendInstructions}
            btnName="Send reset instructions"
            variant={ButtonVariants.MediumStd}
            onClick={sendResetInstruction}
            isLoading={isLoading}
          />
        </div>
      </section>
    </div>
  );
}

export default ResetPassword;

import React, { useCallback, useState } from 'react';
import {
  caretFilledSmallDown,
  microsoftOfficeIcon,
  informationIcon,
  norwayData,
} from 'constants/index';
import Popover from 'components/popover/popover';
import { uuid } from 'helpers';
import { iOffice365DropdownOption } from 'types';
import Input from 'components/input/input';
import { useStore } from 'store';
import { LanguageType } from 'enums';
import styles from './settingsDropdown.module.scss';

interface iProps {
  className?: string;
  heading: string;
  isShowMSIcon?: boolean;
  options: iOffice365DropdownOption[];
  choosedOption?: iOffice365DropdownOption;
  onChange: (a: iOffice365DropdownOption) => void;
  timeZoneDropdown?: boolean;
}

const DEFAULT_VALUE = {
  id: uuid(),
  option: 'Empty',
  optionNorway: norwayData.empty,
  icon: informationIcon,
  value: '',
};

function ContentTypeDropdown(props: iProps): JSX.Element {
  const {
    className,
    heading,
    options,
    isShowMSIcon,
    onChange,
    choosedOption = DEFAULT_VALUE,
    timeZoneDropdown,
  } = props;
  const [search, setSearch] = useState('');
  const [state] = useStore();

  const { selectedLanguage } = state.office365 || {};

  // const [choosedOption, setChoosedOption] = useState(options[0]);

  const { icon, option, optionNorway } = choosedOption || {};

  const onChoose = useCallback(
    (choosed: iOffice365DropdownOption) => {
      // setChoosedOption(choosed);
      setSearch('');
      onChange(choosed);
    },
    [onChange]
  );

  return (
    <div className={`${styles.contentTypeDropdown} ${className}`}>
      <div className={styles.sectionHeadingContainer}>
        <p className={styles.sectionHeading}>{heading}</p>
        {isShowMSIcon && (
          <img className={styles.msIcon} src={microsoftOfficeIcon} alt="" />
        )}
      </div>
      <Popover
        popoverClassName={styles.popoverClassName}
        popoverComponent={
          <ul className={styles.viewOptions}>
            {options
              .filter((opt) =>
                opt.option
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase())
              )
              .map((i) => (
                <li className={styles.option} onClick={() => onChoose(i)}>
                  {i.icon && (
                    <img className={styles.icon} src={i.icon} alt="" />
                  )}
                  {(selectedLanguage.value === LanguageType.English ||
                    timeZoneDropdown) &&
                    i.option}
                  {selectedLanguage.value === LanguageType.Norwegian &&
                    i.optionNorway}
                </li>
              ))}
          </ul>
        }
      >
        <div className={styles.dropdownChoosedOption}>
          {icon && <img className={styles.icon} src={icon} alt="" />}
          {!timeZoneDropdown && (
            <p className={styles.option}>
              {selectedLanguage.value === LanguageType.English && option}
              {selectedLanguage.value === LanguageType.Norwegian &&
                optionNorway}
            </p>
          )}
          {timeZoneDropdown && (
            <Input
              placeholder={option}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              hideBorder
            />
          )}
          <img
            className={styles.dropdownIcon}
            src={caretFilledSmallDown}
            alt=""
          />
        </div>
      </Popover>
    </div>
  );
}

ContentTypeDropdown.defaultProps = {
  className: '',
  isShowMSIcon: false,
  choosedOption: DEFAULT_VALUE,
  timeZoneDropdown: false,
};

export default ContentTypeDropdown;

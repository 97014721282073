import { gql } from 'apollo-boost';

export enum Common {
  AddFolder = 'addFolder',
  GetFoldersByCompany = 'getFoldersByCompany',
  GetFolderById = 'getFolderbyid',
  DeleteFolder = 'deleteFolder',
  UpdateFolder = 'updateFolder',
}

export const addFolderMutation = gql`
    mutation ${Common.AddFolder}(
      $parentId: String
      $companyId: String!
      $name: String!
      $folderType: FolderType
      $companyUserId: String!
      $userId: String!
      ){
        ${Common.AddFolder}(
          parentId: $parentId
          companyId: $companyId
          name: $name
          folderType: $folderType
          companyUserId: $companyUserId
          userId: $userId
          ){
            message
            statusCode
            data {
              id
              url
              parentFolders {
                id
                name
              }
              company
              folder_path
              name
              folderType
              created_at
              updated_at
              subFolders {
                id
                url
                parentFolders {
                  id
                  name
                }
                company
                folder_path
                name
                folderType
                created_at
                updated_at
              }
            }
        }
    }
`;

export const getAllFoldersQuery = gql`
    query ${Common.GetFoldersByCompany}(
        $companyId: String!
        $folderType: FolderType!
        $companyUserId: String
    ){
      ${Common.GetFoldersByCompany}(
        companyId: $companyId
        folderType: $folderType
        companyUserId: $companyUserId
      ){
        statusCode
        message
        data {
          id
          url
          parentFolders {
            id
                name
          }
          company
          folder_path
          name
          folderType
          created_at
          updated_at
          owner{
            id
          }
          sharedAt
        }
      }
    }
`;

export const getFolderByIdQuery = gql`
    query ${Common.GetFolderById}(
        $id: String
    ){
      ${Common.GetFolderById}(
        id: $id
      ){
        statusCode
        message
        data {
          id
          url
          parentFolders {
            id
                name
          }
          company
          folder_path
          name
          folderType
          created_at
          updated_at
          sharedAt
          owner{
            id
          }
          subFolders {
            id
            url
            parentFolders {
              id
                name
            }
            company
            folder_path
            name
            folderType
            created_at
            updated_at
          }
        }
      }
    }
`;

export const deleteFolderMutation = gql`
    mutation ${Common.DeleteFolder}(
      $id: String!
    ){
      ${Common.DeleteFolder}(
        id: $id
      ){
        statusCode
        message
        data {
          deleted
        }
      }
    }
`;

export const updateFolderMutation = gql`
    mutation ${Common.UpdateFolder}(
      $name: String!
      $id: String!
      $foldertype: FolderType
    ){
      ${Common.UpdateFolder}(
        name: $name
        id: $id
        foldertype: $foldertype
      ){
        statusCode
        message
        data {
          id
          url
          parentFolders {
            id
                name
          }
          company
          folder_path
          name
          folderType
          created_at
          updated_at
          subFolders {
            id
            url
            parentFolders {
              id
                name
            }
            company
            folder_path
            name
            folderType
            created_at
            updated_at
          }
        }
      }
    }
`;

import Content from 'components/content/content';
import EmptyState, {
  EmptyStateVariants,
} from 'components/empty-state/emptyState';
// import FileUploadProgress, {
//   FileUploadProgressVariants,
// } from 'components/file-upload-progress/fileUploadProgress';
import DeleteModal, {
  DeleteModalVariants,
} from 'components/delete-modal/deleteModal';
import { deleteContent } from 'apis';
import SubFolderEmptyState, {
  SubFolderEmptyStatesVariants,
} from 'components/sub-folder-empty-state/subFolderEmptyState';
import FoldersSkeleton from 'components/skeletons/folders-skeleton/foldersSkeleton';
import ListingSkeleton from 'components/skeletons/listing-skeleton/listingSkeleton';
import { CONTENT_TYPE } from 'constants/index';
import Folders from 'components/folders/folders';
import { ModalViews, DeleteStatus, ContentPermissions } from 'enums';
import React, { useRef, useCallback, useState } from 'react';
import { useStore } from 'store';
import { s3Operations, toaster } from 'helpers';
import {
  contentPageActions,
  homePageActions,
  layoutActions,
} from 'store/actions';
// import { contentPageActions } from 'store/actions';
import { iContentData, iState } from 'types';
import ContentDetails from '../content-details/contentDetails';
// import SubFolderEmptyState from '../sub-folder-empty-state/subFolderEmptyState';
import styles from './contentList.module.scss';

interface iProps {
  className?: string;
  data: iContentData[];
  isLoading: boolean;
}

const confirmationMsg = (type: string): string => {
  return `This ${
    (type === CONTENT_TYPE.Image && 'image') ||
    (type === CONTENT_TYPE.Video && 'video') ||
    (type === CONTENT_TYPE.Website && 'web link') ||
    ''
  } is currently used in live screens or playlists.`;
};

const usageNotification = (type: string): string => {
  return `If you delete this ${
    (type === CONTENT_TYPE.Image && 'image') ||
    (type === CONTENT_TYPE.Video && 'video') ||
    (type === CONTENT_TYPE.Website && 'web link') ||
    ''
  } it will also be removed from these places.`;
};

function ContentList(props: iProps): JSX.Element {
  const { className, data, isLoading } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { contentPage, layout } = state || {};
  const [contentToDelete, setContentToDelete] = useState<iContentData | null>();
  const [isContentUsedSomeWhere, setIsContentUsedSomeWhere] =
    useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { contentFolders, contentActiveFolder } = contentPage || {};
  const { chosenView, isShowDeleteContent } = layout || {};
  const { userPermissions } = state.user || {};
  const { deleteContentAndFolder = true } = userPermissions || {};

  const containerRef: any = useRef(null);
  // const sharedFolder: any = [
  //   {
  //     company: null,
  //     created_at: '2023-01-16T10:41:39.746Z',
  //     folderType: 'SCREENZ',
  //     folder_path: null,
  //     id: 'ac0df8b8-835a-4898-beef-cc7d954723a0',
  //     name: 'Folder shared with user',
  //     parentFolders: null,
  //     updated_at: '2023-01-16T10:41:39.746Z',
  //     url: null,
  //     __typename: 'Folder',
  //     isActive: true,
  //   },
  // ];

  const confirmToDelete = useCallback(
    async (content: iContentData) => {
      const isWebPage = content.contentType === CONTENT_TYPE.Website;
      const isDeleted = isWebPage
        ? true
        : await s3Operations.delete({
            Key: content.url,
          });

      if (isDeleted) {
        const deletedPermanently = await deleteContent(
          {
            id: content.id,
            deleteContent: true,
          },
          setIsDeleting
        );
        if (deletedPermanently === DeleteStatus.Deleted) {
          dispatch(contentPageActions.fetchContentsData(true));
          dispatch(layoutActions.toggleModal());
          setIsContentUsedSomeWhere(false);
          setContentToDelete(null);
          toaster.success('Content has deleted successfully');
        }
      }
    },
    [dispatch]
  );

  const deleteContentHandler = useCallback(
    async (cont) => {
      if (!cont) return;

      // if (isContentUsedSomeWhere) {
      //   confirmToDelete(contentToDelete);
      // }
      const verification = await deleteContent(
        {
          id: cont.id,
          deleteContent: false,
        },
        setIsDeleting
      );
      if (verification === DeleteStatus.UsedSomewhere) {
        setIsContentUsedSomeWhere(true);
        dispatch(layoutActions.toggleModal(ModalViews.isShowDeleteContent));
      }
      if (verification === DeleteStatus.CanDelete)
        // confirmToDelete(cont);
        dispatch(layoutActions.toggleModal(ModalViews.isShowDeleteContent));
    },
    [contentToDelete, confirmToDelete]
  );

  const openDeleteContentModal = useCallback(
    (content) => {
      deleteContentHandler(content);
      setContentToDelete(content);
    },
    [dispatch]
  );

  //  console.log('isContentUsedSomeWhere_____', isContentUsedSomeWhere);
  const { contentAcces } = userPermissions || {};

  return (
    <>
      <div
        className={`${className} ${styles.listAndDetails} 
      ${styles.displayboth}`}
      >
        <div
          className={`${styles.foldersAndScreensWrapper} ${styles[chosenView]}`}
        >
          {isLoading ? (
            <FoldersSkeleton />
          ) : (
            <Folders
              className={styles.foldersWrapper}
              data={contentFolders}
              openFolder={(folder) => {
                dispatch(homePageActions.setIsFetchingFolders(true));
                dispatch(contentPageActions.setContentActiveFolder(folder));
              }}
              isHavingAllAccess={
                contentAcces === ContentPermissions.AllContents
              }
            />
          )}
          {/* {isLoading ? (
            <FoldersSkeleton />
          ) : (
            <Folders
              className={styles.foldersWrapper}
              data={sharedFolder}
              openFolder={(folder) => {
                dispatch(homePageActions.setIsFetchingFolders(true));
                dispatch(contentPageActions.setContentActiveFolder(folder));
              }}
            />
          )} */}

          {!isLoading &&
            contentFolders &&
            contentFolders?.length === 0 &&
            !contentActiveFolder &&
            data?.length === 0 && (
              <EmptyState
                className={styles.fitHeight}
                variant={EmptyStateVariants.Content}
              />
            )}
          {((contentFolders && contentFolders?.length > 0) ||
            contentActiveFolder) &&
            data?.length === 0 &&
            !isLoading && (
              <SubFolderEmptyState
                variant={SubFolderEmptyStatesVariants.Contents}
              />
            )}
          {isLoading ? (
            <ListingSkeleton />
          ) : (
            data?.length > 0 && (
              <div className={styles.listContainer} ref={containerRef}>
                <div className={styles.listHeader}>
                  <p>Name</p>
                  <p>Type</p>
                  <p>Owner</p>
                  <p>Modified</p>
                </div>
                <div id="contentListingContainer">
                  {data.map(
                    (i, index) =>
                      i && (
                        <Content
                          isLastContent={index === data.length - 1}
                          data={i}
                          deleteContent={() => {
                            if (deleteContentAndFolder) {
                              openDeleteContentModal(i);
                              return;
                            }
                            toaster.error('You have no access to delete');
                          }}
                        />
                      )
                  )}
                </div>
                {/* {uploadingFiles?.length > 0 && !isShowAddContent && (
                  <div className={styles.uploadingFilesContainer}>
                    {uploadingFiles.map((i) => (
                      <FileUploadProgress
                        variant={FileUploadProgressVariants.OffModal}
                        data={i}
                        // cancel={() => removeFileFromUpload(index)}
                      />
                    ))}
                  </div>
                )} */}
              </div>
            )
          )}
        </div>

        <ContentDetails
          className={`${styles.detailView} ${styles[chosenView]}`}
          isLoading={isLoading}
        />
      </div>
      {contentToDelete && (
        <DeleteModal
          isOpen={isShowDeleteContent}
          variant={DeleteModalVariants.Content}
          name={contentToDelete?.name || ''}
          isLoading={isDeleting}
          contentType={contentToDelete?.contentType}
          // isUsedSomewhere={isContentUsedSomeWhere}
          onClose={() => setIsContentUsedSomeWhere(false)}
          confirmationMsg={
            (isContentUsedSomeWhere &&
              confirmationMsg(contentToDelete.contentType)) ||
            ''
          }
          usageNotification={
            (isContentUsedSomeWhere &&
              usageNotification(contentToDelete.contentType)) ||
            ''
          }
          // confirmationMsg="This image is currently used in live screens or playlists."
          // usageNotification="If you delete this image it will also be removed from these places."
          deleteHandler={
            // isContentUsedSomeWhere
            //   ?
            () => confirmToDelete(contentToDelete)
            // : deleteContentHandler
          }
          displayInput={false}
        />
      )}
    </>
  );
}

ContentList.defaultProps = {
  className: '',
};

export default ContentList;

import Checkbox from 'components/checkbox/checkbox';
import PortraitScreen from 'components/svg/portraitScren';
import Screen from 'components/svg/screenz';
import { clockIcon, playlistsIcon } from 'constants/index';
import { ScreenType } from 'enums';
import React, { useCallback } from 'react';
import { useStore } from 'store';
import { homePageActions } from 'store/actions';
// import { playlistPageActions } from 'store/actions';
import { iPlaylistData, iState } from 'types';
import styles from './playlists.module.scss';
import { PlaylistVariants, Variants } from './variant';

interface iProps {
  className?: string;
  variant: PlaylistVariants;
  onClick?: (data: iPlaylistData) => void;
  foundPlaylists?: iPlaylistData[] | null;
}

function Playlists(props: iProps): JSX.Element {
  const { className, variant, onClick, foundPlaylists } = props;
  const { showCheckBox, showDesktopIcon, showSubPlaylistName } =
    Variants[variant];

  const [state, dispatch]: [iState, any] = useStore();
  const { contentToAddToPlaylist, playlists } = state.playlistsPage || {};
  const { playlistToAssignScreen } = state.homePage || {};

  const onClickContent = useCallback(
    (playlist: iPlaylistData) => {
      dispatch(homePageActions.setPlaylistToAssignToScreen(playlist.id));
      if (onClick) onClick(playlist);
    },
    [onClick, dispatch]
  );

  const data =
    Array.isArray(foundPlaylists) && foundPlaylists?.length > 0
      ? foundPlaylists
      : playlists;

  return (
    <div className={`${className} ${styles.playlists}`}>
      {data.map((playlist: iPlaylistData) => {
        const found = contentToAddToPlaylist.find((i) => i.id === playlist.id);

        const isSelected =
          found !== (null || undefined) ||
          playlistToAssignScreen === playlist.id;
        return (
          <div
            className={`${styles.playlist} ${isSelected && styles.active}`}
            onClick={() => onClickContent(playlist)}
          >
            <div className={styles.colOne}>
              {showCheckBox && (
                <Checkbox
                  className={styles.checkBox}
                  isChecked={isSelected}
                  onClick={() => onClickContent(playlist)}
                />
              )}
              <img
                className={styles.playlistsIcon}
                src={playlistsIcon}
                alt=""
              />
              <div className={styles.playlistName}>
                <p>
                  {playlist.name.length > 25
                    ? `${playlist.name.slice(0, 22)}...`
                    : playlist.name}
                </p>
                {showSubPlaylistName && <span>Playlist</span>}
              </div>
            </div>
            <div className={styles.colTwo}>
              {showDesktopIcon && (
                // <img className={styles.playlistsIcon} src={screenIcon} alt="" />
                <>
                  {playlist.screenType === ScreenType.HORIZONTAL && (
                    <Screen className={styles.screenIcon} />
                  )}
                  {playlist.screenType === ScreenType.VERTICAL && (
                    <PortraitScreen className={styles.screenIcon} />
                  )}
                </>
              )}
              <img className={styles.clockIcon} src={clockIcon} alt="" />
              <span className={styles.playlistLength}>{playlist.duration}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

Playlists.defaultProps = {
  className: '',
  onClick: () => {},
  foundPlaylists: [],
};

export { PlaylistVariants };
export default Playlists;

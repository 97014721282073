import { refreshIcon } from 'constants/index';
import React from 'react';
import { useStore } from 'store';
import { homePageActions } from 'store/actions';
import { iState } from 'types';
import styles from './pingTime.module.scss';

interface iProps {
  className?: string;
  isLoading: boolean;
  lastPing: string;
  refresh?: () => void;
}

function PingTime(props: iProps): JSX.Element {
  const { className, isLoading, lastPing, refresh } = props;
  const [, dispatch]: [iState, any] = useStore();

  let date = '';
  if (lastPing)
    date = `${new Date(lastPing).toLocaleString('en-us', {
      weekday: 'short',
    })},
    ${new Date(lastPing).toLocaleString('en-us', {
      month: 'short',
    })}
    ${new Date(lastPing).getDate()}, ${new Date(
      lastPing
    ).getFullYear()} ${new Date(lastPing).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })} `;

  return (
    <div className={`${styles.timeAndRefresh} ${className}`}>
      <p className={styles.pingTime}>
        <span>Last ping: </span>
        {date && <span>{date}</span>}
        <span>device time</span>
      </p>
      <div
        className={`${styles.refreshIcon} `}
        onClick={() => {
          if (refresh) {
            refresh();
            dispatch(homePageActions.invokeGetScreenById(true));
          } else {
            dispatch(homePageActions.invokeGetScreenById(true));
          }
        }}
      >
        <img
          className={isLoading && styles.refreshAnimation}
          src={refreshIcon}
          alt=""
        />
      </div>
    </div>
  );
}

PingTime.defaultProps = {
  className: '',
  refresh: () => {},
};

export default PingTime;

import { iPlaylistContents } from 'types';
import { CONTENT_TYPE } from 'constants/index';

const getVideoLength = (content): any => {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = (): void => {
      window.URL.revokeObjectURL(video.src);
      resolve(Math.floor(video.duration));
    };
    video.src = content.url || '';
  });
};

export const mapContentPrimaryDuration = async (
  contentToAddToPlaylist: iPlaylistContents[],
  currentLength: number
): Promise<any> => {
  const prepareContents: iPlaylistContents[] = [];
  /* eslint no-restricted-syntax: ["error"] */
  for (const content of contentToAddToPlaylist) {
    //  console.log('mapContentPrimaryDuration____', content);

    if (content.contentType === CONTENT_TYPE.Video) {
      const duration = await getVideoLength(content);
      prepareContents.push({
        ...content,
        id: content.id,
        duration: `${duration}`,
        sortOrder: currentLength + prepareContents.length + 1,
      });
    } else {
      const duration = content.duration || '10';
      prepareContents.push({
        ...content,
        id: content.id,
        duration,
        sortOrder: currentLength + prepareContents.length + 1,
      });
    }
  }
  //  console.log('prepareContents_____', {
  //   prepareContents,
  //   contentToAddToPlaylist,
  // });

  return prepareContents;
};

import { getUserByCompanyId } from 'apis';
import { InvitedTableColumns } from 'constants/index';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTable } from 'react-table';
import { useStore } from 'store';
import { iState, iUserData } from 'types';
import InvitedAvatar from './components/invited-avatar/invitedAvatar';
import Resend from './components/resend/resend';
import styles from './invited.module.scss';

function Invited({
  resendingInviteTo,
  revokingInviteTo,
  isResending,
  isRevoking,
  resend,
  revoke,
  canRefetchInvited,
  setCanRefetchInvited,
}): JSX.Element {
  const [, setIsLoading] = useState<boolean>(false);
  const [state]: [iState] = useStore();
  const { homePage } = state || {};
  const [invitedUser, setInvitedUser] = useState<Array<iUserData>>([]);
  const { companyData } = homePage || {};
  const { id: companyId } = companyData || {};
  //  console.log({ isLoading });
  const columns = useMemo(() => InvitedTableColumns, []);
  const data = useMemo(() => invitedUser, [invitedUser]);

  const tableInstance = useTable({
    columns,
    data,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const getUsers = useCallback(async () => {
    if (!companyId) return;
    const response = await getUserByCompanyId(
      { company: companyId },
      setIsLoading
    );
    if (response) {
      //  console.log({ response });
      const parsed = response
        .filter(
          (i) => i.invitedStatus === 'INVITED' || i.invitedStatus === 'REVOKED'
        )
        .map((i) => ({
          ...i,
          user: {
            name: i.first_name,
            lastName: i.last_name,
            email: i.email,
            profilePic: i.profile_pic,
            invitedStatus: i.invitedStatus,
          },
        }));
      setInvitedUser(parsed);
      setCanRefetchInvited(false);
    }
  }, [companyId, setCanRefetchInvited]);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (canRefetchInvited) getUsers();
  }, [canRefetchInvited, getUsers]);

  const getDateFormatedDate = (dateTime: string): string => {
    if (!dateTime) return '';
    const date = new Date(dateTime);
    return (
      (date &&
        `${date.getDate()}.${
          date.getMonth() + 1
        }.${date.getFullYear()} ${date.getHours()}.${date.getMinutes()}`) ||
      ''
    );
  };

  const revokeHandler = useCallback(
    async (user) => {
      const response = await revoke(user);
      if (response) {
        getUsers();
      }
      //  console.log('revokeHandler_____', response);
    },
    [revoke, getUsers]
  );

  return (
    <table {...getTableProps()} className={styles.tableHead}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                const { id } = cell.column || {};
                const { role } = row.original || {};
                const userAvatar = id === 'user';
                const sentDetails = id === 'sent';
                const actionsDetails = id === 'actions';
                const isShowPermission = id === 'permission';
                const permissions =
                  (role.id === ('1' || '2') && 'Full Permission') ||
                  'Limited Permission';

                //  console.log('row_original___', cell, sentDetails);

                return (
                  <td
                    {...cell.getCellProps()}
                    className={`${id === 'sentDetails' && styles.sentDetails}`}
                  >
                    {userAvatar ? (
                      <InvitedAvatar data={cell.value} />
                    ) : (
                      cell.value
                    )}
                    {isShowPermission && permissions}
                    {sentDetails &&
                      getDateFormatedDate(cell.row.original.emailSentTime)}
                    {actionsDetails && (
                      <Resend
                        // resendingInviteTo
                        // revokingInviteTo
                        isRevoked={
                          row.original.invitedStatus === 'REVOKED' || false
                        }
                        isResending={
                          (resendingInviteTo === row.original.id &&
                            isResending) ||
                          false
                        }
                        isRevoking={
                          (revokingInviteTo === row.original.id &&
                            isRevoking) ||
                          false
                        }
                        resend={() => resend(row.original)}
                        revoke={() => revokeHandler(row.original)}
                      />
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
export default Invited;

import Popover from 'components/popover/popover';
import CaretFilledDown from 'components/svg/caretfilledDown';
import { contentIcon, playlistsIcon, ROUTES } from 'constants/index';
import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router';
import { useStore } from 'store';
import {
  // contentPageActions,
  // homePageActions,
  layoutActions,
  // playlistPageActions,
} from 'store/actions';
import styles from './playlistContentPopover.module.scss';

interface iProps {
  className?: string;
  setView: (a: 'Playlists' | 'Contents') => void;
  parentView?: string | null;
}

const SCREENS_VIEW_OPTIONS = [
  { id: 0, icon: playlistsIcon, option: 'Playlists' },
  { id: 1, icon: contentIcon, option: 'Contents' },
];

const PLAYLIST_VIEW_OPTIONS = [
  // { id: 1, icon: contentIcon, option: 'Contents' },
];

function PlaylistContentPopover(props: iProps): JSX.Element {
  const { className, setView, parentView } = props;
  const { pathname } = useLocation();
  const [, dispatch] = useStore();
  const [activeView, setActiveView] = useState(
    (pathname === ROUTES.playlist && PLAYLIST_VIEW_OPTIONS[0]) ||
      (parentView === 'Contents' && SCREENS_VIEW_OPTIONS[1]) ||
      SCREENS_VIEW_OPTIONS[0]
  );
  const [dropDown, setDropDown] = useState(false);

  const clear = useCallback(() => {
    // dispatch(homePageActions.setScreensActiveFolder(null));
    // dispatch(homePageActions.setScreensFolderHierarchy([]));
    // dispatch(contentPageActions.setContentActiveFolder(null));
    // dispatch(contentPageActions.setContentFolderHierarchy([]));
    // dispatch(playlistPageActions.setPlaylistsActiveFolder(null));
    // dispatch(playlistPageActions.setPlaylistsFolderHierarchy([]));
    dispatch(layoutActions.setAssignModalContentActiveFolders(null));
    dispatch(layoutActions.setAssignModalContentsFolderHierarchy([]));
    dispatch(layoutActions.setAssignModalContentActiveFolders(null));
    dispatch(layoutActions.setAssignModalContentFolders([]));
    dispatch(layoutActions.setAssignModalContentsFolderHierarchy([]));
  }, [dispatch]);

  const onClick = useCallback(
    (view) => {
      dispatch(layoutActions.invokeGetAllFolders(true));
      setActiveView(view);
      setView(view.option);
      clear();
    },
    [setView]
  );

  const VIEWS =
    (pathname === ROUTES.playlist && PLAYLIST_VIEW_OPTIONS) ||
    SCREENS_VIEW_OPTIONS;

  if (VIEWS.length === 0) return <div />;
  return (
    <Popover
      popoverClassName={styles.viewPopover}
      popoverComponent={
        <ul className={styles.viewOptions}>
          {VIEWS.map((i) => (
            <li className={styles.option} onClick={() => onClick(i)}>
              <img className={styles.icon} src={i.icon} alt="" />
              {i.option}
            </li>
          ))}
        </ul>
      }
      setPopoverEffect={setDropDown}
      popOverEffect={dropDown}
    >
      <div
        className={`${className} ${styles.playlistDropdown} ${
          dropDown && styles.active
        }`}
      >
        <img className={styles.playlistsIcon} src={activeView.icon} alt="" />
        <span className={styles.playlistName}>{activeView.option}</span>
        <CaretFilledDown className={styles.caretFilledDropdownIcon} />
      </div>
    </Popover>
  );
}

PlaylistContentPopover.defaultProps = {
  className: '',
  parentView: null,
};

export default PlaylistContentPopover;

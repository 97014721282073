import React, { useCallback, useEffect, useState } from 'react';
import { iState } from 'types';
import { useStore } from 'store';
import { useNavigate } from 'react-router-dom';
import { contentPageActions } from 'store/actions';
import { CONTENT_TYPE, ROUTES } from 'constants/index';
import { toaster } from 'helpers';
import { ScreenType } from 'enums';
import { addContent, editWebContent } from 'apis';
import Button, { ButtonVariants } from 'components/button/button';
import styles from './buttons.module.scss';

interface iProps {
  className?: string;
}

function Buttons(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user, contentPage } = state || {};
  const {
    contentActiveFolder,
    addWebContentData,
    isEditWebContent,
    contentView,
    isTriedToLoadWebsiteTwo,
  } = contentPage || {};
  const { companyData } = state.homePage;
  const navigate = useNavigate();

  //  console.log('contentPage____', { contentPage, contentView });

  const addWebContent = useCallback(
    async ({ url, webSiteName, companyId, orintation }) => {
      const response = await addContent(
        {
          name: webSiteName,
          contentType: CONTENT_TYPE.Website,
          company: companyId,
          url: url.replaceAll(/\s/g, ''),
          size: '',
          dimension: '',
          orientation: orintation,
          folder: contentActiveFolder?.id,
          thumbnailPath: '',
          user: user.loggedInData ? user.loggedInData?.id : '',
        },
        setIsLoading
      );

      if (response) {
        toaster.success('Webpage added successfully');
        dispatch(contentPageActions.setAddWebContentData(null));
        navigate(ROUTES.content);
      }
    },
    [contentActiveFolder?.id, dispatch, navigate, user.loggedInData]
  );

  const updateWebContent = useCallback(
    async ({ id, url, webSiteName, orintation }) => {
      const response = await editWebContent(
        {
          id,
          name: webSiteName,
          orientation: orintation,
          dimension: '',
          url,
        },
        setIsLoading
      );

      if (response) {
        toaster.success('Webpage added successfully');
        dispatch(contentPageActions.setAddWebContentData(null));
        navigate(ROUTES.content);
      }
    },
    [dispatch, navigate]
  );

  const addWebpageHandler = useCallback(async () => {
    const {
      url,
      webSiteName,
      // isLoadableInIFrame,
      isTriedToLoadWebsite,
      orintation,
    } = addWebContentData || {};

    const { id } = contentView || {};

    if (!url) {
      toaster.error('Please enter an URL');
      return;
    }
    if (!webSiteName) {
      toaster.error('Please enter a name to remember');
      return;
    }
    if (!isTriedToLoadWebsite) {
      toaster.error('Please click on the "Load Website" to validate URL');
      return;
    }
    if (!isTriedToLoadWebsiteTwo) {
      toaster.error(
        'Please click on the "Load Website" to validate Orientation'
      );
      return;
    }
    // if (!isLoadableInIFrame) {
    //   toaster.error('This url can not be loaded in the iframe');
    //   return;
    // }
    if (!companyData) {
      toaster.error('Company data not found');
      return;
    }
    if (isEditWebContent && id) {
      updateWebContent({
        id,
        url,
        webSiteName,
        orintation,
      });
    } else {
      addWebContent({
        url,
        webSiteName,
        companyId: companyData.id,
        orintation,
      });
    }

    // const response = await addContent(
    //   {
    //     name: webSiteName,
    //     contentType: CONTENT_TYPE.Website,
    //     company: companyData.id,
    //     url,
    //     size: '',
    //     dimension: '',
    //     folder: contentActiveFolder?.id || null,
    //     thumbnailPath: '',
    //     user: user.loggedInData ? user.loggedInData?.id : '',
    //   },
    //   setIsLoading
    // );
    // if (response) {
    //   toaster.success('Webpage added successfully');
    //   dispatch(contentPageActions.setAddWebContentData(null));
    //   navigate(ROUTES.content);
    // }
  }, [
    addWebContent,
    isEditWebContent,
    companyData,
    addWebContentData,
    contentView,
    updateWebContent,
    isTriedToLoadWebsiteTwo,
  ]);

  useEffect(() => {
    //  console.log('isTriedToLoadWebsiteTwothree', isTriedToLoadWebsiteTwo);
  }, [isTriedToLoadWebsiteTwo]);

  const cancelWebContent = useCallback(() => {
    dispatch(contentPageActions.setIsEditWebContent(false));
    dispatch(
      contentPageActions.setAddWebContentData({
        url: '',
        webSiteName: '',
        isLoadableInIFrame: false,
        isTriedToLoadWebsite: false,
        orintation: ScreenType.VERTICAL,
      })
    );
    navigate(ROUTES.content);
  }, [navigate, dispatch]);

  return (
    <div className={`${styles.buttons} ${className}`}>
      <Button
        className={styles.btns}
        btnName="Cancel"
        variant={ButtonVariants.SmallTransp}
        onClick={cancelWebContent}
      />
      <Button
        className={styles.btns}
        btnName={isEditWebContent ? 'Save changes' : 'Save webpage'}
        variant={ButtonVariants.SmallStd}
        onClick={addWebpageHandler}
        isLoading={isLoading}
      />
    </div>
  );
}

Buttons.defaultProps = {
  className: '',
};

export default Buttons;

import React from 'react';
import { useStore } from 'store';
// import { CARD_NAME } from 'enums';
import EventView from './event-view/eventView';
import TemplateSettings from './template-settings-bar/templateSettingsBar';
import styles from './meetingMessageOverviewSection.module.scss';

interface iProps {
  className?: string;
}

function MainSection(props: iProps): JSX.Element {
  const { className } = props;
  const [state] = useStore();

  const { currentCard } = state.meetingOverviewFull || {};
  return (
    <div className={`${styles.calenderEventsMainSection} ${className}`}>
      <EventView />
      <TemplateSettings cardNumber={currentCard} />
    </div>
  );
}

MainSection.defaultProps = {
  className: '',
};

export default MainSection;

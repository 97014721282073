import React from 'react';
import Input from 'components/input/input';
import { norwayData } from 'constants/index';
import styles from './nameEditor.module.scss';

interface iProps {
  className?: string;
  label: string;
  value: string;
  isDisabled?: boolean;
  onChange: (a: any) => void;
  textArea?: boolean;
}

function Index(props: iProps): JSX.Element {
  const {
    className,
    label,
    value,
    isDisabled = false,
    onChange,
    textArea = false,
  } = props;
  const addExtraWidth =
    label === 'Title' ||
    label === norwayData.title ||
    label === 'Subtext' ||
    label === norwayData.subText;
  const addExtraBodyWidth = label === 'Body' || label === norwayData.body;

  return (
    <div className={`${styles.nameContainer} ${className}`}>
      <p className={styles.label}>{label}</p>
      {!textArea && (
        <Input
          className={`${styles.customNameInput} ${
            addExtraWidth && styles.extraWidth
          } ${addExtraBodyWidth && styles.addExtraBodyWidth}`}
          value={value}
          disabled={isDisabled}
          onChange={onChange}
        />
      )}
      {textArea && (
        <textarea
          className={`${styles.textAreaContent} ${
            addExtraWidth && styles.extraWidth
          } ${addExtraBodyWidth && styles.addExtraBodyWidth}`}
          value={value}
          onChange={onChange}
          spellCheck={false}
          // maxLength={61}
        />
      )}
    </div>
  );
}

Index.defaultProps = {
  className: '',
  isDisabled: false,
  textArea: false,
};

export default Index;

import {
  meetingOverviewFull,
  meetingOverviewMessage,
  meetingOverviewportrait,
  // meetingOverviewportrait,
  standardMeetingRoom,
} from 'constants/index';
import { EventTemplateType } from 'enums';
import { uuid } from 'helpers';

export const meetingRooms = [
  {
    id: uuid(),
    name: 'Meeting Room',
    icon: standardMeetingRoom,
    message: 'For use on a single room for status.',
    eventTemplateType: EventTemplateType.meetingRoom,
  },
  {
    id: uuid(),
    name: 'Meeting Overview Full',
    icon: meetingOverviewFull,
    message:
      'For use on large landscape screen, with up to status on 10-12 meeting rooms.',
    eventTemplateType: EventTemplateType.meetingOverviewFull,
  },
  {
    id: uuid(),
    name: 'Meeting Overview',
    icon: meetingOverviewMessage,
    message:
      'For use on large landscape screen, with up to status on 5 meeting rooms.',
    eventTemplateType: EventTemplateType.meetingOverviewMessage,
  },
  {
    id: uuid(),
    name: 'Meeting Overview Portrait',
    icon: meetingOverviewportrait,
    message:
      'For use on large portrait screen, with up to status on 10 meeting rooms.',
    eventTemplateType: EventTemplateType.meetingOverviewPotrait,
  },
];

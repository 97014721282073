import Button, { ButtonVariants } from 'components/button/button';
import ClientNameAndFolderPath from 'components/client-name-and-folder-path/clientNameAndFolderPath';
import Popover from 'components/popover/popover';
import Search from 'components/search/search';
// import { client } from 'filestack-react';
import {
  cloudUpload2Icon,
  greyAddIcon,
  RESPONSIVE_WINDOW_WIDTH,
  ROUTES,
  WebIcon,
  whiteAddIcon,
  templatesIcon,
  rootFolderId,
} from 'constants/index';
import {
  ContentPermissions,
  LayoutViews,
  ModalViews,
  PlayListPermissions,
  ScreenPermissions,
  ScreenType,
  UserRoles,
} from 'enums';
import { uuid } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import {
  contentPageActions,
  layoutActions,
  meetingOverviewFullActions,
  office365Actions,
} from 'store/actions';
import { iState } from 'types';
import ViewSwitchButton from '../view-switch-button/viewSwitchButton';
import styles from './subHeader.module.scss';

interface iProps {
  className?: string;
}

const CONTENT_ADD_OPTIONS = [
  {
    id: uuid(),
    icon: templatesIcon,
    option: 'Add Template',
    action: 'addTemplate',
  },
  {
    id: uuid(),
    icon: WebIcon,
    option: 'Add webpage',
    action: 'addWebPage',
  },
  {
    id: uuid(),
    icon: cloudUpload2Icon,
    option: 'Upload Files',
    action: 'addFiles',
  },
  // {
  //   id: uuid(),
  //   icon: cloudUpload2Icon,
  //   option: 'Filestack',
  //   action: 'addFilestack',
  // },
];

function SubHeader(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const [classInfo, setClassInfo] = useState('');
  const { layout, user, homePage } = state || {};
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isShowingClientInstance } = layout || {};
  const { companyData } = homePage || {};
  const {
    contentAcces,
    playListAccess,
    screenAccess,
    addContentAndFolders,
    addPlayListAndFolders,
    addScreensAndFolders,
  } = state.user.userPermissions || {};
  const { screensActiveFolder } = state.homePage || {};
  const { playlistActiveFolder } = state.playlistsPage || {};
  const { contentActiveFolder } = state.contentPage || {};
  // console.log('Sachin folder id', screensActiveFolder);
  const { windowWidth } = layout || {};

  let { screenFolders, contentFolders, playListFolders } =
    state.user.userPermissions || {};

  let enableAddScreen = false;
  let enableAddPlayList = false;
  let enableAddContent = false;

  const isSuperUserAdmin = user.role === UserRoles.SuperAdmin;
  const isOwner = user.role === UserRoles.Owner;
  const isAdmin = user.role === UserRoles.Admin;
  const isClient = user.role === UserRoles.Client;
  const isResellerAdmin = user.role === UserRoles.Reseller;

  const onClick = useCallback(() => {
    if (pathname === ROUTES.playlists) {
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddPlaylist));
      return;
    }
    if (
      pathname === ROUTES.home &&
      user.role === UserRoles.SuperAdmin &&
      !isShowingClientInstance
    )
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddClient));
    if (pathname === ROUTES.home && user.role === UserRoles.Reseller)
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddClient));
    if (
      (pathname === ROUTES.screenz && (isClient || isOwner || isAdmin)) ||
      isShowingClientInstance
    )
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddScreen));
  }, [
    dispatch,
    user,
    pathname,
    isAdmin,
    isClient,
    isOwner,
    isShowingClientInstance,
  ]);

  const addFolder = useCallback(() => {
    dispatch(layoutActions.toggleModal(ModalViews.isShowAddFolder));
  }, [dispatch]);

  const addWebPage = useCallback(() => {
    if (pathname === ROUTES.content)
      // dispatch(layoutActions.toggleModal(ModalViews.isShowAddWebContent));
      dispatch(contentPageActions.setIsEditWebContent(false));
    dispatch(contentPageActions.setContentView(null));
    dispatch(
      contentPageActions.setAddWebContentData({
        url: '',
        webSiteName: '',
        isLoadableInIFrame: false,
        isTriedToLoadWebsite: false,
        orintation: ScreenType.VERTICAL,
      })
    );
    navigate(ROUTES.addWebpage);
    // dispatch(layoutActions.toggleModal(ModalViews.isShowAddContent));
  }, [pathname, navigate]);

  const addFiles = useCallback(() => {
    if (pathname === ROUTES.content)
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddContent));
  }, [dispatch, pathname]);

  const addTemplate = useCallback(() => {
    if (pathname === ROUTES.content) {
      dispatch(office365Actions.resetTemplatData());
      dispatch(meetingOverviewFullActions.resetOverviewTemplateData());
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddTemplate));
    }
  }, [dispatch, pathname]);

  const addButtons = (pathname === ROUTES.content && CONTENT_ADD_OPTIONS) || [];

  const username =
    companyData?.name ||
    (pathname === ROUTES.playlists && 'Client Name') ||
    (pathname === ROUTES.content && 'Bykle') ||
    (isClient && 'Client Name') ||
    (isResellerAdmin && 'Welcome Reseller Name') ||
    'Client Name';

  switch (pathname) {
    case ROUTES.screenz: {
      if (screensActiveFolder && addScreensAndFolders) {
        enableAddScreen = true;
      } else if (
        addScreensAndFolders &&
        screenAccess === ScreenPermissions.AllScreens
      ) {
        enableAddScreen = true;
      } else if (screenAccess === ScreenPermissions.OnlyTheyAreAdded) {
        screenFolders = screenFolders || [];
        const found = screenFolders.find(
          (element) => element.folderId === rootFolderId
        );
        if (found) {
          enableAddScreen = true;
        }
      }

      break;
    }
    case ROUTES.playlists: {
      // console.log('sachin testing', addPlayListAndFolders, enableAddPlayList);

      if (playlistActiveFolder && addPlayListAndFolders) {
        enableAddPlayList = true;
      } else if (
        addPlayListAndFolders &&
        playListAccess === PlayListPermissions.AllPlayList
      ) {
        enableAddPlayList = true;
      } else if (playListAccess === PlayListPermissions.OnlyTheyAreAdded) {
        playListFolders = playListFolders || [];
        const found = playListFolders.find(
          (element) => element.folderId === rootFolderId
        );
        if (found) {
          enableAddPlayList = true;
        }
      }

      break;
    }
    case ROUTES.content: {
      if (contentActiveFolder && addContentAndFolders) {
        enableAddContent = true;
      } else if (
        addContentAndFolders &&
        contentAcces === ContentPermissions.AllContents
      ) {
        enableAddContent = true;
      } else if (contentAcces === ContentPermissions.OnlyTheyAreAdded) {
        contentFolders = contentFolders || [];
        const found = contentFolders.find(
          (element) => element.folderId === rootFolderId
        );
        if (found) {
          enableAddContent = true;
        }
      }

      break;
    }
    default:
      break;
  }

  // const openFolder = useCallback(
  //   (folder) => {
  //     dispatch(homePageActions.setActiveFolder(folder));
  //   },
  //   [dispatch]
  // );

  const isShowAddFolder =
    isClient ||
    isOwner ||
    isAdmin ||
    isShowingClientInstance ||
    (isSuperUserAdmin && pathname !== ROUTES.home) ||
    false;

  // const getDimention = (file): Promise<any> => {
  //   return new Promise((resolve) => {
  //     if (file.type.includes('image')) {
  //       const URL = window.URL || window.webkitURL;
  //       const img = new Image();
  //       const objectUrl = URL.createObjectURL(file);
  //       img.onload = () => {
  //         resolve(`${img.width}*${img.height}`);
  //         URL.revokeObjectURL(objectUrl);
  //       };
  //       img.src = objectUrl;
  //     }
  //     if (file.type.includes('video')) {
  //       const url = URL.createObjectURL(file);
  //       const video = document.createElement('video');
  //       video.src = url;
  //       video.addEventListener('loadedmetadata', () => {
  //         resolve(`${video.videoWidth}*${video.videoHeight}`);
  //       });
  //     }
  //   });
  // };
  // const filePickerOptions = {
  //   accept: ['image/*', 'video/*'],
  //   maxSize: 500000000,
  //   maxFiles: 15,
  //   onFileSelected: async (file) => {
  //     console.log(file);
  //     const dimension = await getDimention(file.originalFile);
  //     const fileSize = dimension.split('*');
  //     const width = Number(fileSize[0]);
  //     const height = Number(fileSize[1]);
  //     if (width < 1920 || height < 1080) {
  //       throw new Error(`File ${file.filename} does not fit our criteria`);
  //     }
  //   },
  //   // onFileUploadFinished: ,
  //   exposeOriginalFile: true,
  //   onUploadDone: (file) => console.log(file),
  // };

  // const handleFilePicker = useCallback(() => {
  //   const filestackApikey = 'AvwbOmFA4Sei1LLjIBlsmz';
  //   const filestack = client.init(filestackApikey, filePickerOptions);
  //   const picker = filestack.picker(filePickerOptions);
  //   picker.open().then((response) => console.log(response, 'final'));
  // }, [dispatch, pathname]);
  const handleCLassInfo = useCallback(() => {
    if (pathname === ROUTES.screenz && enableAddScreen === false) {
      setClassInfo('hideBtn');
    } else if (pathname === ROUTES.content && enableAddContent === false) {
      setClassInfo('hideBtn');
    } else if (pathname === ROUTES.playlists && enableAddPlayList === false) {
      setClassInfo('hideBtn');
    } else {
      setClassInfo('screenzBtn');
    }
  }, [enableAddScreen, enableAddPlayList, enableAddContent, pathname]);

  useEffect(() => {
    handleCLassInfo();
  }, [handleCLassInfo]);

  return (
    <div className={`${className} ${styles.subheader}`}>
      {windowWidth > RESPONSIVE_WINDOW_WIDTH ? (
        <>
          <div className={styles.left}>
            {(isSuperUserAdmin && !isShowingClientInstance && (
              <>
                <ViewSwitchButton variant={LayoutViews.SuperAdminClients} />
                {/* <ViewSwitchButton variant={LayoutViews.SuperAdminResellers} /> */}
              </>
            )) ||
              (username && <ClientNameAndFolderPath username={username} />)}
          </div>
          <div className={styles.right}>
            <Search className={styles.searchField} isShowClear />
            {/* {isShowAddFolder && (
              <Button
                className={styles.folderBtn}
                variant={ButtonVariants.SmallTransp}
                btnName="Folder"
                icon={greyAddIcon}
                onClick={addFolder}
              />
            )} */}
            {pathname === ROUTES.screenz &&
            // enableAddScreen &&
            isShowAddFolder ? (
              <Button
                className={`${styles.folderBtn} ${
                  !enableAddScreen && styles.disabled
                }`}
                variant={ButtonVariants.SmallTransp}
                btnName="Folder"
                icon={greyAddIcon}
                onClick={addFolder}
                isDisabled={!enableAddScreen}
              />
            ) : null}
            {pathname === ROUTES.content &&
            // enableAddContent &&
            isShowAddFolder ? (
              <Button
                className={`${styles.folderBtn} ${
                  !enableAddContent && styles.disabled
                }`}
                variant={ButtonVariants.SmallTransp}
                btnName="Folder"
                icon={greyAddIcon}
                onClick={addFolder}
                isDisabled={!enableAddContent}
              />
            ) : null}
            {pathname === ROUTES.playlists &&
            // enableAddPlayList &&
            isShowAddFolder ? (
              <Button
                className={`${styles.folderBtn} ${
                  !enableAddPlayList && styles.disabled
                }`}
                variant={ButtonVariants.SmallTransp}
                btnName="Folder"
                icon={greyAddIcon}
                onClick={addFolder}
                isDisabled={!enableAddPlayList}
              />
            ) : null}
            {pathname === ROUTES.content ? (
              <Popover
                popoverClassName={styles.popoverWrapper}
                popoverComponent={
                  <div className={`${styles.addDropdown} ${className}`}>
                    {addButtons.map((i) => (
                      <div
                        className={styles.option}
                        onClick={() => {
                          if (i.action === 'addWebPage') addWebPage();
                          if (i.action === 'addFiles') addFiles();
                          if (i.action === 'addTemplate') addTemplate();
                          // if (i.action === 'addFilestack') handleFilePicker();
                        }}
                      >
                        <img src={i.icon} alt="" />
                        <span>{i.option}</span>
                      </div>
                    ))}
                  </div>
                }
                isDisabled={classInfo === 'hideBtn'}
              >
                <Button
                  className={`${styles.DropdownBtn}`}
                  variant={ButtonVariants.SmallStd}
                  btnName="Content"
                  icon={whiteAddIcon}
                  iconPosition="left"
                  isDisabled={classInfo === 'hideBtn'}
                />
              </Popover>
            ) : (
              <Button
                className={styles.screenzBtn}
                variant={ButtonVariants.SmallStd}
                btnName={
                  (pathname === ROUTES.screenz &&
                    isShowingClientInstance &&
                    'Screen') ||
                  (pathname === ROUTES.playlists && 'Playlist') ||
                  (pathname === ROUTES.content && 'Content') ||
                  (isSuperUserAdmin && 'Client') ||
                  ((isOwner || isAdmin || isClient) && 'Screen') ||
                  (isResellerAdmin && 'Client') ||
                  ''
                }
                icon={whiteAddIcon}
                onClick={onClick}
                isDisabled={classInfo === 'hideBtn'}
              />
            )}
          </div>
        </>
      ) : (
        <Search isOpenState className={styles.searchField} />
      )}
    </div>
  );
}

SubHeader.defaultProps = {
  className: '',
};

export default SubHeader;

import React, { useEffect, useState, useCallback } from 'react';
import { iPlaylistContents } from 'types';
import {
  closeIcon,
  CONTENT_TYPE,
  eyeIcon,
  meetingOverviewFull,
  meetingOverviewMessage,
  meetingOverviewportrait,
  refreshIcon,
  standardMeetingRoom,
} from 'constants/index';
import { EventTemplateType } from 'enums';
import styles from './playlistPreview.module.scss';

interface iProps {
  className?: string;
  data: iPlaylistContents[];
  close: () => void;
}

function PlaylistPreview(props: iProps): JSX.Element {
  const { className, data, close } = props;
  const [content, setContent] = useState<iPlaylistContents | null>();
  const [templateImg, setTemplateImg] = useState<any>();
  const [enableRefreshBtn, setEnableRefreshBtn] = useState(false);

  const preparePlaylistPreview = useCallback(
    async (refresh?: boolean) => {
      if (!Array.isArray(data)) return;
      const timeOutIds: any = [];
      setEnableRefreshBtn(false);

      if (refresh) {
        timeOutIds.map((i) => clearTimeout(i));
        setContent(null);
        //  console.log('Restarting');
        preparePlaylistPreview();
        return;
      }
      /* eslint no-restricted-syntax: ["error"] */
      for (const element of data) {
        if (data[data.length - 1] === element) setEnableRefreshBtn(true);
        const tempImg =
          (element.templateData?.templateType ===
            EventTemplateType.meetingRoom &&
            standardMeetingRoom) ||
          (element.templateData?.templateType ===
            EventTemplateType.meetingOverviewFull &&
            meetingOverviewFull) ||
          (element.templateData?.templateType ===
            EventTemplateType.meetingOverviewMessage &&
            meetingOverviewMessage) ||
          (element.templateData?.templateType ===
            EventTemplateType.meetingOverviewPotrait &&
            meetingOverviewportrait);
        setTemplateImg(tempImg);
        setContent(element);
        let timerId;
        await new Promise((resolve) => {
          timerId = setTimeout(() => {
            resolve(1);
          }, Number(element.duration) * 1000);
          if (refresh) {
            clearTimeout(timerId);
            Promise.reject(new Error('Whoops!'));
          }

          timeOutIds.push(timerId);
        });
      }
    },
    [data]
  );

  // const cutPreview = useCallback(() => {
  //   setContent(null);
  // }, []);

  useEffect(() => {
    if (data) {
      preparePlaylistPreview();
    }
  }, [data, preparePlaylistPreview]);

  return (
    <div className={`${styles.playlistPreview} ${className}`}>
      <div className={styles.drawerHeader}>
        <div className={styles.colOne}>
          <img src={eyeIcon} alt="" />
          <p className={styles.previewHeading}>Preview Playlist</p>
        </div>
        <div className={styles.colTwo}>
          <div
            className={`${styles.refreshIcon} ${
              !enableRefreshBtn && styles.disabled
            }`}
            onClick={() => {
              if (enableRefreshBtn) preparePlaylistPreview(true);
            }}
          >
            <img src={refreshIcon} alt="" />
          </div>

          <img
            className={styles.closeIcon}
            src={closeIcon}
            alt=""
            onClick={close}
          />
        </div>
      </div>
      <div className={`${styles.section} ${styles.playlistPreview}`}>
        {content && (
          <div className={styles.playlistPreview}>
            {content?.contentType === CONTENT_TYPE.Image && (
              <img
                className={styles.contentPreview}
                src={content?.url}
                alt=""
              />
            )}
            {content?.contentType === CONTENT_TYPE.Video && (
              <video preload="none" src={content?.url} controls autoPlay>
                <source src={content?.url} type="video/mp4" />
                <track src="captions_en.vtt" kind="captions" />
              </video>
            )}
            {content?.contentType === CONTENT_TYPE.Website && (
              <iframe
                className={styles.iframe}
                src={content?.url}
                title="web"
              />
            )}
            {content.contentType === CONTENT_TYPE.Template && (
              <img
                className={styles.standardMeetingRoom}
                src={templateImg || ''}
                alt=""
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

PlaylistPreview.defaultProps = {
  className: '',
};

export default PlaylistPreview;

/* eslint-disable */
import OnlineStatusPill from 'components/online-status-pill/onlineStatusPill';
import {
  arrowRightIcon,
  layoutsIcon,
  logoBoxIcon,
  contentIcon,
  playlistsIcon,
  WebIcon,
  CONTENT_TYPE,
  ROUTES,
  videoIcon,
  templatesGreyIcon,
} from 'constants/index';
import { getNewlySharedStatus } from 'helpers';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { homePageActions } from 'store/actions';
import { iScreenData, iState } from 'types';
import Remote from './remote-wrapper/remoteWrapper';
import Screenshot from './screenshot/screenshot';
import styles from './screenTile.module.scss';

interface iProps {
  className?: string;
  data: iScreenData;
  displayNewItemFlag?: boolean;
}

function ScreenTile(props: iProps): JSX.Element {
  const { className, data, displayNewItemFlag } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const navigate = useNavigate();
  // useEffect(() => {
  //   //  console.log(data);
  // }, []);

  const {
    // isConnectedScreen,
    // isOnline,

    // screenName,
    // contentName,
    // deviceName,
    // screenshot,
    hashCode,
    name,
    isOnline = false,
    screenShotUrl,
    // deviceName,
    currentPlayList,
    currentContent,
    modelName,
  } = data || {};
  const contentName =
    (currentContent && currentContent.name) ||
    (currentPlayList && currentPlayList.name) ||
    'No content assigned';

  const isConnectedScreen = (hashCode !== null && true) || false;

  // const getOnlineStatus = useCallback((lastPingTime) => {
  //   const ONE_MINUT = 60000;
  //   const difference = new Date().getTime() - new Date(lastPingTime).getTime();
  //   if (difference < ONE_MINUT) return true;
  //   return false;
  // }, []);
  const { contentType = '' } = currentContent || {};

  const usedContentIcon =
    (currentPlayList && playlistsIcon) ||
    (contentType === CONTENT_TYPE.Image && contentIcon) ||
    (contentType === CONTENT_TYPE.Website && WebIcon) ||
    (contentType === CONTENT_TYPE.Video && videoIcon) ||
    (contentType === CONTENT_TYPE.Template && templatesGreyIcon) ||
    '';

  return (
    <div
      key={data.id}
      id={data.id}
      className={`${className} ${styles.screenTile}`}
    >
      {isConnectedScreen && (
        // <Remote
        //   className={`${styles.remoteIcon} ${!isOnline && styles.offline}`}
        // />
        <Remote
          className={styles.remoteOnHover}
          isOnline={isOnline}
          // isOnline={lastPing ? getOnlineStatus(lastPing) : false}
          screenId={data.id}
        />
      )}

      <div
        className={styles.topSection}
        onClick={() => {
          dispatch(homePageActions.setScreenzId(data.id));
          dispatch(homePageActions.setScreenData(data));
          navigate(ROUTES.screenDetails);
        }}
      >
        <Screenshot
          screenshot={screenShotUrl || ''}
          isConnectedScreen={isConnectedScreen}
          isOnline={isOnline}
          displayNewItemFlag={
            displayNewItemFlag && getNewlySharedStatus(data?.sharedAt)
          }
          // isOnline={lastPing ? getOnlineStatus(lastPing) : false}
        />

        <div className={styles.screenNameHolder} id={`${data.id}`}>
          <p className={styles.clientName}>{name}</p>
          <div className={styles.layoutName}>
            {usedContentIcon && <img src={usedContentIcon} alt="" />}
            <span className={styles.contentSpan}>
              {contentName.length > 25
                ? contentName.slice(0, 25) + '...'
                : contentName}
            </span>
            <span className={styles.contentMobileSpan}>
              {contentName.length > 17
                ? contentName.slice(0, 17) + '...'
                : contentName}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        {!isConnectedScreen ? (
          <p
            className={styles.assignToScreen}
            onClick={() => {
              dispatch(homePageActions.setScreenzId(data.id));
              dispatch(homePageActions.setScreenData(data));
              navigate(ROUTES.screenDetails);
            }}
          >
            Assign screen to get started
            <img src={arrowRightIcon} alt="" />
          </p>
        ) : (
          <>
            <section className={styles.left}>
              <div className={styles.deviceName}>
                <img src={logoBoxIcon} alt="" />
                <span onClick={() => navigate(ROUTES.screenDetails)}>
                  {modelName}
                </span>
              </div>
            </section>
            <section className={styles.right}>
              <OnlineStatusPill
                isOnline={isOnline}
                // isOnline={lastPing ? getOnlineStatus(lastPing) : false}
              />
            </section>
          </>
        )}
      </div>
    </div>
  );
}

ScreenTile.defaultProps = {
  className: '',
  // id: '',
  displayNewItemFlag: false,
};

export default ScreenTile;

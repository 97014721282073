import Button, { ButtonVariants } from 'components/button/button';
import ContentEditable from 'components/content-editable/contentEditable';
import Loader from 'components/loader/loader';
import {
  cloudUploadIcon,
  // contentEmptyStateImg,
  imageIcon,
  // playlistEmptyStateImage,
  playlistsWhite,
  screenIcon,
} from 'constants/index';
import {
  ContentPermissions,
  EmptyScreens,
  ModalViews,
  PlayListPermissions,
  ScreenPermissions,
} from 'enums';
import { s3Operations } from 'helpers';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useStore } from 'store';
import { contentPageActions, layoutActions } from 'store/actions';
import { iEmptyState, iState } from 'types';
import { useLocation } from 'react-router-dom';
import styles from './emptyState.module.scss';
import { EmptyStateVariants } from './variants';

interface iProps {
  className?: string;
  variant: EmptyStateVariants | null;
  editable?: boolean;
  setEmptyStateToUpdate?: (data: iEmptyState) => void;
  setCanSaveChanges?: (data: boolean) => void;
}
function EmptyState(props: iProps): JSX.Element {
  const {
    className,
    variant,
    editable = false,
    setEmptyStateToUpdate,
    setCanSaveChanges,
  } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { contentPage, layout } = state || {};
  const { emptyState, showCancelAndSaveBtns } = contentPage || {};
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const inputRef = useRef<any>(null);
  const { emptyStates } = layout || {};
  const {
    addPlayListAndFolders,
    addContentAndFolders,
    addScreensAndFolders,
    screenAccess,
    contentAcces,
    playListAccess,
  } = state.user.userPermissions || {};
  const [displayBtn, setDisplayBtn] = useState<boolean>(false);
  const [data, setData] = useState<iEmptyState | undefined>();
  const location = useLocation();

  useEffect(() => {
    setData(
      emptyStates.find(
        (i) =>
          (variant === EmptyStateVariants.Screenz &&
            i.key === EmptyScreens.Screens) ||
          (variant === EmptyStateVariants.Content &&
            i.key === EmptyScreens.Content) ||
          (variant === EmptyStateVariants.Playlists &&
            i.key === EmptyScreens.Playlists) ||
          (variant === EmptyStateVariants.Clients &&
            i.key === EmptyScreens.Clients)
      )
    );
  }, [variant, emptyStates]);

  const { Screenz, Content, Playlists, Clients } = EmptyStateVariants;

  const btnName =
    (variant === Screenz && 'Add your first screens') ||
    (variant === Playlists && 'Add your first Playlist') ||
    (variant === Content && 'Add your first files') ||
    (variant === Clients && 'Add your first client') ||
    '';

  const btnIcon =
    (variant === Playlists && playlistsWhite) ||
    (variant === Content && cloudUploadIcon) ||
    screenIcon ||
    '';

  const onClickAdd = useCallback(() => {
    if (editable) return;
    if (variant === EmptyStateVariants.Screenz)
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddScreen));
    if (variant === EmptyStateVariants.Content)
      // dispatch(layoutActions.openAddContentModal());
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddContent));
    if (variant === EmptyStateVariants.Playlists)
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddPlaylist));
  }, [dispatch, variant, editable]);

  const onChange = (event): void => {
    if (data) {
      const { name, value = '' } = event.target;
      const temp = { ...data, [name]: value };
      setData(temp);
      if (setEmptyStateToUpdate) setEmptyStateToUpdate(temp);
      if (setCanSaveChanges) setCanSaveChanges(true);
    }
  };

  // if (description === '') description = data?.description || '';
  const onChangeContentEditable = useCallback(
    (value) => {
      if (data) {
        const temp = { ...data, description: value || '' };
        setData(temp);
        if (setEmptyStateToUpdate) setEmptyStateToUpdate(temp);
        if (setCanSaveChanges) setCanSaveChanges(true);
      }
    },
    [data, setCanSaveChanges, setEmptyStateToUpdate]
  );

  const onFocus = (): void => {
    if (!showCancelAndSaveBtns)
      dispatch(contentPageActions.showCancelAndSaveBtns());
  };

  // const fileUpload = (): any => {
  //   if (!editable) return;
  //   //  console.log(inputRef.current);
  //   if (inputRef.current) {
  //     inputRef?.current.click();
  //   }
  // };

  const fileChooseHandler = useCallback(
    async (event) => {
      const file: File = event.target.files[0];
      setIsUploadingImage(true);
      const response: any = await s3Operations.upload({
        params: {
          Key: file.name,
          Body: file,
        },
        contentType: file.type.includes('svg') ? 'image/svg+xml' : '',
        uploadType: 'emptyStatesBannerImg',
      });
      if (response) {
        if (data) {
          const temp = { ...data, bannerImg: response.Location };
          setData(temp);
          if (setEmptyStateToUpdate) setEmptyStateToUpdate(temp);
          if (setCanSaveChanges) setCanSaveChanges(true);
        }
        setIsUploadingImage(false);
      } else {
        setIsUploadingImage(false);
      }
    },
    [data, setEmptyStateToUpdate, setCanSaveChanges]
  );

  useEffect(() => {
    switch (location.pathname) {
      case '/screenz':
        if (
          addScreensAndFolders &&
          screenAccess === ScreenPermissions.AllScreens
        ) {
          setDisplayBtn(true);
        }
        break;
      case '/playlists':
        if (
          addPlayListAndFolders &&
          playListAccess === PlayListPermissions.AllPlayList
        ) {
          setDisplayBtn(true);
        }
        break;
      case '/content':
        if (
          addContentAndFolders &&
          contentAcces === ContentPermissions.AllContents
        ) {
          setDisplayBtn(true);
        }
        break;
      default:
        setDisplayBtn(false);
    }
  }, [
    addContentAndFolders,
    addPlayListAndFolders,
    addScreensAndFolders,
    location.pathname,
  ]);

  return (
    <div
      className={`${className} ${styles.emptyState} ${
        variant === EmptyStateVariants.Screenz && styles.emptyScreenz
      }`}
    >
      <div className={styles.descriptionArea}>
        <input
          className={styles.heading}
          name="title"
          value={data?.title}
          spellCheck={false}
          onChange={onChange}
          onFocus={onFocus}
          disabled={!editable}
        />
        <ContentEditable
          className={styles.description}
          editable={editable}
          value={data?.description || ''}
          isModified={(a) => {
            if (setCanSaveChanges) setCanSaveChanges(a);
          }}
          onChange={onChangeContentEditable}
        />
        <Button
          className={styles.screenzBtn}
          variant={ButtonVariants.SmallStd}
          btnName={emptyState === 'Clients' ? 'Add your first client' : btnName}
          icon={btnIcon}
          onClick={onClickAdd}
          isDisabled={emptyState === 'Clients' ? false : !displayBtn}
        />
      </div>
      <div className={`${styles.emptyStateImg} `}>
        <img
          className={`${isUploadingImage && styles.loading} `}
          src={data?.bannerImg}
          alt=""
        />
        {isUploadingImage ? (
          <Loader className={styles.emptyStateLoader} />
        ) : (
          <>
            <Button
              className={`${styles.changeImgBtn} ${editable && styles.show}`}
              variant={ButtonVariants.SmallTransp}
              btnName="Change Images"
              icon={imageIcon}
              // onClick={fileUpload}
            />
            <input
              className={styles.fileInput}
              disabled={!editable}
              ref={inputRef}
              type="file"
              accept="image/*"
              onChange={fileChooseHandler}
            />
          </>
        )}
      </div>
    </div>
  );
}

EmptyState.defaultProps = {
  className: '',
  editable: false,
  setEmptyStateToUpdate: () => {},
  setCanSaveChanges: () => {},
};

export { EmptyStateVariants };
export default EmptyState;

import AirPlane from 'components/svg/airtPlane';
import CloseCircle from 'components/svg/closeCircle';
import React from 'react';
import styles from './resend.module.scss';

interface iProps {
  className?: string;
  isResending: boolean;
  isRevoking: boolean;
  isRevoked: boolean;
  resend: () => void;
  revoke: () => void;
}

function Resend(props: iProps): JSX.Element {
  const {
    className,
    isResending = false,
    isRevoking = false,
    isRevoked = false,
    resend,
    revoke,
  } = props;

  return (
    <div className={`${className} ${styles.resendContainer}`}>
      <button
        type="submit"
        className={styles.resendBtn}
        onClick={resend}
        disabled={isResending}
      >
        <AirPlane className={styles.resendIcon} />
        {isResending ? 'Resending' : 'Resend'}
      </button>
      <button
        type="submit"
        className={styles.resendBtn}
        onClick={() => {
          if (!isRevoked) revoke();
        }}
        disabled={isRevoking}
      >
        {!isRevoked && <CloseCircle className={styles.resendIcon} />}{' '}
        {(isRevoking && 'Revoking') || (isRevoked && 'Revoked') || 'Revoke'}
      </button>
    </div>
  );
}

Resend.defaultProps = {
  className: '',
};

export default Resend;

import { getCompanies, searchCompany } from 'apis';
import ClientTile, {
  ClientTileVariants,
} from 'components/client-tile/clientTile';
import Loader from 'components/loader/loader';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'store';
import { homePageActions } from 'store/actions';
import { iCompanyData, iState } from 'types';
import styles from './clientsList.module.scss';

interface iProps {
  className?: string;
}

function SuperUserClients(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [foundCompanies, setFoundCompanies] =
    useState<Array<iCompanyData> | null>(null);

  const { companies, searchCompanyKey, invokeGetCompanies } =
    state.homePage || {};

  const getAllCompanies = useCallback(async () => {
    const response = await getCompanies({ limit: 0, skip: 0 }, setIsLoading);
    if (response) {
      //  console.log({ response });
      dispatch(homePageActions.setCompanies(response));
      dispatch(homePageActions.invokeGetCompanies(false));
    }
  }, [dispatch]);

  useEffect(() => {
    getAllCompanies();
  }, []);

  useEffect(() => {
    if (invokeGetCompanies) getAllCompanies();
  }, [invokeGetCompanies, getAllCompanies]);

  const searchCompaniesHandler = useCallback(async () => {
    const response = await searchCompany(
      { name: searchCompanyKey },
      setIsLoading
    );
    if (response) {
      setFoundCompanies(response);
    } else setFoundCompanies(null);
  }, [searchCompanyKey, companies]);

  useEffect(() => {
    if (searchCompanyKey !== '') searchCompaniesHandler();
    else setFoundCompanies(null);
  }, [searchCompanyKey, searchCompaniesHandler]);

  // const data = foundCompanies?.length > 0 ? foundCompanies : companies;

  const data =
    Array.isArray(foundCompanies) &&
    foundCompanies.length > 0 &&
    searchCompanyKey !== ''
      ? foundCompanies
      : companies;
  return (
    <>
      {isLoading && <Loader />}
      {!isLoading &&
      searchCompanyKey !== '' &&
      !Array.isArray(foundCompanies) ? (
        <p className={styles.notFound}>Result not found</p>
      ) : (
        <div className={`${className} ${styles.clientView}`}>
          {!isLoading &&
            data.map((i: iCompanyData) => (
              <ClientTile
                key={i.id}
                variant={ClientTileVariants.SuperUserAdmin}
                data={i}
              />
            ))}
        </div>
      )}
    </>
  );
}

SuperUserClients.defaultProps = {
  className: '',
};

export default SuperUserClients;

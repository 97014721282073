import { iPlaylistContents } from 'types';

// export const calculateContentRuntime = (
//   contents: iPlaylistContents[] | [] | null | undefined
// ): string => {
//   if (!contents) return '00:00:00';

//   if (contents?.length === 0) return '00:00:00';

//   const duration = contents.map((i) => i.duration);
//   const minutesArray = duration.map((i) => (i && i.split(':')[0]) || '0');
//   const secondsArray = duration.map((i) => (i && i.split(':')[1]) || '0');
//   let minutes = 0;
//   let seconds = 0;
//   for (let i = 0; i < minutesArray?.length; i += 1) {
//     minutes += Number(minutesArray[i]);
//   }
//   for (let i = 0; i < secondsArray?.length; i += 1) {
//     seconds += Number(secondsArray[i]);
//   }
//   return `00:${minutes || '00'}:${seconds || '00'}`;
// };

export const getMinus = (seconds: number | string): string => {
  try {
    return new Date(Number(seconds) * 1000).toISOString().substring(14, 16);
  } catch (error) {
    return '';
  }
};

export const getSeconds = (seconds: number | string): string => {
  try {
    return new Date(Number(seconds) * 1000).toISOString().substring(17, 19);
  } catch (error) {
    return '';
  }
};

export const getDurationInTimeFormat = (seconds: number | string): string => {
  try {
    return new Date(Number(seconds) * 1000).toISOString().substring(11, 19);
  } catch (error) {
    return '';
  }
};

export const calculateContentRuntime = (
  contents: iPlaylistContents[] | [] | null | undefined
): string => {
  if (!contents) return '00:00:00';
  if (contents?.length === 0) return '00:00:00';
  const durations = contents.map((i) => i.duration);
  let totalSeconds = 0;
  durations.forEach((i) => {
    totalSeconds = Number(i) + totalSeconds;
  });
  //  console.log('calculateContentRuntime', { contents, durations, totalSeconds });

  if (totalSeconds > 0) return getDurationInTimeFormat(totalSeconds);
  return '00:00:00';
};

import React, { useCallback, useState, useEffect } from 'react';
import { closeIcon, editBigIcon, informationIcon } from 'constants/index';
import TemplateIcon from 'components/svg/template';
import { uuid } from 'helpers';
import { useStore } from 'store';
import { getContent, getRooms, MsGraphApis } from 'apis';
import {
  CalenderSettingsView,
  CARD_NAME,
  EventContentType,
  LanguageType,
} from 'enums';
import {
  iState,
  iOffice365DropdownOption,
  iCalenderEvent,
  iTemplateContent,
} from 'types';
import { meetingOverviewFullActions, office365Actions } from 'store/actions';
import NameEditor from './name-editor/nameEditor';
import {
  DEFAULT,
  CONTEN_TYPE_OPTIONS,
  CLOCK_ALIGNMENT_OPTIONS,
  LARGE_AREATYPE_OPTIONS,
} from './constants';
import { TIME_ZONES } from './timeZones';
import Toggler from './toggler/toggler';
import SettingsDropdown from './settings-dropdown/settingsDropdown';
import englishData from '../../../english.json';
import norwayData from '../../../norwegian.json';
import styles from './templateSettingsBar.module.scss';
import AssetManager from './asset-manager/assetManager';
import { LANGUAGE_OPTIONS } from '../../is-meetingRoom-section/template-settings-bar/constants';

interface iProps {
  className?: string;
  cardNumber: CARD_NAME;
  shortCard?: boolean;
}

function TemplateSettingsBar(props: iProps): JSX.Element {
  const { className, cardNumber, shortCard } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const [timezones, setTimeZones] = useState<Array<iOffice365DropdownOption>>(
    []
  );
  const { companyData } = state.homePage || {};
  const [rooms, setRooms] = useState<any>([]);

  const {
    // contentSettingsContentOption,
    templateIdToEdit,
    selectedLanguage,
  } = state.office365 || {};

  const {
    currentView,
    templateOverviewName = '',
    cardDetails,
    largeAreaContent,
    preTitle = '',
    title = '',
    body = '',
    subText = '',
  } = state.meetingOverviewFull || {};

  const {
    contentOption,
    clockAlignment,
    overviewTimezone,
    overviewCustom_name,
    roomDetails,
    overviewChoosedRoom,
    isShowOverviewSubject,
    isShowOverviewHostName,
    isShowOverviewNextMeeting,
  } = cardDetails[cardNumber] || {};

  const { ContentSetting, TemplateSetting, ContentArea, LargeArea } =
    CalenderSettingsView;

  const [templateTexts, setTemplateTexts] = useState<any>(englishData);

  useEffect(() => {
    if (selectedLanguage.value === LanguageType.English) {
      setTemplateTexts(englishData);
    }
    if (selectedLanguage.value === LanguageType.Norwegian) {
      setTemplateTexts(norwayData);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    const zones = TIME_ZONES.map((i) => ({
      id: uuid(),
      option: i.text,
      icon: '',
      value: i.utc[0],
    }));
    zones.unshift({
      id: uuid(),
      option: 'Empty',
      icon: informationIcon,
      value: EventContentType.Empty,
    });
    setTimeZones(zones);
  }, []);

  const {
    setIsShowOverviewSubject,
    setIsShowOverviewHostname,
    setIsShowOverviewNextMeeting,
  } = meetingOverviewFullActions;

  const locationBasedEvents = useCallback(
    (allEvents: iCalenderEvent[]) => {
      const { value } = overviewTimezone || {};
      return allEvents.filter((event) => event.originalStartTimeZone === value);
    },
    [overviewTimezone]
  );

  const callEvents = useCallback(
    async (room, cardNumb) => {
      try {
        const apiResponse = await MsGraphApis.callEventsOnRoom([room.id]);
        const events = await apiResponse[0].events;
        dispatch(
          meetingOverviewFullActions.setOverviewAllCalenderEvents({
            events: [],
            CARD_NAME: cardNumb,
          })
        );
        dispatch(
          meetingOverviewFullActions.setOverviewCurrentCalenderEvents({
            events: null,
            CARD_NAME: cardNumb,
          })
        );
        dispatch(
          meetingOverviewFullActions.setOverviewNextCurrentCalenderEvents({
            events: null,
            CARD_NAME: cardNumb,
          })
        );
        if (events.length === 0) {
          dispatch(
            meetingOverviewFullActions.setOverviewAllCalenderEvents({
              events: [],
              CARD_NAME: cardNumb,
            })
          );
          dispatch(
            meetingOverviewFullActions.setOverviewCurrentCalenderEvents({
              events: null,
              CARD_NAME: cardNumb,
            })
          );
          dispatch(
            meetingOverviewFullActions.setOverviewNextCurrentCalenderEvents({
              events: null,
              CARD_NAME: cardNumb,
            })
          );
        }

        if (events) {
          let filteredEvents: iCalenderEvent[] = events;

          filteredEvents = filteredEvents.filter((event) => {
            const startDate = new Date(`${event.start.dateTime}Z`);
            const endDate = new Date(`${event.end.dateTime}Z`);
            const currentdate = new Date();
            return (
              startDate.getDate() === currentdate.getDate() &&
              endDate >= currentdate
            );
          });

          dispatch(
            meetingOverviewFullActions.setOverviewAllCalenderEvents({
              events: filteredEvents || [],
              CARD_NAME: cardNumb,
            })
          );
          if (filteredEvents.length > 0) {
            const onGoingEvent = filteredEvents.filter((event) => {
              const startDate = new Date(`${event.start.dateTime}Z`);
              const endDate = new Date(`${event.end.dateTime}Z`);
              const currentdate = new Date();
              return (
                startDate.getTime() <= currentdate.getTime() &&
                endDate.getTime() >= currentdate.getTime()
              );
            });
            if (onGoingEvent.length > 0) {
              dispatch(
                meetingOverviewFullActions.setOverviewCurrentCalenderEvents({
                  events: onGoingEvent[0],
                  CARD_NAME: cardNumb,
                })
              );
              if (filteredEvents.length >= 2) {
                dispatch(
                  meetingOverviewFullActions.setOverviewNextCurrentCalenderEvents(
                    {
                      events: filteredEvents[1],
                      CARD_NAME: cardNumb,
                    }
                  )
                );
              }
              return;
            }
            dispatch(
              meetingOverviewFullActions.setOverviewCurrentCalenderEvents({
                events: null,
                CARD_NAME: cardNumb,
              })
            );
            dispatch(
              meetingOverviewFullActions.setOverviewNextCurrentCalenderEvents({
                events: filteredEvents[0],
                CARD_NAME: cardNumb,
              })
            );
          }
          if (filteredEvents.length === 0) {
            dispatch(
              meetingOverviewFullActions.setOverviewAllCalenderEvents({
                events: [],
                CARD_NAME: cardNumb,
              })
            );
            dispatch(
              meetingOverviewFullActions.setOverviewCurrentCalenderEvents({
                events: null,
                CARD_NAME: cardNumb,
              })
            );
            dispatch(
              meetingOverviewFullActions.setOverviewNextCurrentCalenderEvents({
                events: null,
                CARD_NAME: cardNumb,
              })
            );
          }
        }
      } catch (error) {
        dispatch(
          meetingOverviewFullActions.setOverviewAllCalenderEvents({
            events: [],
            CARD_NAME: cardNumb,
          })
        );
        dispatch(
          meetingOverviewFullActions.setOverviewCurrentCalenderEvents({
            events: null,
            CARD_NAME: cardNumb,
          })
        );
        dispatch(
          meetingOverviewFullActions.setOverviewNextCurrentCalenderEvents({
            events: null,
            CARD_NAME: cardNumb,
          })
        );
      }
    },
    [dispatch, locationBasedEvents, ContentSetting, overviewTimezone, state]
  );
  const isContentSettings = currentView === ContentSetting;
  const isLargeAreaContent = currentView === LargeArea;
  const isTemplatesSettings = currentView === TemplateSetting;

  useEffect(() => {
    (async () => {
      if (!companyData) return;
      const roomsResponse = await getRooms({
        company: companyData.id || '',
      });
      if (roomsResponse) {
        setRooms(roomsResponse);
      }
    })();
  }, [templateIdToEdit]);

  const mapDataToContentBox = useCallback(
    (content: iTemplateContent, cardPosition: CARD_NAME) => {
      const {
        contentType,
        details: cardContentDetails,
        id: contentId,
        room,
      } = content || {};
      const {
        contentAlignment,
        imageUrl,
        roomDetails: contentRoomDetails,
        showHostName,
        showNextMeeting,
        showSubject,
        timezone,
      } = cardContentDetails || {};

      dispatch(
        meetingOverviewFullActions.setOverviewContentsId({
          option: contentId,
          CARD_NAME: cardPosition,
        })
      );

      const contentTypeOption = CONTEN_TYPE_OPTIONS.find(
        (i) => i.value === contentType
      );

      if (contentTypeOption) {
        dispatch(
          meetingOverviewFullActions.setOverviewSettingsContentOption({
            option: contentTypeOption,
            CARD_NAME: cardPosition,
          })
        );
      }
      const contentClockAlignment = CLOCK_ALIGNMENT_OPTIONS.find(
        (i) => i.value === contentAlignment
      );
      if (contentClockAlignment) {
        dispatch(
          meetingOverviewFullActions.setOverviewClockAllignement({
            option: contentClockAlignment,
            CARD_NAME: cardPosition,
          })
        );
      }
      const timeZoneOption = timezones.find((i) => i.value === timezone);
      if (timeZoneOption) {
        dispatch(
          meetingOverviewFullActions.setOverviewTimeZoneOption({
            option: {
              id: uuid(),
              option: timeZoneOption.option,
              value: timeZoneOption.value,
              icon: '',
            },
            CARD_NAME: cardPosition,
          })
        );
      }
      if (imageUrl) {
        dispatch(
          meetingOverviewFullActions.setOverviewEventLogo({
            url: imageUrl,
            CARD_NAME: cardPosition,
          })
        );
      }
      const choosedRoom = rooms.find((i) => i.id === room?.id);

      if (choosedRoom) {
        dispatch(
          meetingOverviewFullActions.updateOverviewCustomName({
            custom_Name: choosedRoom.custom_name,
            CARD_NAME: cardPosition,
          })
        );
        dispatch(
          meetingOverviewFullActions.setOverviewChoosedRoom({
            roomData: choosedRoom,
            CARD_NAME: cardPosition,
          })
        );
        callEvents(choosedRoom, cardPosition);
      }
      if (contentRoomDetails) {
        dispatch(
          meetingOverviewFullActions.updateRoomDetails({
            room_details: contentRoomDetails,
            CARD_NAME: cardPosition,
          })
        );
      }
      dispatch(
        setIsShowOverviewSubject({
          isOn: showSubject,
          CARD_NAME: cardPosition,
        })
      );
      dispatch(
        setIsShowOverviewHostname({
          isOn: showHostName,
          CARD_NAME: cardPosition,
        })
      );
      dispatch(
        setIsShowOverviewNextMeeting({
          isOn: showNextMeeting,
          CARD_NAME: cardPosition,
        })
      );
    },
    [templateIdToEdit, dispatch, rooms, callEvents, state]
  );

  const getTemplate = useCallback(async () => {
    if (!templateIdToEdit) {
      return;
    }
    const response = await getContent(templateIdToEdit);
    if (response?.templateData) {
      const { details, contents, templateType } = response.templateData || {};

      const {
        backgroundImageUrl,
        leftAreaContetType,
        leftAreaImageUrl,
        leftAreaText,
      } = details || {};
      dispatch(office365Actions.setTempalteDataToUpdate(response));
      if (templateType) {
        dispatch(office365Actions.setChosenTemplateType(templateType || ' '));
      }
      if (response.templateData.opacity) {
        dispatch(
          meetingOverviewFullActions.setTransparency(
            response.templateData.opacity.toString()
          )
        );
      }
      if (response.name) {
        dispatch(
          meetingOverviewFullActions.setMeetingTemplateName(response.name || '')
        );
      }

      dispatch(
        meetingOverviewFullActions.setEventBgImg(backgroundImageUrl || '')
      );

      const largeAreaTypeOption = LARGE_AREATYPE_OPTIONS.find(
        (i) => i.value === leftAreaContetType
      );

      if (largeAreaTypeOption) {
        dispatch(
          meetingOverviewFullActions.setLargeAreaContent(largeAreaTypeOption)
        );
      }

      if (leftAreaImageUrl) {
        // leftAreaImage
        dispatch(
          meetingOverviewFullActions.setOverviewLargeImage(leftAreaImageUrl)
        );
      }

      if (leftAreaText) {
        // EmptyText
        dispatch(
          meetingOverviewFullActions.updateOverviewPretitle(
            leftAreaText.preTitle || ''
          )
        );
        dispatch(
          meetingOverviewFullActions.updateOverviewTitle(
            leftAreaText.title || ''
          )
        );
        dispatch(
          meetingOverviewFullActions.updateOverviewBody(leftAreaText.body || '')
        );
        dispatch(
          meetingOverviewFullActions.updateOverviewSubtext(
            leftAreaText.subText || ''
          )
        );
      }
      if (Array.isArray(contents)) {
        contents.forEach((content) => {
          if (content.position === 7) {
            const cardPosition = CARD_NAME.One;
            mapDataToContentBox(content, cardPosition);
          }
          if (content.position === 1) {
            const cardPosition = CARD_NAME.Two;
            mapDataToContentBox(content, cardPosition);
          }
          if (content.position === 8) {
            const cardPosition = CARD_NAME.Three;
            mapDataToContentBox(content, cardPosition);
          }
          if (content.position === 2) {
            const cardPosition = CARD_NAME.Four;
            mapDataToContentBox(content, cardPosition);
          }
          if (content.position === 9) {
            const cardPosition = CARD_NAME.Five;
            mapDataToContentBox(content, cardPosition);
          }
          if (content.position === 3) {
            const cardPosition = CARD_NAME.Six;
            mapDataToContentBox(content, cardPosition);
          }
          if (content.position === 10) {
            const cardPosition = CARD_NAME.Seven;
            mapDataToContentBox(content, cardPosition);
          }
          if (content.position === 4) {
            const cardPosition = CARD_NAME.Eight;
            mapDataToContentBox(content, cardPosition);
          }
          if (content.position === 11) {
            const cardPosition = CARD_NAME.Nine;
            mapDataToContentBox(content, cardPosition);
          }
          if (content.position === 5) {
            const cardPosition = CARD_NAME.Ten;
            mapDataToContentBox(content, cardPosition);
          }
          if (content.position === 12) {
            const cardPosition = CARD_NAME.Eleven;
            mapDataToContentBox(content, cardPosition);
          }
          if (content.position === 6) {
            const cardPosition = CARD_NAME.Twelve;
            mapDataToContentBox(content, cardPosition);
          }
        });
      }
    }
  }, [templateIdToEdit, dispatch, rooms, callEvents, state]);

  useEffect(() => {
    if (templateIdToEdit) {
      getTemplate();
    }
  }, [templateIdToEdit, rooms]);

  const onChangeView = useCallback(() => {
    if (isContentSettings) {
      dispatch(meetingOverviewFullActions.setCurrentCard(null));
      dispatch(meetingOverviewFullActions.setCureentView(LargeArea));
    }
    if (isTemplatesSettings) {
      dispatch(meetingOverviewFullActions.setCurrentCard(CARD_NAME.Two));
      dispatch(meetingOverviewFullActions.setCureentView(ContentSetting));
    }
    if (isLargeAreaContent) {
      dispatch(meetingOverviewFullActions.setCurrentCard(null));
      dispatch(meetingOverviewFullActions.setCureentView(TemplateSetting));
    }
  }, [
    dispatch,
    overviewTimezone,
    ContentSetting,
    ContentArea,
    isTemplatesSettings,
    isContentSettings,
  ]);

  const onChangeDropdown = useCallback(
    (option: iOffice365DropdownOption) => {
      if (isContentSettings) {
        if (option.value === EventContentType.Empty)
          dispatch(
            meetingOverviewFullActions.setOverviewClockAllignement({
              option,
              CARD_NAME: cardNumber,
            })
          );
        dispatch(
          meetingOverviewFullActions.setOverviewSettingsContentOption({
            option,
            CARD_NAME: cardNumber,
          })
        );
      }
      if (isLargeAreaContent) {
        dispatch(meetingOverviewFullActions.setLargeAreaContent(option));
        if (option.value === EventContentType.Empty) {
          // EmptyImage
          dispatch(meetingOverviewFullActions.setOverviewLargeImage(''));
          // EmptyText
          dispatch(meetingOverviewFullActions.updateOverviewPretitle(''));
          dispatch(meetingOverviewFullActions.updateOverviewTitle(''));
          dispatch(meetingOverviewFullActions.updateOverviewBody(''));
          dispatch(meetingOverviewFullActions.updateOverviewSubtext(''));
        }
        if (option.value === EventContentType.Image) {
          dispatch(meetingOverviewFullActions.updateOverviewPretitle(''));
          dispatch(meetingOverviewFullActions.updateOverviewTitle(''));
          dispatch(meetingOverviewFullActions.updateOverviewBody(''));
          dispatch(meetingOverviewFullActions.updateOverviewSubtext(''));
        }
        if (option.value === EventContentType.Text) {
          dispatch(meetingOverviewFullActions.setOverviewLargeImage(''));
        }
      }
    },
    [dispatch, isContentSettings, cardNumber, state]
  );

  const onChangeClockAlignment = useCallback(
    (option: iOffice365DropdownOption) => {
      dispatch(
        meetingOverviewFullActions.setOverviewClockAllignement({
          option,
          CARD_NAME: cardNumber,
        })
      );
    },
    [dispatch, isContentSettings, cardNumber]
  );

  const onChangeMeetingOverviewTimeZone = useCallback(
    (option: iOffice365DropdownOption) => {
      dispatch(
        meetingOverviewFullActions.setOverviewTimeZoneOption({
          option,
          CARD_NAME: cardNumber,
        })
      );
    },
    [dispatch, ContentSetting, cardNumber]
  );

  const onClickRoom = useCallback(
    (option: iOffice365DropdownOption) => {
      const roomData = rooms.find((i) => i.id === option.id);
      if (roomData) {
        dispatch(
          meetingOverviewFullActions.updateOverviewCustomName({
            custom_Name: roomData.custom_name,
            CARD_NAME: cardNumber,
          })
        );
        dispatch(
          meetingOverviewFullActions.setOverviewChoosedRoom({
            roomData,
            CARD_NAME: cardNumber,
          })
        );
        callEvents(roomData, cardNumber);
      }
    },
    [dispatch, rooms, callEvents, state]
  );

  const onClickLanguage = useCallback(
    (option: iOffice365DropdownOption) => {
      dispatch(office365Actions.setSelectedLanguage(option));
    },
    [dispatch, state]
  );

  const filterContentOption =
    shortCard &&
    (cardNumber === CARD_NAME.Two || cardNumber === CARD_NAME.Eleven);

  return (
    <div className={`${styles.templateSettingsBar} ${className}`}>
      <div className={styles.headingContainer}>
        {isTemplatesSettings && (
          <TemplateIcon className={styles.templatesIcon} />
        )}
        {(isContentSettings || isLargeAreaContent) && (
          <img className={styles.icon} src={editBigIcon} alt="" />
        )}

        <p className={styles.heading}>
          {(isContentSettings && templateTexts.contentSettings) ||
            (isTemplatesSettings && templateTexts.templateSettings) ||
            (isLargeAreaContent && templateTexts.contentSettings)}{' '}
        </p>
        <img
          className={styles.closeIcon}
          src={closeIcon}
          alt=""
          onClick={onChangeView}
        />
      </div>
      <hr className={styles.hr} />
      {isTemplatesSettings && (
        <>
          <NameEditor
            label={templateTexts.templateName}
            value={templateOverviewName}
            onChange={(event) => {
              dispatch(
                meetingOverviewFullActions.setMeetingTemplateName(
                  event.target.value || ''
                )
              );
            }}
          />
          <hr className={styles.hr} />
          <SettingsDropdown
            heading={templateTexts.language}
            options={LANGUAGE_OPTIONS}
            choosedOption={
              selectedLanguage || {
                id: uuid(),
                option: 'English',
                optionNorway: 'English',
                icon: '',
                value: LanguageType.English,
              }
            }
            onChange={onClickLanguage}
          />
          <hr className={styles.hr} />
          <AssetManager
            heading={templateTexts.background}
            assetType="bgImg"
            cardNumber={cardNumber}
          />
          {/* <hr className={styles.hr} /> */}
        </>
      )}
      {isLargeAreaContent && (
        <>
          <SettingsDropdown
            heading={templateTexts.contentType}
            options={LARGE_AREATYPE_OPTIONS}
            choosedOption={largeAreaContent}
            onChange={onChangeDropdown}
          />
          {largeAreaContent.value === EventContentType.Image && (
            <>
              <hr className={styles.hr} />
              <AssetManager
                heading={templateTexts.setImage}
                assetType="largeArea"
                cardNumber={cardNumber}
              />
            </>
          )}
          {largeAreaContent.value === EventContentType.Text && (
            <>
              <hr className={styles.hr} />
              <NameEditor
                label={templateTexts.preTitle}
                value={preTitle || ''}
                onChange={(event) => {
                  dispatch(
                    meetingOverviewFullActions.updateOverviewPretitle(
                      event.target.value
                    )
                  );
                }}
              />
              <hr className={styles.hrTwo} />
              <NameEditor
                label={templateTexts.title}
                value={title || ''}
                onChange={(event) => {
                  dispatch(
                    meetingOverviewFullActions.updateOverviewTitle(
                      event.target.value
                    )
                  );
                }}
                textArea
              />
              <hr className={styles.hrTwo} />
              <NameEditor
                label={templateTexts.body}
                value={body || ''}
                onChange={(event) => {
                  dispatch(
                    meetingOverviewFullActions.updateOverviewBody(
                      event.target.value
                    )
                  );
                }}
                textArea
              />
              <hr className={styles.hrTwo} />
              <NameEditor
                label={templateTexts.subText}
                value={subText || ''}
                onChange={(event) => {
                  dispatch(
                    meetingOverviewFullActions.updateOverviewSubtext(
                      event.target.value
                    )
                  );
                }}
                textArea
              />
            </>
          )}
        </>
      )}

      {/* Content Settings */}
      {isContentSettings && (
        <>
          <SettingsDropdown
            heading={templateTexts.contentType}
            options={
              filterContentOption
                ? CONTEN_TYPE_OPTIONS.filter(
                    (item) => item.value !== EventContentType.Calender
                  )
                : CONTEN_TYPE_OPTIONS
            }
            choosedOption={contentOption}
            onChange={onChangeDropdown}
          />
          {contentOption.value !== EventContentType.Empty && (
            <hr className={styles.hr} />
          )}
          {contentOption.value === EventContentType.Clock && (
            <SettingsDropdown
              heading={templateTexts.version}
              options={CLOCK_ALIGNMENT_OPTIONS}
              choosedOption={clockAlignment}
              onChange={onChangeClockAlignment}
            />
          )}
          {contentOption.value === EventContentType.Clock &&
            clockAlignment.value !== EventContentType.Empty && (
              <>
                <SettingsDropdown
                  heading={templateTexts.setTimezoneType}
                  options={timezones}
                  choosedOption={
                    (overviewTimezone && {
                      id: overviewTimezone?.id,
                      value: overviewTimezone?.value,
                      option: overviewTimezone?.option,
                      icon: '',
                    }) ||
                    DEFAULT
                  }
                  onChange={onChangeMeetingOverviewTimeZone}
                  timeZoneDropdown
                />
                {/* <hr className={styles.hr} /> */}
              </>
            )}
          {contentOption.value === EventContentType.Image && (
            <AssetManager
              heading={templateTexts.setImage}
              assetType="logo"
              cardNumber={cardNumber}
            />
            // <hr className={styles.hr} />
          )}
          {contentOption.value === EventContentType.Calender && (
            <>
              <SettingsDropdown
                heading={templateTexts.setCalendar}
                isShowMSIcon
                options={
                  (rooms &&
                    rooms.length > 0 &&
                    rooms.map((room) => ({
                      id: room.id,
                      option: room.name,
                      optionNorway: room.name,
                      value: room.name,
                      icon: '',
                    }))) || [DEFAULT]
                }
                choosedOption={
                  (overviewChoosedRoom && {
                    id: overviewChoosedRoom?.id,
                    value: overviewChoosedRoom?.name,
                    optionNorway: overviewChoosedRoom?.name,
                    option: overviewChoosedRoom?.name,
                    icon: '',
                  }) ||
                  DEFAULT
                }
                onChange={onClickRoom}
              />
              <hr className={styles.hrTwo} />
              <NameEditor
                label={templateTexts.setCustomName}
                value={overviewCustom_name || ''}
                onChange={(event) => {
                  dispatch(
                    meetingOverviewFullActions.updateOverviewCustomName({
                      custom_Name: event.target.value,
                      CARD_NAME: cardNumber,
                    })
                  );
                  dispatch(office365Actions.setupdateRoomCustomName(true));
                }}
              />
              <NameEditor
                className={styles.roomDetails}
                label={templateTexts.roomDetails}
                value={roomDetails || ''}
                onChange={(event) => {
                  dispatch(
                    meetingOverviewFullActions.updateRoomDetails({
                      room_details: event.target.value,
                      CARD_NAME: cardNumber,
                    })
                  );
                  dispatch(office365Actions.setupdateRoomCustomName(true));
                }}
              />
              <hr className={styles.hrTwo} />
              <div className={styles.toggleSettingsContainer}>
                <p className={styles.heading}>Settings for Resource</p>
                <Toggler
                  label={templateTexts.showSubject}
                  value={isShowOverviewSubject || false}
                  onChange={(isOn) =>
                    dispatch(
                      setIsShowOverviewSubject({
                        isOn,
                        CARD_NAME: cardNumber,
                      })
                    )
                  }
                />
                <Toggler
                  label={templateTexts.showHostname}
                  value={isShowOverviewHostName || false}
                  onChange={(isOn) =>
                    dispatch(
                      setIsShowOverviewHostname({
                        isOn,
                        CARD_NAME: cardNumber,
                      })
                    )
                  }
                />
                <Toggler
                  label={templateTexts.showNextMeeting}
                  value={isShowOverviewNextMeeting || false}
                  onChange={(isOn) =>
                    dispatch(
                      setIsShowOverviewNextMeeting({
                        isOn,
                        CARD_NAME: cardNumber,
                      })
                    )
                  }
                />
              </div>
            </>
          )}
        </>
      )}
      <hr className={styles.hr} />
    </div>
  );
}

TemplateSettingsBar.defaultProps = {
  className: '',
  shortCard: false,
};

export default TemplateSettingsBar;

import { addPlaylist } from 'apis';
import LabelAndInput from 'components/label-and-input/labelAndInput';
import Modal from 'components/modal/modal';
import { ROUTES, screenRotation } from 'constants/index';
import { ModalViews, ScreenType } from 'enums';
import { calculateContentRuntime, toaster, uuid } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import {
  homePageActions,
  layoutActions,
  playlistPageActions,
} from 'store/actions';
import { iState } from 'types';
import OrientationAndSize from '../orientation-and-size/orientationAndSize';
import styles from './addModal.module.scss';
import { AddModalVariants, variants } from './variants';

const CREATE_PLACE_LIST_INPUTS = [
  {
    id: uuid(),
    name: 'playlistName',
    placeholder: 'Give your playlist a name',
  },
];

const DUPLICATE_PLACE_LIST_INPUTS = [
  {
    id: uuid(),
    name: 'playlistName',
    placeholder: 'Give your playlist a name',
  },
];

const ADD_FOLDER_INPUTS = [
  {
    id: uuid(),
    name: 'addFolder',
    placeholder: 'Give a folder a name',
  },
];

interface iProps {
  variant: AddModalVariants;
}

function AddModal(props: iProps): JSX.Element {
  const { variant } = props;
  const { AddPlaylist, AddPlaylistFolder, DuplicatePlaylist } =
    AddModalVariants;
  const [state, dispatch]: [iState, any] = useStore();
  const { foldersAndSubFolders } = state.homePage || {};
  const { playlistData, playlistActiveFolder } = state.playlistsPage || {};
  const [value, setValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [screenType, setScreenType] = useState<ScreenType>(
    ScreenType.HORIZONTAL
  );

  const [errorMessage, setErrorMessage] = useState<string>('');
  const { isShowAddPlaylist, isShowAddFolderForPlaylists } = state.layout || {};
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { heading, btnName, headingIcon, emptyErrorMessage } =
    variants[variant];

  const { companyData } = state.homePage;
  const { id: companyId } = companyData || {};

  const inputs =
    (variant === AddPlaylist && CREATE_PLACE_LIST_INPUTS) ||
    (variant === DuplicatePlaylist && DUPLICATE_PLACE_LIST_INPUTS) ||
    (variant === AddPlaylistFolder && ADD_FOLDER_INPUTS) ||
    [];

  useEffect(() => {
    //  console.log(playlistData, 'playlistDataDuration');
  }, [playlistData]);

  const openPlaylist = useCallback(
    (data) => {
      dispatch(playlistPageActions.setPlaylistData(data));
      navigate(ROUTES.playlist);
    },
    [navigate, dispatch]
  );

  const addPlaylistHandler = useCallback(async () => {
    if (!companyId) return;
    const { companyUserId } = companyData || {};
    const response = await addPlaylist(
      {
        companyId,
        name: value,
        folderId: playlistActiveFolder?.id || null,
        screenType,
        companyUserId: companyUserId || '',
        // companyUserId:"d1ba521d-6756-4c5b-beed-9b3363423a46",
        userId: state.user.loggedInData?.id || '',
      },
      setIsLoading
    );
    if (response) {
      openPlaylist(response);
      dispatch(playlistPageActions.addPlaylist(value));
      dispatch(playlistPageActions.fetchPlaylistData(true));
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddPlaylist));
      toaster.success('Playlist added successfully');
    }
  }, [
    companyId,
    dispatch,
    value,
    playlistActiveFolder?.id,
    openPlaylist,
    screenType,
  ]);

  const playlistDuration = calculateContentRuntime(
    playlistData?.playListContents
  );

  const duplicatePlaylistHandler = useCallback(async () => {
    if (!companyId) {
      toaster.error('Company data not found');
      return;
    }
    if (!playlistData) {
      toaster.error('Playlist data not found');
      return;
    }

    const response = await addPlaylist(
      {
        // ...playlistData,
        dimention: playlistData.dimention,
        duration: playlistDuration,
        // id: playlistData.id,
        // playListContents: playlistData.playListContents,

        contents:
          (playlistData.playListContents &&
            playlistData.playListContents.map((i) => ({
              id: i.id,
              duration: i.duration,
              sortOrder: i.sortOrder,
            }))) ||
          [],
        companyId,
        name: value,
        folderId: playlistActiveFolder?.id || null,
        screenType,
        userId: state.user.loggedInData?.id || '',
      },
      setIsLoading
    );
    if (response) {
      navigate(ROUTES.playlists);
      dispatch(playlistPageActions.addPlaylist(value));
      dispatch(playlistPageActions.fetchPlaylistData(true));
      dispatch(layoutActions.toggleModal());
    }
  }, [
    companyId,
    dispatch,
    value,
    playlistData,
    navigate,
    calculateContentRuntime,
    playlistActiveFolder?.id,
    screenType,
    playlistDuration,
  ]);

  const add = useCallback(async () => {
    if (value === '') {
      setErrorMessage(emptyErrorMessage);
      return;
    }
    if (!companyId) {
      toaster.error('something went wrong');
      return;
    }

    if (pathname === ROUTES.playlists && variant === AddPlaylist) {
      addPlaylistHandler();
    }
    if (pathname === ROUTES.playlist && variant === DuplicatePlaylist) {
      duplicatePlaylistHandler();
    }
    if (pathname === ROUTES.playlists && variant === AddPlaylistFolder) {
      dispatch(homePageActions.addFolder(value, foldersAndSubFolders));
      dispatch(
        layoutActions.toggleModal(ModalViews.isShowAddFolderForPlaylists)
      );
    }
    setValue('');
  }, [
    dispatch,
    value,
    pathname,
    emptyErrorMessage,
    foldersAndSubFolders,
    AddPlaylist,
    DuplicatePlaylist,
    AddPlaylistFolder,
    variant,
    companyId,
    addPlaylistHandler,
    duplicatePlaylistHandler,
  ]);

  const onChange = useCallback((event) => {
    setErrorMessage('');
    setValue(event.target.value);
  }, []);

  const closeModal = useCallback(() => {
    dispatch(layoutActions.toggleModal());
    setErrorMessage('');
    setValue('');
  }, [dispatch]);

  const showModal =
    (variant === AddPlaylist && isShowAddPlaylist) ||
    (variant === DuplicatePlaylist && isShowAddPlaylist) ||
    (variant === AddPlaylistFolder && isShowAddFolderForPlaylists);

  useEffect(() => {
    const inputElement = document.getElementById('inputNameField');
    if (inputElement && showModal) {
      inputElement.focus();
    }
  }, [showModal]);

  return (
    <Modal
      isOpen={showModal}
      heading={heading}
      btnName={btnName}
      headingIcon={headingIcon}
      onClose={closeModal}
      submit={add}
      isLoading={isLoading}
    >
      <div className={styles.addFolderModal}>
        {inputs.map((input) => (
          <div className={styles.labelAndInput}>
            {/* <Input
              id="inputNameField"
              className={styles.input}
              name={input.name}
              placeholder={errorMessage || input.placeholder}
              value={value}
              isError={errorMessage !== ''}
              onChange={onChange}
            /> */}
            <LabelAndInput
              className={styles.input}
              name={input.name}
              id="inputNameField"
              label={
                errorMessage &&
                (input.name === 'playlistName' ? `Playlist` : input.name)
              }
              placeholder={input.placeholder}
              value={value}
              onChange={onChange}
              errorMessage={errorMessage}
            />
          </div>
        ))}
        <hr className={styles.addModalHrLine} />
        <div className={styles.orientationHeading}>
          <img className={styles.orintationIcon} src={screenRotation} alt="" />
          <p className={styles.orintation}>Playlist Size</p>
        </div>
        <OrientationAndSize
          activeScreenTpe={screenType}
          onClickOrientation={setScreenType}
        />
      </div>
    </Modal>
  );
}

export { AddModalVariants };
export default AddModal;

import { uuid } from 'helpers';
import {
  // ClientPermissions,
  ScreenPermissions,
  ContentPermissions,
  PlayListPermissions,
} from 'enums';

export interface iSubPermission {
  id: string;
  option: string;
  value: string;
  key: string;
  accessTo: 'clients' | 'screenAccess' | 'contentAcces' | 'playListAccess';
  // subPermissions: string;
}

export interface Permission {
  id: string;
  option: string;
  key: string;
  subPermissions: iSubPermission[];
}

export interface Permissions {
  id: string;
  heading: string;
  permissions: Permission[];
}

export const PermissionKeys = {
  addClients: 'addClients',
  addContentAndFolders: 'addContentAndFolders',
  addPlayListAndFolders: 'addPlayListAndFolders',
  addTeammates: 'addTeammates',
  clients: 'clients',
  contentAcces: 'contentAcces',
  deleteClients: 'deleteClients',
  deleteContentAndFolder: 'deleteContentAndFolder',
  deletePlayListAndFolder: 'deletePlayListAndFolder',
  deleteScreenAndFolder: 'deleteScreenAndFolder',
  editCompanyDetails: 'editCompanyDetails',
  manageReseller: 'manageReseller',
  permissionFor: 'permissionFor',
  playListAccess: 'playListAccess',
  removeTeammates: 'removeTeammates',
  rollOrCompanyUserId: 'rollOrCompanyUserId',
  screenAccess: 'screenAccess',
  addScreensAndFolders: 'addScreensAndFolders',
};

// const RESELLER: Permissions[] = [
//   {
//     id: uuid(),
//     heading: 'Reseller',
//     permissions: [
//       {
//         id: uuid(),
//         option: 'Manage Resellers',
//         key: PermissionKeys.manageReseller,
//         subPermissions: [],
//       },
//     ],
//   },
// ];

// const CLIENTS: Permissions[] = [
//   {
//     id: uuid(),
//     heading: 'Clients',
//     permissions: [
//       {
//         id: uuid(),
//         option: '',
//         key: PermissionKeys.clients,
//         subPermissions: [
//           {
//             id: uuid(),
//             option: 'All clients',
//             value: ClientPermissions.AllClients,
//             key: PermissionKeys.clients,
//             accessTo: 'clients',
//           },
//           {
//             id: uuid(),
//             option: 'Subset of clients',
//             value: ClientPermissions.SubsetOFClients,
//             key: PermissionKeys.clients,
//             accessTo: 'clients',
//           },
//           {
//             id: uuid(),
//             option: 'Only clients they are added',
//             value: ContentPermissions.OnlyTheyAreAdded,
//             key: PermissionKeys.clients,
//             accessTo: 'clients',
//           },
//         ],
//       },
//     ],
//   },
// ];

const SETTINGS: Permissions[] = [
  {
    id: uuid(),
    heading: 'Settings',
    permissions: [
      {
        id: uuid(),
        option: 'Add Team member',
        key: PermissionKeys.addTeammates,
        subPermissions: [],
      },
      {
        id: uuid(),
        option: 'Remove Team member',
        key: PermissionKeys.removeTeammates,
        subPermissions: [],
      },
      {
        id: uuid(),
        option: 'Edit company details',
        key: PermissionKeys.editCompanyDetails,
        subPermissions: [],
      },
    ],
  },
];

const SCREENS: Permissions[] = [
  {
    id: uuid(),
    heading: 'Screen',
    permissions: [
      {
        id: uuid(),
        option: 'Screen access',
        key: PermissionKeys.screenAccess,
        subPermissions: [
          {
            id: uuid(),
            option: 'All screens',
            value: ScreenPermissions.AllScreens,
            key: PermissionKeys.screenAccess,
            accessTo: 'screenAccess',
          },
          {
            id: uuid(),
            option: 'Only where they are added',
            value: ScreenPermissions.OnlyTheyAreAdded,
            key: PermissionKeys.screenAccess,
            accessTo: 'screenAccess',
          },
        ],
      },
      {
        id: uuid(),
        option: 'Add screens and folders',
        key: PermissionKeys.addScreensAndFolders,
        subPermissions: [],
      },
      {
        id: uuid(),
        option: 'Delete screens and folders',
        key: PermissionKeys.deleteScreenAndFolder,
        subPermissions: [],
      },
    ],
  },
];

const CONTENTS: Permissions[] = [
  {
    id: uuid(),
    heading: 'Content',
    permissions: [
      {
        id: uuid(),
        option: 'Content access',
        key: PermissionKeys.contentAcces,
        subPermissions: [
          {
            id: uuid(),
            option: 'All contents',
            value: ContentPermissions.AllContents,
            key: PermissionKeys.contentAcces,
            accessTo: 'contentAcces',
          },
          {
            id: uuid(),
            option: 'Only where they are added',
            value: ContentPermissions.OnlyTheyAreAdded,
            key: PermissionKeys.contentAcces,
            accessTo: 'contentAcces',
          },
        ],
      },
      {
        id: uuid(),
        option: 'Add contents and folders',
        key: PermissionKeys.addContentAndFolders,
        subPermissions: [],
      },
      {
        id: uuid(),
        option: 'Delete contents and folders',
        key: PermissionKeys.deleteContentAndFolder,
        subPermissions: [],
      },
    ],
  },
];
const PLAYLISTS: Permissions[] = [
  {
    id: uuid(),
    heading: 'Playlists',
    permissions: [
      {
        id: uuid(),
        option: 'Playlist access',
        key: PermissionKeys.playListAccess,
        subPermissions: [
          {
            id: uuid(),
            option: 'All playlists',
            value: PlayListPermissions.AllPlayList,
            key: PermissionKeys.playListAccess,
            accessTo: 'playListAccess',
          },
          {
            id: uuid(),
            option: 'Only where they are added',
            value: PlayListPermissions.OnlyTheyAreAdded,
            key: PermissionKeys.playListAccess,
            accessTo: 'playListAccess',
          },
        ],
      },
      {
        id: uuid(),
        option: 'Add playlists and folders',
        key: PermissionKeys.addPlayListAndFolders,
        subPermissions: [],
      },
      {
        id: uuid(),
        option: 'Delete playlists and folders',
        key: PermissionKeys.deletePlayListAndFolder,
        subPermissions: [],
      },
    ],
  },
];

export const ALL_PERMISSIONS = [
  // ...RESELLER,
  // ...CLIENTS,
  ...SETTINGS,
  ...SCREENS,
  ...CONTENTS,
  ...PLAYLISTS,
];

// const RellerPermissions = {
//   manageReseller: true,
// };
// const ClientsPermissions = {
//   clients: 'allClients',
//   addClients: true,
//   deleteClients: true,
// };
// const TeammatesPermissions = {
//   addTeammates: true,
//   removeTeammates: true,
//   editCompanyDetails: true,
// };
// const ScreensPermissions = {
//   screenAccess: 'allScreens',
//   deleteScreenAndFolder: true,
// };
// const ContentsPermissions = {
//   contentAcces: 'allContents',
//   addContentAndFolders: true,
//   deleteContentAndFolder: true,
// };
// const PlaylistsPermissions = {
//   playListAccess: 'allPlayList',
//   addPlayListAndFolders: true,
//   deletePlayListAndFolder: true,
// };

// const PERMISSIONS = {
//   // RellerPermissions,
//   // ClientsPermissions,
//   // TeammatesPermissions,
//   // ScreensPermissions,
//   // ContentsPermissions,
//   // PlaylistsPermissions,
//   manageReseller: true,
//   clients: 'subsetOFClients',
//   addClients: true,
//   deleteClients: true,
//   addTeammates: true,
//   removeTeammates: true,
//   editCompanyDetails: true,
//   screenAccess: 'allScreens',
//   deleteScreenAndFolder: true,
//   contentAcces: 'allContents',
//   addContentAndFolders: true,
//   deleteContentAndFolder: true,
//   playListAccess: 'allPlayList',
//   addPlayListAndFolders: true,
//   deletePlayListAndFolder: true,
// };

import React from 'react';
import Radio from 'components/radiobutton/radioButton';
import styles from './featuresWithRadioBtns.module.scss';

interface iProps {
  className?: string;
  feature: string;
  isChecked?: boolean;
  value?: string;
  onChange?: (a: string) => void;
  isNotDisabled?: boolean;
}

function FeaturesWithRadioBtns(props: iProps): JSX.Element {
  const { className, feature, isChecked, value, onChange, isNotDisabled } =
    props;

  return (
    <div
      className={`${styles.featuresWithRadioBtns} ${className} ${
        !isNotDisabled && styles.disabled
      } `}
      onClick={(event) => {
        event.stopPropagation();
        if (isNotDisabled) {
          if (onChange) onChange(value || '');
        }
      }}
    >
      <Radio
        className={styles.radioBtn}
        value={value || ''}
        isChecked={isChecked}
        onChange={() => {}}
      />
      <p className={styles.feature}>{feature}</p>
    </div>
  );
}

FeaturesWithRadioBtns.defaultProps = {
  className: '',
  isChecked: false,
  value: '',
  onChange: () => {},
  isNotDisabled: false,
};

export default FeaturesWithRadioBtns;

import { LanguageType } from 'enums';

export const getCurrentTime = (timeZone = ''): string => {
  const date = new Date(new Date().toLocaleString('en-US', { timeZone }));
  let hours = `${date.getHours()}`;
  hours = (hours.length === 1 && `0${hours}`) || hours;
  let minutes = `${date.getMinutes()}`;
  minutes = (minutes.length === 1 && `0${minutes}`) || minutes;
  return `${hours}:${minutes}`;
};

export const getCurrentDate = (
  timeZone = '',
  language: LanguageType
): string => {
  const DAYS = (language === LanguageType.English && [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]) ||
    (language === LanguageType.Norwegian && [
      'Søndag',
      'Mandag',
      'Tirsdag',
      'Onsdag',
      'Torsdag',
      'Fredag',
      'Lørdag',
    ]) || [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];

  const MONTH = (language === LanguageType.English && [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]) ||
    (language === LanguageType.Norwegian && [
      'Januar',
      'Februar',
      'Mars',
      'April',
      'Kan',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Desember',
    ]) || [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
  const date = new Date(new Date().toLocaleString('en-US', { timeZone }));
  return `${DAYS[date.getDay()]} ${date.getDate()}, ${MONTH[date.getMonth()]}`;
};

export const getHours = (date: string): string => {
  let hours = `${new Date(date).getHours()}`;
  hours = (hours.length === 1 && `0${hours}`) || hours;
  return hours;
};

export const getMinutes = (date: string): string => {
  let minutes = `${new Date(date).getMinutes()}`;
  minutes = (minutes.length === 1 && `0${minutes}`) || minutes;
  return minutes;
};

export const getMinutesDifference = (date: string): any => {
  const givenDate = new Date(`${date}Z`);
  // const currentDate: any = new Date();
  const difference = givenDate.getTime() - new Date().getTime();
  const minuteDifference = difference / 60000;
  if (minuteDifference) return minuteDifference;
  return null;
};

import { getCompanies } from 'apis';
import Popover from 'components/popover/popover';
import Search from 'components/search/search';
import { checkCircleIcon, expandArrowMore, ROUTES } from 'constants/index';
import { LayoutViews } from 'enums';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import {
  contentPageActions,
  homePageActions,
  layoutActions,
  playlistPageActions,
} from 'store/actions';
import { iCompanyData, iState } from 'types';
import styles from './clientsListDropdown.module.scss';

interface iProps {
  className?: string;
}

function ClientsListDropdown(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const [isShowing, setIsShowing] = useState<boolean>(false);
  const {
    companies = [],
    companyData,
    invokeGetCompanies,
    activeCompanyData,
  } = state.homePage || {};
  const [popOverEffect, setPopoverEffect] = useState(false);
  const [searchClick, setSearchClick] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const activeClient = companyData;

  const getAllCompanies = useCallback(async () => {
    const response = await getCompanies({ limit: 0, skip: 0 }, setIsLoading);
    if (response) {
      dispatch(homePageActions.setCompanies(response));
      // if (companyData) {
      //   const updatedCompany = response.find(
      //     (company) => company.id === companyData?.id
      //   );
      //   if (updatedCompany) {
      //     dispatch(homePageActions.setCompanyData(updatedCompany));
      //   }
      // }
      dispatch(homePageActions.invokeGetCompanies(false));
    }
  }, [dispatch]);

  useEffect(() => {
    getAllCompanies();
  }, []);

  useEffect(() => {
    if (invokeGetCompanies) getAllCompanies();
  }, [invokeGetCompanies, getAllCompanies]);

  const getCompaniesData = useCallback(async () => {
    const response = await getCompanies({ limit: 0, skip: 0 }, setIsLoading);
    if (response) {
      dispatch(homePageActions.setCompanies(response));
      if (companyData) {
        const updatedCompany = response.find(
          (company) => company.id === companyData?.id
        );
        if (updatedCompany) {
          dispatch(homePageActions.setCompanyData(updatedCompany));
        }
      }
      // dispatch(homePageActions.invokeGetCompanies(false));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeCompanyData) {
      getCompaniesData();
      dispatch(homePageActions.setActiveCompanyData(false));
    }
  }, [activeCompanyData]);
  // useEffect(() => {
  //   console.log(activeClient, 'activeClient');
  // }, [state]);

  const onClickClient = useCallback(
    (company: iCompanyData) => {
      dispatch(layoutActions.setShowClientsInstance(true));
      dispatch(homePageActions.setCompanyData(company));
      dispatch(layoutActions.initialSidebarState(true));
      dispatch(layoutActions.setHomePageView(LayoutViews.Screens));
      dispatch(contentPageActions.setContents([]));

      dispatch(homePageActions.setScreensActiveFolder(null));
      dispatch(homePageActions.setScreensFolders([]));
      dispatch(homePageActions.setScreensFolderHierarchy([]));

      dispatch(contentPageActions.setContentActiveFolder(null));
      dispatch(contentPageActions.setContentFolders([]));
      dispatch(contentPageActions.setContentFolderHierarchy([]));

      dispatch(playlistPageActions.setPlaylistsActiveFolder(null));
      dispatch(playlistPageActions.setPlaylistsFolders([]));
      dispatch(playlistPageActions.setPlaylistsFolderHierarchy([]));

      dispatch(layoutActions.setAssignModalContentActiveFolders(null));
      dispatch(layoutActions.setAssignModalContentFolders([]));
      dispatch(layoutActions.setAssignModalContentsFolderHierarchy([]));
      navigate(ROUTES.screenz);
    },
    [dispatch, navigate]
  );

  return (
    <Popover
      // canCloseOnClick={!searchClick}
      searchClick={searchClick}
      setIsShowing={setIsShowing}
      popoverClassName={styles.dropdown}
      popoverComponent={
        <div className="">
          <section
            onClick={() => setSearchClick(!searchClick)}
            className={styles.searchContainer}
          >
            <Search
              setKeyword={setKeyword}
              className={styles.searchField}
              isOpenState
              isShowClear
              rolebasedHeader
            />
          </section>
          <ul className={styles.listContainer}>
            {companies
              .filter((company) =>
                company.name
                  .toLocaleLowerCase()
                  .includes(keyword.toLocaleLowerCase())
              )
              .map((i: iCompanyData) => (
                <li
                  key={i.companyUserId}
                  className={`${styles.listItem} ${
                    i.id === activeClient?.id && styles.active
                  }`}
                  onClick={() => {
                    onClickClient(i);
                    setKeyword('');
                  }}
                >
                  {i.name}
                  {activeClient && i.id === activeClient.id && (
                    <img
                      className={styles.activeClientIcon}
                      src={checkCircleIcon}
                      alt=""
                    />
                  )}
                </li>
              ))}
          </ul>
        </div>
      }
      popOverEffect={popOverEffect}
      setPopoverEffect={setPopoverEffect}
      setSearchKeyword={setKeyword}
    >
      <div
        className={`${className} ${styles.clientsDropdown} ${
          isShowing && styles.shown
        }`}
        onClick={() => setIsShowing(!isShowing)}
      >
        <span className={styles.clientName}>Clients</span>
        <img className={styles.expandArrowRight} src={expandArrowMore} alt="" />
        <span className={styles.clientName}>
          {activeClient && !isLoading ? activeClient?.name : 'All Clients'}
        </span>
        <img
          className={`${styles.expandArrowDown} ${isShowing && styles.shown}`}
          src={expandArrowMore}
          alt=""
        />
      </div>
    </Popover>
  );
}

ClientsListDropdown.defaultProps = {
  className: '',
};

export default ClientsListDropdown;

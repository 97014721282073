import { getCompanies } from 'apis';
import Button, { ButtonVariants } from 'components/button/button';
import Popover from 'components/popover/popover';
import {
  arrowRightIcon,
  companyIcon,
  disconnectionIcon,
  menuMoreIcon,
  ROUTES,
  screenIcon,
  TrashRedIcon,
  userIcon,
} from 'constants/index';
import { ModalViews, UserSettingsView } from 'enums';
// import { getOnlineStatus } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import {
  contentPageActions,
  homePageActions,
  layoutActions,
  playlistPageActions,
  userActions,
} from 'store/actions';
import { iCompanyData, iState } from 'types';
import styles from './clientTile.module.scss';
import { ClientTileVariants, Variants } from './Variants';

interface iProps {
  className?: string;
  variant: ClientTileVariants;
  data: iCompanyData;
}

// interface iDeviceStatusCounts {
//   offlineCount: number;
//   onlineCount: number;
// }

function ClientCard(props: iProps): JSX.Element {
  const { className, variant, data } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { showClientsCount, showPlan } = Variants[variant];
  const [dropDown, setDropDown] = useState(false);
  // const [deviceStatusCounts, setDeviceStatusCounts] =
  //   useState<iDeviceStatusCounts>({
  //     offlineCount: 0,
  //     onlineCount: 0,
  //   });
  const navigate = useNavigate();
  const [, setIsLoading] = useState(false);

  useEffect(() => {
    //  console.log(state, 'deleteModal');
  }, [state.layout.isShowDeleteModal]);

  const onClickClient = useCallback(() => {
    dispatch(layoutActions.setShowClientsInstance(true));
    dispatch(homePageActions.setCompanyData(data));
    dispatch(layoutActions.initialSidebarState(true));

    dispatch(homePageActions.setScreensActiveFolder(null));
    dispatch(homePageActions.setScreensFolders([]));
    dispatch(homePageActions.setScreensFolderHierarchy([]));

    dispatch(contentPageActions.setContentActiveFolder(null));
    dispatch(contentPageActions.setContentFolders([]));
    dispatch(contentPageActions.setContentFolderHierarchy([]));

    dispatch(playlistPageActions.setPlaylistsActiveFolder(null));
    dispatch(playlistPageActions.setPlaylistsFolders([]));
    dispatch(playlistPageActions.setPlaylistsFolderHierarchy([]));

    dispatch(layoutActions.setAssignModalContentActiveFolders(null));
    dispatch(layoutActions.setAssignModalContentFolders([]));
    dispatch(layoutActions.setAssignModalContentsFolderHierarchy([]));

    navigate(ROUTES.screenz);
  }, [dispatch, data]);

  const getAllCompanies = async (): Promise<void> => {
    const response = await getCompanies({ limit: 0, skip: 0 }, setIsLoading);
    if (response) {
      dispatch(homePageActions.setCompanies(response));
      dispatch(homePageActions.invokeGetCompanies(false));
    }
  };

  const onDeleteClient = async (
    e: React.MouseEvent<HTMLInputElement>
  ): Promise<void> => {
    e.stopPropagation();
    dispatch(homePageActions.setCompanyData(data));
    dispatch(layoutActions.toggleModal(ModalViews.isShowDeleteClient));
    dispatch(homePageActions.invokeGetCompanies(false));
    await getAllCompanies();
  };

  const onClickSettings = useCallback(() => {
    dispatch(homePageActions.setCompanyData(data));
    dispatch(userActions.changeView(UserSettingsView.General));
    navigate(ROUTES.userSettings);
  }, [navigate]);

  const onContainerClickClient = (
    e: React.MouseEvent<HTMLInputElement>
  ): any => {
    if (e.target instanceof Image) return;
    // const target = e.target as HTMLParagraphElement;
    // if (target.id === 'deleteClient') return;

    onClickClient();
  };

  // const onChange = useCallback((event): void => {
  //   if (event.target instanceof Image) return;
  //   const target = event.target as HTMLParagraphElement;
  //   if (target.id === 'deleteClient') return;
  //   onClickClient();
  // }, [onclick]);

  // const getScreensStatusHandler = useCallback(async (companyData) => {
  //   if (!companyData) return;

  //   const response = await getScreensStatusByCompany({
  //     companyId: companyData.id,
  //   });
  //   if (response) {
  //     const onlineStatusMapped = response.map((i) => ({
  //       ...i,
  //       lastPing: response.find((j) => j.id === i.id)?.lastPing || '',
  //     }));

  //     let offlineCount = 0;
  //     let onlineCount = 0;
  //     if (onlineStatusMapped) {
  //       onlineStatusMapped.forEach((element) => {
  //         if (getOnlineStatus(element.lastPing)) {
  //           onlineCount += 1;
  //         } else {
  //           offlineCount += 1;
  //         }
  //       });
  //       setDeviceStatusCounts({ offlineCount, onlineCount });
  //     } else {
  //       setDeviceStatusCounts({ offlineCount: 0, onlineCount: 0 });
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   getScreensStatusHandler(data);
  // }, [getScreensStatusHandler, data]);

  return (
    <div
      key={data.id}
      className={`${className} ${styles.clientCard}`}
      onClick={onContainerClickClient}
    >
      <div className={styles.header}>
        <div className={styles.headerContainer}>
          <p className={styles.clientName}>{data.name}</p>
          <Popover
            popoverClassName={styles.morePopover}
            popoverComponent={
              <>
                <div className={styles.moreWrapper}>
                  <div onClick={onClickClient} className={styles.moreOption}>
                    <img src={companyIcon} alt="" />
                    <p>Go to client</p>
                  </div>
                  <div onClick={onClickSettings} className={styles.moreOption}>
                    <img src={userIcon} alt="" />
                    <p>Settings</p>
                  </div>
                  <div onClick={onDeleteClient} className={styles.moreOption}>
                    <img src={TrashRedIcon} alt="" />
                    <p id="deleteClient">Delete Client</p>
                  </div>
                </div>
                <div className={styles.caretContainer}>
                  <span />
                </div>
              </>
            }
            setPopoverEffect={setDropDown}
            popOverEffect={dropDown}
          >
            <Button
              className={`${styles.moreBtn} ${dropDown && styles.activeMore}`}
              variant={ButtonVariants.SmallTransp}
              icon={menuMoreIcon}
              iconPosition="center"
            />
          </Popover>
        </div>
        <div className={styles.statusContainer}>
          <div className={`${styles.status} ${styles.online}`}>
            <div className={`${styles.statusIcon} ${styles.connected}`}>
              <img src={screenIcon} alt="" />
            </div>
            <span className={styles.count}>
              {(data.onlinecount && data.onlinecount) || 0}
            </span>
            <span className={`${styles.message} ${styles.online}`}>Online</span>
          </div>

          <div className={`${styles.status} ${styles.offline}`}>
            <div className={`${styles.statusIcon} ${styles.disconnected}`}>
              <img src={disconnectionIcon} alt="" />
            </div>
            <span className={styles.count}>
              {(data.offlinecount && data.offlinecount) || 0}
            </span>
            <span className={`${styles.message} ${styles.offline}`}>
              Offline
            </span>
          </div>
        </div>
      </div>
      <div className={`${styles.footer} ${styles[variant]}`}>
        {showClientsCount && (
          <div className={styles.clientsCount}>
            <span>Clients: </span>
            <span>{data.totalClients}</span>
          </div>
        )}
        {showPlan && (
          <div className={styles.clientPlan}>
            <p>Plan: </p>
            <p>Pro</p>
          </div>
        )}
        <div className={styles.gotoClient} onClick={onClickClient}>
          Go to client
          <img src={arrowRightIcon} alt="" />
        </div>
      </div>
    </div>
  );
}

export { ClientTileVariants };
ClientCard.defaultProps = {
  className: '',
};

export default ClientCard;

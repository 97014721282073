import React from 'react';
import styles from './notsetup.module.scss';

interface iProps {
  className?: string;
  isEnabled: boolean;
}

function NotSetup(props: iProps): JSX.Element {
  const { className, isEnabled } = props;
  //  console.log('isEnabled_____', isEnabled);

  return (
    <div
      className={`${className} ${styles.notSetup} ${
        isEnabled && styles.enabled
      }`}
    >
      {isEnabled ? 'Enabled' : 'Not Setup'}
    </div>
  );
}

NotSetup.defaultProps = {
  className: '',
};

export default NotSetup;

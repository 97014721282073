import Content from 'components/svg/content';
import Playlist from 'components/svg/playlist';
import Screenz from 'components/svg/screenz';
import { ROUTES } from 'constants/index';
import { uuid } from 'helpers';

export const SIDEBAR_ICONS = [
  {
    id: uuid(),
    icon: Screenz,
    iconName: 'Screens',
    path: ROUTES.screenz,
  },
  {
    id: uuid(),
    icon: Content,
    iconName: 'Content',
    path: ROUTES.content,
  },
  {
    id: uuid(),
    icon: Playlist,
    iconName: 'Playlists',
    path: ROUTES.playlists,
  },
];

export const COMPANIES = [
  { id: uuid(), name: 'Food Asylum' },
  { id: uuid(), name: 'Panda Panda' },
  { id: uuid(), name: 'Curry Curry' },
];

export { Screenz };

import { deletePlaylist } from 'apis';
import RunningStatus from 'components/assign-content-modal/running-state/runningState';
import Checkbox from 'components/checkbox/checkbox';
import Popover from 'components/popover/popover';
import More from 'components/svg/more';
import PortraitScreen from 'components/svg/portraitScren';
import Screen from 'components/svg/screenz';
import {
  dustbinIcon,
  playlistsIcon,
  sortIcon,
  timerIcon /* activeFolderIcon */,
} from 'constants/index';
import { DeleteStatus, ModalViews, ScreenType } from 'enums';
import {
  getDurationInTimeFormat,
  getNewlySharedStatus,
  toaster,
} from 'helpers';
import React, { useCallback } from 'react';
import { useSortBy, useTable } from 'react-table';
import { useStore } from 'store';
import {
  homePageActions,
  layoutActions,
  // playlistPageActions,
} from 'store/actions';
// import { playlistPageActions } from 'store/actions';
import { iPlaylistData, iState } from 'types';
import styles from './playlistTable.module.scss';

interface iProps {
  className?: string;
  isShowCheckBox?: boolean;
  // fullHeight?: boolean;
  columns?: any[];
  data: iPlaylistData[];
  onClick?: (data: iPlaylistData) => void;
  setDataToDelete?: (data: iPlaylistData) => void;
  showDeleteButton?: boolean;
  displayNewItemFlag?: boolean;
  setIsPlaylistUsedSomeWhere?: (data: boolean) => void;
}

function PlaylistsTable(props: iProps): JSX.Element {
  const {
    className,
    isShowCheckBox = true,
    // fullHeight,
    columns,
    data,
    onClick,
    setDataToDelete,
    showDeleteButton,
    displayNewItemFlag,
    setIsPlaylistUsedSomeWhere,
  } = props;
  console.log(showDeleteButton);
  // const windowHeight = window.innerHeight;
  const [state, dispatch]: [iState, any] = useStore();
  const { contentToAddToPlaylist } = state.playlistsPage || {};
  const { playlistToAssignScreen } = state.homePage || {};
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  // useEffect(() => {
  //   //  console.log(data, '');
  // }, [data]);

  const firstPageRows = rows.slice(0, (data && data?.length) || 20);

  const onClickRow = useCallback(
    (row: iPlaylistData) => {
      // const folderDAta: any = {
      //   company: '',
      //   created_at: '2022-11-11T05:12:51.207Z',
      //   folderType: 'PLAYLIST',
      //   folder_path: '',
      //   id: row.id,
      //   name: row.name,
      //   parentFolders: [],
      //   updated_at: '2022-11-11T05:12:51.207Z',
      //   url: '',
      //   __typename: 'Playlist',
      // };
      // dispatch(homePageActions.setIsFetchingFolders(true));
      // dispatch(playlistPageActions.setPlaylistsActiveFolder(folderDAta));
      dispatch(homePageActions.setPlaylistToAssignToScreen(row.id));
      // dispatch(homePageActions.setContentToAssignToScreen(''));
      if (onClick) onClick(row);
    },
    [onClick, dispatch]
  );

  const deletePlaylistHandler = useCallback(
    async (cell) => {
      if (!cell.row.original) return;

      const response = await deletePlaylist(
        {
          id: cell.row.original.id,
          is_data: false,
        },
        () => {}
      );
      if (response === DeleteStatus.UsedSomewhere) {
        if (setIsPlaylistUsedSomeWhere) setIsPlaylistUsedSomeWhere(true);
        dispatch(layoutActions.toggleModal(ModalViews.isShowDeletePlaylist));
      }
      if (response !== DeleteStatus.UsedSomewhere) {
        dispatch(layoutActions.toggleModal(ModalViews.isShowDeletePlaylist));
      }
      if (response === DeleteStatus.Deleted) {
        console.log('deleted');
      }
    },
    [dispatch]
  );

  const onClickDelete = useCallback(
    (cell) => {
      if (setDataToDelete) setDataToDelete(cell.row.original);
      deletePlaylistHandler(cell);
    },
    [setDataToDelete, dispatch]
  );

  return (
    <table {...getTableProps()} className={`${styles.tableHead} ${className}`}>
      <thead>
        {headerGroups.map((headerGroup) => {
          return (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const { id } = column || {};
                const showTimerIcon = id === 'duration';
                const showSortIcon = id !== 'name';
                const isMore = id === 'more';

                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={isMore ? styles.hideSort : undefined}
                  >
                    {showTimerIcon && (
                      <img
                        className={styles.timerIcon}
                        src={timerIcon}
                        alt=""
                      />
                    )}
                    {column.render('Header')}
                    {showSortIcon && (
                      <img className={styles.sortIcon} src={sortIcon} alt="" />
                    )}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody
        {...getTableBodyProps()}
        // style={
        //   (fullHeight && {
        //     maxHeight: windowHeight - 295,
        //   }) ||
        //   {}
        // }
      >
        {firstPageRows.map((row, index) => {
          prepareRow(row);
          const { original } = row || {};

          const isSelected =
            contentToAddToPlaylist?.includes(original.id) ||
            original.id === playlistToAssignScreen;
          return (
            <tr
              {...row.getRowProps()}
              className={`
              ${
                isShowCheckBox ? styles.gridFiveColumns : styles.gridFourColumns
              } 
              ${isSelected && styles.active}
              `}
              onClick={() => onClickRow(original)}
            >
              {isShowCheckBox && (
                <Checkbox className={styles.checkBox} isChecked={isSelected} />
              )}
              {row.cells.map((cell) => {
                const { id } = cell.column || {};
                const showPlayIcon = id === 'name';
                const showScreenIcon = id === 'screenType';
                const showRunningComp = id === 'status';
                const runTime = id === 'duration';
                const isMore = id === 'more';
                const cellName = cell?.value || '';

                return (
                  <td
                    {...cell.getCellProps()}
                    className={`${id === 'name' && styles.name}`}
                  >
                    {showPlayIcon && (
                      <img
                        className={styles.playlistsIcon}
                        src={playlistsIcon}
                        alt=""
                      />
                    )}
                    {showScreenIcon &&
                      original?.screenType === ScreenType.HORIZONTAL && (
                        <Screen className={styles.screenIcon} />
                      )}
                    {showScreenIcon &&
                      original?.screenType === ScreenType.VERTICAL && (
                        <PortraitScreen className={styles.screenIcon} />
                      )}
                    {(showRunningComp && (
                      <RunningStatus status={cell.value} />
                    )) ||
                      (runTime && getDurationInTimeFormat(cell.value)) ||
                      (cellName && cellName?.length > 30
                        ? `${cellName?.slice(0, 30)}...`
                        : cellName)}
                    {showPlayIcon &&
                      displayNewItemFlag &&
                      getNewlySharedStatus(original.sharedAt) && (
                        <p className={styles.sharedStatus}>
                          Newly shared with you
                        </p>
                      )}
                    {isMore && (
                      <Popover
                        className={styles.morePopupContainer}
                        popoverClassName={`${styles.popover} ${
                          index === firstPageRows.length - 1 && styles.last
                        }`}
                        popoverComponent={
                          // showDeleteButton === false ? (
                          <div
                            className={`${styles.morePopup} ${
                              showDeleteButton && styles.disabled
                            }`}
                            onClick={(event) => {
                              event.stopPropagation();
                              if (!showDeleteButton) {
                                onClickDelete(cell);
                                return;
                              }
                              toaster.error('You have no access to delete');
                            }}
                          >
                            <img src={dustbinIcon} alt="" />
                            <p>Delete</p>
                          </div>
                          // ) : (
                          //   <div />
                          // )
                        }
                      >
                        <div className={styles.moreBtn}>
                          <More className={styles.icon} />
                        </div>
                      </Popover>
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

PlaylistsTable.defaultProps = {
  className: '',
  isShowCheckBox: false,
  // fullHeight: false,
  columns: [],
  onClick: () => {},
  setDataToDelete: () => {},
  setIsPlaylistUsedSomeWhere: () => {},
  showDeleteButton: false,
  displayNewItemFlag: false,
};

export default PlaylistsTable;

import { norwayData, englishData } from 'constants/index';
import {
  CalenderSettingsView,
  CARD_NAME,
  ClockContentType,
  EventContentType,
  LanguageType,
} from 'enums';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import { meetingOverviewFullActions } from 'store/actions';
import { getCurrentDate } from '../../../is-meetingRoom-section/event-view/constants';
import {
  getCurrentTime,
  getHours,
  getMinutes,
} from '../../../is_meetingMessageOverview-section/event-view/constants';

import styles from './longComponent.module.scss';

interface iProps {
  className?: string;
  cardNumber: CARD_NAME;
}

function LongComponent(props: iProps): JSX.Element {
  const { className, cardNumber } = props;
  const [state, dispatch] = useStore();

  const { currentView, currentCard, cardDetails } =
    state.meetingOverviewFull || {};
  const { ContentSetting, TemplateSetting } = CalenderSettingsView;
  const {
    contentOption,
    clockAlignment,
    overviewTimezone,
    overviewEventLogo,
    overviewCustom_name,
    roomDetails = '',
    overviewCurrentCalenderEvents,
    overviewNextEvents,
    isShowOverviewSubject,
    isShowOverviewHostName,
    isShowOverviewNextMeeting,
    overviewChoosedRoom,
  } = cardDetails[cardNumber] || {};

  const { start, end, subject, organizer } =
    overviewCurrentCalenderEvents || {};
  const { start: currentStart, end: currentEnd } = overviewNextEvents || {};

  const { selectedLanguage } = state.office365 || {};

  const [templateTexts, setTemplateTexts] = useState<any>(englishData);

  useEffect(() => {
    if (selectedLanguage.value === LanguageType.English) {
      setTemplateTexts(englishData);
    }
    if (selectedLanguage.value === LanguageType.Norwegian) {
      setTemplateTexts(norwayData);
    }
  }, [selectedLanguage]);

  const formatNumber = (number): string => {
    return `0${number}`.slice(-2);
  };

  const getTimeFromData = (date): string => {
    //  console.log(`date`);
    //  console.log(date);

    const dateFormate = new Date(`${date}Z`);
    const hours = dateFormate.getHours().toString();
    const minutes = dateFormate.getMinutes().toString();

    return `${formatNumber(hours)}:${formatNumber(minutes)}`;
  };

  const startTime =
    start && `${getHours(start.dateTime)}:${getMinutes(start.dateTime)}`;

  const endTime =
    end && `${getHours(end.dateTime)}:${getMinutes(end.dateTime)}`;

  const nextStartTime =
    currentStart &&
    `${getHours(currentStart.dateTime)}:${getMinutes(currentStart.dateTime)}`;

  const nextEndTime =
    (currentEnd &&
      `${getHours(currentEnd.dateTime)}:${getMinutes(currentEnd.dateTime)}`) ||
    '';

  const selectedLanguageType =
    (selectedLanguage.value === LanguageType.English && LanguageType.English) ||
    (selectedLanguage.value === LanguageType.Norwegian &&
      LanguageType.Norwegian) ||
    LanguageType.English;
  const isTemplatesSettings = currentView === TemplateSetting;
  const isRightAligned = clockAlignment.value === ClockContentType.RightAligned;
  return (
    <div
      className={`${className} ${styles.currentTime} ${
        currentCard === cardNumber && !isTemplatesSettings && styles.showActive
      }`}
      onClick={() => {
        dispatch(meetingOverviewFullActions.setCurrentCard(cardNumber));
        dispatch(meetingOverviewFullActions.setCureentView(ContentSetting));
      }}
    >
      {contentOption.value === EventContentType.Image && overviewEventLogo && (
        <div className={styles.logoHolder}>
          <img src={overviewEventLogo} alt="" />
        </div>
      )}
      {contentOption.value === EventContentType.Clock &&
        clockAlignment.value !== EventContentType.Empty && (
          <>
            <p
              className={`${styles.time} ${
                isRightAligned && styles.rightAligned
              }`}
            >
              {overviewTimezone &&
                (overviewTimezone.value || '').includes('/') &&
                getCurrentTime(overviewTimezone?.value)}
            </p>
            <p
              className={`${styles.date} ${
                isRightAligned && styles.rightAligned
              }`}
            >
              {overviewTimezone &&
                (overviewTimezone.value || '').includes('/') &&
                getCurrentDate(overviewTimezone?.value, selectedLanguageType)}
            </p>
          </>
        )}
      {contentOption.value === EventContentType.Calender && (
        <div className={styles.meetingContainer}>
          <div className={styles.subjectInfo}>
            <div className={styles.meetingInfoAndDetails}>
              <p
                className={`${styles.meetingInfo} ${
                  overviewCurrentCalenderEvents?.start.dateTime &&
                  styles.ongoing
                }`}
              >
                {overviewCustom_name.length < 17
                  ? overviewCustom_name
                  : `${overviewCustom_name.slice(0, 17)}...`}
              </p>
              <p className={styles.roomDetails}>
                {roomDetails.length < 17
                  ? roomDetails
                  : `${roomDetails.slice(0, 17)}...`}
              </p>
            </div>
            <p className={styles.meetingTime}>
              {overviewChoosedRoom &&
                (!startTime || !endTime) &&
                templateTexts.available}
              {(startTime || endTime) &&
                `${getTimeFromData(
                  overviewCurrentCalenderEvents?.start.dateTime
                )}-> ${getTimeFromData(
                  overviewCurrentCalenderEvents?.end.dateTime
                )}`}
            </p>
          </div>
          {isShowOverviewSubject && (
            <p className={styles.description}>{subject}</p>
          )}

          <div className={styles.orgainzerContainer}>
            <p className={styles.organizer}>
              {(isShowOverviewHostName &&
                organizer &&
                organizer.emailAddress.name) ||
                ''}
            </p>
            {isShowOverviewNextMeeting && (
              <div className={styles.subject}>
                <p className={styles.heading}>
                  {overviewNextEvents && `${templateTexts.nextMeeting} -`}
                </p>
                {overviewNextEvents && (
                  <p className={styles.heading}>
                    {overviewNextEvents.subject} -{' '}
                  </p>
                )}
                <p className={styles.heading}>
                  {(nextStartTime || nextEndTime) &&
                    `${getTimeFromData(
                      overviewNextEvents?.start.dateTime
                    )}-> ${getTimeFromData(overviewNextEvents?.end.dateTime)}`}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

LongComponent.defaultProps = {
  className: '',
};

export default LongComponent;

import { eyeCrossed, eyeIcon } from 'constants/index';
import React, { useState } from 'react';
import styles from './input.module.scss';

interface iProps {
  id?: string;
  className?: string;
  value: string;
  type?: 'text' | 'password';
  isError?: boolean;
  // errorMessage?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  onFocus?: () => void;
  onKeyUp?: (event: any) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hideBorder?: boolean;
}

function Input(props: iProps): JSX.Element {
  const {
    id,
    className,
    placeholder,
    value,
    isError = false,
    // errorMessage = '',
    name,
    type = 'text',
    disabled,
    onChange,
    onFocus,
    onKeyUp,
    hideBorder,
  } = props;
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  //  console.log(errorMessage);

  return (
    <div className={styles.inputContainer}>
      <input
        id={id}
        name={name}
        type={isShowPassword ? 'text' : type}
        className={`${className} ${styles.input}
         ${styles}
         ${isError && styles.error} ${disabled && styles.disabled} ${
          hideBorder && styles.hideBorder
        }`}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        autoComplete="none"
      />
      <input
        autoComplete="on"
        style={{ display: 'none' }}
        id="fake-hidden-input-to-stop-google-address-lookup"
      />
      {type === 'password' && (
        <img
          className={styles.eyeIcon}
          src={isShowPassword ? eyeCrossed : eyeIcon}
          alt=""
          onClick={() => setIsShowPassword(!isShowPassword)}
        />
      )}
    </div>
  );
}

Input.defaultProps = {
  id: '',
  name: '',
  type: 'text',
  isError: false,
  // errorMessage: '',
  className: '',
  placeholder: '',
  disabled: false,
  onFocus: () => {
    //
  },
  onKeyUp: () => {},
  onChange: () => {},
  hideBorder: false,
};

export default Input;

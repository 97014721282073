import { addScreen } from 'apis';
import LabelAndInput from 'components/label-and-input/labelAndInput';
import Modal from 'components/modal/modal';
import { ScreenTabIcon } from 'constants/index';
import { LayoutViews } from 'enums';
import { toaster } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'store';
import { homePageActions, layoutActions } from 'store/actions';
import { iState } from 'types';
import styles from './addScreenModal.module.scss';

// const screenHash = Math.floor(Math.random() * 1000000);
function AddScreenModal(): JSX.Element {
  const [state, dispatch]: [iState, any] = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { layout } = state || {};
  const [screenName, setScreenName] = useState<string>('');
  const [hashCode, setHashCode] = useState<string>('');
  const [isShowDelayMessage, setIsShowDelayMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [hashCodeError, setHashCodeError] = useState<string>('');

  const { companyData, screensActiveFolder } = state.homePage || {};
  const {
    isShowAddScreen = false,
    isShowingClientInstance,
    chosenView,
  } = layout || {};

  const onChange = useCallback((event): void => {
    setScreenName(event.target.value);
    setErrorMessage('');
    setHashCodeError('');
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch(homePageActions.setIsScreenBeingAdded('Newly adding screen'));
  //   }, 3000);
  // }, [dispatch]);

  const addScreenHandler = useCallback(() => {
    (async () => {
      const { id: companyId } = companyData || {};
      if (!screenName) {
        setErrorMessage('Please enter a screen name');
        return;
      }
      if (!hashCode) {
        setHashCodeError('Please enter a hash code');
        return;
      }
      if (!companyId) return;

      if (chosenView === LayoutViews.Screens || isShowingClientInstance) {
        setIsLoading(true);
        const { companyUserId } = companyData || {};
        const response = await addScreen(
          {
            hash: `${hashCode}`,
            companyId,
            name: screenName,
            folderId: screensActiveFolder?.id || null,
            companyUserId: companyUserId || null,
            // companyUserId: 'd1ba521d-6756-4c5b-beed-9b3363423a46',
            userId: state.user.loggedInData?.id || '',
          },
          () => {}
        );
        if (response) {
          // setIsShowDelayMessage(true);
          dispatch(layoutActions.toggleModal());
          setIsLoading(false);
          dispatch(homePageActions.setIsScreenBeingAdded(screenName));
          // toaster.success(
          //   'Your screen is being added. This will take a minute or two. Please wait!',
          //   40000
          // );
          /**
           * Dont change this delay code,  Mohan asked to implement the delay, for some scenario
           * */
          setTimeout(() => {
            setScreenName('');
            setHashCode('');
            setIsShowDelayMessage(false);
            dispatch(homePageActions.fetchScreensData(true));
            toaster.success('Screen added successfully');
          }, 40000);
        } else {
          setIsLoading(false);
        }
      }
    })();
  }, [
    dispatch,
    chosenView,
    isShowingClientInstance,
    screenName,
    hashCode,
    companyData,
    screensActiveFolder?.id,
  ]);
  useEffect(() => {
    const inputElement = document.getElementById('addScreenName');
    if (inputElement && isShowAddScreen) {
      inputElement.focus();
    }
  }, [isShowAddScreen]);

  const closeModalHandler = useCallback(() => {
    dispatch(layoutActions.toggleModal());
    setErrorMessage('');
    setHashCodeError('');
    setScreenName('');
    setHashCode('');
  }, [dispatch]);

  return (
    <Modal
      isOpen={isShowAddScreen}
      heading="Add new screen"
      btnName="Add Screen"
      isLoading={isLoading}
      headingIcon={ScreenTabIcon}
      onClose={closeModalHandler}
      submit={addScreenHandler}
    >
      <div className={styles.addScreen}>
        {isShowDelayMessage && (
          <p className={styles.delayMessage}>
            Your screen is being added. This will take a minute or two. Please
            wait!
          </p>
        )}
        {!isShowDelayMessage && (
          <>
            <div className={styles.labelAndInput}>
              <span className={styles.label}>Screen name</span>
              <LabelAndInput
                className={styles.input}
                name="screenName"
                id="addScreenName"
                label={errorMessage && `Screen Name`}
                placeholder="Screen Name"
                value={screenName}
                onChange={onChange}
                errorMessage={errorMessage}
              />
            </div>
            <div className={styles.labelAndInput}>
              <span className={styles.label}>Screen Hash</span>
              <LabelAndInput
                className={styles.input}
                name="screenHash"
                label={hashCodeError && 'Hash Code'}
                placeholder="Hash Code"
                value={hashCode}
                onChange={(e) => setHashCode(e.target.value)}
                errorMessage={hashCodeError}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

export default AddScreenModal;

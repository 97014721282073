import React, { useCallback, useState } from 'react';
import { updateCompanyFeature } from 'apis';
import { toaster } from 'helpers';
import styles from './checkboxContainer.module.scss';
import Checkbox from '../checkbox/checkbox';

interface iProps {
  data: any;
  item: any;
  setData: any;
}

function CheckboxContainer(props: iProps): JSX.Element {
  const { data, item, setData } = props;
  const [checked] = useState(false);

  const isChecked = item.hasAccessManagementPermission;

  const updateCompanyFeatureOfCompany = useCallback(async (client) => {
    const response = await updateCompanyFeature(
      {
        companyId: client.id,
        accessManagement: !client.hasAccessManagementPermission,
      },
      () => {}
    );
    if (response) {
      toaster.success('Updated access');
    }
  }, []);

  return (
    <div className={styles.listHeader}>
      <div className={styles.nameContainer}>
        <p className={styles.name}>{item.name}</p>
        <p className={styles.type}>{item.type}</p>
      </div>

      <div className={styles.horizontalLine} />
      <p
        className={`${styles.checkBoxContanier} ${isChecked && styles.active}`}
      >
        <Checkbox
          isChecked={isChecked}
          onClick={() => {
            const duplicateData = [...data];
            updateCompanyFeatureOfCompany(item);
            const updatedData = duplicateData.map((loopItem) => {
              if (loopItem.name === item.name) {
                return {
                  ...loopItem,
                  hasAccessManagementPermission:
                    !loopItem.hasAccessManagementPermission,
                };
              }
              return loopItem;
            });
            setData(updatedData);
          }}
        />
      </p>
      <p className={`${styles.checkBoxContanier} ${styles.disable}`}>
        <Checkbox isChecked={checked} onClick={() => {}} />
      </p>
      <p className={`${styles.checkBoxContanier} ${styles.disable}`}>
        <Checkbox isChecked={checked} onClick={() => {}} />
      </p>
      <p className={`${styles.checkBoxContanier} ${styles.disable}`}>
        <Checkbox isChecked={checked} onClick={() => {}} />
      </p>
      <p className={`${styles.checkBoxContanier} ${styles.disable}`}>
        <Checkbox isChecked={checked} onClick={() => {}} />
      </p>
      <p className={`${styles.checkBoxContanier} ${styles.disable}`}>
        <Checkbox isChecked={checked} onClick={() => {}} />
      </p>
      <p className={`${styles.checkBoxContanier} ${styles.disable}`}>
        <Checkbox isChecked={checked} onClick={() => {}} />
      </p>
      <p className={`${styles.checkBoxContanier} ${styles.disable}`}>
        <Checkbox isChecked={checked} onClick={() => {}} />
      </p>
      <p className={`${styles.checkBoxContanier} ${styles.disable}`}>
        <Checkbox isChecked={checked} onClick={() => {}} />
      </p>
    </div>
  );
}

export default CheckboxContainer;

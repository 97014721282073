import o365badge from 'assets/images/o365badge.png';
import Checkbox from 'components/checkbox/checkbox';
import LabelAndInput from 'components/label-and-input/labelAndInput';
import Modal from 'components/modal/modal';
import { searchIcon } from 'constants/index';
import { toaster } from 'helpers';
import React, { useCallback, useState } from 'react';
import { useStore } from 'store';
import { layoutActions, office365Actions } from 'store/actions';
import { iState } from 'types';
import styles from './o365RoomsModal.module.scss';
import Search from './component/search';

type iProps = {
  addRoomsData: any;
};

function O365RoomsModal(props: iProps): JSX.Element {
  const { addRoomsData } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { o365UserRooms = [] } = state?.office365 || {};
  // const { storedAccountRooms = [] } = state?.office365 || {};
  const { isShowO365RoomsModal = false } = state.layout || {};
  const [searchKey, setSearchKey] = useState('');

  const closeModalHandler = useCallback(() => {
    setSearchKey('');
    dispatch(layoutActions.toggleModal());
  }, [dispatch]);

  const filteredData = o365UserRooms.filter((item) => {
    return (
      (item.displayName &&
        item.displayName.toLowerCase().includes(searchKey.toLowerCase())) ||
      (item.emailAddress &&
        item.emailAddress.toLowerCase().includes(searchKey.toLowerCase())) ||
      (item.custom_name &&
        item.custom_name.toLowerCase().includes(searchKey.toLowerCase()))
    );
  });
  const isAllRoomsSelected = o365UserRooms.every((room) => room.status);
  const checkMarkAllRooms = useCallback(() => {
    if (!isAllRoomsSelected) {
      dispatch(
        office365Actions.setO365UserRooms(
          o365UserRooms.map((room) => {
            return {
              ...room,
              status: true,
            };
          })
        )
      );
    }
    if (isAllRoomsSelected) {
      dispatch(
        office365Actions.setO365UserRooms(
          o365UserRooms.map((room) => {
            return {
              ...room,
              status: false,
            };
          })
        )
      );
    }
  }, [o365UserRooms]);

  return (
    <Modal
      isOpen={isShowO365RoomsModal}
      headingIcon={o365badge}
      heading={
        <Search
          className={styles.searchBar}
          placeholder="Search"
          icon={searchIcon}
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
        />
      }
      btnName="Finished"
      onClose={closeModalHandler}
      submit={() => {
        const custom_NameError = o365UserRooms
          ?.filter((item) => item.status === true)
          ?.find((item) => item.customNameError === true);
        if (!custom_NameError) addRoomsData();
        if (custom_NameError) toaster.error('Custom name already exists');
        //  console.log(custom_NameError);
      }}
    >
      <div className={styles.o365ModalContainer}>
        <span className={styles.mainHeading}>Resources in your account</span>

        <div className={styles.tableContainer}>
          <div className={styles.tableHead}>
            <div className={`${styles.headerTabs}`}>
              <Checkbox
                className={styles.checkbox}
                checkmarkStyle
                isChecked={isAllRoomsSelected}
                onClick={checkMarkAllRooms}
              />
              <span className={styles.name}>Name</span>
            </div>
            <span className={`${styles.headerTabs} ${styles.secondColumn}`}>
              Asset Mailbox
            </span>
            <span className={`${styles.headerTabs} ${styles.thirdColumn}`}>
              Set Custom Name
            </span>
          </div>
          {filteredData.length === 0 ? (
            <p className={styles.notFoundTitle}>Data Not Found</p>
          ) : (
            <div className={styles.tableBody}>
              {filteredData &&
                filteredData.map((room) => {
                  // const existingRoom = storedAccountRooms?.find(
                  //   (storedRoom) =>
                  //     storedRoom.emailAddress.toLocaleLowerCase() ===
                  //     room.emailAddress?.toLocaleLowerCase()
                  // );
                  const handleInputChange = (e): void => {
                    //  console.log(e.target.value, 'e.target.value');
                    const customNameError = o365UserRooms
                      ?.filter((item) => item.status === true)
                      ?.filter((item) => item.custom_name === e.target.value);
                    dispatch(
                      office365Actions.setCustomName({
                        id: room.id,
                        name: e.target.value,
                        customNameError: Boolean(customNameError.length),
                      })
                    );
                  };

                  const isChecked = room?.status;
                  return (
                    <div className={styles.bodyContainer} key={room.id}>
                      <div className={styles.bodyColumns}>
                        <Checkbox
                          className={styles.checkbox}
                          checkmarkStyle
                          isChecked={Boolean(isChecked)}
                          onClick={() => {
                            if (room.emailAddress) {
                              dispatch(
                                office365Actions.addOrRemoveRoom(
                                  room.emailAddress
                                )
                              );
                            }
                          }}
                        />
                        <span className={styles.name}>{room.displayName}</span>
                      </div>
                      <span
                        className={`${styles.bodyColumns} ${styles.secondColumn}`}
                      >
                        {room.emailAddress}
                      </span>
                      <span
                        className={`${styles.bodyColumns} ${styles.thirdColumn}`}
                      >
                        <LabelAndInput
                          disabled={!isChecked}
                          value={room?.custom_name || ''}
                          onChange={handleInputChange}
                          // isError
                          label=""
                          errorMessage={
                            room?.custom_name && room.customNameError
                              ? 'Custom Name already exists'
                              : ''
                          }
                        />
                      </span>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

O365RoomsModal.defaultProps = {};

export default O365RoomsModal;

import { updateScreen } from 'apis';
import Button, { ButtonVariants } from 'components/button/button';
import OnlineStatusPill from 'components/online-status-pill/onlineStatusPill';
import Popover from 'components/popover/popover';
import {
  caretFilledSmallDown,
  closeIcon,
  dustbinIcon,
  menuMoreIcon,
  ROUTES,
  screenGreyIcon,
} from 'constants/index';
import { ModalViews } from 'enums';
import { toaster } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { homePageActions, layoutActions } from 'store/actions';
import { iState } from 'types';
import styles from './header.module.scss';

interface iProps {
  className?: string;
}

function Header(props: iProps): JSX.Element {
  const { className } = props;
  const navigate = useNavigate();
  // const [popOverEffect, setPopoverEffect] = useState(false);
  const [dropDownEffect, setDropDownEffect] = useState(false);
  const [state, dispatch]: [iState, any] = useStore();
  const [isLoading] = useState<boolean>(false);
  const {
    screenData,
    invokeUpdateScreen,
    contentToAssignScreen,
    playlistToAssignScreen,
    isSaveAndCloseDisabled,
  } = state.homePage || {};
  const [screenTitle] = useState(screenData?.name || 'Your screens');
  const { deleteScreenAndFolder } = state.user.userPermissions || {};

  const redirect = useCallback(() => {
    navigate(ROUTES.screenz);
  }, [navigate]);

  const updateScreenHandler = useCallback(() => {
    if (!screenData) return;
    const { id, hashCode, name, company } = screenData;
    (async () => {
      const args = {
        id,
        hashCode,
        name,
        companyId: company.id,
      };

      if (screenData.currentContent) {
        args['currentContentId'] = screenData.currentContent.id;
        args['currentPlayListId'] = null;
      }
      if (screenData.currentPlayList)
        args['currentPlayListId'] = screenData.currentPlayList.id;
      args['currentContentId'] = null;

      if (contentToAssignScreen) {
        args['currentContentId'] = contentToAssignScreen;
        args['currentPlayListId'] = null;
        args['isUpdatingContents'] = true;
      }
      if (playlistToAssignScreen) {
        args['currentContentId'] = null;
        args['currentPlayListId'] = playlistToAssignScreen;
        args['isUpdatingContents'] = true;
      }

      const response = await updateScreen(args, () => {});
      if (response) {
        if (!invokeUpdateScreen) navigate(ROUTES.screenz);
        dispatch(homePageActions.invokeUpdateScreen(false));
        dispatch(homePageActions.invokeGetScreenById(true));
        dispatch(layoutActions.toggleModal());
        dispatch(homePageActions.setContentToAssignToScreen(''));
        dispatch(homePageActions.setPlaylistToAssignToScreen(''));
        dispatch(homePageActions.setIsSaveAndCloseDisabled(true));
        toaster.success('Screen updated successfully');
      }
    })();
  }, [
    screenData,
    dispatch,
    navigate,
    contentToAssignScreen,
    playlistToAssignScreen,
    invokeUpdateScreen,
  ]);

  useEffect(() => {
    if (invokeUpdateScreen) updateScreenHandler();
  }, [invokeUpdateScreen, updateScreenHandler]);

  const deleteScreen = (): void => {
    if (deleteScreenAndFolder) {
      dispatch(layoutActions.toggleModal(ModalViews.isShowDeleteScreen));
      return;
    }
    toaster.error('You have no access to delete');
  };
  const {
    // lastPing = '',
    isOnline,
  } = screenData || {};

  return (
    <div className={`${className} ${styles.screenDetailsHeader}`}>
      <div className={styles.colOne}>
        <p className={styles.screenName}>{screenTitle}</p>
        <OnlineStatusPill
          className={styles.onlineStatusPill}
          isOnline={isOnline || false}
          // isOnline={lastPing ? getOnlineStatus(lastPing) : false}
        />
      </div>
      <div className={styles.colTwo}>
        <Popover
          popoverClassName={styles.morePopover}
          popoverComponent={
            <div onClick={deleteScreen} className={`${styles.moreWrapper}`}>
              <div
                className={`${styles.mutateOption} ${
                  !deleteScreenAndFolder && styles.disabled
                }`}
              >
                <img src={dustbinIcon} alt="" />
                <p>Delete Screen</p>
              </div>
            </div>
          }
          setPopoverEffect={setDropDownEffect}
          popOverEffect={dropDownEffect}
        >
          <Button
            className={`${styles.saveAndCloseBtn} ${styles.moreBtn} ${
              styles.headerBtns
            } ${dropDownEffect && styles.activeMore}`}
            variant={ButtonVariants.SmallTransp}
            btnName="More"
            icon={caretFilledSmallDown}
            iconPosition="right"
          />
        </Popover>
        <Button
          className={`${styles.headerBtns}`}
          variant={ButtonVariants.SmallTransp}
          btnName="Cancel"
          onClick={redirect}
        />
        <Button
          className={`${styles.headerBtns}`}
          variant={ButtonVariants.SmallStd}
          btnName="Save and close"
          isLoading={isLoading}
          isDisabled={isSaveAndCloseDisabled}
          onClick={updateScreenHandler}
        />
        <Popover
          popoverClassName={styles.morePopoverBtn}
          popoverComponent={
            <div className={styles.popoverWrapper}>
              <div
                onClick={deleteScreen}
                className={`${styles.deleteOption} ${
                  !deleteScreenAndFolder && styles.disabled
                }`}
              >
                <img src={dustbinIcon} alt="" />
                <p>Delete Screen</p>
              </div>
              <div
                onClick={updateScreenHandler}
                className={styles.deleteOption}
              >
                <img src={screenGreyIcon} alt="" />
                <p>Save</p>
              </div>
            </div>
          }
        >
          <img className={styles.moreIcon} src={menuMoreIcon} alt="" />
        </Popover>
        <img
          className={styles.closeIcon}
          src={closeIcon}
          alt=""
          onClick={redirect}
        />
      </div>
    </div>
  );
}

Header.defaultProps = {
  className: '',
};

export default Header;

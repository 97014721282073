import ContentPreview from 'components/content-preview/contentPreview';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { CONTENT_TYPE, dustbinIcon } from 'constants/index';
import React, { useCallback, useEffect } from 'react';
import { getMinus, getSeconds } from 'helpers';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useStore } from 'store';
import { SideDrawers } from 'enums';
import { playlistPageActions, layoutActions } from 'store/actions';
import { iPlaylistContents, iState } from 'types';
import TimePicker from '../time-picker/time';
import styles from './playlistContent.module.scss';

interface iProps {
  className?: string;
  data: iPlaylistContents[];
  setIsDeleting: (a: boolean) => void;
}

function PlaylistContent(props: iProps): JSX.Element {
  const { className, data, setIsDeleting } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const {
    playlistData,
    canUpdatePlaylist,
    contentToAddToPlaylist,
    isShowNewlyAssignedContentsInList,
  } = state.playlistsPage || {};

  useEffect(() => {
    // console.log(data, 'data');
  }, [data]);

  const reorder = useCallback(
    (list: iPlaylistContents[], startIndex, endIndex): any => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    },
    []
  );

  const onDragEnd = useCallback(
    async (dragAndDropResponse) => {
      const source = dragAndDropResponse?.source?.index;
      const destination = dragAndDropResponse?.destination?.index;

      const items: iPlaylistContents[] = reorder(
        [...data],
        source,
        destination
      );

      items.map((content, index: number): iPlaylistContents => {
        //  console.log(content);
        content.sortOrder = index;
        return content;
      });

      dispatch(
        playlistPageActions.updatePlaylist({
          ...playlistData,
          playListContents: [...items],
        })
      );

      dispatch(playlistPageActions.disablePlaylistSaveAndCloseBtn(false));
    },
    [dispatch, data, reorder, playlistData]
  );

  const onClickContent = useCallback(
    (content) => {
      //  console.log('onClickContent');
      dispatch(
        layoutActions.toggleSideDrawer(SideDrawers.isShowContentPreview)
      );
      dispatch(playlistPageActions.setContentView(content));
    },
    [dispatch]
  );

  // const openDeleteContentModal = useCallback(
  //   (content) => {
  //     setContentToDelete(content);
  //     dispatch(layoutActions.toggleModal(ModalViews.isShowDeleteModal));
  //   },
  //   [dispatch]
  // );

  const deleteContentHandler = useCallback(
    async (content) => {
      if (!playlistData) return;
      const playListContents = playlistData.playListContents || [];

      if (!Array.isArray(playListContents)) return;
      const newPlayListContents = playListContents.filter(
        (i) =>
          i.playlistContentId !== content.playlistContentId ||
          i.id !== content.id
      );

      //  console.log(newPlayListContents, content, 'testing Delete content');
      setIsDeleting(true);
      await dispatch(
        playlistPageActions.setPlaylistData({
          ...playlistData,
          playListContents: newPlayListContents,
        })
      );
      // dispatch(playlistPageActions.invokeUpdatePlaylist(true));
      dispatch(playlistPageActions.disablePlaylistSaveAndCloseBtn(false));
    },
    [playlistData, dispatch, setIsDeleting]
  );

  return (
    <div className={`${styles.playlistContents} ${className}`}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              id="playlistContaier"
            >
              {data &&
                data?.length > 0 &&
                data.map((content, index) => (
                  <>
                    <Draggable
                      // eslint-disable-next-line
                      key={`${index}`}
                      draggableId={`${index}`}
                      index={index}
                      isDragDisabled={canUpdatePlaylist}
                      mode="SNAP"
                    >
                      {(provided2) => (
                        <div
                          key={content.id}
                          className={styles.content}
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                          {...provided2.dragHandleProps}
                          onClick={() => onClickContent(content)}
                        >
                          <div className={`${styles.colOne}`}>
                            <div className={styles.preview}>
                              {content && (
                                <ContentPreview
                                  data={{
                                    contentType: `${content.contentType}`,
                                    dimension: `${content.dimension}`,
                                    duration: `${content.duration}`,
                                    id: `${content.id}`,
                                    name: `${content.name}`,
                                    size: `${content.size}`,
                                    thumbnailPath: `${content.thumbnailPath}`,
                                    url: `${content.url}`,
                                    folder: 'null',
                                    company: 'null',
                                    templateData: content.templateData,
                                  }}
                                />
                              )}
                            </div>
                            <div className={styles.contentNameAndType}>
                              {content.name && (
                                <p className={styles.contentName}>
                                  {content.name.length > 50
                                    ? `${content.name.slice(0, 50)}...`
                                    : content.name}
                                </p>
                              )}
                              <p className={styles.contentType}>
                                {content.contentType}
                              </p>
                            </div>
                          </div>
                          <div className={`${styles.colTwo}`}>
                            {content.contentType === CONTENT_TYPE.Video ? (
                              <p className={styles.videoDuration}>
                                {`${getMinus(content.duration)}:${getSeconds(
                                  content.duration
                                )}`}
                              </p>
                            ) : (
                              <TimePicker
                                duration={content.duration}
                                videoSrc={
                                  content.contentType === CONTENT_TYPE.Video
                                    ? content.url
                                    : ''
                                }
                                // videoType
                                onChange={(event, time) => {
                                  // //  console.log('ON_TIME_CHANGE', time);
                                  //  console.log('event_event_event', event);
                                  const contentss =
                                    playlistData?.playListContents || [];
                                  const indexx = contentss.findIndex(
                                    (i) => i.id === content.id
                                  );
                                  contentss[indexx] = {
                                    ...content,
                                    duration: time,
                                  };
                                  dispatch(
                                    playlistPageActions.updatePlaylist({
                                      ...playlistData,
                                      contentss,
                                    })
                                  );
                                  dispatch(
                                    playlistPageActions.disablePlaylistSaveAndCloseBtn(
                                      false
                                    )
                                  );
                                  event.preventDefault();
                                }}
                              />
                            )}

                            <img
                              className={styles.dustbinIcon}
                              src={dustbinIcon}
                              alt=""
                              onClick={(event) => {
                                event.stopPropagation();
                                deleteContentHandler(content);
                                // openDeleteContentModal(content);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                    <div className={styles.lastChild} />

                    {/* </div> */}
                  </>
                ))}
              {provided.placeholder}
              {isShowNewlyAssignedContentsInList && (
                <Skeleton
                  count={contentToAddToPlaylist.length}
                  className={styles.playlistContentSkeleton}
                />
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {/* {contentToDelete && (
        <DeleteModal
          variant={DeleteModalVariants.Playlist}
          name={contentToDelete?.name || ''}
          deleteHandler={deleteContentHandler}
        />
      )} */}
    </div>
  );
}

PlaylistContent.defaultProps = {
  className: '',
};

export default PlaylistContent;

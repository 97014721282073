// import { ROUTES } from 'constants/index';
/* eslint-disable */
import { getMsToken } from 'apis';
import { redirectURI, ROUTES } from 'constants/index';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import { office365Actions } from 'store/actions';
import { iState } from 'types';
import { useNavigate } from 'react-router-dom';
import styles from './office365Auth.module.scss';

interface iProps {
  className?: string;
}

function Office365Auth(props: iProps): JSX.Element {
  const { className } = props;
  const [urlParams, setUrlParams] = useState<any>();
  const [state, dispatch]: [iState, any] = useStore();
  const { responseAfterLogin } = state.office365 || {};
  const navigate = useNavigate();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setUrlParams(params);
    //  console.log(params);
    fetchTokens(params);
  }, []);

  const fetchTokens = async (params) => {
    const response = await getMsToken(
      {
        code: params.code,
        tenantId: responseAfterLogin.tenantId,
        redirect_url: `${redirectURI}office365-auth`,
        // redirect_url: 'http://localhost:3000/office365-auth',
      },
      () => {}
    );
    if (response) {
      dispatch(office365Actions.setMsAccountAccessResponse(response));
      navigate(ROUTES.userSettingsIntegrations);
      dispatch(office365Actions.setIsMStokensFetched(true));
    }
  };

  return (
    <div className={`${styles.office365Auth} ${className}`}>
      Office 365 Auth
      <br />
      <br />
      <br />
      {/* {urlParams && <div>{JSON.stringify(urlParams)}</div>} */}
      {urlParams && <div>Signing In...</div>}
    </div>
  );
}

Office365Auth.defaultProps = {
  className: '',
};

export default Office365Auth;

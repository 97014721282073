import { iCalenderEvent } from 'types';
import {
  calendarIcon,
  informationIcon,
  clockIcon,
  imageIcon,
  textWordIcon,
  norwayData,
} from 'constants/index';
import { uuid } from 'helpers';
import { ClockContentType, EventContentType } from 'enums';

export const CONTEN_AREA_OPTIONS = [
  {
    id: uuid(),
    option: 'Empty',
    icon: informationIcon,
    value: EventContentType.Empty,
  },
  {
    id: uuid(),
    option: 'Clock',
    icon: clockIcon,
    value: EventContentType.Clock,
  },
];

export const DEFAULT = {
  id: uuid(),
  option: 'Empty',
  optionNorway: norwayData.empty,
  icon: informationIcon,
  value: EventContentType.Empty,
};

export const CLOCK_ALIGNMENT_OPTIONS = [
  {
    id: uuid(),
    option: 'Empty',
    optionNorway: norwayData.empty,
    icon: informationIcon,
    value: '',
  },
  {
    id: uuid(),
    option: 'Right Aligned',
    optionNorway: norwayData.rightAligned,
    icon: '',
    value: ClockContentType.RightAligned,
  },
  {
    id: uuid(),
    option: 'Left Aligned',
    optionNorway: norwayData.leftAligned,
    icon: '',
    value: ClockContentType.LeftAligned,
  },
];

export const CONTEN_TYPE_OPTIONS = [
  {
    id: uuid(),
    option: 'Empty',
    optionNorway: norwayData.empty,
    icon: informationIcon,
    value: EventContentType.Empty,
  },
  {
    id: uuid(),
    option: 'Calendar',
    optionNorway: norwayData.calendar,
    icon: calendarIcon,
    value: EventContentType.Calender,
  },
  {
    id: uuid(),
    option: 'Clock',
    optionNorway: norwayData.clock,
    icon: clockIcon,
    value: EventContentType.Clock,
  },
  {
    id: uuid(),
    option: 'Image',
    optionNorway: norwayData.image,
    icon: imageIcon,
    value: EventContentType.Image,
  },
];

export const LARGE_AREATYPE_OPTIONS = [
  {
    id: uuid(),
    option: 'Empty',
    optionNorway: norwayData.empty,
    icon: informationIcon,
    value: EventContentType.Empty,
  },
  {
    id: uuid(),
    option: 'Image',
    optionNorway: norwayData.image,
    icon: imageIcon,
    value: EventContentType.Image,
  },
  {
    id: uuid(),
    option: 'Text',
    optionNorway: norwayData.text,
    icon: textWordIcon,
    value: EventContentType.Text,
  },
];

export const ROOMS = [
  {
    id: '7d441736-6963-496f-9822-7de69dff7b01',
    name: 'Screenz Dev Room India',
    address: null,
    geoCoordinates: null,
    phone: '',
    nickname: 'Screenz Dev Room India',
    emailAddress: 'Screenz-Dev-Room-India@screenz.no',
    building: null,
    floorNumber: null,
    floorLabel: null,
    label: null,
    capacity: 12,
    bookingType: 'standard',
    audioDeviceName: null,
    videoDeviceName: null,
    displayDeviceName: null,
    isWheelChairAccessible: false,
    tags: [],
  },
  {
    id: 'd5c7de0b-47d8-4f57-a42e-59b36842628b',
    name: 'Testing-Room-1',
    address: null,
    geoCoordinates: null,
    phone: '',
    nickname: 'Testing-Room-1',
    emailAddress: 'Testing-Room-1@screenz.onmicrosoft.com',
    building: null,
    floorNumber: null,
    floorLabel: null,
    label: null,
    capacity: 6,
    bookingType: 'standard',
    audioDeviceName: null,
    videoDeviceName: null,
    displayDeviceName: null,
    isWheelChairAccessible: false,
    tags: [],
  },
  {
    id: '46336a47-7bc7-49b1-a49d-d6e0cbb5cdc1',
    name: 'Testing',
    address: null,
    geoCoordinates: null,
    phone: '',
    nickname: 'Testing',
    emailAddress: 'Testing@screenz.no',
    building: null,
    floorNumber: null,
    floorLabel: null,
    label: null,
    capacity: 10,
    bookingType: 'standard',
    audioDeviceName: null,
    videoDeviceName: null,
    displayDeviceName: null,
    isWheelChairAccessible: false,
    tags: [],
  },
  {
    id: '552875c0-d37f-4dc9-ad20-4933a15e8467',
    name: 'EXC_Roome_H',
    address: null,
    geoCoordinates: null,
    phone: '+91 829971081835',
    nickname: 'EXC_Roome_H',
    emailAddress: 'EXC_Roome_H@screenz.no',
    building: null,
    floorNumber: null,
    floorLabel: null,
    label: null,
    capacity: 15,
    bookingType: 'standard',
    audioDeviceName: null,
    videoDeviceName: null,
    displayDeviceName: null,
    isWheelChairAccessible: false,
    tags: [],
  },
  {
    id: '792371ed-a86b-4500-b76a-4059dc4cb7d2',
    name: 'Synergize MeetingRoom Oslo',
    address: null,
    geoCoordinates: null,
    phone: '',
    nickname: 'Synergize MeetingRoom Oslo',
    emailAddress: 'Synergize-MeetingRoom-Oslo@screenz.no',
    building: null,
    floorNumber: null,
    floorLabel: null,
    label: null,
    capacity: null,
    bookingType: 'standard',
    audioDeviceName: null,
    videoDeviceName: null,
    displayDeviceName: null,
    isWheelChairAccessible: false,
    tags: [],
  },
  {
    id: '5c752546-f680-4159-a8fd-19bdd67a0476',
    name: 'EXC_Room_G',
    address: null,
    geoCoordinates: null,
    phone: '',
    nickname: 'EXC_Room_G',
    emailAddress: 'EXC_Room_G@screenz.onmicrosoft.com',
    building: null,
    floorNumber: null,
    floorLabel: null,
    label: null,
    capacity: 20,
    bookingType: 'standard',
    audioDeviceName: null,
    videoDeviceName: null,
    displayDeviceName: null,
    isWheelChairAccessible: false,
    tags: [],
  },
  {
    id: 'a4b5a972-215e-488a-87a3-5adf2645cc52',
    name: 'EXC_Room_D',
    address: null,
    geoCoordinates: null,
    phone: '',
    nickname: 'EXC_Room_D',
    emailAddress: 'EXC_Room_D@screenz.onmicrosoft.com',
    building: null,
    floorNumber: null,
    floorLabel: null,
    label: null,
    capacity: 20,
    bookingType: 'standard',
    audioDeviceName: null,
    videoDeviceName: null,
    displayDeviceName: null,
    isWheelChairAccessible: false,
    tags: [],
  },
  {
    id: '0be2ce54-a245-4b27-b5da-ba6ff05bd2b3',
    name: 'EXC_Room_C',
    address: null,
    geoCoordinates: null,
    phone: '',
    nickname: 'EXC_Room_C',
    emailAddress: 'EXC_Room_C@screenz.onmicrosoft.com',
    building: null,
    floorNumber: null,
    floorLabel: null,
    label: null,
    capacity: 25,
    bookingType: 'standard',
    audioDeviceName: null,
    videoDeviceName: null,
    displayDeviceName: null,
    isWheelChairAccessible: false,
    tags: [],
  },
  {
    id: 'bf739a45-3e06-4ce5-b4b7-2c20a471a637',
    name: 'EXC_Room_B',
    address: null,
    geoCoordinates: null,
    phone: '',
    nickname: 'EXC_Room_B',
    emailAddress: 'EXC_Room_B@screenz.onmicrosoft.com',
    building: null,
    floorNumber: null,
    floorLabel: null,
    label: null,
    capacity: 8,
    bookingType: 'standard',
    audioDeviceName: null,
    videoDeviceName: null,
    displayDeviceName: null,
    isWheelChairAccessible: false,
    tags: [],
  },
  {
    id: '28fa0432-08ea-435e-a6bc-dafb51aaab1f',
    name: 'EXC_Room_A',
    address: null,
    geoCoordinates: null,
    phone: '',
    nickname: 'EXC_Room_A',
    emailAddress: 'EXC_Room_A@screenz.onmicrosoft.com',
    building: null,
    floorNumber: null,
    floorLabel: null,
    label: null,
    capacity: 10,
    bookingType: 'standard',
    audioDeviceName: null,
    videoDeviceName: null,
    displayDeviceName: null,
    isWheelChairAccessible: false,
    tags: [],
  },
];

export const EVENTS: iCalenderEvent[] = [
  {
    id: 'AAMkADE0ZDdmNmZmLWMwNzgtNDViZS1hYjBiLTdhMzBmM2NkMzdjZQBGAAAAAAA2inZ8gttVSY4BUuHgxGivBwC9R3ZUjDdtT7GXmExWPGHDAAAAAAENAAC9R3ZUjDdtT7GXmExWPGHDAAAB4hh2AAA=',
    subject: 'Mohan Raj ',
    originalStartTimeZone: 'India Standard Time',
    start: {
      dateTime: '2022-09-26T17:30:00.0000000',
      timeZone: 'UTC',
    },
    end: {
      dateTime: '2022-09-26T17:45:00.0000000',
      timeZone: 'UTC',
    },
    location: {
      displayName: 'Testing-Room-1; Testing',
      locationType: 'default',
      uniqueId: 'Testing-Room-1; Testing',
      uniqueIdType: 'private',
    },
    organizer: {
      emailAddress: {
        name: 'Mohan raj',
        address: 'mohan@screenz.no',
      },
    },
  },
  {
    id: 'AAMkADE0ZDdmNmZmLWMwNzgtNDViZS1hYjBiLTdhMzBmM2NkMzdjZQBGAAAAAAA2inZ8gttVSY4BUuHgxGivBwC9R3ZUjDdtT7GXmExWPGHDAAAAAAENAAC9R3ZUjDdtT7GXmExWPGHDAAAB4hh3AAA=',
    subject: 'Mohan Raj ',
    originalStartTimeZone: 'India Standard Time',
    start: {
      dateTime: '2022-09-26T19:30:00.0000000',
      timeZone: 'UTC',
    },
    end: {
      dateTime: '2022-09-26T20:00:00.0000000',
      timeZone: 'UTC',
    },
    location: {
      displayName: 'Testing; Testing-Room-1',
      locationType: 'default',
      uniqueId: 'Testing; Testing-Room-1',
      uniqueIdType: 'private',
    },
    organizer: {
      emailAddress: {
        name: 'Mohan raj',
        address: 'mohan@screenz.no',
      },
    },
  },
  {
    id: 'AAMkADE0ZDdmNmZmLWMwNzgtNDViZS1hYjBiLTdhMzBmM2NkMzdjZQBGAAAAAAA2inZ8gttVSY4BUuHgxGivBwC9R3ZUjDdtT7GXmExWPGHDAAAAAAENAAC9R3ZUjDdtT7GXmExWPGHDAAAB4hh4AAA=',
    subject: 'Mohan Raj ',
    originalStartTimeZone: 'India Standard Time',
    start: {
      dateTime: '2022-09-26T20:00:00.0000000',
      timeZone: 'UTC',
    },
    end: {
      dateTime: '2022-09-26T20:30:00.0000000',
      timeZone: 'UTC',
    },
    location: {
      displayName: 'Testing; Testing-Room-1',
      locationType: 'default',
      uniqueId: 'Testing; Testing-Room-1',
      uniqueIdType: 'private',
    },
    organizer: {
      emailAddress: {
        name: 'Mohan raj',
        address: 'mohan@screenz.no',
      },
    },
  },
  {
    id: 'AAMkADE0ZDdmNmZmLWMwNzgtNDViZS1hYjBiLTdhMzBmM2NkMzdjZQBGAAAAAAA2inZ8gttVSY4BUuHgxGivBwC9R3ZUjDdtT7GXmExWPGHDAAAAAAENAAC9R3ZUjDdtT7GXmExWPGHDAAAB4hh6AAA=',
    subject: 'Mohan Raj ',
    originalStartTimeZone: 'India Standard Time',
    start: {
      dateTime: '2022-09-27t04:00:00.0000000',
      timeZone: 'utc',
    },
    end: {
      dateTime: '2022-09-27t04:30:00.0000000',
      timeZone: 'utc',
    },
    location: {
      displayName: 'Testing; Testing-Room-1',
      locationType: 'default',
      uniqueId: 'Testing; Testing-Room-1',
      uniqueIdType: 'private',
    },
    organizer: {
      emailAddress: {
        name: 'Mohan raj',
        address: 'mohan@screenz.no',
      },
    },
  },
  {
    id: 'AAMkADE0ZDdmNmZmLWMwNzgtNDViZS1hYjBiLTdhMzBmM2NkMzdjZQBGAAAAAAA2inZ8gttVSY4BUuHgxGivBwC9R3ZUjDdtT7GXmExWPGHDAAAAAAENAAC9R3ZUjDdtT7GXmExWPGHDAAAB4hh7AAA=',
    subject: 'Mohan Raj ',
    originalStartTimeZone: 'India Standard Time',
    start: {
      dateTime: '2022-09-27T05:00:00.0000000',
      timeZone: 'UTC',
    },
    end: {
      dateTime: '2022-09-27T05:30:00.0000000',
      timeZone: 'UTC',
    },
    location: {
      displayName: '',
      locationType: 'default',
      uniqueIdType: 'unknown',
      uniqueId: 'Testing; Testing-Room-1',
    },
    organizer: {
      emailAddress: {
        name: 'Mohan raj',
        address: 'mohan@screenz.no',
      },
    },
  },
  {
    id: 'AAMkADE0ZDdmNmZmLWMwNzgtNDViZS1hYjBiLTdhMzBmM2NkMzdjZQBGAAAAAAA2inZ8gttVSY4BUuHgxGivBwC9R3ZUjDdtT7GXmExWPGHDAAAAAAENAAC9R3ZUjDdtT7GXmExWPGHDAAAB4hh_AAA=',
    subject: 'Mohan Raj ',
    originalStartTimeZone: 'India Standard Time',
    start: {
      dateTime: '2022-09-27T10:30:00.0000000',
      timeZone: 'UTC',
    },
    end: {
      dateTime: '2022-09-27T11:00:00.0000000',
      timeZone: 'UTC',
    },
    location: {
      displayName: '',
      locationType: 'default',
      uniqueIdType: 'unknown',
      uniqueId: 'Testing; Testing-Room-1',
    },
    organizer: {
      emailAddress: {
        name: 'Mohan raj',
        address: 'mohan@screenz.no',
      },
    },
  },
  {
    id: 'AAMkADE0ZDdmNmZmLWMwNzgtNDViZS1hYjBiLTdhMzBmM2NkMzdjZQBGAAAAAAA2inZ8gttVSY4BUuHgxGivBwC9R3ZUjDdtT7GXmExWPGHDAAAAAAENAAC9R3ZUjDdtT7GXmExWPGHDAAAB4hh9AAA=',
    subject: 'Mohan Raj ',
    originalStartTimeZone: 'India Standard Time',
    start: {
      dateTime: '2022-09-28T03:30:00.0000000',
      timeZone: 'UTC',
    },
    end: {
      dateTime: '2022-09-28T04:00:00.0000000',
      timeZone: 'UTC',
    },
    location: {
      displayName: '',
      locationType: 'default',
      uniqueIdType: 'unknown',
      uniqueId: 'Testing; Testing-Room-1',
    },
    organizer: {
      emailAddress: {
        name: 'Mohan raj',
        address: 'mohan@screenz.no',
      },
    },
  },
  {
    id: 'AAMkADE0ZDdmNmZmLWMwNzgtNDViZS1hYjBiLTdhMzBmM2NkMzdjZQBGAAAAAAA2inZ8gttVSY4BUuHgxGivBwC9R3ZUjDdtT7GXmExWPGHDAAAAAAENAAC9R3ZUjDdtT7GXmExWPGHDAAAABSK3AAA=',
    subject: 'Mohan Raj ',
    originalStartTimeZone: 'India Standard Time',
    start: {
      dateTime: '2022-09-28T04:30:00.0000000',
      timeZone: 'UTC',
    },
    end: {
      dateTime: '2022-09-28T05:00:00.0000000',
      timeZone: 'UTC',
    },
    location: {
      displayName: '',
      locationType: 'default',
      uniqueIdType: 'unknown',
      uniqueId: 'Testing; Testing-Room-1',
    },
    organizer: {
      emailAddress: {
        name: 'Mohan raj',
        address: 'mohan@screenz.no',
      },
    },
  },
  {
    id: 'AAMkADE0ZDdmNmZmLWMwNzgtNDViZS1hYjBiLTdhMzBmM2NkMzdjZQBGAAAAAAA2inZ8gttVSY4BUuHgxGivBwC9R3ZUjDdtT7GXmExWPGHDAAAAAAENAAC9R3ZUjDdtT7GXmExWPGHDAAADmZs5AAA=',
    subject: 'sachin ',
    originalStartTimeZone: 'India Standard Time',
    start: {
      dateTime: '2022-09-28t19:30:00.0000000',
      timeZone: 'utc',
    },
    end: {
      dateTime: '2022-09-28t20:00:00.0000000',
      timeZone: 'utc',
    },
    location: {
      displayName: '',
      locationType: 'default',
      uniqueId: 'Testing; Testing-Room-1',
      uniqueIdType: 'private',
    },
    organizer: {
      emailAddress: {
        name: 'sachin',
        address: 'sachin@screenz.no',
      },
    },
  },
  {
    id: 'AAMkADE0ZDdmNmZmLWMwNzgtNDViZS1hYjBiLTdhMzBmM2NkMzdjZQBGAAAAAAA2inZ8gttVSY4BUuHgxGivBwC9R3ZUjDdtT7GXmExWPGHDAAAAAAENAAC9R3ZUjDdtT7GXmExWPGHDAAADmZs6AAA=',
    subject: 'sachin ',
    originalStartTimeZone: 'India Standard Time',
    start: {
      dateTime: '2022-09-28t20:30:00.0000000',
      timeZone: 'utc',
    },
    end: {
      dateTime: '2022-09-28t21:00:00.0000000',
      timeZone: 'utc',
    },
    location: {
      displayName: 'testing',
      locationType: 'testing@screenz.no',
      uniqueIdType: 'unknown',
      uniqueId: 'Testing; Testing-Room-1',
    },
    organizer: {
      emailAddress: {
        name: 'sachin',
        address: 'sachin@screenz.no',
      },
    },
  },
];

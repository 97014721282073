import React from 'react';
import { iState } from 'types';
import { useStore } from 'store';
// import { meetingOverviewFullActions } from 'store/actions';
import { CalenderSettingsView, CARD_NAME, EventContentType } from 'enums';
import { meetingOverviewFullActions } from 'store/actions';
import LongComponent from './components/longComponent';
import styles from './eventView.module.scss';

interface iProps {
  className?: string;
}

function EventView(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { transparency } = state.meetingOverviewFull || {};

  const {
    eventBgImg,
    currentView,
    currentCard,
    largeContentUrl = '',
    largeAreaContent,
    preTitle = '',
    title = '',
    body = '',
    subText = '',
  } = state.meetingOverviewFull || {};

  const { LargeArea, TemplateSetting } = CalenderSettingsView;

  const isTemplatesSettings = currentView === TemplateSetting;
  const isLargeAreaContent = currentView === LargeArea;
  const isDisplayLargeAreaImage =
    largeAreaContent.value === EventContentType.Image;
  const isDisplayLargeAreaText =
    largeAreaContent.value === EventContentType.Text;

  const style = {
    backgroundColor: `rgba(21, 22, 33, ${
      parseFloat(transparency) <= 1
        ? parseFloat(transparency)
        : parseFloat(transparency) / 10
    })`,
  };
  return (
    <div className={`${styles.eventContainer}  ${className}`}>
      <div className={styles.main}>
        {eventBgImg && (
          <img className={styles.eventBgImg} src={eventBgImg} alt="" />
        )}
        <div className={`${styles.eventDetails}`} style={style}>
          <div className={styles.sectionOne}>
            <LongComponent cardNumber={CARD_NAME.One} />
            <div
              className={`${className} ${styles.currentTime} ${
                isLargeAreaContent &&
                !currentCard &&
                !isTemplatesSettings &&
                styles.showActive
              } ${largeContentUrl && styles.hideBorder}`}
              onClick={() => {
                dispatch(meetingOverviewFullActions.setCurrentCard(null));
                dispatch(meetingOverviewFullActions.setCureentView(LargeArea));
              }}
            >
              {isDisplayLargeAreaImage && largeContentUrl && (
                <img
                  className={`${styles.eventBgImg} ${
                    largeContentUrl && isLargeAreaContent && styles.showBorder
                  }`}
                  src={largeContentUrl}
                  alt=""
                />
              )}
              {isDisplayLargeAreaText && (
                <>
                  <p className={`${styles.preTitleStyles}`}>{preTitle}</p>
                  <p className={styles.titleStyles}>{title}</p>
                  <p className={styles.bodyStyles}>{body}</p>
                  <p className={styles.subTextStyles}>{subText}</p>
                </>
              )}
            </div>
          </div>
          <div className={styles.sectionTwo}>
            <LongComponent cardNumber={CARD_NAME.Two} />
            <LongComponent cardNumber={CARD_NAME.Four} />
            <LongComponent cardNumber={CARD_NAME.Six} />
            <LongComponent cardNumber={CARD_NAME.Eight} />
            <LongComponent cardNumber={CARD_NAME.Ten} />
            <LongComponent cardNumber={CARD_NAME.Twelve} />
          </div>
        </div>
      </div>
    </div>
  );
}

EventView.defaultProps = {
  className: '',
};

export default EventView;

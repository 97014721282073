import Popover from 'components/popover/popover';
import { caretFilledSmallDown, ROUTES } from 'constants/index';
import { LayoutViews } from 'enums';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import {
  homePageActions,
  layoutActions,
  contentPageActions,
  playlistPageActions,
} from 'store/actions';
import { iCompanyData, iState } from 'types';
import styles from './clientsListDropdown.module.scss';

interface iProps {
  className?: string;
}

function ClientsListDropdown(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const [isShowing, setIsShowing] = useState<boolean>(false);
  const { companies = [], companyData } = state.homePage || {};
  const { isShowingClientInstance } = state.layout || {};
  const navigate = useNavigate();
  // //  console.log({ state });

  const activeClient =
    companyData || (companies?.length > 0 && companies[0]) || null;

  const onClickClient = useCallback(
    (company: iCompanyData) => {
      dispatch(layoutActions.setShowClientsInstance(true));
      dispatch(homePageActions.setCompanyData(company));
      dispatch(layoutActions.setHomePageView(LayoutViews.Screens));
      navigate(ROUTES.screenz);

      dispatch(layoutActions.initialSidebarState(true));
      dispatch(homePageActions.setScreensActiveFolder(null));
      dispatch(homePageActions.setScreensFolders([]));
      dispatch(homePageActions.setScreensFolderHierarchy([]));

      dispatch(contentPageActions.setContentActiveFolder(null));
      dispatch(contentPageActions.setContentFolders([]));
      dispatch(contentPageActions.setContentFolderHierarchy([]));

      dispatch(playlistPageActions.setPlaylistsActiveFolder(null));
      dispatch(playlistPageActions.setPlaylistsFolders([]));
      dispatch(playlistPageActions.setPlaylistsFolderHierarchy([]));

      dispatch(layoutActions.setAssignModalContentActiveFolders(null));
      dispatch(layoutActions.setAssignModalContentFolders([]));
      dispatch(layoutActions.setAssignModalContentsFolderHierarchy([]));
    },
    [dispatch]
  );

  return (
    <Popover
      setIsShowing={setIsShowing}
      popoverClassName={styles.dropdown}
      popoverComponent={
        <ul className={styles.listContainer}>
          {companies.map((i: iCompanyData) => (
            <li
              className={`${styles.listItem} ${
                activeClient && i.id === activeClient.id && styles.active
              }`}
              onClick={() => onClickClient(i)}
            >
              {i.name}
            </li>
          ))}
        </ul>
      }
    >
      <div
        className={`${className} ${styles.clientsDropdown} ${
          isShowing && styles.shown
        }`}
        onClick={() => setIsShowing(!isShowing)}
      >
        {!isShowingClientInstance && (
          <span className={styles.clientName}>All Clients</span>
        )}
        {isShowingClientInstance && (
          <span className={styles.clientName}>{companyData?.name}</span>
        )}
        <img
          className={`${styles.expandArrowDown}`}
          src={caretFilledSmallDown}
          alt=""
        />
      </div>
    </Popover>
  );
}

ClientsListDropdown.defaultProps = {
  className: '',
};

export default ClientsListDropdown;

import { Mutation, Query } from 'config';
import { toaster } from 'helpers';
import { DeleteStatus } from 'enums';
import {
  iGraphqlResponse,
  iAddPlaylistArgs,
  iPlaylistData,
  iAddPlaylistResponse,
  iGetPlayListByCompanyResponse,
  iGetPlaylistsArgs,
  iGetPlaylistByIdArgs,
  iGetPlaylistByIdResponse,
  iUpdatePlaylistArgs,
  iUpdatePlaylistResponse,
  iSearchPlaylistArgs,
  iSearchPlaylistResponse,
  iDeletePlaylistArgs,
  iDeletePlaylistResponse,
} from 'types';
import { captureException } from 'helpers/sentry';
import {
  addPlaylistMutation,
  getPlayListByCompanyQuery,
  getPlaylistByIdQuery,
  updatePlaylistMutation,
  searchPlaylistMutation,
  deletePlaylistMutation,
} from '../graphql';

export const addPlaylist = async (
  args: iAddPlaylistArgs,
  isLoading
): Promise<iPlaylistData | null> => {
  try {
    const playlistDuration = !args.duration ? '00:00:00' : args.duration;
    isLoading(true);
    const response = <iGraphqlResponse<iAddPlaylistResponse>>(
      await Mutation(addPlaylistMutation, {
        ...args,
        duration: playlistDuration,
      })
    );
    isLoading(false);
    return response.data.addPlaylist.data;
  } catch (error: any) {
    isLoading(false);
    toaster.error(error.message);
    captureException(error);
    return null;
  }
};

export const getAllPlaylists = async (
  args: iGetPlaylistsArgs,
  isLoading
): Promise<Array<iPlaylistData> | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iGetPlayListByCompanyResponse>>(
      await Query(getPlayListByCompanyQuery, args)
    );
    isLoading(false);
    return response.data.getPlayListByCompany.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const getPlaylistsById = async (
  args: iGetPlaylistByIdArgs,
  isLoading?: (a: boolean) => void
): Promise<iPlaylistData | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = <iGraphqlResponse<iGetPlaylistByIdResponse>>(
      await Query(getPlaylistByIdQuery, args)
    );
    if (isLoading) isLoading(false);
    return response.data.getplaylistbyid.data;
  } catch (error: any) {
    if (isLoading) isLoading(false);
    captureException(error);
    return null;
  }
};

export const updatePlaylist = async (
  args: iUpdatePlaylistArgs,
  isLoading
): Promise<iPlaylistData | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iUpdatePlaylistResponse>>(
      await Mutation(updatePlaylistMutation, args)
    );
    isLoading(false);
    return response.data.updatePlaylist.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const searchPlaylist = async (
  args: iSearchPlaylistArgs,
  isLoading
): Promise<Array<iPlaylistData> | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iSearchPlaylistResponse>>(
      await Mutation(searchPlaylistMutation, args)
    );
    isLoading(false);
    return response.data.searchPlaylistbyname.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const deletePlaylist = async (
  args: iDeletePlaylistArgs,
  isLoading?: (_: boolean) => void
): Promise<string | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = <iGraphqlResponse<iDeletePlaylistResponse>>(
      await Mutation(deletePlaylistMutation, {
        ...args,
      })
    );
    if (isLoading) isLoading(false);
    if (response) {
      const { screenz = [] } = response.data.deletePlaylist.data || {};
      if (response.data.deletePlaylist.message === 'Result')
        return DeleteStatus.Deleted;
      if (screenz.length === 0 && args.is_data) {
        const deleteResponse = await deletePlaylist({ ...args, is_data: true });
        if (deleteResponse) return DeleteStatus.Deleted;
      }
      if (screenz.length > 0) return DeleteStatus.UsedSomewhere;
    }
    return null;
  } catch (error: any) {
    if (isLoading) isLoading(false);
    captureException(error);
    return null;
  }
};

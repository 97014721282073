import { gql } from 'apollo-boost';

export enum RolesAndPermissions {
  GetPermissions = 'getPermissions',
  AddOrUpdatePermission = 'addOrUpdatePermission',
  AddUserRole = 'adduserrole',
}

export const getPermissionsQuery = gql`
  query ${RolesAndPermissions.GetPermissions} (
    $rollOrCompanyUserId: String!
    $permissionFor: PermissionFor!
  ){
    ${RolesAndPermissions.GetPermissions} (
      rollOrCompanyUserId: $rollOrCompanyUserId
      permissionFor: $permissionFor
    ){
      statusCode
      message
      data {
        id
        permissionFor
        rollOrCompanyUserId
        manageReseller
        clients
        addClients
        deleteClients
        addTeammates
        removeTeammates
        editCompanyDetails
        screenAccess
        addScreensAndFolders
        deleteScreenAndFolder
        contentAcces
        addContentAndFolders
        deleteContentAndFolder
        playListAccess
        addPlayListAndFolders
        deletePlayListAndFolder
        screenFolders{
          folderId
          accessType
        }
        contentFolders{
          folderId
          accessType
        }
        playListFolders{
          folderId
          accessType
        }
      }
    }
  }
`;

export const addOrUpdatePermissionMutation = gql`
  mutation ${RolesAndPermissions.AddOrUpdatePermission} (
    $mutationType: MutationType!
    $id: String
    $permissionFor: PermissionFor!
    $permissionType: PermissionType!
    $rollOrCompanyUserId: String!
    $manageReseller: Boolean
    $clients: ClientPermissions
    $addClients: Boolean
    $deleteClients: Boolean
    $addTeammates: Boolean
    $removeTeammates: Boolean
    $editCompanyDetails: Boolean
    $screenAccess: ScreenPermissions
    $addScreensAndFolders: Boolean
    $deleteScreenAndFolder: Boolean
    $contentAcces: ContentPermissions
    $addContentAndFolders: Boolean
    $deleteContentAndFolder: Boolean
    $playListAccess: PlayListPermissions
    $addPlayListAndFolders: Boolean
    $deletePlayListAndFolder: Boolean
    $screenFolders: [PermittedFolders]
    $contentFolders: [PermittedFolders]
    $playListFolders: [PermittedFolders]
  ){
    ${RolesAndPermissions.AddOrUpdatePermission} (
      mutationType: $mutationType
      id: $id
      permissionFor: $permissionFor
      permissionType: $permissionType
      rollOrCompanyUserId: $rollOrCompanyUserId
      manageReseller: $manageReseller
      clients: $clients
      addClients: $addClients
      deleteClients: $deleteClients
      addTeammates: $addTeammates
      removeTeammates: $removeTeammates
      editCompanyDetails: $editCompanyDetails
      screenAccess: $screenAccess
      addScreensAndFolders: $addScreensAndFolders
      deleteScreenAndFolder: $deleteScreenAndFolder
      contentAcces: $contentAcces
      addContentAndFolders: $addContentAndFolders
      deleteContentAndFolder: $deleteContentAndFolder
      playListAccess: $playListAccess
      addPlayListAndFolders: $addPlayListAndFolders
      deletePlayListAndFolder: $deletePlayListAndFolder
      screenFolders: $screenFolders
      contentFolders: $contentFolders
      playListFolders: $playListFolders
    ){
      statusCode
      message
      data {
        id
        permissionFor
        rollOrCompanyUserId
        manageReseller
        clients
        addClients
        deleteClients
        addTeammates
        removeTeammates
        editCompanyDetails
        screenAccess
        addScreensAndFolders
        deleteScreenAndFolder
        contentAcces
        addContentAndFolders
        deleteContentAndFolder
        playListAccess
        addPlayListAndFolders
        deletePlayListAndFolder
        screenFolders{
          folderId
          accessType
        }
        contentFolders{
          folderId
          accessType
        }
        playListFolders{
          folderId
          accessType
        }
      }
    }
  }
`;

export const addRoleMutation = gql`
  mutation ${RolesAndPermissions.AddUserRole} (
    $role_name: String
  ){
    ${RolesAndPermissions.AddUserRole} (
      role_name: $role_name
    ){
      statusCode
      message
      data {
        id
        role_name
        created_at
        updated_at
      }
    }
  }
`;

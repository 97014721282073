import React from 'react';
// import { useStore } from 'store';
// import { contentPageActions } from 'store/actions';
import { iEmptyStates, iLanguages } from 'types';
import TableHeader from '../table-header/tableHeader';
import styles from './table.module.scss';

interface iProps {
  rows: iEmptyStates[];
  onClick: (row: any) => void;
}

function Index(props: iProps): JSX.Element {
  // const [, dispatch]: [iState, any] = useStore();
  const { rows, onClick } = props;

  return (
    <div className={styles.table}>
      <TableHeader />
      <div className={styles.rows}>
        {rows.map((i: iEmptyStates) => {
          const date = new Date(i.lastUpdated);

          const lastUpdated =
            (date instanceof Date &&
              `${date.getDate()}.${
                date.getMonth() + 1
              }.${date.getFullYear()} ${date.getHours()}.${date.getMinutes()}`) ||
            '';

          return (
            <div
              // onClick={() => dispatch(contentPageActions.addEmptyState(i.value))}
              onClick={() => onClick(i)}
              className={styles.row}
            >
              <div className={`${styles.cell} ${styles.firstCell}`}>
                {i.value}
              </div>
              <div className={`${styles.cell} ${styles.languages}`}>
                {i.languages.map((j: iLanguages) => (
                  <p
                    className={`${styles.language} 
                    ${j.language === 'NO' && styles.no}
                    ${j.language === 'EN' && styles.en}
                  `}
                  >
                    {j.language}
                  </p>
                ))}
              </div>
              <div className={styles.cell}>{lastUpdated}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

Index.defaultProps = {};

export default Index;

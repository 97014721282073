import { gql } from 'apollo-boost';

export enum Office365 {
  GetRooms = 'getRooms',
  AddOrUpdateTemplete = 'addOrUpdateTemplete',
  GetRoomsbyofficeuserid = 'getRoomsbyofficeuserid',
  AddRooms = 'addRooms',
  GetMsToken = 'getMsToken',
  GetOfficeRooms = 'getOfficeRooms',
}

export const getRooomsQuery = gql`
  query ${Office365.GetRooms}(
    $company: String
  ){
    ${Office365.GetRooms}(
      company: $company
    ){
      statusCode
      message
      data {
        id
        msAccount {
          id
          name
          email
          accessToken
          refreshToken
          inUse
          msId
          created_at
          updated_at
        }
        name
        custom_name
        address
        geoCoordinates
        phone
        nickname
        emailAddress
        building
        floorNumber
        floorLabel
        label
        capacity
        bookingType
        audioDeviceName
        videoDeviceName
        displayDeviceName
        tags
        status
      }
    }
  }
`;

export const addOrUpdateTempleteMutation = gql`
  mutation ${Office365.AddOrUpdateTemplete} (
    $id: String
    $name: String
    $mutationType: MutationType!
    $templateType: TemplateType!
    $folderId: String
    $companyId: String
    $userId: String
    $updateRoomName: Boolean
    $language: Language!
    $details: TemplateInputDetails!
    $contents: [TemplateInputContents]!
    $opacity: Float
  ){
    ${Office365.AddOrUpdateTemplete} (
      id: $id
      name: $name
      mutationType: $mutationType
      templateType: $templateType
      folderId: $folderId
      language: $language
      companyId: $companyId
      userId: $userId
      updateRoomName: $updateRoomName
      details: $details
      contents: $contents
      opacity: $opacity
      ){
      message
      statusCode
      data {
        id
        name
        templateType
        opacity
        details{
          backgroundImageUrl
          logoUrl
          ActivateBooking
        }
        contents{
          id
          contentType
          position
          details{
            roomDetails
            showSubject
            showHostName
            showNextMeeting
            contentAlignment
            timezone
            imageUrl
            
          }
        }
      }
    }
  }
`;

export const getRoomyByOfficeIdQuery = gql`
  query ${Office365.GetRoomsbyofficeuserid}(
    $msAccountId: String! 
  ){
    ${Office365.GetRoomsbyofficeuserid}(
      msAccountId: $msAccountId
    ){
      statusCode
      message
      data{
          id
          name
          custom_name
          address
          geoCoordinates
          phone
          nickname
          emailAddress
          building
          floorNumber
          floorLabel
          label
          capacity
          bookingType
          audioDeviceName
          isWheelChairAccessible
          videoDeviceName
          displayDeviceName
          tags
          status
      }
    }
  }
`;

export const addRoomsMutation = gql`
  mutation ${Office365.AddRooms}(
    $msAccountId: String!
    $companyId: String!
    $roomdata: [Roomsadd]
  ){
    ${Office365.AddRooms}(
      roomdata: $roomdata
      companyId: $companyId
      msAccountId: $msAccountId
    ){
      statusCode
      message
      data{
        id
      }
    }
  }
`;

export const getMsTokenMutation = gql`
  mutation ${Office365.GetMsToken}(
    $code: String!
    $tenantId: String!
    $redirect_url: String!
  ){
    ${Office365.GetMsToken}(
      code: $code
      tenantId: $tenantId
      redirect_url: $redirect_url
    ){
      statusCode
      message
      data {
        access_token
        token_type
        refresh_token
        expires_in
        ext_expires_in
        name
      }
    }
  }
`;

export const getOfficeRoomsMutation = gql`
  query ${Office365.GetOfficeRooms}(
    $msId: String!
  ){
    ${Office365.GetOfficeRooms}(
      msId: $msId
    ){
      statusCode
      message
      data{
      value{
        id
        emailAddress
        displayName
        address
        geoCoordinates
        phone
        nickname
        building
        floorNumber
        floorLabel
        label
        capacity
        bookingType
        audioDeviceName
        videoDeviceName
        displayDeviceName
        isWheelChairAccessible
      }
      
    }
    }
  }
`;

import EmptyState, {
  EmptyStateVariants,
} from 'components/empty-state/emptyState';
import RoleBasedHeader from 'components/Layouts/components/role-based-header/roleBasedHeader';
import Layout, { LayoutVariants } from 'components/Layouts/layout';
import { toaster, uuid } from 'helpers';
import { updateSystemConfig, getAllSystemConfigs } from 'apis';
import { useStore } from 'store';
import {
  commonActions,
  homePageActions,
  layoutActions,
  userActions,
} from 'store/actions';
import { iEmptyState, iState } from 'types';
import HeaderTitle from 'modules/user/components/general/header-title/headerTitle';
import React, { useCallback, useEffect, useState } from 'react';
import { EmptyScreens, UserRoles } from 'enums';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/index';
import Subheader from './components/subheader/subheader';
import Table from './components/table/table';
import styles from './emptyStates.module.scss';

function EmptyStates(): JSX.Element {
  const [state, dispatch]: [iState, any] = useStore();
  const [currentView, setCurrentView] = useState<EmptyScreens | ''>('');
  const [canSaveChanges, setCanSaveChanges] = useState<boolean>(false);
  const [emptyStateToUpdate, setEmptyStateToUpdate] = useState<iEmptyState>();
  const { role, clientChoosed } = state.user || {};
  const { emptyStates } = state.layout || {};
  const { Screens, Content, Playlists, Clients } = EmptyScreens;
  const isSuperAdmin = role === UserRoles.SuperAdmin;
  //  console.log('emptyStates________', { emptyStates, Screens });
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Empty States';
  }, []);

  useEffect(() => {
    if (!clientChoosed && isSuperAdmin) {
      // const foundSuperAdmin = allRoles?.find((role) => role.role_name === Roles.Super_admin)
      // if(foundSuperAdmin)
      dispatch(homePageActions.setCompanyData(null));
      navigate(ROUTES.chooseTeam);
    }
    if (!isSuperAdmin) {
      window.Intercom('shutdown');
      localStorage.removeItem('isLoggedIn');
      window.Intercom('boot', {
        app_id: 'd7jw43br',
      });
      dispatch(userActions.setClientChoosed(false));
      dispatch(commonActions.reset());
      navigate(ROUTES.login);
    }
  }, [clientChoosed, pathname]);

  const CLIENT_EMPTY_STATES = [
    {
      id: uuid(),
      value: 'Screens',
      key: EmptyScreens.Screens,
      languages: [
        { id: '1', language: 'NO' },
        { id: '2', language: 'EN' },
      ],
      lastUpdated:
        emptyStates.find((i) => i.key === Screens)?.lastUpdated || '',
    },
    {
      id: uuid(),
      value: 'Content',
      key: EmptyScreens.Content,
      languages: [
        { id: '1', language: 'NO' },
        { id: '2', language: 'EN' },
      ],
      lastUpdated:
        emptyStates.find((i) => i.key === Content)?.lastUpdated || '',
    },
    {
      id: uuid(),
      value: 'Playlists',
      key: EmptyScreens.Playlists,
      languages: [
        { id: '1', language: 'NO' },
        { id: '2', language: 'EN' },
      ],
      lastUpdated:
        emptyStates.find((i) => i.key === Playlists)?.lastUpdated || '',
    },
  ];

  const SUPER_USER_EMPTY_STATES = [
    {
      id: uuid(),
      value: 'Clients',
      key: EmptyScreens.Clients,
      languages: [
        { id: '1', language: 'NO' },
        { id: '2', language: 'EN' },
      ],
      lastUpdated:
        emptyStates.find((i) => i.key === Clients)?.lastUpdated || '',
    },
  ];

  const onCancelChanges = (): void => {
    setCurrentView('');
    setCanSaveChanges(false);
  };

  const saveChange = useCallback(async () => {
    if (emptyStateToUpdate) {
      const response = await updateSystemConfig({
        id: emptyStateToUpdate.id,
        sysc_key: emptyStateToUpdate.key,
        sysc_value: JSON.stringify({
          title: emptyStateToUpdate.title,
          description: emptyStateToUpdate.description,
          bannerImg: emptyStateToUpdate.bannerImg,
          lastUpdated: new Date(),
        }),
      });
      if (response) {
        setCurrentView('');
        const systemConfigs = await getAllSystemConfigs();
        if (systemConfigs)
          dispatch(layoutActions.setEmptyStates(systemConfigs));
        setCanSaveChanges(false);
        toaster.success('Updated empty state successfully');
      }
    }
  }, [emptyStateToUpdate, dispatch]);

  const heading =
    (currentView === Screens && 'Screens') ||
    (currentView === Content && 'Contents') ||
    (currentView === Playlists && 'Playlists') ||
    (currentView === Clients && 'Clients');

  const isEditable = isSuperAdmin || false;

  return (
    <Layout variant={LayoutVariants.layoutOne}>
      <div className={styles.emptyStates}>
        <RoleBasedHeader />
        <Subheader />
        {currentView === '' ? (
          <div className={styles.pageWrapper}>
            <p className={styles.heading}>Clients</p>
            <Table
              rows={CLIENT_EMPTY_STATES}
              onClick={(row) => {
                setCurrentView(row.key);
              }}
            />
            <p className={styles.heading}>Superuser</p>
            <Table
              rows={SUPER_USER_EMPTY_STATES}
              onClick={(row) => {
                setCurrentView(row.key);
              }}
            />
          </div>
        ) : (
          <>
            <div className={styles.subHeader}>
              <div>{heading}</div>
              <HeaderTitle
                // isDisabled={!showCancelAndSaveBtns}
                isDisabled={canSaveChanges}
                onCancelChanges={onCancelChanges}
                onSaveChanges={saveChange}
              />
            </div>
            {currentView && (
              <EmptyState
                editable={isEditable}
                setCanSaveChanges={setCanSaveChanges}
                setEmptyStateToUpdate={setEmptyStateToUpdate}
                variant={
                  (currentView === EmptyScreens.Screens &&
                    EmptyStateVariants.Screenz) ||
                  (currentView === EmptyScreens.Clients &&
                    EmptyStateVariants.Clients) ||
                  (currentView === EmptyScreens.Playlists &&
                    EmptyStateVariants.Playlists) ||
                  (currentView === EmptyScreens.Content &&
                    EmptyStateVariants.Content) ||
                  null
                }
              />
            )}
          </>
        )}
      </div>
    </Layout>
  );
}

export default EmptyStates;

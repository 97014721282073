import React from 'react';
import { iState } from 'types';
import { useStore } from 'store';
// import { meetingOverviewFullActions } from 'store/actions';
import { CARD_NAME } from 'enums';
import styles from './eventView.module.scss';
import LongComponent from './components/longComponent';

interface iProps {
  className?: string;
}

function EventView(props: iProps): JSX.Element {
  const { className } = props;
  const [state]: [iState, any] = useStore();
  const { transparency } = state.meetingOverviewFull || {};
  const { eventBgImg } = state.meetingOverviewFull || {};

  const style = {
    backgroundColor: `rgba(21, 22, 33, ${
      parseFloat(transparency) <= 1
        ? parseFloat(transparency)
        : parseFloat(transparency) / 10
    })`,
  };

  return (
    <div className={`${styles.eventContainer}  ${className}`}>
      <div className={styles.main}>
        {eventBgImg && (
          <img className={styles.eventBgImg} src={eventBgImg} alt="" />
        )}
        <div className={`${styles.eventDetails}`} style={style}>
          <div className={styles.sectionOne}>
            <div className={styles.firstTwoBoxes}>
              <LongComponent
                className={styles.shortBoxes}
                cardNumber={CARD_NAME.Eleven}
              />
              <LongComponent
                className={styles.shortBoxes}
                cardNumber={CARD_NAME.Two}
              />
            </div>
            <LongComponent cardNumber={CARD_NAME.Four} />
            <LongComponent cardNumber={CARD_NAME.Six} />
            <LongComponent cardNumber={CARD_NAME.Eight} />
            <LongComponent cardNumber={CARD_NAME.Ten} />
            <LongComponent cardNumber={CARD_NAME.Twelve} />
            <LongComponent cardNumber={CARD_NAME.One} />
            <LongComponent cardNumber={CARD_NAME.Three} />
            <LongComponent cardNumber={CARD_NAME.Five} />
            <LongComponent cardNumber={CARD_NAME.Seven} />
            <LongComponent cardNumber={CARD_NAME.Nine} />
          </div>
        </div>
      </div>
    </div>
  );
}

EventView.defaultProps = {
  className: '',
};

export default EventView;

import { captureException } from '@sentry/react';
import { Mutation, Query } from 'config';
import { restEndPoint } from 'constants/index';
import {
  iGraphqlResponse,
  iGetRoomsResponse,
  iRoomData,
  iAddOrUpdateTempleteArgs,
  iAddOrUpdateTempleteResponse,
  iGetRoomsByOfficeIdArgs,
  iGetRoomsByOfficeIdResponse,
  iAddRoomsResponse,
  iAddRoomsData,
  iGetRoomsArgs,
  iGetMsTokenArgs,
  iGetMsTokensData,
  iGetMsTokensResponse,
  iGetOfficeRoomsArgs,
  iGetOffice365Room,
  iGetOfficeRoomsResponse,
  iAddOrUpdateTempleteData,
  // iAddRoomsArgs,
} from 'types';
import {
  getRooomsQuery,
  addOrUpdateTempleteMutation,
  getRoomyByOfficeIdQuery,
  addRoomsMutation,
  getMsTokenMutation,
  getOfficeRoomsMutation,
} from '../graphql';

const MICROSOFT_GRAPH = 'https://graph.microsoft.com/v1.0/';

const msEndPoints = {
  callAllRooms: () => `${MICROSOFT_GRAPH}places/microsoft.graph.room`,
  callAllEvents: () => `${MICROSOFT_GRAPH}me/events`,
  callEventsOnRoom: (roomId) =>
    `${restEndPoint}/getRoomEvents/?roomIds=${JSON.stringify(roomId)}`,
};

const headerConfigure = (accessToken: string): any => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append('Authorization', bearer);
  return headers;
};

export const MsGraphApis = {
  callRooms: (accessToken) => {
    const options = {
      method: 'GET',
      headers: headerConfigure(accessToken),
    };

    return fetch(msEndPoints.callAllRooms(), options)
      .then((response) => response.json())
      .catch((error) => {
        captureException(error);
        console.log(error);
        return [];
      });
  },
  callEvents: ({ accessToken }) => {
    const options = {
      method: 'GET',
      headers: headerConfigure(accessToken),
    };

    return fetch(msEndPoints.callAllEvents(), options)
      .then((response) => response.json())
      .catch((error) => {
        console.log(error);
        captureException(error);
        return [];
      });
  },
  callEventsOnRoom: (email) => {
    const options = {
      method: 'GET',
      // headers: headerConfigure(accessToken),
    };

    return fetch(msEndPoints.callEventsOnRoom(email), options)
      .then(async (response) => {
        const data = await response.json();
        // //  console.log(data.data, 'data.data');
        return data.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  },
};

export const getRooms = async (
  args: iGetRoomsArgs,
  isLoading?: (a: boolean) => void
): Promise<Array<iRoomData> | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = <iGraphqlResponse<iGetRoomsResponse>>(
      await Query(getRooomsQuery, args)
    );
    if (isLoading) isLoading(false);
    return response.data.getRooms.data;
  } catch (error: any) {
    if (isLoading) isLoading(false);
    return null;
  }
};

export const addTemplate = async (
  args: iAddOrUpdateTempleteArgs,
  isLoading?: (a: boolean) => void
): Promise<iAddOrUpdateTempleteData | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = <iGraphqlResponse<iAddOrUpdateTempleteResponse>>(
      await Mutation(addOrUpdateTempleteMutation, args)
    );
    if (isLoading) isLoading(false);
    return response.data.addOrUpdateTemplete.data;
  } catch (error: any) {
    if (isLoading) isLoading(false);
    return null;
  }
};

export const getRoomsByOfficeId = async (
  args: iGetRoomsByOfficeIdArgs,
  isLoading: (a: boolean) => void
): Promise<Array<iRoomData> | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = <iGraphqlResponse<iGetRoomsByOfficeIdResponse>>(
      await Mutation(getRoomyByOfficeIdQuery, args)
    );
    if (isLoading) isLoading(false);
    return response.data.getRoomsbyofficeuserid.data;
  } catch (error: any) {
    if (isLoading) isLoading(false);
    return null;
  }
};

export const addRooms = async (
  args: any,
  isLoading: (a: boolean) => void
): Promise<iAddRoomsData | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = await (<iGraphqlResponse<iAddRoomsResponse>>(
      await Mutation(addRoomsMutation, args)
    ));
    if (isLoading) isLoading(false);
    return response.data.addRooms.data;
  } catch (error: any) {
    if (isLoading) isLoading(false);
    return null;
  }
};

export const getMsToken = async (
  args: iGetMsTokenArgs,
  isLoading
): Promise<iGetMsTokensData | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = await (<iGraphqlResponse<iGetMsTokensResponse>>(
      await Mutation(getMsTokenMutation, args)
    ));
    if (isLoading) isLoading(false);
    return response.data.getMsToken.data;
  } catch (error: any) {
    if (isLoading) isLoading(false);
    return null;
  }
};

export const getOfficeRooms = async (
  args: iGetOfficeRoomsArgs,
  isLoading
): Promise<iGetOffice365Room[] | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = await (<iGraphqlResponse<iGetOfficeRoomsResponse>>(
      await Query(getOfficeRoomsMutation, args)
    ));
    if (isLoading) isLoading(false);
    return response.data.getOfficeRooms.data.value;
  } catch (error: any) {
    if (isLoading) isLoading(false);
    //  console.log(error);
    return null;
  }
};

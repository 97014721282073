import { deleteCompanyById } from 'apis';
import DeleteModal, {
  DeleteModalVariants,
} from 'components/delete-modal/deleteModal';
import EmptyState, {
  EmptyStateVariants,
} from 'components/empty-state/emptyState';
import { LayoutViews, ModalViews } from 'enums';
import { toaster } from 'helpers';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { homePageActions, layoutActions } from 'store/actions';
import { iState } from 'types';
import Clients from './clients-list/clientsList';
import Resellers from './resellers-list/resellersList';
import styles from './superUserAdmin.module.scss';

interface iProps {
  className?: string;
}

function SuperUserAdmin(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { layout } = state || {};
  const { chosenView, isShowDeleteClient } = layout || {};
  const { companyData, companies } = state.homePage || {};
  const navigate = useNavigate();

  const deleteClient = async (): Promise<void> => {
    if (companyData) {
      const response = await deleteCompanyById({ id: companyData?.id });
      if (response?.statusCode === 200) {
        toaster.success('Client deleted successfully');
      }
      navigate('/');
      dispatch(homePageActions.setCompanyData(null));
      dispatch(layoutActions.toggleModal(ModalViews.isShowDeleteClient));
      dispatch(homePageActions.invokeGetCompanies(true));
    }
  };

  // useEffect(() => {
  //   console.log(companyData, 'companyData');
  // }, [companyData]);

  return (
    <div className={`${className} ${styles.wrapper}`}>
      {companies && companies?.length === 0 && (
        <EmptyState variant={EmptyStateVariants.SuperAdmin} />
      )}

      {chosenView === LayoutViews.SuperAdminClients && companies && <Clients />}
      {chosenView === LayoutViews.SuperAdminResellers && <Resellers />}
      <DeleteModal
        isOpen={isShowDeleteClient}
        variant={DeleteModalVariants.Client}
        name={companyData?.name || ''}
        deleteHandler={deleteClient}
      />
    </div>
  );
}

SuperUserAdmin.defaultProps = {
  className: '',
};

export default SuperUserAdmin;

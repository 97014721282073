import {
  LayoutViews,
  UserRoles,
  UserSettingsView,
  TwoFAType,
  CalenderSettingsView,
  EventContentType,
  ContentPermissions,
  PermissionFor,
  PlayListPermissions,
  ScreenPermissions,
} from 'enums';
import { uuid } from 'helpers';
import {
  informationIcon,
  ACCESS_TOKEN,
  englishData,
  norwayData,
  englishIcon,
} from 'constants/index';
import { iState } from 'types';

export const initialState: iState = {
  user: {
    role: UserRoles.SuperAdmin,
    allRoles: null,
    isLoggedIn: false,
    loggedInData: null,
    resetPasswordData: null,
    userSettings: {
      activeView: UserSettingsView.Profile,
    },
    clientChoosed: false,
    removeTeammateData: null,
    canFetchRoles: false,
    twoFAVerificationData: {
      id: '',
      twoFA: TwoFAType.DISABLED,
    },
    userCompanyId: '',
    userId: '',
    userPermissions: {
      addClients: true,
      addContentAndFolders: true,
      addPlayListAndFolders: true,
      addScreensAndFolders: true,
      addTeammates: true,
      clients: '',
      contentAcces: ContentPermissions.AllContents,
      deleteClients: true,
      deleteContentAndFolder: true,
      deletePlayListAndFolder: true,
      deleteScreenAndFolder: true,
      editCompanyDetails: true,
      id: '',
      manageReseller: true,
      permissionFor: PermissionFor.User,
      playListAccess: PlayListPermissions.AllPlayList,
      removeTeammates: true,
      rollOrCompanyUserId: '',
      screenAccess: ScreenPermissions.AllScreens,
    },
  },
  layout: {
    assignModalContentFolders: [],
    assignModalContentsFolderHierarchy: [],
    assignModalContentActiveFolder: null,
    windowWidth: 0,
    chosenView: LayoutViews.Screens,
    emptyStates: [],
    isShowAddTemplate: false,
    isShowAddContent: false,
    isShowAddWebContent: false,
    isShowContentPreview: false,
    isShowPlaylistPreview: false,
    isShowAddPlaylist: false,
    isShowAddFolderForPlaylists: false,
    isShowAddContentToPlaylist: false,
    isShowAddTeammate: false,
    isShowingClientInstance: false,
    isShowAddClient: false,
    isShowAddScreen: false,
    isShowAddFolder: false,
    isShowEditPlaylistResolution: false,
    isShowShareAccess: false,
    isShowAddRole: false,
    isShowSetUp2FA: false,
    isShowDisable2FA: false,
    initialSidebarState: false,
    invokeGetAllFolders: false,
    invokeGetFolderById: false,
    invokeGetMSAccounts: false,
    isShowAssignContentOrPlaylist: false,
    isShowDeleteModal: false,
    isShowDeleteFolder: false,
    isShowDeleteContent: false,
    isShowDeletePlaylist: false,
    isShowDeleteTeammate: false,
    isShowDeleteClient: false,
    isShowDeleteScreen: false,
    isShowO365Modal: false,
    isShowO365RoomsModal: false,
    isShowTemplatePreview: false,
    templateToView: null,
  },
  homePage: {
    screensFolders: [],
    screensActiveFolder: null,
    screenId: '',
    superUserClients: [],
    superUserResellers: [],
    resellerClients: [],
    screens: [],
    companies: [],
    activeCompanyData: false,
    foldersAndSubFolders: [],
    activeFolder: null,
    folderHierarchy: [],
    screensFolderHierarchy: [],
    openedFolders: [],
    isFetchingFolders: false,
    isScreenBeingAdded: '',
    activeClientInstanceData: null,
    screenzId: '',
    totalOnlineCount: null,
    totalOfflineCount: null,
    screenData: null,
    companyData: null,
    companyUserData: '',
    canFetchScreensData: false,
    searchCompanyKey: '',
    searchScreenKey: '',
    contentToAssignScreen: '',
    playlistToAssignScreen: '',
    invokeGetScreenById: false,
    invokeUpdateScreen: false,
    invokeGetCompanies: false,
    isSaveAndCloseDisabled: true,
    lastFetchedTime: new Date(),
    screensOnlineStatus: [],
    shareAcces: [],
  },
  contentPage: {
    contentFolders: [],
    contentsFolderHierarchy: [],
    contentActiveFolder: null,
    files: [],
    contents: [],
    uploadingFiles: [],
    emptyState: '',
    showCancelAndSaveBtns: false,
    contentView: null,
    templateView: null,
    canFetchContentsData: false,
    searchContentKey: '',
    addWebContentData: null,
    isEditWebContent: false,
    isTriedToLoadWebsiteTwo: true,
  },
  playlistsPage: {
    playlistFolders: [],
    playlistActiveFolder: null,
    playlistsFolderHierarchy: [],
    playlists: [],
    playlistData: null,
    contentToAddToPlaylist: [],
    canFetchPlaylistData: false,
    canUpdatePlaylist: false,
    searchPlaylistKey: '',
    contentView: null,
    playlistPreview: null,
    isPlaylistSaveAndCloseBtnDisabled: true,
    isShowNewlyAssignedContentsInList: false,
    canDeletePlaylist: false,
  },
  meetingOverviewFull: {
    currentView: CalenderSettingsView.TemplateSetting,
    templateOverviewName: '',
    eventBgImg: '',
    currentCard: null,
    largeAreaContent: {
      id: uuid(),
      option: 'Empty',
      optionNorway: norwayData.empty,
      icon: informationIcon,
      value: 'Empty',
    },
    preTitle: '',
    title: '',
    body: '',
    subText: '',
    transparency: '',
    largeContentUrl: '',
    cardDetails: {
      One: {
        id: '',
        position: 7,
        contentOption: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        clockAlignment: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        overviewTimezone: {
          id: uuid(),
          option: 'Empty',
          icon: informationIcon,
          value: 'Empty',
        },
        overviewEventLogo: '',
        overviewCustom_name: '',
        roomDetails: '',
        overviewChoosedRoom: null,
        overviewCalenderEvents: [],
        overviewCurrentCalenderEvents: null,
        overviewNextEvents: null,
        isShowOverviewSubject: true,
        isShowOverviewHostName: true,
        isShowOverviewNextMeeting: true,
      },
      Two: {
        id: '',
        position: 1,
        contentOption: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        clockAlignment: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        overviewTimezone: {
          id: uuid(),
          option: 'Empty',
          icon: informationIcon,
          value: 'Empty',
        },
        overviewEventLogo: '',
        overviewCustom_name: '',
        roomDetails: '',
        overviewChoosedRoom: null,
        overviewCalenderEvents: [],
        overviewCurrentCalenderEvents: null,
        overviewNextEvents: null,
        isShowOverviewSubject: true,
        isShowOverviewHostName: true,
        isShowOverviewNextMeeting: true,
      },
      Three: {
        id: '',
        position: 8,
        contentOption: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        clockAlignment: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        overviewTimezone: {
          id: uuid(),
          option: 'Empty',
          icon: informationIcon,
          value: 'Empty',
        },
        overviewEventLogo: '',
        overviewCustom_name: '',
        roomDetails: '',
        overviewChoosedRoom: null,
        overviewCalenderEvents: [],
        overviewCurrentCalenderEvents: null,
        overviewNextEvents: null,
        isShowOverviewSubject: true,
        isShowOverviewHostName: true,
        isShowOverviewNextMeeting: true,
      },
      Four: {
        id: '',
        position: 2,
        contentOption: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        clockAlignment: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        overviewTimezone: {
          id: uuid(),
          option: 'Empty',
          icon: informationIcon,
          value: 'Empty',
        },
        overviewEventLogo: '',
        overviewCustom_name: '',
        roomDetails: '',
        overviewChoosedRoom: null,
        overviewCalenderEvents: [],
        overviewCurrentCalenderEvents: null,
        overviewNextEvents: null,
        isShowOverviewSubject: true,
        isShowOverviewHostName: true,
        isShowOverviewNextMeeting: true,
      },
      Five: {
        id: '',
        position: 9,
        contentOption: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        clockAlignment: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        overviewTimezone: {
          id: uuid(),
          option: 'Empty',
          icon: informationIcon,
          value: 'Empty',
        },
        overviewEventLogo: '',
        overviewCustom_name: '',
        roomDetails: '',
        overviewChoosedRoom: null,
        overviewCalenderEvents: [],
        overviewCurrentCalenderEvents: null,
        overviewNextEvents: null,
        isShowOverviewSubject: true,
        isShowOverviewHostName: true,
        isShowOverviewNextMeeting: true,
      },
      Six: {
        id: '',
        position: 3,
        contentOption: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        clockAlignment: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        overviewTimezone: {
          id: uuid(),
          option: 'Empty',
          icon: informationIcon,
          value: 'Empty',
        },
        overviewEventLogo: '',
        overviewCustom_name: '',
        roomDetails: '',
        overviewChoosedRoom: null,
        overviewCalenderEvents: [],
        overviewCurrentCalenderEvents: null,
        overviewNextEvents: null,
        isShowOverviewSubject: true,
        isShowOverviewHostName: true,
        isShowOverviewNextMeeting: true,
      },
      Seven: {
        id: '',
        position: 10,
        contentOption: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        clockAlignment: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        overviewTimezone: {
          id: uuid(),
          option: 'Empty',
          icon: informationIcon,
          value: 'Empty',
        },
        overviewEventLogo: '',
        overviewCustom_name: '',
        roomDetails: '',
        overviewChoosedRoom: null,
        overviewCalenderEvents: [],
        overviewCurrentCalenderEvents: null,
        overviewNextEvents: null,
        isShowOverviewSubject: true,
        isShowOverviewHostName: true,
        isShowOverviewNextMeeting: true,
      },
      Eight: {
        id: '',
        position: 4,
        contentOption: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        clockAlignment: {
          id: uuid(),
          option: 'Empty',
          icon: informationIcon,
          optionNorway: norwayData.empty,
          value: 'Empty',
        },
        overviewTimezone: {
          id: uuid(),
          option: 'Empty',
          icon: informationIcon,
          value: 'Empty',
        },
        overviewEventLogo: '',
        overviewCustom_name: '',
        roomDetails: '',
        overviewChoosedRoom: null,
        overviewCalenderEvents: [],
        overviewCurrentCalenderEvents: null,
        overviewNextEvents: null,
        isShowOverviewSubject: true,
        isShowOverviewHostName: true,
        isShowOverviewNextMeeting: true,
      },
      Nine: {
        id: '',
        position: 11,
        contentOption: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        clockAlignment: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        overviewTimezone: {
          id: uuid(),
          option: 'Empty',
          icon: informationIcon,
          value: 'Empty',
        },
        overviewEventLogo: '',
        overviewCustom_name: '',
        roomDetails: '',
        overviewChoosedRoom: null,
        overviewCalenderEvents: [],
        overviewCurrentCalenderEvents: null,
        overviewNextEvents: null,
        isShowOverviewSubject: true,
        isShowOverviewHostName: true,
        isShowOverviewNextMeeting: true,
      },
      Ten: {
        id: '',
        position: 5,
        contentOption: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        clockAlignment: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        overviewTimezone: {
          id: uuid(),
          option: 'Empty',
          icon: informationIcon,
          value: 'Empty',
        },
        overviewEventLogo: '',
        overviewCustom_name: '',
        roomDetails: '',
        overviewChoosedRoom: null,
        overviewCalenderEvents: [],
        overviewCurrentCalenderEvents: null,
        overviewNextEvents: null,
        isShowOverviewSubject: true,
        isShowOverviewHostName: true,
        isShowOverviewNextMeeting: true,
      },
      Eleven: {
        id: '',
        position: 12,
        contentOption: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        clockAlignment: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        overviewTimezone: {
          id: uuid(),
          option: 'Empty',
          icon: informationIcon,
          value: 'Empty',
        },
        overviewEventLogo: '',
        overviewCustom_name: '',
        roomDetails: '',
        overviewChoosedRoom: null,
        overviewCalenderEvents: [],
        overviewCurrentCalenderEvents: null,
        overviewNextEvents: null,
        isShowOverviewSubject: true,
        isShowOverviewHostName: true,
        isShowOverviewNextMeeting: true,
      },
      Twelve: {
        id: '',
        position: 6,
        contentOption: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        clockAlignment: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        overviewTimezone: {
          id: uuid(),
          option: 'Empty',
          icon: informationIcon,
          value: 'Empty',
        },
        overviewEventLogo: '',
        overviewCustom_name: '',
        roomDetails: '',
        overviewChoosedRoom: null,
        overviewCalenderEvents: [],
        overviewCurrentCalenderEvents: null,
        overviewNextEvents: null,
        isShowOverviewSubject: true,
        isShowOverviewHostName: true,
        isShowOverviewNextMeeting: true,
      },
    },
  },
  office365: {
    templateIdToEdit: null,
    activeMsAccount: { accessToken: ACCESS_TOKEN, account: null } || null,
    currentView: CalenderSettingsView.TemplateSetting,
    eventCntentType: EventContentType.Empty,
    isShowClock: false,
    calanderEvents: [],
    currentEvents: null,
    nextEvents: null,
    tempalteDataToUpdate: null,
    contentAreaContentOption: {
      id: uuid(),
      option: englishData.empty,
      optionNorway: norwayData.empty,
      icon: informationIcon,
      value: '',
    },
    contentSettingsContentOption: {
      id: uuid(),
      option: 'Empty',
      optionNorway: norwayData.empty,
      icon: informationIcon,
      value: '',
    },
    selectedLanguage: {
      id: uuid(),
      option: 'English',
      optionNorway: 'English',
      icon: englishIcon,
      value: '',
    },
    eventInfo: {
      eventBgImg: '',
      eventLogo: '',
      isShowActiveBooking: true,
      isShowNextMeeting: true,
      isShowHostName: true,
      isShowSubject: true,
      customName: '',
      timeZone: null,
      roomChoosed: null,
      templateName: '',
      chosenTemplateType: '',
    },
    storedAccountRooms: [],
    o365UserRooms: [],
    updateRoomCustomName: false,
    responseAfterLogin: null,
    msAccountAccessResponse: null,
    isMSAccountsFetched: false,
    isMStokensFetched: false,
    fetchedMSAccounts: null,
  },
};

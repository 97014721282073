import EmptyState, {
  EmptyStateVariants,
} from 'components/empty-state/emptyState';
import Folders from 'components/folders/folders';
import PlaylistTable from 'components/playlists/playlist-table/playlistTable';
import { deletePlaylist } from 'apis';
import Playlists, {
  PlaylistVariants,
} from 'components/playlists/playlists/playlists';
import {
  DeleteStatus,
  // ModalViews,
  PlayListPermissions,
  UserRoles,
} from 'enums';
import DeleteModal, {
  DeleteModalVariants,
} from 'components/delete-modal/deleteModal';
import FoldersSkeleton from 'components/skeletons/folders-skeleton/foldersSkeleton';
import ListingSkeleton from 'components/skeletons/listing-skeleton/listingSkeleton';
import SubFolderEmptyState, {
  SubFolderEmptyStatesVariants,
} from 'components/sub-folder-empty-state/subFolderEmptyState';
import { toaster } from 'helpers';
import { ROUTES, playlistTableColumns } from 'constants/index';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import {
  homePageActions,
  layoutActions,
  playlistPageActions,
} from 'store/actions';
import { iPlaylistData, iState } from 'types';
import ScreensDetails from '../playlist-details/playlistDetails';
import styles from './playlistsList.module.scss';

interface iProps {
  className?: string;
  searchedData?: iPlaylistData[] | null;
  isLoading: boolean;
  // sharedPlaylistsData?: iPlaylistData[] | null;
}

function PlaylistsList(props: iProps): JSX.Element {
  const { className, searchedData, isLoading } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { chosenView, isShowDeletePlaylist } = state.layout || {};
  const [playlistDataToDelete, setPlaylistDataToDelete] =
    useState<iPlaylistData | null>();
  const [isDeleting, setIsDeliting] = useState<boolean>(false);
  const [isPlaylistUsedSomeWhere, setIsPlaylistUsedSomeWhere] =
    useState<boolean>(false);
  const { deletePlayListAndFolder } = state.user.userPermissions || {};

  const { playlists, playlistFolders, playlistActiveFolder } =
    state.playlistsPage || {};
  const { role, userPermissions, loggedInData } = state.user || {};
  const navigate = useNavigate();
  // const folderShared: any = [
  //   {
  //     company: null,
  //     created_at: '2023-01-16T10:41:39.746Z',
  //     folderType: 'SCREENZ',
  //     folder_path: null,
  //     id: 'ac0df8b8-835a-4898-beef-cc7d954723a0',
  //     name: 'Folder shared with user',
  //     parentFolders: null,
  //     updated_at: '2023-01-16T10:41:39.746Z',
  //     url: null,
  //     __typename: 'Folder',
  //     isActive: true,
  //   },
  // ];
  const openPlaylist = useCallback(
    (data) => {
      navigate(ROUTES.playlist);
      dispatch(playlistPageActions.setPlaylistData(data));
    },
    [navigate, dispatch]
  );

  const confirmToDelete = useCallback(async () => {
    if (!playlistDataToDelete) return;

    const response = await deletePlaylist(
      {
        id: playlistDataToDelete.id,
        is_data: true,
      },
      setIsDeliting
    );
    if (response === DeleteStatus.Deleted) {
      toaster.success('Playlist deleted successfully');
      setIsPlaylistUsedSomeWhere(false);
      dispatch(layoutActions.toggleModal());
      dispatch(playlistPageActions.fetchPlaylistData(true));
      setPlaylistDataToDelete(null);
    }
  }, [dispatch, playlistDataToDelete]);

  // const deletePlaylistHandler = useCallback(async () => {
  //   if (!playlistDataToDelete) return;

  //   const response = await deletePlaylist(
  //     {
  //       id: playlistDataToDelete.id,
  //       is_data: false,
  //     },
  //     setIsDeliting
  //   );
  //   if (response === DeleteStatus.UsedSomewhere) {
  //     setIsPlaylistUsedSomeWhere(true);
  //     dispatch(layoutActions.toggleModal(ModalViews.isShowDeletePlaylist));
  //   }
  //   if (response !== DeleteStatus.UsedSomewhere)
  //     // confirmToDelete(cont);
  //     dispatch(layoutActions.toggleModal(ModalViews.isShowDeletePlaylist));
  // }, [dispatch, playlistDataToDelete]);

  const isSuperUserAdmin = role === UserRoles.SuperAdmin;
  const ownPlaylists =
    (searchedData && searchedData?.length > 0
      ? searchedData
      : playlists
    ).filter((playlist) => playlist.owner?.id === loggedInData?.id) || [];
  const sharedPlaylists =
    (searchedData && searchedData?.length > 0
      ? searchedData
      : playlists
    ).filter((playlist) => playlist.owner?.id !== loggedInData?.id) || [];

  // const sharedPlaylistsData = [...searchedData];
  // sharedPlaylistsData[0].newlyadded = true;

  // console.log(searchedData)

  const { playListAccess } = userPermissions || {};
  return (
    <div
      className={`${className} ${styles.listAndDetails} ${styles.displayboth}`}
    >
      <div
        className={`${styles.foldersAndScreensWrapper} ${styles[chosenView]} ${
          playlists?.length === 0 &&
          playlistFolders?.length === 0 &&
          !playlistActiveFolder &&
          styles.fitHeight
        } ${playlistFolders?.length === 0 && styles}`}
      >
        {isLoading ? (
          <FoldersSkeleton />
        ) : (
          playlistFolders?.length > 0 && (
            <Folders
              className={styles.foldersWrapper}
              data={playlistFolders}
              openFolder={(folder) => {
                dispatch(homePageActions.setIsFetchingFolders(true));
                dispatch(playlistPageActions.setPlaylistsActiveFolder(folder));
              }}
              isHavingAllAccess={
                playListAccess === PlayListPermissions.AllPlayList
              }
            />
          )
        )}
        {/* {isLoading ? (
          <FoldersSkeleton />
        ) : (
          playlistFolders?.length > 0 && (
            <Folders
              className={styles.foldersWrapper}
              data={folderShared}
              openFolder={(folder) => {
                dispatch(homePageActions.setIsFetchingFolders(true));
                dispatch(playlistPageActions.setPlaylistsActiveFolder(folder));
              }}
            />
          )
        )} */}

        {!isLoading &&
          playlists?.length === 0 &&
          playlistFolders?.length === 0 &&
          !playlistActiveFolder && (
            <EmptyState variant={EmptyStateVariants.Playlists} />
          )}
        {(playlistFolders?.length > 0 || playlistActiveFolder) &&
          playlists?.length === 0 && (
            <SubFolderEmptyState
              variant={SubFolderEmptyStatesVariants.Playlists}
            />
          )}
        {isLoading ? (
          <ListingSkeleton />
        ) : (
          playlists?.length > 0 && (
            <>
              {!isSuperUserAdmin &&
                playListAccess !== PlayListPermissions.AllPlayList && (
                  <>
                    {ownPlaylists.length > 0 && (
                      <>
                        <p className={styles.heading}>Your Playlists</p>
                        <div className={`${className} ${styles.screenList}`}>
                          <PlaylistTable
                            columns={playlistTableColumns}
                            data={ownPlaylists || []}
                            className={styles.playlistTable}
                            onClick={openPlaylist}
                            setDataToDelete={setPlaylistDataToDelete}
                            showDeleteButton={deletePlayListAndFolder !== true}
                            setIsPlaylistUsedSomeWhere={
                              setIsPlaylistUsedSomeWhere
                            }
                          />
                        </div>
                      </>
                    )}
                    {sharedPlaylists.length > 0 && (
                      <p className={styles.heading}>
                        Playlists shared with you
                      </p>
                    )}
                  </>
                )}
              <div className={`${className} ${styles.screenList}`}>
                {!isSuperUserAdmin &&
                  playListAccess !== PlayListPermissions.AllPlayList &&
                  sharedPlaylists.length > 0 && (
                    <PlaylistTable
                      columns={playlistTableColumns}
                      data={sharedPlaylists}
                      className={styles.playlistTable}
                      onClick={openPlaylist}
                      setDataToDelete={setPlaylistDataToDelete}
                      showDeleteButton={deletePlayListAndFolder !== true}
                      displayNewItemFlag
                      setIsPlaylistUsedSomeWhere={setIsPlaylistUsedSomeWhere}
                    />
                  )}
                {(isSuperUserAdmin ||
                  playListAccess === PlayListPermissions.AllPlayList) && (
                  <PlaylistTable
                    columns={playlistTableColumns}
                    data={
                      searchedData && searchedData?.length > 0
                        ? searchedData
                        : playlists
                    }
                    className={styles.playlistTable}
                    onClick={openPlaylist}
                    setDataToDelete={setPlaylistDataToDelete}
                    showDeleteButton={deletePlayListAndFolder !== true}
                    setIsPlaylistUsedSomeWhere={setIsPlaylistUsedSomeWhere}
                  />
                )}
                {/* <div className={styles.sharedPlaylist}>
                <p className={styles.heading}>Screens shared with you</p>
                <p className={styles.sharedFlag}>Newly shared with you</p>
                <PlaylistTable
                  columns={playlistTableColumns}
                  data={
                    searchedData && searchedData?.length > 0
                      ? searchedData
                      : playlists
                  }
                  // sharedPlaylist={true}

                  className={styles.playlistTable2}
                  onClick={openPlaylist}
                  setDataToDelete={setPlaylistDataToDelete}
                  // flagKeyName="newlyadded"
                />
              </div> */}
                {!isSuperUserAdmin &&
                  playListAccess !== PlayListPermissions.AllPlayList && (
                    <>
                      {/* <p className={styles.heading}>Playlists shared with you</p> */}
                      <Playlists
                        foundPlaylists={searchedData}
                        variant={PlaylistVariants.InPlaylist}
                        className={styles.forMobile}
                        onClick={openPlaylist}
                      />
                    </>
                  )}
                {(isSuperUserAdmin ||
                  playListAccess === PlayListPermissions.AllPlayList) && (
                  <Playlists
                    foundPlaylists={searchedData}
                    variant={PlaylistVariants.InPlaylist}
                    className={styles.forMobile}
                    onClick={openPlaylist}
                  />
                )}
              </div>
            </>
          )
        )}
      </div>

      <ScreensDetails
        className={`${styles.detailView} 
    
         ${styles[chosenView]}`}
        isLoading={isLoading}
      />
      {playlistDataToDelete && (
        <DeleteModal
          isOpen={isShowDeletePlaylist}
          variant={DeleteModalVariants.Playlist}
          name={playlistDataToDelete?.name || ''}
          isLoading={isDeleting}
          // isUsedSomewhere={isPlaylistUsedSomeWhere}
          onClose={() => setIsPlaylistUsedSomeWhere(false)}
          confirmationMsg={
            isPlaylistUsedSomeWhere
              ? 'This Playlist is currently used on active screens.'
              : ''
          }
          usageNotification={
            isPlaylistUsedSomeWhere
              ? 'If you delete this Playlist these screens will be without content.'
              : ''
          }
          deleteHandler={
            // isPlaylistUsedSomeWhere ?
            () => confirmToDelete()
            //  : deletePlaylistHandler
          }
          displayInput={false}
        />

        // <DeleteModal
        //   isOpen={isShowDeleteContent}
        //   variant={DeleteModalVariants.Content}
        //   name={contentToDelete?.name || ''}
        //   isLoading={isDeleting}
        //   contentType={contentToDelete?.contentType}
        //   // isUsedSomewhere={isContentUsedSomeWhere}
        //   onClose={() => setIsContentUsedSomeWhere(false)}
        //   confirmationMsg={
        //     (isContentUsedSomeWhere &&
        //       confirmationMsg(contentToDelete.contentType)) ||
        //     ''
        //   }
        //   usageNotification={
        //     (isContentUsedSomeWhere &&
        //       usageNotification(contentToDelete.contentType)) ||
        //     ''
        //   }
        //   // confirmationMsg="This image is currently used in live screens or playlists."
        //   // usageNotification="If you delete this image it will also be removed from these places."
        //   deleteHandler={
        //     // isContentUsedSomeWhere
        //     //   ?
        //     () => confirmToDelete(contentToDelete)
        //     // : deleteContentHandler
        //   }
        //   displayInput={false}
        // />
      )}
    </div>
  );
}

PlaylistsList.defaultProps = {
  className: '',
  searchedData: [],
};

export default PlaylistsList;

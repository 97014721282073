import Button, { ButtonVariants } from 'components/button/button';
import Checkbox from 'components/checkbox/checkbox';
import Popover from 'components/popover/popover';
import { filterIcon } from 'constants/index';
import React, { useState } from 'react';
import styles from './filterContent.module.scss';

// const FilterData = [
//   {
//     id: uuid(),
//     checkList: 'Show all',
//   },
//   {
//     id: uuid(),
//     checkList: 'Images',
//   },
//   {
//     id: uuid(),
//     checkList: 'Videos',
//   },
//   {
//     id: uuid(),
//     checkList: 'Webpages',
//   },
// ];
interface iProps {
  checked: {
    showAll: boolean;
    image: boolean;
    videos: boolean;
    webPages: boolean;
    templates: boolean;
  };
  setChecked: any;
}
function Filter(props: iProps): JSX.Element {
  const { checked, setChecked } = props;
  const [dropDownEffect, setDropDownEffect] = useState(false);

  // useEffect(() => {
  //   //  console.log(checked, 'vikram');
  //   alert('hi');
  // }, [checked]);
  // const ontoggleCheck = () = {
  //   setChecked()
  // }
  // useEffect(() => {
  //   if (
  //     checked.image === true &&
  //     checked.videos === true &&
  //     checked.webPages === true
  //   )
  //     setChecked({ ...checked, showAll: true });
  //   if (
  //     checked.image === false ||
  //     checked.videos === false ||
  //     checked.webPages === false
  //   )
  //     setChecked({ ...checked, showAll: false });
  // }, [checked]);
  const onClickShowAll = (): void => {
    if (!checked.showAll)
      setChecked({
        showAll: true,
        image: true,
        videos: true,
        webPages: true,
        templates: true,
      });
    if (
      checked.showAll ||
      (checked.image === true &&
        checked.videos === true &&
        checked.webPages === true &&
        checked.webPages === true &&
        checked.templates === true)
    )
      setChecked({
        showAll: false,
        image: false,
        videos: false,
        templates: false,
        webPages: false,
      });
  };

  return (
    <Popover
      popoverClassName={styles.filterPopover}
      popoverComponent={
        <div className={styles.filterWrapper}>
          <div onClick={onClickShowAll} className={styles.checkBoxContainer}>
            <Checkbox
              checkmarkStyle
              isChecked={
                checked.image &&
                checked.videos &&
                checked.webPages &&
                checked.templates
              }
              className={styles.checkbox}
            />
            <p className={styles.checkBoxList}>Show All</p>
          </div>
          <div
            onClick={() => setChecked({ ...checked, image: !checked.image })}
            className={styles.checkBoxContainer}
          >
            <Checkbox
              checkmarkStyle
              isChecked={checked.image}
              className={styles.checkbox}
            />
            <p className={styles.checkBoxList}>Images</p>
          </div>
          <div
            onClick={() => setChecked({ ...checked, videos: !checked.videos })}
            className={styles.checkBoxContainer}
          >
            <Checkbox
              checkmarkStyle
              isChecked={checked.videos}
              className={styles.checkbox}
            />
            <p className={styles.checkBoxList}>Videos</p>
          </div>
          <div
            onClick={() =>
              setChecked({ ...checked, webPages: !checked.webPages })
            }
            className={styles.checkBoxContainer}
          >
            <Checkbox
              checkmarkStyle
              isChecked={checked.webPages}
              className={styles.checkbox}
            />
            <p className={styles.checkBoxList}>Webpages</p>
          </div>
          <div
            onClick={() =>
              setChecked({ ...checked, templates: !checked.templates })
            }
            className={styles.checkBoxContainer}
          >
            <Checkbox
              checkmarkStyle
              isChecked={checked.templates}
              className={styles.checkbox}
            />
            <p className={styles.checkBoxList}>Templates</p>
          </div>
        </div>
      }
      setPopoverEffect={setDropDownEffect}
      popOverEffect={dropDownEffect}
      canCloseOnClick={false}
    >
      <Button
        className={`${styles.saveAndCloseBtn} ${styles.moreBtn} ${
          styles.headerBtns
        } ${dropDownEffect && styles.activeMore}`}
        variant={ButtonVariants.SmallTransp}
        btnName="Filter"
        icon={filterIcon}
        iconPosition="left"
      />
    </Popover>
  );
}

export default Filter;

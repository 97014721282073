import React, { useState, useCallback } from 'react';
import Modal from 'components/modal/modal';
import { securityIcon } from 'constants/icons';
import { iSetUp2FAData, iState } from 'types';
import { useStore } from 'store';
import { TwoFAType } from 'enums';
import { toaster } from 'helpers';
import { setUp2FA, verify2FAToken } from 'apis';
import { layoutActions, userActions } from 'store/actions';
import AuthenticationTypes from './auth-types/authTypes';
import AuthenticateWithApp from './authenticate-with-app/authenticateWithApp';
import AuthenticateWithEmail from './authenticate-with-email/authenticateWithEmail';
import AuthCodeVerification from './authenticate-verify-code/authenticateVerifyCode';

enum AuthenticateType {
  App = 'App',
  Email = 'Email',
}
export enum TwoFactAuthViews {
  AuthTypes = 'AuthTypes',
  AuthWithApp = 'AuthWithApp',
  AuthWithMail = 'AuthWithMail',
  AuthVerifyCode = 'AuthVerifyCode',
}

function TwoFactorAuthModal(): JSX.Element {
  const { AuthTypes, AuthWithApp, AuthWithMail, AuthVerifyCode } =
    TwoFactAuthViews;
  const { App, Email } = AuthenticateType;
  const [authenticateType, setAuthenticatingBy] = useState<AuthenticateType>();
  const [twoFactAuthView, setTwoFactAuthView] = useState<TwoFactAuthViews>(
    TwoFactAuthViews.AuthTypes
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [twoFAResponse, set2FAResponse] = useState<iSetUp2FAData>();
  const [codeToVerify, setCodeToVerify] = useState<string>('');
  const [enabledTwoFAType, setEnabledTwoFAType] = useState<TwoFAType>();
  const [state, dispatch]: [iState, any] = useStore();
  const { isShowSetUp2FA } = state.layout || {};
  const { id = '', email } = state.user.loggedInData || {};

  const { authQR = '' } = twoFAResponse || {};

  const verify2FATokenHandler = useCallback(async () => {
    //  console.log(codeToVerify);
    if (!codeToVerify) {
      toaster.error('Please enter code to proceed');
      return;
    }
    if (!twoFAResponse) return;

    const response = await verify2FAToken({
      userId: id,
      twoFAType: TwoFAType[twoFAResponse.twoFA],
      verificationCode: codeToVerify,
    });
    if (response) {
      toaster.success('Two factor authentication has been setup successfully');
      dispatch(layoutActions.toggleModal());
      if (enabledTwoFAType)
        dispatch(
          userActions.set2FAData({
            id: '',
            twoFA: enabledTwoFAType,
            isDarkMode: false,
            authQR: '',
          })
        );
    }
  }, [codeToVerify, twoFAResponse, dispatch, id, enabledTwoFAType]);

  const setUp2FAHandler = useCallback(
    async (twoFAType: TwoFAType) => {
      const response = await setUp2FA(
        {
          userId: id,
          twoFAType,
        },
        setIsLoading
      );
      if (response) {
        // dispatch(
        //   userActions.set2FAData({
        //     id: '',
        //     twoFA: twoFAType,
        //     isDarkMode: false,
        //     authQR: '',
        //   })
        // );
        setEnabledTwoFAType(twoFAType);
        set2FAResponse(response);
        setCodeToVerify('');
      }
    },
    [id]
  );

  const submitHandler = useCallback(async () => {
    if (authenticateType === App) {
      if (!id) return;
      if (twoFactAuthView === AuthTypes) {
        // const response = await setUp2FA(
        //   {
        //     userId: id,
        //     twoFAType: TwoFAType.AUTHAPP,
        //   },
        //   setIsLoading
        // );
        // if (response) {
        //   set2FAResponse(response);
        //   setCodeToVerify('');
        //   setTwoFactAuthView(AuthWithApp);
        // }
        await setUp2FAHandler(TwoFAType.AUTHAPP);
        setTwoFactAuthView(AuthWithApp);
      }
      if (twoFactAuthView === AuthWithApp) {
        verify2FATokenHandler();
      }
    }
    if (authenticateType === Email) {
      if (
        twoFactAuthView !==
        (TwoFactAuthViews.AuthWithMail && TwoFactAuthViews.AuthVerifyCode)
      )
        setTwoFactAuthView(TwoFactAuthViews.AuthWithMail);
      if (twoFactAuthView === TwoFactAuthViews.AuthWithMail) {
        await setUp2FAHandler(TwoFAType.EMAIL);
        setTwoFactAuthView(AuthVerifyCode);
      }
      if (twoFactAuthView === TwoFactAuthViews.AuthVerifyCode) {
        verify2FATokenHandler();
      }
      // if (twoFactAuthView === TwoFactAuthViews.AuthWithMail) {
      //   await setUp2FAHandler(TwoFAType.EMAIL);
      //   setTwoFactAuthView(AuthVerifyCode);
      // }
      // else setTwoFactAuthView(TwoFactAuthViews.AuthWithMail);
    }
  }, [
    authenticateType,
    twoFactAuthView,
    Email,
    App,
    AuthTypes,
    AuthWithApp,
    id,
    verify2FATokenHandler,
    AuthVerifyCode,
    setUp2FAHandler,
  ]);

  return (
    <Modal
      isOpen={isShowSetUp2FA}
      heading="Two-Factor Authentication"
      btnName={
        (twoFactAuthView === AuthTypes &&
          authenticateType === Email &&
          'Activate by email') ||
        (twoFactAuthView === AuthTypes &&
          authenticateType === App &&
          'Activate Authenticator App') ||
        (twoFactAuthView === AuthTypes && 'Choose method') ||
        (twoFactAuthView === AuthWithApp && 'Activate 2FA') ||
        (twoFactAuthView === AuthWithMail &&
          authenticateType === Email &&
          'Send Code') ||
        (twoFactAuthView === AuthVerifyCode &&
          authenticateType === Email &&
          'Activate 2FA') ||
        ''
      }
      isBtnDisabled={!authenticateType}
      isLoading={isLoading}
      headingIcon={securityIcon}
      submit={submitHandler}
      onClose={() => {
        setTwoFactAuthView(TwoFactAuthViews.AuthTypes);
        setAuthenticatingBy(undefined);
        dispatch(layoutActions.toggleModal());
      }}
    >
      <>
        {TwoFactAuthViews.AuthTypes === twoFactAuthView && (
          <AuthenticationTypes onClick={setAuthenticatingBy} />
        )}
        {authenticateType === App &&
          TwoFactAuthViews.AuthWithApp === twoFactAuthView && (
            <AuthenticateWithApp
              qrCode={authQR}
              setCodeToVerify={setCodeToVerify}
            />
          )}
        {authenticateType === Email &&
          twoFactAuthView === AuthWithMail &&
          email && <AuthenticateWithEmail email={email} />}
        {authenticateType === Email && twoFactAuthView === AuthVerifyCode && (
          <AuthCodeVerification setCodeToVerify={setCodeToVerify} />
        )}
        <div />
      </>
    </Modal>
  );
}

TwoFactorAuthModal.defaultProps = {
  className: '',
};

export default TwoFactorAuthModal;

import React, { useCallback } from 'react';
import styles from './addTeamMembers.module.scss';

interface iProps {
  className?: string;
  teammate: {
    email: string;
    role: string;
  };
  setTeammate: any;
}

function AddTeamMembers(props: iProps): JSX.Element {
  const { className, setTeammate, teammate } = props;

  const onChange = useCallback(
    (event): void => {
      const temp = { ...teammate };

      temp[event.target.name] = event.target.value;
      setTeammate(temp);
    },
    [teammate]
  );
  return (
    <div className={`${styles.mainContainer} ${className}`}>
      <p>You can invite multiple teammates by separating them with a comma.</p>
      <textarea
        className={styles.labelAndInput}
        name="email"
        placeholder="Enter your teammates’ email addresses"
        value={teammate.email || ''}
        onChange={onChange}
      />
    </div>
  );
}

AddTeamMembers.defaultProps = {
  className: '',
};

export default AddTeamMembers;

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { SENTRY_ID } from 'constants/index';

export const initializeSentry = (): any => {
  Sentry.init({
    dsn: SENTRY_ID,
    release: `${process.env.NPM_PACKAGE_VERSION}`,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
};

export const captureException = (error: unknown): any => {
  if (process.browser && window.location.hostname !== 'localhost')
    Sentry.captureException(error);
};

import { getCompaniesById, updateCompany } from 'apis';
import Button, { ButtonVariants } from 'components/button/button';
import LabelAndInput from 'components/label-and-input/labelAndInput';
import { toaster } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'store';
import { homePageActions } from 'store/actions';
import { iState, iUpdateCompanyArgs } from 'types';
import SectionHeading from '../section-heading/sectionHeading';
import styles from './companyInfo.module.scss';

interface iProps {
  className?: string;
}

const companyInitialValues = {
  id: '',
  name: '',
  org_no: '',
  address: '',
  postal_code: '',
  email: ``,
  city: '',
  phone_number: '',
  country_code: '',
};

function CompanyInformation(props: iProps): JSX.Element {
  const { className } = props;
  const [companyInfo, setCompanyInfo] =
    useState<iUpdateCompanyArgs>(companyInitialValues);
  const [state, dispatch]: [iState, any] = useStore();
  const { companyData } = state.homePage || {};
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { editCompanyDetails } = state.user.userPermissions || {};

  //  console.log({ isLoading });

  const handleChange = useCallback(
    (event): void => {
      const initialValue = { ...companyInfo };
      initialValue[event.target.name] = event.target.value;
      setCompanyInfo(initialValue);
      setDisabled(false);
    },
    [companyInfo]
  );

  const handleUpdateCompanyInfo = useCallback(() => {
    if (!companyData) return;
    (async () => {
      const response = await updateCompany(
        {
          id: companyData.id,
          email: companyInfo.email || companyData.email,
          name: companyInfo.name || companyData.name,
          address: companyInfo.address || companyData.address,
          city: companyInfo.city || companyData.city,
          postal_code: companyInfo.postal_code || companyData.postal_code,
          org_no: companyInfo.org_no || companyData.org_no,
        },
        setIsLoading
      );
      if (response) {
        //  console.log({ response }, 'updated');
        setCompanyInfo(response);
        toaster.success('Client updated successfully');
        dispatch(homePageActions.setActiveCompanyData(true));
        dispatch(homePageActions.invokeGetCompanies(true));
      }
    })();
  }, [companyData, companyInfo]);

  useEffect(() => {
    if (!companyData) return;
    (async () => {
      const response = await getCompaniesById(
        { id: companyData.id },
        setIsLoading
      );
      if (response !== null) {
        //  console.log({ response });
        setCompanyInfo(response);
      }
    })();
  }, [companyData]);

  return (
    <div>
      {!isLoading && (
        <div className={`${styles.companyInfoWrapper} ${className}`}>
          <SectionHeading
            className={styles.sectionHeading}
            heading="Company information"
          />
          <LabelAndInput
            className={styles.labelAndInput}
            label="Company name"
            name="name"
            // capitalize
            value={companyInfo.name}
            onChange={handleChange}
            disabled={!editCompanyDetails}
          />
          <LabelAndInput
            className={styles.labelAndInput}
            label="Org. nr."
            name="org_no"
            // capitalize
            value={companyInfo.org_no}
            onChange={handleChange}
            disabled={!editCompanyDetails}
          />
          <LabelAndInput
            className={styles.labelAndInput}
            label="Address"
            // capitalize
            name="address"
            value={companyInfo.address}
            onChange={handleChange}
            disabled={!editCompanyDetails}
          />
          <div className={styles.inputContainer}>
            <LabelAndInput
              className={`${styles.labelAndInput} ${styles.postCodeInput}`}
              label="Postcode"
              name="postal_code"
              // capitalize
              value={companyInfo.postal_code}
              onChange={handleChange}
              disabled={!editCompanyDetails}
            />
            <LabelAndInput
              className={`${styles.labelAndInput} ${styles.cityInput}`}
              label="City"
              // capitalize
              name="city"
              value={companyInfo.city}
              onChange={handleChange}
              disabled={!editCompanyDetails}
            />
          </div>
          <div className={styles.btnsContainer}>
            <Button
              className={styles.saveChangeBtn}
              btnName="Save changes"
              isDisabled={disabled}
              variant={ButtonVariants.SmallStd}
              onClick={handleUpdateCompanyInfo}
            />
          </div>
        </div>
      )}
    </div>
  );
}

CompanyInformation.defaultProps = {
  className: '',
};

export default CompanyInformation;

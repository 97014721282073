import EmptyState, {
  EmptyStateVariants,
} from 'components/empty-state/emptyState';
import SubFolderEmptyState, {
  SubFolderEmptyStatesVariants,
} from 'components/sub-folder-empty-state/subFolderEmptyState';
import Folders from 'components/folders/folders';
import { updateDragAndDropList } from 'apis';
import ScreenTile from 'components/screen-tile/screenTile';
import React, { useCallback, useEffect, useState } from 'react';
// import PingTime from 'components/ping-time/pingTime';
import ScreensSkeleton from 'components/skeletons/screens-skeleton/screensSkeleton';
import FoldersSkeleton from 'components/skeletons/folders-skeleton/foldersSkeleton';
import { ListManager } from 'react-beautiful-dnd-grid';
import { useStore } from 'store';
import { homePageActions } from 'store/actions';
import { ScreenPermissions, UserRoles } from 'enums';
import { iScreenData, iState } from 'types';
import ScreensPrimaryDetails from '../screen-primary-details/screenPrimaryDetails';
import styles from './screensList.module.scss';

interface iProps {
  className?: string;
  data: iScreenData[];
  isLoading: boolean;
  // isRefreshing: boolean;
  onReorder: (a: iScreenData[]) => void;
  // refresh: () => void;
}

function ScreensList(props: iProps): JSX.Element {
  const { className, isLoading, data, onReorder } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { screensFolders, screensActiveFolder } = state.homePage || {};
  const { chosenView } = state.layout || {};
  const [gridItems, setGridItems] = useState<number>();
  const { role } = state.user || {};
  const { userPermissions, loggedInData } = state.user || {};

  const reorder = useCallback(
    (list: iScreenData[], startIndex, endIndex): any => {
      const result = Array.from(list);
      const [removed] = result.splice(endIndex, 1);
      result.splice(startIndex, 0, removed);
      return result;
    },
    []
  );

  const onDrop = useCallback(
    async (destinationIndex, sourceIndex) => {
      if (destinationIndex === sourceIndex) return;

      // const list = [...data];
      // const source = { ...data[sourceIndex] };
      // const destination = { ...data[destinationIndex] };
      // list[sourceIndex] = destination;
      // list[destinationIndex] = source;
      // const orderedList = list.map((screen, index) => ({
      //   id: screen.id,
      //   sortOrder: index,
      // }));
      // onReorder([...list]);
      const items = reorder([...data], sourceIndex, destinationIndex);

      items.map((screen, index: number): iScreenData[] => {
        //  console.log(screen);
        screen.sortOrder = index;
        return screen;
      });
      onReorder(items);
      await updateDragAndDropList(
        items.map((i) => ({
          id: i.id,
          sortOrder: i.sortOrder,
        }))
      );
    },
    [data, onReorder, reorder]
  );

  // const sharedData: iScreenData[] = [
  //   {
  //     brightness: 20,
  //     company: {
  //       address: '',
  //       city: '',
  //       country_code: '',
  //       email: '',
  //       id: '',
  //       lastActiveTime: '',
  //       name: '',
  //       org_no: '',
  //       phone_number: '',
  //       postal_code: '',
  //     },
  //     created_at: '',
  //     currentContent: {
  //       company: '',
  //       contentType: '',
  //       id: '',
  //       name: '',
  //       url: '',
  //     },
  //     currentPlayList: {
  //       dimention: 'abc',
  //       duration: 'abc',
  //       id: 'abc',
  //       name: '',
  //       screenType: '',
  //       status: '',
  //     },
  //     hashCode: 'abc',
  //     id: '',
  //     lastPing: '',
  //     name: '',
  //     sortOrder: 1,
  //     volume: 15,
  //   },
  //   {
  //     brightness: 20,
  //     company: {
  //       address: 'abc',
  //       city: 'city',
  //       country_code: '',
  //       email: '',
  //       id: '',
  //       lastActiveTime: '',
  //       name: '',
  //       org_no: '',
  //       phone_number: '',
  //       postal_code: '123',
  //     },
  //     created_at: 'abc',
  //     currentContent: {
  //       company: '',
  //       contentType: '',
  //       id: '',
  //       name: '',
  //       url: '',
  //     },
  //     currentPlayList: {
  //       dimention: 'abc',
  //       duration: 'abc',
  //       id: '',
  //       name: '',
  //       screenType: '',
  //       status: '',
  //     },
  //     hashCode: '',
  //     id: '',
  //     lastPing: '',
  //     name: '',
  //     sortOrder: 1,
  //     volume: 15,
  //   },
  //   {
  //     brightness: 20,
  //     company: {
  //       address: 'abc',
  //       city: 'city',
  //       country_code: '',
  //       email: '',
  //       id: '',
  //       lastActiveTime: '',
  //       name: '',
  //       org_no: '',
  //       phone_number: '',
  //       postal_code: '123',
  //     },
  //     created_at: 'abc',
  //     currentContent: {
  //       company: '',
  //       contentType: '',
  //       id: '',
  //       name: '',
  //       url: '',
  //     },
  //     currentPlayList: {
  //       dimention: 'abc',
  //       duration: 'abc',
  //       id: '',
  //       name: '',
  //       screenType: '',
  //       status: '',
  //     },
  //     hashCode: '',
  //     id: '',
  //     lastPing: '',
  //     name: '',
  //     sortOrder: 1,
  //     volume: 15,
  //   },
  // ];

  useEffect(() => {
    const container = document.getElementById('screesDragAndDropContainer');
    if (container) {
      const { clientWidth } = container || {};
      console.log(state.layout.windowWidth, clientWidth);
      if (clientWidth < 700) {
        setGridItems(1);
      }
      if (clientWidth > 701 && clientWidth < 1023) {
        setGridItems(2);
      }
      if (clientWidth > 1024 && clientWidth < 1600) {
        setGridItems(3);
      }
      if (clientWidth > 1601 && clientWidth < 2000) {
        setGridItems(4);
      }
      if (clientWidth > 2000) {
        setGridItems(5);
      }
    }
  });

  const isHaveData = data.length > 0;
  const isHaveRootFolders = screensFolders.length > 0;
  const isInSubFolder = screensActiveFolder || false;

  const isSuperUserAdmin = role === UserRoles.SuperAdmin;
  // const isSharedFolder: any = [
  //   {
  //     company: null,
  //     created_at: '2023-01-16T10:41:39.746Z',
  //     folderType: 'SCREENZ',
  //     folder_path: null,
  //     id: 'ac0df8b8-835a-4898-beef-cc7d954723a0',
  //     name: 'Folder shared with user',
  //     parentFolders: null,
  //     updated_at: '2023-01-16T10:41:39.746Z',
  //     url: null,
  //     __typename: 'Folder',
  //     isActive: true,
  //   },
  // ];

  const ownScreens =
    data.filter((screeen) => screeen.owner?.id === loggedInData?.id) || [];
  const sharedScreens =
    data.filter((screeen) => screeen.owner?.id !== loggedInData?.id) || [];

  const { screenAccess } = userPermissions || {};

  return (
    <div
      className={`${className} ${styles.listAndDetails} ${
        // data?.length > 0 && styles.displayboth
        styles.displayboth
      }`}
    >
      <div
        className={`${styles.foldersAndScreensWrapper} ${styles[chosenView]} ${
          !isHaveData && !isHaveRootFolders && !isInSubFolder && styles.noData
        }`}
      >
        {/* <div className={styles.lastPingAndRefresh}>
          {isHaveData && (
            <PingTime
              className={styles.pingTime}
              isLoading={isRefreshing || isLoading}
              lastPing={`${lastFetchedTime}` || `${new Date()}`}
              refresh={refresh}
            />
          )}
        </div> */}
        {(isLoading && <FoldersSkeleton />) ||
          (screensFolders?.length > 0 && (
            <Folders
              className={styles.foldersWrapper}
              data={screensFolders}
              openFolder={(folder) => {
                dispatch(homePageActions.setIsFetchingFolders(true));
                dispatch(homePageActions.setScreensActiveFolder(folder));
              }}
              isHavingAllAccess={screenAccess === ScreenPermissions.AllScreens}
            />
          ))}
        {/* {(isLoading && <FoldersSkeleton />) ||
          (screensFolders?.length > 0 && (
            <Folders
              className={styles.foldersWrapper}
              data={isSharedFolder}
              openFolder={(folder) => {
                dispatch(homePageActions.setIsFetchingFolders(true));
                dispatch(homePageActions.setScreensActiveFolder(folder));
              }}
            />
          ))} */}

        {isLoading ? (
          <ScreensSkeleton className={styles.screensSkeleton} />
        ) : (
          <>
            {!isHaveData && !isHaveRootFolders && !isInSubFolder && (
              <EmptyState variant={EmptyStateVariants.Screenz} />
            )}

            {((!isHaveData && isHaveRootFolders) ||
              (!isHaveData && isInSubFolder)) && (
              <SubFolderEmptyState
                variant={SubFolderEmptyStatesVariants.Screens}
              />
            )}

            {/* {isHaveData && (
              <div className={`${className} ${styles.screenList}`}>
                {data.map((i) => (
                  <ScreenTile data={i} />
                ))}
              </div>
            )}
            <p className={styles.screenHeader}>Screen shared with you</p>
            <div className={`${styles.screenList}`}>
              {sharedData.map((i) => (
                <ScreenTile data={i} />
              ))}
            </div> */}
            {!isSuperUserAdmin &&
              screenAccess !== ScreenPermissions.AllScreens &&
              ownScreens.length > 0 && (
                <div
                  id="screesDragAndDropContainer"
                  className={styles.gridContainer}
                >
                  {gridItems && isHaveData && (
                    <>
                      <p className={styles.heading}>Your Screens</p>
                      <ListManager
                        items={ownScreens}
                        direction="horizontal"
                        maxItems={gridItems}
                        render={(item) => <ScreenTile data={item} />}
                        onDragEnd={onDrop}
                      />
                    </>
                  )}
                </div>
              )}
            {!isSuperUserAdmin &&
              screenAccess !== ScreenPermissions.AllScreens &&
              sharedScreens.length > 0 && (
                <div
                  id="screesDragAndDropContainer"
                  className={styles.gridContainer}
                >
                  {gridItems && isHaveData && (
                    <>
                      <p className={styles.heading}>Screens shared with you</p>
                      <ListManager
                        items={sharedScreens}
                        direction="horizontal"
                        maxItems={gridItems}
                        render={(item) => (
                          <ScreenTile data={item} displayNewItemFlag />
                        )}
                        onDragEnd={onDrop}
                      />
                    </>
                  )}
                </div>
              )}
            {(isSuperUserAdmin ||
              screenAccess === ScreenPermissions.AllScreens) && (
              <div
                id="screesDragAndDropContainer"
                className={styles.gridContainer}
              >
                {gridItems && (
                  <ListManager
                    items={data}
                    direction="horizontal"
                    maxItems={gridItems}
                    render={(item) => <ScreenTile data={item} />}
                    onDragEnd={onDrop}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
      <ScreensPrimaryDetails
        className={`${styles.detailView} ${styles[chosenView]}`}
        isLoading={isLoading}
        data={data}
      />
    </div>
  );
}

ScreensList.defaultProps = {
  className: '',
};

export default ScreensList;

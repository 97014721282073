import { CalenderSettingsView, EventContentType } from 'enums';
import {
  iCalenderEvent,
  iContentData,
  iOffice365DropdownOption,
  iRoomData,
  // iMsAccount,
} from 'types';
import { OFFICE_365 } from '../constants';

interface iActions {
  type: string;
  payload?: any;
}

export const office365Actions = {
  setTemplateIdToEdit: (data: string): iActions => ({
    type: OFFICE_365.setTemplateIdToEdit,
    payload: data,
  }),
  setTemplateName: (data: string): iActions => ({
    type: OFFICE_365.setTemplateName,
    payload: data,
  }),
  setFetchedMSAccounts: (data: any): iActions => ({
    type: OFFICE_365.setFetchedMSAccounts,
    payload: data,
  }),
  setMsAccountAccessToken: (data: string): iActions => ({
    type: OFFICE_365.setMsAccountAccessToken,
    payload: data,
  }),

  setActiveMsAccount: (data): iActions => ({
    type: OFFICE_365.setActiveMsAccount,
    payload: data,
  }),
  setContentAreaContentOption: (
    option: iOffice365DropdownOption
  ): iActions => ({
    type: OFFICE_365.setContentAreaContentOption,
    payload: option,
  }),
  setContentSettingsContentOption: (
    option: iOffice365DropdownOption
  ): iActions => ({
    type: OFFICE_365.setContentSettingsContentOption,
    payload: option,
  }),
  setTimeZoneOption: (option: iOffice365DropdownOption): iActions => ({
    type: OFFICE_365.setTimeZoneOption,
    payload: option,
  }),
  setChoosedRoom: (room: iRoomData | null): iActions => ({
    type: OFFICE_365.setChoosedRoom,
    payload: room,
  }),
  updateCustomName: (name: string): iActions => {
    //  console.log('updateCustomName____', name);

    return {
      type: OFFICE_365.updateCustomName,
      payload: name,
    };
  },
  setAllCalenderEvents: (events: iCalenderEvent[]): iActions => ({
    type: OFFICE_365.setAllCalenderEvents,
    payload: events,
  }),
  setCurrentCalenderEvents: (events: iCalenderEvent | null): iActions => ({
    type: OFFICE_365.setCurrentEvents,
    payload: events,
  }),
  setTempalteDataToUpdate: (events: iContentData | null): iActions => ({
    type: OFFICE_365.setTempalteDataToUpdate,
    payload: events,
  }),
  resetTemplatData: (): iActions => ({
    type: OFFICE_365.resetTemplatData,
  }),

  setNextCurrentCalenderEvents: (events: iCalenderEvent | null): iActions => ({
    type: OFFICE_365.setNextEvents,
    payload: events,
  }),
  setCurrentView: (currentView: CalenderSettingsView): iActions => ({
    type: OFFICE_365.setCurrentView,
    payload: currentView,
  }),
  setEventBgImg: (img: string): iActions => ({
    type: OFFICE_365.setEventBgImg,
    payload: img,
  }),
  setEventLogo: (img: string): iActions => ({
    type: OFFICE_365.setEventLogo,
    payload: img,
  }),
  setIsShowSubject: (isOn?: boolean): iActions => ({
    type: OFFICE_365.setIsShowSubject,
    payload: isOn,
  }),
  setIsShowHostName: (isOn?: boolean): iActions => ({
    type: OFFICE_365.setIsShowHostName,
    payload: isOn,
  }),
  setIsShowNextMeeting: (isOn?: boolean): iActions => ({
    type: OFFICE_365.setIsShowNextMeeting,
    payload: isOn,
  }),
  setIsShowactivateBooking: (isOn?: boolean): iActions => ({
    type: OFFICE_365.setIsShowactivateBooking,
    payload: isOn,
  }),
  setIsShowClock: (status: boolean): iActions => ({
    type: OFFICE_365.setIsShowClock,
    payload: status,
  }),
  setEventContentType: (type: EventContentType): iActions => ({
    type: OFFICE_365.setEventContentType,
    payload: type,
  }),
  setStoredAccountRooms: (data: iRoomData[]): iActions => ({
    type: OFFICE_365.setStoredAccountRooms,
    payload: data,
  }),
  setChosenTemplateType: (data: string): iActions => ({
    type: OFFICE_365.setChosenTemplateType,
    payload: data,
  }),
  addOrRemoveRoom: (email: string): iActions => ({
    type: OFFICE_365.addOrRemoveRoom,
    payload: email,
  }),
  setO365UserRooms: (data: any) => ({
    type: OFFICE_365.setO365UserRooms,
    payload: data,
  }),
  setCustomName: (data: {
    id: string;
    name: string;
    customNameError: boolean;
  }) => ({
    type: OFFICE_365.setCustomName,
    payload: data,
  }),
  setupdateRoomCustomName: (data: boolean): iActions => ({
    type: OFFICE_365.setupdateRoomCustomName,
    payload: data,
  }),
  setResponseAfterLogin: (data: any): iActions => ({
    type: OFFICE_365.setResponseAfterLogin,
    payload: data,
  }),
  setMsAccountAccessResponse: (data: any): iActions => ({
    type: OFFICE_365.setMsAccountAccessResponse,
    payload: data,
  }),
  setIsMSaccountsFetched: (data: boolean): iActions => ({
    type: OFFICE_365.setIsMSaccountsFetched,
    payload: data,
  }),
  setIsMStokensFetched: (data: boolean): iActions => ({
    type: OFFICE_365.setIsMStokensFetched,
    payload: data,
  }),
  setSelectedLanguage: (option: iOffice365DropdownOption): iActions => ({
    type: OFFICE_365.setSelectedLanguage,
    payload: option,
  }),
};

import React from 'react';
import styles from './button.module.scss';
import { ButtonVariants, Variants } from './variants';

interface iProps {
  className?: string;
  btnName?: string;
  variant: ButtonVariants;
  icon?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  iconPosition?: 'left' | 'right' | 'center';
  isSupportResponsive?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  deleteModal?: boolean;
}

function Button(props: iProps): JSX.Element {
  const {
    className,
    btnName,
    variant,
    isActive,
    isDisabled,
    icon,
    isLoading = false,
    iconPosition = 'right',
    isSupportResponsive,
    onClick = () => {
      //
    },
    deleteModal,
  } = props;

  const o365Btn = btnName?.split(' ').includes('Microsoft');

  return isSupportResponsive ? (
    <img
      className={`${className} ${styles.icon}`}
      src={icon}
      alt=""
      onClick={onClick}
    />
  ) : (
    <button
      type="button"
      disabled={isDisabled}
      className={`${styles.button} ${styles.standard} ${
        styles[variant]
      } ${className} ${deleteModal && styles.deleteBtn} ${
        !isDisabled && deleteModal && styles.active
      } ${isActive && !deleteModal && styles.active}`}
      onClick={onClick}
    >
      {isLoading ? (
        'Loading...'
      ) : (
        <>
          {icon && iconPosition === 'left' && (
            <img
              className={`${styles.icon} ${o365Btn && styles.o365Icon}`}
              src={icon}
              alt=""
            />
          )}
          {btnName}
          {icon && iconPosition === 'right' && (
            <img
              className={`${styles.icon} ${styles.right}`}
              src={icon}
              alt=""
            />
          )}
          {icon && iconPosition === 'center' && (
            <img
              className={`${styles.icon} ${styles.center}`}
              src={icon}
              alt=""
            />
          )}
        </>
      )}
    </button>
  );
}

Button.defaultProps = {
  className: '',
  icon: '',
  btnName: '',
  isSupportResponsive: false,
  iconPosition: 'left',
  isActive: false,
  isDisabled: false,
  isLoading: false,
  onClick: () => {
    //
  },
  deleteModal: false,
};

export { ButtonVariants, Variants };
export default Button;

import { emptyScreensFolders } from 'constants/index';
import React from 'react';
import { SubFolderEmptyStatesVariants, Variants } from './variants';
import styles from './subFolderEmptyState.module.scss';

interface iProps {
  className?: string;
  variant: SubFolderEmptyStatesVariants;
}

function SubFolderEmptyState(props: iProps): JSX.Element {
  const { className, variant } = props;

  const { heading, description } = Variants[variant];

  return (
    <div className={`${className} ${styles.subFolderEmptyState}`}>
      <img
        className={styles.emptyScreensFoldersBanner}
        src={emptyScreensFolders}
        alt=""
      />
      <p className={styles.noScreens}>{`No ${heading} here:-(`}</p>
      <p className={styles.description}>{description}</p>
    </div>
  );
}

SubFolderEmptyState.defaultProps = {
  className: '',
};

export { SubFolderEmptyStatesVariants };
export default SubFolderEmptyState;

import { deletePlaylist, getPlaylistsById, updatePlaylist } from 'apis';
import AddModal, { AddModalVariants } from 'components/add-modal/addModal';
import AssignContentModal from 'components/assign-content-modal/assignContentModal';
import DeleteModal, {
  DeleteModalVariants,
} from 'components/delete-modal/deleteModal';
import RoleBasedHeader from 'components/Layouts/components/role-based-header/roleBasedHeader';
import Layout, { LayoutVariants } from 'components/Layouts/layout';
import Loader from 'components/loader/loader';
import UploadContentModal from 'components/upload-content-modal/uploadContentModal';
import { ROUTES } from 'constants/index';
import { LayoutViews, DeleteStatus, UserRoles, ModalViews } from 'enums';
import {
  calculateContentRuntime,
  mapContentPrimaryDuration,
  toaster,
} from 'helpers';

import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { layoutActions, playlistPageActions } from 'store/actions';
import { iPlaylistContents, iPlaylistData, iState } from 'types';
import ClientsListDropdown from 'modules/home/components/super-user-admin/clientsdropdownList/clientsListDropdown';
import Details from './components/details/details';
import EmptyState from './components/empty-state/emptyState';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import PlaylistContent from './components/playlist-content/playlistContent';
import Subheader from './components/subheader/subheader';
import MainHeader from '../../../components/Layouts/components/header/header';
import styles from './playlistView.module.scss';

function PlaylistView(): JSX.Element {
  const [state, dispatch]: [iState, any] = useStore();
  const { chosenView } = state.layout || {};
  const {
    playlistData,
    canUpdatePlaylist,
    contentToAddToPlaylist,
    isPlaylistSaveAndCloseBtnDisabled,
    isShowNewlyAssignedContentsInList,
    canDeletePlaylist,
  } = state.playlistsPage || {};
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isPlaylistUsedSomeWhere, setIsPlaylistUsedSomeWhere] =
    useState<boolean>(false);
  const { companyData } = state.homePage || {};
  const { isShowDeletePlaylist } = state.layout || {};
  const { user } = state || {};

  const [latePlaylistName, setLatePlaylistName] = useState<boolean>(false);
  const { id = '', playListContents = [] } = playlistData || {};

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!companyData) {
      navigate(ROUTES.chooseTeam);
    }
  }, [pathname]);

  const cancel = useCallback(() => {
    navigate(ROUTES.playlists);
  }, [navigate]);

  useEffect(() => {
    return () => {
      dispatch(playlistPageActions.showNewlyAssignedContentsInList(false));
      dispatch(playlistPageActions.clearAddContentToPlaylist());
      dispatch(playlistPageActions.disablePlaylistSaveAndCloseBtn(true));
      // dispatch(playlistPageActions.setPlaylistData(null));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(layoutActions.setHomePageView(LayoutViews.Playlist));
  }, [dispatch]);

  const getPlaylist = useCallback(async () => {
    const response = await getPlaylistsById({ id }, () => {});
    if (response) {
      dispatch(playlistPageActions.setPlaylistData(response));
    }
  }, [dispatch, id]);

  useEffect(() => {
    getPlaylist();
  }, []);

  const prepareContentForListing = useCallback(
    async (contentsToAdd: iPlaylistContents[], playlist: iPlaylistData) => {
      const currentContents = playlist.playListContents || [];
      const withDuration = await mapContentPrimaryDuration(
        contentsToAdd,
        currentContents.length || 0
      );
      //  console.log('contentToAddToPlaylist____', {
      //   currentContents,
      //   playlist,
      //   withDuration,
      // });
      dispatch(
        playlistPageActions.setPlaylistData({
          ...playlist,
          playListContents: [...currentContents, ...withDuration],
        })
      );
      dispatch(playlistPageActions.showNewlyAssignedContentsInList(false));
      dispatch(playlistPageActions.clearAddContentToPlaylist());
    },
    [dispatch]
  );

  useEffect(() => {
    if (contentToAddToPlaylist.length === 0) return;
    if (!isShowNewlyAssignedContentsInList) return;
    if (!playlistData) return;

    // const contents = playlistData?.playListContents || [];
    prepareContentForListing(contentToAddToPlaylist, playlistData);
    // const withDuration = mapContentPrimaryDuration(
    //   contents,
    //   playlistData.playListContents?.length || 0
    // );
    // dispatch(
    //   playlistPageActions.setPlaylistData({
    //     ...playlistData,
    //     playListContents: [...contents, ...contentToAddToPlaylist],
    //   })
    // );
    // dispatch(playlistPageActions.showNewlyAssignedContentsInList(false));
    // dispatch(playlistPageActions.clearAddContentToPlaylist());
  }, [
    isShowNewlyAssignedContentsInList,
    contentToAddToPlaylist,
    prepareContentForListing,
  ]);

  // const getVideoLength = useCallback((content) => {
  //   return new Promise((resolve) => {
  //     const video = document.createElement('video');
  //     video.preload = 'metadata';
  //     video.onloadedmetadata = (): void => {
  //       window.URL.revokeObjectURL(video.src);
  //       resolve(Math.floor(video.duration));
  //     };
  //     video.src = content.url || '';
  //   });
  // }, []);

  const isSuperAdmin = user.role === UserRoles.SuperAdmin;

  const updatePlaylistHandler = useCallback(async () => {
    if (!playlistData) return;
    const { id: companyId = '' } = companyData || {};
    setIsLoading(true);
    // const prepareContents: iPlaylistContents[] = [];
    // if (playListContents && playListContents?.length > 0)
    //   playListContents.map(async (i, index) => {
    //     if (i.contentType === CONTENT_TYPE.Video) {
    //       prepareContents.push({
    //         id: i.id,
    //         duration: i.duration,
    //         sortOrder: i.sortOrder || index,
    //       });
    //     } else {
    //       const duration = i.duration || '10';
    //       prepareContents.push({
    //         id: i.id,
    //         duration,
    //         sortOrder: i.sortOrder || index,
    //       });
    //     }
    //     return null;
    //   });

    // if (contentToAddToPlaylist?.length > 0)
    //   await Promise.all([
    //     ...contentToAddToPlaylist.map(async (i, index) => {
    //       if (i.contentType === CONTENT_TYPE.Video) {
    //         const duration = await getVideoLength(i);
    //         prepareContents.push({
    //           id: i.id,
    //           duration: `${duration}`,
    //           sortOrder: playListContents.length + index,
    //         });
    //       } else {
    //         const duration = i.duration || '10';
    //         prepareContents.push({
    //           id: i.id,
    //           duration,
    //           sortOrder: playListContents.length + index,
    //         });
    //       }

    //       return null;
    //     }),
    //   ]);

    const contents = playlistData.playListContents || [];
    const playlistDuration = calculateContentRuntime(contents);

    const response = await updatePlaylist(
      {
        id: playlistData.id,
        name: playlistData.name,
        dimention: playlistData.dimention,
        status: playlistData.status,
        screenType: playlistData.screenType,
        duration: playlistDuration,
        companyId,
        contents:
          (contents.length > 0 &&
            contents.map((i) => ({
              id: i.id,
              duration: i.duration,
              sortOrder: i.sortOrder,
            }))) ||
          [],
      },
      () => {}
    );
    if (response) {
      dispatch(playlistPageActions.invokeUpdatePlaylist(false));
      toaster.success(
        isDeleting
          ? 'Content deleted successfully'
          : 'Playlist updated successfully'
      );
      dispatch(playlistPageActions.showNewlyAssignedContentsInList(false));
      dispatch(playlistPageActions.disablePlaylistSaveAndCloseBtn(true));
      setIsLoading(false);
      setIsDeleting(false);
      dispatch(layoutActions.toggleModal());
      dispatch(playlistPageActions.clearAddContentToPlaylist());
      getPlaylist();
      if (
        !isDeleting &&
        contentToAddToPlaylist.length === 0 &&
        !isPlaylistSaveAndCloseBtnDisabled
      )
        navigate(ROUTES.playlists);
    } else {
      dispatch(playlistPageActions.invokeUpdatePlaylist(false));
      setIsLoading(false);
      dispatch(playlistPageActions.disablePlaylistSaveAndCloseBtn(false));
    }
  }, [
    playlistData,
    dispatch,
    companyData,
    contentToAddToPlaylist,
    isDeleting,
    getPlaylist,
    navigate,
    isPlaylistSaveAndCloseBtnDisabled,
  ]);

  useEffect(() => {
    if (canUpdatePlaylist) updatePlaylistHandler();
  }, [canUpdatePlaylist, updatePlaylistHandler]);

  useEffect(() => {
    return () => {
      dispatch(layoutActions.toggleSideDrawer());
    };
  }, [dispatch]);

  const confirmToDelete = useCallback(async () => {
    if (!playlistData) return;

    const response = await deletePlaylist(
      {
        id: playlistData.id,
        is_data: true,
      },
      setIsDeleting
    );
    if (response === DeleteStatus.Deleted) {
      toaster.success('Playlist deleted successfully');
      setIsPlaylistUsedSomeWhere(false);
      dispatch(playlistPageActions.setPlaylistsActiveFolder(null));
      dispatch(playlistPageActions.invokeDeletePlaylist(false));
      dispatch(layoutActions.toggleModal());
      navigate(ROUTES.playlists);
    }
  }, [dispatch, playlistData, navigate]);

  // const deletePlaylistHandler = useCallback(async () => {
  //   if (!playlistData) return;

  //   const response = await deletePlaylist(
  //     {
  //       id: playlistData.id,
  //       is_data: false,
  //     },
  //     setIsDeleting
  //   );
  //   if (response === DeleteStatus.Deleted) {
  //     toaster.success('Playlist deleted successfully');
  //     setIsPlaylistUsedSomeWhere(false);
  //     dispatch(layoutActions.toggleModal());
  //     navigate(ROUTES.playlists);
  //   }
  //   if (response === DeleteStatus.UsedSomewhere) {
  //     setIsPlaylistUsedSomeWhere(true);
  //   }
  // }, [dispatch, playlistData, navigate]);

  const deletePlaylistHandler = useCallback(async () => {
    if (!playlistData) return;

    const response = await deletePlaylist(
      {
        id: playlistData.id,
        is_data: false,
      },
      () => {}
    );
    if (response === DeleteStatus.UsedSomewhere) {
      if (setIsPlaylistUsedSomeWhere) setIsPlaylistUsedSomeWhere(true);
      dispatch(layoutActions.toggleModal(ModalViews.isShowDeletePlaylist));
    }
    if (response !== DeleteStatus.UsedSomewhere) {
      dispatch(layoutActions.toggleModal(ModalViews.isShowDeletePlaylist));
    }
    if (response === DeleteStatus.Deleted) {
      console.log('deleted');
    }
  }, [dispatch]);

  useEffect(() => {
    if (canDeletePlaylist) deletePlaylistHandler();
  }, [canDeletePlaylist]);

  return (
    <Layout variant={LayoutVariants.layoutOne}>
      <div className={styles.playlistView}>
        {isSuperAdmin && <RoleBasedHeader />}
        <MainHeader />
        <Header latePlaylistName={latePlaylistName} />
        <ClientsListDropdown className={styles.clientsListInLayout} />
        <div className={styles.mainContent}>
          <Subheader className={styles.mobileSubheader} />
          <div className={`${styles.colOne} ${styles[chosenView]}`}>
            <Subheader className={styles.desktopSubheader} />
            {isLoading ? (
              <Loader
                message="It is being processed, It may take some
                  time,  please wait!"
              />
            ) : (
              <div>
                {playListContents && playListContents?.length === 0 && (
                  <EmptyState />
                )}
                {playListContents && playListContents?.length > 0 && (
                  <PlaylistContent
                    data={playListContents}
                    setIsDeleting={setIsDeleting}
                  />
                )}
              </div>
            )}
          </div>
          <Details
            setLatePlaylistName={setLatePlaylistName}
            className={`${styles.detailView} ${styles[chosenView]} ${
              isLoading && styles.isLoading
            }`}
          />
          <AssignContentModal />
          <UploadContentModal />
        </div>
        <AddModal variant={AddModalVariants.DuplicatePlaylist} />
        <Footer onClickCancel={cancel} />
        {/* <DeleteModal
          isOpen={isShowDeletePlaylist}
          variant={DeleteModalVariants.Playlist}
          name={playlistData?.name || ''}
          isLoading={isDeleting}
          isUsedSomewhere={isPlaylistUsedSomeWhere}
          deleteHandler={() => deletePlaylistHandler(isPlaylistUsedSomeWhere)}
        /> */}

        <DeleteModal
          isOpen={isShowDeletePlaylist}
          variant={DeleteModalVariants.Playlist}
          name={playlistData?.name || ''}
          isLoading={isDeleting}
          // isUsedSomewhere={isPlaylistUsedSomeWhere}
          onClose={() => setIsPlaylistUsedSomeWhere(false)}
          confirmationMsg={
            isPlaylistUsedSomeWhere
              ? 'This Playlist is currently used on active screens.'
              : ''
          }
          usageNotification={
            isPlaylistUsedSomeWhere
              ? 'If you delete this Playlist these screens will be without content.'
              : ''
          }
          deleteHandler={
            // isPlaylistUsedSomeWhere ?
            confirmToDelete
            // : deletePlaylistHandler
          }
          displayInput={false}
        />

        {/* <DeleteModal
          isOpen={isShowDeletePlaylist}
          variant={DeleteModalVariants.Playlist}
          name={playlistDataToDelete?.name || ''}
          isLoading={isDeleting}
          // isUsedSomewhere={isPlaylistUsedSomeWhere}
          onClose={() => setIsPlaylistUsedSomeWhere(false)}
          confirmationMsg={
            isPlaylistUsedSomeWhere
              ? 'This Playlist is currently used on active screens.'
              : ''
          }
          usageNotification={
            isPlaylistUsedSomeWhere
              ? 'If you delete this Playlist these screens will be without content.'
              : ''
          }
          deleteHandler={
            // isPlaylistUsedSomeWhere ?
            () => confirmToDelete()
            //  : deletePlaylistHandler
          }
          displayInput={false}
        /> */}
      </div>
    </Layout>
  );
}

export default PlaylistView;

import React, { useCallback, useState, useEffect } from 'react';
import {
  activeFolderIcon,
  usersIcon,
  sharedUser,
  disable,
  caretFilledSmallDown,
} from 'constants/index';
import { useStore } from 'store';
import { toaster, uuid, validators } from 'helpers';
import AvatarGroup from 'components/avatar-group/avatarGroup';
import { iState } from 'types';
import { layoutActions } from 'store/actions';
import Modal, { ModalVariants } from 'components/modal/modal';
import { FolderType, itemType, ModalViews, UserRoles } from 'enums';
import Button, { ButtonVariants } from 'components/button/button';
import { getItemUsers, getUserByCompanyId, shareAccess } from 'apis';
import Popover from 'components/popover/popover';
import UsersList, { UsersListVariants } from './shared-with-list/usersList';
import styles from './shareAccess.module.scss';

interface iProps {
  className?: string;
  heading: string;
  shareType: itemType;
  shareItemId?: string;
  folderType?: FolderType;
}

function ShareAccess(props: iProps): JSX.Element {
  const { className, shareType, shareItemId, folderType, heading } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { isShowShareAccess } = state.layout || {};
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [sharedAccessWith, setSharedAccessWith] = useState<any>([]);
  const [isShowInviteUser, setIsShowInviteUser] = useState<boolean>(false);
  const [USERS, setUSERS] = useState<any>([]);
  const { companyData } = state.homePage || {};
  const { windowWidth } = state.layout || {};
  const { id: companyId } = companyData || {};
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sharedUsers] = useState<any>([]);
  const [sharedAccessUsers, setSharedAccessUsers] = useState<any>([]);
  const [isShowing, setIsShowing] = useState<boolean>(false);
  const [type, setType] = useState<any>('Can Edit');
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const { role } = state.user || {};

  useEffect(() => {
    if (searchKey && validators.isEmail(searchKey)) {
      const found = USERS.find((i) => i.email === searchKey);
      if (found) setIsShowInviteUser(false);
      else setIsShowInviteUser(false);
    } else setIsShowInviteUser(false);
  }, [searchKey]);

  const searchedUsers =
    (searchKey !== '' &&
      USERS.filter(
        (i) =>
          i.first_name.toLowerCase().includes(searchKey.toLowerCase()) ||
          i.last_name.toLowerCase().includes(searchKey.toLowerCase())
      )) ||
    [];

  const usersData =
    (searchKey !== '' && searchedUsers.length === 0 && []) ||
    (searchedUsers.length > 0 && searchedUsers) ||
    USERS;

  const chooseToShareAccess = useCallback(
    (user) => {
      console.log(
        sharedAccessUsers.map((item) => item.id).includes(user.companyUserId),
        sharedAccessUsers,
        user,
        'search'
      );
      const doesUserExist = sharedAccessWith
        .map((item) => item.companyUserId)
        .includes(user.companyUserId);
      if (!doesUserExist) {
        setSelectedUsers([...selectedUsers, user]);
        setSharedAccessWith([...sharedAccessWith, user]);
        const userArr = { id: user.companyUserId, accessType: 'EDIT' };
        setSharedAccessUsers([...sharedAccessUsers, userArr]);
      }
    },
    [sharedAccessUsers, sharedAccessWith]
  );

  const getItemsUsers = useCallback(async () => {
    const response = await getItemUsers(
      {
        companyId: companyId || '',
        folderType: folderType || null,
        itemId: shareItemId || '',
        itemType: shareType,
      },
      () => {}
    );
    if (!response) return;
    if (Array.isArray(response)) {
      setSharedAccessWith(response);
      setSharedAccessUsers(
        response?.map((user) => {
          return {
            id: user.companyUserId,
            accessType: 'EDIT',
          };
        })
      );
    }
  }, []);

  useEffect(() => {
    getItemsUsers();
  }, []);

  const inviteUserHandler = useCallback(() => {
    setSharedAccessWith([...sharedAccessWith, { email: searchKey }]);
    console.log('search', searchKey);
  }, [sharedAccessWith, searchKey]);

  const shareAccessToUsers = async (): Promise<void> => {
    const response = await shareAccess(
      {
        itemType: shareType,
        itemId: shareItemId,
        folderType,
        users: sharedAccessUsers,
      },
      setIsLoading
    );
    if (response) {
      setSelectedUsers([]);
      console.log(response, 'responsefrom share access');
      toaster.success('Access shared with the team member');
      dispatch(layoutActions.toggleModal());
    }
  };

  const getTeammemebers = useCallback(async () => {
    if (!companyId) return;
    const response = await getUserByCompanyId(
      { company: companyId },
      setIsLoading
    );
    if (response) {
      const parsed = response
        .filter((i) => i.invitedStatus === 'ACCEPTED')
        .map((i) => ({
          ...i,
          user: {
            name: i.first_name,
            lastName: i.last_name,
            email: i.email,
            profilePic: i.profile_pic,
          },
        }));
      setUSERS(parsed);
      // setFetchAgainTeammates(false);
    }
  }, [companyId]);

  useEffect(() => {
    if (windowWidth <= 900 && !isLoading) getTeammemebers();
  }, [windowWidth]);

  const closeToggle = useCallback(() => {
    const removeUnsavedUsers = sharedAccessWith.filter((user) => {
      const verifyUser = selectedUsers
        .map((item) => item.companyUserId)
        .includes(user.companyUserId);
      if (!verifyUser) return true;
      return false;
    });
    console.log(selectedUsers, 'removedUsers');
    setSharedAccessWith(removeUnsavedUsers);
    setSharedAccessUsers(
      removeUnsavedUsers.map((item) => {
        return { id: item.companyUserId, accessType: 'EDIT' };
      })
    );
    setSelectedUsers([]);
    dispatch(layoutActions.toggleModal());
  }, [sharedAccessUsers, sharedAccessWith]);

  const dropdownOptions = [
    {
      id: uuid(),
      option: 'Can Edit',
    },
    // {
    //   id: uuid(),
    //   option: 'Read',
    // },
  ];

  const isSuperUserAdmin = role === UserRoles.SuperAdmin;
  const disableShareBtn =
    !state.homePage.companyData?.features?.accessManagment;

  return (
    <>
      <div className={`${styles.shareWrapper} ${className}`}>
        <p className={styles.heading}>
          {heading}
          {/* Has access to the{' '}
          {(shareType === 'screen' && 'screen') ||
            (shareType === 'playlist' && 'playlist') ||
            (shareType === 'content' && 'folder')} */}
        </p>
        {Array.isArray(sharedAccessWith) && sharedAccessWith?.length === 0 && (
          <div className={styles.subHeading}>
            <img className={styles.usersIcon} src={sharedUser} alt="" />
            {sharedUsers &&
              sharedUsers?.map((user) => {
                return (
                  <img className={styles.usersIcon} src={user.pic} alt="" />
                );
              })}
            <p className={styles.subHeading}>No one has access</p>
          </div>
        )}

        {Array.isArray(sharedAccessWith) && sharedAccessWith?.length !== 0 && (
          <AvatarGroup
            className={styles.avatarGroup}
            data={sharedAccessWith || []}
            onClick={() => {
              if (
                isSuperUserAdmin ||
                state.homePage.companyData?.features?.accessManagment
              ) {
                dispatch(
                  layoutActions.toggleModal(ModalViews.isShowShareAccess)
                );
                getTeammemebers();
              }
            }}
          />
        )}
        <Button
          className={`${styles.disableBtn} ${
            disableShareBtn && !isSuperUserAdmin && styles.disable
          }`}
          btnName="Share"
          variant={ButtonVariants.SmallTransp}
          icon={disable}
          isDisabled={isSuperUserAdmin ? false : disableShareBtn}
          iconPosition="left"
          onClick={() => {
            dispatch(layoutActions.toggleModal(ModalViews.isShowShareAccess));
            getTeammemebers();
          }}
        />
      </div>
      <Modal
        isOpen={isShowShareAccess}
        variant={ModalVariants.Standard}
        headingIcon={activeFolderIcon}
        heading={heading}
        onClose={() => closeToggle()}
        submit={() => shareAccessToUsers()}
        btnName="Next"
      >
        <div className={styles.shareModal}>
          <div className={styles.searchUsers}>
            <div
              className={`${styles.searchDropdown} ${
                isFocused && styles.focused
              }`}
            >
              <input
                className={styles.search}
                type="text"
                value={searchKey}
                placeholder="Invite someone to this folder"
                onFocus={() => setIsFocused(true)}
                onBlur={() => {
                  setTimeout(() => {
                    setIsFocused(false);
                  }, 200);
                }}
                onChange={(event) => setSearchKey(event.target.value)}
              />
              <div className={styles.canEdit}>
                {/* <select name="cars" id="cars" className={styles.dropDown}>
                  <option value="can edit" className={styles.dropDown}>
                    Can Edit
                  </option>
                  <option value="read" className={styles.dropDown}>
                    Read
                  </option>
                </select> */}

                <Popover
                  setIsShowing={setIsShowing}
                  popoverClassName={styles.optionPopover}
                  popoverComponent={
                    <div>
                      {dropdownOptions.map((i) => (
                        <div
                          className={styles.logout}
                          onClick={() => {
                            if (i.option === 'Can Edit') setType('Can Edit');
                            if (i.option === 'Read') setType('Read');
                          }}
                        >
                          {/* <img src={i.icon} alt="" /> */}
                          <span>{i.option}</span>
                        </div>
                      ))}
                    </div>
                    // </>
                  }
                >
                  <div
                    className={`${className} ${styles.profile} ${
                      isShowing && styles.show
                    }`}
                  >
                    {type}{' '}
                    <img
                      className={styles.expandArrowDown}
                      src={caretFilledSmallDown}
                      alt=""
                    />
                  </div>
                </Popover>
              </div>
              {isFocused && (
                <UsersList
                  className={styles.foundList}
                  variant={UsersListVariants.ListToChoose}
                  data={usersData.filter(
                    (user) => user.id !== state.user.loggedInData?.id
                  )}
                  isShowInviteUser={isShowInviteUser}
                  onClick={chooseToShareAccess}
                  onClickInvite={inviteUserHandler}
                />
              )}
            </div>
            <div className={styles.accessMessage}>
              <div className={styles.iconBox}>
                <img className={styles.usersIcon} src={usersIcon} alt="" />
              </div>
              <p className={styles.message}>
                The following people has access to this folder
              </p>
            </div>
          </div>
          <UsersList
            variant={UsersListVariants.SharedWithList}
            data={sharedAccessWith}
          />
        </div>
      </Modal>
    </>
  );
}

ShareAccess.defaultProps = {
  className: '',
  shareItemId: null,
  folderType: null,
};

export default ShareAccess;

import React, { useCallback, useState, useEffect } from 'react';
import Input from 'components/input/input';
import { deleteFolder, updateFolder } from 'apis';
import { dustbinIcon } from 'constants/icons';
import Button, { ButtonVariants } from 'components/button/button';
import DeleteModal, {
  DeleteModalVariants,
} from 'components/delete-modal/deleteModal';
import { toaster } from 'helpers';
import { useStore } from 'store';
import { iState } from 'types';
import More from 'components/svg/more';
import Popover from 'components/popover/popover';
import {
  layoutActions,
  contentPageActions,
  playlistPageActions,
  homePageActions,
} from 'store/actions';
import { ModalViews, FolderType } from 'enums';
import { ROUTES } from 'constants/index';
import { CurrentFolderVariants } from './variants';
import styles from './currentFolder.module.scss';

interface iProps {
  className?: string;
  variant: CurrentFolderVariants;
}

function CurrentFolder(props: iProps): JSX.Element {
  const { className, variant } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [folderName, setFolderName] = useState<string>('');
  const [enableBtn, setEnableBtn] = useState(false);
  const { pathname } = window.location;
  const { contentActiveFolder, contentsFolderHierarchy } = state.contentPage;
  const { screensActiveFolder, screensFolderHierarchy } = state.homePage;
  const { playlistActiveFolder, playlistsFolderHierarchy } =
    state.playlistsPage;
  const { isShowDeleteFolder } = state.layout;
  const {
    deleteScreenAndFolder,
    deleteContentAndFolder,
    deletePlayListAndFolder,
  } = state.user.userPermissions || {};

  const { Screens, Contents, Playlists } = CurrentFolderVariants;

  const currentFolder =
    (variant === Screens && screensActiveFolder) ||
    (variant === Contents && contentActiveFolder) ||
    (variant === Playlists && playlistActiveFolder) ||
    null;

  const hierarchy =
    (variant === Screens && screensFolderHierarchy) ||
    (variant === Contents && contentsFolderHierarchy) ||
    (variant === Playlists && playlistsFolderHierarchy);

  useEffect(() => {
    if (currentFolder) {
      setFolderName(currentFolder.name);
    }
  }, [currentFolder]);

  const onChange = useCallback((event) => {
    setIsDisabled(false);
    setFolderName(event.target.value);
  }, []);

  const onClickSave = useCallback(async () => {
    if (!currentFolder) {
      toaster.error('Active folder not found');
      return;
    }
    const folderType =
      (variant === Screens && FolderType.SCREENZ) ||
      (variant === Playlists && FolderType.PLAYLIST) ||
      (variant === Contents && FolderType.CONTENT);
    if (!folderType) return;

    const response = await updateFolder({
      name: folderName,
      id: currentFolder.id,
      foldertype: folderType,
    });
    if (response) {
      setIsDisabled(true);
      if (screensActiveFolder && variant === Screens) {
        dispatch(
          homePageActions.setScreensActiveFolder({
            ...screensActiveFolder,
            name: folderName,
          })
        );
      }
      if (contentActiveFolder && variant === Contents) {
        dispatch(
          contentPageActions.setContentActiveFolder({
            ...contentActiveFolder,
            name: folderName,
          })
        );
      }
      if (playlistActiveFolder && variant === Playlists) {
        dispatch(
          playlistPageActions.setPlaylistsActiveFolder({
            ...playlistActiveFolder,
            name: folderName,
          })
        );
      }
    }
  }, [
    folderName,
    Contents,
    Playlists,
    Screens,
    currentFolder,
    variant,
    dispatch,
    playlistActiveFolder,
    contentActiveFolder,
    screensActiveFolder,
  ]);

  const onClickDelete = useCallback(() => {
    dispatch(layoutActions.toggleModal(ModalViews.isShowDeleteFolder));
  }, [dispatch]);

  const getActiveFolder = useCallback(() => {
    const activeFolder: any =
      (Array.isArray(hierarchy) &&
        hierarchy.length > 0 && { ...hierarchy[hierarchy.length - 1] }) ||
      null;

    if (variant === Screens)
      dispatch(homePageActions.setScreensActiveFolder(activeFolder));

    if (variant === Contents)
      dispatch(contentPageActions.setContentActiveFolder(activeFolder));

    if (variant === Playlists)
      dispatch(playlistPageActions.setPlaylistsActiveFolder(activeFolder));
    return activeFolder;
  }, [hierarchy, Contents, Playlists, Screens, dispatch, variant]);

  const deleteFolderHandler = useCallback(async () => {
    if (!currentFolder) {
      toaster.error('Folder data found');
      return;
    }
    const { id = '' } = currentFolder || {};
    const response = await deleteFolder(id, setIsDeleting);
    if (response) {
      dispatch(layoutActions.toggleModal(ModalViews.isShowDeleteFolder));
      getActiveFolder();
    }
  }, [currentFolder, dispatch, getActiveFolder]);

  const handleDeleteBtn = useCallback(() => {
    if (pathname === ROUTES.screenz && deleteScreenAndFolder) {
      setEnableBtn(true);
    } else if (pathname === ROUTES.content && deleteContentAndFolder) {
      setEnableBtn(true);
    } else if (pathname === ROUTES.playlists && deletePlayListAndFolder) {
      setEnableBtn(true);
    }
  }, [
    deleteContentAndFolder,
    deletePlayListAndFolder,
    deleteScreenAndFolder,
    pathname,
  ]);

  useEffect(() => {
    handleDeleteBtn();
  }, [handleDeleteBtn]);

  return (
    <div className={`${styles.currentFolder} ${className}`}>
      <div className={styles.sectionHeading}>
        <p className={styles.heading}>Folder Name</p>
        {/* {enableBtn === true ? ( */}
        <Popover
          className={styles.morePopupContainer}
          popoverClassName={`${styles.popover}`}
          popoverComponent={
            <div className={`${styles.morePopup} ${styles.webContentMutation}`}>
              <div
                className={`${styles.option} ${!enableBtn && styles.disabled}`}
                onClick={() => {
                  if (enableBtn) {
                    onClickDelete();
                    return;
                  }
                  toaster.error('You have no access to delete');
                }}
              >
                <img src={dustbinIcon} alt="" />
                <p>Delete</p>
              </div>
            </div>
          }
        >
          <div className={styles.moreBtn}>
            <More className={styles.icon} />
          </div>
        </Popover>
        {/* ) : null} */}
      </div>
      <div className={styles.inputAndSaveBtn}>
        <Input
          className={styles.folderNameInput}
          value={folderName || ''}
          onChange={onChange}
        />
        <Button
          className={styles.saveBtn}
          variant={ButtonVariants.ChangeView}
          btnName="Save"
          isDisabled={isDisabled}
          onClick={onClickSave}
        />
      </div>
      {currentFolder && (
        <DeleteModal
          isOpen={isShowDeleteFolder}
          name={currentFolder.name || ''}
          variant={DeleteModalVariants.Folder}
          isLoading={isDeleting}
          deleteHandler={deleteFolderHandler}
        />
      )}
    </div>
  );
}

CurrentFolder.defaultProps = {
  className: '',
};
export { CurrentFolderVariants };
export default CurrentFolder;

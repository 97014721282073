import React, { useCallback, useState } from 'react';
import { caretFilledSmallDown } from 'constants/index';
import Avatar, { AvatarVariants } from 'components/avatar/avatar';
import { uuid } from 'helpers';
import Popover from 'components/popover/popover';
import InviteUser from '../invite-user/inviteUser';

import { UsersListVariants, Variants } from './variant';

import styles from './usersList.module.scss';

interface iProps {
  className?: string;
  data: any;
  variant: UsersListVariants;
  isShowInviteUser?: boolean;
  onClick?: (_: any) => void;
  onClickInvite?: () => void;
}

function UsersList(props: iProps): JSX.Element {
  const {
    className,
    data,
    variant,
    isShowInviteUser = false,
    onClick,
    onClickInvite,
  } = props;
  const { showEmail, showOwnerTag, showInviteUser, showEdit } =
    Variants[variant] || {};

  const [type, setType] = useState<any>('Remove');
  const [isShowing, setIsShowing] = useState<boolean>(false);

  const USER_ID = 1;

  const onClickInviteHandler = useCallback(() => {
    if (onClickInvite) onClickInvite();
  }, [onClickInvite]);

  const dropdownOptions = [
    {
      id: uuid(),
      option: 'Remove',
    },
    // {
    //   id: uuid(),
    //   option: 'Read',
    // },
  ];

  return (
    <div
      className={`${styles.usersList} ${
        !isShowInviteUser && styles[variant]
      } ${className}`}
    >
      {isShowInviteUser && showInviteUser && (
        <InviteUser onClickInvite={onClickInviteHandler} />
      )}

      {showInviteUser &&
        Array.isArray(data) &&
        data?.map((user) => (
          <div
            className={styles.avatarAndName}
            onClick={() => onClick && onClick(user)}
          >
            {showEmail ? (
              <div className={styles.avatarWithEmail}>
                <div>
                  <Avatar
                    variant={AvatarVariants.Small}
                    firstName={user.first_name}
                    lastName={user.last_name}
                    profilePic={user.profile_pic}
                  />
                </div>

                <div>
                  <p className={styles.name}>
                    {user.first_name} {user.last_name}
                  </p>
                  <p className={styles.email}>{user.email}</p>
                </div>
              </div>
            ) : (
              <>
                <Avatar
                  variant={AvatarVariants.Small}
                  firstName={user.first_name}
                  lastName={user.last_name}
                  profilePic={user.profile_pic}
                />
                <p className={styles.name}>
                  {user.first_name} {user.last_name} {user.id === 1 && '(You)'}
                </p>
              </>
            )}

            {user.id === USER_ID && showOwnerTag && (
              <p className={styles.owner}>Owner</p>
            )}
            {user.id !== USER_ID && showEdit && (
              <div className={styles.canEdit}>
                <Popover
                  setIsShowing={setIsShowing}
                  popoverClassName={styles.optionPopover}
                  popoverComponent={
                    <div>
                      {dropdownOptions.map((i) => (
                        <div
                          className={styles.logout}
                          onClick={() => {
                            // if (i.option === 'Can Edit') setType('Can Edit');
                            // if (i.option === 'Read') setType('Read');
                            if (i.option === 'Remove') setType('Remove');
                          }}
                        >
                          {/* <img src={i.icon} alt="" /> */}
                          <span>{i.option}</span>
                        </div>
                      ))}
                    </div>
                    // </>
                  }
                >
                  <div
                    className={`${className} ${styles.profile} ${
                      isShowing && styles.show
                    }`}
                  >
                    {type}{' '}
                    <img
                      className={styles.expandArrowDown}
                      src={caretFilledSmallDown}
                      alt=""
                    />
                  </div>
                </Popover>
              </div>
            )}
          </div>
        ))}
    </div>
  );
}

UsersList.defaultProps = {
  className: '',
  isShowInviteUser: false,
  onClick: () => {},
  onClickInvite: () => {},
};

export { UsersListVariants };
export default UsersList;

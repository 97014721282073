import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { StoreProvider } from 'store';
import { initialState } from 'store/initialState/initialState';
import { rootReducer } from 'store/reducers/rootReducer';
import OfflineDetector from 'components/offlineDetector/offlineDetector';
import Routes from './routes/routes';
import './styles/colors.css';
import './styles/common.styles.css';
import './styles/fonts.scss';
import './styles/global.styles.css';

function App(): JSX.Element {
  useEffect(() => {
    const variable = localStorage.getItem('isLoggedIn');
    if (!variable) {
      window.Intercom('boot', {
        app_id: 'd7jw43br',
      });
    }
  }, []);
  return (
    <div
      className="App"
      // onTouchMove={(event) => {
      //   event.preventDefault();
      // }}
    >
      <OfflineDetector>
        <Toaster
          containerStyle={{ zIndex: '100000000000000' }}
          toastOptions={{ className: 'toasterStyles' }}
        />
        <StoreProvider initialState={initialState} reducer={rootReducer}>
          <Routes />
        </StoreProvider>
      </OfflineDetector>
    </div>
  );
}

export default App;

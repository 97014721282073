import { gql } from 'apollo-boost';

export enum O365 {
  AddOrUpdateMSAccount = 'addOrUpdateMSAccount',
  GetMsAccounts = 'getMsAccounts',
  DeleteMsAccount = 'deleteMsAccount',
}

export const addOrUpdateMSAccountMutation = gql`
    mutation ${O365.AddOrUpdateMSAccount}(
        $id: String
        $name: String!
        $companyId: String!
        $mutationType: MutationType!
        $companyUserId: String
        $email: String!
        $msId: String!
        $accessToken: String!
        $refreshToken: String!
        $accountDetails: MSAccountDetails
    ){
        ${O365.AddOrUpdateMSAccount}(
            id: $id
            name: $name
            msId: $msId
            companyId: $companyId
            mutationType: $mutationType
            companyUserId: $companyUserId
            email: $email
            accessToken: $accessToken
            refreshToken: $refreshToken
            accountDetails: $accountDetails
        ){
            message
            statusCode
            data {
                id
                name
                email
                accessToken
                msId
                created_at
                updated_at
                accountDetails {
                    environment
                }
            }
        }
    }
`;

export const getMsAccountsQuery = gql`
    query ${O365.GetMsAccounts}(
        $compannyId: String!
    ){
        ${O365.GetMsAccounts}(
            compannyId: $compannyId
        ){
            statusCode
            message
            data{
              id
              name
              email
              accessToken
              inUse
              msId
              created_at
              updated_at
              accountDetails{
                environment
                homeAccountId
                idTokenClaims {
                    aio
                    aud
                    exp
                    iat
                    iss
                    name
                    nbf
                    nonce
                    oid
                    preferred_username
                    rh
                    sub
                    tid
                    uti
                    ver
                }
                localAccountId
                name
                nativeAccountId
                tenantId
                username
              }
            }
        }
    }
`;

export const deleteRoomsMutation = gql`
    mutation ${O365.DeleteMsAccount}(
        $id: String! 
    ){
        ${O365.DeleteMsAccount}(
            id: $id
        ){
            statusCode
            message
            data{
            isDeleted
            }
        }
    }
`;

import { addContent } from 'apis';
import Button, { ButtonVariants } from 'components/button/button';
import FileUploadProgress, {
  FileUploadProgressVariants,
} from 'components/file-upload-progress/fileUploadProgress';
import Modal from 'components/modal/modal';
import {
  cloudUpload2Icon,
  CONTENT_TYPE,
  uploadContentIcon,
} from 'constants/index';
import { s3Operations, toaster, uuid } from 'helpers';
// import { LayoutViews, ModalViews } from 'enums';
import React, { useCallback, useEffect, useState } from 'react';
// import ImageUploading from 'react-images-uploading';
import { useStore } from 'store';
import {
  contentPageActions,
  // homePageActions,
  layoutActions,
} from 'store/actions';
import { iState, iUploadingFile } from 'types';
// import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import styles from './uploadContentModal.module.scss';

// import upload from 'components/svg/upload';

function UploadContent(): JSX.Element {
  const [state, dispatch]: [iState, any] = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { layout, contentPage, homePage, user } = state || {};
  const { isShowAddContent } = layout || {};
  const { uploadingFiles, contentActiveFolder } = contentPage || {};
  const { companyData } = homePage || {};
  const { length: uploadingFilesLenght } = uploadingFiles || {};

  // const addClient = useCallback(() => {
  //   if (layout.chosenView === LayoutViews.Screens)
  //     dispatch(homePageActions.addScreen());
  //   dispatch(layoutActions.toggleModal(ModalViews.isShowAddScreen));
  // }, [dispatch, layout.chosenView]);

  useEffect(() => {
    dispatch(contentPageActions.clearUploadingFiles());
  }, []);

  // const videoResolution = (file: File): any => {
  //   return new Promise((resolve) => {
  //     if (file.type.includes('image')) {
  //     }
  //     if (file.type.includes('video')) {
  //       const url = URL.createObjectURL(file);
  //       const video = document.createElement('video');
  //       video.src = url;
  //       video.addEventListener('loadedmetadata', function () {
  //         //  console.log('width:', video.videoWidth);
  //         //  console.log('height:', height);
  //         resolve(`${video.videoWidth}*${video.videoHeight}`);
  //       });
  //     }
  //   });
  // };

  // const ffmpeg = createFFmpeg({
  //   log: true,
  // });

  // const convertToMp4 = async (file): Promise<any> => {
  //   await ffmpeg.load();
  //   ffmpeg.FS('writeFile', `${file.name}`, await fetchFile(file));
  //   await ffmpeg.run('-i', `${file.name}`, `${file.name}.mp4`);
  //   const data = ffmpeg.FS('readFile', `${file.name}.mp4`);
  //   console.log(
  //     URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }))
  //   );
  // };

  const getDimention = (file): Promise<any> => {
    return new Promise((resolve) => {
      if (file.type.includes('image')) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);
        img.onload = () => {
          resolve(`${img.width}*${img.height}`);
          URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;
      }
      if (file.type.includes('video')) {
        // if (file.name.includes('mov')) {
        //   convertToMp4(file);
        // }
        const url = URL.createObjectURL(file);
        const video = document.createElement('video');
        video.src = url;
        console.log(url, 'Uploaded video details');
        video.addEventListener('loadedmetadata', () => {
          // if (
          //   video.videoHeight === 0 ||
          //   video.videoWidth === 0 ||
          //   video.videoWidth > 1920 ||
          //   video.videoHeight > 1080
          // ) {
          //   toaster.error(
          //     `${file.name} doesn't fit our uploading criteria. Please check the file.`
          //   );
          // }
          // const inputVideoRatio = (
          //   video.videoWidth / video.videoHeight
          // ).toFixed(2);
          // const standardVideoRatio = (1920 / 1080).toFixed(2);
          // if (inputVideoRatio !== standardVideoRatio) {
          //   toaster.error(
          //     `${file.name} doesn't fit our uploading criteria. Please check the file.`
          //   );
          // }
          // if (video.videoWidth !== 0 && video.videoHeight !== 0) {
          // if (video.videoWidth < 1921 && video.videoHeight < 1081) {
          // if (inputVideoRatio === standardVideoRatio) {
          // console.log(video, file.size, 'Uploaded video details');
          resolve(`${video.videoWidth}*${video.videoHeight}`);
          // }
          // }
          // }
        });
        // resolve(`not available for this Format`);
      }
    });
  };

  const onFileChoose = useCallback(
    async (event) => {
      const { files = [] } = event.target;
      console.log(files, 'files');
      const filesWithDimensions = await Promise.all(
        [...files].map(async (i: File) => {
          return {
            file: i,
            dimension: await getDimention(i),
          };
        })
      );
      dispatch(
        contentPageActions.uploadFiles(
          filesWithDimensions
            .filter((i) => {
              console.log(i.file.type, 'i');
              if (i.file.type.includes('video')) {
                // const fileSize = i.dimension.split('*');
                // const width = Number(fileSize[0]);
                // const height = Number(fileSize[1]);
                // if (
                // height === 0 ||
                // width === 0 ||
                // width > 1920 ||
                // height > 1080
                // ) {
                //   toaster.error(
                //     `${i.file.name} doesn't fit our uploading criteria. Please check the file.`
                //   );
                //   // return false;
                // }
                // const inputVideoRatio = (width / height).toFixed(2);
                // const standardVideoRatio = (1920 / 1080).toFixed(2);
                // if (inputVideoRatio !== standardVideoRatio) {
                //   toaster.error(
                //     `${i.file.name} doesn't fit our uploading criteria. Please check the file.`
                //   );
                //   // return false;
                // }
                // if (width !== 0 && height !== 0) {
                // if (width < 1921 && height < 1081) {
                if (i.file.size <= 1024 * 1024 * 1024) {
                  // if (inputVideoRatio === standardVideoRatio) {
                  return true;
                  // }
                }
                // }
                // }
                // if (
                //   Number((width / height).toPrecision(2)) ===
                //     Number((16 / 9).toPrecision(2)) ||
                //   Number((width / height).toPrecision(2)) ===
                //     Number((16 / 10).toPrecision(2)) ||
                //   Number((width / height).toPrecision(2)) ===
                //     Number((5 / 4).toPrecision(2)) ||
                //   Number((width / height).toPrecision(2)) ===
                //     Number((4 / 3).toPrecision(2)) ||
                //   i.file.type === 'video/mp4' ||
                //   i.file.type === 'video/WebM'
                // ) {
                //   return true;
                // }
                if (i.file.size > 1024 * 1024 * 1024) {
                  toaster.error(
                    // `File ${i.file.name} does not fit our criteria`
                    `Sorry, cannot proceed as ${i.file.name} file size is beyond 1GB`
                  );
                  return false;
                }
                // if (
                //   height === 0 ||
                //   width === 0 ||
                //   width > 1920 ||
                //   height > 1080 ||
                //   inputVideoRatio !== standardVideoRatio
                // ) {
                //   toaster.error(
                //     // `File ${i.file.name} does not fit our criteria`
                //     `Sorry, cannot proceed as ${i.file.name} file resolution is not 1920 x 1080`
                //   );
                //   return false;
                // }
                return false;
              }
              return true;
            })
            .map((i) => {
              return {
                id: uuid(),
                dimension: i.dimension,
                progress: 0,
                file: i.file,
                isUploaded: false,
                url: '',
              };
            })
        )
      );
    },
    [dispatch]
  );

  const addContentHandler = useCallback(
    async (
      contentsToAdd: iUploadingFile,
      index: number
    ): Promise<null | undefined> => {
      const { id: companyId = '' } = companyData || {};
      const contentType =
        (contentsToAdd.file.type.includes('image') && CONTENT_TYPE.Image) ||
        (contentsToAdd.file.type.includes('video') && CONTENT_TYPE.Video) ||
        '';
      const response = await addContent(
        {
          name: contentsToAdd.file.name,
          url: contentsToAdd.url,
          contentType,
          size: `${contentsToAdd.file.size}`,
          dimension: contentsToAdd.dimension,
          folder: contentActiveFolder?.id || '',
          thumbnailPath: '',
          company: companyId,
          user: user.loggedInData ? user.loggedInData?.id : '',
        },
        () => {}
      );

      if (response && index === uploadingFilesLenght - 1) {
        dispatch(layoutActions.toggleModal());
        dispatch(contentPageActions.clearUploadingFiles());
        setIsLoading(false);
        toaster.success('Content added successfully');
        dispatch(contentPageActions.fetchContentsData(true));
      }

      return null;
    },
    [
      companyData,
      dispatch,
      uploadingFilesLenght,
      user.loggedInData,
      contentActiveFolder?.id,
    ]
  );

  const uploadFileHandler = useCallback(async () => {
    const { id: companyId = '' } = companyData || {};
    if (!companyId) {
      toaster.error('Company details not found');
      return;
    }

    setIsLoading(true);
    let count = 0;
    // eslint-disable-next-line
    for (let i of uploadingFiles) {
      if (!i.isUploaded) {
        const response: any = await s3Operations.upload({
          params: {
            Key: i.file.name,
            Body: i.file,
          },
          companyId: companyData?.id,
          uploadType: 'content',
          progress: (progress) => {
            dispatch(
              contentPageActions.updateUploadStatus({
                ...i,
                progress,
              })
            );
          },
        });

        if (response) {
          dispatch(
            contentPageActions.updateUploadStatus({
              ...i,
              progress: 100,
              url: response.Location,
              isUploaded: true,
            })
          );
          await addContentHandler({ ...i, url: response.Location }, count);
          count += 1;
        }
      }
    }
  }, [uploadingFiles, dispatch, companyData, addContentHandler]);

  const removeFileFromUpload = useCallback(
    (index) => {
      dispatch(contentPageActions.removeFile(index));
    },
    [dispatch]
  );

  return (
    <Modal
      isOpen={isShowAddContent}
      heading="Upload content"
      btnName="Upload"
      isLoading={isLoading}
      headingIcon={cloudUpload2Icon}
      isBtnDisabled={uploadingFilesLenght === 0}
      onClose={() => dispatch(layoutActions.toggleModal())}
      submit={uploadFileHandler}
    >
      <div className={styles.uploadContent}>
        <div
          className={`${styles.fileUploadArea} ${
            uploadingFilesLenght === 0 && styles.initialState
          }`}
        >
          <input
            className={uploadingFilesLenght === 0 && styles.initialState}
            type="file"
            accept="image/png, image/jpg, image/jpeg, video/mp4, video/WebM, video/quicktime, video/x-m4v, video/wmv, video/mkv, video/x-matroska, .mkv, video/avi, video/x-msvideo, video/x-ms-wmv, .mov, video/mpeg, .3gp, video/ogg"
            multiple
            onChange={onFileChoose}
          />
          <img
            className={styles.uploadContentIcon}
            src={uploadContentIcon}
            alt=""
          />
          <div>
            <p className={styles.description}>
              Drag your photos or video here to start uploading,
            </p>
            <p className={styles.descriptionTwo}>
              Note:
              {/* Please upload the videos of standard resolution(i.e 1920 x
              1024) and */}
              Maximum size of the video allowed is 1GB.
            </p>
          </div>

          <Button
            className={styles.browseButton}
            variant={ButtonVariants.MediumStd}
            btnName="Browse files"
          />
        </div>
        {/* <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          dataURLKey="data_url"
          resolutionType="ratio"
          resolutionWidth={16 || 5 || 4}
          acceptType={['png', 'jpeg', 'jpg']}
          resolutionHeight={9 || 10 || 4 || 3}
        >
          {({
            // imageList,
            onImageUpload,
            // onImageRemoveAll,
            // onImageUpdate,
            // onImageRemove,
            isDragging,
            dragProps,
            errors,
          }) => {
            console.log(errors, 'hi');
            return (
              // write your building UI
              <div className="upload__image-wrapper">
                <button
                  type="button"
                  style={isDragging ? { color: 'red' } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Click or Drop here
                </button>
                &nbsp;
                {errors && (
                  <div>
                    {errors.resolution && <span>hi</span>}
                    {errors.acceptType && <span>hello</span>}
                  </div>
                )}
              </div>
            );
          }}
        </ImageUploading> */}
        {uploadingFilesLenght > 0 && (
          <p className={styles.uploadingMessage}>
            <img
              className={styles.cloudUpload2Icon}
              src={cloudUpload2Icon}
              alt=""
            />
            <span>The following files are uploading</span>
          </p>
        )}
        <ul
          className={`${styles.filesUploading} ${
            uploadingFilesLenght > 0 && styles.postInitialState
          }`}
        >
          {uploadingFiles.map((i, index): JSX.Element => {
            // if (!i.isUploaded) return <div />;
            return (
              <FileUploadProgress
                variant={FileUploadProgressVariants.InModal}
                cancel={() => removeFileFromUpload(index)}
                data={i}
              />
            );
          })}
        </ul>
      </div>
    </Modal>
  );
}

export default UploadContent;

import { tableCheckIcon } from 'constants/index';
import React, { useCallback } from 'react';
import styles from './checkbox.module.scss';

interface iProps {
  // className?: string;
  isChecked?: boolean;
  onClick?: () => void;
}

function Checkbox(props: iProps): JSX.Element {
  const {
    // className,
    isChecked,
    onClick,
  } = props;

  const onClickHandler = useCallback(() => {
    // event.stopPropagation();
    if (onClick) onClick();
  }, [onClick]);

  return isChecked ? (
    <img
      onClick={onClickHandler}
      className={styles.ellipseFill}
      src={tableCheckIcon}
      alt="im"
    />
  ) : (
    <div onClick={onClickHandler} className={styles.ellipseEmpty} />
  );
}

Checkbox.defaultProps = {
  // className: '',
  isChecked: false,
  onClick: () => {},
};

export default Checkbox;

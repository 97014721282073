import {
  getAllFolders,
  getAllPlaylists,
  getContentsByCompany,
  getFolderById,
} from 'apis';
import Button, { ButtonVariants } from 'components/button/button';
import ClientNameAndFolderPath from 'components/client-name-and-folder-path/clientNameAndFolderPath';
import Folders from 'components/folders/folders';
import Modal, { ModalVariants } from 'components/modal/modal';
import PlaylistTable from 'components/playlists/playlist-table/playlistTable';
import Playlists, {
  PlaylistVariants,
} from 'components/playlists/playlists/playlists';
import Search from 'components/search/search';
import { arrowRightWhiteIcon, ROUTES, TEMP_COLUMNS } from 'constants/index';
import { ContentPermissions, FolderType, PlayListPermissions } from 'enums';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from 'store';
import {
  contentPageActions,
  homePageActions,
  layoutActions,
  playlistPageActions,
} from 'store/actions';
import { iState } from 'types';
import styles from './assignContentModal.module.scss';
import ContentList from './content-list/contentList';
import PlaylistContentPopover from './playlist-content-popover/playlistContentPopover';

interface iProps {
  className?: string;
}

const PLAYLIST = 'Playlists';
const CONTENT = 'Contents';
const TEMPLATE = 'Templates';

function AssignContentModal(props: iProps): JSX.Element {
  const { className } = props;
  const { pathname } = useLocation();
  const [searchKey, setSearchKey] = useState<string>('');
  const [view, setView] = useState(
    (pathname === ROUTES.playlist && CONTENT) || PLAYLIST
  );
  const [state, dispatch]: [iState, any] = useStore();
  const { contentToAddToPlaylist, playlists } = state.playlistsPage || {};
  const { userPermissions } = state.user || {};
  const {
    assignModalContentActiveFolder,
    assignModalContentFolders,
    isShowAssignContentOrPlaylist,
    invokeGetAllFolders,
  } = state.layout || {};
  const { contents } = state.contentPage || {};
  const { companyData, contentToAssignScreen, playlistToAssignScreen } =
    state.homePage || {};

  const { id: folderId } = assignModalContentActiveFolder || {};
  const { id: companyId, companyUserId = '' } = companyData || {};

  const [checked, setChecked] = useState({
    showAll: false,
    image: false,
    videos: false,
    webPages: false,
    templates: false,
  });

  useEffect(() => {
    dispatch(homePageActions.setContentToAssignToScreen(''));
    dispatch(homePageActions.setPlaylistToAssignToScreen(''));
  }, []);

  const getContents = useCallback(async () => {
    if (!companyId) return;
    const response = await getContentsByCompany({
      company: companyId,
      folder: folderId || '',
      companyUserId: companyUserId || null,
    });
    if (response) {
      dispatch(contentPageActions.setContents(response));
    }
  }, [companyUserId, companyId, dispatch, folderId]);

  useEffect(() => {
    if (isShowAssignContentOrPlaylist) getContents();
    else {
      setSearchKey('');
    }
  }, [folderId, getContents, isShowAssignContentOrPlaylist]);

  const clear = useCallback(() => {
    // dispatch(homePageActions.setScreensActiveFolder(null));
    // dispatch(homePageActions.setScreensFolderHierarchy([]));
    // dispatch(contentPageActions.setContentActiveFolder(null));
    // dispatch(contentPageActions.setContentFolderHierarchy([]));
    // dispatch(playlistPageActions.setPlaylistsActiveFolder(null));
    // dispatch(playlistPageActions.setPlaylistsFolderHierarchy([]));
    dispatch(layoutActions.setAssignModalContentActiveFolders(null));
    dispatch(layoutActions.setAssignModalContentsFolderHierarchy([]));
    dispatch(layoutActions.setAssignModalContentActiveFolders(null));
    dispatch(layoutActions.setAssignModalContentFolders([]));
    dispatch(layoutActions.setAssignModalContentsFolderHierarchy([]));
  }, [dispatch]);

  const assignHandler = useCallback(() => {
    clear();
    if (pathname === ROUTES.playlist) {
      dispatch(playlistPageActions.showNewlyAssignedContentsInList(true));
      dispatch(playlistPageActions.disablePlaylistSaveAndCloseBtn(false));
      dispatch(layoutActions.setAssignModalContentActiveFolders(null));
      dispatch(contentPageActions.setContents([]));
      dispatch(layoutActions.toggleModal());
      return;
    }
    if (pathname === ROUTES.screenDetails) {
      dispatch(homePageActions.invokeUpdateScreen(true));
      dispatch(layoutActions.setAssignModalContentActiveFolders(null));
    }
  }, [dispatch, pathname]);

  const getFoldersByIdHandler = useCallback(
    async (activeFolderId) => {
      if (!isShowAssignContentOrPlaylist) {
        return;
      }
      if (!activeFolderId) {
        return;
      }
      const response = await getFolderById({ id: activeFolderId }, () => {});
      if (response && response.subFolders) {
        const { subFolders, parentFolders } = response;
        dispatch(homePageActions.setIsFetchingFolders(false));
        dispatch(layoutActions.setAssignModalContentFolders(subFolders || []));
        dispatch(
          layoutActions.setAssignModalContentsFolderHierarchy(
            [...parentFolders].reverse()
          )
        );
      }
    },
    [dispatch, isShowAssignContentOrPlaylist]
  );

  const getAllFoldersHandler = useCallback(async () => {
    if (!companyId) {
      // toaster.error('Company data not found');
      return;
    }
    const folderType =
      (view === CONTENT && FolderType.CONTENT) ||
      (view === PLAYLIST && FolderType.PLAYLIST);
    if (!folderType) return;

    const response = await getAllFolders(
      {
        companyId,
        companyUserId: companyUserId || '',
        folderType,
      },
      () => {}
    );

    if (response) {
      dispatch(layoutActions.setAssignModalContentFolders(response));
      dispatch(layoutActions.invokeGetAllFolders(false));
    }
  }, [companyId, dispatch, view]);

  useEffect(() => {
    if (isShowAssignContentOrPlaylist && !folderId) getAllFoldersHandler();
  }, [
    getAllFoldersHandler,
    isShowAssignContentOrPlaylist,
    folderId,
    view,
    invokeGetAllFolders,
  ]);

  useEffect(() => {
    getFoldersByIdHandler(folderId);
  }, [folderId, getFoldersByIdHandler]);

  const getPlaylists = useCallback(async () => {
    if (!companyId) return;

    const response = await getAllPlaylists(
      {
        companyId,
        folderId: folderId || null,
      },
      () => {}
    );
    if (response) {
      dispatch(playlistPageActions.setPlaylist(response));
      dispatch(playlistPageActions.fetchPlaylistData(false));
    }
  }, [companyId, dispatch, folderId]);

  useEffect(() => {
    if (isShowAssignContentOrPlaylist) getPlaylists();
  }, [getPlaylists, isShowAssignContentOrPlaylist]);

  const onCancel = useCallback(() => {
    clear();
    dispatch(layoutActions.toggleModal());
    dispatch(layoutActions.setAssignModalContentActiveFolders(null));
    dispatch(layoutActions.setAssignModalContentFolders([]));
    dispatch(homePageActions.setContentToAssignToScreen(''));
    dispatch(homePageActions.setPlaylistToAssignToScreen(''));
    dispatch(playlistPageActions.clearAddContentToPlaylist());
    dispatch(contentPageActions.setContents([]));
    setSearchKey('');
  }, [dispatch]);

  const setFilterToInitialStates = useCallback(() => {
    setChecked({
      showAll: false,
      image: false,
      videos: false,
      webPages: false,
      templates: false,
    });
  }, []);
  const playlistData =
    searchKey !== ''
      ? playlists.filter((i) =>
          i.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
        )
      : playlists;

  const contentsData =
    searchKey !== ''
      ? contents.filter((i) =>
          i.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
        )
      : contents;

  const { playListAccess, contentAcces } = userPermissions || {};

  return (
    <Modal
      onSearch={setSearchKey}
      isOpen={isShowAssignContentOrPlaylist}
      variant={
        view === PLAYLIST
          ? ModalVariants.AddContentPlaylist
          : ModalVariants.AddContentToPlaylist
      }
      className={styles.addContentModal}
      btnName="Assign content"
      heading={<PlaylistContentPopover parentView={view} setView={setView} />}
      submitBtn={
        <Button
          className={styles.assignContentBtn}
          variant={ButtonVariants.SmallStd}
          btnName="Assign content"
          icon={arrowRightWhiteIcon}
          iconPosition="right"
          // isDisabled={
          //   contentToAssignScreen !== '' || contentToAddToPlaylist?.length === 0
          // }
          isDisabled={
            contentToAssignScreen === '' &&
            playlistToAssignScreen === '' &&
            contentToAddToPlaylist?.length === 0
          }
          onClick={assignHandler}
        />
      }
      searchKeyword={searchKey}
      checked={checked}
      setChecked={setChecked}
      submit={() => {}}
      onClose={onCancel}
    >
      <div className={`${className} ${styles.assignContentWrapper}`}>
        <div className={styles.searchBar}>
          <Search isOpenState onChange={setSearchKey} />
        </div>
        {/* {assignModalContentFolders?.length > 0 && ( */}
        <ClientNameAndFolderPath
          className={styles.clientNameAndFolderName}
          username="All Folders"
          setChecked={setFilterToInitialStates}
        />
        {/* // )} */}
        {assignModalContentFolders?.length > 0 && view === CONTENT && (
          <Folders
            className={styles.folderListing}
            variant="small"
            data={assignModalContentFolders}
            isHavingAllAccess={
              view === CONTENT &&
              contentAcces === ContentPermissions.AllContents
            }
            openFolder={(folder) => {
              dispatch(homePageActions.setIsFetchingFolders(true));
              dispatch(
                layoutActions.setAssignModalContentActiveFolders(folder)
              );
            }}
            setChecked={setFilterToInitialStates}
          />
        )}
        {assignModalContentFolders?.length > 0 && view === PLAYLIST && (
          <Folders
            className={styles.folderListing}
            variant="small"
            data={assignModalContentFolders}
            isHavingAllAccess={
              view === PLAYLIST &&
              playListAccess === PlayListPermissions.AllPlayList
            }
            openFolder={(folder) => {
              dispatch(homePageActions.setIsFetchingFolders(true));
              dispatch(
                layoutActions.setAssignModalContentActiveFolders(folder)
              );
            }}
            setChecked={setFilterToInitialStates}
          />
        )}
        {view === PLAYLIST &&
          (playlistData?.length === 0 ? (
            <p className={`${styles.noPlaylistsFound} ${styles.desktop}`}>
              {searchKey !== ''
                ? 'Result not found'
                : 'No playlist exists. You can create a playlist.'}
            </p>
          ) : (
            <PlaylistTable
              className={styles.playlists}
              isShowCheckBox
              columns={TEMP_COLUMNS}
              data={playlistData}
            />
          ))}
        {view === PLAYLIST &&
          (playlistData?.length === 0 ? (
            <p className={`${styles.noPlaylistsFound} ${styles.responsive}`}>
              {searchKey !== ''
                ? 'Result not found'
                : 'No playlist exists. You can upload a playlist.'}
            </p>
          ) : (
            <Playlists
              foundPlaylists={playlistData}
              // foundPlaylists={playlists.filter((i) => i.name.includes(searchKey))}
              variant={PlaylistVariants.AssignContentModal}
            />
          ))}
        {(view === CONTENT || view === TEMPLATE) &&
          (contentsData?.length === 0 ? (
            <p className={`${styles.noPlaylistsFound} `}>
              {searchKey !== ''
                ? 'Result not found'
                : 'No content exists. You can upload content. '}
            </p>
          ) : (
            <ContentList
              className={styles.contentListModal}
              view={view}
              searchKey={searchKey}
              checked={checked}
            />
          ))}
      </div>
    </Modal>
  );
}

AssignContentModal.defaultProps = {
  className: '',
};

export default AssignContentModal;

import {
  iCompanyData,
  iFolderData,
  iScreenData,
  iScreenUpdateData,
  iParentFolders,
  iScreenStatusData,
} from 'types';
import { HOME_PAGE } from '../constants';

interface iActions {
  type: string;
  payload?: any;
}

export const homePageActions = {
  addScreen: (): iActions => ({
    type: HOME_PAGE.addScreen,
  }),
  fetchScreensData: (data: boolean): iActions => ({
    type: HOME_PAGE.fetchScreensData,
    payload: data,
  }),
  setScreensFolderHierarchy: (data: iParentFolders[] | []): iActions => ({
    type: HOME_PAGE.setScreensFolderHierarchy,
    payload: data,
  }),
  setActiveCompanyData: (data: boolean): iActions => ({
    type: HOME_PAGE.setActiveCompanyData,
    payload: data,
  }),
  setIsFetchingFolders: (data: boolean): iActions => ({
    type: HOME_PAGE.setIsFetchingFolders,
    payload: data,
  }),
  setIsScreenBeingAdded: (data: string): iActions => ({
    type: HOME_PAGE.setIsScreenBeingAdded,
    payload: data,
  }),
  setScreenId: (data: string): iActions => ({
    type: HOME_PAGE.setScreenId,
    payload: data,
  }),

  setScreenData: (screen: iScreenData): iActions => ({
    type: HOME_PAGE.setScreenData,
    payload: screen,
  }),
  setTotalOnlineCount: (count: number | null): iActions => ({
    type: HOME_PAGE.setTotalOnlineCount,
    payload: count,
  }),
  setTotalOfflineCount: (count: number | null): iActions => ({
    type: HOME_PAGE.setTotalOfflineCount,
    payload: count,
  }),
  setIsSaveAndCloseDisabled: (status: boolean): iActions => ({
    type: HOME_PAGE.setIsSaveAndCloseDisabled,
    payload: status,
  }),
  setScreensOnlineStatus: (status: iScreenStatusData[]): iActions => ({
    type: HOME_PAGE.setScreensOnlineStatus,
    payload: status,
  }),
  setLastFetchedTime: (time: Date): iActions => ({
    type: HOME_PAGE.setLastFetchedTime,
    payload: time,
  }),

  updateScreenData: (screen: iScreenUpdateData): iActions => ({
    type: HOME_PAGE.updateScreenData,
    payload: screen,
  }),
  setCompanies: (companies: iCompanyData[]): iActions => ({
    type: HOME_PAGE.setCompanies,
    payload: companies,
  }),
  setSearchCompanyKey: (key: string): iActions => ({
    type: HOME_PAGE.searchCompanyKey,
    payload: key,
  }),
  setSearchScreenKey: (key: string): iActions => ({
    type: HOME_PAGE.searchScreenKey,
    payload: key,
  }),
  setCompanyData: (companyData: iCompanyData | null): iActions => ({
    type: HOME_PAGE.setCompanyData,
    payload: companyData,
  }),
  screenOrderChange: (screens): iActions => ({
    type: HOME_PAGE.screenOrderChange,
    payload: screens,
  }),
  addFolder: (folderName: string, folders: iFolderData[]): iActions => ({
    type: HOME_PAGE.addFolder,
    payload: { folderName, folders },
  }),
  invokeGetCompanies: (data: boolean): iActions => ({
    type: HOME_PAGE.invokeGetCompanies,
    payload: data,
  }),
  addSuperUserClient: (): iActions => ({
    type: HOME_PAGE.addSuperUserClient,
  }),
  addSuperUserResellerClient: (): iActions => ({
    type: HOME_PAGE.addSuperUserResellerClient,
  }),
  invokeUpdateScreen: (data: boolean): iActions => ({
    type: HOME_PAGE.invokeUpdateScreen,
    payload: data,
  }),
  invokeGetScreenById: (data: boolean): iActions => ({
    type: HOME_PAGE.invokeGetScreenById,
    payload: data,
  }),
  addResellerClient: (): iActions => ({
    type: HOME_PAGE.addResellerClient,
  }),
  setActiveFolder: (folder: iFolderData | null): iActions => ({
    type: HOME_PAGE.setActiveFolder,
    payload: folder,
  }),
  setActiveClientInstanceData: (data: iCompanyData | null) => ({
    type: HOME_PAGE.setActiveClientInstanceData,
    payload: data,
  }),
  setScreenzId: (id: string) => ({
    type: HOME_PAGE.screenzId,
    payload: id,
  }),
  setScreensFolders: (folders: iFolderData[] | []): iActions => ({
    type: HOME_PAGE.setScreensFolders,
    payload: folders,
  }),
  setScreensActiveFolder: (folder: iFolderData | null): iActions => ({
    type: HOME_PAGE.setScreensActiveFolder,
    payload: folder,
  }),
  setContentToAssignToScreen: (id: string): iActions => ({
    type: HOME_PAGE.setContentToAssignToScreen,
    payload: id,
  }),
  setPlaylistToAssignToScreen: (id: string): iActions => ({
    type: HOME_PAGE.setPlaylistToAssignToScreen,
    payload: id,
  }),
};

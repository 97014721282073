const onBoarding = {
  login: '/login',
  joinYourTeam: '/join-your-team',
  forgotPassword: '/forgot-password',
  verifyEmail: '/verify-email',
  passwordResetInstructions: '/password-reset-instructions',
  setNewPassword: '/set-new-password',
  resetPassword: '/reset-password',
  home: '/',
  superUser: '/super-user',
  office365Auth: '/office365-auth',
  calenderEvents: '/calender-events',
  screenDetails: '/screen-details',
  userSettings: '/user-settings',
  content: '/content',
  playlists: '/playlists',
  playlist: '/playlist',
  emailTeamInvite: '/emailTeamInvite',
  emptyStates: '/empty-states',
  chooseTeam: '/choose-team',
  verify2fa: '/verify-2fa',
  addWebpage: '/add-web-content',
  roles: '/roles',
  rolesDetails: '/roles-details',
  userSettingsTeamMembers: '/user-settings/teamMembers',
  userSettingsIntegrations: '/user-settings/integrations',
  userSettingsProfile: '/user-settings/profile',
  userSettingsPassword: '/user-settings/password',
  addTeamMates: '/user-settings/addTeamMembers',
  userSettingsPermissions: '/user-settings/teamMembersPermissions',
  screenz: '/screenz',
  features: '/features',
};

export const ROUTES = {
  ...onBoarding,
};

export const PUBLIC_ROUTES = [
  ROUTES.login,
  ROUTES.joinYourTeam,
  ROUTES.forgotPassword,
  ROUTES.resetPassword,
  ROUTES.chooseTeam,
];

export const PROTECTED_ROUTES = [];

import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import { iState } from 'types';
import { useLocation, useNavigate } from 'react-router-dom';
import { refreshIcon, playlistEmptyStateImage, ROUTES } from 'constants/index';
import { ScreenType } from 'enums';
import Buttons from './header/buttons/buttons';
import Header from './header/header';
import Sidebar from './sidebar/sidebar';
import styles from './addWebContent.module.scss';

interface iProps {
  className?: string;
}

function AddWebContent(props: iProps): JSX.Element {
  const { className } = props;
  const [state]: [iState, any] = useStore();
  const [isLoadWeb, setIsLoadWeb] = useState<boolean>(false);
  const [oreintation, setOreintation] = useState(ScreenType.HORIZONTAL);
  const {
    addWebContentData,
    isEditWebContent,
    isTriedToLoadWebsiteTwo = false,
  } = state.contentPage || {};
  const { url } = addWebContentData || {};
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { companyData } = state.homePage || {};

  useEffect(() => {
    if (!companyData) {
      navigate(ROUTES.chooseTeam);
    }
  }, [pathname]);

  const onClickRefresh = React.useCallback(() => {
    const iframe: any = document.getElementById('addWebContentIframe');
    if (!iframe) return;
    if (iframe.src) iframe.src = url;
  }, [url]);

  return (
    <div className={`${styles.addWebContent} ${className}`}>
      <Header isEditWebContent={isEditWebContent} />
      <div className={styles.mainSection}>
        <div
          className={`${styles.previewContainer} ${
            oreintation === ScreenType.VERTICAL
              ? styles.portait
              : styles.landscape
          }`}
        >
          <div className={styles.previewHeader}>
            <div className={styles.leftOne}>
              <span className={styles.browserBtns} />
              <span className={styles.browserBtns} />
              <span className={styles.browserBtns} />
            </div>
            <div className={styles.rightOne}>
              <button
                className={styles.refreshBtn}
                type="button"
                onClick={onClickRefresh}
              >
                <img className={styles.refreshIcon} src={refreshIcon} alt="" />
                <span>Refresh</span>
              </button>
            </div>
          </div>
          <div className={styles.mainSection}>
            {isLoadWeb && isTriedToLoadWebsiteTwo && (
              <iframe
                className={styles.previewIFrame}
                id="addWebContentIframe"
                title="Web page preview"
                src={url}
                frameBorder="0"
              />
            )}
            {!isLoadWeb && (
              <>
                <img src={playlistEmptyStateImage} alt="" />
                <p className={styles.addUrl}>Add URL</p>
                <p className={styles.addUrlDescription}>
                  The website URL will preview here. Your screen uses iFrame to
                  dispaly websites, not all websites allow this.
                </p>
              </>
            )}
          </div>
        </div>
        <Sidebar
          openWebSite={(show) => setIsLoadWeb(show)}
          refresher={onClickRefresh}
          setOreintation={setOreintation}
        />
        <Buttons className={styles.respnsiveBtns} />
      </div>
    </div>
  );
}

AddWebContent.defaultProps = {
  className: '',
};

export default AddWebContent;

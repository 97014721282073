import ContentPreview from 'components/content-preview/contentPreview';
import { CONTENT_TYPE, dustbinIcon, editIcon, ROUTES } from 'constants/index';
import React, { useCallback } from 'react';
import { useStore } from 'store';
import { EventTemplateType, SideDrawers } from 'enums';
import More from 'components/svg/more';
import Popover from 'components/popover/popover';
import {
  contentPageActions,
  layoutActions,
  office365Actions,
} from 'store/actions';
import { iContentData, iState } from 'types';
import { useNavigate } from 'react-router-dom';
import styles from './content.module.scss';

interface iProps {
  className?: string;
  data: iContentData;
  isLastContent?: boolean;
  deleteContent?: () => void;
}
function Content(props: iProps): JSX.Element {
  const { className, data, isLastContent, deleteContent } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { deleteContentAndFolder = true } = state.user.userPermissions || {};
  const navigate = useNavigate();

  const openDetailView = useCallback(() => {
    if (data.contentType !== CONTENT_TYPE.Template) {
      dispatch(
        layoutActions.toggleSideDrawer(SideDrawers.isShowContentPreview)
      );
      dispatch(contentPageActions.setContentView(data));
    }
    if (data.contentType === CONTENT_TYPE.Template) {
      dispatch(
        layoutActions.toggleSideDrawer(SideDrawers.isShowTemplatePreview)
      );
      if (data.templateData?.templateType === EventTemplateType.meetingRoom) {
        dispatch(
          office365Actions.setChosenTemplateType(EventTemplateType.meetingRoom)
        );
        dispatch(
          layoutActions.setTemplateToView(EventTemplateType.meetingRoom)
        );
      }
      if (
        data.templateData?.templateType ===
        EventTemplateType.meetingOverviewFull
      ) {
        dispatch(
          office365Actions.setChosenTemplateType(
            EventTemplateType.meetingOverviewFull
          )
        );
        dispatch(
          layoutActions.setTemplateToView(EventTemplateType.meetingOverviewFull)
        );
      }
      if (
        data.templateData?.templateType ===
        EventTemplateType.meetingOverviewMessage
      ) {
        dispatch(
          office365Actions.setChosenTemplateType(
            EventTemplateType.meetingOverviewMessage
          )
        );
        dispatch(
          layoutActions.setTemplateToView(
            EventTemplateType.meetingOverviewMessage
          )
        );
      }
      if (
        data.templateData?.templateType ===
        EventTemplateType.meetingOverviewPotrait
      ) {
        dispatch(
          office365Actions.setChosenTemplateType(
            EventTemplateType.meetingOverviewPotrait
          )
        );
        dispatch(
          layoutActions.setTemplateToView(
            EventTemplateType.meetingOverviewPotrait
          )
        );
      }
      dispatch(contentPageActions.setTemplatePreview(data));
      dispatch(office365Actions.setTemplateIdToEdit(data.id));
    }
  }, [dispatch, data, state]);

  const onClickEdit = useCallback(() => {
    dispatch(contentPageActions.setContentView(data));
    dispatch(contentPageActions.setIsEditWebContent(true));
    navigate(ROUTES.addWebpage);
  }, [dispatch, navigate]);

  const date = (data.updated_at && new Date(data.updated_at)) || '';

  const templateNameType =
    (data.templateData?.templateType === EventTemplateType.meetingRoom &&
      'MeetingRoom Standard') ||
    (data.templateData?.templateType ===
      EventTemplateType.meetingOverviewFull &&
      'Meeting Overview Full') ||
    (data.templateData?.templateType ===
      EventTemplateType.meetingOverviewMessage &&
      'Meeting Overview') ||
    (data.templateData?.templateType ===
      EventTemplateType.meetingOverviewPotrait &&
      'Meeting Overview Portrait');
  const activeContent = {
    borderWidth: '1px',
    borderColor: '#615ae2',
    borderStyle: 'solid',
  };
  return (
    <div
      className={`${styles.content} ${className}`}
      onClick={openDetailView}
      style={
        data.id === state.contentPage?.contentView?.id &&
        state.layout.isShowContentPreview
          ? { ...activeContent }
          : {}
      }
    >
      <div className={styles.previewAndMeta}>
        <ContentPreview data={data} />
        <div className={styles.contentNameAndLength}>
          <p className={styles.contentName}>{data.name}</p>
          {(data.contentType === CONTENT_TYPE.Template && (
            <p className={styles.contentMeta}>{templateNameType}</p>
          )) || (
            <p className={styles.contentMeta}>
              {data.contentType} /{' '}
              {data.contentType === CONTENT_TYPE.Website ? (
                <p className={styles.url}>{data.url}</p>
              ) : (
                data.dimension
              )}
            </p>
          )}
        </div>
      </div>
      <p className={styles.type}>{data.contentType}</p>
      <p className={styles.ownerName}>
        {data?.user?.first_name} {data?.user?.last_name}
      </p>
      <p className={styles.modifiedOn}>
        {date &&
          `${date.getDate()}.${
            date.getMonth() + 1
          }.${date.getFullYear()} ${date.getHours()}.${date.getMinutes()} `}
      </p>

      <Popover
        className={styles.morePopupContainer}
        popoverClassName={`${styles.popover} ${isLastContent && styles.last}`}
        popoverComponent={
          <div className={`${styles.morePopup} ${styles.webContentMutation}`}>
            {data.contentType === CONTENT_TYPE.Website && (
              <div className={styles.option} onClick={onClickEdit}>
                <img src={editIcon} alt="" />
                <p>Edit</p>
              </div>
            )}
            <div
              className={`${styles.option} ${
                !deleteContentAndFolder && styles.disabled
              }`}
              onClick={deleteContent}
            >
              <img src={dustbinIcon} alt="" />
              <p>Delete</p>
            </div>
          </div>
        }
      >
        <div className={styles.moreBtn}>
          <More className={styles.icon} />
        </div>
      </Popover>
    </div>
  );
}

Content.defaultProps = {
  className: '',
  isLastContent: false,
  deleteContent: () => {},
};

export default Content;

import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './screensSkeleton.module.scss';

interface iProps {
  className?: string;
}

function ScreensSkeleton(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <div className={`${styles.screensSkeleton} ${className}`}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(() => (
        <Skeleton className={styles.skeletonStyles} />
      ))}
    </div>
  );
}

ScreensSkeleton.defaultProps = {
  className: '',
};

export default ScreensSkeleton;

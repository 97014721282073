import React from 'react';

interface iProps {
  className?: string;
}

function AddRounded(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <svg
      className={className}
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#jvisl9vg3a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 1.75a8.75 8.75 0 1 0 0 17.5 8.75 8.75 0 0 0 0-17.5zM0 10.5C0 4.701 4.701 0 10.5 0S21 4.701 21 10.5 16.299 21 10.5 21 0 16.299 0 10.5zm10.5-4.375c.483 0 .875.392.875.875v2.625H14a.875.875 0 0 1 0 1.75h-2.625V14a.875.875 0 0 1-1.75 0v-2.625H7a.875.875 0 0 1 0-1.75h2.625V7c0-.483.392-.875.875-.875z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="jvisl9vg3a">
          <path fill="#fff" d="M0 0h21v21H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

AddRounded.defaultProps = {
  className: '',
};

export default AddRounded;

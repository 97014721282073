import React, { useEffect, useState, useCallback } from 'react';
import Layout, { LayoutVariants } from 'components/Layouts/layout';
import {
  ScreenPermissions,
  ContentPermissions,
  PlayListPermissions,
  PermissionFor,
  PermissionType,
  PermissionMutationType,
  ClientPermissions,
} from 'enums';
import { useLocation, useNavigate } from 'react-router-dom';
import { toaster } from 'helpers';
import { ROUTES } from 'constants/index';
import { getPermissions, addOrUpdatePermissions, deleteUserRole } from 'apis';
import { iAddOrUpdatePermissionsArgs, iPermissionsData } from 'types';
import { ALL_PERMISSIONS, PermissionKeys } from './constants';
// import SpecificPermissions, {
//   SpecificPermissionsVariants,
// } from '../specific-permission/specificPermission';
import FeatureWithSwitch from './feature-with-switch/featureWithSwitch';
import FeatureWithRadio from './features-with-radio-btns/featuresWithRadioBtns';
import SubHeader from './subheader/subheader';
import styles from './roleDetailsView.module.scss';

interface iProps {
  className?: string;
}

function RolesDetails(props: iProps): JSX.Element {
  const { className } = props;
  const [permissions, setPermissions] = useState<iPermissionsData>();
  const [isPermissionsModified, setIsPermissionsModified] =
    useState<boolean>(false);
  const location: any = useLocation();
  const navigate = useNavigate();
  //  console.log(
  //   'permissions_____permissions',
  //   permissions,
  //   location,
  //   setIsPermissionsModified
  // );

  useEffect(() => {
    (async () => {
      const id = location?.state?.id;
      if (!id) {
        navigate(ROUTES.roles);
        return;
      }
      const response = await getPermissions({
        rollOrCompanyUserId: id,
        permissionFor: PermissionFor.Role,
      });
      if (response) {
        setPermissions(response);
      }
    })();
  }, [location?.state?.id]);

  const updatePermissions = useCallback(async () => {
    const { screenAccess, contentAcces, playListAccess } = permissions || {};
    if (typeof screenAccess === 'boolean') {
      toaster.error(
        'Please select a screen permission whether all or specific'
      );
      return;
    }
    if (typeof contentAcces === 'boolean') {
      toaster.error(
        'Please select a content permission whether all or specific'
      );
      return;
    }
    if (typeof playListAccess === 'boolean') {
      toaster.error(
        'Please select a playlist permission whether all or specific'
      );
      return;
    }

    if (!permissions) {
      //  console.log('SOMETHING WENT WRONG');
      return;
    }

    //  console.log(
    // 'ClientPermissions[permissions.clients]',
    //   ClientPermissions,
    //   permissions.clients
    // );

    const id = location?.state?.id;
    const updateArgs: iAddOrUpdatePermissionsArgs = {
      mutationType: PermissionMutationType.UPDATE,
      ...permissions,
      id: permissions.id,
      permissionFor: PermissionFor.Role,
      permissionType: PermissionType.fullAccess,
      rollOrCompanyUserId: id,
      clients:
        ClientPermissions[
          `${permissions.clients[0].toUpperCase()}${permissions.clients.slice(
            1
          )}`
        ],
      screenAccess:
        ScreenPermissions[
          `${permissions.screenAccess[0].toUpperCase()}${permissions.screenAccess.slice(
            1
          )}`
        ],
      contentAcces:
        ContentPermissions[
          `${permissions.contentAcces[0].toUpperCase()}${permissions.contentAcces.slice(
            1
          )}`
        ],
      playListAccess:
        PlayListPermissions[
          `${permissions.playListAccess[0].toUpperCase()}${permissions.playListAccess.slice(
            1
          )}`
        ],
    };
    const response = await addOrUpdatePermissions(updateArgs);
    if (response) {
      setIsPermissionsModified(false);
      //  console.log('UPDATE_PERMISSIONS', permissions, updateArgs);
      toaster.success('The permissions have been successfully updated!');
    }
  }, [permissions, location?.state?.id]);

  const removeRole = useCallback(async () => {
    const response = await deleteUserRole(location?.state?.id, () => {});
    if (response?.statusCode === 200) {
      toaster.success('User role deleted');
      navigate(ROUTES.roles, { replace: true });
    }
  }, [location?.state?.id]);

  return (
    <Layout variant={LayoutVariants.layoutFour}>
      <section className={`${styles.roleDetailsWrapper} ${className}`}>
        <SubHeader
          roleName={location?.state?.roleName}
          isDisabled={isPermissionsModified}
          saveChanges={updatePermissions}
          removeRole={removeRole}
        />
        <div className={styles.rolesDetailsMainContainer}>
          <div className={styles.permissionsContainer}>
            {permissions &&
              ALL_PERMISSIONS.map((i) => {
                return (
                  <>
                    <p className={`${styles.mainHeading} ${styles.noBorder}`}>
                      {i.heading}
                    </p>
                    <>
                      {i.permissions.map((permission) => {
                        const mainPermissions =
                          (typeof permissions[permission.key] === 'boolean' &&
                            permissions[permission.key]) ||
                          (permissions[permission.key] === 'disabled' &&
                            false) ||
                          (permissions[permission.key] === 'onlyTheyAreAdded' &&
                            true) ||
                          (permissions[permission.key] === 'allPlayList' &&
                            true) ||
                          (permissions[permission.key] === 'allContents' &&
                            true) ||
                          (permissions[permission.key] === 'allScreens' &&
                            true) ||
                          false;
                        return (
                          <>
                            {permission.option && (
                              <FeatureWithSwitch
                                feature={permission.option}
                                isAccessible={mainPermissions}
                                isNotDisabled
                                onChange={(status) => {
                                  if (
                                    permission.key ===
                                      PermissionKeys.screenAccess ||
                                    permission.key ===
                                      PermissionKeys.contentAcces ||
                                    permission.key ===
                                      PermissionKeys.playListAccess
                                  ) {
                                    const permissionsStatus =
                                      (permission.key ===
                                        PermissionKeys.screenAccess &&
                                        ScreenPermissions.AllScreens) ||
                                      (permission.key ===
                                        PermissionKeys.contentAcces &&
                                        ContentPermissions.AllContents) ||
                                      (permission.key ===
                                        PermissionKeys.playListAccess &&
                                        PlayListPermissions.AllPlayList);
                                    console.log(status, 'status');
                                    setPermissions({
                                      ...permissions,
                                      [permission.key]: status
                                        ? permissionsStatus
                                        : 'disabled',
                                      // setPermissions({
                                      //   ...permissions,
                                      //   [permission.key]: status || 'disabled',
                                      // ? status
                                      // : 'disabled',
                                    });
                                  } else
                                    setPermissions({
                                      ...permissions,
                                      [permission.key]: status,
                                    });
                                  setIsPermissionsModified(true);
                                }}
                              />
                            )}
                            {permission.subPermissions.map((subPerm) => {
                              return (
                                <>
                                  <FeatureWithRadio
                                    feature={subPerm.option}
                                    value={subPerm.value}
                                    isNotDisabled
                                    isChecked={
                                      subPerm.value === permissions[subPerm.key]
                                    }
                                    onChange={(choosed) => {
                                      if (mainPermissions) {
                                        setPermissions({
                                          ...permissions,
                                          [subPerm.key]: choosed,
                                        });
                                        setIsPermissionsModified(true);
                                      }
                                    }}
                                  />
                                  {/* {subPerm.value ===
                                    (ScreenPermissions.OnlyTheyAreAdded ||
                                      ContentPermissions.OnlyTheyAreAdded ||
                                      PlayListPermissions.OnlyTheyAreAdded) &&
                                    subPerm.value ===
                                      permissions[subPerm.accessTo] && (
                                      <SpecificPermissions
                                        variant={
                                          SpecificPermissionsVariants.ScreensAccess
                                        }
                                      />
                                    )} */}
                                </>
                              );
                            })}
                          </>
                        );
                      })}
                    </>
                  </>
                );
              })}
          </div>
        </div>
      </section>
    </Layout>
  );
}

RolesDetails.defaultProps = {
  className: '',
};

export default RolesDetails;

import { activeFolderIcon, folderIcon } from 'constants/index';
import React from 'react';
import styles from './folder.module.scss';

interface iProps {
  className?: string;
  variant?: 'small' | 'default';
  folderName: string;
  isActive?: boolean;
  onClick?: () => void;
}

function Folder(props: iProps): JSX.Element {
  const { className, isActive, folderName, variant, onClick } = props;

  return (
    <div
      className={`${className} ${styles.container} ${
        variant === 'small' && styles.small
      }`}
      onClick={onClick}
    >
      <img
        className={styles.folderIcon}
        src={isActive ? activeFolderIcon : folderIcon}
        alt=""
      />
      {folderName}
    </div>
  );
}

Folder.defaultProps = {
  className: '',
  isActive: false,
  variant: 'default',
  onClick: () => {},
};

export default Folder;

import { informationIcon, norwayData } from 'constants/index';
import { CalenderSettingsView } from 'enums';
import { uuid } from 'helpers';
import { COMMON, MEETINGOVERVIEWFULL } from 'store/constants';
import { initialState } from 'store/initialState/initialState';
import { iMeetingOverviewFullState } from 'types';

export const meetingOverviewFullReducer = (
  state: iMeetingOverviewFullState,
  action
): iMeetingOverviewFullState => {
  switch (action.type) {
    case MEETINGOVERVIEWFULL.setCurrentView: {
      return {
        ...state,
        currentView: action.payload,
      };
    }
    case MEETINGOVERVIEWFULL.setMeetingTemplateName: {
      return {
        ...state,
        templateOverviewName: action.payload,
      };
    }
    case MEETINGOVERVIEWFULL.setOverviewEventBgImg: {
      return {
        ...state,
        eventBgImg: action.payload,
      };
    }
    case MEETINGOVERVIEWFULL.setCurrentCard: {
      return {
        ...state,
        currentCard: action.payload,
      };
    }
    case MEETINGOVERVIEWFULL.setOverviewSettingsContentOption: {
      // let selectedCard;
      const temp = { ...state.cardDetails };
      if (state.cardDetails[action.payload.CARD_NAME])
        // selectedCard = state.cardDetails[state.currentCard];
        temp[action.payload.CARD_NAME].contentOption = action.payload.option;

      return {
        ...state,
        cardDetails: temp,
      };
    }

    case MEETINGOVERVIEWFULL.setOverviewClockAllignement: {
      const temp = { ...state.cardDetails };
      if (state.cardDetails[action.payload.CARD_NAME])
        temp[action.payload.CARD_NAME].clockAlignment = action.payload.option;
      return {
        ...state,
        cardDetails: temp,
      };
    }

    case MEETINGOVERVIEWFULL.setOverviewTimeZoneOption: {
      const temp = { ...state.cardDetails };
      if (state.cardDetails[action.payload.CARD_NAME])
        temp[action.payload.CARD_NAME].overviewTimezone = action.payload.option;
      return {
        ...state,
        cardDetails: temp,
      };
    }

    case MEETINGOVERVIEWFULL.setOverviewEventLogo: {
      const temp = { ...state.cardDetails };
      if (state.cardDetails[action.payload.CARD_NAME])
        temp[action.payload.CARD_NAME].overviewEventLogo = action.payload.url;
      return {
        ...state,
        cardDetails: temp,
      };
    }
    case MEETINGOVERVIEWFULL.updateOverviewCustomName: {
      const temp = { ...state.cardDetails };
      if (state.cardDetails[action.payload.CARD_NAME])
        temp[action.payload.CARD_NAME].overviewCustom_name =
          action.payload.custom_Name;
      return {
        ...state,
        cardDetails: temp,
      };
    }
    case MEETINGOVERVIEWFULL.updateRoomDetails: {
      const temp = { ...state.cardDetails };
      if (state.cardDetails[action.payload.CARD_NAME])
        temp[action.payload.CARD_NAME].roomDetails =
          action.payload.room_details;
      return {
        ...state,
        cardDetails: temp,
      };
    }
    case MEETINGOVERVIEWFULL.setOverviewChoosedRoom: {
      const temp = { ...state.cardDetails };
      if (state.cardDetails[action.payload.CARD_NAME])
        temp[action.payload.CARD_NAME].overviewChoosedRoom =
          action.payload.roomData;
      return {
        ...state,
        cardDetails: temp,
      };
    }
    case MEETINGOVERVIEWFULL.setOverviewAllCalenderEvents: {
      const temp = { ...state.cardDetails };
      if (state.cardDetails[action.payload.CARD_NAME])
        temp[action.payload.CARD_NAME].overviewCalenderEvents =
          action.payload.events;
      return {
        ...state,
        cardDetails: temp,
      };
    }
    case MEETINGOVERVIEWFULL.setOverviewCurrentCalenderEvents: {
      const temp = { ...state.cardDetails };
      if (state.cardDetails[action.payload.CARD_NAME])
        temp[action.payload.CARD_NAME].overviewCurrentCalenderEvents =
          action.payload.events;
      return {
        ...state,
        cardDetails: temp,
      };
    }
    case MEETINGOVERVIEWFULL.setOverviewNextCurrentCalenderEvents: {
      const temp = { ...state.cardDetails };
      if (state.cardDetails[action.payload.CARD_NAME])
        temp[action.payload.CARD_NAME].overviewNextEvents =
          action.payload.events;
      return {
        ...state,
        cardDetails: temp,
      };
    }
    case MEETINGOVERVIEWFULL.setIsShowOverviewSubject: {
      const temp = { ...state.cardDetails };
      if (state.cardDetails[action.payload.CARD_NAME])
        temp[action.payload.CARD_NAME].isShowOverviewSubject =
          action.payload.isOn;
      return {
        ...state,
        cardDetails: temp,
      };
    }
    case MEETINGOVERVIEWFULL.setIsShowOverviewHostname: {
      const temp = { ...state.cardDetails };

      if (state.cardDetails[action.payload.CARD_NAME])
        temp[action.payload.CARD_NAME].isShowOverviewHostName =
          action.payload.isOn;
      return {
        ...state,
        cardDetails: temp,
      };
    }
    case MEETINGOVERVIEWFULL.setIsShowOverviewNextMeeting: {
      const temp = { ...state.cardDetails };
      if (state.cardDetails[action.payload.CARD_NAME])
        temp[action.payload.CARD_NAME].isShowOverviewNextMeeting =
          action.payload.isOn;
      return {
        ...state,
        cardDetails: temp,
      };
    }
    case MEETINGOVERVIEWFULL.setOverviewContentsId: {
      const temp = { ...state.cardDetails };
      if (state.cardDetails[action.payload.CARD_NAME])
        temp[action.payload.CARD_NAME].id = action.payload.option;
      return {
        ...state,
        cardDetails: temp,
      };
    }
    case MEETINGOVERVIEWFULL.setLargeAreaContent: {
      return {
        ...state,
        largeAreaContent: action.payload,
      };
    }
    case MEETINGOVERVIEWFULL.updateOverviewPretitle: {
      return {
        ...state,
        preTitle: action.payload,
      };
    }
    case MEETINGOVERVIEWFULL.updateOverviewTitle: {
      return {
        ...state,
        title: action.payload,
      };
    }
    case MEETINGOVERVIEWFULL.updateOverviewBody: {
      return {
        ...state,
        body: action.payload,
      };
    }
    case MEETINGOVERVIEWFULL.updateOverviewSubtext: {
      return {
        ...state,
        subText: action.payload,
      };
    }
    case MEETINGOVERVIEWFULL.setTransparency: {
      return {
        ...state,
        transparency: action.payload,
      };
    }
    case MEETINGOVERVIEWFULL.setOverviewLargeImage: {
      return {
        ...state,
        largeContentUrl: action.payload,
      };
    }
    case MEETINGOVERVIEWFULL.resetOverviewTemplateData: {
      return {
        ...state,
        currentView: CalenderSettingsView.TemplateSetting,
        templateOverviewName: '',
        eventBgImg: '',
        currentCard: null,
        largeAreaContent: {
          id: uuid(),
          option: 'Empty',
          optionNorway: norwayData.empty,
          icon: informationIcon,
          value: 'Empty',
        },
        preTitle: '',
        body: '',
        title: '',
        subText: '',
        largeContentUrl: '',
        cardDetails: {
          One: {
            id: '',
            position: 7,
            contentOption: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            clockAlignment: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            overviewTimezone: {
              id: uuid(),
              option: 'Empty',
              icon: informationIcon,
              value: 'Empty',
            },
            overviewEventLogo: '',
            overviewCustom_name: '',
            roomDetails: '',
            overviewChoosedRoom: null,
            overviewCalenderEvents: [],
            overviewCurrentCalenderEvents: null,
            overviewNextEvents: null,
            isShowOverviewSubject: true,
            isShowOverviewHostName: true,
            isShowOverviewNextMeeting: true,
          },
          Two: {
            id: '',
            position: 1,
            contentOption: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            clockAlignment: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            overviewTimezone: {
              id: uuid(),
              option: 'Empty',
              icon: informationIcon,
              value: 'Empty',
            },
            overviewEventLogo: '',
            overviewCustom_name: '',
            roomDetails: '',
            overviewChoosedRoom: null,
            overviewCalenderEvents: [],
            overviewCurrentCalenderEvents: null,
            overviewNextEvents: null,
            isShowOverviewSubject: true,
            isShowOverviewHostName: true,
            isShowOverviewNextMeeting: true,
          },
          Three: {
            id: '',
            position: 8,
            contentOption: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            clockAlignment: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            overviewTimezone: {
              id: uuid(),
              option: 'Empty',
              icon: informationIcon,
              value: 'Empty',
            },
            overviewEventLogo: '',
            overviewCustom_name: '',
            roomDetails: '',
            overviewChoosedRoom: null,
            overviewCalenderEvents: [],
            overviewCurrentCalenderEvents: null,
            overviewNextEvents: null,
            isShowOverviewSubject: true,
            isShowOverviewHostName: true,
            isShowOverviewNextMeeting: true,
          },
          Four: {
            id: '',
            position: 2,
            contentOption: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            clockAlignment: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            overviewTimezone: {
              id: uuid(),
              option: 'Empty',
              icon: informationIcon,
              value: 'Empty',
            },
            overviewEventLogo: '',
            overviewCustom_name: '',
            roomDetails: '',
            overviewChoosedRoom: null,
            overviewCalenderEvents: [],
            overviewCurrentCalenderEvents: null,
            overviewNextEvents: null,
            isShowOverviewSubject: true,
            isShowOverviewHostName: true,
            isShowOverviewNextMeeting: true,
          },
          Five: {
            id: '',
            position: 9,
            contentOption: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            clockAlignment: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            overviewTimezone: {
              id: uuid(),
              option: 'Empty',
              icon: informationIcon,
              value: 'Empty',
            },
            overviewEventLogo: '',
            overviewCustom_name: '',
            roomDetails: '',
            overviewChoosedRoom: null,
            overviewCalenderEvents: [],
            overviewCurrentCalenderEvents: null,
            overviewNextEvents: null,
            isShowOverviewSubject: true,
            isShowOverviewHostName: true,
            isShowOverviewNextMeeting: true,
          },
          Six: {
            id: '',
            position: 3,
            contentOption: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            clockAlignment: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            overviewTimezone: {
              id: uuid(),
              option: 'Empty',
              icon: informationIcon,
              value: 'Empty',
            },
            overviewEventLogo: '',
            overviewCustom_name: '',
            roomDetails: '',
            overviewChoosedRoom: null,
            overviewCalenderEvents: [],
            overviewCurrentCalenderEvents: null,
            overviewNextEvents: null,
            isShowOverviewSubject: true,
            isShowOverviewHostName: true,
            isShowOverviewNextMeeting: true,
          },
          Seven: {
            id: '',
            position: 10,
            contentOption: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            clockAlignment: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            overviewTimezone: {
              id: uuid(),
              option: 'Empty',
              icon: informationIcon,
              value: 'Empty',
            },
            overviewEventLogo: '',
            overviewCustom_name: '',
            roomDetails: '',
            overviewChoosedRoom: null,
            overviewCalenderEvents: [],
            overviewCurrentCalenderEvents: null,
            overviewNextEvents: null,
            isShowOverviewSubject: true,
            isShowOverviewHostName: true,
            isShowOverviewNextMeeting: true,
          },
          Eight: {
            id: '',
            position: 4,
            contentOption: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            clockAlignment: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            overviewTimezone: {
              id: uuid(),
              option: 'Empty',
              icon: informationIcon,
              value: 'Empty',
            },
            overviewEventLogo: '',
            overviewCustom_name: '',
            roomDetails: '',
            overviewChoosedRoom: null,
            overviewCalenderEvents: [],
            overviewCurrentCalenderEvents: null,
            overviewNextEvents: null,
            isShowOverviewSubject: true,
            isShowOverviewHostName: true,
            isShowOverviewNextMeeting: true,
          },
          Nine: {
            id: '',
            position: 11,
            contentOption: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            clockAlignment: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            overviewTimezone: {
              id: uuid(),
              option: 'Empty',
              icon: informationIcon,
              value: 'Empty',
            },
            overviewEventLogo: '',
            overviewCustom_name: '',
            roomDetails: '',
            overviewChoosedRoom: null,
            overviewCalenderEvents: [],
            overviewCurrentCalenderEvents: null,
            overviewNextEvents: null,
            isShowOverviewSubject: true,
            isShowOverviewHostName: true,
            isShowOverviewNextMeeting: true,
          },
          Ten: {
            id: '',
            position: 5,
            contentOption: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            clockAlignment: {
              id: uuid(),
              option: 'Empty',
              icon: informationIcon,
              optionNorway: norwayData.empty,
              value: 'Empty',
            },
            overviewTimezone: {
              id: uuid(),
              option: 'Empty',
              icon: informationIcon,
              value: 'Empty',
            },
            overviewEventLogo: '',
            overviewCustom_name: '',
            roomDetails: '',
            overviewChoosedRoom: null,
            overviewCalenderEvents: [],
            overviewCurrentCalenderEvents: null,
            overviewNextEvents: null,
            isShowOverviewSubject: true,
            isShowOverviewHostName: true,
            isShowOverviewNextMeeting: true,
          },
          Eleven: {
            id: '',
            position: 12,
            contentOption: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            clockAlignment: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            overviewTimezone: {
              id: uuid(),
              option: 'Empty',
              icon: informationIcon,
              value: 'Empty',
            },
            overviewEventLogo: '',
            overviewCustom_name: '',
            roomDetails: '',
            overviewChoosedRoom: null,
            overviewCalenderEvents: [],
            overviewCurrentCalenderEvents: null,
            overviewNextEvents: null,
            isShowOverviewSubject: true,
            isShowOverviewHostName: true,
            isShowOverviewNextMeeting: true,
          },
          Twelve: {
            id: '',
            position: 6,
            contentOption: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            clockAlignment: {
              id: uuid(),
              option: 'Empty',
              optionNorway: norwayData.empty,
              icon: informationIcon,
              value: 'Empty',
            },
            overviewTimezone: {
              id: uuid(),
              option: 'Empty',
              icon: informationIcon,
              value: 'Empty',
            },
            overviewEventLogo: '',
            overviewCustom_name: '',
            roomDetails: '',
            overviewChoosedRoom: null,
            overviewCalenderEvents: [],
            overviewCurrentCalenderEvents: null,
            overviewNextEvents: null,
            isShowOverviewSubject: true,
            isShowOverviewHostName: true,
            isShowOverviewNextMeeting: true,
          },
        },
      };
    }
    case COMMON.reset: {
      return initialState.meetingOverviewFull;
    }
    default:
      return state;
  }
};

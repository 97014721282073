import { TwoFAType, ModalViews } from 'enums';
import React, { useCallback, useState } from 'react';
import Popover from 'components/popover/popover';
import More from 'components/svg/more';
import Dustbin from 'components/svg/dustbin';
import { iState, iUserData } from 'types';
import { useStore } from 'store';
import { userActions, layoutActions, commonActions } from 'store/actions';
import DeleteModal, {
  DeleteModalVariants,
} from 'components/delete-modal/deleteModal';
import { deleteUser } from 'apis';
import { toaster } from 'helpers';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/index';
import NotSetup from '../not-setup/notSetup';
import styles from './teamMembersMobile.module.scss';

interface iProps {
  className: string;
  teamMembers: iUserData[];
  setFetchAgainTeammates: (a: boolean) => void;
}

function TeamMembersMobileView(props: iProps): JSX.Element {
  const { className, teamMembers, setFetchAgainTeammates } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { isShowDeleteContent } = state.layout || {};
  const { removeTeammateData, loggedInData } = state?.user || {};
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isContentUsedSomeWhere, setIsContentUsedSomeWhere] =
    useState<boolean>(false);
  const { companyData } = state.homePage || {};
  const { removeTeammates } = state.user.userPermissions || {};
  const navigate = useNavigate();
  // const [isLoading] = useState<boolean>(false);
  //  console.log({ isLoading });
  console.log(isContentUsedSomeWhere, setIsDeleting);

  const getDateFormatedDate = (dateTime: string): string => {
    if (!dateTime) return '';
    const date = new Date(dateTime);
    return (
      (date &&
        `${date.getDate()}.${date.getMonth()}.${date.getFullYear()} ${date.getHours()}.${date.getMinutes()}`) ||
      ''
    );
  };

  // const openDeleteTeammateModal = useCallback(
  //   (user: iUserData) => {
  //     dispatch(userActions.setRemoveTeammate(user));
  //     dispatch(layoutActions.toggleModal(ModalViews.isShowDeleteTeammate));
  //   },
  //   [dispatch]
  // );

  const openDeleteTeammateModal = useCallback(
    (user: iUserData) => {
      dispatch(userActions.setRemoveTeammate(user));
      dispatch(layoutActions.toggleModal(ModalViews.isShowDeleteContent));
    },
    [dispatch]
  );

  const removeTeammateHandler = useCallback(async () => {
    const { companyUserId } = removeTeammateData || {};
    if (!companyUserId) return;
    const response = await deleteUser(companyUserId, () => {});
    if (response) {
      if (
        removeTeammateData?.id === loggedInData?.id &&
        removeTeammateData?.email === loggedInData?.email
      ) {
        dispatch(commonActions.reset());
      }
      toaster.success('The teammate removed successfully ');
      setFetchAgainTeammates(true);
      dispatch(layoutActions.toggleModal());
      navigate(ROUTES.userSettingsTeamMembers);
    }
  }, [dispatch, navigate, removeTeammateData]);

  return (
    <div className={`${className} ${styles.playlists}`}>
      {teamMembers
        .filter((i) => i.invitedStatus !== 'INVITED')
        .map((item) => (
          <section key={item.id}>
            <div className={styles.avatarContainer}>
              <div className={styles.avatarCircle}>
                <div className={styles.avatarText}>
                  {item.profile_pic ? (
                    <img src={item.profile_pic} alt="" />
                  ) : (
                    <span>
                      {(item.first_name && item.first_name[0]) || ''}
                      {(item.last_name && item.last_name[0]) || ''}
                    </span>
                  )}
                </div>
              </div>
              <section>
                <span className={styles.nameCotainer}>
                  <p className={styles.nameDetails}>{item.first_name}</p>
                  <p className={styles.nameDetails}>{item.last_name}</p>
                </span>
                <p className={styles.emailDetails}>{item.email}</p>
              </section>
            </div>
            <div className={styles.dividerRow} />
            <div className={styles.permissionContainer}>
              <div className={styles.fullAccess}>
                <div className={styles.permissionDetails}>
                  <p className={styles.permissionTitle}>Permission</p>
                  <p className={styles.permissionAccess}>
                    {item.permissionType === 'limitedAccess'
                      ? 'Limited Access'
                      : 'Full Access'}
                  </p>
                </div>
                <div className={styles.permissionDetails}>
                  <p className={styles.permissionTitle}>Last Active</p>
                  <p className={styles.permissionAccess}>
                    {item.lastActiveTime &&
                      getDateFormatedDate(item.lastActiveTime)}
                  </p>
                </div>
              </div>
              <div className={styles.notSetupContainer}>
                <p className={styles.fA}>2fa</p>
                <span>
                  <NotSetup
                    isEnabled={
                      item?.settings
                        ? item?.settings?.twoFA !== TwoFAType.DISABLED
                        : false
                    }
                    // isEnabled
                  />
                </span>
              </div>
              <Popover
                className={styles.morePopupContainer}
                popoverClassName={styles.popover}
                popoverComponent={
                  <div
                    className={styles.morePopup}
                    onClick={(event) => {
                      const teamMemberCanDeleteOwner =
                        companyData?.role?.id === '3' && item?.role.id === '2';
                      if (!teamMemberCanDeleteOwner) {
                        if (!removeTeammates)
                          toaster.error('You do not have access to remove');
                        if (removeTeammates) {
                          event.stopPropagation();
                          openDeleteTeammateModal(item);
                        }
                      } else {
                        toaster.error(
                          'Team member do not have the access to delete the owner'
                        );
                      }
                    }}
                  >
                    <Dustbin className={styles.moreBtn} />
                    <p>Remove Team Member</p>
                  </div>
                }
              >
                <div className={styles.moreBtn}>
                  <More className={styles.icon} />
                </div>
              </Popover>
            </div>
            <div className={styles.teamMemberDivider} />
          </section>
        ))}

      <DeleteModal
        isOpen={isShowDeleteContent}
        variant={DeleteModalVariants.Teammate}
        name={
          `${removeTeammateData?.first_name} ${removeTeammateData?.last_name}` ||
          ''
        }
        isLoading={isDeleting}
        onClose={() => setIsContentUsedSomeWhere(false)}
        confirmationMsg="Are you sure you want to remove this user?"
        usageNotification="The user will no longer be able to access Screenz."
        deleteHandler={removeTeammateHandler}
        displayInput={false}
      />
    </div>
  );
}

export default TeamMembersMobileView;
